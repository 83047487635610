import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { Divider, MobileStepper } from "@mui/material"
import useHumans from "@/hooks/useHumans"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import HumanTooltipInfos from "@/styles/atoms/Tooltip/specialized/HumanTooltip"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip"
import Button from "@/styles/atoms/Button"

const PreventBottomClose = styled.div<{ active: boolean }>`
    ${({ active }) =>
			active &&
			css`
            position: relative;

            :after {
                content: '';
                position: absolute;
                display: block;
                height: 100px;
                bottom: -100px;
                left: 0;
                right: 0;
                background-color: transparent;
            }
        `}
`

const ActionCelluleTooltip = ({ cellule, children }) => {
	const humans = useHumans()

	const [selected, setSelected] = useState(0)

	return (
		<LightTooltip
			arrow
			placement="left"
			disableFocusListener
			disableTouchListener
			title={
				<PreventBottomClose active={cellule.crisisResponsableIds.length > 1}>
					<HumanTooltipInfos
						human={humans[cellule.crisisResponsableIds[selected]]}
						writePermission={false}
					/>
					<Divider />
					{cellule.crisisResponsableIds.length > 1 && (
						<MobileStepper
							variant="dots"
							steps={cellule.crisisResponsableIds.length}
							position="static"
							activeStep={selected}
							nextButton={
								<Button
									variant="light"
									size="small"
									onClick={() => setSelected(selected + 1)}
									disabled={selected >= cellule.crisisResponsableIds.length - 1}
								>
									<KeyboardArrowRight />
								</Button>
							}
							backButton={
								<Button
									variant="light"
									size="small"
									onClick={() => setSelected(selected - 1)}
									disabled={selected <= 0}
								>
									<KeyboardArrowLeft />
								</Button>
							}
						/>
					)}
				</PreventBottomClose>
			}
		>
			<div>{children}</div>
		</LightTooltip>
	)
}

export default ActionCelluleTooltip
