import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import sanitize from "sanitize-html"
import _ from "lodash"
import {
	CardContent,
	CardHeader,
	IconButton,
	Step,
	StepContent,
	StepLabel,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DownloadCell from "../arretes/DownloadCell"
import {
	ARRETE,
	CARE_FAMILY,
	INCIDENT,
	INTERVENTION,
	PATH_MAIN_COURANTE_ARRETE,
	PATH_MAIN_COURANTE_CARE,
	PATH_MAIN_COURANTE_INCIDENT,
	PATH_MAIN_COURANTE_INTERVENTION,
} from "@/redux-toolkit/userSettings/constants"
import {
	setCurrentIncident,
	setCurrentIntervention,
} from "@/redux-toolkit/edit/reducer"
import usePoints from "@/hooks/usePoints"
import useAccessRights from "@/hooks/useAccessRights"
import HistoryCardDescription from "./historicCard/HistoryCardDescription"
import { IHystoryElement, IHystoryElementAugmented } from "utils/types/IEvent"
import { Card } from "@/utils/components/style/card"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import usePdfs from "@/hooks/usePdfs"

// app:javascript:components:maincourante:subComponents:HistoricCard.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:HistoricCard",
)
debug.log = console.log.bind(console)
const StyledCardHeader = styled(CardHeader) <{ $color: string }>`
    & .MuiCardHeader-content {
        display: flex;
        flex-direction: column-reverse;
        .MuiCardHeader-title {
            font-size: 1.1rem;
            font-weight: 500;
        }
        .MuiCardHeader-subheader {
            font-size: 0.8rem;
        }
    }
    background: ${({ $color }) =>
		`linear-gradient(45deg, var(--${$color}500), var(--${$color}400))`};
    * {
        color: var(--white) !important;
    }
`
const HistoricCard = ({
	historyEvent,
}: {
	historyEvent: IHystoryElementAugmented
}) => {
	const dispatch = useDispatch()
	const points = usePoints()
	const history = useHistory()
	const pdfs = usePdfs()

	const permission_incident = useAccessRights(EnumToolPath.INCIDENT)
	const permission_intervention = useAccessRights(EnumToolPath.INTERVENTION)
	const permission_arretes = useAccessRights(EnumToolPath.ARRETE)
	const permission_care = useAccessRights(EnumToolPath.CARE)

	const eventPermissions = {
		[INCIDENT]: permission_incident,
		[INTERVENTION]: permission_intervention,
		[ARRETE]: permission_arretes,
		[CARE_FAMILY]: permission_care,
	}

	if (
		!eventPermissions[historyEvent.type]?.read &&
		eventPermissions[historyEvent.type] !== undefined
	) {
		return null
	}

	const onClick = ({ type, id }) => {
		switch (type) {
			case INCIDENT:
				dispatch(setCurrentIncident(points[id]))
				return
			case INTERVENTION:
				console.log(points[id])
				dispatch(setCurrentIntervention(points[id]))
				return
			case CARE_FAMILY:
				history.push(`/maincourante/care/${id}`)
				return
			default:
				console.error("onclick, type not supported")
				return
		}
	}
	const displayEditable =
		(historyEvent.type === INCIDENT || historyEvent.type === INTERVENTION) &&
		!!points[historyEvent.objectId]

	return (
		<Step key={historyEvent.date} active>
			<StepLabel StepIconComponent={historyEvent.icon as any}>
				{Moment(historyEvent.date).format("DD/MM/YYYY HH:mm")}
			</StepLabel>
			<StepContent>
				<Card>
					<StyledCardHeader
						subheader={historyEvent.subTitle}
						title={historyEvent.title}
						$color={historyEvent.color}
						action={
							<>
								{eventPermissions[historyEvent.type]?.write &&
									!historyEvent.noAction && (
										<>
											{historyEvent.type !== ARRETE && displayEditable && (
												<IconButton
													onClick={() =>
														onClick({
															id: historyEvent.objectId,
															type: historyEvent.type,
														})
													}
												>
													{historyEvent?.editIcon ?? <EditIcon />}
												</IconButton>
											)}
											{historyEvent.type === ARRETE && !!pdfs[historyEvent.objectId] && (
												<DownloadCell arreteId={historyEvent.objectId} />
											)}
										</>
									)}
							</>
						}
					/>
					{historyEvent.type !== ARRETE && historyEvent?.description && (
						<HistoryCardDescription historyEvent={historyEvent} />
					)}
				</Card>
			</StepContent>
		</Step>
	)
}

export default HistoricCard
