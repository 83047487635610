import ErrorService from "@/services/ErrorService"
import { setCommunesCdc, setCdcInfos, setCdcSharedJsonSchemas } from "./reducer"
import CdcApi from "./CdcApi"
import { createAsyncThunk } from "@reduxjs/toolkit"
import JsonSchemaApi from "../data/jsonSchema/JsonSchemaApi"
import IJsonSchema from "@/utils/types/IJsonSchema"

export const getUserCommunesCdc = async (dispatch) => {
	try {
		const { communes_cdc, cdc } = await CdcApi.getCommunesCdc()
		const res = await JsonSchemaApi.getCdcJsonSchemas(cdc.id)

		const cdcSharedJsonSchemas = {}
		//* keeping cdc shared_between_communes forms from all communes dynamics forms
		cdc.shared_between_communes.forEach((jsonschema_id: string) => {
			if (!res.jsonSchemas[jsonschema_id]) return
			cdcSharedJsonSchemas[jsonschema_id] = res.jsonSchemas[jsonschema_id]
		})

		dispatch(setCommunesCdc(communes_cdc))
		dispatch(setCdcInfos(cdc))
		dispatch(setCdcSharedJsonSchemas(cdcSharedJsonSchemas))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des paramétres d'utilisateur, message : ${error.message}`
		ErrorService.error({
			component: "ressources:getUserCommunesCdc",
			message: errorMessage,
			dispatch,
		})
	}
}

export const getPicrimCommuneCdc = createAsyncThunk(
	"picrim/getCdc",
	async (communeId: string, { dispatch }) => {
		try {
			const { communes_cdc, cdc } = await CdcApi.getPicrimCdc(communeId)
			dispatch(setCommunesCdc(communes_cdc))
			dispatch(setCdcInfos(cdc))
		} catch (error) {
			console.error(error)
			throw new Error(
				`Erreur lors de la récupération de la CDC, message d'erreur : ${error.message}`,
			)
		}
	},
)
