import { Tooltip } from "@mui/material"
import { withStyles } from "@mui/styles"

export const StyledTooltip = withStyles((theme) => ({
	tooltip: {
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: 11,
		background: "transparent",
		boxShadow: "var(--nextui-box-shadow-low)",
		maxWidth: "min-content",
		margin: "4px",
		padding: "4px",
		borderRadius: "16px",
	},
}))(Tooltip) as React.FC<any>
