import ArrowBack from "@mui/icons-material/ArrowBack"
import { Title } from "@/pages/admin/formulaire_dynamique/MyForms/components/components/ErrorsSolverItem"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import Debug from "debug"
import React from "react"
import { useSelector } from "react-redux"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
import getImage from "@/utils/getImage"
import EditStylePoint from "./EditStylePoint"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"

// app:javascript:utils:panels:panelCarto:PanelStyleEditorPoint.tsx
const debug = Debug(
	"app:javascript:utils:panels:panelCarto:PanelStyleEditorPoint",
)
debug.log = console.log.bind(console)

const PanelStyleEditorPoint = ({
	providedJsonSchemaId = undefined,
	onLeave = undefined,
	leaveButtonTitle = "Retour",
}: {
	providedJsonSchemaId?: string
	onLeave?: () => void
	leaveButtonTitle?: string
}) => {
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const { jsonSchemaId } = useSelector((state: any) => state.common.panel)
	const jSchemaId = providedJsonSchemaId ?? jsonSchemaId

	const jsonSchemaTitle =
		jSchemaId === INCIDENT
			? "Évènements"
			: jSchemaId === INTERVENTION
			? "Interventions"
			: jSchemaId === TEMPORARY_CARE
			? "Centre d'accueil"
			: jsonSchemasWithStatics[jSchemaId]?.title ?? jSchemaId

	return (
		<Flex fullWidth directionColumn alignItemsStart padding="1rem" gap="1rem">
			{onLeave && (
				<Flex gap="1rem">
					<PrimaryOutlinedButton startIcon={<ArrowBack />} onClick={onLeave}>
						{leaveButtonTitle}
					</PrimaryOutlinedButton>
					{/* <PrimaryOutlinedButton onClick={() => {}}>
                    Réinitialiser le style
                </PrimaryOutlinedButton> */}
				</Flex>
			)}
			<Flex gap="1rem">
				<img
					src={getImage(jsonSchemasWithStatics[jSchemaId]?.imgId ?? jSchemaId)}
					width="45"
				/>
				<Flex directionColumn alignItemsStart>
					<Title>Modification du style</Title>
					<Subtitle>{jsonSchemaTitle}</Subtitle>
				</Flex>
			</Flex>
			<EditStylePoint jsonSchemaId={jSchemaId} />
		</Flex>
	)
}

export default PanelStyleEditorPoint
