import React from "react"
import Debug from "debug"
import { useDispatch, useSelector } from "react-redux"
import PanelDaybookData from "./panelCarto/PanelDaybookData"
import PanelDaybookList from "./PanelDaybookList"
import PanelDaybookPoint from "./PanelDaybookPoint"
import PanelLegend from "./PanelLegend"
import PanelDaybookSignal from "./PanelDaybookSignal"
import SimpleSuperPanel from "./SimpleSuperPanel"
import PanelDaybookCare from "./PanelDaybookCare"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import PanelDaybookVortex from "./panelCarto/PanelDaybookVortex"
import PanelDaybookVortexStation from "./panelCarto/vortex/VortexStationData"
import PanelDaybookMultipolygon from "./PanelDaybookMultipolygon"
import PanelStyleEditorPoint from "./panelCarto/PanelStyleEditorPoint"
import PanelStyleEditorPolygon from "./panelCarto/PanelStyleEditorPolygon"
import PanelCartoConfigs from "./panelCarto/PanelCartoConfigs"
import PanelFilterJsonSchema from "./panelCarto/PanelFilterJsonSchema"
import { setPanel } from "@/redux-toolkit/common/reducer"
import PanelCartoConfigInfos from "./panelCarto/PanelCartoConfigInfos"
import IsolatedTreeProvider from "@/pages/telechargement/Upload/component/IsolatedTreeProvider"
import PanelSuivis from "./PanelSuivis"
import PanelSupportsList from "./PanelSupportsList"
import PanelSupportPoint from "./PanelSupportPoint"

// app:javascript:utils:panels:PanelCarto.tsx
const debug = Debug("app:javascript:utils:panels:PanelCarto")
debug.log = console.log.bind(console)

export const DAYBOOK_DATA = "DAYBOOK_DATA"
export const DAYBOOK_LEGEND = "DAYBOOK_LEGEND"
export const DAYBOOK_LIST = "DAYBOOK_LIST"
export const DAYBOOK_POINT = "DAYBOOK_POINT"
export const DAYBOOK_SIGNAL = "DAYBOOK_SIGNAL"
export const DAYBOOK_CARE = "DAYBOOK_CARE"
export const DAYBOOK_VORTEX = "DAYBOOK_VORTEX"
export const DAYBOOK_VORTEX_STATION = "DAYBOOK_VORTEX_STATION"
export const DAYBOOK_COUCHE = "DAYBOOK_COUCHE"
export const CARTO_CONFIGS = "CARTO_CONFIGS"
export const CARTO_CONFIG_INFO = "CARTO_CONFIG_INFO"
export const STYLE_EDITOR_POINT = "STYLE_EDITOR_POINT"
export const STYLE_EDITOR_POLYGON = "STYLE_EDITOR_POLYGON"
export const FILTER_JSONSCHEMA = "FILTER_JSONSCHEMA"
export const DAYBOOK_SUPPORTS_LIST = "DAYBOOK_SUPPORTS_LIST"
export const DAYBOOK_SUPPORT_POINT = "DAYBOOK_SUPPORT_POINT"

const PanelCarto = ({ isOpen }) => {
	const selectedEvent = useSelectedEvent()
	const currentPannel = useSelector((state: any) => state.common.panel)
	const dispatch = useDispatch()

	const renderPannel = {
		[DAYBOOK_DATA]: <PanelDaybookData />,
		[DAYBOOK_LEGEND]: <PanelLegend />,
		[DAYBOOK_LIST]: <PanelDaybookList />,
		[DAYBOOK_POINT]: <PanelDaybookPoint />,
		[DAYBOOK_SIGNAL]: <PanelDaybookSignal />,
		[DAYBOOK_CARE]: <PanelDaybookCare />,
		[DAYBOOK_VORTEX]: <PanelDaybookVortex />,
		[DAYBOOK_VORTEX_STATION]: <PanelDaybookVortexStation />,
		[DAYBOOK_COUCHE]: <PanelDaybookMultipolygon />,
		[CARTO_CONFIGS]: (
			<IsolatedTreeProvider>
				<PanelCartoConfigs />
			</IsolatedTreeProvider>
		),
		[CARTO_CONFIG_INFO]: (
			<IsolatedTreeProvider>
				<PanelCartoConfigInfos configId={currentPannel?.configId} />
			</IsolatedTreeProvider>
		),
		[STYLE_EDITOR_POINT]: (
			<PanelStyleEditorPoint
				onLeave={() => {
					dispatch(setPanel({ type: DAYBOOK_LEGEND }))
				}}
				leaveButtonTitle="Retour à la légende"
			/>
		),
		[STYLE_EDITOR_POLYGON]: (
			<PanelStyleEditorPolygon
				onLeave={() => {
					dispatch(setPanel({ type: DAYBOOK_LEGEND }))
				}}
				leaveButtonTitle="Retour à la légende"
			/>
		),
		[FILTER_JSONSCHEMA]: <PanelFilterJsonSchema />,
		[DAYBOOK_SUPPORTS_LIST]: <PanelSupportsList />,
		[DAYBOOK_SUPPORT_POINT]: <PanelSupportPoint />,
	}

	if (!selectedEvent && !window.location.pathname.includes("/cartographie")) {
		return null
	}

	return (
		<>
			<SimpleSuperPanel isOpen={isOpen}>
				{renderPannel[currentPannel?.type]}
			</SimpleSuperPanel>
		</>
	)
}

export default PanelCarto
