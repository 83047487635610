import { CardContent, Table, TableCell, TableRow } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React from "react"
import styled from "styled-components"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import useEnhanceStatic from "./properties/enhanceStatics/useEnhanceStatic"
import PointProperty from "./properties/PointProperty"
import _ from "lodash"
import getImage from "@/utils/getImage"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import useCdc from "@/hooks/useCdc"

const MyLeftCell = styled(TableCell)`
    padding: 8px 12px 8px 0 !important;
    width: 30%;
`
const MyRightCell = styled(TableCell)`
    padding: 8px 0 8px 12px !important;
    text-align: right !important;
    width: 70%;
`

function PointCardContent({
	point,
	showCommune = false,
	isPublic = false,
	showJsonSchema = false,
}) {
	const jsonSchemas = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const jsonSchemasWithShared = { ...jsonSchemas, ...cdcSharedJsonSchemas }
	const jsonSchema = jsonSchemasWithShared[point?.jsonschema_id]
	const schemaProperties = isPublic
		? jsonSchema?.template?.properties?.filter((property) => property.isPublic)
		: jsonSchema?.template?.properties

	const cdc = useCdc()
	const communeName = cdc.communes?.find(
		(comm) => comm.id === point?.commune_id,
	)?.name

	const { addBefore, addAfter } = useEnhanceStatic(jsonSchema)

	if (!schemaProperties) return null

	const keys = schemaProperties.filter(
		(prop) => prop.isIdentifiant && prop.type === JsonSchemaPropertiesType.TEXT,
	)

	return (
		<CardContent>
			<Table size="small" style={{ tableLayout: "fixed" }}>
				{showCommune && (
					<TableRow>
						<MyLeftCell component="th" scope="row">
							Commune
						</MyLeftCell>
						<MyRightCell>{communeName}</MyRightCell>
					</TableRow>
				)}
				{addBefore.map(({ title, renderer }) => (
					<TableRow key={title}>
						<MyLeftCell component="th" scope="row">
							{title}
						</MyLeftCell>
						<MyRightCell>
							{renderer({
								point,
								align: "right",
								editable: false,
							})}
						</MyRightCell>
					</TableRow>
				))}
				{showJsonSchema && (
					<TableRow>
						<MyLeftCell component="th" scope="row">
							Type
						</MyLeftCell>
						<MyRightCell>
							<img src={getImage(jsonSchema?.imgId)} width={35} height={35} />{" "}
							{jsonSchema?.title}
						</MyRightCell>
					</TableRow>
				)}
				{schemaProperties.map((property) => {
					const convertedValue = _.isNumber(
						_.get(point, "geojson.properties." + property.name),
					)
						? _.toString(_.get(point, "geojson.properties." + property.name))
						: _.get(point, "geojson.properties." + property.name)

					if (_.isEmpty(convertedValue)) {
						return null
					}

					return (
						!keys.includes(property) && (
							<TableRow key={property.name}>
								<MyLeftCell component="th" scope="row">
									{property.name}
								</MyLeftCell>
								<MyRightCell>
									<PointProperty
										point={point}
										property={property}
										align={"right"}
									/>
								</MyRightCell>
							</TableRow>
						)
					)
				})}
				{addAfter.map(({ title, renderer }) => (
					<TableRow key={title}>
						<MyLeftCell component="th" scope="row">
							{title}
						</MyLeftCell>
						<MyRightCell>
							{renderer({
								point,
								align: "right",
								editable: false,
							})}
						</MyRightCell>
					</TableRow>
				))}
			</Table>
		</CardContent>
	)
}

export default PointCardContent
