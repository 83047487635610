import { createAsyncThunk } from "@reduxjs/toolkit"
import { PDF_GENERATORS } from "@/redux-toolkit/time/constants"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import ErrorService from "@/services/ErrorService"
import PdfGeneratorApi from "./PdfGeneratorApi"
import { addPdfGenerator, removePdfGenerator } from "./reducer"

export const getPdfGenerators = async (dispatch, state) => {
	try {
		const { pdfGenerators, serverDatetime } =
			await PdfGeneratorApi.getPdfGenerators(state.time[PDF_GENERATORS])
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: PDF_GENERATORS,
			}),
		)
		pdfGenerators.forEach((pdfGenerator) =>
			dispatch(addPdfGenerator(pdfGenerator)),
		)
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des modèles de pdfs, message d'erreur : ${error.message}`
		ErrorService.error({
			component: getPdfGenerators,
			message: errorMessage,
			dispatch,
		})
	}
}

export const createPdfsGenerator = createAsyncThunk(
	"data/pdfGenerator/createPdfsGenerator",
	async ({ data, title }: any, { dispatch, getState }) => {
		try {
			const res = await PdfGeneratorApi.createPdfGenerator(data, title)
			await getPdfGenerators(dispatch, getState())
		} catch (error) {
			const errorMessage = `Le modèle d'arrêté n'a pas pu être créé, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:createPdfGenerator",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updatePdfsGenerator = createAsyncThunk(
	"data/pdfGenerator/updatePdfsGenerator",
	async ({ data, title, id }: any, { dispatch, getState }) => {
		try {
			const res = await PdfGeneratorApi.updatePdfGenerator(data, title, id)
			await getPdfGenerators(dispatch, getState())
		} catch (error) {
			const errorMessage = `Le modèle d'arrêté n'a pas pu être modifier`
			ErrorService.error({
				component: "resources:updatePdfsGenerator",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const deletePdfsGenerator = createAsyncThunk(
	"data/pdfGenerator/deletePdfsGenerator",
	async (id: string, { dispatch, getState }) => {
		try {
			const res = await PdfGeneratorApi.deletePdfGenerator(id)
			dispatch(removePdfGenerator(id))
		} catch (error) {
			const errorMessage = `Le modèle n'a pas pu être supprimé. Message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:deletePdfGenerator",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
