import Axios from "axios"

const getFiches = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(`/api/fiches?lastSynchro=${lastSynchro}`)
	return result.data
}

const getFichesWithDeleted = async (
	lastSynchro = "2000-04-12T12:47:50+02:00",
) => {
	const result = await Axios.get(
		`/api/fiches?lastSynchro=${lastSynchro}&deleted=true`,
	)
	return result.data
}

const createFiche = async (fiche) => {
	const result = await Axios.post("/api/fiches", { data: fiche })
	return result.data
}

const deleteFiche = async (id) => {
	const result = await Axios.delete(`/api/fiches/${id}`)
	return result.data
}

const updateFiche = async (fiche) => {
	const result = await Axios.put(`/api/fiches/${fiche.id}`, {
		data: fiche,
	})
	return result.data
}

export default {
	getFiches,
	getFichesWithDeleted,
	createFiche,
	deleteFiche,
	updateFiche,
}
