import React from "react"
import { useLocalStorage } from "react-use"
import NestedListChildrenBase, {
	NestedListChildrenProps,
} from "./NestedListChildrenBase"

const NestedListChildrenPersistent = (props: NestedListChildrenProps) => {
	const [open, setOpen] = useLocalStorage(props.subCategory?.title, false)

	return <NestedListChildrenBase open={open} setOpen={setOpen} {...props} />
}

export default NestedListChildrenPersistent
