import {
	removeCoucheJsonSchemas as removeCoucheJsonSchemasCarto,
	addCoucheJsonSchemas as addCoucheJsonSchemasCarto,
	addCoucheMultiPolygons as addCoucheMultiPolygonsCarto,
	removeCoucheMultiPolygons as removeCoucheMultiPolygonsCarto,
	reorderSurfaces as reorderSurfacesCarto,
	toggleCoucheEvents as toggleCoucheEventsCarto,
	toggleVisibilityJsonSchemas as togglevisibilityJsonSchemasCarto,
	toggleVisibilityMultiPolygons as togglevisibilityMultiPolygonsCarto,
	addStyleJsonSchemas as addStyleJsonSchemasCarto,
	toggleSelectedLayer as toggleSelectedLayerCarto,
	toggleViewportParams as toggleViewportParamsCarto,
	overwriteState as overwriteCartoState,
} from "@/redux-toolkit/carto/reducer"
import {
	removeCoucheJsonSchemas as removeCoucheJsonSchemasDaybook,
	addCoucheJsonSchemas as addCoucheJsonSchemasDaybook,
	addCoucheMultiPolygons as addCoucheMultiPolygonsDaybook,
	removeCoucheMultiPolygons as removeCoucheMultiPolygonsDaybook,
	removeCoucheDrawGeojson,
	reorderSurfaces as reorderSurfacesDaybook,
	toggleCoucheEvents as toggleCoucheEventsDaybook,
	toggleVisibilityDrawGeojson as togglevisibilityDrawGeojsonDaybook,
	toggleVisibilityJsonSchemas as togglevisibilityJsonSchemasDaybook,
	toggleVisibilityMultiPolygons as togglevisibilityMultiPolygonsDaybook,
	addStyleJsonSchemas as addStyleJsonSchemasDaybook,
	toggleSelectedLayer as toggleSelectedLayerDaybook,
	toggleViewportParams as toggleViewportParamsDaybook,
	toggleNextStatusFilter as toggleNextStatusFilterDaybook,
} from "@/redux-toolkit/daybook/reducer"
import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { toggleClusterJsonSchema as toggleCartoCluster } from "@/redux-toolkit/carto/reducer"
import { toggleClusterJsonSchema as toggleDaybookCluster } from "@/redux-toolkit/daybook/reducer"
import IMapState from "utils/types/IMapState"

const selectDaybook = createDeepEqualSelector(
	(state: any) => state.daybook,
	(daybook) => daybook,
)
const selectCarto = createDeepEqualSelector(
	(state: any) => state.carto,
	(carto) => carto,
)

const useMapState: () => IMapState & {
	addCoucheJsonSchemas: any
	addCoucheMultiPolygons: any
	addStyleJsonSchemas: any
	removeCoucheJsonSchemas: any
	removeCoucheMultiPolygons: any
	removeCoucheDrawGeojson: any
	reorderSurfaces: any
	toggleCoucheEvents: any
	toggleVisibilityDrawGeojson: any
	toggleVisibilityJsonSchemas: any
	toggleVisibilityMultiPolygons: any
	toggleMarkerCluster: any
	toggleSelectedLayer: any
	toggleViewportParams: any
	overwriteState: any
	toggleNextStatusFilter: any
} = () => {
	//* Cartography used in download tool doesn't come from daybook tool
	const isCarto = ["cartographie", "download"].some((keyWord) =>
		window.location.pathname.includes(keyWord),
	)
	const mapState = isCarto
		? useSelector(selectCarto)
		: useSelector(selectDaybook)

	return {
		...mapState,
		removeCoucheJsonSchemas: isCarto
			? removeCoucheJsonSchemasCarto
			: removeCoucheJsonSchemasDaybook,
		addCoucheJsonSchemas: isCarto
			? addCoucheJsonSchemasCarto
			: addCoucheJsonSchemasDaybook,
		addStyleJsonSchemas: isCarto
			? addStyleJsonSchemasCarto
			: addStyleJsonSchemasDaybook,
		addCoucheMultiPolygons: isCarto
			? addCoucheMultiPolygonsCarto
			: addCoucheMultiPolygonsDaybook,
		removeCoucheMultiPolygons: isCarto
			? removeCoucheMultiPolygonsCarto
			: removeCoucheMultiPolygonsDaybook,
		removeCoucheDrawGeojson: isCarto ? undefined : removeCoucheDrawGeojson,
		reorderSurfaces: isCarto ? reorderSurfacesCarto : reorderSurfacesDaybook,
		toggleCoucheEvents: isCarto
			? toggleCoucheEventsCarto
			: toggleCoucheEventsDaybook,
		toggleNextStatusFilter: isCarto
			? undefined
			: toggleNextStatusFilterDaybook,
		toggleVisibilityDrawGeojson: isCarto
			? undefined
			: togglevisibilityDrawGeojsonDaybook,
		toggleVisibilityJsonSchemas: isCarto
			? togglevisibilityJsonSchemasCarto
			: togglevisibilityJsonSchemasDaybook,
		toggleVisibilityMultiPolygons: isCarto
			? togglevisibilityMultiPolygonsCarto
			: togglevisibilityMultiPolygonsDaybook,
		toggleMarkerCluster: isCarto ? toggleCartoCluster : toggleDaybookCluster,
		toggleSelectedLayer: isCarto
			? toggleSelectedLayerCarto
			: toggleSelectedLayerDaybook,
		toggleViewportParams: isCarto
			? toggleViewportParamsCarto
			: toggleViewportParamsDaybook,
		overwriteState: isCarto
			? overwriteCartoState
			: null,
	}
}

export default useMapState
