import { yupResolver } from "@hookform/resolvers/yup"
import PeopleIcon from "@mui/icons-material/People"
import { Chip, IconButton } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import Debug from "debug"
import Moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { PrimaryOutlinedButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { widthCustom } from "@/utils/components/tables/widthProps"
import FDateTime from "@/utils/form/FDateTime"
import { FormDependencies } from "@/utils/form/FormDependencies"
import FText from "@/utils/form/FText"
import SuperModal from "@/utils/modals/SuperModal"
import Yup from "@/utils/yup"
import { v4 as uuidv4 } from "uuid"
import AdultChip from "../chips/AdultChip"
import AnimalChip from "../chips/AnimalChip"
import BabyChip from "../chips/BabyChip"
import ChildChip from "../chips/ChildChip"
import ModalLeavingPerson from "./ModalLeavingPerson"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_CARE } from "@/redux-toolkit/userSettings/constants"
import { IFamilyMemberType } from "@/utils/types/IFamilyMember"
import { ColumnDef } from "@tanstack/react-table"
import { StyledChip } from "@/utils/components/style/chip"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:maincourante:care:leaving:ModalLeavingFamily.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:leaving:ModalLeavingFamily",
)
debug.log = console.log.bind(console)

const SPeopleIcon = styled(PeopleIcon)<{ disabled: boolean }>`
    ${({ disabled }) =>
			css`
            color: ${disabled ? "#cdcdcd" : "var(--primary800)"};
        `}
`

export const LEAVING_FAMILY = "LEAVING_FAMILY"

const ModalDaybookLeavingGroup = ({
	careId,
	leavingGroupId,
	isOpen,
	onClose,
	initialValues,
}: {
	careId: string
	leavingGroupId: string
	isOpen: boolean
	onClose: () => void
	initialValues?: any
}) => {
	const leavingFamilySchema = Yup.object({
		geojson: Yup.object({
			properties: Yup.object({
				"Date de sortie": Yup.string()
					.required("La date de sortie est obligatoire")
					.default(Moment().format()),
				"Lieu de sortie": Yup.string().default(""),
				"Points associés": Yup.array(
					Yup.object({
						"Identifiant du point associé": Yup.string(),
						"Index du membre associé": Yup.number(),
					}),
				)
					.default([])
					.min(1, "Veuillez renseigner au moins une personne sortante"),
			}),
		}),
	})
	const [isOpenModalLeavingPerson, setIsOpenModalLeavingPerson] =
		useState(false)
	const [hasBeenModified, setHasBeenModified] = useState(false)
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const isLocked = selectedEvent?.locked
	const dispatch = useDispatch()
	const points = usePoints()
	const initialData = points[leavingGroupId]
	const onSubmit = (data) => {
		const {
			geojson: { properties: geojsonDataProperties },
		} = data
		if (initialData) {
			dispatch(
				updatePointWithUpdateEvent({
					point: {
						...initialData,
						...{
							geojson: {
								properties: {
									...geojsonDataProperties,
									careId,
								},
							},
							event_id: selectedEvent?.id,
							jsonschema_id: LEAVING_FAMILY,
						},
					},
					event: {
						...selectedEvent,
						updated_at: Moment().format(),
					},
				}),
			)
		} else {
			dispatch(
				createPointWithUpdateEvent({
					point: {
						geojson: {
							properties: {
								...geojsonDataProperties,
								careId,
							},
						},
						id: uuidv4(),
						event_id: selectedEvent?.id,
						jsonschema_id: LEAVING_FAMILY,
					},
					event: {
						...selectedEvent,
						updated_at: Moment().format(),
					},
				}),
			)
		}
		onClose()
	}

	const methods = useForm({
		resolver: yupResolver(leavingFamilySchema),
		defaultValues: initialValues
			? initialValues
			: leavingFamilySchema.getDefault(),
	})

	useEffect(() => {
		if (initialValues) {
			methods.reset({
				...initialValues,
				geojson: {
					properties: {
						...initialValues.geojson.properties,
						"Date de sortie": Moment().format(),
					},
				},
			})
			return
		}
		if (initialData) {
			let type = "personne seule"
			if (initialData.geojson.properties["Points associés"].length > 1) {
				type = "groupe de personnes"
			}
			methods.reset(initialData)
			return
		}
		methods.reset({
			...leavingFamilySchema.getDefault(),
			geojson: {
				// @ts-ignore
				properties: {
					...leavingFamilySchema.getDefault().geojson.properties,
					"Date de sortie": Moment().format(),
				},
			},
		})
	}, [initialData, initialValues, isOpen])

	const personAssociated =
		methods.watch("geojson.properties.Points associés") ?? []

	const members =
		personAssociated.map((person = {}) => {
			const familyPoint = points[person["Identifiant du point associé"]]
			let member
			if (!familyPoint) {
				member = {
					type: undefined,
					Identité: "Le groupe de cette personne a été supprimé",
					Spécificités: [],
					Téléphone: "",
					besoin: "",
				}
			} else {
				if (person["Index du membre associé"] === -1) {
					member = {
						type: IFamilyMemberType.ADULT,
						Identité: familyPoint.geojson.properties.Identité,
						Spécificités: familyPoint.geojson.properties.Spécificités,
						Téléphone: familyPoint.geojson.properties.Téléphone,
						besoin: familyPoint.geojson.properties.besoin,
					}
				} else {
					member =
						familyPoint.geojson.properties.family?.[
							person["Index du membre associé"]
						]
				}
			}
			return member
		}) ?? []

	methods.watch("geojson.properties.Points associés")

	const columns = useMemo(() => {
		return [
			{
				header: "Type",
				accessorKey: "type",
				cell: ({
					cell: {
						row: { index },
					},
				}) => {
					switch (members[index]?.type) {
						case IFamilyMemberType.ADULT:
							return <AdultChip />
						case IFamilyMemberType.CHILD:
							return <ChildChip />
						case IFamilyMemberType.BABY:
							return <BabyChip />
						case IFamilyMemberType.ANIMAL:
							return <AnimalChip />
						default:
							return (
								<StyledChip
									label="Inconnu"
									variant="outlined"
									$colorProperty="var(--neutral800)"
									$backgroundColorProperty="var(--neutral100)"
								/>
							)
					}
				},
				...widthCustom(120),
			},
			{
				header: "Identité",
				accessorKey: "Identité",
				cell: ({
					cell: {
						row: { index },
					},
				}) => {
					return <>{members[index]?.Identité}</>
				},
			},
			{
				header: "Spécificités",
				accessorKey: "Spécificités",
				cell: ({
					row,
					cell: {
						row: { index },
					},
					...other
				}) => {
					return (
						<>
							{(members[index]?.Spécificités ?? []).map((specificity) => (
								<Chip key={specificity} label={specificity} />
							))}
						</>
					)
				},
			},
			{
				header: "Commentaire",
				accessorKey: "Commentaire",
				cell: ({
					row,
					cell: {
						row: { index },
					},
					...other
				}) => {
					return <>{members[index]?.Commentaire}</>
				},
			},
			{
				header: "",
				accessorKey: "Supprimer",
				enableSorting: false,
				cell: ({ row }) => {
					return (
						<>
							<PrimaryOutlinedButton
								onClick={() => {
									methods.setValue(
										"geojson.properties.Points associés",
										methods
											.getValues()
											.geojson.properties["Points associés"].filter(
												(member, index) => index !== row.index,
											),
									)
									setHasBeenModified(true)
								}}
								disabled={!permissions.write || isLocked}
							>
								Retirer
							</PrimaryOutlinedButton>
						</>
					)
				},
				...widthCustom(100),
			},
		] as ColumnDef<any>[]
	}, [personAssociated, methods.getValues(), members])

	return (
		<FormProvider {...methods}>
			<SuperModal
				rightContent={
					<Flex fullWidth directionColumn gap="1rem" alignItemsInitial>
						<FDateTime
							name="geojson.properties.Date de sortie"
							label="Date de sortie"
							disabled={!permissions.write || isLocked}
						/>
						<H6>Ajouter une personne sortante</H6>
						<PrimaryOutlinedButton
							onClick={() => setIsOpenModalLeavingPerson(true)}
							disabled={!permissions.write || isLocked}
						>
							<IconButton
								onClick={() => {
									setIsOpenModalLeavingPerson(true)
								}}
								disabled={!permissions.write || isLocked}
							>
								<SPeopleIcon disabled={!permissions.write || isLocked} />
							</IconButton>
							Ajoutez une ou plusieurs personne(s)
						</PrimaryOutlinedButton>
					</Flex>
				}
				title="Sortie de personne(s) du centre d'accueil"
				isOpen={isOpen}
				disableSave={
					(!methods.formState.isDirty && !initialValues && !hasBeenModified) ||
					!permissions.write ||
					isLocked
				}
				size="5xl"
				onClose={onClose}
				onClick={methods.handleSubmit(onSubmit)}
				isNew={leavingGroupId === undefined}
			>
				<Flex gap="1rem" fullWidth alignItemsStart>
					<Flex directionColumn gap="2rem" fullWidth alignItemsStart>
						<Flex directionColumn gap="1rem" fullWidth alignItemsStart>
							<FText
								name="geojson.properties.Lieu de sortie"
								label="Lieu de sortie"
								disabled={!permissions.write || isLocked}
							/>
						</Flex>

						<FormDependencies targets={["geojson.properties.Points associés"]}>
							{({ "geojson.properties.Points associés": personAssociated }) => (
								<>
									{personAssociated.length > 0 && (
										<Flex gap="1rem" directionColumn alignItemsStart fullWidth>
											<H6>Personnes sorties</H6>
											<SuperReactTable
												noDefer
												$secondaryTableStyle
												data={members}
												columns={columns}
												infiniteSize
												showBottom={false}
												isEditable={false}
												isDeletable={false}
												simpleTable
												selectable={false}
											/>
										</Flex>
									)}
								</>
							)}
						</FormDependencies>
					</Flex>
					<ModalLeavingPerson
						isOpen={isOpenModalLeavingPerson}
						onClose={() =>
							setIsOpenModalLeavingPerson(!isOpenModalLeavingPerson)
						}
						careId={careId}
						initialData={initialData}
					/>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalDaybookLeavingGroup
