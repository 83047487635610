import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import { setPanel } from "@/redux-toolkit/common/reducer"
import React from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"

const Wrapper = styled.div<{ isOpen: boolean }>`
    flex: 0 0 auto !important;
    min-width: var(--panel-width);
    margin-top: 48px;
    transition: 0.5s;
    background-color: white;
    width: ${({ isOpen }) => (isOpen ? "var(--panel-width)" : 0)}px !important;
    max-width: var(--panel-width) !important;
    overflow: ${({ isOpen }) => (isOpen ? "auto" : "hidden")};
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${
			isMobile
				? css`
              position: absolute;
              top: 0;
              bottom: 0;
              z-index: 100;
              width: 100%;
          `
				: css`
              width: ${(p: any) => (p.isOpen ? "750px" : 0)};
          `
		}
`

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: ${isMobile ? "0.4em" : "1rem"};
    border-bottom: 1px solid var(--neutral150) !important;
    align-items: center;
`
const PanelContent = styled.div`
    flex-grow: 1;
    overflow: auto;
`

const SimpleSuperPanel = ({ isOpen, children }) => {
	const dispatch = useDispatch()
	return (
		<Wrapper isOpen={isOpen}>
			{isMobile && (
				<TopContainer>
					<IconButton onClick={() => dispatch(setPanel(null))}>
						<CloseIcon />
					</IconButton>
				</TopContainer>
			)}
			<PanelContent>{children}</PanelContent>
		</Wrapper>
	)
}

export default SimpleSuperPanel
