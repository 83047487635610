import L from "leaflet"
import "./MapService/fullscreen"
import "leaflet-simple-map-screenshoter"
import FileSaver from "file-saver"
import React from "react"
import ReactDOMServer from "react-dom/server"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import SignalService from "./SignalService"

let simpleMapScreenshoter
let map = undefined
let drawTool = undefined

const fitGeoJSONBounds = (geojson) => {
	map.invalidateSize()
	map.fitBounds(L.geoJSON(geojson).getBounds())
}

const registerDrawTool = (newDrawTool) => {
	drawTool = newDrawTool
}
const drawCircle = (currentMap) => {
	currentMap.pm.enableDraw("Circle", {
		swappable: true,
		snapDistance: 20,
	})
}
const drawPolygon = (currentMap) => {
	currentMap.pm.enableDraw("Polygon", {
		snappable: true,
		snapDistance: 20,
	})
}
const drawMarker = ({ color, type, currentMap }) => {
	const iconReact = SignalService.buildIcon({ color, type })
	currentMap.pm.enableDraw("Marker", {
		snappable: true,
		snapDistance: 20,
		markerStyle: {
			icon: iconReact,
		},
	})
}
const removeLastPoint = (currentMap) => {
	try {
		currentMap.pm.Draw?.Polygon?._removeLastVertex()
	} catch (e) {
		console.log(e)
	}
}
const drawRectangle = (currentMap) => {
	currentMap.pm.enableDraw("Rectangle", {
		snappable: true,
		snapDistance: 20,
	})
}

const drawLine = (currentMap) => {
	currentMap.pm.enableDraw("Line", {
		snappable: true,
		snapDistance: 20,
	})
}

const clearLayers = () => {
	drawTool.leafletElement.clearLayers()
}

const getMap = () => {
	return map
}

const registerMap = (newMap) => {
	const pluginOptions = {
		hidden: true,
		hideElementsWithSelectors: [
			".leaflet-control-container",
			".MuiButton-root",
			".MuiPaper-rounded",
			".MinimapControl__Container-sc-qqf4he-2",
			".leaflet-popup",
			".BottomCenterControl__Control-sc-7kwrdm-0 fZyyLC",
			".MinimapControl__StyledMapContainer-sc-ufy13m-0",
			".SuperControl-sc-scj8iu-0",
		],
	}

	if (newMap) {
		map = newMap
		map.doubleClickZoom.disable()
		simpleMapScreenshoter = L.simpleMapScreenshoter(pluginOptions).addTo(map)
	}
}
const takeScreen = () => {
	const format = "blob" // 'image' - return base64, 'canvas' - return canvas
	const overridedPluginOptions = {
		mimeType: "image/jpeg",
		domtoimageOptions: {
			cacheBust: true,
		},
	}
	simpleMapScreenshoter
		.takeScreen(format, overridedPluginOptions)
		.then((blob) => {
			FileSaver.saveAs(blob, "screen.png")
		})
		.catch((e) => {
			console.error(e)
		})
}
const toggleFullscreen = () => {
	map?.toggleFullscreen?.()
}

const disableScrollAndDrag = () => {
	map?.scrollWheelZoom?.disable?.()
	map?.dragging?.disable?.()
}
const setCenter = ({ lat, lng }) => {
	map?.setView?.([lat, lng], 13)
}
const enableScrollAndDrag = () => {
	map?.scrollWheelZoom?.enable?.()
	map?.dragging?.enable?.()
}

const setView = ({ lat, lng, zoom = 13 }) => {
	map?.setView([lat, lng], zoom)
}
const getDistanceBetweenTwoMarker = (latlng1, latlng2) => {
	if (!latlng1 || !latlng2) {
		return 0
	}
	const lat1 = latlng1.lat
	const lng1 = latlng1.lng
	const lat2 = latlng2.lat
	const lng2 = latlng2.lng
	const R = 6371 // Radius of the earth in km
	const dLat = (lat2 - lat1) * (Math.PI / 180) // deg2rad below
	const dLon = (lng2 - lng1) * (Math.PI / 180)
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(lat1 * (Math.PI / 180)) *
			Math.cos(lat2 * (Math.PI / 180)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const d = R * c // Distance in km

	return Math.round(d * 1000) // Distance in m
}

export default {
	getDistanceBetweenTwoMarker,
	setView,
	getMap,
	registerMap,
	disableScrollAndDrag,
	enableScrollAndDrag,
	registerDrawTool,
	clearLayers,
	drawPolygon,
	drawRectangle,
	drawLine,
	drawCircle,
	fitGeoJSONBounds,
	toggleFullscreen,
	takeScreen,
	drawMarker,
	setCenter,
	removeLastPoint,
}
