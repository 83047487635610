import ListIcon from "@mui/icons-material/List"
import {
	Checkbox,
	IconButton,
	ListItemButton,
	ListItemText,
} from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useDaybookState from "@/hooks/useMapState"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import { setPanel } from "@/redux-toolkit/common/reducer"

import { JSON_SCHEMAS, MULTI_POLYGONS } from "@/redux-toolkit/time/constants"
import Debug from "debug"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import getImage from "@/utils/getImage"
import { DAYBOOK_LIST } from "../PanelCarto"

// app:javascript:utils:panels:panelCarto:NestedJsonSchemaDaybookCard.tsx
const debug = Debug(
	"app:javascript:utils:panels:panelCarto:NestedJsonSchemaDaybookCard",
)
debug.log = console.log.bind(console)
const StyledListItemButton2 = styled(ListItemButton)<{ $color: string }>`
    display: flex !important;
    gap: 0.5rem !important;
    padding: 0.5rem !important;
    margin-left: 1em !important;
    & .MuiListItemText-primary {
        font-size: var(--font-size-small) !important;
    }
`
const NestedJsonSchemaDaybookCard = ({
	itemJsonSchema,
	color,
	noListIcon = false,
}) => {
	const dispatch = useDispatch()
	const points = usePoints()
	const multiPolygons = useMultiPolygons()
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const {
		coucheJsonSchemas,
		coucheMultiPolygons,
		addCoucheJsonSchemas,
		addCoucheMultiPolygons,
		removeCoucheJsonSchemas,
		removeCoucheMultiPolygons,
	} = useDaybookState()
	let checked

	const onClickMultiPolygon = (polygonId, checked) => {
		dispatch(
			checked
				? removeCoucheMultiPolygons(polygonId)
				: addCoucheMultiPolygons({
						id: polygonId,
						priority: multiPolygons[polygonId]?.custom_props?.priority,
				  }),
		)
	}

	const onClickPoints = (jsonSchemaId, checked) => {
		dispatch(
			checked
				? removeCoucheJsonSchemas(jsonSchemaId)
				: addCoucheJsonSchemas({ id: jsonSchemaId }),
		)
	}

	switch (itemJsonSchema.type) {
		case JSON_SCHEMAS:
			checked = Boolean(coucheJsonSchemas?.[itemJsonSchema.id])
			return (
				<StyledListItemButton2
					$color={color}
					key={itemJsonSchema.id}
					onClick={() => onClickPoints(itemJsonSchema.id, checked)}
				>
					<Checkbox
						onChange={() => onClickPoints(itemJsonSchema.id, checked)}
						checked={checked}
					/>
					<img
						src={getImage(jsonSchemaWithStatics[itemJsonSchema.id].imgId)}
						width={35}
						height={35}
					/>
					<ListItemText
						primary={jsonSchemaWithStatics[itemJsonSchema.id].title}
						secondary={
							Object.values(points).filter(
								(point) => point.jsonschema_id === itemJsonSchema.id,
							).length + " données"
						}
					/>
					{!noListIcon && (
						<IconButton
							onClick={(e) => {
								e.stopPropagation()
								dispatch(
									setPanel({
										type: DAYBOOK_LIST,
										jsonschema_id: itemJsonSchema.id,
									}),
								)
							}}
						>
							<ListIcon />
						</IconButton>
					)}
				</StyledListItemButton2>
			)
		case MULTI_POLYGONS:
			checked = Boolean(coucheMultiPolygons?.[itemJsonSchema.id])
			return (
				<StyledListItemButton2
					$color={color}
					key={itemJsonSchema.id}
					onClick={() => onClickMultiPolygon(itemJsonSchema.id, checked)}
				>
					<Checkbox
						onChange={() => onClickMultiPolygon(itemJsonSchema.id, checked)}
						checked={checked}
					/>
					<MultiPolygonColorSquare
						multiPolygon={multiPolygons[itemJsonSchema.id]}
					/>
					<ListItemText primary={itemJsonSchema.title} />
				</StyledListItemButton2>
			)
	}
}

export default NestedJsonSchemaDaybookCard
