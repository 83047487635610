import { Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import { isMobile } from "react-device-detect"
import { useController, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import NewStyledInputLayout from "./formUtils/NewStyledInputLayout"
import _ from "lodash"

const SFlex = styled(Flex)<{ twoColumns?: boolean }>`
    margin-left: 1rem;
    margin-top: 0.75rem;
	flex-wrap: wrap;
    ${
			isMobile &&
			css`
        flex-direction: column;
        align-items: flex-start;
    `
		}

    ${({ twoColumns }) =>
			twoColumns &&
			css`
            flex-wrap: wrap;
            & > * {
                flex-basis: max(45%, 300px);
                flex-shrink: 1;
                flex-grow: 1;
            }
        `}
`

export const SFormControlLabel = styled(FormControlLabel)<{
	$readOnly: boolean
}>`
    ${({ $readOnly }) =>
			$readOnly &&
			css`
            pointer-events: none;
        `}
`

const FCheckboxes = ({
	name,
	label = undefined,
	options,
	disabled = false,
	readOnly = false,
	twoColumns = false,
	directionColumn = false,
	noBorder = false,
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: [],
	})
	let correctedValue = value
	if (_.isEmpty(value)) {
		correctedValue = []
	} else {
		correctedValue = value ? value.filter((item) => options.includes(item)) : []
	}

	return (
		<NewStyledInputLayout
			label={label}
			error={Boolean(error)}
			helperText={error?.message}
			empty={false}
			disabled={disabled}
			noBorder={noBorder}
		>
			<SFlex
				twoColumns={twoColumns}
				tabIndex={0}
				ref={ref}
				directionColumn={directionColumn}
				alignItemsStart
			>
				{options.map((option) => (
					<SFormControlLabel
						$readOnly={readOnly}
						key={option}
						label={option}
						value={option}
						control={
							<Checkbox
								readOnly={readOnly}
								checked={correctedValue.includes(option)}
								onChange={() => {
									if (correctedValue.includes(option)) {
										const newValue = correctedValue.filter(
											(x: string) => x !== option,
										)
										onChange(newValue.length ? newValue : null)
									} else {
										onChange([...correctedValue, option])
									}
								}}
							/>
						}
					/>
				))}
			</SFlex>
		</NewStyledInputLayout>
	)
}

export default FCheckboxes
