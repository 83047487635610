import { createAsyncThunk } from "@reduxjs/toolkit"
import { SYNCHRO_MULTIPOLYGON } from "@/redux-toolkit/common/loadingKeys"
import { addLoadingKey, removeLoadingKey } from "@/redux-toolkit/common/reducer"
import {
	getMultiPolygon,
	getMultiPolygonsWithDeleted,
	synchronizeCreatedMultiPolygon,
	synchronizeDeletedMultiPolygon,
	synchronizeUpdatedMultiPolygon,
} from "@/redux-toolkit/data/multiPolygon/resources"
import ErrorService from "@/services/ErrorService"
import SynchroService from "../SynchroService"

const synchronize = async (dispatch, getState, withoutDelete = false) => {
	dispatch(addLoadingKey(SYNCHRO_MULTIPOLYGON))

	try {
		const multiPolygons = getState().data.multiPolygons

		const {
			toDeleteItems: toDeleteMultipolygons,
			toCreateItems: toCreateMultipolygons,
			toUpdateItems: toUpdateMultipolygons,
		} = SynchroService.getItemsByLocalStatus(multiPolygons)
		await Promise.all([
			...toCreateMultipolygons.map((multiPolygon) =>
				synchronizeCreatedMultiPolygon(dispatch, multiPolygon),
			),
			...toUpdateMultipolygons.map((multiPolygon) =>
				synchronizeUpdatedMultiPolygon(dispatch, multiPolygon),
			),
			...toDeleteMultipolygons.map((multiPolygon) =>
				synchronizeDeletedMultiPolygon(dispatch, multiPolygon),
			),
		])
		if (withoutDelete) {
			await getMultiPolygon(dispatch, getState)
		} else {
			await getMultiPolygonsWithDeleted(dispatch, getState)
		}
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation des couches cartographiques, message: ${error.message}`
		ErrorService.error({
			component: "SynchroMultiPolygonService:synchronize",
			message: errorMessage,
			dispatch,
		})
	} finally {
		dispatch(removeLoadingKey(SYNCHRO_MULTIPOLYGON))
	}
}

export const synchronizeAction = createAsyncThunk(
	"data/events/multiPolygon/synchronizeAction",
	async (_a, { dispatch, getState }) => {
		try {
			await synchronize(dispatch, getState, false)
		} catch (error) {
			const errorMessage = `Erreur lors de la synchronisation des couches cartographiques, message: ${error.message}`
			ErrorService.error({
				component: "SynchroMultiPolygonService:synchronizeAction",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export default { synchronize, synchronizeAction }
