import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectCommuneGeojson = createDeepEqualSelector(
	(state: any) => state.commune.geojson,
	(item: any) => item,
)

const useCommuneGeojson = () => {
	return useSelector(selectCommuneGeojson)
}

export default useCommuneGeojson
