import { createAsyncThunk } from "@reduxjs/toolkit"
import { JSON_SCHEMAS } from "@/redux-toolkit/time/constants"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import ErrorService from "@/services/ErrorService"
import JsonSchemaApi from "./JsonSchemaApi"
import { addJsonSchema, removeJsonSchema, setJsonSchema } from "./reducer"

export const getJsonSchemas = async (dispatch, state) => {
	try {
		const { jsonSchemas, serverDatetime } = await JsonSchemaApi.getJsonSchemas()

		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: JSON_SCHEMAS,
			}),
		)
		dispatch(setJsonSchema(jsonSchemas))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des points, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:getJsonSchemas",
			message: errorMessage,
			dispatch,
		})
	}
}

export const getPicrimJsonSchemas = createAsyncThunk(
	"picrim/getJsonSchemas",
	async (communeId: string, { dispatch }) => {
		try {
			const { json_schemas } = await JsonSchemaApi.getPicrimJsonSchemas(
				communeId,
			)

			dispatch(setJsonSchema(json_schemas))
		} catch (error) {
			console.error(error)
			throw new Error(
				`Erreur lors de la récupération des points, message d'erreur : ${error.message}`,
			)
		}
	},
)

export const deleteJsonSchema = createAsyncThunk(
	"data/jsonSchema/deleteJsonSchema",
	async (id: string, { dispatch }) => {
		try {
			await JsonSchemaApi.deleteJsonSchema(id)
			dispatch(removeJsonSchema(id))
		} catch (error) {
			const errorMessage = `Erreur lors de la création du formulaire dynamique`
			ErrorService.error({
				component: "resources:deleteJsonSchema",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const createJsonSchema = createAsyncThunk(
	"data/jsonSchema/createJsonSchema",
	async (jsonSchema: any, { dispatch }) => {
		try {
			const createdJsonSchema = await JsonSchemaApi.createJsonSchema(jsonSchema)
			dispatch(addJsonSchema(createdJsonSchema))
		} catch (error) {
			const errorMessage = `Erreur lors de la création du formulaire dynamique`
			ErrorService.error({
				component: "resources:createJsonSchema",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateJsonSchema = createAsyncThunk(
	"data/jsonSchema/updateJsonSchema",
	async (jsonSchema: any, { dispatch }) => {
		try {
			const updatedJsonSchema = await JsonSchemaApi.updateJsonSchema(jsonSchema)
			dispatch(addJsonSchema(JSON.parse(updatedJsonSchema.json_schema)))
		} catch (error) {
			const errorMessage = `Erreur lors de la mise a jour du formulaire dynamique`
			ErrorService.error({
				component: "resources:updateJsonSchema",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const toggleJsonSchemaPublic = createAsyncThunk(
	"data/jsonSchema/toggleJsonSchemaPublic",
	async (jsonSchema: any, { dispatch }) => {
		try {
			const toggledJsonSchemaPublic =
				await JsonSchemaApi.toggleJsonSchemaPublic(jsonSchema)
			dispatch(addJsonSchema(JSON.parse(toggledJsonSchemaPublic.json_schema)))
		} catch {
			const errorMessage = `Erreur lors de la mise a jour du champ 'public' du formulaire dynamique`
			ErrorService.error({
				component: "resources:updateJsonSchema",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const getAdminJsonSchemas = createAsyncThunk(
	"data/jsonSchema/getAdminJsonSchemas",
	async (args, { dispatch }) => {
		try {
			const { json_schemas: jsonSchemas } =
				await JsonSchemaApi.getJsonSchemasAdmin()

			dispatch(setJsonSchema(jsonSchemas))
		} catch (error) {
			const errorMessage = `Erreur lors de la récupération des formulaires dynamiques, message d'erreur : ${error.message}`
			ErrorService.error({
				component: "resources:getAdminJsonSchemas",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
