import { CardContent } from "@mui/material"
import { useCurrentPng } from "@/hooks/useCurrentPng"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { INCIDENT } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import Moment from "moment"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"
import styled from "styled-components"
import { Card } from "@/utils/components/style/card"
import { H6 } from "@/utils/components/style/header"
import FSelect from "@/utils/form/FSelect"
import IPoint from "@/utils/types/IPoint"

window.Moment = Moment

const StyledResponsiveContainer = styled(ResponsiveContainer)`
				height: 200px !important;
				width: calc(
								67vw - var(--width-navbar) - var(--width-navbar-additional-width)
				) !important;
				@media screen and (max-width: 1200px) {
								width: calc(85vw - var(--width-navbar)) !important;
				}
`
const DisplayScrollbar = styled(CardContent)`
				margin-top: 0.5rem;
				overflow: auto;
				overflow-y: hidden;
				padding: 0 !important;
`

const SCard = styled(Card)`
				width: 100%;
				padding: 0.5rem;
`
const ContainerFSelect = styled.div`
	display: grid;
	place-items: end;
	& > .MuiAutocomplete-root {
		width: 150px;
	}
`
const ToolTipContainer = styled.div`
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px; // Adjusted gap for better visual separation
    color: var(--primary500);

    .tooltip-time {
        margin: 0;
        font-size: 16px; // Size that ensures legibility without overpowering the value
        color: var(--primary500); // A color that stands out yet is not as dominant as the value
        font-weight: 500; // Medium weight for subtle emphasis
    }

    .tooltip-value {
        margin: 0;
        font-size: 24px; // Larger font size for primary emphasis
        font-weight: bold; // Bold weight to highlight numerical value
        color: var(--primary); // A color that draws attention to the value
    }

    .tooltip-description {
        margin: 0;
        font-size: 14px; // Smaller font size to make it less prominent
        color: var(--primary600); // Subdued color for less emphasis
        font-weight: normal; // Regular weight for secondary information
    }
`

const sortByDateDebut = (points: IPoint[]) => {
	const sortedPoints = Object.values(points).sort((a: IPoint, b: IPoint) => {
		const dateA = Moment(
			_.get(a, "geojson.properties.Date de début", a.created_at),
		)
		const dateB = Moment(
			_.get(b, "geojson.properties.Date de début", b.created_at),
		)
		if (dateA.isBefore(dateB)) {
			return -1
		}
		if (dateA.isAfter(dateB)) {
			return 1
		}
		return 0
	})
	return sortedPoints
}
const DaybookChart = ({ setChartBlob, enableAnimation = true }) => {
	const selectedEvent = useSelectedEvent()
	const points = usePoints()
	const [getPng, { ref: areaRef, isLoading, ready, setRef }] = useCurrentPng(
		"chart",
		setChartBlob,
	)
	const methods = useForm({
		defaultValues: {
			timeInterval: "1 heures",
		},
	})
	const { setValue, watch } = methods

	const concernedPoints = useMemo(
		() =>
			Object.values(points).filter(
				(point) =>
					point.jsonschema_id === INCIDENT &&
					point.event_id === selectedEvent?.id,
			),
		[points, selectedEvent],
	)

	// Calcul de la différence de temps pour ajuster les options d'intervalle
	const timeDifference = useMemo(() => {
		if (concernedPoints.length < 2) return 0
		const sortedPoints = sortByDateDebut(concernedPoints)
		const earliest = Moment(
			_.get(
				sortedPoints[0],
				"geojson.properties.Date de début",
				sortedPoints[0].created_at,
			),
		)
		const latest = Moment(
			_.get(
				sortedPoints[sortedPoints.length - 1],
				"geojson.properties.Date de début",
				sortedPoints[sortedPoints.length - 1].created_at,
			),
		)

		return latest.diff(earliest, "hours")
	}, [concernedPoints])

	// Détermination dynamique des options d'intervalle et de l'option initiale
	const intervalOptions = useMemo(() => {
		if (timeDifference === 0) {
			setValue("timeInterval", "1 minute")
			return ["1 minute", "3 minutes", "5 minutes"]
		}
		if (timeDifference <= 1) {
			setValue("timeInterval", "5 minutes")
			return [
				"1 minute",
				"3 minutes",
				"5 minutes",
				"10 minutes",
				"15 minutes",
				"30 minutes",
				"45 minutes",
			]
		}
		if (timeDifference <= 12) {
			setValue("timeInterval", "15 minutes")
			return [
				"5 minutes",
				"10 minutes",
				"15 minutes",
				"30 minutes",
				"1 heure",
				"2 heures",
				"4 heures",
				"6 heures",
			]
		}

		if (timeDifference <= 24) {
			setValue("timeInterval", "30 minutes")
			return [
				"5 minutes",
				"10 minutes",
				"15 minutes",
				"30 minutes",
				"1 heure",
				"2 heures",
				"4 heures",
				"6 heures",
			]
		}
		if (timeDifference <= 72) {
			setValue("timeInterval", "2 heures")
			return [
				"15 minutes",
				"30 minutes",
				"1 heure",
				"2 heures",
				"4 heures",
				"6 heures",
				"12 heures",
			]
		}
		setValue("timeInterval", "6 heures")
		return [
			"30 minutes",
			"1 heure",
			"2 heures",
			"4 heures",
			"6 heures",
			"12 heures",
			"24 heures",
		]
	}, [timeDifference])

	const timeInterval = watch("timeInterval")
	const TIME_DIFF = useMemo(() => {
		// Updated logic to parse both minute and hour intervals
		if (!timeInterval) return 360 // Default to 1 hour if no interval is selected
		const match = timeInterval.match(/^(\d+)\s(minutes|heures)$/)
		if (!match) return 360 // Default to 1 hour if parsing fails
		const [, amount, unit] = match
		if (amount === "1") return 360
		return unit === "heures" ? parseInt(amount, 10) * 60 : parseInt(amount, 10) // Convert hours to minutes
	}, [timeInterval])

	const chartData = useMemo(() => {
		if (concernedPoints.length === 0) return []

		// Sort points by date to find the earliest and latest dates
		const sortedPoints = sortByDateDebut(concernedPoints)
		const earliestIncidentDate = Moment(
			_.get(
				sortedPoints[0],
				"geojson.properties.Date de début",
				sortedPoints[0].created_at,
			),
		)
		const latestIncidentDate = Moment(
			_.get(
				sortedPoints[sortedPoints.length - 1],
				"geojson.properties.Date de début",
				sortedPoints[sortedPoints.length - 1].created_at,
			),
		)

		// Determine the time range span using the TIME_DIFF (which is now in minutes)
		const timeSlots = []
		let currentTime = Moment(earliestIncidentDate)
		while (
			currentTime.isBefore(latestIncidentDate) ||
			currentTime.isSame(latestIncidentDate, "minute")
		) {
			timeSlots.push(currentTime.format("DD/MM/YY HH:mm"))
			currentTime.add(TIME_DIFF, "minutes") // Adding intervals in minutes
		}

		// Initialize results object with time slots
		const results = timeSlots.reduce((acc, time) => ({ ...acc, [time]: 0 }), {})

		// Aggregate incidents into the relevant time slots
		sortedPoints.forEach((point) => {
			const incidentDate = Moment(
				_.get(point, "geojson.properties.Date de début", point.created_at),
			)

			// Find the closest time slot for each incident
			const closestTimeSlot = timeSlots.find((slot) => {
				const slotStart = Moment(slot, "DD/MM/YY HH:mm")
				const slotEnd = Moment(slot, "DD/MM/YY HH:mm").add(TIME_DIFF, "minutes")
				return (
					incidentDate.isSameOrAfter(slotStart) &&
					incidentDate.isBefore(slotEnd)
				)
			})

			if (closestTimeSlot) results[closestTimeSlot]++
		})

		// Convert results object into an array of objects for the chart
		return Object.entries(results).map(([key, value]) => ({
			time: key,
			value,
		}))
	}, [concernedPoints, TIME_DIFF])

	return (
		<FormProvider {...methods}>
			<SCard>
				<H6>Nombre d&apos;évènements par heure</H6>
				<ContainerFSelect>
					<FSelect
						name="timeInterval"
						label="Intervalle"
						options={intervalOptions}
						size="small"
					/>
				</ContainerFSelect>
				<DisplayScrollbar>
					<StyledResponsiveContainer>
						<AreaChart
							data={chartData}
							// @ts-ignore
							ref={setRef}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<XAxis
								dataKey="time"
								tickFormatter={(value) => {
									// Check if the time difference is less than 24 hours
									const formatString =
										timeDifference < 24 ? "HH:mm" : "DD/MM HH:mm"
									return Moment(value, "DD/MM/YY HH:mm").format(formatString)
								}}
							/>
							<YAxis dataKey="value" />
							<Tooltip
								content={({ payload, label }) => {
									if (payload && payload.length > 0) {
										const slotStartTime = Moment(label, "DD/MM/YY HH:mm")
										const displayFormat =
											TIME_DIFF < 60 ? "HH:mm" : "DD/MM HH:mm"
										const slotEndTime = slotStartTime
											.clone()
											.add(TIME_DIFF, "minutes")
											.format(displayFormat)

										return (
											<ToolTipContainer>
												<p className="tooltip-time">{`${slotStartTime.format(
													displayFormat,
												)} - ${slotEndTime}`}</p>{" "}
												{/* Modified time slot display */}
												<p className="tooltip-value">{payload[0].value}</p>{" "}
												{/* Number of events */}
												<p className="tooltip-description">Évènements</p>{" "}
												{/* Description */}
											</ToolTipContainer>
										)
									}
									return null
								}}
							/>

							<Area
								isAnimationActive={enableAnimation}
								type="monotone"
								dataKey="value"
								stroke="var(--primary500)"
								fill="var(--primary300)"
							/>
						</AreaChart>
					</StyledResponsiveContainer>
				</DisplayScrollbar>
			</SCard>
		</FormProvider>
	)
}
export default DaybookChart
