import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectCommunesCdc = createDeepEqualSelector(
	(state: any) => state.cdc,
	(cdc: any) => cdc,
)

const useCdc = () => {
	return useSelector(selectCommunesCdc)
}

export default useCdc
