import axios from "axios"

const getMappings = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await axios.get(`/api/mappings?lastSynchro=${lastSynchro}`)
	return result.data
}
const updateMappingLastImport = async (id) => {
	const result = await axios.put(`/api/mappings/${id}`)
	return result.data
}

const getMappingsAdmin = async () => {
	const result = await axios.get(`/api/mappings/admin`)
	return result.data
}

const createMappingAdmin = async (mapping) => {
	const result = await axios.post(`/api/mappings/admin`, { data: mapping })
	return result.data
}

const updateMappingAdmin = async (mapping) => {
	const result = await axios.put(`/api/mappings/admin/${mapping.id}`, {
		data: mapping,
	})
	return result.data
}

const deleteMappingAdmin = async (id) => {
	const result = await axios.delete(`/api/mappings/admin/${id}`)
	return result.data
}

export default {
	getMappings,
	updateMappingLastImport,
	getMappingsAdmin,
	createMappingAdmin,
	updateMappingAdmin,
	deleteMappingAdmin,
}
