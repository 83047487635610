import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    background-color: var(--primary50);
    border-radius: 2px;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    gap: 6px;
`

const ItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    min-width: 0;
`

const ActionItemsWrapper = ({ Icon, children }) => {
	return (
		<Wrapper>
			<Icon fontSize="small" />
			<ItemsWrapper>{children}</ItemsWrapper>
		</Wrapper>
	)
}

export default ActionItemsWrapper
