import { View } from "@react-pdf/renderer"
import React from "react"

const PRow = ({ children, ...props }) => {
	return (
		<View
			{...props}
			style={{ ...{ flexDirection: "row" }, ...props.style }}
			wrap={false}
		>
			{children}
		</View>
	)
}

export default PRow
