import { useContext, useEffect } from "react"
import { ParentSubmitContext } from "./ParentSubmitContext"

const useParentSubmit = (handleSubmit) => {
	const { shouldSubmit, setShouldSubmit, onSubmit } =
		useContext(ParentSubmitContext)
	useEffect(() => {
		if (shouldSubmit) {
			setShouldSubmit(false)
			handleSubmit(onSubmit)()
		}
	}, [shouldSubmit])
}

export default useParentSubmit
