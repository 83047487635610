import useSelectedEvent from "@/hooks/useSelectedEvent"
import { createDrawMultiPolygon } from "@/redux-toolkit/daybook/resources"
import Debug from "debug"
import _ from "lodash"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { Flex } from "utils/components/style/flex"
import FPolygonColor from "utils/form/carto/FPolygonColor"
import FUploadGeojson from "utils/form/upload/FUploadGeojson"
import SuperModal from "utils/modals/SuperModal"
import { v4 as uuidv4 } from "uuid"
import styled from "styled-components"
import FText from "utils/form/FText"
import NSpacer from "utils/components/style/NSpacer"
const debug = Debug(
	"app:javascript:utils:panels:panelCarto:import:ModalImportGeojson",
)
debug.log = console.log.bind(console)

const DetailsContainer = styled.div<{ $shouldExpand: boolean }>`
    max-height: ${({ $shouldExpand }) => ($shouldExpand ? "100vh" : "0")};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    width: 100%;
`

const ModalImportGeojson = ({ isOpen, onClose }) => {
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const methods = useForm({
		defaultValues: {
			geojson: {},
			name: "Nouvelle couche",
			fillColor: "#000000",
			color: "#000000",
			weight: 1,
			opacity: 1,
			fillOpacity: 0.5,
			stroke: true,
			fill: true,
		},
		mode: "onChange",
	})
	const values = methods.watch()
	const geojson = values.geojson
	const onSubmit = (value) => {
		const { geojson, name, ...custom_props } = value
		dispatch(
			createDrawMultiPolygon({
				id: uuidv4(),
				name: name,
				custom_props: {
					style: {
						...custom_props,
						fill: true,
						stroke: true,
					},
				},
				event_id: selectedEvent?.id,
				geojson: JSON.parse(geojson),
			}),
		)
		onClose()
		methods.reset()
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				small
				isOpen={isOpen}
				onClose={onClose}
				title={"Import GeoJSON"}
				onClick={methods.handleSubmit(onSubmit)}
				disableSave={_.isEmpty(geojson)}
			>
				<Flex directionColumn gap="1rem" fullWidth alignItemsInitial>
					<FUploadGeojson name="geojson" label="GeoJSON" />
					<DetailsContainer $shouldExpand={!_.isEmpty(geojson)}>
						<NSpacer />
						<FText name="name" label="Nom de la couche" />
						<NSpacer />
						<FPolygonColor showPreview />
					</DetailsContainer>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalImportGeojson
