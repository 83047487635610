import { SYNCHRO_POINT } from "@/redux-toolkit/common/loadingKeys"
import { addLoadingKey, removeLoadingKey } from "@/redux-toolkit/common/reducer"
import { getMappings } from "@/redux-toolkit/data/mappings/ressources"
import { createAsyncThunk } from "@reduxjs/toolkit"
import {
	getPoints,
	getPointsWithDeleted,
	synchronizeCreatedPoint,
	synchronizeDeletedPoint,
	synchronizeUpdatedPoint,
} from "@/redux-toolkit/data/points/resources"
import ErrorService from "@/services/ErrorService"
import SynchroService from "../SynchroService"
import { toast } from "react-toastify"
import _ from "lodash"
import Moment from "moment"
import { updateLocalEvent } from "@/redux-toolkit/data/events/reducer"

async function processArrayInBatches(array, batchSize, callback) {
	for (let i = 0; i < array.length; i += batchSize) {
		const batch = array.slice(i, i + batchSize)
		await callback(batch)
	}
}

const synchronize = async (
	dispatch,
	getState,
	withoutDelete = false,
	callBack = () => {},
) => {
	dispatch(addLoadingKey(SYNCHRO_POINT))

	try {
		if (withoutDelete) {
			await getPoints(dispatch, getState)
		} else {
			await getPointsWithDeleted(dispatch, getState)
		}
		const state = getState()
		const points = state.data.points
		const {
			toDeleteItems: toDeletePoints,
			toCreateItems: toCreatePoints,
			toUpdateItems: toUpdatePoints,
		} = SynchroService.getItemsByLocalStatus(points)

		await processArrayInBatches(toCreatePoints, 5, async (batch) => {
			await Promise.all(
				batch.map((point) => synchronizeCreatedPoint(dispatch, point)),
			)
		})

		await processArrayInBatches(toUpdatePoints, 5, async (batch) => {
			await Promise.all(
				batch.map((point) => synchronizeUpdatedPoint(dispatch, point)),
			)
		})

		await processArrayInBatches(toDeletePoints, 5, async (batch) => {
			await Promise.all(
				batch.map((point) => synchronizeDeletedPoint(dispatch, point)),
			)
		})
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation des points, message: ${error.message}`
		ErrorService.error({
			component: "SynchroPointService:synchronize",
			message: errorMessage,
			dispatch,
		})
	} finally {
		dispatch(removeLoadingKey(SYNCHRO_POINT))
		callBack?.()
	}
}

const synchronizePointAction = createAsyncThunk(
	"data/points/synchronizePointAction",
	async (
		{ withoutDelete = false, callBack = () => {} }: any,
		{ dispatch, getState },
	) => {
		return await synchronize(dispatch, getState, withoutDelete, callBack)
	},
)

export default { synchronize, synchronizePointAction }
