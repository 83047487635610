import { addLoadingKey, removeLoadingKey } from "@/redux-toolkit/common/reducer"
import { SYNCHRO_FICHE } from "@/redux-toolkit/common/loadingKeys"
import {
	getFiches,
	getFichesWithDeleted,
	synchronizeCreatedFiche,
	synchronizeDeletedFiche,
	synchronizeUpdatedFiche,
} from "@/redux-toolkit/data/fiche/resources"
import ErrorService from "@/services/ErrorService"
import SynchroService from "../SynchroService"
import Debug from "debug"

const debug = Debug("components:services:synchro:SynchroFichesService")
// Debug.enable('components:services:synchro:SynchroFichesService')
debug.log = console.log.bind(console) // don't forget to bind to console!
const synchronize = async (dispatch, getState, withoutDelete = false) => {
	debug("Beginning synchronize")
	dispatch(addLoadingKey(SYNCHRO_FICHE))

	try {
		const fiches = getState().data.fiches

		const {
			toDeleteItems: toDeleteFiches,
			toCreateItems: toCreateFiches,
			toUpdateItems: toUpdateFiches,
		} = SynchroService.getItemsByLocalStatus(fiches)

		await Promise.all([
			...toCreateFiches.map((point) =>
				synchronizeCreatedFiche(dispatch, point),
			),
			...toUpdateFiches.map((point) =>
				synchronizeUpdatedFiche(dispatch, point),
			),
			...toDeleteFiches.map((point) =>
				synchronizeDeletedFiche(dispatch, point),
			),
		])
		debug("local synchronized, beginning getFiches")
		if (withoutDelete) {
			await getFiches(dispatch, getState)
		} else {
			await getFichesWithDeleted(dispatch, getState)
		}
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation des fiches, message: ${error.message}`
		ErrorService.error({
			component: "SynchroFichesService:synchronize",
			message: errorMessage,
			dispatch,
		})
	} finally {
		dispatch(removeLoadingKey(SYNCHRO_FICHE))
		debug("end synchronize")
	}
}

export default { synchronize }
