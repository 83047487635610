import styled, { css } from "styled-components"

export const DiffSpan = styled.span<{ add?: boolean }>`
    padding: 1px 4px;
    border-radius: 2px;
    ${({ add }) =>
			add
				? css`
                  background-color: var(--green150);
                  color: var(--green700);
              `
				: css`
                  background-color: var(--red200);
                  color: var(--neutral700);
                  text-decoration: line-through;
              `}
`
export const TextAlign = styled.div<{
	align: "right" | "left"
	$fontSize: string | undefined
}>`
    text-align: ${({ align }) => align};
    word-wrap: break-word;
    display: inline-block;
    max-width: 100%;
    ${({ $fontSize }) =>
			$fontSize &&
			css`
            font-size: ${$fontSize};
        `}
`
