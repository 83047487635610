import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"

const initialState = {}

const pdfSlice = createSlice({
	name: "data/pdf",
	initialState,
	reducers: {
		addPdf(state, { payload: pdf }) {
			state[pdf.id] = pdf
		},
		setPdfs(state, { payload: pdfs }) {
			Object.keys(state).forEach((key) => delete state[key])
			pdfs.forEach((pdf) => {
				state[pdf.id] = pdf
			})
		},
		removePdf(state, { payload: id }) {
			delete state[id]
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default pdfSlice.reducer
export const { addPdf, setPdfs, removePdf } = pdfSlice.actions
