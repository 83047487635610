import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Debug from "debug"
import React from "react"
import styled from "styled-components"
import JsonSchemaCard from "./JsonSchemaTreeCard"

// app:javascript:utils:panels:panelCarto:NestedListChildren.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:NestedListChildren")
debug.log = console.log.bind(console)

const StyledList = styled(List)<{ $color: string }>`
    padding: 0px !important;
    border-left: 2px solid ${({ $color }) => $color} !important;
    margin-left: 2em !important;
`
const StyledListItemButton = styled(ListItemButton)<{ $color: string }>`
    padding-left: 2rem !important;
    margin-left: 0.5rem !important;
`
const StyledListItemText = styled(ListItemText)`
    margin-left: -1.5rem !important;
`
const LengthIndicator = styled.div<{ $color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 5px;
    min-width: 20px;
    font-size: 12px;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    margin: 0 2px;
    background-color: ${({ $color }) => ($color ? $color : "#757575")};
`

export interface NestedListChildrenProps {
	subCategory: { title: string; items: Array<any> }
	color?: string
	setPcsCategory?: React.Dispatch<any>
	actionOnFinalSelect?: () => void
}

interface NestedListChildrenExtendedProps extends NestedListChildrenProps {
	open: boolean
	setOpen: React.Dispatch<any>
}

const NestedListChildren = ({
	open,
	setOpen,
	subCategory,
	color = "neutral",
	setPcsCategory = undefined,
	actionOnFinalSelect = undefined,
}: NestedListChildrenExtendedProps) => {
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<>
			<StyledList $color={color}>
				<StyledListItemButton onClick={handleClick} $color={color}>
					<StyledListItemText primary={subCategory.title} />
					<LengthIndicator $color={color}>
						{subCategory?.items?.length ?? 0}
					</LengthIndicator>
					{open ? <ExpandLess /> : <ExpandMore />}
				</StyledListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					{subCategory.items.map((itemJsonSchema) => (
						<JsonSchemaCard
							key={itemJsonSchema.id}
							itemJsonSchema={itemJsonSchema}
							color={color}
							setPcsCategory={setPcsCategory}
							actionOnFinalSelect={actionOnFinalSelect}
						/>
					))}
				</Collapse>
			</StyledList>
		</>
	)
}

export default NestedListChildren
