import React from "react"
import PageContainer from "utils/components/layout/PageContainer"
import TitleHeader from "utils/components/TitleHeader"

export const JSONSCHEMA_ERROR_PERMISSION =
	"Le formulaire demandé est soit innexistant, ou bien vous ne possédez pas les permissions requises pour le lire."
export const TOOL_ERROR_PERMISSION =
	"Vous ne possédez pas les permissions requises pour utiliser cet outil."
export const FEATURE_ERROR_PERMISSION =
	"Vous ne possédez pas les permissions requises pour utiliser cette fonctionnalité."
export const BACK_TITLE_HOME = "Retour à l'accueil"
export const BACK_TITLE_CUSTOMDATA = "Retour aux données communales"
export const BACK_PATH_HOME = "/home"
export const BACK_PATH_CUSTOMDATA = "/customdata/my_forms"

const ErrorPermissions = ({
	errorMessage = TOOL_ERROR_PERMISSION,
	backTitle = BACK_TITLE_HOME,
	backUrl = BACK_PATH_HOME,
	noContainer = false,
}) => {
	return (
		<>
			{noContainer && (
				<TitleHeader
					title={errorMessage}
					backArrowTitle={backTitle}
					backArrowUrl={backUrl}
				/>
			)}
			{!noContainer && (
				<PageContainer>
					<TitleHeader
						title={errorMessage}
						backArrowTitle={backTitle}
						backArrowUrl={backUrl}
					/>
				</PageContainer>
			)}
		</>
	)
}

export default ErrorPermissions
