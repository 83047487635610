import PersonAddIcon from "@mui/icons-material/PersonAdd"
import { Checkbox, IconButton, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import useHumans from "@/hooks/useHumans"
import useIsInterco from "@/hooks/useIsInterco"
import React, { useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useBoolean } from "react-use"
import { Flex } from "@/utils/components/style/flex"
import HumanService from "@/utils/services/HumanService"
import DraggableHumanTags from "./FSelectHumans/DraggableHumanTags"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import FModalHumans from "./modal/FModalHumans"
import styled from "styled-components"
import _ from "lodash"
import Defer from "@/utils/Defer"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const StyledLi = styled.li`
    padding: 0 !important;
    margin-left: 3px;
`

const ListboxComponent = ({ children, ...props }) => {
	return (
		<div {...props}>
			<Defer chunkSize={10}>{children}</Defer>
		</div>
	)
}

const FSelectHumans = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
	customModalTitle = undefined,
	multiple = true,
	restrainedHumanIds = undefined,
}) => {
	const [isOpenModal, setIsOpenModal] = useBoolean(false)
	const [acMenuOpen, setAcMenuOpen] = useState(false)
	const humans = useHumans()
	const isInterco = useIsInterco()
	const { control } = useFormContext()

	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	const options = useMemo(() => {
		if (!_.isEmpty(restrainedHumanIds)) {
			return Object.values(humans)
				.map((human) => human.id)
				.filter((humanId) => restrainedHumanIds.includes(humanId))
		} else {
			return Object.values(humans).map((human) => human.id)
		}
	}, [humans, value, restrainedHumanIds])

	const correctedValue = value
		? value.filter((h: string) => options.includes(h))
		: []

	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...correctedValue]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onChange(reordered)
	}

	return (
		<>
			<Autocomplete
				ListboxComponent={ListboxComponent}
				disableCloseOnSelect
				open={acMenuOpen}
				onOpen={() => setAcMenuOpen(readOnly ? false : true)}
				onClose={() => setAcMenuOpen(false)}
				onKeyDown={(event) => {
					if (event.key === "Backspace" && readOnly) {
						//@ts-ignore
						event.defaultMuiPrevented = true
					}
				}}
				fullWidth
				disableClearable
				multiple={multiple}
				noOptionsText={"Aucune donnée correspondante"}
				value={correctedValue}
				options={options}
				onChange={(e, newValue: any) => {
					onChange(newValue)
				}}
				disabled={disabled}
				renderOption={(props, option, { selected }) => {
					const human = humans[option]
					return (
						<StyledLi {...props} key={option}>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								checked={selected}
							/>
							<Flex gap="1rem">
								{HumanService.displayTypeAsChip(human, true, isInterco)}
								{HumanService.displayFullName(human)}
							</Flex>
						</StyledLi>
					)
				}}
				getOptionLabel={(option) =>
					HumanService.displayFullName(humans[option])
				}
				renderTags={(value: readonly string[], getTagProps) => (
					<DraggableHumanTags
						getTagProps={getTagProps}
						value={value}
						onDragEnd={onDragEnd}
						readOnly={readOnly}
						disabled={disabled}
					/>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Sélectionner des personnes"
						inputRef={ref}
						error={Boolean(error)}
						helperText={error?.message}
						label={label}
						InputProps={{
							...params.InputProps,
							readOnly,
							endAdornment: readOnly ? undefined : (
								<>
									{params.InputProps.endAdornment}
									<IconButton
										onClick={() => setIsOpenModal(true)}
										disabled={disabled}
									>
										<PersonAddIcon />
									</IconButton>
								</>
							),
						}}
					/>
				)}
			/>
			<FModalHumans
				multiple
				onChange={onChange}
				value={correctedValue}
				isOpen={isOpenModal}
				onClose={() => setIsOpenModal(false)}
				customTitle={customModalTitle}
			/>
		</>
	)
}

export default FSelectHumans
