import { useSelector } from "react-redux"
import SynchroService from "@/services/SynchroService"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IPoint from "utils/types/IPoint"
import { CARE, STOCK_PLACE } from "utils/services/StaticFormsService"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(points) => SynchroService.notDeleted(points),
)

const useAllStockPlaces: () => { [key: string]: IPoint } = () => {
	const points = useSelector(selectPoints) as IPoint[]
	const stockPlaces = {}
	Object.values(points)
		.filter(
			(point) =>
				point["jsonschema_id"] === STOCK_PLACE.id ||
				point["jsonschema_id"] === CARE.id,
		)
		.forEach((point) => {
			stockPlaces[point.id] = point
		})
	return stockPlaces
}

export default useAllStockPlaces
