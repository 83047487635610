import React from "react"
import { useSelector } from "react-redux"
import Debug from "debug"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { Flex } from "utils/components/style/flex"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { Title } from "@/pages/admin/formulaire_dynamique/MyForms/components/components/ErrorsSolverItem"
import { Subtitle } from "utils/components/style/text"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import EditStylePolygon from "./EditStylePolygon"

// app:javascript:utils:panels:panelCarto:PanelStyleEditorPolygon.tsx
const debug = Debug(
	"app:javascript:utils:panels:panelCarto:PanelStyleEditorPolygon",
)
debug.log = console.log.bind(console)

const PanelStyleEditorPolygon = ({
	providedMultiPolygonId = undefined,
	onLeave = undefined,
	leaveButtonTitle = "retour",
}: {
	providedMultiPolygonId?: string
	onLeave?: () => void
	leaveButtonTitle?: string
}) => {
	const { entityId } = useSelector((state: any) => state.common.panel)
	const polygonId = providedMultiPolygonId ?? entityId

	const multiPolygons = useMultiPolygons()
	const multiPolygonName = multiPolygons[polygonId].name
	return (
		<>
			<Flex fullWidth directionColumn alignItemsStart padding="1rem" gap="1rem">
				{onLeave && (
					<Flex gap="1rem">
						<PrimaryOutlinedButton startIcon={<ArrowBack />} onClick={onLeave}>
							{leaveButtonTitle}
						</PrimaryOutlinedButton>
					</Flex>
				)}
				<Flex gap="1rem">
					<MultiPolygonColorSquare multiPolygon={multiPolygons[polygonId]} />
					<Flex directionColumn alignItemsStart>
						<Title>Modification du style</Title>
						<Subtitle>{multiPolygonName}</Subtitle>
					</Flex>
				</Flex>
				<EditStylePolygon entityId={polygonId} />
			</Flex>
		</>
	)
}

export default PanelStyleEditorPolygon
