import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectIsOnline = createDeepEqualSelector(
	(state: any) => state.common.isOnline,
	(isOnline: any) => isOnline,
)

const useIsOnline = () => {
	return useSelector(selectIsOnline)
}

export default useIsOnline
