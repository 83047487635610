import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"
import syncDataAdapter from "../syncDataAdapter"

const initialState = {}

const multiPolygonSlice = createSlice({
	name: "data/multiPolygon",
	initialState,
	reducers: {
		addMultipolygon(state, { payload: multiPolygon }) {
			state[multiPolygon.id] = multiPolygon
		},
		removeMultiPolygon(state, { payload: multiPolygon }) {
			delete state[multiPolygon.id]
		},
		setLoadedMultipolygon(state, { payload: id }) {
			state[id].loaded = true
		},
		createLocalMultipolygon: syncDataAdapter.createLocal,
		updateLocalMultipolygon: syncDataAdapter.updateLocal,
		deleteLocalMultipolygon: syncDataAdapter.deleteLocal,
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default multiPolygonSlice.reducer
export const {
	removeMultiPolygon,
	addMultipolygon,
	setLoadedMultipolygon,
	createLocalMultipolygon,
	updateLocalMultipolygon,
	deleteLocalMultipolygon,
} = multiPolygonSlice.actions
