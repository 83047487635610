import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"
import { sortNfdLowerCase } from "utils/formatNfdLowerCase"

const selectTags = createDeepEqualSelector(
	(state: any) => state.common.tags,
	(tags) => tags,
)

export interface ITag {
	category: "vehicules" | "stocks"
	label: string
}

const useTags = (tagType: ITag["category"]) => {
	const allTags = useSelector(selectTags) as ITag[]

	return allTags
		.filter((tag) => tag.category === tagType)
		.map((tag) => tag.label)
		.sort(sortNfdLowerCase)
}

export const useAllTags = () => {
	return useSelector(selectTags) as ITag[]
}

export default useTags
