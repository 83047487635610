import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import useCrisisOrgaWithOrder from "@/pages/maincourante/following/orga/utils/useCrisisOrgaWithOrder"
import React, { useState } from "react"
import styled from "styled-components"
import CelluleChip from "utils/components/chip/CelluleChip"
import ActionCelluleTooltip from "../components/ActionCelluleTooltip"
import ActionItemsWrapper from "../components/ActionItemsWrapper"

const ExpandLink = styled.div`
    cursor: pointer;
    font-weight: 500;
    font-size: 0.86em;
    margin-left: 5px;

    :hover {
        text-decoration: underline;
    }
`
const SLICE_NUMBER = 1
function ActionCellules({ celluleIds, withCrisisOrga, customWrapper = null }) {
	const { cellules, order } = useCrisisOrgaWithOrder()
	const [number, setNumber] = useState(SLICE_NUMBER)

	if (!celluleIds || !celluleIds.length) return null

	const concernedCellules = order
		.filter((item) => celluleIds.includes(item))
		.map((celluleId) => cellules[celluleId])
	const Wrapper = customWrapper
		? customWrapper
		: (props) => <ActionItemsWrapper Icon={PeopleAltIcon} {...props} />
	if (!concernedCellules.length) return null
	return (
		<Wrapper>
			{concernedCellules.slice(0, number).map((cellule) => {
				if (withCrisisOrga)
					return (
						<ActionCelluleTooltip cellule={cellule} key={cellule.id}>
							<CelluleChip cellule={cellule} />
						</ActionCelluleTooltip>
					)
				return <CelluleChip cellule={cellule} key={cellule.id} />
			})}
			{concernedCellules.length > SLICE_NUMBER &&
				(number ? (
					<ExpandLink onClick={() => setNumber(undefined)}>
						+{concernedCellules.length - SLICE_NUMBER} (cellules)
					</ExpandLink>
				) : (
					<ExpandLink onClick={() => setNumber(SLICE_NUMBER)}>
						voir moins
					</ExpandLink>
				))}
		</Wrapper>
	)
}

export default ActionCellules
