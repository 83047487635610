import React from "react"
import { useLocalStorage } from "react-use"
import CustomTabsBase, { CustomTabsBaseProps } from "./CustomTabsBase"

interface PersistentCustomTabsProps extends CustomTabsBaseProps {
	localStorageKey: string
}

const PersistentCustomTabs = ({
	localStorageKey,
	...props
}: PersistentCustomTabsProps) => {
	const [currentTab, setCurrentTab] = useLocalStorage(localStorageKey, 0)

	return (
		<CustomTabsBase
			currentTab={currentTab}
			setCurrentTab={setCurrentTab}
			{...props}
		/>
	)
}

export default PersistentCustomTabs
