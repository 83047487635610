import React, { useEffect, useMemo, useState } from "react"
import useUploadedCartos from "@/hooks/useUploadedCartos"
import { Flex } from "utils/components/style/flex"
import styled from "styled-components"
import Div from "utils/dnd/Div"
import CollapsibleList from "utils/components/list/CollapsibleList"
import { InputAdornment, List, TextField } from "@mui/material"
import _, { get } from "lodash"
import PanelPresetsItem from "../PanelPresetsItem"
import { useDispatch } from "react-redux"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import ModalUploadCartography from "utils/modals/ModalUploadCartography"
import useMapState from "@/hooks/useMapState"
import SaveIcon from "@mui/icons-material/Save"
import SearchIcon from "@mui/icons-material/Search"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import { addMultipolygon } from "@/redux-toolkit/data/multiPolygon/reducer"
import { resetCartoReducerAction } from "@/redux-toolkit/carto/resources"
import { resetDaybookReducerAction } from "@/redux-toolkit/daybook/resources"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { mapZoomDiff, maxZoom } from "utils/components/map/SuperMapContained"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_CARTOGRAPHY } from "@/redux-toolkit/userSettings/constants"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const mapChecked = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		role="img"
		width="32"
		height="32"
		preserveAspectRatio="xMidYMid meet"
		viewBox="0 0 24 24"
	>
		<path d="M18.25,22L15.5,19L16.66,17.82L18.25,19.41L21.84,15.82L23,17.23M20.5,3A0.5,0.5 0 0,1 21,3.5V13.36C20.36,13.13 19.69,13 19,13C17.46,13 16.06,13.6 15,14.56V7.1L9,5V16.9L13.04,18.3C13,18.54 13,18.77 13,19C13,19.46 13.06,19.92 13.16,20.36L9,18.9L3.66,20.97C3.59,21 3.55,21 3.5,21A0.5,0.5 0 0,1 3,20.5V5.38C3,5.15 3.16,4.97 3.35,4.9L9,3L15,5.1L20.33,3" />
	</svg>
)

const SFlex = styled(Flex)`
    background-color: var(--neutral50);
`
const SList = styled(List)`
    flex-grow: 1;
    width: 100%;
    padding: 0 !important;
    max-height: -webkit-fill-available;
    overflow-y: auto;
    & > .MuiListItemButton-root {
        border-top: 1px solid var(--neutral400) !important;
    }
    & > .MuiCollapse-root:last-child {
        border-bottom: 1px solid var(--neutral400) !important;
    }
    & > .MuiListItemButton-root:last-child {
        border-bottom: 1px solid var(--neutral400) !important;
    }
`
const SPaper = styled.div`
    background-color: white;
    padding: 1rem;
    width: 100%;
    max-width: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--cool-shadow);
`
const SearchBarContainer = styled.div`
    width: 100%;
    padding: 1rem;
    & > * {
        background-color: white !important;
    }
`

const PanelCartoConfigs = () => {
	const uploadedCartos = useUploadedCartos()
	const multiPolygons = useMultiPolygons()
	const selectedEvent = useSelectedEvent()
	const [memoizedMapStates, setMemoizedMapStates] = useState(undefined)
	const dispatch = useDispatch()
	const [cartoToPcs, setCartoToPcs] = useState({
		isOpen: false,
		action: undefined,
		carto: undefined,
		actionResult: undefined,
	})
	const {
		coucheJsonSchemas,
		coucheMultiPolygons,
		addStyleJsonSchemas,
		addCoucheJsonSchemas,
		addCoucheMultiPolygons,
		toggleSelectedLayer,
		toggleViewportParams,
		overwriteState,
		selectedLayer,
		viewportParams,
		surfaceOrder,
	} = useMapState()
	const [superRenderList, setSuperRenderList] = useState({})
	const [searchValue, setSearchValue] = useState("")
	const [collapsedList, setCollapsedList] = useState([])

	const isCarto = window.location.pathname.includes("cartographie")
	const writePermission = useAccessRights(EnumToolPath.CD_CARTOGRAPHY)?.write
	const resetReducerAction = window.location.pathname.includes("cartographie")
		? resetCartoReducerAction
		: resetDaybookReducerAction

	const pointsKeys = Object.values(coucheJsonSchemas)
		.filter((jsonSchema) => jsonSchema.visibility === 1)
		.map((jsonSchema) => {
			const { visibility, cluster, ...rest } = jsonSchema
			return rest
		})
	const couchesKeys = Object.values(coucheMultiPolygons)
		.filter((jsonSchema) => jsonSchema.visibility === 1)
		.map((couche) => {
			const { visibility, priority, moved, ...rest } = couche
			return rest
		})

	const getDaybookDrawedPolygons = () => {
		if (!selectedEvent) return []
		return Object.values(multiPolygons)
			.filter((multiPolygon) => multiPolygon.event_id === selectedEvent?.id)
			.map((multiPolygon) => {
				return { id: multiPolygon.id }
			})
	}

	const getStyles = (couches) => {
		return Object.values(couches).map((couche: any) => multiPolygons[couche.id])
	}

	useEffect(() => {
		//* Avoid initialisation
		if (!memoizedMapStates)
			return setMemoizedMapStates({
				coucheJsonSchemas: null,
				coucheMultiPolygons: null,
				selectedLayer: null,
				viewportParams: null,
				surfaceOrder: null,
				multiPolygonsWithStyle: null,
			})
		if (!cartoToPcs.isOpen && cartoToPcs.actionResult !== "created") {
			dispatch(
				overwriteState({
					coucheJsonSchemas: memoizedMapStates.coucheJsonSchemas,
					coucheMultiPolygons: memoizedMapStates.coucheMultiPolygons,
					selectedLayer: memoizedMapStates.selectedLayer,
					viewportParams: memoizedMapStates.viewportParams,
					surfaceOrder: memoizedMapStates.surfaceOrder,
				}),
			)
			memoizedMapStates.multiPolygonsWithStyle.forEach((multiPolygon) =>
				dispatch(addMultipolygon(multiPolygon)),
			)
		}
	}, [cartoToPcs.isOpen])

	const restoreConfig = async (carto) => {
		const pointsKeys = carto.pointsKeys
		const couchesKeys = carto.couchesKeys
		const daybookDrawedPolygonsKeys = getDaybookDrawedPolygons()
		await dispatch(resetReducerAction())
		const idsToDisplay = [
			...pointsKeys,
			...couchesKeys,
			...(isCarto ? [] : daybookDrawedPolygonsKeys),
		]
		idsToDisplay.forEach(async (obj) => {
			if (pointsKeys.includes(obj)) {
				if (obj.style) {
					dispatch(
						addStyleJsonSchemas({
							id: obj.id,
							style: {
								iconSize: obj.style.iconSize as number,
							},
						}),
					)
				}
				dispatch(addCoucheJsonSchemas(obj))
				return
			}
			if (
				(couchesKeys.includes(obj) ||
					daybookDrawedPolygonsKeys.includes(obj)) &&
				multiPolygons[obj.id]
			) {
				dispatch(
					addMultipolygon({
						...multiPolygons[obj.id],
						custom_props: {
							...multiPolygons[obj.id]?.custom_props,
							style: obj.style ?? multiPolygons[obj.id]?.custom_props?.style,
						},
					}),
				)
				dispatch(
					addCoucheMultiPolygons({
						id: obj.id,
						priority: multiPolygons[obj.id]?.custom_props?.priority,
					}),
				)
				return
			}
		})
		dispatch(toggleSelectedLayer(carto.layerType))

		//* Applying zoom correction due to modal usage, it's equal to 2x zoom scoll in.
		const correctedZoom = carto?.viewParams?.zoom + mapZoomDiff
		const zoomParam = correctedZoom > maxZoom ? maxZoom : correctedZoom

		const correctedViewParams = {
			...carto.viewParams,
			zoom: zoomParam,
		}
		dispatch(toggleViewportParams(correctedViewParams))
	}

	const getSubtitle = (carto) => {
		const subtitle =
			!_.isEmpty(carto.pointsKeys) || !_.isEmpty(carto.couchesKeys)
				? carto.pointsKeys.length + carto.couchesKeys.length + " couche(s)"
				: "Cartographie vierge"

		return subtitle
	}

	useEffect(() => {
		const newRenderList = {}

		Object.values(uploadedCartos).map((carto) => {
			const categoryKey = _.isEmpty(carto.category)
				? "Non catégorisée"
				: carto.category
			if (!newRenderList[categoryKey]) {
				newRenderList[categoryKey] = []
			}

			newRenderList[categoryKey].push(
				<PanelPresetsItem
					key={carto.id}
					carto={carto}
					subtitle={getSubtitle(carto)}
					avatar={mapChecked}
					onClick={() => {
						restoreConfig(carto)
					}}
					isEditable={isCarto && writePermission}
					onEdit={() => {
						setMemoizedMapStates({
							coucheJsonSchemas: coucheJsonSchemas,
							coucheMultiPolygons: coucheMultiPolygons,
							selectedLayer: selectedLayer,
							viewportParams: viewportParams,
							surfaceOrder: surfaceOrder,
							multiPolygonsWithStyle: getStyles(coucheMultiPolygons),
						})
						setCartoToPcs({
							isOpen: true,
							action: "REPLACE",
							carto: carto,
							actionResult: undefined,
						})
					}}
					isDeletable={isCarto && writePermission}
				/>,
			)
		})

		setSuperRenderList(newRenderList)
	}, [
		uploadedCartos,
		coucheJsonSchemas,
		coucheMultiPolygons,
		selectedLayer,
		viewportParams,
		surfaceOrder,
	])

	const filteredCartosConfig = useMemo(() => {
		if (!searchValue) return
		const filteredCartos =
			Object.values(uploadedCartos).filter((carto) =>
				formatNfdLowerCase(carto.configName).includes(
					formatNfdLowerCase(searchValue),
				),
			) ?? []
		return filteredCartos
	}, [searchValue, uploadedCartos])

	return (
		<SFlex fullWidth fullHeight directionColumn>
			<SearchBarContainer>
				<TextField
					className="w-full"
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="Rechercher une carte"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</SearchBarContainer>
			<SList>
				{_.isEmpty(searchValue) && (
					<>
						{Object.keys(superRenderList).map((category) => {
							return (
								<CollapsibleList
									key={category}
									Wrapper={Div}
									buttonText={category}
									initialOpen={!collapsedList.includes(category)}
									onCollapse={() => {
										if (!collapsedList.includes(category)) {
											setCollapsedList([...collapsedList, category])
										} else {
											setCollapsedList(
												collapsedList.filter((cat) => cat !== category),
											)
										}
									}}
									numberElements={superRenderList[category].length}
								>
									{superRenderList[category].map(
										(jsxCompenent) => jsxCompenent,
									)}
								</CollapsibleList>
							)
						})}
					</>
				)}
				{!_.isEmpty(searchValue) && (
					<>
						{filteredCartosConfig.map((carto) => (
							<PanelPresetsItem
								key={carto.id}
								carto={carto}
								subtitle={getSubtitle(carto)}
								avatar={mapChecked}
								onClick={() => restoreConfig(carto)}
								isEditable={isCarto && writePermission}
								onEdit={() => {
									setMemoizedMapStates({
										coucheJsonSchemas: coucheJsonSchemas,
										coucheMultiPolygons: coucheMultiPolygons,
										selectedLayer: selectedLayer,
										viewportParams: viewportParams,
										surfaceOrder: surfaceOrder,
										multiPolygonsWithStyle: getStyles(coucheMultiPolygons),
									})
									setCartoToPcs({
										isOpen: true,
										action: "REPLACE",
										carto: carto,
										actionResult: undefined,
									})
								}}
								isDeletable={isCarto && writePermission}
							/>
						))}
					</>
				)}
			</SList>
			{isCarto && writePermission && (
				<SPaper>
					<PrimaryOutlinedButton
						fullWidth
						onClick={() => {
							setMemoizedMapStates({
								coucheJsonSchemas: coucheJsonSchemas,
								coucheMultiPolygons: coucheMultiPolygons,
								selectedLayer: selectedLayer,
								viewportParams: viewportParams,
								surfaceOrder: surfaceOrder,
								multiPolygonsWithStyle: getStyles(coucheMultiPolygons),
							})
							setCartoToPcs({
								isOpen: true,
								action: "CREATE",
								carto: {
									pointsKeys: pointsKeys,
									couchesKeys: couchesKeys,
								},
								actionResult: undefined,
							})
						}}
						startIcon={<SaveIcon />}
					>
						Nouvel enregistrement
					</PrimaryOutlinedButton>
				</SPaper>
			)}
			<ModalUploadCartography
				isOpen={cartoToPcs.isOpen}
				action={cartoToPcs.action}
				carto={cartoToPcs.carto}
				onClose={(actionResult = undefined) => {
					setCartoToPcs({
						isOpen: false,
						action: undefined,
						carto: undefined,
						actionResult: actionResult,
					})
				}}
			/>
		</SFlex>
	)
}

export default PanelCartoConfigs
