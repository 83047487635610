import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import BookOutlinedIcon from "@mui/icons-material/BookOutlined"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import EventNoteIcon from "@mui/icons-material/EventNote"
import FlagIcon from "@mui/icons-material/Flag"
import GavelIcon from "@mui/icons-material/Gavel"
import LocalPolice from "@mui/icons-material/LocalPolice"
import NightShelterIcon from "@mui/icons-material/NightShelter"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety"
import CampaignIcon from "@mui/icons-material/Campaign"
import { Alert, Card, CardContent, CardHeader } from "@mui/material"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import { ALERT } from "@/pages/carto2/cartographie/service/SignalService"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { setSelectedEvent } from "@/redux-toolkit/common/reducer"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import { setLastSelectedEvent } from "@/redux-toolkit/daybook/reducer"
import {
	ARRETE,
	CARE_FAMILY,
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
	VIGILANCE_CHANGE,
	DISPOSITIF,
	SUIVI_PCS,
} from "@/redux-toolkit/userSettings/constants"
import Moment from "moment"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import styled from "styled-components"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import Defer from "@/utils/Defer"
import FFlag from "@/utils/form/specific/fiche/FFlag"
import { IHystoryElementAugmented } from "@/utils/types/IEvent"
import { VigilanceLabel } from "@/utils/types/IFiche"
import DaybookMap from "./DaybookMap"
import Filter from "./following/filter/Filter"
import useFilterForm, { NO_FILTER } from "./following/filter/useFilterForm"
import DaybookChart from "./subComponents/DaybookChart"
import DaybookPieCare from "./subComponents/DaybookPieCare"
import DaybookPieStatus from "./subComponents/DaybookPieStatus"
import DaybookPieUrgent from "./subComponents/DaybookPieUrgent"
import DaybookPieUrgent2 from "./subComponents/DaybookPieUrgent2/DaybookPieUrgent2"
import HistoricCard from "./subComponents/HistoricCard"
import { ModalContent } from "@nextui-org/react"
import Button from "@/styles/atoms/Button"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const StyledBox = styled(Box)`
    flex-grow: 1;
    max-height: calc(var(--modal-max-height) - 3.5rem);
    margin-bottom: -2em;
    overflow: auto;
    width: 450px;
`
const MapContent = styled.div`
	min-height: 500px;
	position: relative;
`
const VigiCard = styled(Card)`
    width: 75%;
    height: 100%;
    padding: 0.5rem;
`
const RapportCard = styled(Card)`
    width: 25%;
    height: 100%;
    padding: 0.5rem;
`
const StyledFlex = styled(Flex)`
    max-height: var(--modal-max-height);
    margin-bottom: -2em;
    overflow-y: auto;
    overflow-x: hidden;
`
const StyledDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    gap: 0.5rem;
`
const StyledCard = styled(Card)`
    width: 100%;
`
const StyledFlexResponsive = styled(Flex)`
    @media (max-width: 800px) {
        flex-direction: column;
    }
`
const CenteredContent = styled(CardContent)`
    height: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
`
const SAlert = styled(Alert)`
    width: fit-content;
    border-radius: 15px !important;
    .MuiAlert-icon {
        font-size: 18px;
        padding: 0;
    }
    .MuiAlert-message {
        font-size: 14px;
        padding: 0;
    }
`

export const NO_BLOB = "NO_BLOB"
const END_CRISIS = "END_CRISIS"

const filterOptions = [
	{
		name: "Type",
		items: [
			"Évènement",
			"Intervention",
			"Centre d'accueil",
			"Vigilance",
			"Arrêté",
			"Dispositif",
		],
		default: "Pas de filtre",
	},
]

export const ScreenShotContext = React.createContext({
	isTakingScreenshotDashboard: false,
	setIsTakingScreenshotDashboard: (isTakingScreenshot) => {},
	isTakingScreenshotMap: false,
	setIsTakingScreenshotMap: (isTakingScreenshot) => {},
	scaleMultiplicator: 1,
})

const DaybookDashboard = () => {
	const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
	const [isOpenScreenshotModal, setIsOpenScreenshotModal] = useState(false)
	const [eventPriorityBlob, setEventPriorityBlob] = useState(null)
	const [eventStatusBlob, setEventStatusBlob] = useState(null)
	const [actionStatusBlob, setActionStatusBlob] = useState(null)
	const [careBlob, setCareBlob] = useState(null)
	const [chartBlob, setChartBlob] = useState(null)
	const [mapScreenBlob, setMapScreenBlob] = useState(null)
	const selectedEvent = useSelectedEvent()
	const [isScreenshoting, setIsScreenshoting] = useState(false)
	const [isTakingScreenshotDashboard, setIsTakingScreenshotDashboard] =
		useState(false)
	const [isTakingScreenshotMap, setIsTakingScreenshotMap] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory()
	const methods = useForm({
		defaultValues: selectedEvent,
	})
	const { values: filterValues, ...filterConfig } = useFilterForm({
		options: filterOptions,
		data: selectedEvent?.history,
	})
	const isLocked = selectedEvent?.locked

	const daybookCrisisWritePerm = useAccessRights(
		EnumToolPath.GESTION_CRISE,
	).write
	const daybookDashboardReadPerm = useAccessRights(
		EnumToolPath.GC_DASHBOARD,
	).read

	if (!daybookDashboardReadPerm) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	const historic = (selectedEvent?.history ?? [])
		.map((event) => {
			if (!event) {
				debugger
				return null
			}
			return {
				...event,
				icon: {
					[VIGILANCE_CHANGE]: FlagIcon,
					[END_CRISIS]: CheckCircleOutlineIcon,
					[INCIDENT]: EventNoteIcon,
					[INTERVENTION]: LocalPolice,
					[ARRETE]: GavelIcon,
					[CARE_FAMILY]: PeopleAltIcon,
					[TEMPORARY_CARE]: NightShelterIcon,
					[DISPOSITIF]: HealthAndSafetyIcon,
					[SUIVI_PCS]: Diversity3Icon,
					[ALERT]: CampaignIcon,
				}[event.type] as any,
				editIcon: {
					[CARE_FAMILY]: <ZoomInIcon />,
				}[event.type] as any,
				color: {
					[VIGILANCE_CHANGE]: "neutral",
					[END_CRISIS]: "neutral",
					[INCIDENT]: "primary",
					[INTERVENTION]: "orange",
					[ARRETE]: "brown",
					[CARE_FAMILY]: "green",
					[TEMPORARY_CARE]: "neutral",
					[DISPOSITIF]: "purple",
					[SUIVI_PCS]: "purple",
					[ALERT]: "cyan",
				}[event.type],
			}
		})
		.filter(Boolean) as IHystoryElementAugmented[]

	const onSubmit = (values) => {
		dispatch(
			updateEvent({
				...values,
				history: [
					...values.history,
					{
						type: VIGILANCE_CHANGE,
						title:
							"Niveau de vigilance mis à jour : " +
							VigilanceLabel[values.vigilance],
						date: Moment().format(),
					},
				],
			}),
		)
		toast.success(
			"Niveau de vigilance mis à jour : " + VigilanceLabel[values.vigilance],
		)
	}

	useEffect(() => {
		return () => {
			setIsTakingScreenshotMap(false)
			setIsTakingScreenshotDashboard(false)
		}
	}, [])

	useEffect(() => {
		methods.reset(selectedEvent)
	}, [selectedEvent])

	useEffect(() => {
		//* Waiting all blobs to be sure to diplay them into the report

		if (
			!mapScreenBlob ||
			!chartBlob ||
			!eventPriorityBlob ||
			!eventStatusBlob ||
			!actionStatusBlob ||
			isTakingScreenshotMap ||
			!careBlob
		) {
			return
		}
		setIsOpenScreenshotModal(false)
		setIsTakingScreenshotMap(false)
		setIsTakingScreenshotDashboard(false)
		history.push({
			pathname: "/maincourante/rapport",
			state: {
				eventPriorityBlob: eventPriorityBlob,
				eventStatusBlob: eventStatusBlob,
				actionStatusBlob: actionStatusBlob,
				careBlob: careBlob,
				chartBlob: chartBlob,
				mapScreenBlob: mapScreenBlob,
			},
		})
	}, [mapScreenBlob, chartBlob, isTakingScreenshotMap])

	const filteredHistory = historic.filter((event) => {
		if (filterValues["Type"] === NO_FILTER) {
			return true
		}
		let shouldFilter = false
		;[
			{ key: INTERVENTION, label: "Intervention" },
			{
				key: INCIDENT,
				label: "Évènement",
			},
			{
				key: ARRETE,
				label: "Arrêté",
			},
			{
				key: CARE_FAMILY,
				label: "Centre d'accueil",
			},
			{
				key: VIGILANCE_CHANGE,
				label: "Vigilance",
			},
			{
				key: DISPOSITIF,
				label: "Dispositif",
			},
		].forEach(({ key, label }) => {
			if (filterValues["Type"] === label) {
				if (event.type === key) {
					shouldFilter = true
				}
			}
		})
		return shouldFilter
	})

	const handleScreenshoting = (withoutMapBlob = false) => {
		setIsScreenshoting(true)
		setIsTakingScreenshotDashboard(true)
		if (withoutMapBlob) {
			setMapScreenBlob(NO_BLOB)
		} else {
			setIsTakingScreenshotMap(true)
		}
	}

	return (
		<ScreenShotContext.Provider
			value={{
				isTakingScreenshotDashboard,
				setIsTakingScreenshotDashboard,
				isTakingScreenshotMap,
				setIsTakingScreenshotMap,
				scaleMultiplicator: 2,
			}}
		>
			<StyledFlexResponsive fullWidth gap="1rem" alignItemsStart>
				<StyledFlex directionColumn gap="1rem">
					<StyledDiv>
						<StyledCard>
							<CardHeader
								title={selectedEvent?.name}
								action={
									<Flex gap="1rem">
										{selectedEvent?.active && (
											<Button
												variant="outlined"
												color="primary"
												onClick={() => setIsOpenModalConfirm(true)}
												disabled={!daybookCrisisWritePerm || isLocked}
											>
												Terminer la crise
											</Button>
										)}
										<Button
											color="primary"
											onClick={() => {
												dispatch(setSelectedEvent(undefined))
												dispatch(setLastSelectedEvent(undefined))
											}}
										>
											Changer de crise
										</Button>
									</Flex>
								}
							/>
						</StyledCard>
						<Flex fullWidth gap="1rem">
							<VigiCard>
								<H6>Niveau de vigilance</H6>
								<CardContent>
									<FormProvider {...methods}>
										<FFlag name="vigilance" />
									</FormProvider>
								</CardContent>
								<Flex flexEnd>
									{methods.formState.isDirty && (
										<Button
											color="primary"
											onClick={methods.handleSubmit(onSubmit)}
										>
											Enregistrer
										</Button>
									)}
								</Flex>
							</VigiCard>
							<RapportCard>
								<H6>Rapport de crise</H6>
								<CenteredContent>
									<Button
										variant="bordered"
										color="primary"
										startContent={
											<BookOutlinedIcon style={{ fontSize: "24px" }} />
										}
										onClick={() => {
											setIsOpenScreenshotModal(true)
										}}
										disabled={isLocked}
									>
										Générer un rapport
									</Button>
								</CenteredContent>
							</RapportCard>
						</Flex>
						<Flex fullWidth wrap>
							<DaybookPieUrgent
								enableAnimation={false}
								setEventPriorityBlob={setEventPriorityBlob}
							/>
							<DaybookPieStatus
								enableAnimation={false}
								setEventStatusBlob={setEventStatusBlob}
							/>
							<DaybookPieUrgent2
								enableAnimation={false}
								setActionStatusBlob={setActionStatusBlob}
							/>
							<DaybookPieCare
								enableAnimation={false}
								setCareBlob={setCareBlob}
							/>
						</Flex>
						<DaybookChart enableAnimation={false} setChartBlob={setChartBlob} />
					</StyledDiv>
				</StyledFlex>
				<Flex directionColumn gap="1rem" alignItemsInitial>
					<Flex flexEnd>
						<Filter {...filterConfig} />
					</Flex>
					<StyledBox sx={{ maxWidth: 450 }}>
						<Stepper activeStep={1} orientation="vertical">
							<Defer>
								{filteredHistory.reverse().map((historyEvent, index) => {
									return (
										<HistoricCard key={index} historyEvent={historyEvent} />
									)
								})}
							</Defer>
						</Stepper>
					</StyledBox>
				</Flex>
				<ConfirmationModal
					message={
						"Voulez-vous vraiment terminer la crise ? La date de fin sera remplie automatiquement et la crise ne sera plus active"
					}
					onConfirm={() => {
						dispatch(
							updateEvent({
								...selectedEvent,
								endDate: Moment().format(),
								active: false,
								history: [
									...selectedEvent.history,
									{
										type: END_CRISIS,
										title: "Fin de la crise",
										date: Moment().format(),
									},
								],
							}),
						)
						setIsOpenModalConfirm(false)
						dispatch(setSelectedEvent(undefined))
					}}
					modalShown={isOpenModalConfirm}
					onClose={() => setIsOpenModalConfirm(false)}
				/>

				<Modal
					isOpen={isOpenScreenshotModal}
					onClose={() => {
						setIsOpenScreenshotModal(!isOpenScreenshotModal)
					}}
					size="5xl"
				>
					<ModalContent>
						<ModalHeader>
							Définissez la surface et l&apos;échelle cartographique à intégrer
							au rapport
						</ModalHeader>
						<ModalBody>
							<SAlert severity="warning">
								Les données affichées sont relatives à celles de l&apos;outil
								cartographie
							</SAlert>
							<MapContent>
								<DaybookMap
									disableMargin
									setMapScreenBlob={setMapScreenBlob}
									superMapProps={{
										displayOptions: {
											displayMinimap: false,
											displayBottomControl: false,
											displayRightControl: false,
											forceUnclustering: true,
										},
										noInteraction: true,
										hasSearchControl: false,
										mapDimensions: {
											width: "100%",
											height: "500px",
										},
									}}
									resize={false}
								/>
							</MapContent>
						</ModalBody>
						<ModalFooter>
							<CancelButton
								onClick={() => setIsOpenScreenshotModal(!isOpenScreenshotModal)}
							/>
							{isScreenshoting && (
								<>
									<Button
										variant="bordered"
										color="primary"
										startContent={
											<NoPhotographyIcon style={{ fontSize: "23px" }} />
										}
										disabled
									>
										Suivant
									</Button>
									<Button
										color="primary"
										startContent={
											<PhotoCameraIcon style={{ fontSize: "23px" }} />
										}
										disabled
									>
										Chargement...
									</Button>
								</>
							)}
							{!isScreenshoting && (
								<>
									<Button
										variant="bordered"
										color="primary"
										startContent={
											<NoPhotographyIcon style={{ fontSize: "23px" }} />
										}
										onClick={() => {
											handleScreenshoting(true)
										}}
									>
										Suivant
									</Button>
									<Button
										color="primary"
										startContent={
											<PhotoCameraIcon style={{ fontSize: "23px" }} />
										}
										onClick={() => {
											handleScreenshoting()
										}}
									>
										Capture d&apos;écran
									</Button>
								</>
							)}
						</ModalFooter>
					</ModalContent>
				</Modal>
			</StyledFlexResponsive>
		</ScreenShotContext.Provider>
	)
}
export default DaybookDashboard
