import { combineReducers } from "redux"

import accountsReducer from "./accounts/reducer"
import multiPolygonsReducer from "./multiPolygons/reducer"

const reducer = combineReducers({
	users: accountsReducer,
	multiPolygons: multiPolygonsReducer,
})

export default reducer
