import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useIsInterco from "@/hooks/useIsInterco"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import {
	JSON_SCHEMAS,
	MULTI_POLYGONS,
} from "@/redux-toolkit/time/constants"
import Moment from "moment"
import { useMemo } from "react"
import intercoNameAdapter from "utils/intercoNameAdapter"
import { menuTreeItems, RISQUES } from "utils/menuTab"
import IMultiPolygon from "utils/types/IMultiPolygon"

interface IItemBase {
	id: string
	title: string
}

interface IItemMultiPolygon extends IItemBase {
	type: typeof MULTI_POLYGONS
	updated_at: string
	forceIndex?: number
}
export interface IItemJSonSchema extends IItemBase {
	type: typeof JSON_SCHEMAS
	isGeolocalisable: boolean
	imgId?: string
}

function useNavigationTree({
	includeMultiPolygons = true,
	includeJsonSchemas = true,
	includeGeolocalisable = true,
} = {}) {
	const jsonSchemas = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const isInterco = useIsInterco()

	const tree = useMemo(
		() =>
			Object.keys(menuTreeItems)
				.map((mainCategory) => {
					const subTree: {
						[key: string]: (IItemMultiPolygon | IItemJSonSchema)[]
					} = {}

					if (includeMultiPolygons) {
						Object.values(multiPolygons).forEach((multiPolygon: IMultiPolygon) => {
							if (multiPolygon.event_id) {
								return
							}
							const path = multiPolygon.custom_props?.path
							if ((path?.mainCategory ?? RISQUES) === mainCategory) {
								let newSubTree = [
									...(subTree[path?.subCategory ?? "Autres"] ?? []),
									{
										type: MULTI_POLYGONS,
										id: multiPolygon.id,
										title: multiPolygon.name,
										updated_at: multiPolygon.updated_at,
										forceIndex: multiPolygon.forceIndex,
									},
								].sort((a: IItemMultiPolygon, b: IItemMultiPolygon) => {
									if (!a.updated_at || !b.updated_at) {
										return 0
									}
									const aMoment = Moment(a.updated_at)
									const bMoment = Moment(b.updated_at)
									if (aMoment.isBefore(bMoment)) {
										return 1
									}
									if (aMoment.isAfter(bMoment)) {
										return -1
									}
									return 0
								}).sort((a: IItemMultiPolygon, b: IItemMultiPolygon) => {
									if (!a.forceIndex && !b.forceIndex) {
										return 0
									}
									if (!a.forceIndex) {
										return 1
									}
									if (!b.forceIndex) {
										return -1
									}
									if (a.forceIndex > b.forceIndex) {
										return 1
									}
									if (a.forceIndex < b.forceIndex) {
										return -1
									}
									return 0
								})
								subTree[path?.subCategory ?? "Autres"] = newSubTree
							}
						})
					}

					if (includeJsonSchemas) {
						Object.values(jsonSchemas).forEach((jsonSchema) => {
							if (!jsonSchema.sub_category || !jsonSchema.main_category) {
								return
							}
							if (jsonSchema?.hide) {
								return
							}
							if (!includeGeolocalisable) {
								if (!JsonSchemaService.isGeolocalisable(jsonSchema)) {
									return
								}
							}

							if (jsonSchema.main_category === mainCategory) {
								subTree[jsonSchema.sub_category] = [
									...(subTree[jsonSchema.sub_category] ?? []),
									{
										type: JSON_SCHEMAS,
										title: intercoNameAdapter(jsonSchema.title, isInterco),
										id: jsonSchema.id,
										isGeolocalisable:
											JsonSchemaService.isGeolocalisable(jsonSchema),
										imgId: jsonSchema.imgId,
									},
								]
							}
						})
					}

					return {
						title: intercoNameAdapter(mainCategory, isInterco),
						subCategories: Object.keys(subTree).map((key) => ({
							title: intercoNameAdapter(key, isInterco),
							items: subTree[key],
						})),
					}
				})
				.filter((category) => category.subCategories.length > 0),
		[jsonSchemas, multiPolygons],
	)



	const flat = useMemo(() => {
		return tree.reduce((acc, category) => {
			return [
				...acc,
				...category.subCategories.reduce((acc, subCategory) => {
					return [...acc, ...subCategory.items]
				}, []),
			]
		}, [])
	}, [tree])

	return { tree, flat }
}

export default useNavigationTree
