import React, { useMemo } from "react"
import Div from "./Div"
import makeDraggable from "./makeDraggable"

function SuperDraggable<P>({
	component = Div,
	id,
	index,
	fullHandle = true,
	children,
	disabled = false,
	...props
}: {
	component?: React.ComponentType<P>
	id: string
	index: number
	fullHandle?: boolean
	disabled?: boolean
	children: React.ReactNode
} & P) {
	if (disabled) {
		const Wrapper = component as any
		return <Wrapper>{children}</Wrapper>
	}
	const SuperComponent = useMemo(() => makeDraggable(component), [component])
	return (
		<SuperComponent
			{...props}
			draggableProps={{
				id,
				index,
				fullHandle,
			}}
		>
			{children}
		</SuperComponent>
	)
}

export default SuperDraggable
