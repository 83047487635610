import { createAsyncThunk } from "@reduxjs/toolkit"
import ErrorService from "@/services/ErrorService"
import { IFileConfig } from "utils/types/IUploadedFile"
import { getFiches } from "../fiche/resources"
import { setUploadedFiles } from "./reducer"
import UploadedFileApi from "./UploadedFileApi"
import { toast } from "react-toastify"

export const getUploadedFiles = async (dispatch: any, _state: any) => {
	try {
		const data = await UploadedFileApi.getUploadedFiles()
		const uploadedFiles = {}
		data.forEach((element) => {
			uploadedFiles[element.id] = element
		})
		dispatch(setUploadedFiles(uploadedFiles))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des fichiers uploadé, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "getUploadedFiles",
			message: errorMessage,
			dispatch,
		})
	}
}

export const uploadFile = createAsyncThunk(
	"data/upladedFile/uploadFile",
	async (
		{ file, config }: { file: any; config: IFileConfig },
		{ dispatch, getState },
	) => {
		try {
			const uploadedFile = await UploadedFileApi.uploadFile({
				file,
				config,
			})
			getUploadedFiles(dispatch, getState)
			toast.success("Le fichier a bien été téléversé")
			return uploadedFile
		} catch (error) {
			const errorMessage = `Le fichier n'a pas pu être téléverser, message d'erreur : ${error?.response?.data?.message ?? error}`
			ErrorService.error({
				component: "resources:uploadFile",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateUploadedFile = createAsyncThunk(
	"data/upladedFile/uploadFile",
	async (
		{ id, ...params }: { id: string; file?: any; config?: IFileConfig },
		{ dispatch, getState },
	) => {
		try {
			const uploadedFile = await UploadedFileApi.updateUploadedFile(id, params)
			getUploadedFiles(dispatch, getState)
			getFiches(dispatch, getState)
			return uploadedFile
		} catch (error) {
			const errorMessage = `Le fichier n'a pas pu être mis à jour, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:updateUploadedFile",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const deleteUploadedFile = createAsyncThunk(
	"data/upladedFile/deleteUploadedFile",
	async (id: string, { dispatch, getState }) => {
		try {
			await UploadedFileApi.deleteUploadedFile(id)
			getUploadedFiles(dispatch, getState)
			getFiches(dispatch, getState)
		} catch (error) {
			const errorMessage = `Le fichier n'a pas pu être supprimé, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:deleteUploadedFile",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const deleteUploadedFiles = createAsyncThunk(
	"data/upladedFile/deleteUploadedFiles",
	async (ids: string[], { dispatch, getState }) => {
		try {
			await Promise.all(ids.map((id) => UploadedFileApi.deleteUploadedFile(id)))
			getUploadedFiles(dispatch, getState)
			getFiches(dispatch, getState)
		} catch (error) {
			const errorMessage = `Les fichiers n'ont pas pu être supprimés, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:deleteUploadedFiles",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
