import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { useCallback } from "react"
import getImage from "@/utils/getImage"
import {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import IMultiPolygon from "utils/types/IMultiPolygon"
import IPoint from "utils/types/IPoint"
import _ from "lodash"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"

export const getPointTitle = (
	schemaProperties: IJsonSchemaProperty[],
	pointProperties: IPoint["geojson"]["properties"],
	multiPolygons?: { [key: string]: IMultiPolygon },
) => {
	const keys = schemaProperties.filter(
		(prop) =>
			prop.isIdentifiant &&
			(prop.type === JsonSchemaPropertiesType.TEXT ||
				prop.type === JsonSchemaPropertiesType.GEOLOC),
	)

	const title = keys
		.map((key) => {
			const pointProperty = pointProperties[key.name]
			if (!pointProperty) return ""
			if (pointProperty.secteur)
				return multiPolygons[pointProperty.secteur]?.name
			if (_.isString(pointProperty)) return pointProperty
			try {
				return pointProperty?.join(" ") ?? ""
			} catch (e) {
				return ""
			}
		})
		.join(" ")

	return title
}

const useGetPointSummary = () => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const jsonSchemasWithShared = { ...jsonSchemas, ...cdcSharedJsonSchemas }
	const multiPolygons = useMultiPolygons()

	return useCallback(
		(point) => {
			const jsonSchema = jsonSchemasWithShared[point?.jsonschema_id]
			const schemaProperties = jsonSchema?.template?.properties

			if (!schemaProperties) return

			const title = getPointTitle(
				schemaProperties,
				point?.geojson?.properties ?? {},
				multiPolygons,
			)

			return {
				title,
				image: getImage(jsonSchema.imgId),
				jsonSchemaTitle: jsonSchema.title,
			}
		},
		[jsonSchemas],
	)
}

export default useGetPointSummary
