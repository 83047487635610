import { Slider } from "@mui/material"
import useMapState from "@/hooks/useMapState"
import Debug from "debug"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
// app:javascript:utils:panels:panelCarto:EditStyle.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:EditStyle.tsx")
debug.log = console.log.bind(console)

const EditStyleContainer = styled(Flex)`
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    padding: 0.5rem;
`

const EditStylePoint = ({ jsonSchemaId }: { jsonSchemaId?: string }) => {
	const { coucheJsonSchemas, addStyleJsonSchemas } = useMapState()
	const dispatch = useDispatch()

	return (
		<EditStyleContainer
			fullWidth
			directionColumn
			alignItemsInitial
			gap="0.5rem"
		>
			<Subtitle>Changer la taille des icônes</Subtitle>
			<Slider
				defaultValue={coucheJsonSchemas[jsonSchemaId]?.style?.iconSize || 30}
				valueLabelDisplay="auto"
				step={5}
				marks
				min={10}
				max={90}
				onChange={(e, value) => {
					dispatch(
						addStyleJsonSchemas({
							id: jsonSchemaId,
							style: {
								iconSize: value as number,
							},
						}),
					)
				}}
			/>
		</EditStyleContainer>
	)
}

export default EditStylePoint
