import MapService from "@/pages/carto2/cartographie/service/MapService"
import React from "react"
import styled, { css } from "styled-components"

const StyledControl = styled((p) => (
	<div
		onMouseEnter={() => {
			if (p.noDisable) {
				return
			}
			return MapService.disableScrollAndDrag()
		}}
		onMouseLeave={() => {
			if (p.noDisable) {
				return
			}
			MapService.enableScrollAndDrag()
		}}
		{...p}
	/>
))`
    z-index: 1000;
    position: absolute;
    right: 0;
    ${({ position = "right" }: any) => {
			if (position === "right") {
				return css`
                right: 0;
            `
			}
			if (position === "left") {
				return css`
                left: 0;
            `
			}
			if (position === "top") {
				return css`
                top: 0;
            `
			}
			if (position === "bottom") {
				return css`
                bottom: 0;
            `
			}
		}}
`
export default StyledControl
