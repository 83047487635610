import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"
import IMapping from "@/utils/types/IMapping/IMapping"

const selectMappings = createDeepEqualSelector(
	(state: any) => state.data.mappings,
	(mappings) => mappings,
)

const useMappings = (): { [key: string]: IMapping } => {
	return useSelector(selectMappings) ?? []
}

export default useMappings
