import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import { fr } from "date-fns/locale"
import { FStandardProps } from "@/utils/components/jsonSchema/form/FPointProperty"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import styled from "styled-components"

const SDesktopDatePicker = styled(DesktopDatePicker)<{ error: boolean }>`
	width: 100%;
	& .MuiInputBase-root {
		border-color: ${({ error }) => (error ? "#d32f2f" : "initial")};
	}
	& .MuiFormHelperText-root {
		color: ${({ error }) => (error ? "#d32f2f" : "initial")};
	}
	& * {
		border-color: ${({ error }) => (error ? "#d32f2f" : "initial")} !important;
	}
`

const FDate = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
	required = true,
}: FStandardProps) => {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: required },
		defaultValue: null,
	})

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
			<SDesktopDatePicker
				readOnly={readOnly}
				disabled={disabled}
				format="dd/MM/yyyy"
				label={label}
				value={value ? new Date(value) : null}
				onChange={(date) => {
					let formattedDate = moment(date).format()
					if (formattedDate === "Invalid date") formattedDate = ""
					onChange(formattedDate)
				}}
				onError={(error, value) => {
					console.log(error, value)
				}}
				slotProps={{
					textField: {
						helperText: error ? error.message : null,
					},
				}}
				error={!!error}
			/>
			<>{JSON.stringify(error, null, 2)}</>
		</LocalizationProvider>
	)
}

export default FDate
