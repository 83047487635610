import useUploadedCartos from "@/hooks/useUploadedCartos"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import DownloadService from "@/pages/telechargement/services/DownloadService"
import React, { useContext, useEffect, useState } from "react"
import { TreeContext } from "../../redux/page/context"

const IsolatedTreeProvider = ({ children }) => {
	const [tree, setTree] = useState({})
	const { dispatch } = useContext(TreeContext)

	const isolated = !dispatch

	const uploadedFiles = useUploadedFiles()
	const uploadedCartos = useUploadedCartos()

	useEffect(() => {
		const getTree = async () => {
			const result = await DownloadService.downloadTree()
			setTree(result.data.tree)
		}
		if (isolated) getTree()
	}, [uploadedFiles, uploadedCartos])

	return isolated ? (
		// @ts-ignore
		<TreeContext.Provider value={{ state: tree }}>
			{children}
		</TreeContext.Provider>
	) : (
		children
	)
}

export default IsolatedTreeProvider
