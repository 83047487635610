import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { Button, Container, Divider, MobileStepper } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { H6 } from "../style/header"
import LightTooltip from "../../../styles/atoms/Tooltip/LightTooltip/LightTooltip"
import CommentIcon from "@mui/icons-material/Comment"
import EventIcon from "@mui/icons-material/Event"
import { Flex } from "../style/flex"
import ReactHtmlParser from "react-html-parser"

const SDiv = styled.div`
    padding: 1rem !important;
`

const SContainer = styled(Container)`
    width: 30vw !important;
    font-weight: 400;
    margin-left: -0.75rem !important;
    max-height: 40vh;
    overflow: auto;
`

const SFlex = styled(Flex)`
    padding: 1rem;
`

const DateContainer = styled(Container)`
    width: 30vw !important;
    font-weight: 400;
    margin-left: -0.75rem !important;
`

const PreventBottomClose = styled.div<{ active: boolean }>`
    ${({ active }) =>
			active &&
			css`
            position: relative;

            :after {
                content: '';
                position: absolute;
                display: block;
                height: 200px;
                bottom: -200px;
                left: 0;
                right: 0;
                background-color: transparent;
            }
        `}
`

const ReponsesTooltip = ({
	data,
	children,
	enterDelay = 300,
	enterNextDelay = 300,
	leaveDelay = 300,
}) => {
	const reponses = data.Réponses.filter(
		(reponse) => reponse.objet !== "" || reponse.date !== "Invalid date",
	)

	const [currentStep, setCurrentStep] = useState(0)
	const isPaginated = reponses.length > 1

	const handleBack = () => {
		setCurrentStep(currentStep - 1)
	}

	const handleNext = () => {
		setCurrentStep(currentStep + 1)
	}

	return (
		<LightTooltip
			placement="left"
			enterDelay={enterDelay}
			enterNextDelay={enterNextDelay}
			leaveDelay={leaveDelay}
			arrow
			title={
				<PreventBottomClose active={isPaginated}>
					<SDiv>
						<H6>
							{data.Objet} : Point de situation{" "}
							{reponses.indexOf(reponses[currentStep]) + 1}/{reponses.length}
						</H6>
					</SDiv>
					<Divider />
					<SFlex>
						<EventIcon />
						<DateContainer>
							<Flex spaceBetween>
								<div>Date de point de situation </div>
								<div>
									{moment(reponses[currentStep].date).format(
										"DD/MM/YYYY HH:mm",
									) === "Invalid date"
										? "Non indiquée"
										: moment(reponses[currentStep].date).format(
												"DD/MM/YYYY HH:mm",
											)}
								</div>
							</Flex>
						</DateContainer>
					</SFlex>
					<Divider />
					<SFlex>
						<CommentIcon />
						<SContainer>
							{reponses[currentStep].objet === ""
								? "Objet de point de situation non indiqué."
								: ReactHtmlParser(reponses[currentStep].objet)}
						</SContainer>
					</SFlex>
					{isPaginated && (
						<MobileStepper
							activeStep={currentStep}
							position="static"
							steps={reponses.length}
							backButton={
								<Button
									size="small"
									onClick={handleBack}
									disabled={currentStep === 0}
								>
									<KeyboardArrowLeft />
								</Button>
							}
							nextButton={
								<Button
									size="small"
									onClick={handleNext}
									disabled={currentStep === reponses.length - 1}
								>
									<KeyboardArrowRight />
								</Button>
							}
						/>
					)}
				</PreventBottomClose>
			}
		>
			<div>{children}</div>
		</LightTooltip>
	)
}

export default ReponsesTooltip
