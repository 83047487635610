import styled, { css } from "styled-components"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	Paper,
	Select,
	Checkbox,
	IconButton,
} from "@mui/material"
import SuperReactTableCell from "../SuperReactTableCell"
import { isDesktop, isMobile } from "react-device-detect"
import React from "react"
import ButtonMulti from "utils/components/button/ButtonMulti/ButtonMulti"
import { Flex } from "utils/components/style/flex"
export const ROW_HEIGHT = 53
export const StyledDoneIcon = styled(DoneIcon)`
  color: var(--green) !important;
`

export const StyledErrorIcon = styled(ErrorIcon)`
  color: var(--red) !important;
`

export const StyledWarningIcon = styled(WarningIcon)`
  color: var(--orange) !important;
`

export const STableBody = styled(TableBody)<{ component: any }>`
  width: 100%;
`
export const SPaper = styled(Paper)`
  width: 100% !important;
  box-shadow: var(--cool-shadow) !important;
`
export const SExpandLessIcon = styled(ExpandLessIcon)`
  ${(p: any) =>
		p.disabled
			? css`
          color: var(--neutral400);
        `
			: css`
          color: var(--primary500);
        `};
  margin-bottom: -10px;
` as any

export const SExpandMoreIcon = styled(ExpandMoreIcon)`
  ${(p: any) =>
		p.disabled
			? css`
          color: var(--neutral400);
        `
			: css`
          color: var(--primary500);
        `};
` as any

export const RowIconButton = styled(IconButton)`
  font-size: 24px;
  padding: 0.6rem !important;
  margin: -0.6rem !important;
  width: fit-content;
`

export const SCheckbox = styled(Checkbox)`
  margin: -8px !important;
  &.Mui-checked {
    color: var(--primary800) !important;
  }
  &.MuiCheckbox-indeterminate {
    color: var(--primary800) !important;
  }
`

export const B = styled.b`
  color: var(--primary800) !important;
`

export const STableHead = styled(TableHead)``

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`

export const SSuperReactTableCell = styled(SuperReactTableCell)<{
	$expandable?: boolean
	$expanded?: boolean
	$isSticky?: boolean
	$isMinimumIndexSticky?: boolean
	width: number
}>`
  ${({ $expandable, $expanded }) =>
		$expandable &&
		$expanded &&
		css`
      border-top: 2px solid var(--primary500);
      border-left: 2px solid var(--primary500);
      border-right: 2px solid var(--primary500);
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    `}
  ${
		isMobile &&
		css`
    display: flex !important;
    justify-content: space-between;
    padding: 5px !important;
    gap: 12px;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: 550;
      align-self: center;
    }
  `
	}
    &:first-child {
    padding-left: 0.75rem !important;
  }
  &:last-child {
    padding-right: 0.75rem !important;
  }
  padding: 0.5rem !important;
  ${({ $isSticky, $isMinimumIndexSticky }) =>
		$isSticky &&
		css`
      position: relative;
      &::before {
        content: ""; /* Required: this can be empty, but must be present */
        display: block; /* Adjust this as needed for your layout */
        position: absolute; /* Position the pseudo-element relative to its parent */
        top: 0; /* Positioning */
        left: 0; /* Positioning */
        width: 100%; /* Match the width of the parent element */
        height: 100%; /* Match the height of the parent element */
        ${
					$isMinimumIndexSticky &&
					"box-shadow: rgba(0, 0, 0, 0.21) -2px 0px 4px -2px;"
				}
        z-index: -1;
      }
    `}
  &::after {
    box-shadow: rgba(0, 0, 0, 0.21) 2px 0px 4px -2px;
  }
`

export const STableCellHead = styled(TableCell)`
  ${
		isMobile &&
		css`
    display: flex !important;
  `
	}
  padding: 5px !important;
  ul {
    padding: 0 !important;
  }
`

export const StyledSelect = styled(Select)`
  min-width: 85px;
  margin-right: 5px;
`

export const StyledCell = styled.div<{ $noBorderBottom: boolean }>`
  padding: 0 !important;
  transition: background-color 0.2s;
  white-space: initial;
  word-wrap: break-word;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: initial !important;
  ${(p: any) =>
		p.noOverflow
			? css`
          overflow: none;
        `
			: css`
          overflow: auto;
        `}
  ${(p: any) =>
		p.hide &&
		css`
      visibility: hidden;
    `}
        & > td {
    ${({ $noBorderBottom }) =>
			$noBorderBottom &&
			css`
        border-bottom: 5px solid red !important;
      `}
  }
` as any

export const STable = styled(Table)<{
	$allowOverflow: boolean
	$secondaryTableStyle: boolean
}>`
  position: none;
  overflow-x: ${({ $allowOverflow }) => ($allowOverflow ? "auto" : "hidden")};
  overflow-y: hidden;
  box-shadow: var(--cool-shadow);

  & * {
    overflow: initial;
  }
  ${({ $secondaryTableStyle }) => {
		if (!isDesktop) {
			return null
		}
		return $secondaryTableStyle
			? css`
          border: 1px solid var(--neutral400);
          padding-left: 0.5rem;
          border-radius: 5px;
          background-color: var(--neutral50);
          & > * {
            background-color: var(--neutral50);
          }
        `
			: css`
          background-color: #fff;
          box-shadow: var(--cool-shadow);
        `
	}}
`
export const IndeterminateCheckbox = React.forwardRef<any, any>(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef()
		const resolvedRef = ref || defaultRef

		return (
			<>
				<SCheckbox
					// @ts-ignore
					inputRef={resolvedRef}
					indeterminate={indeterminate}
					{...rest}
				/>
			</>
		)
	},
)

export const WhiteTableRow = styled(TableRow)`
  background-color: white;
`

export const MainTableRow = styled(TableRow)<{
	$removeBorder: boolean
	$disrespectHeight: boolean
	$expandedRow: boolean
	$clickable: boolean
	component: any
}>`
  ${({ $disrespectHeight }) =>
		isDesktop &&
		!$disrespectHeight &&
		css`
      height: ${ROW_HEIGHT}px !important;
    `}

  ${({ $expandedRow }) =>
		$expandedRow &&
		css`
      background-color: var(--primary50);
      & > * {
        background-color: var(--primary50) !important;
      }
    `}
    ${isMobile && `display: grid !important;`}
    ${
			isMobile &&
			css`
        background-color: #fff;
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        box-shadow: var(--cool-shadow) !important;
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 3px;
        }
        & > td:last-of-type {
            border-bottom: none !important;
        }
    `
		}

    & > td {
    ${({ $removeBorder }) => ($removeBorder ? css`` : undefined)}
  }

  ${({ $clickable }: any) =>
		$clickable &&
		css`
      transition: background-color 0.2s;
      cursor: pointer;
      &:hover {
        background-color: var(--primary50) !important;
        border: 1px solid var(--primary500) !important;
      }
    `}
`

export const SubTableRow = styled(TableRow)<{ $allowOverflow: boolean }>`
  overflow-x: ${({ $allowOverflow }) => ($allowOverflow ? "auto" : "hidden")};
  display: table-row;

  ${({ $allowOverflow }) =>
		$allowOverflow &&
		css`
      border: 2px solid var(--primary500);
    `}
`

export const SubTableCell = styled(TableCell)`
  display: table-cell;
  position: none;
  padding: 0 !important;
  ${
		isMobile &&
		css`
    border: none !important;
  `
	};
`
export const RowContainer = styled.div`
  width: 100%;
  height: ${3 * ROW_HEIGHT}px;
`
export const NoDataRow = styled(TableRow)`
  position: absolute;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
`
export const StyledBottomFlex = styled(Flex)`
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0.5rem;
`
export const NoDataCell = styled(TableCell)`
  color: grey !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: ${3 * ROW_HEIGHT}px;
`
export const SButtonMulti = styled(ButtonMulti)`
  flex-grow: 1 !important;
`

export const ResponsiveFlex = styled.div<{ $flexGrow: number }>`
  display: flex;
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  flex-wrap: wrap;
  gap: 0.5rem;
  ${
		isMobile &&
		css`
    width: 100%;
  `
	}

  & > * {
    flex-grow: 1;
  }
`

export const TableRelativeContainer = styled.div`
  position: relative;
  max-width: 100%;
  overflow: auto;
  width: 100%;
`
