import React from "react"
import { ModalFooter as NModalFooter } from "@nextui-org/react"
import { isMobile } from "react-device-detect"

// Update your ModalFooter component to use React.forwardRef
const ModalFooter = React.forwardRef<
	HTMLDivElement,
	React.ComponentPropsWithRef<typeof NModalFooter>
>((props, ref) => {
	// Forward the ref along with other props to NModalFooter
	return (
		<NModalFooter
			{...props}
			disableAnimation={isMobile}
			isDismissable={false}
			ref={ref}
		/>
	)
})

ModalFooter.displayName = "ModalFooter" // Setting a displayName is a good practice for components using forwardRef

export default ModalFooter
