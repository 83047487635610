import useDrawGeoJSON from "@/hooks/useDrawGeoJSON"
import useFilterPointsInGeojson from "@/hooks/useFilterPointsInGeojson"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMapState from "@/hooks/useMapState"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { useStaticPoints } from "@/hooks/useStaticPoints"
import { DAYBOOK_CARTOGRAPHIE } from "@/pages/maincourante/subComponents/bottomCenterControl/RequestControl"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import DatabaseService from "@/services/DatabaseService"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import useNavigationTree from "@/utils/components/categoryNavigation/useNavigationTree"
import { IFExtractSelectOption } from "@/utils/form/FExtractSelect"
import getImage from "@/utils/getImage"
import { ModalContent } from "@nextui-org/react"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { CARTOGRAPHIE } from "../Cartography"
import JsonSchemaService from "../service/JsonSchemaService"
import RequestModalForm from "./RequestModalForm"
import RequestModalPDF from "./RequestModalPDF"
import Button from "@/styles/atoms/Button"

export const CARE_FAMILY = "CARE_FAMILY"
export const POINTS = "POINTS"
export const MULTI_POLYGONS = "MULTI_POLYGONS"
const labelIncident = "Évènements"
const labelIntervention = "Interventions"
const labelTemporaryCare = "CARE ouverts"
export const SECTORS_OPTION = "Secteurs"
export const IDENFIANTS_OPTION = "Champs Identifiants"

const RequestModal = ({ setIsModalOpen, isModalOpen }) => {
	const methods = useForm()
	const points = usePoints()
	const { flat } = useNavigationTree()
	const multiPolygons = useMultiPolygons()
	const [concernedMultiPolygon, setConcernedMultiPolygon] = useState(undefined)
	const [concernedJsonSchema, setConcernedJsonSchema] = useState(undefined)
	const { coucheJsonSchemas, coucheMultiPolygons, showedFacility } =
		useMapState()
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const selectedEvent = useSelectedEvent()
	const staticPoints = useStaticPoints()
	const daybookDrawGeojson = useDrawGeoJSON()
	const [intersectedPointsByCouches, setIntersectedPointsByCouches] =
		React.useState(undefined)

	const orderOptions = [SECTORS_OPTION, IDENFIANTS_OPTION]
	const requestFrom = window.location.pathname.includes("/maincourante")
		? DAYBOOK_CARTOGRAPHIE
		: CARTOGRAPHIE

	const filterPoints = useFilterPointsInGeojson()

	useEffect(() => {
		if (isModalOpen === false) {
			methods.reset()
			setIntersectedPointsByCouches(undefined)
		}
	}, [isModalOpen])

	const optionPoints = useMemo(() => {
		const finalOptionPoints = [] as Array<IFExtractSelectOption>
		if (requestFrom === DAYBOOK_CARTOGRAPHIE) {
			;[
				{
					condition: staticPoints.care.length > 0,
					option: {
						label: labelTemporaryCare,
						imageSrc: "/img/maincourante/care.png",
						value: TEMPORARY_CARE,
					},
				},
				{
					condition: staticPoints.incident.length > 0,
					option: {
						label: labelIncident,
						imageSrc: "/img/maincourante/evenement_encours_normale.png",
						value: INCIDENT,
					},
				},
				{
					condition: staticPoints.intervention.length > 0,
					option: {
						label: labelIntervention,
						imageSrc: "/img/maincourante/intervention_afaire_normale.png",
						value: INTERVENTION,
					},
				},
			].forEach(({ condition, option }) => {
				if (condition) {
					finalOptionPoints.push(option)
				}
			})
		}

		Object.values(flat).forEach((element: any) => {
			Object.values(coucheJsonSchemas ?? {}).forEach((jsonSchema: any) => {
				if (
					element.id === jsonSchema.id &&
					Object.values(points).filter(
						(point) => point.jsonschema_id === jsonSchema.id,
					).length > 0
				) {
					finalOptionPoints.push({
						value: jsonSchema.id,
						label: element.title,
						imageSrc: getImage(jsonSchemaWithStatics[jsonSchema.id]?.imgId),
					})
					return
				}
			})
		})

		return finalOptionPoints
	}, [
		coucheJsonSchemas,
		coucheMultiPolygons,
		flat,
		jsonSchemaWithStatics,
		selectedEvent,
		staticPoints,
	])

	const optionMultiPolygon = useMemo(() => {
		const finalOptionMultiPolygon = [] as Array<IFExtractSelectOption>
		if (requestFrom === DAYBOOK_CARTOGRAPHIE) {
			Object.values(multiPolygons)
				.filter((multiPolygon) => multiPolygon.event_id === selectedEvent?.id)
				.forEach((multiPolygon: any) => {
					finalOptionMultiPolygon.push({
						value: multiPolygon.id,
						label: multiPolygon.name,
						polygonId: multiPolygon.id,
					})
				})
		}
		Object.values(flat).forEach((element: any) => {
			Object.values(coucheMultiPolygons ?? {}).forEach((polygon) => {
				if (element.id === polygon.id) {
					finalOptionMultiPolygon.push({
						value: polygon.id,
						label: multiPolygons[polygon.id].name,
						polygonId: polygon.id,
					})
					return
				}
			})
		})
		Object.values(daybookDrawGeojson ?? {}).forEach((element) =>
			optionMultiPolygon.push({
				value: element.id,
				label: element.name,
				polygonId: element.id,
			}),
		)
		return finalOptionMultiPolygon
	}, [
		coucheMultiPolygons,
		daybookDrawGeojson,
		flat,
		multiPolygons,
		selectedEvent,
	])

	const intersect = async (values) => {
		const selected1 = values["Couche(s) de points"]
		const selected2 = values["Couche surfacique"]
		const orderOption = values["Ordonnancement"]

		const tmpIntersectedPointsByCouches = {}
		const promises = values["Couche(s) de points"].map(
			async ({ value, label, imageSrc }) => {
				const geojson = await DatabaseService.get(
					values["Couche surfacique"].value,
				)
				const supportedPointsIds = showedFacility
					? Object.values(points)
							.filter(
								(pts) =>
									pts.geojson.properties["suivi_group_id"] === showedFacility,
							)
							.map(
								(support) => support.geojson.properties.relatedObject.objectId,
							)
					: []
				const intersectedPoints = await filterPoints(
					JSON.parse(geojson),
					Object.values(points).filter((point) => {
						if (
							value === TEMPORARY_CARE ||
							value === INCIDENT ||
							value === INTERVENTION
						) {
							return (
								point.jsonschema_id === value &&
								point.event_id === selectedEvent?.id
							)
						}

						if (showedFacility) {
							return (
								point.jsonschema_id === value &&
								supportedPointsIds.includes(point.id)
							)
						}

						return point.jsonschema_id === value
					}),
				)

				return {
					label,
					id: value,
					intersectedPoints: intersectedPoints.sort((a, b) => {
						if (orderOption === SECTORS_OPTION) {
							const geolocKeyA =
								JsonSchemaService.getUndeterminedGeolocPropertyName(
									a,
									jsonSchemaWithStatics,
								)
							const geolocKeyB =
								JsonSchemaService.getUndeterminedGeolocPropertyName(
									b,
									jsonSchemaWithStatics,
								)

							const aSector = _.get(
								a,
								`geojson.properties.${geolocKeyA}.secteur`,
							)
							const bSector = _.get(
								b,
								`geojson.properties.${geolocKeyB}.secteur`,
							)

							const aSectorName = multiPolygons[aSector]?.name
							const bSectorName = multiPolygons[bSector]?.name

							if (!aSectorName && !bSectorName) {
								return 0
							}

							if (!aSectorName) {
								return 1
							}
							if (!bSectorName) {
								return -1
							}

							return aSectorName.localeCompare(bSectorName)
						}
						if (IDENFIANTS_OPTION === orderOption) {
							const identifiantKeyA =
								JsonSchemaService.getUndeterminedIdentifiants(
									a,
									jsonSchemaWithStatics,
								).join(" ")
							const identifiantKeyB =
								JsonSchemaService.getUndeterminedIdentifiants(
									b,
									jsonSchemaWithStatics,
								).join(" ")
							return identifiantKeyA.localeCompare(identifiantKeyB)
						}
						return 0
					}),
				}
			},
		)
		const results = await Promise.all(promises)
		results.forEach(({ label, intersectedPoints, id }) => {
			tmpIntersectedPointsByCouches[id] = intersectedPoints
		})

		setIntersectedPointsByCouches(tmpIntersectedPointsByCouches)
		setConcernedMultiPolygon(values["Couche surfacique"])
		setConcernedJsonSchema(
			values["Couche(s) de points"].map(({ value }) => value),
		)

		return results
	}

	const handleSubmit = async (values) => {
		methods.reset()
		const intersectedPoints = intersect(values)
	}

	return (
		<Modal
			size="3xl"
			isOpen={isModalOpen}
			onClose={() => {
				setIsModalOpen(false)
				methods.reset()
			}}
		>
			<ModalContent>
				<FormProvider {...methods}>
					<ModalHeader>Requête</ModalHeader>
					{!intersectedPointsByCouches && (
						<>
							<ModalBody>
								<RequestModalForm
									optionMultiPolygon={optionMultiPolygon}
									optionPoints={optionPoints}
									orderOptions={orderOptions}
								/>
							</ModalBody>
							<ModalFooter>
								<CancelButton
									onClick={() => {
										if (isModalOpen) {
											setIsModalOpen(!isModalOpen)
										}
									}}
								/>
								<Button
									color="primary"
									onClick={methods.handleSubmit(handleSubmit)}
								>
									Lancer la requête
								</Button>
							</ModalFooter>
						</>
					)}
					{intersectedPointsByCouches && (
						<RequestModalPDF
							intersectedPointsByCouches={intersectedPointsByCouches}
							isModalOpen={isModalOpen}
							setIsModalOpen={setIsModalOpen}
							concernedMultiPolygon={concernedMultiPolygon}
						/>
					)}
				</FormProvider>
			</ModalContent>
		</Modal>
	)
}

export default RequestModal
