import { List } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"
import IPoint from "utils/types/IPoint"
import { LEAVING_FAMILY } from "./ModalDaybookLeavingGroup"
import LeavingGroupListElement from "./LeavingGroupListElement"
import useCareLeftPersonsByGroup from "@/hooks/care/useCareLeftPersonsByGroup"

// app:javascript:components:maincourante:care:leaving:FLeavingGroupListElement.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:leaving:FLeavingGroupListElement",
)
debug.log = console.log.bind(console)
const FLeavingGroupListElement = ({
	matchingSearchPersons,
	careFamilyPoints,
	name,
	label,
	initialData,
}: {
	matchingSearchPersons: {
		[key: string]: {
			id: string
			matchingKeys: string[]
		}
	}
	name: string
	label?: string
	careFamilyPoints: IPoint[]
	initialData: IPoint
}) => {
	const { control } = useFormContext()
	const points = usePoints()
	const selectedEvent = useSelectedEvent()
	const { careId, leavingGroupId } = useParams<{
		careId: string
		leavingGroupId: string
	}>()
	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	const leftPersonsByGroup = useCareLeftPersonsByGroup()

	const modifyingPersonsByGroup = useMemo(() => {
		const associatedPoints =
			initialData?.geojson?.properties?.["Points associés"] ?? []
		const modifyingPersons = {}
		associatedPoints.forEach((pointAssoc) => {
			modifyingPersons[pointAssoc["Identifiant du point associé"]] = [
				...(modifyingPersons[pointAssoc["Identifiant du point associé"]] || []),
				pointAssoc["Index du membre associé"],
			]
		})
		return modifyingPersons
	}, [points, careId, selectedEvent.id, initialData])

	return (
		<>
			{!_.isEmpty(matchingSearchPersons) && (
				<List>
					{Object.values(matchingSearchPersons).map((matchingSearchPerson) => (
						<LeavingGroupListElement
							leftPersonsByGroup={leftPersonsByGroup[matchingSearchPerson.id]}
							familyId={matchingSearchPerson.id}
							value={value}
							onChange={onChange}
							modifyingPersons={
								modifyingPersonsByGroup[matchingSearchPerson.id]
							}
						/>
					))}
				</List>
			)}
			{_.isEmpty(matchingSearchPersons) && (
				<>
					{careFamilyPoints.map((carePoint) => (
						<LeavingGroupListElement
							familyId={carePoint.id}
							leftPersonsByGroup={leftPersonsByGroup[carePoint.id]}
							modifyingPersons={modifyingPersonsByGroup[carePoint.id]}
							value={value}
							onChange={onChange}
						/>
					))}
				</>
			)}
		</>
	)
}

export default FLeavingGroupListElement
