import { v4 as uuidv4 } from "uuid"
const registeredList = {}
// @ts-ignore
window.registeredList = registeredList
const registerKeyboardShortcut = (combination: string) => {
	const uuid = uuidv4()
	if (!registeredList[combination]) {
		registeredList[combination] = [uuid]
	} else {
		registeredList[combination] = [uuid, ...registeredList[combination]]
	}
	return uuid
}

const removeKeyboardShortcut = (uuid: string) => {
	Object.keys(registeredList).forEach((key) => {
		if (registeredList[key].includes(uuid)) {
			registeredList[key] = registeredList[key].filter(
				(listId) => listId !== uuid,
			)
		}
	})
}

const pushOnTop = (uuid: string) => {
	Object.keys(registeredList).forEach((key) => {
		if (registeredList[key].includes(uuid)) {
			registeredList[key] = [
				uuid,
				...registeredList[key].filter((listId) => listId !== uuid),
			]
		}
	})
}

const isCombinationFirst = ({
	combination,
	uuid,
}: {
	combination: string
	uuid: string
}) => {
	return registeredList[combination][0] === uuid
}

export default {
	registerKeyboardShortcut,
	removeKeyboardShortcut,
	isCombinationFirst,
	pushOnTop,
}
