import { createAsyncThunk } from "@reduxjs/toolkit"
import { FICHES } from "@/redux-toolkit/time/constants"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import SynchroFicheService from "@/services/synchro/SynchroFicheService"
import { FIRST_SYNCHRO_DATE } from "@/services/SynchroService"
import ErrorService from "@/services/ErrorService"
import IFiche from "utils/types/IFiche"
import FicheApi from "./FicheApi"
import {
	addFiche,
	createLocalFiche,
	deleteLocalFiche,
	removeFiche,
	updateLocalFiche,
} from "./reducer"

// ! START LOCAL

export const createFiche = createAsyncThunk(
	"data/fiche/createFiche",
	async (fiche: IFiche, { dispatch, getState }) => {
		dispatch(createLocalFiche(fiche))
		await SynchroFicheService.synchronize(dispatch, getState)
	},
)

export const updateFiche = createAsyncThunk(
	"data/fiche/updateFiche",
	async (fiche: IFiche, { dispatch, getState }) => {
		dispatch(updateLocalFiche(fiche))
		await SynchroFicheService.synchronize(dispatch, getState)
	},
)

export const updateFicheWithoutSynchro = updateLocalFiche

export const deleteFiche = createAsyncThunk(
	"data/fiche/deleteFiche",
	async (fiche: IFiche, { dispatch, getState }) => {
		dispatch(deleteLocalFiche(fiche))
		await SynchroFicheService.synchronize(dispatch, getState)
	},
)

export const synchroFiche = createAsyncThunk(
	"data/fiche/synchroFiche",
	async (params, { dispatch, getState }) => {
		await SynchroFicheService.synchronize(dispatch, getState)
	},
)

// ! END LOCAL

// ! START SYNCHRONIZATION

export const getFiches = async (dispatch: any, state: any) => {
	try {
		const { fiches, serverDatetime } = await FicheApi.getFiches(
			state?.time?.[FICHES] ?? FIRST_SYNCHRO_DATE,
		)
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: FICHES,
			}),
		)
		fiches.forEach((fiche: any) => dispatch(addFiche(fiche)))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des fiches, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:getFiches",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeCreatedFiche = async (dispatch: any, fiche) => {
	const { localStatus, localModifications, ...toCreateFiche } = fiche
	try {
		const createdFiche = await FicheApi.createFiche(toCreateFiche)
		dispatch(addFiche(createdFiche))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la création de fiches, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeCreatedFiche",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeUpdatedFiche = async (dispatch: any, fiche) => {
	const { localStatus, localModifications, ...toUpdateFiche } = fiche
	try {
		const updatedFiche = await FicheApi.updateFiche(toUpdateFiche)
		dispatch(addFiche(updatedFiche))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la modification de fiches, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeUpdatedFiche",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeDeletedFiche = async (dispatch: any, fiche) => {
	try {
		const deleteFiche = await FicheApi.deleteFiche(fiche.id)
		dispatch(removeFiche(deleteFiche.id))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la suppression de fiches, message d'erreur : ${error.message}'`
		ErrorService.error({
			component: "resources:synchronizeDeletedFiche",
			message: errorMessage,
			dispatch,
		})
	}
	return
}
export const getFichesWithDeleted = async (dispatch: any, getState) => {
	try {
		const { fiches, serverDatetime } = await FicheApi.getFichesWithDeleted(
			getState().time[FICHES],
		)
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: FICHES,
			}),
		)
		fiches.forEach((fiche) => {
			if (fiche.deleted) {
				dispatch(removeFiche(fiche))
				return
			}
			dispatch(addFiche(fiche))
		})
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des fiches, message : ${error.message}`
		ErrorService.error({
			component: "resources:getFichesWithDeleted",
			message: errorMessage,
			dispatch,
		})
	}
	return
}
