import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"

const selectCartoCouches = createDeepEqualSelector(
	(state: any) => state.carto.coucheMultiPolygons,
	(coucheMultiPolygons) => coucheMultiPolygons,
)

const useCartoCouches = () => {
	return useSelector(selectCartoCouches)
}

export default useCartoCouches
