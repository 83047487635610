import riskFiles from "isoMorphicData/riskFiles.json"
import { useMemo } from "react"
import buildUploadedFileLink from "utils/buildUploadedFileLink"
import fileinfo from "utils/fileinfo"
import useCommune from "./useCommune"
import useUploadedFiles from "./useUploadedFiles"

const useRiskFiles = (withUploaded = true) => {
	const commune = useCommune()
	const uploadedFiles = useUploadedFiles()

	return useMemo(
		() => {
			const concerned = Object.fromEntries(
				Object.entries(riskFiles)
					.filter(([, details]) => commune?.risks?.includes?.(details.risk))
					.map(([key, detail]) => [key, { ...detail, key }]),
			)

			if (!withUploaded) return concerned

			// Replace existing
			Object.values(uploadedFiles)
				.filter(
					(file) =>
						file.config.category === "PEDAGOGIQUE" &&
						"replace" in file.config.pcs,
				)
				.forEach((file) => {
					// @ts-ignore
					const replace = file.config.pcs.replace
					concerned[replace] = {
						...concerned[replace],
						risk: fileinfo(file.filename).base,
						pdf: buildUploadedFileLink(file.id, false),
					}
				})

			// Add nex
			const uploadedRiskFiles = Object.fromEntries(
				Object.entries(uploadedFiles)
					.filter(
						([, file]) =>
							file.config.category === "PEDAGOGIQUE" &&
							("after" in file.config.pcs || "appendix" in file.config.pcs),
					)
					.map(([id, file]) => [
						id,
						{
							key: id,
							risk: fileinfo(file.filename).base,
							pdf: buildUploadedFileLink(file.id, false),
						},
					]),
			)

			return {
				...concerned,
				...uploadedRiskFiles,
			}
		},
		withUploaded ? [uploadedFiles, commune.risks] : [commune.risks],
	)
}

export default useRiskFiles
