import useHumans from "@/hooks/useHumans"
import React, { useState } from "react"
import { Flex } from "@/utils/components/style/flex"
import { TypeComponentProps } from "../PointPropertyValue"
import _ from "lodash"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import useAllHumans from "@/hooks/useAllHumans"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"

const safeArray = <T,>(value): T[] => {
	if (!value) return []
	if (!Array.isArray(value)) return []
	return value
}

const SFlex = styled(Flex)`
    max-width: 50vw;
`

export const ExpandLink = styled.div`
    cursor: pointer;
    font-weight: 500;
    font-size: 0.86em;
    margin-left: 5px;
    display: flex;
    align-items: ${isMobile ? "start" : "end"};

    :hover {
        text-decoration: underline;
    }
`

const SLICE_NUMBER = 1

function TypeResponsables({
	value: humanIds,
	editable,
	align = "left",
	diffWith = false,
	isSmall = false,
	directionColumn = true,
}: TypeComponentProps & {
	diffWith?: boolean
	isSmall?: boolean
	directionColumn?: boolean
}) {
	const humans = useAllHumans()
	const safeHumanIds = safeArray<string>(humanIds).filter((id) => humans[id])
	const [number, setNumber] = useState(SLICE_NUMBER)

	return (
		<SFlex
			$wrap
			flexEnd={align === "right"}
			alignItemsInitial={align === "left"}
			gap="2px"
			directionColumn={directionColumn}
		>
			{diffWith &&
				diffWith
					.filter((id) => !safeHumanIds.includes(id))
					.map((id) => (
						<HumanChip
							writePermission={false}
							key={id}
							human={humans[id]}
							deleted
							size={isSmall ? "small" : "medium"}
						/>
					))}
			{safeHumanIds.slice(0, number).map((id) => {
				return (
					<HumanChip
						writePermission={editable && !diffWith}
						key={id}
						human={humans[id]}
						added={diffWith && !diffWith.includes(id)}
						size={isSmall ? "small" : "medium"}
					/>
				)
			})}
			{safeHumanIds.length > SLICE_NUMBER &&
				(number ? (
					<ExpandLink onClick={() => setNumber(undefined)}>
						+{safeHumanIds.length - SLICE_NUMBER} (responsables)
					</ExpandLink>
				) : (
					<ExpandLink onClick={() => setNumber(SLICE_NUMBER)}>
						voir moins
					</ExpandLink>
				))}
		</SFlex>
	)
}

export default TypeResponsables
