import React from "react"
import { ModalBody as NModalBody } from "@nextui-org/react"
import { isMobile } from "react-device-detect"

// Update your ModalBody component to use React.forwardRef
const ModalBody = React.forwardRef<
    HTMLDivElement,
    React.ComponentPropsWithRef<typeof NModalBody>
>((props, ref) => {
    // Forward the ref along with other props to NModalBody
    return (
        <NModalBody
            {...props}
            disableAnimation={isMobile}
            isDismissable={false}
            ref={ref}
        />
    )
})

ModalBody.displayName = "ModalBody" // Setting a displayName is a good practice for components using forwardRef

export default ModalBody
