import DaybookAlertServices from "utils/services/DaybookAlertServices";
import { AnyGeojsonFeature } from "utils/types/ITurfGeojson";
import useJsonSchemasWithStatics from "./useJsonSchemasWithStatics";
import IPoint from "utils/types/IPoint";

const useFilterPointsInGeojson = () => {
    const jsonSchemas = useJsonSchemasWithStatics();
    return (geojson: AnyGeojsonFeature, points: Array<IPoint>) => DaybookAlertServices.filterPointsInGeojson(points, geojson, jsonSchemas) as Promise<Array<IPoint>>;
}

export default useFilterPointsInGeojson;