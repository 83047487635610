import { InputProps, TextField } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import { FStandardProps } from "utils/components/jsonSchema/form/FPointProperty"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import _ from "lodash"

const HelperTextOrange = styled.div`
    color: var(--orange700);
    font-size: 0.8rem;
`

const FText = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
	multiline = false,
	placeholder = "",
	autoFocus = undefined,
	helperText = "",
	helperTextOrange = "",
	size = "medium",
	className,
	minRows = 3,
	maxRows = undefined,
	displayNumberCharacter = false,
	customInputProps = {},
}: FStandardProps & {
	multiline?: number | boolean
	placeholder?: string
	size?: "small" | "medium"
	autoFocus?: boolean
	helperText?: string
	helperTextOrange?: string
	className?: string
	minRows?: number
	maxRows?: number
	displayNumberCharacter?: boolean
	customInputProps?: InputProps
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
		defaultValue: "",
	})

	if (readOnly && _.isEmpty(inputProps.value)) {
		return null
	}

	return (
		<Flex alignItemsInitial directionColumn fullWidth className={className}>
			<TextField
				size={size}
				fullWidth
				minRows={typeof multiline === "number" ? multiline : minRows}
				maxRows={typeof multiline === "number" ? multiline : maxRows}
				placeholder={placeholder}
				error={!!error?.message}
				multiline={typeof multiline === "number" ? true : multiline}
				label={label ?? name}
				// display number of character
				helperText={
					error?.message ||
					(displayNumberCharacter && inputProps.value.length > 0
						? `${inputProps.value.length} caractères`
						: helperText)
				}
				{...inputProps}
				inputRef={ref}
				disabled={disabled || readOnly}
				InputProps={{
					readOnly: readOnly,
					...customInputProps,
				}}
				autoFocus={autoFocus}
			/>
			{helperTextOrange && (
				<HelperTextOrange>{helperTextOrange}</HelperTextOrange>
			)}
		</Flex>
	)
}

export default FText
