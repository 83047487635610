import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Step, Stepper } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import { SBaseModal, SModalFooter } from "utils/components/style/modal"
import Yup from "utils/yup"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import {
	uploadCarto,
	updateCarto,
} from "@/redux-toolkit/data/uploadedCarto/resources"
import StepMoreInfos from "./UploadModalSteps/StepMoreInfos"
import StepLocation from "./UploadModalSteps/StepLocation"
import StepFormProvider from "@/pages/telechargement/Upload/component/StepFormProvider"
import IUploadedCarto from "utils/types/IUploadedCarto"
import HorizontalStepLabel from "@/pages/telechargement/Upload/component/HorizontalStepLabel"
import StepDatas from "./UploadModalSteps/StepDatas"
import StepLayer from "./UploadModalSteps/StepLayer"
import StepViewPosition from "./UploadModalSteps/StepViewPosition"
import ButtonsSection from "./ButtonsSection"
import useUploadLocation from "@/pages/telechargement/Upload/hooks/useUploadLocation"
import styled from "styled-components"
import useMapState from "@/hooks/useMapState"
import _ from "lodash"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { ModalContent } from "@nextui-org/react"

const SAlert = styled(Alert)`
    margin-top: 2em;
    align-items: center;
    gap: .5em;
`

const schema = Yup.object().shape({
	action: Yup.string().required().default(null),
	idToReplace: Yup.string().nullable().default(null),
	name: Yup.string().required().default(null),
	category: Yup.string().default(""),
	datas: Yup.object()
		.shape({
			pointsKeys: Yup.array().default(null),
			couchesKeys: Yup.array().default(null),
		})
		.required()
		.default({}),
	layer: Yup.string().required().default(null),
	location: Yup.object().nullable().default({}),
})

const ModalUploadCartography = ({
	isOpen,
	action,
	carto,
	onClose,
}: {
	isOpen: boolean
	action: "CREATE" | "REPLACE"
	carto: IUploadedCarto
	onClose: (actionResult?: string) => void
}) => {
	const methods = useForm({
		resolver: yupResolver(schema),
	})
	const values = methods.watch()

	const dispatch = useDispatch()
	//| States to manage Steps from buttonsSection components
	const [modalPanel, setModalPanel] = useState({
		panel: 0,
		id: undefined,
	})
	const [isEditingCoucheStyle, setIsEditingCoucheStyle] = useState(false)
	const [tempValue, setTempValue] = useState(undefined)
	// -----------------------------------------------------------------
	const { getUploadLocation } = useUploadLocation()
	const [step, setStep] = useState(0)
	const steps = ["datas", "layer", "viewParams", "name", "location"]
	const stepsLabels = [
		"Données",
		"Fond de carte",
		"Positionnement",
		"Informations",
		"Enregistrement",
	]

	const { coucheJsonSchemas, addStyleJsonSchemas } = useMapState()

	useEffect(() => {
		if (!isOpen) return
		Object.keys(coucheJsonSchemas).forEach((id) =>
			dispatch(
				addStyleJsonSchemas({
					id: id,
					style: {
						iconSize: 25,
					},
				}),
			),
		)
	}, [isOpen])

	useEffect(() => {
		if (step === 0 && !values.idToReplace) methods.setValue("idToReplace", null)
	}, [step])

	useEffect(() => {
		if (isOpen) {
			methods.reset({
				...schema.getDefault(),
				action: action, //Default value to decide if we create or update
				idToReplace: carto?.id, //Default value from editing carto for update
				name: carto?.configName, //Default value from editing carto for "name" step name input
				category: carto?.category, //Default value from editing carto for "name" step category select
				layer: carto?.layerType, //Default value from editing carto for "layer" step
				viewParams: carto?.viewParams, //Default value from editing carto for "layer" step
			})
			setStep(0)
		}
	}, [isOpen, carto])

	const onSumbit = async (values) => {
		if (values.action === "CREATE") {
			await dispatch(uploadCarto(values))
			toast.success("La cartographie à correctement été enregistrée.")
			onClose("created")
		}
		if (values.action === "REPLACE") {
			await dispatch(updateCarto(values))
			toast.success("La cartographie a bien été mise à jour")
			onClose()
		}
	}

	const renderStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<StepDatas
						modalPanel={modalPanel}
						setModalPanel={setModalPanel}
						setIsEditingCoucheStyle={setIsEditingCoucheStyle}
						setTempValue={setTempValue}
					/>
				)
			case 1:
				return <StepLayer setTempValue={setTempValue} />
			case 2:
				return (
					<StepViewPosition
						methods={methods}
						setTempValue={setTempValue}
						tempValue={tempValue}
					/>
				)
			case 3:
				return <StepMoreInfos setTempValue={setTempValue} />
			case 4:
				return <StepLocation methods={methods} setTempValue={setTempValue} />
			default:
				return <></>
		}
	}

	return (
		<StepFormProvider
			methods={methods}
			setActiveStep={setStep}
			activeStep={step}
			steps={steps}
		>
			<Modal isOpen={isOpen} size="5xl">
				<ModalContent>
					<ModalHeader>
						{!methods.getValues("idToReplace") ? "Création" : "Édition"} d'une
						cartographie
					</ModalHeader>
					<ModalBody>
						<Flex gap directionColumn alignItemsStretch>
							<Stepper
								alternativeLabel
								activeStep={step}
								orientation="horizontal"
							>
								{stepsLabels.map((label, index) => (
									<Step key={label}>
										<HorizontalStepLabel key={index} label={label} />
									</Step>
								))}
							</Stepper>
							{step < steps.length && renderStepContent(step)}
							{step === steps.length && (
								<SAlert>
									<Flex directionColumn alignItemsInitial gap=".5rem">
										<div>
											Vôtre cartographie est prête{" "}
											{!methods.getValues("idToReplace")
												? "pour l'enregistrement."
												: "à être sauvegardée."}
										</div>
										<div>
											Emplacement :{" "}
											<b>
												{!methods.getValues("location")
													? "Non intégrée au PCS"
													: getUploadLocation({
															pcs: methods.getValues("location"),
														})}
											</b>
											.
										</div>
										<div>
											Cliquez sur "Terminer" pour{" "}
											{!methods.getValues("idToReplace")
												? "l'enregistrer."
												: "enregistrer vos modifications."}
										</div>
									</Flex>
								</SAlert>
							)}
						</Flex>
					</ModalBody>
					<ModalFooter>
						<ButtonsSection
							step={step}
							steps={steps}
							setStep={setStep}
							onSubmit={methods.handleSubmit(onSumbit)}
							onClose={() => {
								//* if cancel from step positionning, bring back to previous step to avoid dev error
								if (_.isEqual(step, 2)) setStep(1)
								onClose()
							}}
							setModalPanel={setModalPanel}
							isEditingCoucheStyle={isEditingCoucheStyle}
							setIsEditingCoucheStyle={setIsEditingCoucheStyle}
							tempValue={tempValue}
							setTempValue={setTempValue}
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</StepFormProvider>
	)
}

export default ModalUploadCartography
