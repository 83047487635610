export function us(locale, platform, userAgent) {
	// general
	locale.bindKeyCode(3, ["cancel"])
	locale.bindKeyCode(8, ["backspace"])
	locale.bindKeyCode(9, ["tab"])
	locale.bindKeyCode(12, ["clear"])
	locale.bindKeyCode(13, ["enter"])
	locale.bindKeyCode(16, ["shift"])
	locale.bindKeyCode(17, ["ctrl"])
	locale.bindKeyCode(18, ["alt", "menu"])
	locale.bindKeyCode(19, ["pause", "break"])
	locale.bindKeyCode(20, ["capslock"])
	locale.bindKeyCode(27, ["escape", "esc"])
	locale.bindKeyCode(32, ["space", "spacebar"])
	locale.bindKeyCode(33, ["pageup"])
	locale.bindKeyCode(34, ["pagedown"])
	locale.bindKeyCode(35, ["end"])
	locale.bindKeyCode(36, ["home"])
	locale.bindKeyCode(37, ["left"])
	locale.bindKeyCode(38, ["up"])
	locale.bindKeyCode(39, ["right"])
	locale.bindKeyCode(40, ["down"])
	locale.bindKeyCode(41, ["select"])
	locale.bindKeyCode(42, ["printscreen"])
	locale.bindKeyCode(43, ["execute"])
	locale.bindKeyCode(44, ["snapshot"])
	locale.bindKeyCode(45, ["insert", "ins"])
	locale.bindKeyCode(46, ["delete", "del"])
	locale.bindKeyCode(47, ["help"])
	locale.bindKeyCode(145, ["scrolllock", "scroll"])
	locale.bindKeyCode(188, ["comma", ","])
	locale.bindKeyCode(190, ["period", "."])
	locale.bindKeyCode(191, ["slash", "forwardslash", "/"])
	locale.bindKeyCode(192, ["graveaccent", "`"])
	locale.bindKeyCode(219, ["openbracket", "["])
	locale.bindKeyCode(220, ["backslash", "\\"])
	locale.bindKeyCode(221, ["closebracket", "]"])
	locale.bindKeyCode(222, ["apostrophe", "'"])

	// 0-9
	locale.bindKeyCode(48, ["zero", "0"])
	locale.bindKeyCode(49, ["one", "1"])
	locale.bindKeyCode(50, ["two", "2"])
	locale.bindKeyCode(51, ["three", "3"])
	locale.bindKeyCode(52, ["four", "4"])
	locale.bindKeyCode(53, ["five", "5"])
	locale.bindKeyCode(54, ["six", "6"])
	locale.bindKeyCode(55, ["seven", "7"])
	locale.bindKeyCode(56, ["eight", "8"])
	locale.bindKeyCode(57, ["nine", "9"])

	// numpad
	locale.bindKeyCode(96, ["numzero", "num0"])
	locale.bindKeyCode(97, ["numone", "num1"])
	locale.bindKeyCode(98, ["numtwo", "num2"])
	locale.bindKeyCode(99, ["numthree", "num3"])
	locale.bindKeyCode(100, ["numfour", "num4"])
	locale.bindKeyCode(101, ["numfive", "num5"])
	locale.bindKeyCode(102, ["numsix", "num6"])
	locale.bindKeyCode(103, ["numseven", "num7"])
	locale.bindKeyCode(104, ["numeight", "num8"])
	locale.bindKeyCode(105, ["numnine", "num9"])
	locale.bindKeyCode(106, ["nummultiply", "num*"])
	locale.bindKeyCode(107, ["numadd", "num+"])
	locale.bindKeyCode(108, ["numenter"])
	locale.bindKeyCode(109, ["numsubtract", "num-"])
	locale.bindKeyCode(110, ["numdecimal", "num."])
	locale.bindKeyCode(111, ["numdivide", "num/"])
	locale.bindKeyCode(144, ["numlock", "num"])

	// function keys
	locale.bindKeyCode(112, ["f1"])
	locale.bindKeyCode(113, ["f2"])
	locale.bindKeyCode(114, ["f3"])
	locale.bindKeyCode(115, ["f4"])
	locale.bindKeyCode(116, ["f5"])
	locale.bindKeyCode(117, ["f6"])
	locale.bindKeyCode(118, ["f7"])
	locale.bindKeyCode(119, ["f8"])
	locale.bindKeyCode(120, ["f9"])
	locale.bindKeyCode(121, ["f10"])
	locale.bindKeyCode(122, ["f11"])
	locale.bindKeyCode(123, ["f12"])
	locale.bindKeyCode(124, ["f13"])
	locale.bindKeyCode(125, ["f14"])
	locale.bindKeyCode(126, ["f15"])
	locale.bindKeyCode(127, ["f16"])
	locale.bindKeyCode(128, ["f17"])
	locale.bindKeyCode(129, ["f18"])
	locale.bindKeyCode(130, ["f19"])
	locale.bindKeyCode(131, ["f20"])
	locale.bindKeyCode(132, ["f21"])
	locale.bindKeyCode(133, ["f22"])
	locale.bindKeyCode(134, ["f23"])
	locale.bindKeyCode(135, ["f24"])

	// secondary key symbols
	locale.bindMacro("shift + `", ["tilde", "~"])
	locale.bindMacro("shift + 1", ["exclamation", "exclamationpoint", "!"])
	locale.bindMacro("shift + 2", ["at", "@"])
	locale.bindMacro("shift + 3", ["number", "#"])
	locale.bindMacro("shift + 4", ["dollar", "dollars", "dollarsign", "$"])
	locale.bindMacro("shift + 5", ["percent", "%"])
	locale.bindMacro("shift + 6", ["caret", "^"])
	locale.bindMacro("shift + 7", ["ampersand", "and", "&"])
	locale.bindMacro("shift + 8", ["asterisk", "*"])
	locale.bindMacro("shift + 9", ["openparen", "("])
	locale.bindMacro("shift + 0", ["closeparen", ")"])
	locale.bindMacro("shift + -", ["underscore", "_"])
	locale.bindMacro("shift + =", ["plus", "+"])
	locale.bindMacro("shift + [", ["opencurlybrace", "opencurlybracket", "{"])
	locale.bindMacro("shift + ]", ["closecurlybrace", "closecurlybracket", "}"])
	locale.bindMacro("shift + \\", ["verticalbar", "|"])
	locale.bindMacro("shift + ;", ["colon", ":"])
	locale.bindMacro("shift + '", ["quotationmark", "'"])
	locale.bindMacro("shift + !,", ["openanglebracket", "<"])
	locale.bindMacro("shift + .", ["closeanglebracket", ">"])
	locale.bindMacro("shift + /", ["questionmark", "?"])

	if (platform.match("Mac")) {
		locale.bindMacro("command", ["mod", "modifier"])
	} else {
		locale.bindMacro("ctrl", ["mod", "modifier"])
	}

	//a-z and A-Z
	for (let keyCode = 65; keyCode <= 90; keyCode += 1) {
		var keyName = String.fromCharCode(keyCode + 32)
		var capitalKeyName = String.fromCharCode(keyCode)
		locale.bindKeyCode(keyCode, keyName)
		locale.bindMacro("shift + " + keyName, capitalKeyName)
		locale.bindMacro("capslock + " + keyName, capitalKeyName)
	}

	// browser caveats
	const semicolonKeyCode = userAgent.match("Firefox") ? 59 : 186
	const dashKeyCode = userAgent.match("Firefox") ? 173 : 189
	const equalKeyCode = userAgent.match("Firefox") ? 61 : 187
	let leftCommandKeyCode
	let rightCommandKeyCode
	if (
		platform.match("Mac") &&
		(userAgent.match("Safari") || userAgent.match("Chrome"))
	) {
		leftCommandKeyCode = 91
		rightCommandKeyCode = 93
	} else if (platform.match("Mac") && userAgent.match("Opera")) {
		leftCommandKeyCode = 17
		rightCommandKeyCode = 17
	} else if (platform.match("Mac") && userAgent.match("Firefox")) {
		leftCommandKeyCode = 224
		rightCommandKeyCode = 224
	}
	locale.bindKeyCode(semicolonKeyCode, ["semicolon", ";"])
	locale.bindKeyCode(dashKeyCode, ["dash", "-"])
	locale.bindKeyCode(equalKeyCode, ["equal", "equalsign", "="])
	locale.bindKeyCode(leftCommandKeyCode, [
		"command",
		"windows",
		"win",
		"super",
		"leftcommand",
		"leftwindows",
		"leftwin",
		"leftsuper",
	])
	locale.bindKeyCode(rightCommandKeyCode, [
		"command",
		"windows",
		"win",
		"super",
		"rightcommand",
		"rightwindows",
		"rightwin",
		"rightsuper",
	])

	// kill keys
	locale.setKillKey("command")
}
