import { Checkbox } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import usePoints from "@/hooks/usePoints"
import React from "react"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import IFamilyMember, { IFamilyMemberType } from "utils/types/IFamilyMember"
import AdultChip from "../chips/AdultChip"
import AnimalChip from "../chips/AnimalChip"
import BabyChip from "../chips/BabyChip"
import ChildChip from "../chips/ChildChip"
import _ from "lodash"
import { StyledChip } from "utils/components/style/chip"

const SListItemButton = styled(ListItemButton)`
    width: 100%;
`
const Container = styled.div`
    width: 100%;
    border: 1px solid grey;
    border-radius: 4px;
`

const LeavingGroupListElement = ({
	familyId,
	value = [],
	onChange,
	leftPersonsByGroup = [],
	modifyingPersons,
}: {
	familyId: string
	leftPersonsByGroup?: number[]
	value: {
		"Identifiant du point associé": string
		"Index du membre associé": number
	}[]
	onChange: (
		value: {
			"Identifiant du point associé": string
			"Index du membre associé": number
		}[],
	) => void
	modifyingPersons: any
}) => {
	const points = usePoints()

	const family = points[familyId]
	if (!family) {
		return null
	}

	const familyMembers: IFamilyMember[] = family.geojson.properties?.family ?? []

	const handleChange = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		indexes: number[],
	) => {
		e.stopPropagation()
		const newValue = [...value]
		indexes.forEach((index) => {
			const findElement = newValue.findIndex(
				(v) =>
					v?.["Index du membre associé"] === index &&
					v?.["Identifiant du point associé"] === familyId,
			)
			if (findElement !== -1) {
				newValue.splice(findElement, 1)
			} else {
				newValue.push({
					"Identifiant du point associé": familyId,
					"Index du membre associé": index,
				})
			}
		})
		onChange(newValue)
	}

	const handleCheckAll = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
		const newValue = _.cloneDeep(value) as {
			"Identifiant du point associé": string
			"Index du membre associé": number
		}[]
		;[-1, ...familyMembers.map((member, index) => index)].forEach((index) => {
			const findElement = newValue.findIndex(
				(v) =>
					v?.["Index du membre associé"] === index &&
					v?.["Identifiant du point associé"] === familyId,
			)
			if (findElement === -1) {
				newValue.push({
					"Identifiant du point associé": familyId,
					"Index du membre associé": index,
				})
			}
		})
		onChange(newValue)
	}

	const handleUncheckAll = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		e.stopPropagation()
		const newValue = _.cloneDeep(value) as {
			"Identifiant du point associé": string
			"Index du membre associé": number
		}[]
		;[-1, ...familyMembers.map((member, index) => index)].forEach((index) => {
			const findElement = newValue.findIndex(
				(v) =>
					v?.["Index du membre associé"] === index &&
					v?.["Identifiant du point associé"] === familyId,
			)
			if (findElement !== -1) {
				newValue.splice(findElement, 1)
			}
		})
		onChange(newValue)
	}
	const allChecked =
		familyMembers.every((member, index) => {
			const findElement = value.find(
				(v) =>
					v?.["Index du membre associé"] === index &&
					v?.["Identifiant du point associé"] === familyId,
			)
			return findElement !== undefined
		}) &&
		value.find(
			(v) =>
				v?.["Index du membre associé"] === -1 &&
				v?.["Identifiant du point associé"] === familyId,
		) !== undefined

	return (
		<Container>
			<SListItemButton
				onClick={(e) => (allChecked ? handleUncheckAll(e) : handleCheckAll(e))}
				disabled={
					leftPersonsByGroup.length > 0 && !modifyingPersons?.includes(-1)
				}
			>
				<Flex spaceBetween fullWidth>
					<Flex gap="1rem">
						<Checkbox checked={allChecked} />
						<div>Référent : {family?.geojson?.properties?.Identité}</div>
					</Flex>
				</Flex>
			</SListItemButton>
			<Collapse in={true} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<ListItemButton
						sx={{ pl: 4 }}
						onClick={(e) => handleChange(e, [-1])}
						disabled={
							leftPersonsByGroup.includes(-1) && !modifyingPersons?.includes(-1)
						}
					>
						<Flex fullWidth>
							<Checkbox
								checked={
									value?.find(
										(v) =>
											v?.["Identifiant du point associé"] === familyId &&
											v?.["Index du membre associé"] === -1,
									) !== undefined
								}
							/>
							<Flex fullWidth spaceBetween gap="1rem">
								<Flex gap="0.5rem">
									<AdultChip size="small" />
									{family?.geojson?.properties?.Identité ||
										`1iere personne du groupe (identité non renseignée)`}
								</Flex>
								{leftPersonsByGroup.includes(-1) ? (
									<StyledChip
										$colorProperty="var(--red500)"
										variant="outlined"
										size="small"
										label="Sorti(e)"
									/>
								) : (
									<StyledChip
										$colorProperty="var(--green500)"
										variant="outlined"
										size="small"
										label="Présent(e)"
									/>
								)}
							</Flex>
						</Flex>
					</ListItemButton>
					{familyMembers.map((familyMember: IFamilyMember, index) => (
						<ListItemButton
							sx={{ pl: 4 }}
							key={familyMember.Identité + index}
							onClick={(e) => handleChange(e, [index])}
							disabled={
								leftPersonsByGroup.includes(index) &&
								!modifyingPersons?.includes(index)
							}
						>
							<Flex fullWidth spaceBetween gap="1rem">
								<Flex>
									<Checkbox
										checked={
											value?.find(
												(v) =>
													v?.["Identifiant du point associé"] === familyId &&
													v?.["Index du membre associé"] === index,
											) !== undefined
										}
									/>
									<Flex gap="0.5rem">
										{
											{
												[IFamilyMemberType.ADULT]: <AdultChip size="small" />,
												[IFamilyMemberType.CHILD]: <ChildChip size="small" />,
												[IFamilyMemberType.BABY]: <BabyChip size="small" />,
												[IFamilyMemberType.ANIMAL]: <AnimalChip size="small" />,
											}[familyMember.type]
										}
										{familyMember?.Identité ||
											`${
												index + 1
											}ieme personne du groupe (identité non renseignée)`}
									</Flex>
								</Flex>
								{leftPersonsByGroup.includes(index) ? (
									<StyledChip
										$colorProperty="var(--red500)"
										variant="outlined"
										size="small"
										label="Sorti(e)"
									/>
								) : (
									<StyledChip
										$colorProperty="var(--green500)"
										variant="outlined"
										size="small"
										label="Présent(e)"
									/>
								)}
							</Flex>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</Container>
	)
}

export default LeavingGroupListElement
