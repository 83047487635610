import { createAsyncThunk } from "@reduxjs/toolkit"
import { CREATE_PDF } from "@/redux-toolkit/common/loadingKeys"
import {
	addLoadingKey,
	removeLoadingKey,
} from "@/redux-toolkit/common/reducer"
import { RootState } from "@/redux-toolkit/store"
import { PDFS } from "@/redux-toolkit/time/constants"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import { toast } from "react-toastify"
import ErrorService from "@/services/ErrorService"
import PdfApi from "./PdfApi"
import { removePdf, setPdfs } from "./reducer"

export const getPdfs = async (dispatch) => {
	try {
		const { pdfs } = await PdfApi.getPdfs()
		dispatch(setPdfs(pdfs))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des pdfs, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:getPdfs",
			message: errorMessage,
			dispatch,
		})
	}
}

export const deletePdf = createAsyncThunk(
	"data/pdf/deletePdf",
	async (id: string, { dispatch, getState }) => {
		try {
			dispatch(removePdf(id))
			await PdfApi.deletePdf(id)
			await getPdfs(dispatch)
		} catch (error) {
			const errorMessage = `Erreur lors de la suppression du pdf, message d'erreur : ${error.message}`
			ErrorService.error({
				component: "resources:deletePdf",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const createPdf = createAsyncThunk(
	"data/pdf/createPdf",
	async ({ data, title }: any, { dispatch, getState }) => {
		dispatch(addLoadingKey(CREATE_PDF))
		try {
			const selectedEventId = (getState() as RootState).common.selectedEvent
			const id = await PdfApi.create(data, title, selectedEventId)
			toast.success("Le pdf a bien été créé")
			await getPdfs(dispatch)
			return id
		} catch (error) {
			const errorMessage = `Erreur lors de la création du pdf, message d'erreur : ${error.message}`
			ErrorService.error({
				component: "resources:createPdf",
				message: errorMessage,
				dispatch,
			})
		} finally {
			dispatch(removeLoadingKey(CREATE_PDF))
		}
	},
)
