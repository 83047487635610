import { SAvatar } from "./Avatar.styled"
import React from "react"
import { ColoredProps } from "@/utils/colored"
import { Avatar as NAvatar } from "@nextui-org/react"

export interface StyledAvatarProps
	extends React.ComponentProps<typeof SAvatar>,
		ColoredProps {}

const Avatar = (props: StyledAvatarProps) => {
	return <SAvatar {...props} />
}

export default Avatar
