import { IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import { GreenOutlinedButton } from "@/utils/components/style/button"
import Debug from "debug"
import usePoints from "@/hooks/usePoints"
import JsonSchemaForm from "@/pages/admin/formulaire_dynamique/MyForms/JsonSchemaForm"
import { StyledFormGroup } from "@/utils/components/style/form"
import ModalPCSCategory from "./ModalPCSCategory"
import ModalPCSElement from "./ModalPCSElement"
import DisplayObject from "./DisplayObject"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { useController, useFormContext } from "react-hook-form"
import { Flex } from "@/utils/components/style/flex"
import EditIcon from "@mui/icons-material/Edit"
import InfoIcon from '@mui/icons-material/Info';
import styled from "styled-components"
import Button from "@/styles/atoms/Button"
import { useBoolean } from "react-use"
import useAccessRights from "@/hooks/useAccessRights"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { Tooltip } from "@nextui-org/react"
import _ from "lodash"

const debug = Debug("app:javascript:utils:formik:PCS:FormikPCS")

const RedText = styled.div`
    color: red;
    font-size: 0.8em;
`

const FormikPCS = ({ name, label, disabled = false }) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
		defaultValue: "",
	})

	const jsonSchemas = useJsonSchemasWithStatics()

	const [isOpenModalStep1, setIsOpenModalStep1] = useBoolean(false)
	const [isOpenModalStep2, setIsOpenModalStep2] = useBoolean(false)
	const [isOpenModal2Step1, setIsOpenModal2Step1] = useBoolean(false)
	const [isOpenModal2Step2, setIsOpenModal2Step2] = useBoolean(false)
	const [isOpenModalInfo, setIsOpenModalInfo] = useBoolean(false)
	const [currentObjectId, setCurrentObjectId] = useState(undefined)
	// data
	const [pcsCategory, setPcsCategory] = useState(undefined)

	const points = usePoints()
	const humanPermissions = useAccessRights(EnumToolPath.JSONSCHEMAS)

	const object = points?.[value?.objectId] ?? {}

	const toggle = () => {
		setIsOpenModalStep1(false)
		setIsOpenModal2Step1(false)
	}

	useEffect(() => {
		if (!isOpenModal2Step2) return
		const new_point = Object.values(points).filter((point) => point.localStatus)[0]
		setIsOpenModal2Step2(false)
		setPcsCategory(undefined)
		onChange({
			categoryId: new_point.jsonschema_id,
			objectId: new_point.id,
		})
	}, [points])

	return (
		<StyledFormGroup>
			<>
				<div>{label} :</div>
				{value?.objectId && (
					<Flex gap="1rem">
						<DisplayObject
							objectId={value?.objectId}
							categoryId={value?.categoryId}
							object={object}
						/>
						<Tooltip content="Changer de personne">
							<IconButton disabled={disabled}>
								<EditIcon
									onClick={() => setIsOpenModalStep1(!isOpenModalStep1)}
								/>
							</IconButton>
						</Tooltip>
						{!!points[value.objectId] && (
							<Tooltip content="Plus d'informations">
								<IconButton>
									<InfoIcon
										onClick={() => {
											setCurrentObjectId(value)
											setIsOpenModalInfo(!isOpenModalInfo)
										}}
									/>
								</IconButton>
							</Tooltip>
						)}
					</Flex>
				)}

				{!value?.objectId && (
					<Flex gap=".5em">
						<Button
							color="success"
							variant="bordered"
							style={{
								width: "fit-content",
							}}
							onClick={() => setIsOpenModalStep1(!isOpenModalStep1)}
							disabled={disabled}
						>
							Choisir un élément du PCS
						</Button>
						{(humanPermissions.accessAll || !_.isEmpty(humanPermissions.WHITELIST)) && (
							<Button
								color="primary"
								variant="bordered"
								style={{
									width: "fit-content",
								}}
								onClick={() => setIsOpenModal2Step1(!isOpenModal2Step1)}
								disabled={disabled}
							>
								Créer une donnée
							</Button>
						)}
					</Flex>
				)}
				{error?.message && <RedText>{"Champ obligatoire"}</RedText>}
				<ModalPCSCategory
					setPcsCategory={(value) => {
						setPcsCategory(value)
						if (isOpenModalStep1) setIsOpenModalStep2(true)
						if (isOpenModal2Step1) setIsOpenModal2Step2(true)
					}}
					setValue={onChange}
					currentValue={value}
					isOpenModal={isOpenModalStep1 || isOpenModal2Step1}
					onClose={toggle}
					title="Etape 1 : choisir une liste du PCS"
				/>
				<ModalPCSElement
					pcsCategory={pcsCategory}
					isOpenModal={isOpenModalStep2}
					onClose={() => setIsOpenModalStep2(false)}
					setPointId={(id) => {
						onChange({
							categoryId: pcsCategory.id,
							objectId: id,
						})
						setIsOpenModalStep2(false)
					}}
					title="Etape 2 : choisir une entité"
				/>
				<JsonSchemaForm
					isNew
					isOpen={isOpenModal2Step2}
					onClose={() => {
						setIsOpenModal2Step2(false)
						setPcsCategory(value)
					}}
					jsonSchema={jsonSchemas?.[pcsCategory?.id]}
					writePermissions={true}
				/>
			</>
			<JsonSchemaForm
				isNew={false}
				isOpen={isOpenModalInfo}
				onClose={() => {
					setIsOpenModalInfo(!isOpenModalInfo)
					setCurrentObjectId({})
				}}
				jsonSchema={jsonSchemas?.[currentObjectId?.categoryId]}
				selectedValues={points?.[currentObjectId?.objectId]}
				writePermissions={false}
			/>
		</StyledFormGroup>
	)
}

export default FormikPCS
