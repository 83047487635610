import { createGlobalStyle, css } from "styled-components"
import { isDesktop, isMobile } from "react-device-detect"
import carouselStyle from "./cssLib/carouselStyle"
import sizes from "@/styles/fondations/sizes"
import colors from "@/styles/fondations/colors/colors"
import color_theme from "@/styles/fondations/colors/colors_theme"
import color_nextui from "@/styles/fondations/colors/colors_nextui"
import shadow from "@/styles/fondations/shadow"
export const HEIGHT_TOP_BAR = 48
export const DESKTOP_NAVBAR_WIDTH = 400
const primary = "blue"
const GlobalStyle = createGlobalStyle`
@tailwind base;
@tailwind components;
@tailwind utilities;
${carouselStyle}
:root {
    ${colors}
    ${color_theme}
    ${color_nextui}
    ${shadow}

    --nextui-radius-small: 4px; //8
    --nextui-radius-medium: 6px; //12
    --nextui-radius-large: 7px; //14
    
    --background-app-color: #f9fafb;
    --height-top-bar: ${(props: any) => HEIGHT_TOP_BAR}px;
    

    // Navbar
    --width-close-navbar: ${isDesktop ? "60px " : "0px"};
    --width-open-navbar: ${isMobile ? "100vw" : `${DESKTOP_NAVBAR_WIDTH}px`};
    --width-navbar: ${(props: any) =>
			props.openMenu
				? css`var(--width-open-navbar)`
				: css`var(--width-close-navbar)`};
    --width-navbar-additional-width: ${(props: any) => {
			return props.openMenu ? css`0px` : css`100px`
		}};
    --panel-width: 0px;
    // Modals
    --modal-width: calc(100vw - var(--width-navbar) );

    --modal-max-width:  ${(props: any) =>
			props.isSmall
				? `var(--modal-width)`
				: `min(calc(var(--modal-width) - 2rem), 75vw)`};

    --modal-height: ${(props: any) =>
			`calc(${props.height}px - var(--height-top-bar))`};

    --modal-max-height: ${(props: any) =>
			props.isSmall
				? `calc(${
						props.height ? `${props.height}px` : "100vh"
					} - var(--height-top-bar)) !important;`
				: `calc(${
						props.height ? `${props.height}px` : "100vh"
					} - var(--height-top-bar) - 2rem) !important;`};

    --width-phone: 480px;
    --height-phone: 320px;

    --height-tablet: 481px;
    --width-tablet: 768px;

    --height-small-screen: 1025px;
    --width-small-screen: 1200px;

    --shadow-scroll: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    --cool-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
    --cool-shadow-hover: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.15);

    --font-size-base: 16px;
    --font-size-small: calc(var(--font-size-base) * 0.8);
    --font-size-big: calc(var(--font-size-base) * 1.2);
    --font-size-bigger: calc(var(--font-size-base) * 1.4);
    --font-size-huge: calc(var(--font-size-base) * 1.6);
    --font-size-huge-plus: calc(var(--font-size-base) * 2);

    --panel-padding: 1rem;

    --picrim-bottom-bar-height: 48px;

    --leaflet-container-width: 100%;
--leaflet-container-height: 100%;
}

.react-pdf__Page__textContent {
    display: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    height: 100%;
    overflow: hidden;
}

* {
    font-size: var(--font-size-base);
}

p {
    margin: 0 !important;
}

/* === Modals === */
.modal {
    transform: none !important;
    margin-left: var(--width-navbar) !important;
    margin-top: var(--height-top-bar) !important;
    padding-top: 0px !important;
    overflow: ${(props: any) => (props.isSmall ? css`hidden` : css`auto`)};
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.modal.note-modal {
    display: none !important;
}
.MuiInputBase-root input,textarea {
    box-shadow: none !important;
}
/* === */

.leaflet-draw {
    display: none;
}
  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
.modal.note-modal {
    display: none !important;
}
.leaflet-control-simpleMapScreenshoter {
    display: none !important;
}
.leaflet-div-icon {
    background: initial !important;
    border: initial !important;
}

.MuiTableCell-root {
    font-size: var(--font-size-small);
    .MuiIconButton-sizeMedium {
        font-size: var(--font-size-bigger) !important;
    }
    .MuiSvgIcon-fontSizeMedium {
        font-size: var(--font-size-bigger) !important;
    }
    * {
        font-size: var(--font-size-small) !important;
    }
    
}
.MuiOutlinedInput-notchedOutline > legend > span {
    font-size: var(--font-size-small) !important;
}
.rdw-editor-toolbar {
        margin-top: -50px;
}
.rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid #0a66b7 !important;
}
.rdw-editor-main {
    max-width: 450px !important;
}
.MuiButton-label {
    text-transform: none !important;
}
.MuiButton-root {
    text-transform: none !important;
}
.MuiTooltip-popper {
    z-index: 99999999 !important;
}
.MuiChip-root {
    width: fit-content;
}
.MuiTooltip-tooltip {
    max-width: 50vw !important;
}
.modal > .close {
    font-size: var(--font-size-huge-plus) !important;
}
legend {
    width: auto !important;
}
input {
    border: none;
    box-shadow: none !important;
}
.Toastify__toast {
    z-index: 999999999 !important;
}
.Toastify__toast-container {
    z-index: 999999999 !important;
}
.Toastify__toast-icon {
    height: 20px;
    width: 20px;
}
.Toastify__toast-body {
        display: flex;
    gap: 1rem;
    margin-left: 0.5rem;
    align-items: center;
}
.modal {
    transformation: none !important;
    margin-left: var(--width-navbar) !important;
    margin-top: 48px !important;
    padding-top: 0px !important;
    overflow: ${(props: any) => (props.isSmall ? css`hidden` : css`auto`)};
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.col {
    padding: 0 !important;
}
  .sr-only {
    display: none;
  }
a > h2, h1, h3, h4, h5, h6, p, blockquote {
    color: initial;
}
.note-btn {
    background-color: white !important;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.note-dropdown-menu {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    width: max-content;
}
.note-icon-caret {
    display: none;
}
html {
    --hidden-if-too-small: none;

    // display element if width > 800 px
    @media (min-width: 800px) {
        --hidden-if-too-small: inherit !important;
    }
}
.MuiAutocomplete-paper {
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
}
.MuiTabs-scrollButtons {
    .MuiSvgIcon-root {
        font-size: 2rem;
    }
}

${
	isDesktop &&
	css`
        *::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
        }

        *::-webkit-scrollbar {
            width: 10px;
            background-color: var(--primary500);
            height: 7px;
        }
        *::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: var(--primary500);
        }
    `
}

* {
    font-family: 'Roboto' !important;
}



.leaflet-container {
    width: var(--leaflet-container-width);
    height: var(--leaflet-container-height);
}

body {
    height: calc(100% - var(--height-top-bar));
    overflow: hidden;
}
html {
    background-color: #0000000a
}

.leaflet-popup-content {
    width: auto !important;
    min-width: 300px;
    margin: 0 !important;
}

button:focus {
    outline: none !important;
    outline: none !important;
    cursor: pointer !important;
}

.MuiCardHeader-action {
    flex: initial !important;//0 0 auto;
    align-self: initial !important;//flex-start;
    margin-top: 0px !important;
    margin-right: 0px !important;
}

.marker-cluster {
    border-radius: 50px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.marker-cluster-small {
    background-color: #AAF9C4DF;
}

.marker-cluster-medium {
    background-color: #FFC400DF;
}

.marker-cluster-large {
    background-color: #FFAAC4DF;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

/* .rdw-image-center {
  display: flex;
  justify-content: center;
}
.rdw-image-left {
  display: flex;
}
.rdw-image-right {
  display: flex;
  justify-content: flex-end;
} */

`
export default GlobalStyle
