import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IUploadedCarto from "utils/types/IUploadedCarto"

const selectUploadedCartos = createDeepEqualSelector(
	(state: any) => state.data.uploadedCartos,
	(uploadedCartos: any) => uploadedCartos,
)

const useUploadedCartos: () => { [key: string]: IUploadedCarto } = () => {
	return useSelector(selectUploadedCartos)
}

export default useUploadedCartos
