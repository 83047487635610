import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { Alert, Card, IconButton } from "@mui/material"
import { setPanel } from "@/redux-toolkit/common/reducer"
import Debug from "debug"
import React, { useState } from "react"
import RouterIcon from "@mui/icons-material/Router"
import styled from "styled-components"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { DAYBOOK_VORTEX_STATION } from "../PanelCarto"
import VortexStationData from "./vortex/VortexStationData"
import { useDispatch } from "react-redux"
import { Subtitle } from "utils/components/style/text"
// app:javascript:utils:panels:panelCarto:PanelDaybookVortex.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:PanelDaybookVortex")
debug.log = console.log.bind(console)

const Container = styled(Flex)`
    padding: 0.5rem;
    background-color: #f5f5f5;
    height: 100%;
`
const SCard = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid var(--primary500);
    &:hover {
        border: 2px solid var(--primary600);
        padding: 15px;
        background-color: var(--neutral100);
        cursor: pointer;
    }
    box-shadow: 0px 0px 0px 0px !important;
`
const Title = styled.div`
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primary800);
`
const SIconButton = styled(IconButton)`
    color: white;
    margin-right: -8px !important;
`

const PanelDaybookVortex = () => {
	const [stationData, setStationData] = useState(undefined)
	const [selectedStation, setSelectedStation] = useState(undefined)
	const dispatch = useDispatch()
	return (
		<Container directionColumn fullWidth gap="1.5rem">
			{!selectedStation && (
				<>
					<Alert severity="info">
						Vortex est un partenaire de Numérisk, qui propose a ses utilisateurs
						de visualiser les données en temps réél mis à disposition par leur
						station.
					</Alert>
					<SCard
						onClick={() => {
							dispatch(
								setPanel({
									type: DAYBOOK_VORTEX_STATION,
									name: "gardouche",
								}),
							)
						}}
					>
						<Flex gap="1rem">
							<RouterIcon />
							<Flex directionColumn alignItemsInitial>
								<Title>Gardouch 1</Title>
								<Subtitle>Commune de Gardouch</Subtitle>
							</Flex>
						</Flex>
						<SIconButton>
							<ArrowForwardIosIcon />
						</SIconButton>
					</SCard>
					<PrimaryOutlinedButton startIcon={<ShoppingCartIcon />}>
						Acheter l&apos;accès à de nouvelles stations
					</PrimaryOutlinedButton>
				</>
			)}
		</Container>
	)
}

export default PanelDaybookVortex
