import Axios from "axios"
import DatabaseService from "@/services/DatabaseService"

const getMultiPolygonsWithDeleted = async (
	lastSynchro = "2000-04-12T12:47:50+02:00",
) => {
	const result = await Axios.get(
		`/api/multiPolygons?lastSynchro=${lastSynchro}&deleted=true`,
	)
	return result.data
}
const getMultiPolygons = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(
		`/api/multiPolygons?lastSynchro=${lastSynchro}`,
	)
	return result.data
}
const getPublicMultiPolygons = async (communeId) => {
	const result = await Axios.get(`/api/picrim/multiPolygons/${communeId}`)
	return result.data
}
const createMultiPolygon = async (multiPolygon) => {
	const geojson = await DatabaseService.get(multiPolygon.id)
	const result = await Axios.post(`/api/multiPolygons`, {
		data: { ...multiPolygon, geojson: JSON.parse(geojson) },
	})
	return result.data
}
const getMultiPolygonDetails = async (id) => {
	const result = await Axios.get(`/api/multiPolygon/${id}`)
	return result.data
}
const getPublicMultiPolygonDetails = async (id) => {
	const result = await Axios.get(`/api/picrim/multiPolygonDetails/${id}`)
	return result.data
}
const updateMultiPolygon = async (multiPolygon) => {
	const result = await Axios.put(
		`/api/multiPolygons/${multiPolygon.id}`,
		multiPolygon,
	)
	return result.data
}
const deleteMultiPolygon = async (multiPolygonId) => {
	const result = await Axios.delete(`/api/multiPolygons/${multiPolygonId}`)
	return result.data
}

export default {
	getMultiPolygonsWithDeleted,
	getMultiPolygons,
	createMultiPolygon,
	updateMultiPolygon,
	deleteMultiPolygon,
	getMultiPolygonDetails,
	getPublicMultiPolygonDetails,
	getPublicMultiPolygons,
}
