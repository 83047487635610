import React from "react"
import { Modal as NModal, ModalContent, Kbd } from "@nextui-org/react"
import CloseIcon from "@mui/icons-material/Close"
import { isMobile } from "react-device-detect"
import Button from "@/styles/atoms/Button"
import Tooltip from "@/styles/atoms/Tooltip"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import styled from "styled-components"

// Update your Modal component to use React.forwardRef
const Modal = React.forwardRef<
	HTMLDivElement,
	React.ComponentPropsWithRef<typeof NModal>
>((props, ref) => {
	// Forward the ref along with other props to NModal
	if (
		!React.Children.toArray(props.children).some(
			(child) => child.type === ModalContent,
		)
	) {
		console.error("Modal component must have a ModalContent child")
		debugger
	}

	if (props.toggle !== undefined) {
		console.error(
			"Modal component must use onClose instead of toggle to close the modal",
		)
		alert(
			"Modal component must use onClose instead of toggle to close the modal",
		)
		debugger
	}

	return (
		<NModal
			{...props}
			disableAnimation={isMobile}
			isDismissable={false}
			ref={ref}
			scrollBehavior="inside"
			closeButton={
				<LightTooltip
					placement="right"
					disableFocusListener
					disableTouchListener
					title={
						<>
							Raccourci :<Kbd>Esc</Kbd>
						</>
					}
				>
					<Button
						variant="light"
						isIconOnly
						aria-label="Close"
						onClick={() => {
							props.onClose()
							console.log(props.isOpen)
							console.log("onClose")
						}}
						startContent={<CloseIcon />}
					/>
				</LightTooltip>
			}
		/>
	)
})

Modal.displayName = "Modal" // Setting a displayName is a good practice for components using forwardRef

export default Modal
