import Axios from "axios"
import Debug from "debug"

const debug = Debug("components:redux:data:jsonSchema:JsonSchemaApi")

const getCartos = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const uploaded_cartos = await Axios.get(
		`/api/uploaded_cartos?lastSynchro=${lastSynchro}`,
	)
	return uploaded_cartos.data
}

const uploadCarto = async (config) => {
	const res = await Axios.post("/api/uploaded_cartos", config)
	return res.data
}
const updateCarto = async (config) => {
	const res = await Axios.put(
		`/api/uploaded_cartos/${config.idToReplace}`,
		config,
	)
	return res.data[0]
}
const deleteCarto = async (id) => {
	const res = await Axios.delete(`/api/uploaded_cartos/${id}`)
	return res.data
}

export default {
	getCartos,
	uploadCarto,
	updateCarto,
	deleteCarto,
}
