import Close from "@mui/icons-material/Close"
import {
	Autocomplete,
	AutocompleteRenderOptionState,
	IconButton,
	MenuItem,
	TextField,
} from "@mui/material"
import _ from "lodash"
import React, {
	ComponentProps,
	useState,
	FC,
	HTMLAttributes,
	ReactNode,
} from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import getImage from "@/utils/getImage"
import { OptionWithImage } from "./admin/FOptionsWithImage/FOptionsWithImageModal.type"

function FSelectWithImage({
	name,
	label = undefined,
	options,
	disabled = false,
	readOnly = false,
	isMulti = false,
	size = "medium",
	placeholder = undefined,
}: {
	size?: "medium" | "small"
	name: string
	label?: string
	options: OptionWithImage[]
	disabled?: boolean
	readOnly?: boolean
	isMulti?: boolean
	placeholder?: string
}) {
	const { control } = useFormContext()

	const {
		field: { ref, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	// watch for changes in the value of the field
	const value = useWatch({
		name,
		control,
	})

	const correctedValue = value ? value : isMulti ? [] : null

	const [acMenuOpen, setAcMenuOpen] = useState(false)

	if (readOnly && _.isEmpty(correctedValue)) {
		return null
	}
	const defaultRenderOption: (
		props: HTMLAttributes<HTMLLIElement>,
		option: any,
		state: AutocompleteRenderOptionState,
	) => ReactNode = (props, option, state) => {
		const correspondingOption = options.find((o) => o.label === option)
		return (
			<MenuItem {...props}>
				<Flex gap="1rem">
					<img
						src={getImage(correspondingOption?.imgId)}
						style={{ width: 30, height: 30, marginRight: 10 }}
					/>
					{option}
				</Flex>
			</MenuItem>
		)
	}
	return (
		<Autocomplete
			size={size}
			open={acMenuOpen}
			onOpen={() => setAcMenuOpen(readOnly ? false : true)}
			onClose={() => setAcMenuOpen(false)}
			onKeyDown={(event) => {
				if (event.key === "Backspace" && readOnly) {
					//@ts-ignore
					event.defaultMuiPrevented = true
				}
			}}
			noOptionsText={"Aucune option"}
			disableClearable
			multiple={isMulti}
			fullWidth
			disabled={disabled}
			renderInput={(params) => (
				<Flex fullWidth>
					<TextField
						{...params}
						inputRef={ref}
						label={label}
						size={size}
						disabled={readOnly}
						error={Boolean(error?.message)}
						helperText={error?.message}
						InputProps={{
							...params.InputProps,
							readOnly,
							startAdornment:
								!isMulti && !_.isEmpty(correctedValue) ? (
									<img
										src={getImage(
											options.find((o) => o.label === correctedValue)?.imgId,
										)}
										style={{
											width: 30,
											height: 30,
											marginRight: 10,
										}}
									/>
								) : (
									params.InputProps.startAdornment
								),
						}}
						placeholder={placeholder}
					/>
				</Flex>
			)}
			ChipProps={readOnly ? { onDelete: undefined } : {}}
			options={options.map((option) => option.label)}
			value={correctedValue}
			onChange={(event, newValue) => {
				onChange(newValue)
			}}
			renderOption={defaultRenderOption}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Flex
						fullWidth
						spaceBetween
						style={{
							backgroundColor: "#f5f5f5",
							borderRadius: 5,
							padding: 5,
							border: "1px solid #e0e0e0",
						}}
						key={index}
						gap="1rem"
						{...getTagProps({ index })}
					>
						<Flex gap="0.5rem">
							<img
								src={getImage(options.find((o) => o.label === option)?.imgId)}
								style={{
									width: 30,
									height: 30,
									marginRight: 10,
								}}
							/>
							{option}
						</Flex>
						<IconButton
							size="small"
							onClick={() => {
								if (isMulti) {
									onChange(value.filter((v) => v !== option))
								} else {
									onChange(null)
								}
							}}
						>
							<Close />
						</IconButton>
					</Flex>
				))
			}
		/>
	)
}

export default FSelectWithImage
export type FSelectWithImageProps = ComponentProps<typeof FSelectWithImage>
