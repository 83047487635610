import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useDrawGeoJSON from "@/hooks/useDrawGeoJSON"
import useHasVortexStation from "@/hooks/useHasVortexStation"
import useIsInterco from "@/hooks/useIsInterco"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMapState from "@/hooks/useMapState"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import SignalService from "@/pages/carto2/cartographie/service/SignalService"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import { deleteMultiPolygon } from "@/redux-toolkit/data/multiPolygon/resources"
import {
	STATUS_DONE,
	STATUS_IN_PROGRESS,
	STATUS_TODO,
	cleanCoucheJsonSchemas,
	toggleShowedFacility,
} from "@/redux-toolkit/daybook/reducer"
import { resetDaybookReducerAction, updateDrawMultiPolygon } from "@/redux-toolkit/daybook/resources"
import {
	INCIDENT,
	INTERVENTION,
	SUIVI_GROUP,
	TEMPORARY_CARE,
	VORTEX_STATION,
} from "@/redux-toolkit/userSettings/constants"
import Button from "@/styles/atoms/Button"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import ButtonMulti from "@/utils/components/button/ButtonMulti/ButtonMulti"
import CollapsibleList from "@/utils/components/list/CollapsibleList"
import MultiPolygonColorSquare from "@/utils/components/map/polygon/MultiPolygonColorSquare"
import { Flex } from "@/utils/components/style/flex"
import Div from "@/utils/dnd/Div"
import SuperDraggable from "@/utils/dnd/SuperDraggable"
import SuperDroppable from "@/utils/dnd/SuperDroppable"
import FColor from "@/utils/form/FColor"
import FText from "@/utils/form/FText"
import getImage from "@/utils/getImage"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import SuperModal from "@/utils/modals/SuperModal"
import CloudUpload from "@mui/icons-material/CloudUpload"
import DragIndicator from "@mui/icons-material/DragIndicator"
import Layers from "@mui/icons-material/Layers"
import { List, ListItemAvatar, ListItemText } from "@mui/material"
import Debug from "debug"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { DAYBOOK_DATA, DAYBOOK_SUPPORTS_LIST } from "./PanelCarto"
import PanelLegendItem from "./PanelLegendItem"
import ModalImportGeojson from "./panelCarto/geojson/ModalImportGeojson"
import IPoint from "../types/IPoint"
import FeedIcon from '@mui/icons-material/Feed'
import ArchiveIcon from "@mui/icons-material/Archive"

const debug = Debug("app:javascript:utils:panels:PanelLegend")
debug.log = console.log.bind(console)
const SMultiPolygonColorSquare = styled(MultiPolygonColorSquare)`
    & > div {
        margin-right: 1.5rem !important;
    }
`
const SFlex = styled(Flex)`
    width: 100%;
    height: 100%;
    background-color: var(--neutral50);
`
const SList = styled(List)`
    flex-grow: 1;
    width: 100%;
    padding: 0 !important;
    max-height: -webkit-fill-available;
    overflow-y: auto;
    & > .MuiListItemButton-root {
        border-top: 1px solid var(--neutral400) !important;
    }
    & > .MuiCollapse-root:last-child {
        border-bottom: 1px solid var(--neutral400) !important;
    }
    & > .MuiListItemButton-root:last-child {
        border-bottom: 1px solid var(--neutral400) !important;
    }
`
const DraggableItem = styled.div`
    position: relative;
    ${({ $isDragging }: any) =>
		$isDragging &&
		css`
            box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px,
                rgb(0 0 0 / 14%) 0px 3px 4px 0px,
                rgb(0 0 0 / 12%) 0px 1px 8px 0px;
        `}
`
const SPaper = styled.div`
    background-color: white;
    padding: 1rem;
    width: 100%;
    max-width: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--cool-shadow);
`
const SDragIndicator = styled(DragIndicator)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--neutral600);
    cursor: grab;
    display: none !important;
    ${DraggableItem}:hover & {
        display: block !important;
    }
`
const StyledCard = styled.div`
    background-color: white;
    box-shadow: var(--cool-shadow) !important;
    margin: 1rem 1.5rem;
`
const CardContent = styled(Flex) <{ $isDisabled: boolean }>`
    padding: .5rem !important;
    ${({ $isDisabled }) =>
		$isDisabled && css`
        & > * {
            color: var(--grey200) !important;
        }
    `
	}
`
const SListItemAvatar = styled(ListItemAvatar)`
    display: flex;
    align-items: center;
    & > * {
        width: 32px !important;
        height: 32px !important;
    }
`
const StyledListItemText = styled(ListItemText) <{ $isDisabled: boolean }>`
    & .MuiListItemText-primary {
        color: $isDisabled ? "var(--gray200)" : "var(--primary800)";
    }
    .MuiListItemText-secondary {
        font-size: var(--font-size-small);
    }
`

const PanelLegend = () => {
	const dispatch = useDispatch()
	const points = usePoints()
	const multiPolygons = useMultiPolygons()
	const jsonSchemas = useJsonSchemasWithStatics()
	const drawGeojson = useDrawGeoJSON()
	const {
		addStyleJsonSchemas,
		addCoucheJsonSchemas,
		removeCoucheDrawGeojson,
		removeCoucheJsonSchemas,
		removeCoucheMultiPolygons,
		reorderSurfaces,
		toggleCoucheEvents,
		toggleVisibilityDrawGeojson,
		toggleVisibilityJsonSchemas,
		toggleVisibilityMultiPolygons,
		toggleNextStatusFilter,
		coucheEvents,
	} = useMapState()
	const [tmpEditMultipolygon, setTmpEditMultipolygon] = useState(undefined)
	const [currentMultiPolygon, setCurrentMultiPolygon] = useState(undefined)
	const [isOpenModalImportGeojson, setIsOpenModalImportGeojson] =
		useState(false)
	const selectedEvent = useSelectedEvent()
	const hasVortexStation = useHasVortexStation()
	const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false)
	const permissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)
	const daybookSuivisPermission = useAccessRights(EnumToolPath.SUIVI_PCS)
	const incidentsPermissions = useAccessRights(EnumToolPath.INCIDENT)
	const interventionsPermissions = useAccessRights(EnumToolPath.INTERVENTION)
	const temporaryCarePermissions = useAccessRights(EnumToolPath.CARE)
	const isLocked = selectedEvent?.locked
	const isCarto = window.location.pathname.includes("cartographie")
	const { coucheJsonSchemas, coucheMultiPolygons, surfaceOrder } = useMapState()
	const isInterco = useIsInterco()
	const suivisGroups = isCarto ? [] : Object.values(points).filter((point: IPoint) =>
		point.jsonschema_id === SUIVI_GROUP && point.event_id === selectedEvent.id
	)

	const onDragEnd = (result) => {
		dispatch(
			reorderSurfaces({
				from: result.source.index,
				to: result.destination.index,
			}),
		)
	}

	const methods = useForm()

	useEffect(() => {
		methods.reset({
			fillColor: tmpEditMultipolygon?.custom_props?.style?.color,
			"Nom de la couche": tmpEditMultipolygon?.name,
			details: tmpEditMultipolygon?.custom_props?.details,
		})
	}, [tmpEditMultipolygon])

	// count number of points for each panelLegendItem
	const countPoints = useMemo(() => {
		const count = {}

		Object.values(points).forEach((point) => {
			if (point.event_id === selectedEvent?.id) {
				if (!count[point.jsonschema_id]) count[point.jsonschema_id] = 0

				switch (coucheJsonSchemas[point.jsonschema_id]?.filterBy) {
					case STATUS_TODO:
						if (point.geojson.properties.Statut === STATUS_TODO)
							count[point.jsonschema_id] += 1
						break
					case STATUS_IN_PROGRESS:
						if (point.geojson.properties.Statut === STATUS_IN_PROGRESS)
							count[point.jsonschema_id] += 1
						break
					case STATUS_DONE:
						if (point.geojson.properties.Statut === STATUS_DONE)
							count[point.jsonschema_id] += 1
						break
					default:
						count[point.jsonschema_id] += 1
						break
				}
			}

			if (
				point.jsonschema_id !== INCIDENT &&
				point.jsonschema_id !== INTERVENTION &&
				point.jsonschema_id !== TEMPORARY_CARE
			) {
				if (count[point.jsonschema_id]) {
					count[point.jsonschema_id] += 1
				} else {
					count[point.jsonschema_id] = 1
				}
			}
		})

		return count
	}, [
		multiPolygons,
		coucheJsonSchemas,
		points,
		selectedEvent,
		jsonSchemas,
		coucheMultiPolygons,
	])

	const onSubmit = (values) => {
		dispatch(
			updateDrawMultiPolygon({
				...tmpEditMultipolygon,
				name: values["Nom de la couche"],
				custom_props: {
					style: {
						color: values["fillColor"],
						fillColor: values["fillColor"],
						fill: true,
						stroke: true,
					},
					priority: "10",
				},
				event_id: selectedEvent?.id,
			}),
		)
		methods.reset({})
		setTmpEditMultipolygon(undefined)
	}

	const shouldDisplayPcsData =
		Object.values(coucheJsonSchemas).filter(
			(couche) =>
				couche.id !== INCIDENT &&
				couche.id !== INTERVENTION &&
				couche.id !== TEMPORARY_CARE,
		).length > 0

	const shouldDisplaySuivisGroup = suivisGroups.length > 0 && daybookSuivisPermission.read

	const buffTitleWithStatusFilter = (jsonschema_id) => {
		switch (coucheJsonSchemas[jsonschema_id].filterBy) {
			case STATUS_TODO:
				return " (à faire)"
			case STATUS_IN_PROGRESS:
				return " (en cours)"
			case STATUS_DONE:
				return " (terminé)"
			default:
				return ""
		}
	}

	const getImgSrcFromFilter = (jsonschema_id) => {
		if (jsonschema_id === INCIDENT) {
			switch (coucheJsonSchemas[INCIDENT].filterBy) {
				case STATUS_IN_PROGRESS:
					return "/img/maincourante/evenement_encours_normale.png"
				case STATUS_DONE:
					return "/img/maincourante/evenement_termine_normale.png"
				default:
					return "/img/maincourante/evenement_encours_normale.png"
			}
		}
		if (jsonschema_id === INTERVENTION) {
			switch (coucheJsonSchemas[INTERVENTION].filterBy) {
				case STATUS_TODO:
					return "/img/maincourante/intervention_afaire_normale.png"
				case STATUS_IN_PROGRESS:
					return "/img/maincourante/intervention_encours_normale.png"
				case STATUS_DONE:
					return "/img/maincourante/intervention_termine_normale.png"
				default:
					return "/img/maincourante/intervention_afaire_normale.png"
			}
		}
	}

	const showSupportToCarto = async (group) => {
		const supports = Object.values(points).filter((point) =>
			point.geojson.properties.suivi_group_id === group.id
		)
		await dispatch(cleanCoucheJsonSchemas())
		await dispatch(toggleShowedFacility({ id: group.id }))

		const concernedJsonSchemasIds = _.uniq(supports.map((support) => support.geojson.properties.relatedObject.categoryId))
		concernedJsonSchemasIds.forEach(async (jsonschema_id) => {
			dispatch(
				addStyleJsonSchemas({
					id: jsonschema_id,
					style: {
						iconSize: 25,
					},
				}),
			)
			dispatch(addCoucheJsonSchemas({ id: jsonschema_id }))
		})

		const concernedSupportIds = supports.map((support) => support.geojson.properties.relatedObject.objectId)
		await dispatch(setPanel({
			type: DAYBOOK_SUPPORTS_LIST,
			concernedSupportIds: concernedSupportIds,
		}))
	}

	return (
		<SFlex directionColumn>
			<SList>
				{!isCarto && (
					<>
						{(incidentsPermissions.read ||
							interventionsPermissions.read ||
							temporaryCarePermissions.read) && (
								<CollapsibleList
									buttonText="Données de la main courante"
									Wrapper={Div}
								>
									<>
										{incidentsPermissions.read && (
											<PanelLegendItem
												count={countPoints[INCIDENT]}
												title={`Évènements${buffTitleWithStatusFilter(INCIDENT)}`}
												jsonSchemaId={INCIDENT}
												onClick={() => {
													dispatch(toggleCoucheEvents(INCIDENT))
												}}
												onChangeFilter={() =>
													dispatch(toggleNextStatusFilter(INCIDENT))
												}
												canDegroup
												canEditStyle
												canFilterByStatus
												avatar={
													<img
														src={getImgSrcFromFilter(INCIDENT)}
														width="35"
														height="35"
													/>
												}
												visible={!(coucheEvents?.[INCIDENT] === false)}
												visibilityAction={() => { }}
												deleteAction={() => { }}
											/>
										)}

										{interventionsPermissions.read && (
											<PanelLegendItem
												count={countPoints[INTERVENTION]}
												title={`Interventions${buffTitleWithStatusFilter(
													INTERVENTION,
												)}`}
												canDegroup
												canEditStyle
												canFilterByStatus
												jsonSchemaId={INTERVENTION}
												onClick={() => {
													dispatch(toggleCoucheEvents(INTERVENTION))
												}}
												onChangeFilter={() =>
													dispatch(toggleNextStatusFilter(INTERVENTION))
												}
												avatar={
													<img
														src={getImgSrcFromFilter(INTERVENTION)}
														width="35"
														height="35"
													/>
												}
												visible={!(coucheEvents?.[INTERVENTION] === false)}
												visibilityAction={() => { }}
												deleteAction={() => { }}
											/>
										)}

										{temporaryCarePermissions.read && (
											<PanelLegendItem
												count={countPoints[TEMPORARY_CARE]}
												canDegroup
												canEditStyle
												jsonSchemaId={TEMPORARY_CARE}
												title={"Centre d'accueil"}
												onClick={() => {
													dispatch(toggleCoucheEvents(TEMPORARY_CARE))
												}}
												avatar={
													<img
														src="/img/maincourante/care.png"
														width="35"
														height="35"
													/>
												}
												visible={!(coucheEvents?.[TEMPORARY_CARE] === false)}
												visibilityAction={() => { }}
												deleteAction={() => { }}
											/>
										)}
									</>
								</CollapsibleList>
							)}
					</>
				)}
				{hasVortexStation && (
					<CollapsibleList
						Wrapper={Div}
						buttonText="Données des stations vortex"
						numberElements={1}
					>
						<>
							<PanelLegendItem
								title={"Station de vortex.io"}
								jsonSchemaId={VORTEX_STATION}
								onClick={() => {
									dispatch(toggleCoucheEvents(VORTEX_STATION))
								}}
								avatar={
									<img
										src="/img/maincourante/vortex.png"
										width="35"
										height="35"
									/>
								}
								visible={!(coucheEvents?.[VORTEX_STATION] === false)}
								visibilityAction={() => { }}
								deleteAction={() => { }}
							/>
						</>
					</CollapsibleList>
				)}

				{surfaceOrder?.length > 0 && (
					<CollapsibleList
						Wrapper={Div}
						buttonText="Données surfaciques"
						numberElements={
							surfaceOrder.filter(
								(id) => coucheMultiPolygons[id] && multiPolygons[id],
							).length
						}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<SuperDroppable component={Div}>
								{(surfaceOrder ?? []).map((id, index) => {
									if (coucheMultiPolygons[id]) {
										const couche = coucheMultiPolygons[id]
										const multiPolygon = multiPolygons[id]
										if (!multiPolygon) {
											return null
										}
										const disableDrag = !permissions.write || isLocked

										return (
											<SuperDraggable
												component={DraggableItem}
												key={id}
												id={id}
												index={index}
												disabled={disableDrag}
											>
												{permissions.write && !isLocked && <SDragIndicator />}
												<PanelLegendItem
													entityId={id}
													canEditStyle
													canEditInfos={permissions.write && !isLocked}
													canRemove={!multiPolygon?.event_id}
													canDelete={
														!!multiPolygon?.event_id &&
														permissions.write &&
														!isLocked
													}
													title={multiPolygon?.name}
													subtitle={
														multiPolygon?.event_id
															? "Dessiné par un utilisateur"
															: intercoNameAdapter(
																"Couche risque du PCS",
																isInterco,
															)
													}
													avatar={
														<SMultiPolygonColorSquare
															multiPolygon={multiPolygon}
															marginRight="1rem"
														/>
													}
													visible={!!couche.visibility}
													visibilityAction={toggleVisibilityMultiPolygons(id)}
													deleteAction={
														multiPolygon?.event_id
															? () => {
																setIsOpenModalConfirmation(true)
																setCurrentMultiPolygon(multiPolygon)
															}
															: removeCoucheMultiPolygons(id)
													}
												/>
											</SuperDraggable>
										)
									} else {
										const couche = drawGeojson[id]

										return (
											<SuperDraggable
												component={DraggableItem}
												key={id}
												id={id}
												index={index}
											>
												<PanelLegendItem
													canEditStyle
													canRemove
													title={couche?.name}
													avatar={
														<SMultiPolygonColorSquare
															multiPolygon={couche}
															marginRight="1rem"
														/>
													}
													visible={couche.visibility}
													visibilityAction={toggleVisibilityDrawGeojson(id)}
													deleteAction={removeCoucheDrawGeojson(id)}
												/>
											</SuperDraggable>
										)
									}
								})}
							</SuperDroppable>
						</DragDropContext>
					</CollapsibleList>
				)}

				{shouldDisplayPcsData && (
					<CollapsibleList
						Wrapper={Div}
						buttonText={intercoNameAdapter("Données PCS", isInterco)}
						numberElements={
							Object.values(coucheJsonSchemas ?? {}).filter(
								(coucheJsonSchema: any) => {
									if (
										[INCIDENT, INTERVENTION, TEMPORARY_CARE].includes(
											coucheJsonSchema.id,
										)
									) {
										return false
									}
									return true
								},
							).length
						}
					>
						<>
							{Object.values(coucheJsonSchemas ?? {}).map(
								(coucheJsonSchema: any) => {
									if (
										[INCIDENT, INTERVENTION, TEMPORARY_CARE].includes(
											coucheJsonSchema.id,
										) ||
										!jsonSchemas[coucheJsonSchema.id]
									) {
										return null
									}
									const geojsonElement = jsonSchemas[coucheJsonSchema.id]

									return (
										<PanelLegendItem
											canDegroup
											canEditStyle
											canGoToList
											canRemove
											count={countPoints[coucheJsonSchema.id]}
											key={coucheJsonSchema.id}
											title={geojsonElement?.title}
											avatar={
												<img
													src={getImage(geojsonElement?.imgId)}
													width={35}
													height={35}
												/>
											}
											jsonSchemaId={coucheJsonSchema.id}
											visible={coucheJsonSchema.visibility}
											visibilityAction={toggleVisibilityJsonSchemas(
												coucheJsonSchema.id,
											)}
											deleteAction={removeCoucheJsonSchemas(
												coucheJsonSchema.id,
											)}
										/>
									)
								},
							)}
						</>
					</CollapsibleList>
				)}
				{shouldDisplaySuivisGroup && (
					<CollapsibleList
						Wrapper={Div}
						buttonText={"Aide aux populations"}
						numberElements={suivisGroups.length}
					>
						<>
							{suivisGroups.map((group) => {
								const ppts = group.geojson.properties
								const nbSupports = Object.values(points).filter((point) =>
									point.geojson.properties.suivi_group_id === group.id
								).length
								const isDisabled = !nbSupports || ppts.ended_at

								return (
									<StyledCard onClick={() => isDisabled ? null : showSupportToCarto(group)}>
										<CardContent $isDisabled={isDisabled}>
											<SListItemAvatar>
												{ppts.ended_at ? <ArchiveIcon /> : <FeedIcon />}
											</SListItemAvatar>
											<StyledListItemText
												primary={ppts.name}
												secondary={`${nbSupports} accompagnement(s)`}
												$isDisabled={isDisabled}
											/>
										</CardContent>
									</StyledCard>
								)
							})}
						</>
					</CollapsibleList>
				)}
				{Object.values(selectedEvent?.mapSignalMarkers ?? {}).length > 0 && (
					<>
						{!isCarto && (
							<CollapsibleList
								Wrapper={Div}
								buttonText="Signalétique"
								numberElements={
									_.uniqBy(
										Object.values(
											_.sortBy(selectedEvent.mapSignalMarkers ?? [], "type") ??
											{},
										),
										"type",
									).length
								}
							>
								<List>
									<>
										{_.uniqBy(
											Object.values(
												_.sortBy(
													selectedEvent.mapSignalMarkers ?? [],
													"type",
												) ?? {},
											),
											"type",
										).map((signalMarker) => (
											<PanelLegendItem
												key={signalMarker.type}
												title={signalMarker.type}
												avatar={SignalService.signalMapping[signalMarker.type](
													"var(--neutral500)",
												)}
												onClick={() => {
													dispatch(toggleCoucheEvents(signalMarker.type))
												}}
												visible={!(coucheEvents?.[signalMarker.type] === false)}
												visibilityAction={() => { }}
												deleteAction={() => { }}
											/>
										))}
									</>
								</List>
							</CollapsibleList>
						)}
					</>
				)}
			</SList>
			<FormProvider {...methods}>
				<SuperModal
					isOpen={!!tmpEditMultipolygon}
					onClose={() => setTmpEditMultipolygon(undefined)}
					onClick={methods.handleSubmit(onSubmit)}
					isNew
					title="Modifier une couche"
				>
					<Flex alignItemsInitial directionColumn gap="1rem">
						<FText name="Nom de la couche" />
						<FColor label="Couleur" name="fillColor" />
						<FText name="details" label="Note (facultatif)" multiline />
					</Flex>
				</SuperModal>
			</FormProvider>
			<ConfirmationModal
				modalShown={isOpenModalConfirmation}
				onClose={() => setIsOpenModalConfirmation(false)}
				message="Voulez-vous vraiment supprimer cette couche dessinée définitivement ?"
				onConfirm={async () => {
					await dispatch(deleteMultiPolygon(currentMultiPolygon))
					await dispatch(
						updateEvent({
							...selectedEvent,
							multiPolygonsIds: selectedEvent.multiPolygonsIds.filter(
								(id) => id !== currentMultiPolygon.id,
							),
						}),
					)
					setIsOpenModalConfirmation(false)
				}}
			/>
			<SPaper>
				{!isCarto && (
					<ButtonMulti
						icon={<Layers />}
						buttonLabel={intercoNameAdapter(
							"Ajouter des données du PCS",
							isInterco,
						)}
						onClick={() => {
							dispatch(setPanel({ type: DAYBOOK_DATA }))
						}}
						options={[
							...(permissions.write
								? [
									{
										label: "Importer un fichier geojson",
										key: "importGeojson",
										icon: <CloudUpload />,
										onClick: () => {
											setIsOpenModalImportGeojson(true)
										},
									},
								]
								: []),
						]}
					/>
				)}
				{isCarto && (
					<Button
						color="primary"
						fullWidth
						onClick={() => {
							dispatch(setPanel({ type: DAYBOOK_DATA }))
						}}
						startContent={<Layers />}
					>
						{intercoNameAdapter("Ajouter des données du PCS", isInterco)}
					</Button>
				)}
			</SPaper>
			<ModalImportGeojson
				isOpen={isOpenModalImportGeojson}
				onClose={() => setIsOpenModalImportGeojson(false)}
			/>
		</SFlex>
	)
}

export default PanelLegend
