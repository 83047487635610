import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { Badge, Popover } from "@mui/material"
import React, { useState } from "react"
import { FormProvider } from "react-hook-form"
import styled, { css } from "styled-components"
import {
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import FChipSelect from "@/utils/form/FChipSelect"
import { OPERATIONNEL_COLOR } from "@/utils/types/IOrga"
import { IFilterFormOptions, NO_FILTER } from "./useFilterForm"
import {
	removeQuotes,
	withoutQuote,
} from "../../../admin/formulaire_dynamique/MyForms/quoteFixForJsonSchema"
import Button from "@/styles/atoms/Button"

const Container = styled.div<{ $size: "medium" | "big" }>`
    padding: 1rem !important;
    ${({ $size }) => {
			if ($size === "medium") {
				return css`
                min-width: 500px;
            `
			} else {
				return css`
                min-width: 650px;
            `
			}
		}}
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const SFlex = styled(Flex)`
    max-width: 60vw;
    min-width: 500px;
    flex-wrap: wrap;
    gap: 1rem;
`
const Filter = ({
	options,
	methods,
	activeFilters,
	size = "medium",
}: {
	options: IFilterFormOptions
	methods: any
	activeFilters: number
	size?: "medium" | "big"
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const isOpen = Boolean(anchorEl)

	return (
		<>
			<Badge
				badgeContent={activeFilters > 9 ? "9+" : activeFilters}
				color="primary"
			>
				<Button
					color="primary"
					fullWidth
					variant="bordered"
					onClick={(event) => {
						setAnchorEl(event.currentTarget)
					}}
					startContent={<FilterAltIcon />}
				>
					Filtrer
				</Button>
			</Badge>
			<Popover
				open={isOpen}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<FormProvider {...methods}>
					<Container $size={size}>
						<Flex fullWidth spaceBetween>
							<H6>Filtrer par :</H6>
							<PrimaryTextButton
								onClick={() => {
									methods.reset()
								}}
							>
								Réinitialiser
							</PrimaryTextButton>
						</Flex>
						<SFlex
							directionColumn
							gap="0.25rem"
							alignItemsInitial
							fullWidth
							$wrap
						>
							{options.map((option) => {
								if ("component" in option) {
									const Component = option.component
									return (
										<Flex spaceBetween gap key={option.name} $wrap>
											<div>{option.name}</div>
											<Component
												name={withoutQuote(option.name)}
												label={undefined}
												small
												{...(option?.componentOptionalProps ?? {})}
											/>
										</Flex>
									)
								}
								return (
									<FChipSelect
										key={option.name}
										name={withoutQuote(option.name)}
										options={[...option.items, NO_FILTER]}
										renderOption={(item) =>
											item === NO_FILTER
												? option.noFilterLabel ?? NO_FILTER
												: option.renderItem?.(item)
										}
									/>
								)
							})}
						</SFlex>
					</Container>
				</FormProvider>
			</Popover>
		</>
	)
}

export default Filter
