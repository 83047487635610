import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { CardContent, Table, TableCell, TableRow } from "@mui/material"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import { setPanel } from "@/redux-toolkit/common/reducer"
import Debug from "debug"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Flex } from "@/utils/components/style/flex"
import { DAYBOOK_SUPPORTS_LIST } from "./PanelCarto"
import Button from "@/styles/atoms/Button"
import PointCardHeader from "../components/jsonSchema/PointCardHeader"
import { getNextSuivi } from "@/pages/maincourante/suivi/TableSupport"
import moment from "moment"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"
import { StyledChip } from "../components/style/chip"

const debug = Debug("app:javascript:utils:panels:PanelDaybookPoint")
debug.log = console.log.bind(console)

const MyLeftCell = styled(TableCell)`
    padding: 8px 12px 8px 0 !important;
    width: 30%;
`
const MyRightCell = styled(TableCell)`
    padding: 8px 0 8px 12px !important;
    text-align: right !important;
    width: 70%;
`

const PanelSupportPoint = () => {
    const daybookPannel = useSelector((state: any) => state.common.panel)
    const points = usePoints()
    const currentSupport = points[daybookPannel.id]
    const concernedPoint = points[currentSupport.geojson.properties.relatedObject.objectId]
    const jsonSchemas = useJsonSchemasWithStatics()
    const jsonSchema = jsonSchemas[currentSupport?.jsonschema_id]
    const nextSuivi = getNextSuivi(currentSupport.geojson.properties.suivi)
    const dispatch = useDispatch()

    useEffect(() => {
        const geolocProperty = jsonSchema?.template?.properties?.find(
            (property) => property.type === "geoloc",
        )
        if (!geolocProperty) return

        const geoloc = concernedPoint.geojson.properties[geolocProperty.name]
        if (!geoloc || !geoloc?.coo?.lat || !geoloc?.coo?.lon) return

        MapService.getMap()?.setView([geoloc.coo.lat, geoloc.coo.lng])
    }, [concernedPoint])

    if (!currentSupport) return null

    const getColorPpt = (suivi) => {
        const statut = suivi["Statut"]
        const suiviDate = suivi["Date prise de contact"]
        const colorPpt = statut === "À faire"
            ? moment().isAfter(suiviDate)
                ? "var(--red)"
                : "var(--orange)"
            : statut === "En cours"
                ? moment().isAfter(suiviDate)
                    ? "var(--red)"
                    : "var(--yellow)"
                : "var(--green)"

        return colorPpt
    }

    return (
        <>
            <Flex fullWidth spaceBetween padding="1rem">
                <Button
                    color="primary"
                    variant="bordered"
                    $margin="1rem"
                    onClick={() => {
                        dispatch(
                            setPanel({
                                type: DAYBOOK_SUPPORTS_LIST,
                            }),
                        )
                    }}
                    startContent={<ArrowBackIcon />}
                >
                    Retour aux accompagnements
                </Button>
            </Flex>
            <PointCardHeader point={concernedPoint} />
            <CardContent>
                <Table size="small" style={{ tableLayout: "fixed" }}>
                    <TableRow>
                        <MyLeftCell component="th" scope="row">
                            Prochaine action
                        </MyLeftCell>
                        <MyRightCell>
                            {nextSuivi["Suites à donner"] ?? "Pas de suivi prévu"}
                        </MyRightCell>
                    </TableRow>
                    <TableRow>
                        <MyLeftCell component="th" scope="row">
                            Statut
                        </MyLeftCell>
                        <MyRightCell>
                            {nextSuivi["Statut"] && (
                                <StyledChip
                                    label={nextSuivi["Statut"]}
                                    $colorProperty={() => getColorPpt(nextSuivi)}
                                    variant="outlined"
                                />
                            )}
                            {!nextSuivi["Statut"] && (
                                "-"
                            )}
                        </MyRightCell>
                    </TableRow>
                    <TableRow>
                        <MyLeftCell component="th" scope="row">
                            Date
                        </MyLeftCell>
                        <MyRightCell>
                            {nextSuivi["Date prise de contact"] ?
                                moment(nextSuivi["Date prise de contact"]).format("DD/MM/YYYY HH:mm")
                                : "Pas de date programmée"}
                        </MyRightCell>
                    </TableRow>
                    <TableRow>
                        <MyLeftCell component="th" scope="row">
                            Observation
                        </MyLeftCell>
                        <MyRightCell>
                            {nextSuivi["Observation"] ?? "-"}
                        </MyRightCell>
                    </TableRow>
                    <TableRow>
                        <MyLeftCell component="th" scope="row">
                            Référent
                        </MyLeftCell>
                        <MyRightCell>
                            {nextSuivi["responsableId"]?.map((respId) =>
                                <HumanChip
                                    human={points[respId]}
                                />
                            ) ?? "Aucun"}
                        </MyRightCell>
                    </TableRow>
                </Table>
            </CardContent>
        </>
    )
}

export default PanelSupportPoint
