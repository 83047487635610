import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectCooperationFilters = createDeepEqualSelector(
	(state: any) => state.edit.cooperationFilters,
	(item: any) => item,
)

export interface ICooperationFilters {
	search: string
	tag?: {
		key: string
		label: string
		jsonschema_id: string
	}
	layer?: {
		key: string
		label: string
		jsonschema_id: string
	}
	communes: any[]
	excludeCommunes: boolean
	changeMapView: boolean
	availableLayers: any[]
	view: string
}

const useCooperationFilters: () => ICooperationFilters = () => {
	return useSelector(selectCooperationFilters)
}

export default useCooperationFilters
