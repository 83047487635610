import { useCallback, useState } from "react"

const useReactTableContainer = () => {
	const [value, setValue] = useState<HTMLDivElement>(undefined)

	const callbackRef = useCallback((node) => {
		setValue(node)
	}, [])

	return {
		value,
		callbackRef,
	}
}
export default useReactTableContainer
