import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "../rootActions"

const initialState = {
	communes: undefined,
	infos: undefined,
	cdcSharedJsonSchemas: undefined,
}

const cdcSlice = createSlice({
	name: "cdc",
	initialState,
	reducers: {
		setCommunesCdc(state, { payload: communes }) {
			state.communes = communes
		},
		setCdcInfos(state, { payload: infos }) {
			state.infos = infos
		},
		setCdcSharedJsonSchemas(state, { payload: cdcSharedJsonSchemas }) {
			state.cdcSharedJsonSchemas = cdcSharedJsonSchemas
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default cdcSlice.reducer
export const { setCommunesCdc, setCdcInfos, setCdcSharedJsonSchemas } = cdcSlice.actions
