import React from "react"
import styled from "styled-components"
import Tooltip from "@mui/material/Tooltip"
const StyledTitle = styled.div`
    font-size: var(--font-size-small);
`
const CustomTooltip = ({ title, children, ...otherProps }) => {
	return (
		<Tooltip title={<StyledTitle>{title}</StyledTitle>} {...otherProps}>
			{children}
		</Tooltip>
	)
}

export default CustomTooltip
