import Axios from "axios"

const getCommunesCdc = async () => {
	const result = await Axios.get("/api/communes/cdc")
	return result.data
}

const getPicrimCdc = async (communeId) => {
	const result = await Axios.get(`/api/picrim/cdc/${communeId}`)
	return result.data
}

export default {
	getCommunesCdc,
	getPicrimCdc,
}
