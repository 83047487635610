import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useEffectOnce, useLocalStorage } from "react-use"
import {
	removeQuotes,
	withoutQuote,
} from "../../../admin/formulaire_dynamique/MyForms/quoteFixForJsonSchema"

export const NO_FILTER = "Pas de filtre"
export const VIGILANCE_FILTER = "current"

export type IFilterFormOptions = ({
	name: string
	default?: any
	path?: string
	noFilterLabel?: string
	persistent?: string
	getItemValue?: (item: any) => any
} & (
	| { items: string[]; renderItem?: (item: string) => string }
	| {
			component: (props: {
				name: string
				label: string
				small?: boolean
			}) => JSX.Element
			componentOptionalProps?: {
				[key: string]: any
			}
	  }
))[]

const useFilterForm = ({
	options,
	data,
	prefixPath = "geojson.properties.",
}: {
	options: IFilterFormOptions
	data?: any[]
	prefixPath?: string
}) => {
	const [persisted, setPersisted] = useLocalStorage("useFilterForm", {})
	const [activeFilters, setActiveFilters] = useState(0)

	const defaultValues = useMemo(() => {
		return Object.fromEntries(
			(options ?? []).map((option) => [
				withoutQuote(option.name),
				(option.persistent && persisted[option.persistent]) ??
					option.default ??
					null,
			]),
		)
	}, options)

	const methods = useForm({
		defaultValues,
	})

	const calculateCounterFromValues = (values) => {
		let tempCounter = 0
		Object.values(values).forEach((value) => {
			if (Array.isArray(value)) {
				tempCounter = tempCounter + value.length
				return
			}
			if (value && value !== NO_FILTER) {
				tempCounter = tempCounter + 1
			}
		})
		setActiveFilters(tempCounter)
	}

	useEffectOnce(() => {
		defaultValues["Vigilances"] === VIGILANCE_FILTER
			? setActiveFilters(activeFilters + 1)
			: null
	})

	methods.watch()

	useEffect(() => {
		const subscription = methods.watch((value, { name }) => {
			if (!_.isEqual(value, defaultValues)) {
				calculateCounterFromValues(value)
			} else {
				setActiveFilters(0)
				defaultValues["Vigilances"] === VIGILANCE_FILTER
					? setActiveFilters(activeFilters + 1)
					: null
			}
			if (!name) return
			const option = options.find((opt) => withoutQuote(opt.name) === name)
			if (option?.persistent)
				setPersisted({
					...persisted,
					[option.persistent]: value[name],
				})
		})
		return () => subscription.unsubscribe()
	}, [methods.watch])

	const filteredData = useMemo(() => {
		if (_.isEmpty(options)) return data
		const values = methods.getValues()
		return data?.filter((item) => {
			return Object.entries(values).every(([key, value]) => {
				const currentOption = options.find(
					(opt) => withoutQuote(opt.name) === key,
				)
				const itemValue = currentOption.getItemValue
					? currentOption.getItemValue(item)
					: _.get(
							item,
							`${prefixPath}${currentOption.path ?? currentOption.name ?? key}`,
					  )
				if (value === NO_FILTER) return true
				if (_.isEqual(value, [])) return true
				if (Array.isArray(value)) {
					return value?.some((v) => itemValue?.some((i) => _.isEqual(i, v)))
				}
				if (Array.isArray(itemValue)) {
					return itemValue?.some((i) => _.isEqual(i, value))
				}

				return _.isEqual(itemValue, value)
			})
		})
	}, [methods.getValues(), data])

	return {
		filteredData,
		values: methods.getValues(),
		methods,
		options,
		activeFilters,
	}
}

export default useFilterForm
