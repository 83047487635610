import {
	RESOURCES_LIEUX_BATIMENTS_COMMUNAUX,
	RESOURCES_MATERIELS_STOCKS_COMMUNAUX,
	RESSOURCES,
	DISPOSITIF_GESTION_CRISE,
	EVACUATION_CONFINEMENT,
	ANNUAIRES,
	ANNUAIRES_COMMUNE,
} from "@/utils/menuTab"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import IJsonSchema, {
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"

export enum StaticFormType {
	ANNUAIRE_PERSONNEL_COMMUNAL = "Annuaire du personnel communal",
	ANNUAIRES_ELUS = "Annuaire des élus",
	VEHICULES = "Véhicules",
	PCC = "Poste de Commandement Communal",
	CARE = "Centre d’Accueil et de Regroupement CARE",
	STOCK = "Stock",
	STOCK_PLACE = "Lieux de stockage",
	RESERVE_COMMUNAL_SECURITE_CIVILE = "Réserve Communale de Sécurité Civile",
	SECTEURS = "Référents secteurs et circuits alerte",
	POINT_RASSEMBLEMENT = "Points de rassemblement",

	// daybook
	ALERTE = "Alerte",
	DISPOSITIF = "Dispositif",

	// other
	ALERTE_MESSAGE = "Message d'alerte",
}

export const SECTEURS: IJsonSchema = {
	id: StaticFormType.SECTEURS,
	imgId: StaticFormType.SECTEURS,
	title: StaticFormType.SECTEURS,
	pcsTitle: "AL03 - Référents secteurs et circuits alerte",
	main_category: DISPOSITIF_GESTION_CRISE,
	sub_category: "Alerte et secteurs",
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				label: "Localisation",
				name: "Localisation",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				label: "Responsables",
				name: "Responsables",
				type: JsonSchemaPropertiesType.RESPONSABLES,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Suppléants",
				name: "Suppléants",
				type: JsonSchemaPropertiesType.RESPONSABLES,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Membres",
				name: "Membres",
				type: JsonSchemaPropertiesType.RESPONSABLES,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
		],
	},
}

export const POINT_RASSEMBLEMENT: IJsonSchema = {
	id: StaticFormType.POINT_RASSEMBLEMENT,
	imgId: StaticFormType.POINT_RASSEMBLEMENT,
	title: StaticFormType.POINT_RASSEMBLEMENT,
	main_category: DISPOSITIF_GESTION_CRISE,
	sub_category: EVACUATION_CONFINEMENT,
	pcsTitle: "EC03 - Points de rassemblement",
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				label: "Lieu",
				name: "Lieu",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Localisation",
				name: "Localisation",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
		],
	},
}

export const ANNUAIRES_ELUS: IJsonSchema = {
	id: StaticFormType.ANNUAIRES_ELUS,
	imgId: StaticFormType.ANNUAIRES_ELUS,
	title: StaticFormType.ANNUAIRES_ELUS,
	main_category: ANNUAIRES,
	sub_category: ANNUAIRES_COMMUNE,
	is_telealert: true,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Poste",
				name: "Poste",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Nom",
				name: "Nom",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Prénom",
				name: "Prénom",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Téléphones",
				name: "Téléphones",
				type: JsonSchemaPropertiesType.PHONE,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Email",
				name: "Email",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Adresse",
				name: "Adresse",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
		],
	},
}

export const ANNUAIRES_PERSONNEL_COMMUNAL: IJsonSchema = {
	id: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
	imgId: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
	title: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
	title_interco: "Annuaire du personnel intercommunal",
	alias: ["Agents"],
	main_category: ANNUAIRES,
	sub_category: ANNUAIRES_COMMUNE,
	is_telealert: true,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Service",
				name: "Service",
				type: JsonSchemaPropertiesType.CREATABLE_SELECT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Poste",
				name: "Poste",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Nom",
				name: "Nom",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Prénom",
				name: "Prénom",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Téléphones",
				name: "Téléphones",
				type: JsonSchemaPropertiesType.PHONE,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Email",
				name: "Email",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Adresse",
				name: "Adresse",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
		],
	},
}

export const RESERVE_COMMUNAL_SECURITE_CIVILE: IJsonSchema = {
	id: StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
	imgId: StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
	title: StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
	title_interco: "Réserve intercommunale de sécurité civile",
	main_category: ANNUAIRES,
	sub_category: ANNUAIRES_COMMUNE,
	is_telealert: true,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Profession",
				name: "Profession",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Nom",
				name: "Nom",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Prénom",
				name: "Prénom",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Téléphones",
				name: "Téléphones",
				type: JsonSchemaPropertiesType.PHONE,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Email",
				name: "Email",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Missions",
				name: "Missions",
				type: JsonSchemaPropertiesType.CHECKBOX,
				items: [
					"Toutes missions nécessaires",
					"Sensibilisation de la population aux risques",
					"Alerte et évacuation de la population",
					"Suivi des personnes vulnérables",
					"Maintien d'un cordon de sécurité",
					"Assistance aux formalités administratives",
					"Collecte et distribution des dons",
				],
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Adresse",
				name: "Adresse",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
		],
	},
}

export const VEHICULES: IJsonSchema = {
	id: StaticFormType.VEHICULES,
	imgId: StaticFormType.VEHICULES,
	title: StaticFormType.VEHICULES,
	main_category: RESSOURCES,
	sub_category: RESOURCES_MATERIELS_STOCKS_COMMUNAUX,
	sort_by: [
		{
			desc: true,
			property: "Prioritaire",
		},
		{
			desc: false,
			property: "Type",
		},
	],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Prioritaire",
				name: "Prioritaire",
				type: JsonSchemaPropertiesType.RADIO,
				items: ["Prioritaire", "Non prioritaire"],
				size: 100,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Type",
				name: "Type",
				type: JsonSchemaPropertiesType.SELECT,
				items: [
					"Engin de chantier",
					"Utilitaire",
					"Voiture",
					"Tracteur et appareil agricole",
					"Transport de marchandises",
					"Transport de personnes",
					"Remorque",
					"Engin de service hivernal",
					"Bateau",
					"Deux roues",
				],
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Tags",
				name: "Tags",
				type: JsonSchemaPropertiesType.TAGS,
				tags: "vehicules",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Modèle",
				name: "Modèle",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Immatriculation",
				name: "Immatriculation",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Places",
				name: "Places",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Carburant",
				name: "Carburant",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observation",
				name: "Observation",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Lieu de stockage",
				name: "Lieu de stockage",
				type: JsonSchemaPropertiesType.STOCKPLACE,
			},
		],
	},
}

export const PCC: IJsonSchema = {
	id: StaticFormType.PCC,
	imgId: StaticFormType.PCC,
	title: StaticFormType.PCC,
	title_interco: "Poste de Commandement Intercommunal",
	main_category: RESSOURCES,
	sub_category: RESOURCES_LIEUX_BATIMENTS_COMMUNAUX,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Lieu",
				name: "Lieu",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Responsable",
				name: "Responsable",
				type: JsonSchemaPropertiesType.RESPONSABLES,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Téléphones",
				name: "Téléphones",
				type: JsonSchemaPropertiesType.PHONE,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Email",
				name: "Email",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Site internet",
				name: "Site internet",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Équipement",
				name: "Équipement",
				type: JsonSchemaPropertiesType.CHECKBOX,
				items: [
					"Ordinateur",
					"Téléphone",
					"Rétroprojecteur",
					"Internet",
					"Malette PCS",
					"Papeterie",
				],
			},
			// {
			//     isPublic: true, isColumn: false,
			//     isIdentifiant: false,
			//     isPcs: true,
			//     isRequired: false,
			//     label: 'Plan',
			//     name: 'Plan',
			//     type: JsonSchemaPropertiesType.TEXTAREA,
			// },
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Adresse",
				name: "Adresse",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
		],
	},
}

export const CARE: IJsonSchema = {
	id: StaticFormType.CARE,
	imgId: StaticFormType.CARE,
	title: StaticFormType.CARE,
	main_category: RESSOURCES,
	sub_category: RESOURCES_LIEUX_BATIMENTS_COMMUNAUX,
	pcsTitle: "Centre Accueil et Regroupement",
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Ouverture",
				name: "Ouverture",
				type: JsonSchemaPropertiesType.RADIO,
				items: ["Prioritaire", "Non prioritaire"],
				size: 100,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Type",
				name: "Type",
				type: JsonSchemaPropertiesType.CHECKBOX,
				items: [
					"Lieu d'accueil",
					"Lieu d'hébergement",
					"Lieu de restauration",
					"Lieu chauffé",
					"Lieu rafraîchi",
				],
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Lieu",
				name: "Lieu",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Responsable",
				name: "Responsable",
				type: JsonSchemaPropertiesType.RESPONSABLES,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Téléphones",
				name: "Téléphones",
				type: JsonSchemaPropertiesType.PHONE,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Surface",
				name: "Surface",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Capacité debout",
				name: "Capacité debout",
				type: JsonSchemaPropertiesType.NUMBER,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Capacité couché",
				name: "Capacité couché",
				type: JsonSchemaPropertiesType.NUMBER,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Équipements",
				name: "Équipements",
				type: JsonSchemaPropertiesType.CHECKBOX,
				items: [
					"Accès PMR",
					"Sanitaires",
					"Vestiaires",
					"Douches",
					"Cuisine",
					"Chambre froide",
					"Local poubelles",
					"Chaufferie",
					"Local électrique",
					"Laverie",
				],
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Adresse",
				name: "Adresse",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
		],
	},
}

export const STOCK: IJsonSchema = {
	id: StaticFormType.STOCK,
	imgId: StaticFormType.STOCK,
	title: StaticFormType.STOCK,
	main_category: RESSOURCES,
	sub_category: RESOURCES_MATERIELS_STOCKS_COMMUNAUX,
	sort_by: [
		{
			desc: true,
			property: "Prioritaire",
		},
		{
			desc: true,
			property: "Nature",
		},
	],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Prioritaire",
				name: "Prioritaire",
				type: JsonSchemaPropertiesType.RADIO,
				items: ["Prioritaire", "Non prioritaire"],
				size: 100,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Nature",
				name: "Nature",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Quantité",
				name: "Quantité",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Tags",
				name: "Tags",
				type: JsonSchemaPropertiesType.TAGS,
				tags: "stocks",
			},
			{
				isPublic: true,
				isColumn: false,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Observations",
				name: "Observations",
				type: JsonSchemaPropertiesType.TEXTAREA,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: true,
				label: "Lieu de stockage",
				name: "Lieu de stockage",
				type: JsonSchemaPropertiesType.STOCKPLACE,
			},
		],
	},
}

export const STOCK_PLACE: IJsonSchema = {
	id: StaticFormType.STOCK_PLACE,
	imgId: StaticFormType.STOCK_PLACE,
	title: StaticFormType.STOCK_PLACE,
	main_category: RESSOURCES,
	sub_category: RESOURCES_LIEUX_BATIMENTS_COMMUNAUX,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				label: "Lieu",
				name: "Lieu",
				type: JsonSchemaPropertiesType.TEXT,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Responsables",
				name: "Responsables",
				type: JsonSchemaPropertiesType.RESPONSABLES,
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: false,
				isPcs: true,
				isRequired: false,
				label: "Adresse",
				name: "Adresse",
				type: JsonSchemaPropertiesType.GEOLOC,
			},
		],
	},
}

export const INTERVENTION_SCHEMA = {
	id: INTERVENTION,
	imgId: INTERVENTION,
	title: "Intervention",
	main_category: undefined,
	sub_category: undefined,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				type: JsonSchemaPropertiesType.DATE,
				label: "Date de début",
				name: "Date de début",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				type: JsonSchemaPropertiesType.TEXT, // ? false, but not important
				label: "Statut",
				name: "Statut",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.TEXT,
				label: "Objet",
				name: "Objet",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.GEOLOC,
				label: "Adresse",
				name: "Adresse",
			},
		],
	},
}

export const INCIDENT_SCHEMA = {
	id: INCIDENT,
	imgId: INCIDENT,
	title: "Evènement",
	main_category: undefined,
	sub_category: undefined,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				type: JsonSchemaPropertiesType.DATE,
				label: "Date de début",
				name: "Date de début",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.TEXT,
				label: "Priorité",
				name: "Priorité",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				type: JsonSchemaPropertiesType.TEXT, // ? false, but not important
				label: "Statut",
				name: "Statut",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: false,
				type: JsonSchemaPropertiesType.TEXT, // ? false, but not important
				label: "Type",
				name: "Type",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.TEXT,
				label: "Objet",
				name: "Objet",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.GEOLOC,
				label: "Géolocalisation",
				name: "Géolocalisation",
			},
		],
	},
}

export const TEMPORARY_CARE_SCHEMA = {
	id: TEMPORARY_CARE,
	imgId: TEMPORARY_CARE,
	title: "Centre d'accueil temporaire",
	main_category: undefined,
	sub_category: undefined,
	sort_by: [],
	template: {
		properties: [
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.TEXT,
				label: "Lieu",
				name: "Lieu",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.TEXT,
				label: "Capacité",
				name: "Capacité",
			},
			{
				isPublic: true,
				isColumn: true,
				isIdentifiant: true,
				isPcs: true,
				isRequired: true,
				type: JsonSchemaPropertiesType.GEOLOC,
				label: "Adresse",
				name: "Adresse",
			},
		],
	},
}

const isStaticForm = (name) => {
	let result = false as any
	getStaticForms().forEach((staticForm) => {
		if (name === staticForm.id) {
			result = staticForm
		}
	})
	return result
}

const getStaticForms = () => {
	return [
		ANNUAIRES_ELUS,
		ANNUAIRES_PERSONNEL_COMMUNAL,
		RESERVE_COMMUNAL_SECURITE_CIVILE,
		STOCK_PLACE,
		CARE,
		PCC,
		STOCK,
		VEHICULES,
		SECTEURS,
		POINT_RASSEMBLEMENT,
	]
}

const getIntercoStaticForms = () => {
	return [
		ANNUAIRES_ELUS,
		ANNUAIRES_PERSONNEL_COMMUNAL,
		RESERVE_COMMUNAL_SECURITE_CIVILE,
		STOCK_PLACE,
		CARE,
		PCC,
		STOCK,
		VEHICULES,
	]
}

export default {
	isStaticForm,
	getStaticForms,
	getIntercoStaticForms,
}
