/* eslint-disable react/no-unescaped-entities */
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { Paper } from "@mui/material"
import Debug from "debug"
import React, { useContext } from "react"
import { isDesktop } from "react-device-detect"
import styled from "styled-components"
import { ScreenShotContext } from "./DaybookDashboard"
import DrawControl from "./subComponents/bottomCenterControl/DrawControl"
import IncidentControl from "./subComponents/bottomCenterControl/IncidentControl"
import LayersControl from "./subComponents/bottomCenterControl/LayersControl"
import PhotoControl from "./subComponents/bottomCenterControl/PhotoControl"
import RequestControl from "./subComponents/bottomCenterControl/RequestControl"
import SignalControl from "./subComponents/bottomCenterControl/SignalControl"

// app:javascript:components:maincourante:BottomCenterControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:BottomCenterControl",
)
debug.log = console.log.bind(console)

const Control = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 0;
    z-index: 999;
`

const FirstPaper = styled(Paper)`
    height: 55px;
    width: 30px;
    background-color: var(--primary800) !important;
    border-radius: 100% 0px 0px 0px !important;
`
const LastPaper = styled(Paper)`
    height: 55px;
    width: 30px;
    background-color: var(--primary800) !important;
    border-radius: 0px 100% 0px 0px !important;
`

const BottomCenterControl = ({
	displayDraw,
	displaySignal,
	displayIncidentControl,
	tempDrawCouche,
	setTempDrawCouche,
	isCaptureSelect,
	setIsCaptureSelect,
}) => {
	const incidentPermissions = useAccessRights(EnumToolPath.INCIDENT)
	const cartographyPermissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked

	if (isTakingScreenshotMap) {
		return null
	}
	return (
		<Control className='BottomCenterControl'>
			<FirstPaper />
			{!isLocked && (
				<>
					{isDesktop && displayDraw && cartographyPermissions.write && (
						<DrawControl />
					)}
					{cartographyPermissions.write && displaySignal && <SignalControl />}
					{incidentPermissions.write && displayIncidentControl && (
						<IncidentControl />
					)}
				</>
			)}
			<RequestControl />
			{isDesktop && (
				<PhotoControl
					tempDrawCouche={tempDrawCouche}
					setTempDrawCouche={setTempDrawCouche}
					isCaptureSelect={isCaptureSelect}
					setIsCaptureSelect={setIsCaptureSelect}
				/>
			)}
			{isDesktop && <LayersControl />}
			<LastPaper />
		</Control>
	)
}

export default BottomCenterControl
