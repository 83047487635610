import { renderTimeViewClock } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { fr } from "date-fns/locale"
import moment from "moment"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import { Neutral350Text, Small } from "utils/components/style/text"

const Container = styled.div<{ widthAuto: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${({ widthAuto }) =>
		css`
      width: ${widthAuto ? "auto" : "100%"}
    `}
`

const FDateTime = ({
	name,
	label = undefined,
	details = undefined,
	placeholder = "",
	noLabel = false,
	disabled = false,
	InputProps = {},
	widthAuto = false,
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const {
		field: { ref, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<Container widthAuto={widthAuto}>
			{details && (
				<Neutral350Text>
					<Small>{details}</Small>
				</Neutral350Text>
			)}
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
				<DesktopDateTimePicker
					ampm={false}
					format="dd/MM/yyyy HH:mm"
					label={label ?? name}
					{...inputProps}
					value={inputProps.value ? new Date(inputProps.value) : null}
					onChange={(date) => {
						let newDate = moment(date).format()
						if (newDate === "Invalid date") newDate = ""
						inputProps.onChange(newDate.toLocaleString())
					}}
					disabled={disabled}
					viewRenderers={{
						hours: renderTimeViewClock,
						minutes: renderTimeViewClock,
					}}
				/>
			</LocalizationProvider>
		</Container>
	)
}

export default FDateTime
