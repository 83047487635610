import React, { useState } from "react"
import NestedListChildrenBase, {
	NestedListChildrenProps,
} from "./NestedListChildrenBase"

const NestedListChildren = (props: NestedListChildrenProps) => {
	const [open, setOpen] = useState(false)

	return <NestedListChildrenBase open={open} setOpen={setOpen} {...props} />
}

export default NestedListChildren
