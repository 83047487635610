import { yupResolver } from "@hookform/resolvers/yup"
import Debug from "debug"
import React, { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Flex } from "@/utils/components/style/flex"
import FSelectHumans from "@/utils/form/FSelectHumans"
import FText from "@/utils/form/FText"
import SuperModal from "@/utils/modals/SuperModal"
import Yup from "@/utils/yup"
import _ from "lodash"
import Moment from "moment"
import usePoints from "@/hooks/usePoints"
import { SUIVI_PCS } from "@/redux-toolkit/userSettings/constants"
import FCustomCreatableSuitesType from "./FCustomCreatableSuitesType"
import useAccessRights from "@/hooks/useAccessRights"
import FDateTime from "@/utils/form/FDateTime"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { useParams } from "react-router-dom"
import FSelect from "@/utils/form/FSelect"

// app:javascript:components:maincourante:suivi:ModalSuiviElement.tsx
const debug = Debug(
	"app:javascript:components:maincourante:suivi:ModalSuiviElement",
)
debug.log = console.log.bind(console)

const schema = Yup.object({
	responsableId: Yup.array(Yup.string()).required().default(null),
	"Suites à donner": Yup.string().required().default(null),
	"Date prise de contact": Yup.string()
		.required()
		.default(Moment().format("DD/MM/YYYY HH:mm")),
	Observation: Yup.string().nullable().default(null),
	Statut: Yup.string().required().default("À faire")
})

const ModalSuiviElement = ({
	isOpen,
	onClose,
	suiviElement,
	onSubmit,
	isNew,
	fields,
	disabled = false,
}) => {
	const { facilityId: facilityIdParams } = useParams<{
		facilityId: string
	}>()
	const facilityId = facilityIdParams === "undefined" ? undefined : facilityIdParams
	const points = usePoints()
	const permissions = useAccessRights(EnumToolPath.SUIVI_PCS)

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: _.isEmpty(suiviElement) ? schema.getDefault() : suiviElement,
	})

	useEffect(() => {
		methods.reset(
			_.isEmpty(suiviElement)
				? schema.getDefault()
				: { ...schema.getDefault(), ...suiviElement },
		)
	}, [isOpen, suiviElement])

	const optionsListSuivi = useMemo(() => {
		const createdOptions = []
		Object.values(points)
			.filter((point) => point.jsonschema_id === SUIVI_PCS && point.geojson.properties.suivi_group_id === facilityId)
			.forEach((point) => {
				point.geojson.properties.suivi.forEach((suivi) => {
					const currentOption = suivi?.["Suites à donner"]
					if (currentOption && !createdOptions.includes(currentOption)) {
						createdOptions.push(currentOption)
					}
				})
			})
		fields.forEach((suivi) => {
			const currentOption = suivi?.["Suites à donner"]
			if (currentOption && !createdOptions.includes(currentOption)) {
				createdOptions.push(currentOption)
			}
		})
		return createdOptions
	}, [points, fields])

	const options = ["À faire", "En cours", "Terminé"]

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				onClose={onClose}
				onClick={methods.handleSubmit(onSubmit)}
				isNew={isNew}
				title={
					isNew
						? "Ajouter un suivi"
						: disabled
							? "Informations du suivi"
							: "Modifier le suivi"
				}
				disableSave={!permissions.write || disabled}
			>
				<Flex directionColumn gap="1rem">
					<FSelectHumans
						name="responsableId"
						label="Référent"
						disabled={!permissions.write || disabled}
					/>
					<Flex fullWidth gap="1rem">
						<FCustomCreatableSuitesType
							name="Suites à donner"
							label="Action"
							optionsList={optionsListSuivi}
							disabled={!permissions.write || disabled}
						/>
						<FSelect
							name="Statut"
							label="Statut"
							options={options}
							disabled={!permissions.write || disabled}
						/>
					</Flex>
					<FDateTime
						label="Date"
						name="Date prise de contact"
						disabled={!permissions.write || disabled}
					/>
					<FText
						multiline
						label="Observation"
						name="Observation"
						helperTextOrange="Ne pas entrer de données médicales (RGPD)"
						disabled={!permissions.write || disabled}
					/>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalSuiviElement
