import {
	addCarto,
	replaceCarto,
	removeCarto,
	setUploadedCartos,
} from "./reducer"
import ErrorService from "@/services/ErrorService"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import { UPLOADED_CARTOS } from "@/redux-toolkit/time/constants"
import UploadedCartosApi from "./UploadedCartosApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getCartos = async (dispatch, state) => {
	try {
		const { uploadedCartos, serverDatetime } =
			await UploadedCartosApi.getCartos()
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: UPLOADED_CARTOS,
			}),
		)

		dispatch(setUploadedCartos(uploadedCartos))
	} catch (error) {
		const errorMessage = `Echec de la récupération des cartographies enregistrées, message d'erreur : ${error}`
		ErrorService.error({
			component: "resources:getCartos",
			message: errorMessage,
			dispatch,
		})
	}
}

export const uploadCarto = createAsyncThunk(
	"data/uploadedCartos/uploadCarto",
	async (config: any, { dispatch }) => {
		try {
			const uploaded_carto = await UploadedCartosApi.uploadCarto(config)
			dispatch(addCarto(uploaded_carto))
		} catch (error) {
			const errorMessage = `Echec de l'enregistrement des cartographies enregistrées, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:uploadCarto",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateCarto = createAsyncThunk(
	"data/uploadedCartos/updateCarto",
	async (config: any, { dispatch }) => {
		try {
			const updated_carto = await UploadedCartosApi.updateCarto(config)
			dispatch(replaceCarto(updated_carto))
		} catch (error) {
			const errorMessage = `Echec de la mise-à-jour des cartographies enregistrées, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:updateCarto",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const deleteCarto = createAsyncThunk(
	"data/uploadedCartos/deleteCarto",
	async (id: string, { dispatch }) => {
		try {
			await UploadedCartosApi.deleteCarto(id)
			dispatch(removeCarto(id))
		} catch (error) {
			const errorMessage = `Echec de la suppression de la cartographie enregistrée, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:deleteCarto",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
