/* eslint-disable react/no-unescaped-entities */
import { CardContent } from "@mui/material"
import { useCurrentPng } from "@/hooks/useCurrentPng"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { TEMPORARY_CARE } from "@/redux-toolkit/userSettings/constants"
import React, { useEffect, useMemo } from "react"
import { Pie, PieChart, Tooltip } from "recharts"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { H6 } from "utils/components/style/header"
import { PieContainer, SubPieContainer } from "./DaybookPieStatus"
import { Legend } from "@/styles/atoms/Legend/Legend"

const PieChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.4em 0.2em;
	gap: 0.5rem;
`
const LowPaddingCardContent = styled(CardContent)`
    padding: 0 !important;
`

const DaybookPieCare = ({ setCareBlob, enableAnimation = true }) => {
	const selectedEvent = useSelectedEvent()
	const points = usePoints()
	const [getPng, { ref: pieRef, isLoading, ready, setRef }] = useCurrentPng(
		"pie",
		setCareBlob,
	)

	const concernedPoints = Object.values(points).filter(
		(point) =>
			point.jsonschema_id === TEMPORARY_CARE &&
			point.event_id === selectedEvent?.id,
	)

	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Normal",
				value: 0,
				fill: "grey",
			},
			{
				name: "Temporaire",
				value: 0,
				fill: "black",
			},
		]
		concernedPoints.forEach((point) => {
			if (point.geojson.properties?.["temporary"] === true) {
				newData[1].value += 1
			} else {
				newData[0].value += 1
			}
			return
		})

		return newData.filter((row) => row.value > 0)
	}, [concernedPoints])

	return (
		<PieContainer>
			<SubPieContainer>
				<H6>Centre d'accueil</H6>
				<Flex gap="1rem" justifyCenter>
					<LowPaddingCardContent>
						<PieChartWrapper>
							<PieChart width={100} height={100} ref={pieRef}>
								<Pie
									isAnimationActive={enableAnimation}
									data={pieData}
									dataKey="value"
									innerRadius={20}
									outerRadius={30}
								/>
								<Tooltip />
								<text
									x={50}
									y={50}
									textAnchor="middle"
									dominantBaseline="middle"
								>
									{concernedPoints.length}
								</text>
							</PieChart>
						</PieChartWrapper>
					</LowPaddingCardContent>
					<LowPaddingCardContent>
						<Wrapper>
							<Legend color="grey" />
							Normal
						</Wrapper>
						<Wrapper>
							<Legend color="black" />
							Temporaire
						</Wrapper>
					</LowPaddingCardContent>
				</Flex>
			</SubPieContainer>
		</PieContainer>
	)
}

export default DaybookPieCare
