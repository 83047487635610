import TrashIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
	Alert,
	AlertTitle,
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Chip,
	IconButton,
} from "@mui/material"
import useCareMembers from "@/hooks/care/useCareMembers"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { Pie, PieChart, Tooltip } from "recharts"
import styled, { css } from "styled-components"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import TypeGeoloc from "@/utils/components/jsonSchema/properties/propertiesType/TypeGeoloc"
import TypePhone from "@/utils/components/jsonSchema/properties/propertiesType/TypePhone"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H1 } from "@/utils/components/style/header"
import IGeoloc from "@/utils/types/IGeoloc"
import IPhones from "@/utils/types/IPhones"
import IPoint, { IGeojsonPropertyValue } from "@/utils/types/IPoint"
import { v4 as uuidv4 } from "uuid"
import { useDispatch } from "react-redux"
import {
	CARE_FAMILY,
	PATH_MAIN_COURANTE_CARE,
} from "@/redux-toolkit/userSettings/constants"
import { LEAVING_FAMILY } from "./leaving/ModalDaybookLeavingGroup"
import ModalCAREExtraction from "./modals/ModalCAREExtraction"
import { useForm } from "react-hook-form"
import ModalExtractDownload from "./modals/ModalExtractDownload"
import Moment from "moment"
import { isDesktop, isMobile } from "react-device-detect"
import useAccessRights from "@/hooks/useAccessRights"
import { ExpandLink } from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
import { Legend } from "@/styles/atoms/Legend/Legend"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"
// app:javascript:components:maincourante:care:CareResumeCard.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:CareResumeCard",
)
debug.log = console.log.bind(console)

const SChip = styled(Chip)`
    & > span {
        font-size: 0.8rem;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 0.5em;
`
const GridInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1em;
`
const SCard = styled(Card)`
    width: 100%;
`
// @ts-ignore
const StyledPieChart = styled(PieChart)`
    & > svg {
        overflow: visible !important;
    }
`
const SAlert = styled(Alert)`
    width: 100%;
`
const StyledFlex = styled(Flex)`
    max-width: 300px;
`
const TinyTitle = styled(H1)`
    font-size: 18px;
    padding: 0;
`
const SAvatar = styled(Avatar)`
    font-weight: bold;
`

export const SectionTitle = styled.div`
    font-size: ${isMobile ? "14px" : "16px"};
`
export const alertTextSize = isMobile ? "13px" : "16px"

const SLICE_NUMBER = 2

const CareResumeCard = ({
	care,
	setCurrentTmpCare,
	setIsOpen,
	canDeleteDatas,
}: {
	care: IPoint
	setCurrentTmpCare: (care: IPoint) => void
	setIsOpen: (open: boolean) => void
	canDeleteDatas: boolean
}) => {
	const [isOpenModalExtraction, setIsOpenModalExtraction] = useState(false)
	const [isOpenNext, setIsOpenNext] = useState(false)
	const [data, setData] = useState(undefined)
	const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false)
	const [currentCareId, setCurrentCareId] = useState(undefined)
	const { adultMember, animalMember, babyMember, childMember, specificities } =
		useCareMembers({ care })
	const points = usePoints()
	const history = useHistory()
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const [key, setKey] = useState(undefined)
	const methods = useForm()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const [number, setNumber] = useState(SLICE_NUMBER)
	const isLocked = selectedEvent?.locked

	const datapieWidth = isMobile ? 150 : 200
	const datapieHeight = isMobile ? 150 : 200
	const datapieInnerRadius = isMobile ? 45 : 60
	const datapieOuterRadius = isMobile ? 60 : 80

	const toggler = () => {
		setIsOpenModalExtraction(false)
		setCurrentCareId(undefined)
		methods.reset()
	}

	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Adultes",
				value: adultMember,
				fill: "green",
			},
			{
				name: "Enfants",
				value: childMember,
				fill: "orange",
			},
			{
				name: `Bébés`,
				value: babyMember,
				fill: "purple",
			},
			{
				name: `Animaux`,
				value: animalMember,
				fill: "#513915",
				// brown
			},
		]
		setKey(uuidv4())
		return newData
	}, [adultMember, animalMember, babyMember, childMember])

	const capacite: IGeojsonPropertyValue<number> =
		care.geojson.properties["Capacité"] ?? 0
	const responsableIds: IGeojsonPropertyValue<string[]> =
		care.geojson.properties["Responsable"] ?? []
	const responsables = responsableIds
		.map((id) => points[id])
		.filter((point) => point !== undefined)
	const carePhones: IGeojsonPropertyValue<IPhones[]> =
		care.geojson.properties?.Téléphones ?? []
	const careGeoloc: IGeojsonPropertyValue<IGeoloc> =
		care.geojson.properties?.Adresse ?? {}
	const freePlace = capacite - _.sumBy(pieData, "value")

	const getExtractableFamilyPoints = (familyPoints) => {
		const leftPersons = {}

		const leftCarePersons = Object.values(points).filter(
			(point) =>
				point.jsonschema_id === LEAVING_FAMILY &&
				point.event_id === selectedEvent?.id &&
				point.geojson.properties.careId === currentCareId,
		)

		leftCarePersons.forEach((point) => {
			point.geojson.properties["Points associés"].forEach((pointAssoc) => {
				const readableDate =
					point.geojson.properties["Date de sortie"].slice(8, 10) +
					"/" +
					point.geojson.properties["Date de sortie"].slice(5, 7) +
					"/" +
					point.geojson.properties["Date de sortie"].slice(0, 4) +
					" à " +
					point.geojson.properties["Date de sortie"].slice(11, 13) +
					"h" +
					point.geojson.properties["Date de sortie"].slice(14, 16)
				leftPersons[pointAssoc["Identifiant du point associé"]] = [
					...(leftPersons[pointAssoc["Identifiant du point associé"]] || []),
					{
						"Index Membre": pointAssoc["Index du membre associé"],
						"Date de sortie": readableDate,
						"Lieu de sortie": point.geojson.properties["Lieu de sortie"],
					},
				]
			})
		})

		const extractableFamilyPoints = familyPoints
			.map((point: any) => {
				let refererStatus = "Présent(e)"
				const newFamilyArray = []
				const countedSpecificity = {}
				//* ====================== DATE ======================
				const readableDate =
					point.geojson.properties["Heure arrivée"].slice(8, 10) +
					"/" +
					point.geojson.properties["Heure arrivée"].slice(5, 7) +
					"/" +
					point.geojson.properties["Heure arrivée"].slice(0, 4) +
					" à " +
					point.geojson.properties["Heure arrivée"].slice(11, 13) +
					"h" +
					point.geojson.properties["Heure arrivée"].slice(14, 16)
				//* ==================== RÉFÉRENT ====================
				leftPersons[point.id]?.forEach((element) => {
					if (element["Index Membre"] === -1) {
						refererStatus = "Sorti(e)"
						_.set(
							point["geojson"]["properties"],
							"Date de sortie",
							element["Date de sortie"],
						)
						_.set(
							point["geojson"]["properties"],
							"Lieu de sortie",
							element["Lieu de sortie"],
						)
					}
				})
				point.geojson.properties["Spécificités"]?.forEach((specificity) => {
					if (!countedSpecificity[specificity]) {
						countedSpecificity[specificity] = 0
					}
					countedSpecificity[specificity] = countedSpecificity[specificity] + 1
				})
				//* ==================== FAMILLE =====================
				_.times(point.geojson.properties.family?.length ?? 1, (index) => {
					const memberInfos = { Statut: "Présent(e)" }
					leftPersons[point.id]?.forEach((element) => {
						if (element["Index Membre"] === index) {
							memberInfos["Statut"] = "Sorti(e)"
							;(memberInfos["Date de sortie"] = element["Date de sortie"]),
								(memberInfos["Lieu de sortie"] = element["Lieu de sortie"])
						}
					})
					newFamilyArray.push({
						...point.geojson.properties.family[index],
						...memberInfos,
					})
					point.geojson.properties.family[index]["Spécificités"]?.forEach(
						(specificity) => {
							if (!countedSpecificity[specificity]) {
								countedSpecificity[specificity] = 0
							}
							countedSpecificity[specificity] =
								countedSpecificity[specificity] + 1
						},
					)
				})
				//* ===================== UPDATE =====================
				_.set(point["geojson"]["properties"], "Heure arrivée", readableDate)
				_.set(point["geojson"]["properties"], "Statut", refererStatus)
				_.set(point["geojson"]["properties"], "family", newFamilyArray)
				_.set(
					point["geojson"]["properties"],
					"totalSpecificity",
					countedSpecificity,
				)
				return point
			})
			.filter((x) => x !== null)

		return {
			jsonschemaId: CARE_FAMILY,
			values: extractableFamilyPoints,
		}
	}

	const handleExtract = (values) => {
		const filtersObject =
			Object.values(values).filter((filterValue) => filterValue !== undefined)
				.length === 0
				? undefined
				: {
						enteringDateFilter:
							values["Trier par date de arrivée ( facultatif )"],
						leavingDateFilter:
							values["Trier par date de sortie ( facultatif )"],
						specFilter: values["Trier par spécificités ( facultatif )"],
						typoFilter: values["Trier par typologies ( facultatif )"]?.type,
					}
		const title = care.geojson.properties["Lieu"]

		const getPoints = (jsonschemaId) =>
			Object.values(points).filter(
				(point) =>
					point.geojson?.properties?.["careId"] === currentCareId &&
					point.event_id === selectedEvent?.id &&
					point.jsonschema_id === jsonschemaId,
			)

		const familyPoints = _.cloneDeep(getPoints(CARE_FAMILY))
		const finalDatas = {
			title: title,
			...getExtractableFamilyPoints(familyPoints),
			filters: filtersObject,
		}
		setData(finalDatas)

		setIsOpenNext(true)
		toggler()
	}

	return (
		<>
			<SCard>
				<CardHeader
					title={`${care.geojson.properties?.Lieu ?? ""}`}
					action={
						<Flex gap="0.5rem">
							{isDesktop && (
								<>
									<Button
										color="primary"
										variant="bordered"
										onClick={() => {
											setCurrentCareId(care.id)
											setIsOpenModalExtraction(true)
										}}
									>
										Extraire les données
									</Button>
									<Button
										color="primary"
										onClick={() => {
											history.push(`/maincourante/care/${care.id}`)
										}}
									>
										Gérer {`${care.geojson.properties?.Lieu ?? ""}`}
									</Button>
								</>
							)}
							<IconButton
								onClick={() => {
									setCurrentTmpCare(care)
									setIsOpen(true)
								}}
							>
								<EditIcon />
							</IconButton>
							{canDeleteDatas && (
								<IconButton
									onClick={() => setIsOpenModalConfirmation(true)}
									disabled={isLocked || !permissions.write}
								>
									<TrashIcon />
								</IconButton>
							)}
						</Flex>
					}
				/>
				<CardContent>
					{isMobile && (
						<Flex fullWidth spaceAround padding="0 0 .5em 0">
							<Button
								color="primary"
								variant="bordered"
								onClick={() => {
									setCurrentCareId(care.id)
									setIsOpenModalExtraction(true)
								}}
							>
								Extraire les données
							</Button>
							<Button
								color="primary"
								onClick={() => {
									history.push(`/maincourante/care/${care.id}`)
								}}
							>
								Gérer {`${care.geojson.properties?.Lieu ?? ""}`}
							</Button>
						</Flex>
					)}
					<Flex fullWidth directionColumn={isMobile} alignItemsStart gap="1rem">
						{responsables.length > 0 && (
							<SAlert>
								<AlertTitle>Responsables</AlertTitle>
								<Flex directionColumn alignItemsStart gap="0.5rem">
									{responsables.slice(0, number).map((responsable) => {
										const responsablePhones = (responsable.geojson.properties
											?.Téléphones ?? []) as IPhones[]
										return (
											<Grid key={responsable.id}>
												<div>
													<HumanChip
														human={responsable}
														size={isMobile ? "small" : "medium"}
													/>
												</div>
												<TypePhone
													value={responsablePhones}
													align="left"
													editable={false}
													fontSize={isMobile ? alertTextSize : "0.9rem"}
												/>
											</Grid>
										)
									})}
									{responsables.length > SLICE_NUMBER &&
										(number ? (
											<ExpandLink onClick={() => setNumber(undefined)}>
												+{responsables.length - SLICE_NUMBER} (responsables)
											</ExpandLink>
										) : (
											<ExpandLink onClick={() => setNumber(SLICE_NUMBER)}>
												voir moins
											</ExpandLink>
										))}
								</Flex>
							</SAlert>
						)}
						<SAlert severity="info">
							<AlertTitle>Centre d&apos;accueil</AlertTitle>
							<GridInfo>
								<SectionTitle>Téléphones</SectionTitle>
								<TypePhone
									value={carePhones}
									align="left"
									editable={false}
									fontSize={alertTextSize}
								/>

								<SectionTitle>Adresse</SectionTitle>
								<div>
									<TypeGeoloc
										value={careGeoloc}
										editable={false}
										align="left"
										fontSize={alertTextSize}
									/>
								</div>
							</GridInfo>
						</SAlert>
					</Flex>
					<br />
					<Flex
						gap="2rem"
						directionColumn={isMobile}
						alignItemsStart
						spaceAround
					>
						<Flex>
							<Flex directionColumn alignItemsStart>
								<TinyTitle>Capacité</TinyTitle>
								<Flex gap="0.5rem">
									<StyledPieChart width={datapieWidth} height={datapieHeight}>
										<Pie
											key={key}
											data={[
												{
													name: "Places occupées",
													value:
														adultMember +
														childMember +
														babyMember +
														animalMember,
													fill: freePlace < 0 ? "red" : "#0f206e",
												},
												{
													name: "Places libres",
													value: freePlace < 0 ? 0 : freePlace,
													fill: "#a6b7fa",
												},
											]}
											dataKey="value"
											innerRadius={datapieInnerRadius}
											outerRadius={datapieOuterRadius}
										/>
										<Tooltip />
										<text
											x={datapieWidth / 2}
											y={datapieHeight / 2}
											textAnchor="middle"
											dominantBaseline="middle"
											style={{ fontSize: "1.2rem" }}
										>
											{_.sumBy(pieData, "value")} / {capacite}
										</text>
									</StyledPieChart>
									<Flex directionColumn gap="2rem">
										{freePlace < 0 && (
											<Alert severity="error">
												Attention, le nombre de places maximum a été atteint
											</Alert>
										)}
										<Grid>
											<Legend color="#a6b7fa" />

											<span>Places libres</span>
											<span>{capacite - _.sumBy(pieData, "value")}</span>
											<Legend color="#0f206e" />
											<span>Places occupées</span>
											<span>
												{adultMember + childMember + babyMember + animalMember}
											</span>
										</Grid>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
						<Flex>
							<Flex directionColumn alignItemsStart>
								<TinyTitle>Typologies</TinyTitle>
								<Flex gap="0.5rem">
									<StyledPieChart width={datapieWidth} height={datapieHeight}>
										<Pie
											key={key}
											data={pieData}
											dataKey="value"
											innerRadius={datapieInnerRadius}
											outerRadius={datapieOuterRadius}
										/>
										<Tooltip />
										<text
											x={datapieWidth / 2}
											y={datapieHeight / 2}
											textAnchor="middle"
											style={{ fontSize: "1.2rem" }}
											dominantBaseline="middle"
										>
											{_.sumBy(pieData, "value")}
										</text>
									</StyledPieChart>
									<Grid>
										<Legend color="green" />
										<span>Adultes</span>
										<span>{adultMember}</span>
										<Legend color="orange" />
										<span>Enfants</span>
										<span>{childMember}</span>
										<Legend color="purple" />
										<span>Bébés</span>
										<span>{babyMember}</span>
										<Legend color="#513915" />
										<span>Animaux</span>
										<span>{animalMember}</span>
									</Grid>
								</Flex>
							</Flex>
						</Flex>
						<Flex directionColumn alignItemsStart gap="0.5rem">
							<TinyTitle>Spécificités</TinyTitle>
							<StyledFlex gap="0.25rem" alignItemsStart $wrap>
								{specificities.length === 0 && "Aucune spécificité"}
								{specificities.length > 0 &&
									specificities.map((specificity) => (
										<SChip
											size="small"
											variant="outlined"
											key={specificity.label}
											label={specificity.label}
											avatar={<SAvatar>{specificity.count}</SAvatar>}
										/>
									))}
							</StyledFlex>
						</Flex>
					</Flex>
				</CardContent>
			</SCard>
			<ModalCAREExtraction
				isOpen={isOpenModalExtraction}
				handleExtract={handleExtract}
				methods={methods}
				toggler={toggler}
			/>
			<ModalExtractDownload
				isOpenNext={isOpenNext}
				setIsOpenNext={setIsOpenNext}
				data={data}
			/>
			<ConfirmationModal
				modalShown={isOpenModalConfirmation}
				onClose={() => setIsOpenModalConfirmation(false)}
				message="Voulez-vous vraiment supprimer ce centre d’accueil ?"
				onConfirm={() => {
					dispatch(
						deletePointWithUpdateEvent({
							point: care,
							event: {
								...selectedEvent,
								updated_at: Moment().format(),
							},
						}),
					)
					setIsOpenModalConfirmation(false)
				}}
			/>
		</>
	)
}

export default CareResumeCard
