import { View as RView } from "@react-pdf/renderer"
import { GENERIC_BLUE, GENERIC_HEIGHT } from "@/pages/rgpd/pdf/RgpdPDF"
import React from "react"
const View = RView as any
const PCell = ({
	children,
	head = false,
	borderBottom = false,
	borderRight = false,
	minHeight = GENERIC_HEIGHT,
	width = null,
	...props
}) => {
	return (
		<View
			{...props}
			style={{
				...{
					flex: 1,
					flexDirection: "column",
					padding: "5px",
					minHeight: minHeight,
					// By default, width take all the available place
					maxWidth: width,
					borderColor: GENERIC_BLUE,
				},
				...(head ? { backgroundColor: GENERIC_BLUE, color: "white" } : {}),
				...(borderBottom ? { borderBottomWidth: 1 } : {}),
				...(borderRight ? { borderRightWidth: 1 } : {}),
				...props.style,
			}}
		>
			{children}
		</View>
	)
}

export default PCell
