import html2canvas, { Options } from "html2canvas"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMap } from "react-leaflet"
import L from "leaflet"
import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"

// credits to https://github.com/brammitch/recharts-to-png

export type UseCurrentPng = [
	() => Promise<string | undefined>,
	{
		isLoading: boolean
		ref: React.MutableRefObject<any>
		setRef: (ref: React.MutableRefObject<any>) => void
		ready: boolean
	},
]

/**
 * @param options - optional html2canvas Options object
 */
export const useCurrentPng = (
	recivedType: "chart" | "pie",
	setBlobState: React.Dispatch<any> = undefined,
	options?: Partial<Options>,
): UseCurrentPng => {
	const ref = useRef<any>(null)
	const [ready, setReady] = useState(false)
	const setRef = useCallback((node) => {
		setReady(!!node)
		ref.current = node
	}, [])
	const [isLoading, setIsLoading] = useState(false)
	const { isTakingScreenshotDashboard, setIsTakingScreenshotDashboard } =
		useContext(ScreenShotContext)

	const getPng = async () => {
		if (ref !== null && ref?.current?.container) {
			setIsLoading(true)

			return await html2canvas(ref.current.container as HTMLElement, {
				logging: false,
				...options,
				allowTaint: true,
				useCORS: true,
			}).then((canvas) => {
				setIsLoading(false)
				return canvas.toDataURL("image/png", 1.0)
			})
		}
	}

	useEffect(() => {
		const asyncSetPngResult = async () => {
			if (!isTakingScreenshotDashboard || isLoading) return
			const png = await getPng()
			setBlobState(png)
			setIsTakingScreenshotDashboard(false)
		}
		if (!ready && recivedType === "pie") {
			asyncSetPngResult()
		}
		if (ready && recivedType === "chart") {
			asyncSetPngResult()
		}
	}, [ready, getPng, isTakingScreenshotDashboard])

	return [
		getPng,
		{
			ref,
			isLoading,
			setRef,
			ready,
		},
	]
}
