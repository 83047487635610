import { LEAVING_FAMILY } from "@/pages/maincourante/care/leaving/ModalDaybookLeavingGroup"
import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import usePoints from "../usePoints"
import useSelectedEvent from "../useSelectedEvent"
const useCareLeftPersonsByGroup = () => {
	const points = usePoints()
	const selectedEvent = useSelectedEvent()
	const { careId } = useParams<{ careId: string }>()

	const leftPersonsByGroup = useMemo(() => {
		const leftCarePersons = Object.values(points).filter(
			(point) =>
				point.jsonschema_id === LEAVING_FAMILY &&
				point.event_id === selectedEvent?.id &&
				point.geojson.properties.careId === careId,
		)
		const leftPersons = {}

		leftCarePersons.forEach((point) => {
			point.geojson.properties["Points associés"].forEach((pointAssoc) => {
				leftPersons[pointAssoc?.["Identifiant du point associé"]] = [
					...(leftPersons[pointAssoc?.["Identifiant du point associé"]] || []),
					pointAssoc?.["Index du membre associé"],
				]
			})
		})
		return leftPersons
	}, [careId, points, selectedEvent?.id])
	return leftPersonsByGroup
}

export default useCareLeftPersonsByGroup
