import React from "react"
import Button from "../Button"

const CancelButton = React.forwardRef<
	HTMLButtonElement,
	React.ComponentProps<typeof Button>
>((props, ref) => {
	return (
		<Button {...props} ref={ref} color="primary" variant="light">
			{props.children ? props.children : "Annuler"}
		</Button>
	)
})

export default CancelButton
