import useHumans from "@/hooks/useHumans"
import Debug from "debug"
import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Flex } from "@/utils/components/style/flex"
import SuperDraggable from "@/utils/dnd/SuperDraggable"
import SuperDroppable from "@/utils/dnd/SuperDroppable"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"
// app:javascript:utils:form:FSelectHumans:DraggableHumanTags.tsx
const debug = Debug(
	"app:javascript:utils:form:FSelectHumans:DraggableHumanTags.tsx",
)
debug.log = console.log.bind(console)

const DraggableHumanTags = ({
	getTagProps,
	value,
	onDragEnd,
	readOnly,
	disabled = false,
}) => {
	const humans = useHumans()

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<SuperDroppable
					component={Flex}
					droppableProps={{
						direction: "horizontal",
					}}
					$wrap
					gap="5px"
				>
					{value.map((option: string, index: number) => {
						const human = humans[option]

						return (
							<SuperDraggable
								key={human.id}
								id={human.id}
								index={index}
								disabled={readOnly || disabled}
							>
								<HumanChip
									human={human}
									{...getTagProps({ index })}
									className={undefined}
									{...(readOnly
										? {
												onDelete: undefined,
											}
										: {
												style: {
													cursor: "grab",
													backgroundColor: "white",
												},
											})}
								/>
							</SuperDraggable>
						)
					})}
				</SuperDroppable>
			</DragDropContext>
		</>
	)
}

export default DraggableHumanTags
