import { createAsyncThunk } from "@reduxjs/toolkit"
import { MAPPINGS } from "@/redux-toolkit/time/constants"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import ErrorService from "@/services/ErrorService"
import IMapping from "utils/types/IMapping/IMapping"
import MappingsApi from "./MappingsApi"
import { addMapping, removeMapping } from "./reducer"

export const getMappings = async (dispatch, state) => {
	try {
		const { mappings, serverDatetime } = await MappingsApi.getMappings(
			state.time[MAPPINGS],
		)

		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: MAPPINGS,
			}),
		)
		mappings.forEach((mapping) => dispatch(addMapping(mapping)))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des mappings, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:getMappings",
			message: errorMessage,
			dispatch,
		})
	}
}

export const deleteMapping = createAsyncThunk(
	"data/mapping/deleteMapping",
	async (id: string, { dispatch }) => {
		try {
			await MappingsApi.deleteMappingAdmin(id)
			dispatch(removeMapping(id))
		} catch (error) {
			const errorMessage = `Erreur lors de la création du mapping`
			ErrorService.error({
				component: "resources:deleteMapping",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const createMapping = createAsyncThunk(
	"data/mapping/createMapping",
	async (mapping: any, { dispatch }) => {
		try {
			const { mapping: createdMapping } = await MappingsApi.createMappingAdmin(
				mapping,
			)

			dispatch(addMapping(createdMapping))
		} catch (error) {
			const errorMessage = `Erreur lors de la création du mapping`
			ErrorService.error({
				component: "resources:createMapping",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateMapping = createAsyncThunk(
	"data/mapping/updateMapping",
	async (mapping: any, { dispatch }) => {
		try {
			const { mapping: updatedMapping } = await MappingsApi.updateMappingAdmin(
				mapping,
			)

			dispatch(addMapping(updatedMapping))
		} catch (error) {
			const errorMessage = `Erreur lors de la mise a jour du mapping`
			ErrorService.error({
				component: "resources:updateMapping",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const getAdminMappings = createAsyncThunk(
	"data/mapping/getAdminMappings",
	async (args, { dispatch }) => {
		try {
			const { mappings } = await MappingsApi.getMappingsAdmin()

			mappings.forEach((mapping) => dispatch(addMapping(mapping)))
		} catch (error) {
			const errorMessage = `Erreur lors de la récupération des formulaires dynamiques, message d'erreur : ${error.message}`
			ErrorService.error({
				component: "resources:getAdminMappings",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
