import Axios from "axios"

const getPdfGenerators = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(
		`/api/pdfGenerators?lastSynchro=${lastSynchro}`,
	)
	return result.data
}
const createPdfGenerator = async (data, title) => {
	const res = await Axios.post("/api/pdfsGenerators", {
		data: {
			data: data,
			title: title,
		},
	})
	if (res.status !== 200) {
		throw new Error("Erreur : les modèles d'arrêté n'ont pas pu être généré")
	}
	return res
}
const updatePdfGenerator = async (data, title, id) => {
	const res = await Axios.put(`/api/pdfsGenerators/${id}`, {
		data: {
			data: data,
			title: title,
		},
	})
	if (res.status !== 200) {
		throw new Error("Erreur : les modèles d'arrêté n'ont pas pu être généré")
	}
	return res
}
const deletePdfGenerator = async (id) => {
	const res = await Axios.delete(`/api/pdfsGenerators/${id}`)
	if (res.status !== 200) {
		throw new Error("Erreur : les modèles d'arrêté n'ont pas pu être supprimé")
	}
	return res
}
export default {
	getPdfGenerators,
	createPdfGenerator,
	updatePdfGenerator,
	deletePdfGenerator,
}
