import React from "react"
import { SketchPicker } from "react-color"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import StyledInputLayout from "./formUtils/StyledInputLayout"

const Padding = styled.div`
    padding: 10px;
`

const FColor = ({ name, label }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { invalid, isTouched, isDirty, error },
		formState: { touchedFields, dirtyFields },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<StyledInputLayout
			errors={errors}
			value={value}
			name={name}
			label={label}
			isExternalShrinked
			$letBorderAndShadow
		>
			<Padding>
				<SketchPicker
					color={value}
					onChangeComplete={(color) => onChange(color["hex"])}
				/>
			</Padding>
		</StyledInputLayout>
	)
}

export default FColor
