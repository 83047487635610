import React, { useEffect, useState } from "react"
import useStep from "@/pages/telechargement/Upload/hooks/useStep"
import FormStep from "@/pages/telechargement/Upload/component/FormStep"
import DatasSelection from "./DatasSelection"
import JsonSchemaEdition from "./JsonSchemaEdition"
import MultiPolygonEdition from "./MultiPolygonEdition"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import useMapState from "@/hooks/useMapState"

const StepDatas = ({
	modalPanel,
	setModalPanel,
	setIsEditingCoucheStyle,
	setTempValue,
	...props
}) => {
	const { onBack } = useStep("datas")
	const multiPolygons = useMultiPolygons()
	const { coucheJsonSchemas, coucheMultiPolygons } = useMapState()

	useEffect(() => {
		setTempValue({
			pointsKeys: Object.values(coucheJsonSchemas).map((coucheJsonSchema) => {
				return {
					id: coucheJsonSchema.id,
					style: coucheJsonSchema.style,
				}
			}),
			couchesKeys: Object.values(coucheMultiPolygons).map(
				(coucheMultiPolygon) => {
					return {
						id: coucheMultiPolygon.id,
						style: multiPolygons[coucheMultiPolygon.id]?.custom_props?.style,
					}
				},
			),
		})
	}, [coucheJsonSchemas, coucheMultiPolygons, multiPolygons])

	const renderModalPanel = (panel) => {
		switch (panel) {
			case 0:
				return (
					<DatasSelection
						setModalPanel={setModalPanel}
						setIsEditingCoucheStyle={setIsEditingCoucheStyle}
					/>
				)
			case 1:
				return <JsonSchemaEdition jsonSchemaId={modalPanel.id} />
			case 2:
				return <MultiPolygonEdition multiPolygonId={modalPanel.id} />
			default:
				return (
					<DatasSelection
						setModalPanel={setModalPanel}
						setIsEditingCoucheStyle={setIsEditingCoucheStyle}
					/>
				)
		}
	}

	return (
		<FormStep
			question=""
			formatedValue=""
			onBack={onBack}
			clearStyles
			{...props}
		>
			{renderModalPanel(modalPanel.panel)}
		</FormStep>
	)
}

export default StepDatas
