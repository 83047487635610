/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close"
import HistoryIcon from "@mui/icons-material/History"
import RoomIcon from "@mui/icons-material/Room"
import WarningIcon from "@mui/icons-material/Warning"
import { Divider, IconButton, MenuItem, Paper, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import {
	booleanContains,
	feature,
	featureCollection,
	multiPolygon,
	point,
	polygon,
} from "@turf/turf"
import Axios from "axios"
import useAddressesSearchHistory from "@/hooks/useAddressesSearchHistory"
import useCommune from "@/hooks/useCommune"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useBoolean, useGeolocation } from "react-use"
import styled from "styled-components"
import ApiDataGouvAddress from "utils/api_externe/ApiDataGouvAddress"
import ApiGoogleAddress from "utils/api_externe/ApiGoogleAddress"
import { Flex } from "utils/components/style/flex"
import getImage from "@/utils/getImage"
import IGeoloc from "utils/types/IGeoloc"
import { useSelector } from "react-redux"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import DatabaseService from "@/services/DatabaseService"
import { useDispatch } from "react-redux"
import { CustomChip, StyledChip } from "utils/components/style/chip"
import GeolocUser, { StyledMenuItem } from "utils/form/GeolocUser"
import IMultiPolygon from "utils/types/IMultiPolygon"
import { Marker, useMap } from "react-leaflet"
import _ from "lodash"
import { isMobile } from "react-device-detect"
import SuperControl from "./SuperControl"
import useKeyboardJs from "utils/keyboard/useKeyboardJs"
import MapService from "@/pages/carto2/cartographie/service/MapService"

const HistoryMenuItem = styled(MenuItem)`
    display: flex;
    gap: 0.5rem;
`

const Container = styled(SuperControl)`
    position: absolute;
    z-index: 1000;
    width: 600px;
    max-width: 70vw;
    right: 0;
    margin: 2rem;
    background-color: white;
    border: 1px solid #e0e0e0;
    .MuiOutlinedInput-notchedOutline {
        top: -7px;
    }
`

const StyledCoo = styled.div`
    color: var(--neutral800);
    font-size: var(--font-size-small);
`

const ContainerAdress = styled.div`
    padding: 1rem;
`

const StyledNoCoo = styled.div`
    & > * {
        color: var(--orange600);
        font-size: var(--font-size-small);
    }
    color: var(--orange600);
    font-size: var(--font-size-small);
`
const StyledWarningIcon = styled(WarningIcon)`
    font-size: var(--font-size-small) !important;
    margin-right: 0.5rem;
`

const ChipContainer = styled.div`
    width: 180px !important;
`
/* Defining the different types of options that can be displayed in the dropdown menu. */
enum OptionType {
	MA_POSITION = "MA_POSITION",
	GEOLOCALISER = "GEOLOCALISER",
	ADRESSE = "ADRESSE",
	DIVIDER = "DIVIDER",
	LOADING = "LOADING",
	LOADING_POINTS = "LOADING_POINTS",
	POINTS = "POINTS",
	HISTORY_ADDRESS = "HISTORY_ADDRESS",
}

const SearchControl = ({}) => {
	const [apiAddressResults, setApiAddressResults] = useState([])
	const [lastSearch, setLastSearch] = useState("")
	const [isLoading, setIsLoading] = useBoolean(false)
	const [lastApiCall, setLastApiCall] = useState([])
	const userPosition = useGeolocation({
		enableHighAccuracy: true,
		maximumAge: 0,
	})
	const autoCompleteRef = useRef(null)
	const commune = useCommune()
	const dispatch = useDispatch()
	const deleteRef = useRef(null)
	const map = useMap()
	const multiPolygons = useMultiPolygons()
	const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)
	const [value, setValue] = useState(null)
	const [isPressedEnter] = useKeyboardJs("enter")

	const customContains = (feature, point) => {
		if (feature.type === "FeatureCollection") {
			return feature.features.some((f) => customContains(f, point))
		}
		if (feature.type === "Feature") {
			return customContains(feature.geometry, point)
		}
		if (feature.type === "MultiPolygon") {
			return feature.coordinates.some((polygonCoordinates) =>
				booleanContains(
					{
						type: "Polygon",
						coordinates: polygonCoordinates,
					},
					point,
				),
			)
		}
		return booleanContains(feature, point)
	}

	const onClose = async (optionalValue = undefined) => {
		const usedValue = optionalValue || value
		if (!usedValue?.coo?.lat && usedValue?.coo?.lng) {
			return null
		}
		let calculatedSecteur
		await Promise.all(
			Object.values(multiPolygons)
				.filter(
					(multiPolygon: IMultiPolygon) => multiPolygon.custom_props.secteur,
				)
				.map(async (poly: IMultiPolygon) => {
					if (!calculatedSecteur) {
						let couche = await DatabaseService.get(poly.id)
						couche = JSON.parse(couche)
						if (Array.isArray(couche)) {
							couche = {
								type: "FeatureCollection",
								features: couche,
							}
						}
						let turfCouche
						if (!usedValue?.coo?.lat || !usedValue?.coo?.lng) {
							return null
						}
						const turfPoint = point([usedValue?.coo?.lng, usedValue?.coo?.lat])
						let contained = false
						if (couche["type"] === "Polygon") {
							turfCouche = polygon(couche["coordinates"])
							contained = booleanContains(turfCouche, turfPoint)
						}
						if (couche["type"] === "MultiPolygon") {
							turfCouche = multiPolygon(couche["coordinates"])
							contained = booleanContains(turfCouche, turfPoint)
						}
						if (couche["type"] === "Feature") {
							turfCouche = feature(couche)
							contained = booleanContains(turfCouche, turfPoint)
						}
						if (couche["type"] === "FeatureCollection") {
							turfCouche = featureCollection(couche)
							contained = customContains(turfCouche.features, turfPoint)
						}
						if (!turfCouche) {
							return
						}
						if (contained) {
							calculatedSecteur = poly.id
						}
					}
				}),
		)
		if (calculatedSecteur) {
			setValue({ ...usedValue, secteur: calculatedSecteur })
		} else {
			if (_.isEmpty(usedValue)) {
				return null
			}
			setValue({ ...usedValue, secteur: "Hors secteur" })
		}
	}

	const { addressesSearchHistory, pushAddressToSearchHistory } =
		useAddressesSearchHistory()

	const onChangeWithHistory = (value) => {
		setValue(value)
		pushAddressToSearchHistory(value)
		setTimeout(() => deleteRef.current?.focus?.(), 100)
	}

	const sleep = async (ms) => {
		return new Promise((resolve, reject) => {
			// @ts-ignore
			SearchControl.cancellationToken = {}
			// @ts-ignore
			SearchControl.cancellationToken.cancel = function () {
				reject(new Error("sleep() cancelled"))
			}
			setTimeout(resolve, ms)
		})
	}

	const onSelectGeoloc = (geoloc: IGeoloc) => {
		setLastSearch(geoloc?.Adresse)
		onChangeWithHistory(geoloc)
	}

	const onSelectPoint = (point) => {
		const newAdress = point.geoloc
		onChangeWithHistory(newAdress)
	}

	const onSelectHistoryAddress = (value) => {
		setLastSearch(value?.Adresse)
		onChangeWithHistory(value)
		onClose(value)
	}

	const selectUserLocation = async () => {
		const resultAddress =
			(await ApiDataGouvAddress.reverseSearchLatLng({
				lat: userPosition.latitude,
				lng: userPosition.longitude,
			})) ?? "Pas d'adresse correspondante"
		const newAdress = {
			Adresse: resultAddress,
			coo: {
				lat: userPosition.latitude,
				lng: userPosition.longitude,
			},
		} as IGeoloc
		setLastSearch(newAdress.Adresse)
		onChangeWithHistory(newAdress)
		onClose(newAdress)
	}

	const apiCall = async () => {
		try {
			if (!isLoading) {
				setIsLoading(true)
			}
			// @ts-ignore
			SearchControl.cancellationToken?.cancel()
			await sleep(500)

			let res = []
			setLastSearch(value?.Adresse)
			res = await ApiGoogleAddress.searchAddress(
				value?.Adresse + " " + commune.name,
				dispatch,
			)
			if (res.length === 0) {
				res = await ApiDataGouvAddress.searchAddress(
					value?.Adresse + " " + commune.name,
					dispatch,
				)
			}
			setIsLoading(false)
			setApiAddressResults(res)
			setLastApiCall(res)
		} catch (error) {
			// coucou
		}
	}

	useEffect(() => {
		if (value?.Adresse?.length > 3 && value?.Adresse !== lastSearch) {
			apiCall()
		}
		if (
			value?.Adresse?.length > 0 &&
			value?.Adresse !== lastSearch &&
			!isLoading
		) {
			setIsLoading(true)
		}
	}, [value, lastSearch, isLoading])

	const displayCoo = () => {
		if (value?.coo?.lat && value?.coo?.lng) {
			return `lat : ${value?.coo?.lat} / lng : ${value?.coo?.lng}`
		}
	}

	useEffect(() => {
		if (!autoCompleteOpen) {
			MapService.enableScrollAndDrag()
		}
	}, [autoCompleteOpen])

	useEffect(() => {
		if (isPressedEnter) {
			// seleft first api result
			if (lastApiCall.length > 0) {
				setAutoCompleteOpen(false)
				onSelectGeoloc(lastApiCall[0])
				onClose(lastApiCall[0])
			}
		}
	}, [isPressedEnter, apiAddressResults])

	const renderAdressLatLng = (geoloc) => {
		return (
			<>
				{geoloc?.coo?.lat && geoloc?.coo?.lng ? (
					<>
						<StyledCoo>
							lat : {geoloc?.coo?.lat} / lng : {geoloc?.coo?.lng}
						</StyledCoo>
					</>
				) : (
					<>
						<StyledNoCoo>
							<Flex>
								<StyledWarningIcon />
								<StyledNoCoo>Pas de lat / lng</StyledNoCoo>
							</Flex>
						</StyledNoCoo>
					</>
				)}
			</>
		)
	}

	const renderOption = (option, state) => {
		const noAdress =
			state.value?.Adresse === undefined ||
			state.value?.Adresse !== "Pas d'adresse correspondante"
		switch (state.type) {
			case OptionType.MA_POSITION:
				return (
					<GeolocUser
						userPosition={userPosition}
						onClickAction={() => {
							setAutoCompleteOpen(false)
							selectUserLocation()
						}}
					/>
				)
			case OptionType.ADRESSE:
				const isGeolocalised = state?.group === "Adresse géolocalisée"
				return (
					<MenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							onSelectGeoloc(state)
							onClose(state)
						}}
					>
						<Flex gap="0.5rem">
							<ChipContainer>
								{isGeolocalised && (
									<CustomChip
										label={state?.group}
										backgroundColor="var(--primary500)"
										size="small"
									/>
								)}
								{!isGeolocalised && (
									<StyledChip
										$colorProperty="var(--primary500)"
										label={state?.group}
										variant="outlined"
										size="small"
									/>
								)}
							</ChipContainer>
							<Flex directionColumn alignItemsStart>
								<div>{state?.Adresse}</div>

								{renderAdressLatLng(state)}
							</Flex>
						</Flex>
					</MenuItem>
				)
			case OptionType.DIVIDER:
				return <Divider />
			case OptionType.LOADING:
				return (
					<MenuItem>
						<Flex gap="1rem">
							<CircularProgress size={20} />
							<div>Recherche en cours</div>
						</Flex>
					</MenuItem>
				)
			case OptionType.LOADING_POINTS:
				return (
					<MenuItem>
						<Flex gap="1rem">
							<CircularProgress size={20} />
							<div>Recherche en cours dans la base de donnée</div>
						</Flex>
					</MenuItem>
				)
			case OptionType.POINTS:
				return (
					<MenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							onSelectPoint(state)
							onClose(state)
						}}
					>
						<Flex gap="1rem">
							<img src={getImage(state.jsonSchema.imgId)} width={40} />
							<div>{state.geoloc.Adresse}</div>
						</Flex>
					</MenuItem>
				)
			case OptionType.HISTORY_ADDRESS:
				return (
					<HistoryMenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							onSelectHistoryAddress(state.value)
							onClose(state.value)
						}}
					>
						<HistoryIcon />
						{noAdress
							? state.value.Adresse
							: `latitude : ${state.value?.coo?.lat} / longitude : ${state.value?.coo?.lng}`}
					</HistoryMenuItem>
				)
			default:
				return <MenuItem>ok</MenuItem>
				break
		}
	}
	useEffect(() => {
		if (
			(value?.Adresse ?? "")?.length === 0 &&
			apiAddressResults.length !== 0
		) {
			setApiAddressResults([])
		}
	}, [value, apiAddressResults])

	const buildedOptions = useMemo(() => {
		if (isLoading) {
			return { options: [{ type: OptionType.LOADING }] }
		}
		if (apiAddressResults.length > 0) {
			return {
				options: [
					...apiAddressResults.map((address) => ({
						...address,
						type: OptionType.ADRESSE,
						group: "Adresse géolocalisée",
					})),
				],
				groupBy: (option) => option.group,
			}
		}
		return {
			options: [
				{ type: OptionType.MA_POSITION },
				{ type: OptionType.DIVIDER },
				...addressesSearchHistory.map((value) => ({
					value,
					type: OptionType.HISTORY_ADDRESS,
				})),
			],
		}
	}, [isLoading, apiAddressResults, addressesSearchHistory, value])
	const isGeolocalisedWithoutAdress =
		value?.coo?.lat && value?.coo?.lng && !value?.Adresse

	useEffect(() => {
		if (value?.coo?.lat && value?.coo?.lng) {
			map.flyTo([value.coo.lat, value.coo.lng], 15)
		}
	}, [value])
	return (
		<>
			{value && value?.coo && value?.coo?.lat && value?.coo?.lng && (
				<Marker position={[value?.coo?.lat, value?.coo?.lng]} />
			)}
			<Container>
				<Autocomplete
					open={autoCompleteOpen}
					onClick={() => setAutoCompleteOpen(true)}
					onClose={() => onClose()}
					disableClearable
					blurOnSelect={true}
					PaperComponent={(props) => <Paper {...props} elevation={20} />}
					freeSolo
					loading={isLoading}
					id="combo-box-demo"
					// @ts-ignore
					groupBy={buildedOptions?.groupBy}
					getOptionLabel={(option) => ""}
					filterOptions={(options, state) => options}
					options={buildedOptions.options}
					onChange={(e: any, newValue: any) => {
						setValue(newValue)
					}}
					onInputChange={(e: any, newInputValue: any) => {
						if (e) {
							if (newInputValue === "") {
								setValue(null)
							} else {
								if (!autoCompleteOpen) {
									setAutoCompleteOpen(true)
								}
								setValue({
									...value,
									Adresse: newInputValue,
								})
							}
						}
					}}
					inputValue={value?.Adresse ?? ""}
					value={value}
					ref={autoCompleteRef}
					renderOption={renderOption}
					renderGroup={(params) => <>{params.children}</>}
					renderInput={(params) => (
						<>
							<TextField
								{...params}
								value={value?.Adresse}
								minRows={3}
								placeholder="Rechercher une adresse ..."
								onClick={() => setAutoCompleteOpen(true)}
								variant="outlined"
								onBlur={() => {
									setAutoCompleteOpen(false)
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{params.InputProps.endAdornment}
											{value?.Adresse && (
												<IconButton
													ref={deleteRef}
													onClick={() => {
														setValue({})
													}}
												>
													<CloseIcon />
												</IconButton>
											)}
											{isLoading && (
												<CircularProgress color="secondary" size={20} />
											)}
										</>
									),
								}}
							/>
						</>
					)}
				/>
				{!_.isEmpty(value?.secteur) ||
					(!_.isEmpty(value?.coo?.lat) && !_.isEmpty(value?.coo?.lng) && (
						<Flex gap="0.25rem" padding={10}>
							{value?.secteur && (
								<CustomChip
									size="small"
									backgroundColor="var(--primary500)"
									label={multiPolygons[value?.secteur]?.name ?? "Hors secteur"}
								/>
							)}
							{renderAdressLatLng(value)}
						</Flex>
					))}
			</Container>
		</>
	)
}

export default SearchControl
