import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"

const selectIsInterco = createDeepEqualSelector(
	(state: any) => state.commune?.is_interco,
	(is_interco: any) => is_interco,
)

const useIsInterco = (): boolean => {
	return useSelector(selectIsInterco)
}

export default useIsInterco
