import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"
import syncDataAdapter from "../syncDataAdapter"
import _ from "lodash"
import IEvent from "utils/types/IEvent"

const initialState = {}

const eventsSlice = createSlice({
	name: "data/events",
	initialState,
	reducers: {
		addEvent: syncDataAdapter.addWithMerge,
		replaceEvent: syncDataAdapter.replace,
		removeEvent: syncDataAdapter.remove,
		createLocalEvent: syncDataAdapter.createLocal,
		updateLocalEvent: syncDataAdapter.updateLocal,
		deleteLocalEvent: syncDataAdapter.deleteLocal,
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default eventsSlice.reducer
export const {
	addEvent,
	removeEvent,
	createLocalEvent,
	updateLocalEvent,
	deleteLocalEvent,
	replaceEvent,
} = eventsSlice.actions
