// USC => cause constants come from userSettings/constants
import {
	CONFIGURATION as USC_CONFIGURATION,
	MAIN_COURANTE as USC_MAIN_COURANTE,
	PATH_MAIN_COURANTE_CREATION_CRISE as USC_CREATION_CRISE,
	PATH_MAIN_COURANTE_DASHBOARD as USC_GC_DASHBOARD,
	PATH_MAIN_COURANTE_CARTOGRAPHY as USC_GC_CARTOGRAPHY,
	PATH_MAIN_COURANTE_ORGA as USC_GC_ORGA,
	PATH_MAIN_COURANTE_SUIVI as USC_SUIVI_CRISE,
	PATH_MAIN_COURANTE_INCIDENT as USC_INCIDENT,
	PATH_MAIN_COURANTE_INTERVENTION as USC_INTERVENTION,
	PATH_MAIN_COURANTE_CARE as USC_CARE,
	PATH_MAIN_COURANTE_ARRETE as USC_ARRETE,
	PATH_MAIN_COURANTE_FICHE as USC_FICHE,
	PATH_MAIN_COURANTE_SUIVI_PCS as USC_SUIVI_PCS,
	PATH_MAIN_COURANTE_ALERTE as USC_ALERTE,
	CUSTOMDATA as USC_CUSTOMDATA,
	PATH_DASHBOARD as USC_CD_DASHBOARD,
	PATH_DONNEES_COMMUNALES as USC_DONNEES_COMMUNALES,
	PATH_CARTOGRAPHY as USC_CD_CARTOGRAPHY,
	PATH_ORGA as USC_CD_ORGA,
	PATH_FICHES as USC_FICHES,
	PATH_FICHES_ACTIONS as USC_FICHES_ACTIONS,
	PATH_FICHES_SCENARIOS as USC_FICHES_SCENARIOS,
	PATH_CUSTOM_PCS as USC_CUSTOM_PCS,
	PATH_ARRETES_TEMPLATES as USC_ARRETES_TEMPLATES,
	PATH_MESSAGE_ALERTE_TEMPLATE as USC_MESSAGE_ALERTE_TEMPLATE,
	COOPERATION as USC_COOPERATION,
	DOCUMENTS as USC_DOCUMENTS,
	PATH_DOWNLOAD_PCS as USC_DOWNLOAD,
	PATH_UPLOAD as USC_UPLOAD,
	JSONSCHEMAS as USC_JSONSCHEMAS,
} from "@/redux-toolkit/userSettings/constants"

//* Enum each tools/features path, which values where allowed to be provided to useAccessRights()
enum EnumToolPath {
	CONFIGURATION = USC_CONFIGURATION,
	GESTION_CRISE = USC_MAIN_COURANTE,
	CREATION_CRISE = USC_CREATION_CRISE,
	GC_DASHBOARD = USC_GC_DASHBOARD,
	GC_CARTOGRAPHY = USC_GC_CARTOGRAPHY,
	GC_ORGA = USC_GC_ORGA,
	SUIVI_CRISE = USC_SUIVI_CRISE,
	INCIDENT = USC_INCIDENT,
	INTERVENTION = USC_INTERVENTION,
	CARE = USC_CARE,
	ARRETE = USC_ARRETE,
	FICHE = USC_FICHE,
	SUIVI_PCS = USC_SUIVI_PCS,
	ALERTE = USC_ALERTE,
	CUSTOMDATA = USC_CUSTOMDATA,
	CD_DASHBOARD = USC_CD_DASHBOARD,
	DONNEES_COMMUNALES = USC_DONNEES_COMMUNALES,
	CD_CARTOGRAPHY = USC_CD_CARTOGRAPHY,
	CD_ORGA = USC_CD_ORGA,
	FICHES = USC_FICHES,
	FICHES_ACTIONS = USC_FICHES_ACTIONS,
	FICHES_SCENARIOS = USC_FICHES_SCENARIOS,
	CUSTOM_PCS = USC_CUSTOM_PCS,
	ARRETES_TEMPLATES = USC_ARRETES_TEMPLATES,
	MESSAGE_ALERTE_TEMPLATE = USC_MESSAGE_ALERTE_TEMPLATE,
	COOPERATION = USC_COOPERATION,
	DOCUMENTS = USC_DOCUMENTS,
	DOWNLOAD_PCS = USC_DOWNLOAD,
	UPLOAD = USC_UPLOAD,
	JSONSCHEMAS = USC_JSONSCHEMAS,
	HOME = "/",
}

//* AccessRights structure
interface IAccessRights {
	communes: string[]
	cdcs: string[]
	tools: Record<
		EnumToolPath,
		| IReadOnlyRight
		| ICommonRights
		| IGestionCriseRights
		| IGCDatasRights
		| IJsonSchemasRights
	>
}

//* Possible cases of permissions structure
interface IReadOnlyRight {
	read: boolean
}
interface ICommonRights extends IReadOnlyRight {
	write: boolean
}
interface IGestionCriseRights extends ICommonRights {
	canDeleteCrisis: boolean
	canLockCrisis: boolean
}
interface IGCDatasRights extends ICommonRights {
	canDeleteDatas: boolean
}
interface IJsonSchemasRights {
	accessAll: boolean
	readAll: boolean
	WHITELIST: { [key: string]: ICommonRights }
	BLACKLIST: string[]
}

//* Predefine tools with à non-basic structure (different of ICommonRights)
type TReadOnlyTools =
	| EnumToolPath.SUIVI_CRISE
	| EnumToolPath.CUSTOMDATA
	| EnumToolPath.DONNEES_COMMUNALES
	| EnumToolPath.FICHES
	| EnumToolPath.DOCUMENTS
type TGestionCriseTools = EnumToolPath.GESTION_CRISE
type TGCDatasTools =
	| EnumToolPath.INCIDENT
	| EnumToolPath.INTERVENTION
	| EnumToolPath.CARE
	| EnumToolPath.ARRETE
	| EnumToolPath.SUIVI_PCS
type TJsonSchemasTools = EnumToolPath.JSONSCHEMAS

//* Mapping to return the correct interface for `T`
type ITools<T extends EnumToolPath> = T extends TReadOnlyTools
	? IReadOnlyRight
	: T extends TGestionCriseTools
	? IGestionCriseRights
	: T extends TGCDatasTools
	? IGCDatasRights
	: T extends TJsonSchemasTools
	? IJsonSchemasRights
	: ICommonRights

export {
	EnumToolPath,
	IAccessRights,
	IReadOnlyRight,
	ICommonRights,
	IGestionCriseRights,
	IGCDatasRights,
	IJsonSchemasRights,
	ITools,
}
