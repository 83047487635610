import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import SuperModal from "./SuperModal"
import { Flex } from "utils/components/style/flex"
import FText from "utils/form/FText"
import IMultiPolygon from "utils/types/IMultiPolygon"
import { useDispatch } from "react-redux"
import { updateDrawMultiPolygon } from "@/redux-toolkit/daybook/resources"

const ModalEditCoucheInfos = ({
	isOpenEditInfos,
	setIsOpenEditInfos,
	multiPolygon,
	extraActions = undefined,
}: {
	isOpenEditInfos: boolean
	setIsOpenEditInfos: (isOpen: boolean) => void
	multiPolygon: IMultiPolygon
	extraActions?: any
}) => {
	const dispatch = useDispatch()

	const methods = useForm({
		defaultValues: {
			"Nom de la couche": multiPolygon?.name,
			details: multiPolygon?.custom_props?.details,
		},
	})

	const onSubmit = (values) => {
		dispatch(
			updateDrawMultiPolygon({
				...multiPolygon,
				name: values["Nom de la couche"],
				custom_props: {
					...multiPolygon.custom_props,
					details: values["details"],
				},
			}),
		)
		methods.reset({})
		if (extraActions) extraActions()
		setIsOpenEditInfos(undefined)
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpenEditInfos}
				isNew={false}
				title="Renommer la couche"
				onClick={methods.handleSubmit(onSubmit)}
				onClose={() => setIsOpenEditInfos(false)}
			>
				<Flex alignItemsInitial directionColumn gap="1rem">
					<FText name="Nom de la couche" />
					<FText name="details" label="Note (facultatif)" multiline />
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}
export default ModalEditCoucheInfos
