import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DeleteIcon from "@mui/icons-material/Delete"
import { Divider, IconButton, List, ListItem, Tooltip } from "@mui/material"
import SignalService from "@/pages/carto2/cartographie/service/SignalService"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import ColorManager from "@/pages/maincourante/subComponents/bottomCenterControl/ColorManager"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import { PATH_MAIN_COURANTE_CARTOGRAPHY } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import _ from "lodash"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import ConfirmationModal from "utils/components/ConfirmationModal"
import { PrimaryTextButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { H4 } from "utils/components/style/header"
import OpenWithIcon from "@mui/icons-material/OpenWith"
import { DAYBOOK_DATA } from "./PanelCarto"
import { toggleDraggableSignalMarkers } from "@/redux-toolkit/daybook/reducer"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
// app:javascript:utils:panels:PanelDaybookSignal.tsx
const debug = Debug("app:javascript:utils:panels:PanelDaybookSignal")
debug.log = console.log.bind(console)
const Container = styled.div`
    padding: 1rem;
`
const SOpenWithIcon = styled(OpenWithIcon)`
    color: var(--primary500);
`

const PanelDaybookSignal = () => {
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const currentPannel = useSelector((state: any) => state.common.panel)
	const signalMarker = selectedEvent?.mapSignalMarkers?.[currentPannel.id] ?? {}
	const permissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)
	const isLocked = selectedEvent?.locked
	const draggableSignalMarkers = useSelector(
		(state: any) => state.daybook.draggableSignalMarkers,
	)
	const onChangeColor = (newColor) => {
		dispatch(
			updateEvent({
				...selectedEvent,
				mapSignalMarkers: {
					...selectedEvent?.mapSignalMarkers,
					[currentPannel.id]: {
						...signalMarker,
						color: newColor,
					},
				},
			}),
		)
	}
	return (
		<>
			<PrimaryTextButton
				onClick={() => dispatch(setPanel({ type: DAYBOOK_DATA }))}
			>
				<ArrowBackIcon />
				Retour aux données
			</PrimaryTextButton>
			<Container>
				<Flex spaceBetween>
					<H4>{signalMarker.type}</H4>
					<Flex gap="0.25rem">
						<Tooltip title="Déplacer le point">
							<IconButton
								onClick={() => {
									dispatch(toggleDraggableSignalMarkers(signalMarker?.id))
								}}
								disabled={isLocked || !permissions.write}
							>
								{draggableSignalMarkers?.[signalMarker?.id] ? (
									<SOpenWithIcon />
								) : (
									<OpenWithIcon />
								)}
							</IconButton>
						</Tooltip>
						<IconButton
							onClick={() => setIsConfirmationModalOpen(true)}
							disabled={isLocked || !permissions.write}
						>
							<DeleteIcon />
						</IconButton>
					</Flex>
				</Flex>
				<List>
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Icône</div>
							{SignalService.signalMapping[signalMarker.type]?.(
								signalMarker.color,
							)}
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Latitude</div>
							{signalMarker.lat}
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Longitude</div>
							{signalMarker.lng}
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Couleur</div>
							<ColorManager
								color={signalMarker.color}
								setColor={onChangeColor}
								disabled={isLocked}
							/>
						</Flex>
					</ListItem>
				</List>
			</Container>

			<ConfirmationModal
				modalShown={isConfirmationModalOpen}
				onClose={() => setIsConfirmationModalOpen(false)}
				onConfirm={() => {
					setIsConfirmationModalOpen(false)
					dispatch(
						updateEvent({
							...selectedEvent,
							mapSignalMarkers: _.omit(
								selectedEvent?.mapSignalMarkers,
								currentPannel.id,
							),
						}),
					)
					dispatch(setPanel({ type: DAYBOOK_DATA }))
				}}
				message="Voulez-vous vraiment supprimer ce point ?"
			/>
		</>
	)
}

export default PanelDaybookSignal
