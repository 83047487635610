import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { resetRedux } from "../rootActions"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "../userSettings/constants"

export const STATUS_ALL = "Tous"
export const STATUS_TODO = "A faire"
export const STATUS_IN_PROGRESS = "En cours"
export const STATUS_DONE = "Terminé"

const initialState = {
	isChangingCarto: false,
	drawGeojson: {},
	coucheJsonSchemas: {
		[INCIDENT]: {
			cluster: 1,
			id: INCIDENT,
			visibility: 1,
			filterBy: STATUS_ALL,
		},
		[INTERVENTION]: {
			cluster: 1,
			id: INTERVENTION,
			visibility: 1,
			filterBy: STATUS_ALL,
		},
		[TEMPORARY_CARE]: {
			cluster: 1,
			id: TEMPORARY_CARE,
			visibility: 1,
		},
	},
	coucheMultiPolygons: {},
	coucheEvents: {},
	selectedLayer: "Osm",
	viewportParams: undefined,
	surfaceOrder: [],
	draggableSignalMarkers: {},
	lastSelectedEvent: undefined,
	showedFacility: undefined
}

const insertSurface = (state, id, priority) => {
	if (state.surfaceOrder.includes(id)) {
		return
	}
	const insertAt = state.surfaceOrder.findIndex((id) => {
		return (
			!state.coucheMultiPolygons[id]?.moved &&
			!state.drawGeojson[id]?.moved &&
			parseInt(state.coucheMultiPolygons[id]?.priority ?? 10) <=
			parseInt(priority)
		)
	})

	if (insertAt < 0) state.surfaceOrder.push(id)
	else state.surfaceOrder.splice(insertAt, 0, id)
}

const removeSurface = (state, id) => {
	const removeAt = state.surfaceOrder.indexOf(id)
	if (removeAt >= 0) state.surfaceOrder.splice(removeAt, 1)
}

const daybookSlice = createSlice({
	name: "daybook",
	initialState,
	reducers: {
		toggleIsChangingCarto: (state) => {
			state.isChangingCarto = !state.isChangingCarto
		},

		// === Add/remove/toggleVisibility couches ===
		// == jsonschemas
		addCoucheJsonSchemas(state, { payload: { id, ...options } }) {
			state.coucheJsonSchemas[id] = {
				id,
				visibility: 1,
				cluster: 1,
				...options,
			}
		},
		toggleDraggableSignalMarkers(state, { payload: id }) {
			if (!state.draggableSignalMarkers[id]) {
				state.draggableSignalMarkers[id] = true
			} else {
				state.draggableSignalMarkers[id] = !state.draggableSignalMarkers[id]
			}
		},
		removeCoucheJsonSchemas(state, { payload: id }) {
			delete state.coucheJsonSchemas[id]
		},
		setLastSelectedEvent(state, { payload: id }) {
			state.lastSelectedEvent = id
		},
		toggleVisibilityJsonSchemas(state, { payload: id }) {
			state.coucheJsonSchemas[id].visibility ^= 1
		},
		addStyleJsonSchemas(state, { payload: { id, style } }) {
			if (state.coucheJsonSchemas[id]) {
				state.coucheJsonSchemas[id].style = style
			}
			if (state.coucheMultiPolygons[id]) {
				state.coucheMultiPolygons[id].style = style
			}
			if (state.drawGeojson[id]) {
				state.drawGeojson[id].style = style
			}
		},
		// == mulipolygon
		addCoucheMultiPolygons(state, { payload: { id, priority } }) {
			if (state.coucheMultiPolygons[id]) {
				return
			}
			state.coucheMultiPolygons[id] = {
				id,
				priority,
				visibility: 1,
				moved: false,
			}
			insertSurface(state, id, priority)
		},
		cleanCoucheMultiPolygons(state) {
			state.coucheMultiPolygons = {}
			state.surfaceOrder = []
		},
		cleanCoucheJsonSchemas(state) {
			state.coucheJsonSchemas = initialState.coucheJsonSchemas
		},
		toggleCoucheEvents(state, { payload: nom }) {
			if (!state.coucheEvents) {
				state.coucheEvents = {}
			}
			if (state.coucheEvents?.[nom] === undefined) {
				state.coucheEvents[nom] = false
				return
			}
			state.coucheEvents[nom] = !state.coucheEvents[nom]
		},
		removeCoucheMultiPolygons(state, { payload: id }) {
			delete state.coucheMultiPolygons[id]
			removeSurface(state, id)
		},
		toggleVisibilityMultiPolygons(state, { payload: id }) {
			state.coucheMultiPolygons[id].visibility ^= 1
		},
		toggleClusterJsonSchema(state, { payload: id }) {
			state.coucheJsonSchemas[id].cluster ^= 1
		},
		// == draw
		addCoucheDrawGeojson(state, { payload: draw }) {
			state.drawGeojson[draw.id] = draw
			state.drawGeojson[draw.id].moved = false
			insertSurface(state, draw.id, 10)
		},
		removeCoucheDrawGeojson(state, { payload: id }) {
			delete state.drawGeojson[id]
			removeSurface(state, id)
		},
		toggleVisibilityDrawGeojson(state, { payload: id }) {
			state.drawGeojson[id].visibility ^= 1
		},
		// === ===

		reorderSurfaces(state, { payload: { from, to } }) {
			const [removed] = state.surfaceOrder.splice(from, 1)
			state.surfaceOrder.splice(to, 0, removed)

			if (state.coucheMultiPolygons[state.surfaceOrder[to]])
				state.coucheMultiPolygons[state.surfaceOrder[to]].moved = true
			if (state.drawGeojson[state.surfaceOrder[to]])
				state.drawGeojson[state.surfaceOrder[to]].moved = true
		},
		resetDaybookReducer: (state) => {
			Object.keys(state).forEach((key) => {
				delete state[key]
			})
			Object.assign(state, initialState)
		},
		toggleSelectedLayer(state, { payload: layerName }) {
			if (state.selectedLayer !== layerName) {
				state.selectedLayer = layerName
			}
		},
		toggleViewportParams(state, { payload: viewportParams }) {
			if (!_.isEqual(state.viewportParams, viewportParams)) {
				state.viewportParams = viewportParams
			}
		},
		toggleNextStatusFilter(state, { payload: nom }) {
			if (state.coucheJsonSchemas?.[nom]?.filterBy === undefined) {
				state.coucheJsonSchemas[nom].filterBy = STATUS_ALL
				return
			}

			const statusFilterNames = [
				STATUS_ALL,
				...(nom === "INTERVENTION" ? [STATUS_TODO] : []),
				STATUS_IN_PROGRESS,
				STATUS_DONE,
			]

			const nextStatusFilterIndex =
				_.indexOf(statusFilterNames, state.coucheJsonSchemas[nom].filterBy) + 1

			if (nextStatusFilterIndex >= statusFilterNames.length) {
				state.coucheJsonSchemas[nom].filterBy = statusFilterNames[0]
			} else {
				state.coucheJsonSchemas[nom].filterBy =
					statusFilterNames[nextStatusFilterIndex]
			}
		},
		toggleShowedFacility(state, { payload: { id } }) {
			state.showedFacility = id
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})
export default daybookSlice.reducer
export const {
	toggleIsChangingCarto,
	resetDaybookReducer,
	toggleCoucheEvents,
	toggleClusterJsonSchema,
	addCoucheJsonSchemas,
	removeCoucheJsonSchemas,
	toggleVisibilityJsonSchemas,
	addCoucheMultiPolygons,
	removeCoucheMultiPolygons,
	toggleVisibilityMultiPolygons,
	addCoucheDrawGeojson,
	removeCoucheDrawGeojson,
	toggleVisibilityDrawGeojson,
	reorderSurfaces,
	cleanCoucheMultiPolygons,
	cleanCoucheJsonSchemas,
	setLastSelectedEvent,
	addStyleJsonSchemas,
	toggleDraggableSignalMarkers,
	toggleSelectedLayer,
	toggleViewportParams,
	toggleNextStatusFilter,
	toggleShowedFacility,
} = daybookSlice.actions
