import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import styled from "styled-components"
import { MenuItem } from "@mui/material"
import { Flex } from "../../style/flex"
import ConfirmationModal from "../../ConfirmationModal"
import IButtonMultiOption from "./IButtonMultiOption"

// app:javascript:utils:components:buttonMultiComponent:OptionButtonMulti.tsx
const debug = Debug(
	"app:javascript:utils:components:buttonMultiComponent:OptionButtonMulti",
)
debug.log = console.log.bind(console)

const SFlex = styled(Flex)<{ $color?: string }>`
    color: ${(props) => props.$color};
`

const OptionButtonMulti = ({
	option,
	handleClose,
	setTempHide,
}: {
	option: IButtonMultiOption
	handleClose: () => void
	setTempHide: (value: boolean) => void
}) => {
	const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
	if (option?.renderCondition ?? true) {
		return (
			<React.Fragment key={option.key}>
				<MenuItem
					disabled={option?.disabled ?? false}
					key={option.key}
					onClick={() => {
						if (option.requireValidation) {
							setIsOpenModalConfirm(true)
							setTempHide(true)
							return
						}
						option.onClick?.()
						handleClose()
					}}
				>
					<SFlex gap="1rem" $color={option.color}>
						{option.icon}
						{option.label}
					</SFlex>
				</MenuItem>
				<ConfirmationModal
					modalShown={isOpenModalConfirm}
					onClose={() => {
						setIsOpenModalConfirm(false)
						setTempHide(false)
						handleClose()
					}}
					onConfirm={() => {
						option?.onClick()
						setTempHide(false)
						handleClose()
					}}
					message={option.confirmMessage ?? ""}
				/>
			</React.Fragment>
		)
	}
	return null
}

export default OptionButtonMulti
