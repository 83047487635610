import { View } from "@react-pdf/renderer"
import React from "react"
import NormalText from "../NormalText"

const PList = ({ items, textProps = {}, marginBottom = 0, ...props }) => {
	return (
		<View
			{...props}
			style={{
				...{
					flexDirection: "column",
					marginBottom,
				},
				...props.style,
			}}
		>
			{items.map((item) => (
				<NormalText
					key={item}
					{...textProps}
					style={{
						...{ marginLeft: 15 },
						...(textProps as any).style,
					}}
				>
					{" "}
					- {item}
				</NormalText>
			))}
		</View>
	)
}

export default PList
