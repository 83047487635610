import React from "react"
import { PieChart, Pie, Tooltip } from "recharts"
import styled from "styled-components"
import _ from "lodash"

// @ts-ignore
const StyledPieChart = styled(PieChart)`
    & > svg {
        overflow: visible !important;
    }
`

const CapacityPie = ({
	adultMember,
	childMember,
	babyMember,
	animalMember,
	freePlace,
	capacite,
	pieData,
	small = false,
}) => {
	return (
		<StyledPieChart width={small ? 80 : 150} height={small ? 80 : 150}>
			<Pie
				data={[
					{
						name: "Places occupées",
						value: adultMember + childMember + babyMember + animalMember,
						fill: freePlace < 0 ? "red" : "#0f206e",
					},
					{
						name: "Places libres",
						value: freePlace < 0 ? 0 : freePlace,
						fill: "#a6b7fa",
					},
				]}
				dataKey="value"
				innerRadius={small ? 30 : 45}
				outerRadius={small ? 40 : 60}
			/>
			<Tooltip />
			<text
				x={small ? 40 : 75}
				y={small ? 40 : 75}
				textAnchor="middle"
				dominantBaseline="middle"
				style={{ fontSize: small ? "0.7rem" : "1.2rem" }}
			>
				{_.sumBy(pieData, "value")} / {capacite}
			</text>
		</StyledPieChart>
	)
}

export default CapacityPie
