import React from "react"
import TravelExploreIcon from "@mui/icons-material/TravelExplore"
import GpsOffIcon from "@mui/icons-material/GpsOff"
import GpsFixedIcon from "@mui/icons-material/GpsFixed"
import styled from "styled-components"
import { MenuItem } from "@mui/material"
import { useSelector } from "react-redux"

export const StyledMenuItem = styled(MenuItem)`
    color: var(--primary800) !important;
    display: flex;
    gap: 0.5rem;
`

export const GeolocUser = ({ userPosition, onClickAction }) => {
	const isPublic = useSelector((state: any) => state.userSettings.settings.id)
		? false
		: true

	return (
		<StyledMenuItem
			onClick={onClickAction}
			disabled={
				userPosition.loading ||
				userPosition.error !== undefined ||
				!window.navigator.onLine
			}
		>
			{/* navigator lines should work correctly but not */}
			{/* https://stackoverflow.com/questions/62943328/how-to-check-if-the-user-has-internet-connection-in-react */}
			{userPosition.loading &&
				window.navigator.onLine &&
				!userPosition.error && (
					<>
						<TravelExploreIcon />
						Localisation en cours
					</>
				)}
			{(userPosition.error || !window.navigator.onLine) && !isPublic && (
				<>
					<GpsOffIcon />
					Position introuvable
				</>
			)}
			{!userPosition.loading &&
				window.navigator.onLine &&
				!userPosition.error && (
					<>
						<GpsFixedIcon />
						Ma Position
					</>
				)}
		</StyledMenuItem>
	)
}

export default GeolocUser
