import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { resetRedux } from "../rootActions"
import { addMultipolygon } from "../data/multiPolygon/reducer"

const initialState = {
	isChangingCarto: false,
	coucheJsonSchemas: {},
	coucheMultiPolygons: {},
	coucheEvents: {},
	selectedLayer: "Osm",
	viewportParams: {
		center: [45.8870177, -1.1964972],
		zoom: 10,
	},
	surfaceOrder: [],
	drawGeojson: {},
}

const insertSurface = (state, id, priority) => {
	if (state.surfaceOrder.includes(id)) {
		return
	}
	const insertAt = state.surfaceOrder.findIndex((id) => {
		return (
			!state.coucheMultiPolygons?.[id]?.moved &&
			!state.drawGeojson?.[id]?.moved &&
			parseInt(state.coucheMultiPolygons[id]?.priority ?? 10) <=
				parseInt(priority)
		)
	})

	if (insertAt < 0) state.surfaceOrder.push(id)
	else state.surfaceOrder.splice(insertAt, 0, id)
}

const removeSurface = (state, id) => {
	const removeAt = state.surfaceOrder.indexOf(id)
	if (removeAt >= 0) state.surfaceOrder.splice(removeAt, 1)
}

const cartoSlice = createSlice({
	name: "carto",
	initialState,
	reducers: {
		toggleIsChangingCarto: (state) => {
			state.isChangingCarto = !state.isChangingCarto
		},

		// === Add/remove/toggleVisibility couches ===
		// == jsonschemas
		addCoucheJsonSchemas(state, { payload: { id, ...options } }) {
			state.coucheJsonSchemas[id] = {
				id,
				visibility: 1,
				cluster: 1,
				...options,
			}
		},
		removeCoucheJsonSchemas(state, { payload: id }) {
			delete state.coucheJsonSchemas[id]
		},
		toggleVisibilityJsonSchemas(state, { payload: id }) {
			state.coucheJsonSchemas[id].visibility ^= 1
		},
		addStyleJsonSchemas(state, { payload: { id, style } }) {
			if (state.coucheJsonSchemas[id]) {
				state.coucheJsonSchemas[id].style = style
			}
			if (state.coucheMultiPolygons[id]) {
				state.coucheMultiPolygons[id].style = style
			}
			if (state.drawGeojson[id]) {
				state.drawGeojson[id].style = style
			}
		},
		// == mulipolygon
		addCoucheMultiPolygons(state, { payload: { id, priority } }) {
			state.coucheMultiPolygons[id] = {
				id,
				priority,
				visibility: 1,
				moved: false,
			}
			insertSurface(state, id, priority)
		},
		toggleCoucheEvents(state, { payload: nom }) {
			if (!state.coucheEvents) {
				state.coucheEvents = {}
			}
			if (state.coucheEvents?.[nom] === undefined) {
				state.coucheEvents[nom] = false
				return
			}
			state.coucheEvents[nom] = !state.coucheEvents[nom]
		},
		removeCoucheMultiPolygons(state, { payload: id }) {
			delete state.coucheMultiPolygons[id]
			removeSurface(state, id)
		},
		toggleVisibilityMultiPolygons(state, { payload: id }) {
			state.coucheMultiPolygons[id].visibility ^= 1
		},
		toggleClusterJsonSchema(state, { payload: id }) {
			state.coucheJsonSchemas[id].cluster ^= 1
		},

		reorderSurfaces(state, { payload: { from, to } }) {
			const [removed] = state.surfaceOrder.splice(from, 1)
			state.surfaceOrder.splice(to, 0, removed)

			if (state.coucheMultiPolygons[state.surfaceOrder[to]])
				state.coucheMultiPolygons[state.surfaceOrder[to]].moved = true
		},
		resetCartoReducer: (state) => {
			Object.keys(state).forEach((key) => {
				delete state[key]
			})
			Object.assign(state, initialState)
		},
		toggleSelectedLayer(state, { payload: layerName }) {
			if (state.selectedLayer !== layerName) {
				state.selectedLayer = layerName
			}
		},
		toggleViewportParams(state, { payload: viewportParams }) {
			if (!_.isEqual(state.viewportParams, viewportParams)) {
				state.viewportParams = viewportParams
			}
		},
		overwriteState(state, { payload: cartoStates }) {
			Object.keys(cartoStates).forEach((key: any) =>
				state[key] = cartoStates[key]
			)
		}
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})
export default cartoSlice.reducer
export const {
	toggleIsChangingCarto,
	resetCartoReducer,
	toggleCoucheEvents,
	toggleClusterJsonSchema,
	addCoucheJsonSchemas,
	removeCoucheJsonSchemas,
	toggleVisibilityJsonSchemas,
	addCoucheMultiPolygons,
	removeCoucheMultiPolygons,
	toggleVisibilityMultiPolygons,
	reorderSurfaces,
	addStyleJsonSchemas,
	toggleSelectedLayer,
	toggleViewportParams,
	overwriteState,
} = cartoSlice.actions
