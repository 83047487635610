import React from "react"
import Button from "@/styles/atoms/Button"
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownTrigger,
} from "@nextui-org/react"
import { isMobile } from "react-device-detect"
import DownloadIcon from "@mui/icons-material/Download"
import ExtractionButtonTable from "../../ExtractionButtonTable"
import DeleteIcon from "@mui/icons-material/Delete"
import Block from "@mui/icons-material/Block"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import IButtonMultiOption from "@/utils/components/button/ButtonMulti/IButtonMultiOption"
import { RowSelectionState } from "@tanstack/react-table"
const ButtonRowSelection = ({
	disableMenuActions,
	selectedFlatRowsLength,
	selectedFlatRowsIds,
	selectedFlatRows,
	data,
	convertToCsv,
	onDeleteClick,
	onDeleteAllClick,
	additionalMultiOptions,
	isDeletable,
	writePermission,
	isLocked,
}: {
	additionalMultiOptions: IButtonMultiOption[]
	disableMenuActions: boolean
	selectedFlatRowsLength: number
	selectedFlatRowsIds: string[]
	selectedFlatRows: RowSelectionState
	data: any[]
	convertToCsv: boolean
	onDeleteClick: (original: any) => void
	onDeleteAllClick: (original: any[]) => void
	isDeletable: boolean
	writePermission: boolean
	isLocked: boolean
}) => {
	const [openConfirmation, setOpenConfirmation] = React.useState(undefined)
	const options = [
		{
			renderCondition: convertToCsv !== undefined,
			icon: <DownloadIcon />,
			label: convertToCsv && (
				<ExtractionButtonTable
					noContainer
					selected={selectedFlatRowsIds}
					convertToCsv={convertToCsv}
				/>
			),
			onClick: () => {
				document.getElementById("extraction-button")?.click()
			},
			description:
				"Téléchargez les données sélectionnées en tant que fichier CSV, compatible avec Excel et d'autres tableurs.",
			key: "export",
		},
		...additionalMultiOptions.map((option) => {
			return {
				...option,
				onClick: () => option.onClick(selectedFlatRows),
			}
		}),
		{
			renderCondition: isDeletable && writePermission && !isLocked,
			requireValidation: true,
			icon: <DeleteIcon />,
			key: "delete",
			description:
				"Éliminez définitivement les entrées sélectionnées de la base de données.",
			onClick: () => {
				if (onDeleteAllClick) {
					onDeleteAllClick(
						selectedFlatRowsIds.map((rowId) =>
							data.find((dataElement) => dataElement.id === rowId),
						),
					)
				} else {
					selectedFlatRowsIds
						.map((rowId) =>
							data.find((dataElement) => dataElement.id === rowId),
						)
						.forEach((original) => onDeleteClick(original))
				}
			},

			confirmMessage:
				"Voulez vous vraiment supprimer toute la séléction ? " +
				selectedFlatRowsIds.length +
				" éléments est/sont concerné(s).",
			label: "Supprimer toute la séléction",
			color: "danger",
		},
		{
			renderCondition: !isDeletable && !convertToCsv,
			label: "Aucune action disponible",
			key: "noAction",
			description: "Aucune action disponible",
			icon: <Block />,
			disabled: true,
		},
	] as IButtonMultiOption[]

	return (
		<>
			{selectedFlatRowsLength > 0 && !disableMenuActions && (
				<Dropdown>
					<DropdownTrigger>
						<Button
							color="primary"
							variant="bordered"
							endContent={<KeyboardArrowDownIcon />}
						>
							{isMobile ? "Menu actions" : "Actions sur la sélection"}
						</Button>
					</DropdownTrigger>
					<DropdownMenu>
						{options.map((option) => {
							if (!option.renderCondition) return null
							return (
								<DropdownItem
									className="max-w-md"
									color={option.color ?? "primary"}
									variant="bordered"
									key={option.key}
									startContent={option.icon}
									description={option.description}
									onClick={() => {
										if (option.requireValidation) {
											setOpenConfirmation(option.key)
										} else {
											option?.onClick()
										}
									}}
								>
									{option.label}
								</DropdownItem>
							)
						})}
					</DropdownMenu>
				</Dropdown>
			)}
			<ConfirmationModal
				modalShown={openConfirmation !== undefined}
				onClose={() => setOpenConfirmation(undefined)}
				onConfirm={() => {
					options.find((option) => option.key === openConfirmation)?.onClick()
					setOpenConfirmation(undefined)
				}}
				message={
					options.find((option) => option.key === openConfirmation)
						?.confirmMessage
				}
			/>
		</>
	)
}

export default ButtonRowSelection
