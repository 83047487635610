import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import StaticFormsService from "@/utils/services/StaticFormsService"
import IJsonSchema, { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { selectAccessRights } from "./useAccessRights"
import { buildJsonSchemasWithAccessRights } from "./useJsonSchemas"
import useMappings from "./useMappings"
import { MAPPING_TOOL_KEY } from "@/pages/admin/mapping/mappingTools/text/key"

export const selectJsonSchemas = createDeepEqualSelector(
	(state: any) => state.data.jsonSchemas,
	(state: any) => state.commune?.is_interco,
	(jsonSchemas, is_interco) => ({
		jsonSchemas,
		is_interco,
	}),
)

export const buildMappingProperty = (name: string) => ({
	isColumn: false,
	isIdentifiant: false,
	isPcs: false,
	isPublic: false,
	isRequired: false,
	isReadOnly: true,
	label: name,
	name: name,
	type: JsonSchemaPropertiesType.TEXT,
})

const useJsonSchemasWithStatics = (
	addMappingProperty = true,
): { [key: string]: IJsonSchema } => {
	const accessRights = useSelector(selectAccessRights)
	const jsonSchemasAccessRights = accessRights?.tools?.JSONSCHEMAS
	const mappings = useMappings()

	const { jsonSchemas: jsonSchemasWithoutStatics, is_interco } =
		useSelector(selectJsonSchemas)
	const staticForms = is_interco
		? StaticFormsService.getIntercoStaticForms()
		: StaticFormsService.getStaticForms()

	const jsonSchemas = { ...jsonSchemasWithoutStatics }
	staticForms.forEach((staticForm) => {
		jsonSchemas[staticForm.id] = {
			...staticForm,
			title:
				is_interco && staticForm.title_interco
					? staticForm.title_interco
					: staticForm.title,
		}
	})

	Object.values(mappings).map((mapping) => {
		const jsonSchema = jsonSchemas[mapping.jsonschema_id] as IJsonSchema

		const newProperties = (jsonSchema?.template?.properties ?? []).map(
			(prop) => {
				if (mapping.schema[prop.name]?.converter === MAPPING_TOOL_KEY) {
					return {
						...prop,
						isReadOnly: true,
					}
				}
				return prop
			},
		)
		if (mapping.add_property && addMappingProperty) {
			newProperties.unshift(buildMappingProperty(mapping.add_property))
		}

		jsonSchemas[mapping.jsonschema_id] = {
			...jsonSchema,
			template: {
				...(jsonSchema?.template ?? {}),
				properties: newProperties,
			},
		}
	})

	return buildJsonSchemasWithAccessRights(jsonSchemas, jsonSchemasAccessRights)
}

export default useJsonSchemasWithStatics
