import React, { useContext, useMemo } from "react"
import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"
import {
	setCurrentIncident,
	setCurrentIntervention,
} from "@/redux-toolkit/edit/reducer"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
	CARE,
	PATH_MAIN_COURANTE_SUIVI,
} from "@/redux-toolkit/userSettings/constants"
import L from "leaflet"
import { Marker, Popup } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { Flex } from "utils/components/style/flex"
import IPoint from "utils/types/IPoint"
import { Content, SubContent } from "../Marker.styled"
import { renderIncidentStatus } from "@/pages/maincourante/following/TableIncident"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_CARE } from "utils/panels/PanelCarto"
import CapacityPie from "@/pages/maincourante/care/CareCard/CapacityPie"
import useCareMembers from "@/hooks/care/useCareMembers"
import _ from "lodash"
import useAccessRights from "@/hooks/useAccessRights"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { Image } from "@nextui-org/react"

const getImgEvenement = (point: IPoint) => {
	if (point.geojson.properties?.Priorité === "Normale") {
		if (point.geojson.properties?.Statut === "Terminé") {
			return "/img/maincourante/evenement_termine_normale.png"
		} else {
			return "/img/maincourante/evenement_encours_normale.png"
		}
	} else {
		if (point.geojson.properties?.Statut === "Terminé") {
			return "/img/maincourante/evenement_termine_urgente.png"
		} else {
			return "/img/maincourante/evenement_encours_urgente.png"
		}
	}
}
const getImgIntervention = (point: IPoint) => {
	if (point.geojson.properties?.Statut === "Terminé") {
		return "/img/maincourante/intervention_termine_normale.png"
	} else if (point.geojson.properties?.Statut === "En cours") {
		return "/img/maincourante/intervention_encours_normale.png"
	} else {
		return "/img/maincourante/intervention_afaire_normale.png"
	}
}

const MarkerDaybookStatic = ({ point, noInteraction = false }) => {
	const dispatch = useDispatch()
	const toolPath =
		point.jsonschema_id === TEMPORARY_CARE
			? EnumToolPath.CARE
			: ((EnumToolPath.SUIVI_CRISE + "." + point.jsonschema_id) as
					| EnumToolPath.INCIDENT
					| EnumToolPath.INTERVENTION)
	const permissions = useAccessRights(toolPath)
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const jsonSchemaId = point.jsonschema_id

	const iconStyle = useSelector(
		(state: any) => state.daybook.coucheJsonSchemas[jsonSchemaId],
	)
	const coucheEvents = useSelector(
		(state: any) => state.daybook?.coucheEvents ?? {},
	)

	const { adultMember, childMember, babyMember, animalMember } = useCareMembers(
		{ care: point },
	)
	const capacite = point.geojson.properties["Capacité"] ?? 0
	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Adultes",
				value: adultMember,
				fill: "green",
			},
			{
				name: "Enfants",
				value: childMember,
				fill: "orange",
			},
			{
				name: `Bébés`,
				value: babyMember,
				fill: "purple",
			},
			{
				name: `Animaux`,
				value: animalMember,
				fill: "#513915",
				// brown
			},
		]
		return newData
	}, [adultMember, animalMember, babyMember, childMember])
	const freePlace = capacite - _.sumBy(pieData, "value")

	const pointGeolocKeyName = [INTERVENTION, TEMPORARY_CARE].includes(
		point.jsonschema_id,
	)
		? "Adresse"
		: "Géolocalisation"

	if (
		!permissions.read ||
		coucheEvents[point.jsonschema_id] === false ||
		!point.geojson.properties[pointGeolocKeyName]?.coo?.lat
	) {
		return null
	}

	const markerTitleKey = [INCIDENT, INTERVENTION].includes(point.jsonschema_id)
		? "Objet"
		: "Lieu"

	const markerSubContent =
		jsonSchemaId === INCIDENT
			? "Évènement"
			: jsonSchemaId === INTERVENTION
				? "Intervention"
				: "Centre d'accueil temporaire"

	const imgUrl =
		point.jsonschema_id === INCIDENT
			? getImgEvenement(point)
			: point.jsonschema_id === INTERVENTION
				? getImgIntervention(point)
				: "/img/maincourante/care.png"

	const renderStatus =
		point.jsonschema_id === INCIDENT
			? renderIncidentStatus
			: point.jsonschema_id === INTERVENTION
				? renderIncidentStatus
				: () => null

	const openPointModal =
		point.jsonschema_id === INCIDENT
			? setCurrentIncident(point)
			: point.jsonschema_id === INTERVENTION
				? setCurrentIntervention(point)
				: point.jsonschema_id === TEMPORARY_CARE
					? setPanel({
							type: DAYBOOK_CARE,
							id: point.id,
						})
					: () => null

	const iconSize = iconStyle?.style?.iconSize ?? 30
	return (
		<Marker
			key={point.id}
			position={[
				point.geojson.properties[pointGeolocKeyName].coo.lat,
				point.geojson.properties[pointGeolocKeyName].coo.lng,
			]}
			eventHandlers={{
				click: () => {
					isTakingScreenshotMap || noInteraction
						? null
						: dispatch(openPointModal)
				},
				mouseover: (e) => {
					if (isTakingScreenshotMap || noInteraction) {
						return
					}
					const { target } = e
					target.openPopup()
				},
				mouseout: (e) => {
					if (isTakingScreenshotMap || noInteraction) {
						return
					}
					const { target } = e
					target.closePopup()
				},
			}}
			icon={L.icon({
				iconUrl: imgUrl,
				iconSize: [iconSize, iconSize],
				iconAnchor: [iconSize / 2, iconSize],
				popupAnchor: [0, -30],
			})}
			// open popup on hover
		>
			{!noInteraction && (
				<Popup>
					<Flex padding="16px" gap="1rem">
						<Image width={80} src={imgUrl} />
						<Flex directionColumn alignItemsInitial fullWidth>
							<Content>{point.geojson.properties[markerTitleKey]}</Content>
							<Flex spaceBetween>
								<SubContent>{markerSubContent}</SubContent>
								{renderStatus(point.geojson.properties["Statut"], true)}
							</Flex>
						</Flex>
						{point.jsonschema_id === TEMPORARY_CARE && (
							<CapacityPie
								adultMember={adultMember}
								childMember={childMember}
								babyMember={babyMember}
								animalMember={animalMember}
								freePlace={freePlace}
								capacite={capacite}
								pieData={pieData}
							/>
						)}
					</Flex>
				</Popup>
			)}
		</Marker>
	)
}

export default MarkerDaybookStatic
