import _ from "lodash"
import Yup from "utils/yup"

interface IGeoloc {
	Adresse: string
	typeAdresse?: string
	secteur: string
	coo: { lat: number; lng: number }
}

export const getYupGeoloc = ({
	isRequired = false,
}: {
	isRequired?: boolean
}) => {
	const yupObject = Yup.object({
		Adresse: Yup.string(),
		typeAdresse: Yup.string().nullable(),
		secteur: Yup.string().nullable(),
		coo: Yup.object({
			lat: Yup.number(),
			lng: Yup.number(),
		}),
	}).transform((value) => {
		if (_.isEmpty(value)) {
			return {}
		}
		if (_.isEmpty(value.coo) || !value?.coo?.lat || !value?.coo?.lng) {
			return {
				...value,
				coo: undefined,
			}
		}
		return value
	})
	if (isRequired) {
		return yupObject.test(
			"required-geoloc",
			"Le champs est requis (adresse ou coordonées)",
			(val) => Boolean(val.Adresse || val.coo),
		)
	}
	return yupObject
}

export default IGeoloc
