import { Button } from "@mui/material"
import useCooperationConfig from "@/pages/cooperation/customdata/hooks/useCooperationConfig"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import styled from "styled-components"
import { PrimaryTextButton } from "utils/components/style/button"

const TextAlign = styled.div<{ align: "right" | "left" }>`
    text-align: ${({ align }) => align};
`

function LinkToOrga({ point, align }) {
	const coopFilters = useCooperationFilters()
	const dispatch = useDispatch()
	const history = useHistory()
	const { modesEnabled } = useCooperationConfig()

	const goToOrga = coopFilters.search
		? () => {
				history.push("/cooperation/organigrammes")
				dispatch(
					setCooperationFilters({
						communes: [point.commune_id],
						excludeCommunes: false,
					}),
				)
		  }
		: () => history.push("/customdata/organigramme")

	return !coopFilters.search || modesEnabled[SearchMode.ORGANIGRAMMES] ? (
		<PrimaryTextButton fullWidth onClick={goToOrga}>
			Voir l&apos;organigramme
		</PrimaryTextButton>
	) : (
		<TextAlign align={align}>
			Le partage d&apos;organigramme n&apos;est pas activé
		</TextAlign>
	)
}

export default LinkToOrga
