import React from "react"
import colored from "@/utils/colored"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import IPoint from "@/utils/types/IPoint"
import { StaticFormType } from "./StaticFormsService"
import { Chip } from "@nextui-org/react"
import styled from "styled-components"
import Avatar from "@/styles/atoms/Avatar"

const getColors = (human) => {
	switch (human?.jsonschema_id) {
		case StaticFormType.ANNUAIRES_ELUS:
			return {
				color: "primary",
				text: "white",
			}
		case StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL:
			return { color: "orange", text: "white" }
		case StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE:
			return { color: "green", text: "white" }
		default:
			return { background: "white", text: "grey" }
	}
}

const displayFullName = (human: IPoint) => {
	if (!human) {
		return ""
	}
	const firstName = human?.geojson?.properties?.Prénom ?? ""
	const lastName = human?.geojson?.properties?.Nom ?? ""
	const separator =
		human?.geojson?.properties?.Prénom && human?.geojson?.properties?.Nom
			? " "
			: ""
	return firstName + separator + lastName
}

const displayPoste = (human: IPoint) => {
	if (!human) {
		return ""
	}
	return human?.geojson?.properties?.Poste ?? "Aucun rôle attribué"
}

const ColoredChip = colored(Chip)

const displayTypeAsChip = (human: IPoint, small = false, isInterco) => {
	const colors = getColors(human)

	return (
		<ColoredChip
			size={small ? "small" : "medium"}
			color="primary"
			$color={colors.color}
			$textColor={colors.text}
			// $backgroundColor={colors.background}
			// $textColor={colors.text}
		>
			{intercoNameAdapter(human.jsonschema_id, isInterco)}
		</ColoredChip>
	)
}

const displayPhones = (human: IPoint) => {
	if (!human) {
		return ""
	}
	const phones = human.geojson.properties?.Téléphones ?? []
	const res = phones.map((phone) => [phone.Type, phone.Numéro].join(" : "))
	return (
		<ul>
			{res.map((phone) => (
				<li key="phone">{phone}</li>
			))}
		</ul>
	)
}

const displayInitials = (human: IPoint) => {
	if (!human) {
		return ""
	}
	const firstName = human?.geojson?.properties?.Prénom ?? ""
	const lastName = human?.geojson?.properties?.Nom ?? ""
	return (firstName?.[0] ?? "") + (lastName?.[0] ?? "")
}

const ColoredAvatar = styled(colored(Avatar))`
	color: hsl(var(--nextui-foreground)) !important;
	background-color: hsl(var(--nextui-primary)) !important;
`

const displayInitialsAsAvatar = (human: IPoint) => {
	const initials = displayInitials(human)
	const colors = getColors(human)
	return (
		<ColoredAvatar
			size="sm"
			color="primary"
			$textColor={colors.text}
			$color={colors.color}
			name={initials}
		/>
	)
}

export default {
	displayFullName,
	displayPoste,
	displayPhones,
	displayInitials,
	displayTypeAsChip,
	displayInitialsAsAvatar,
}
