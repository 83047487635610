import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import { useSelector } from "react-redux"
import { layersPersonnes, layersRessources } from "../utils/layers"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import _ from "lodash"
import IJsonSchema from "@/utils/types/IJsonSchema"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"

const selectCdcInfos = createDeepEqualSelector(
	(state: any) => state.cdc.infos,
	(item: any) => item,
)

const useCooperationConfig = () => {
	const infos = useSelector(selectCdcInfos)
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()

	const filteredLayersPersonnes = layersPersonnes.filter((item) =>
		infos?.shared_between_communes?.includes?.(item.key),
	)
	const filteredLayersRessources = layersRessources.filter((item) =>
		infos?.shared_between_communes?.includes(item.key),
	)
	const filteredLayersRessourcesPrivees = Object.values(cdcSharedJsonSchemas).map((item: IJsonSchema) => (
		item.id
	))

	return {
		modesEnabled: {
			[SearchMode.ORGANIGRAMMES]: Boolean(infos?.share_orga),
			[SearchMode.DONNEES_PARTAGEES]: 
				Boolean(filteredLayersPersonnes.length) ||
				Boolean(filteredLayersRessources.length) ||
				Boolean(filteredLayersRessourcesPrivees.length),
		},
		layers: {
			personnes: filteredLayersPersonnes,
			ressources: filteredLayersRessources,
			ressourcesPrivees: [],
		},
	}
}

export default useCooperationConfig
