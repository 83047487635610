import { Slider, TextField } from "@mui/material"
import { SectionTitle } from "@/pages/maincourante/care/CareResumeCard"
import ButtonPopover from "@/pages/maincourante/subComponents/ButtonPopover"
import Debug from "debug"
import React from "react"
import { ChromePicker } from "react-color"
import { useFormContext } from "react-hook-form"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
import {
	Container,
	SBorderColor,
	SFlex,
	SFormatColorFill,
	SLineWeight,
	SquareColor,
	STextField,
	SSlider,
} from "./FPolygonColor.styled"
// app:javascript:utils:form:carto:FPolygonColor:FPolygonColor.tsx
const debug = Debug(
	"app:javascript:utils:form:carto:FPolygonColor:FPolygonColor",
)
debug.log = console.log.bind(console)

interface Props {
	showPreview?: boolean
}

const FPolygonColor = ({ showPreview = false }: Props) => {
	const methods = useFormContext()
	const values = methods.watch()
	return (
		<Flex gap="2rem" fullWidth>
			<SFlex directionColumn gap="1rem" fullWidth>
				<Container alignItemsInitial gap="0.5rem" fullWidth>
					<SFormatColorFill />

					<Flex directionColumn gap="1rem" alignItemsInitial fullWidth>
						<SectionTitle>Remplissage</SectionTitle>
						<Flex directionColumn gap="0.5rem" alignItemsInitial fullWidth>
							<Subtitle>Couleur de remplissage</Subtitle>
							<ButtonPopover
								popoverContent={({ handleClose }) => (
									<ChromePicker
										color={values.fillColor}
										disableAlpha
										onChangeComplete={(color) =>
											methods.setValue("fillColor", color.hex)
										}
									/>
								)}
							>
								{({ handleClick }) => (
									<SquareColor color={values.fillColor} onClick={handleClick} />
								)}
							</ButtonPopover>
						</Flex>
						<Flex directionColumn gap="0.5rem" alignItemsInitial fullWidth>
							<Subtitle>Opacité de remplissage</Subtitle>
							<Flex fullWidth gap="0.5rem">
								<SSlider
									value={values.fillOpacity}
									onChange={(e, fillOpacity: number) => {
										return methods.setValue("fillOpacity", fillOpacity)
									}}
									aria-labelledby="discrete-slider"
									valueLabelDisplay="auto"
									// percentage
									valueLabelFormat={(value) => `${value * 100}%`}
									step={0.1}
									marks
									min={0}
									max={1}
								/>
								<STextField
									size="small"
									type="number"
									value={values.fillOpacity * 100}
									onChange={(e) => {
										return methods.setValue(
											"fillOpacity",
											Number(e.target.value) / 100,
										)
									}}
									InputProps={{
										endAdornment: "%",
									}}
									inputProps={{
										min: 0,
										max: 1,
										step: 0.1,
									}}
								/>
							</Flex>
						</Flex>
					</Flex>
				</Container>

				<Container alignItemsInitial gap="0.5rem" fullWidth>
					<SBorderColor />

					<Flex directionColumn gap="1rem" alignItemsInitial fullWidth>
						<SectionTitle>Bordure</SectionTitle>
						<Flex directionColumn gap="0.5rem" alignItemsInitial fullWidth>
							<Subtitle>Couleur de la bordure</Subtitle>
							<ButtonPopover
								popoverContent={({ handleClose }) => (
									<ChromePicker
										color={values.color}
										disableAlpha
										onChangeComplete={(color) =>
											methods.setValue("color", color.hex)
										}
									/>
								)}
							>
								{({ handleClick }) => (
									<SquareColor color={values.color} onClick={handleClick} />
								)}
							</ButtonPopover>
						</Flex>
						<Flex directionColumn gap="0.5rem" alignItemsInitial fullWidth>
							<Subtitle>Opacité de la bordure</Subtitle>
							<Flex fullWidth gap="0.5rem">
								<SSlider
									value={values.opacity}
									onChange={(e, opacity: number) =>
										methods.setValue("opacity", opacity)
									}
									aria-labelledby="discrete-slider"
									valueLabelDisplay="auto"
									// percentage
									valueLabelFormat={(value) => `${value * 100}%`}
									step={0.1}
									marks
									min={0}
									max={1}
								/>
								<STextField
									size="small"
									value={values.opacity * 100}
									onChange={(e) =>
										methods.setValue("opacity", Number(e.target.value) / 100)
									}
									InputProps={{
										endAdornment: "%",
									}}
									type="number"
									inputProps={{
										min: 0,
										max: 1,
										step: 0.1,
									}}
								/>
							</Flex>
						</Flex>
					</Flex>
				</Container>
				<Container alignItemsInitial gap="0.5rem" fullWidth>
					<SLineWeight />

					<Flex directionColumn gap="0.5rem" alignItemsInitial fullWidth>
						<SectionTitle>Épaisseur du trait</SectionTitle>

						<Flex directionColumn gap="0.5rem" alignItemsInitial fullWidth>
							<Subtitle>Épaisseur de la bordure</Subtitle>
							<Flex fullWidth gap="0.5rem">
								<SSlider
									value={values.weight}
									onChange={(e, weight: number) =>
										methods.setValue("weight", weight)
									}
									aria-labelledby="discrete-slider"
									valueLabelDisplay="auto"
									step={0.5}
									marks
									min={0}
									max={5}
								/>
								<STextField
									size="small"
									value={values.weight}
									onChange={(e) =>
										methods.setValue("weight", parseFloat(e.target.value))
									}
									type="number"
									inputProps={{
										min: 0,
										max: 5,
										step: 0.5,
									}}
								/>
							</Flex>
						</Flex>
					</Flex>
				</Container>
			</SFlex>
			{showPreview && (
				<Flex directionColumn alignItemsStart>
					<SectionTitle>Prévisualisation</SectionTitle>
					<MultiPolygonColorSquare
						multiPolygon={{
							custom_props: { style: values },
						}}
						size={"large"}
					/>
				</Flex>
			)}
		</Flex>
	)
}

export default FPolygonColor
