import _ from "lodash"
import {
	booleanContains,
	booleanPointInPolygon,
	point,
	buffer,
	nearestPointOnLine,
	distance,
	polygon,
	multiPolygon,
	booleanClockwise,
	flatten,
	union,
	Feature,
	Point,
	lineString,
	featureCollection,
} from "@turf/turf"
import IPoint from "utils/types/IPoint"
import {
	AnyGeojsonFeature,
	ITurfFeature,
	ITurfFeatureCollection,
	ITurfMultiPolygon,
	ITurfPolygon,
} from "utils/types/ITurfGeojson"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import IJsonSchema from "@/utils/types/IJsonSchema"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"

const bufferMultiPolygonGeojson = (
	originalGeojson: ITurfMultiPolygon,
	currentGeojson: ITurfMultiPolygon,
	buffValue: number,
) => {
	const reverseClockwiseRes =
		buffValue > 0 &&
		!_.isEqual(originalGeojson.coordinates, currentGeojson.coordinates) //* Reverse if already buffed

	const bufferedGeojsonCoordinates = [] as number[][][][]

	currentGeojson.coordinates.forEach((polygonsArray, i) => {
		bufferedGeojsonCoordinates.push([])
		polygonsArray.forEach((poly) => {
			const isFilledOne = reverseClockwiseRes
				? !booleanClockwise(poly)
				: booleanClockwise(poly)

			if (isFilledOne) {
				const tempPoly = polygon([poly], { name: "bufferedPolyCoos" })
				const bufferedCoos = buffer(tempPoly, buffValue, {
					units: "meters",
				}).geometry.coordinates[0]
				bufferedGeojsonCoordinates[i].push(bufferedCoos)
			} else {
				bufferedGeojsonCoordinates[i].push(poly)
			}
		})
	})

	return bufferedGeojsonCoordinates
}

const uniformGeojson = (flattedGeojson: ITurfFeatureCollection) => {
	const features = flattedGeojson.features as Array<
		ITurfFeature & { geometry: ITurfPolygon | ITurfMultiPolygon }
	>
	let uniformed = union(features[0].geometry, features[1].geometry)

	for (let i = 2; i < features.length; i++) {
		uniformed = union(uniformed.geometry, features[i].geometry)
	}

	return uniformed.geometry
}

const buffSelectedGeojson = async (
	geojson: AnyGeojsonFeature,
	buffValue: number,
	originalGeojson: AnyGeojsonFeature,
	selectedRangeValue: number,
	setIsOpenModalBuffingLoading: (isOpen?: any) => void,
) => {
	let buffered = undefined as AnyGeojsonFeature

	if (selectedRangeValue !== 0) {
		if (geojson.type === "FeatureCollection") {
			//@ts-ignore : buffer() accept FeatureCollection geojson even if IDE return type error for "geojson" (see Turfjs doc.)
			const bufferedGeojson = buffer(geojson, buffValue, {
				units: "meters",
			}) as ITurfFeatureCollection
			if (bufferedGeojson.features.length > 1) {
				const uniformedBufferedGeojson = uniformGeojson(bufferedGeojson)
				buffered = uniformedBufferedGeojson
			} else {
				buffered = bufferedGeojson
			}
		}
		if (geojson.type === "Polygon") {
			const bufferedGeojson = buffer(geojson, buffValue, {
				units: "meters",
			})

			buffered = bufferedGeojson.geometry
		}
		if (geojson.type === "MultiPolygon") {
			const originalGeo = originalGeojson as ITurfMultiPolygon
			const bufferedCoordinates = bufferMultiPolygonGeojson(
				originalGeo,
				geojson,
				buffValue,
			)
			const bufferedGeojson = multiPolygon(bufferedCoordinates)
			const flattenBufferedGeojson = flatten(bufferedGeojson)
			const uniformedBufferedGeojson = uniformGeojson(flattenBufferedGeojson)

			buffered = uniformedBufferedGeojson
		}
		if (geojson.type === "MultiLineString") {
			const linesCoordinates = geojson.coordinates
			const tempFeatures = linesCoordinates.map((lineCoos) =>
				buffer(lineString(lineCoos), buffValue, {
					units: "meters",
				}),
			)

			const features = tempFeatures.filter((geojson) => geojson)
			const bufferedGeojson = featureCollection(features)
			const uniformedBufferedGeojson = uniformGeojson(bufferedGeojson)
			buffered = uniformedBufferedGeojson
		}
	}

	setIsOpenModalBuffingLoading(false)
	return buffered ?? geojson
}

const customContains = (
	geojson: AnyGeojsonFeature,
	point: Feature<
		Point,
		{
			[name: string]: any
		}
	>,
) => {
	if (geojson.type === "FeatureCollection") {
		return geojson.features.some((geoFeature) =>
			customContains(geoFeature, point),
		)
	}
	if (geojson.type === "Feature") {
		return customContains(geojson.geometry, point)
	}
	if (geojson.type === "MultiPolygon") {
		return geojson.coordinates.some((polygonCoordinates) =>
			booleanContains(
				{
					type: "Polygon",
					coordinates: polygonCoordinates,
				},
				point,
			),
		)
	}
	if (geojson.type === "LineString") {
		const pointCoordinates = point.geometry.coordinates
		const nearestPoint = nearestPointOnLine(geojson, pointCoordinates)
		const dist = distance(pointCoordinates, nearestPoint)
		const isOnLine = dist < 0.002 //distance in degrees

		return isOnLine
	}
	if (geojson.type === "MultiLineString") {
		return geojson.coordinates.some((lineCoordinates) => {
			const pointCoordinates = point.geometry.coordinates
			const line = lineString(lineCoordinates, { name: "tempLine" })
			const nearestPoint = nearestPointOnLine(line, pointCoordinates)
			const dist = distance(pointCoordinates, nearestPoint)
			const isOnLine = dist < 0.002 //distance in degrees

			return isOnLine
		})
	}

	return booleanPointInPolygon(point, geojson)
}

const nbDatasWithoutGeoloc = (
	pointsToDisplay: IPoint[],
	jsonSchemas: { [key: string]: IJsonSchema },
) => {
	const nbDatasWithoutGeoloc = pointsToDisplay.filter((point) => {
		const geolocKey = JsonSchemaService.getUndeterminedGeolocPropertyName(
			point,
			jsonSchemas,
		)
		const coos = point.geojson.properties[geolocKey]?.coo

		return _.isEmpty(coos)
	}).length

	return nbDatasWithoutGeoloc
}

const filterPointsInGeojson = async (
	pointsToFilter: IPoint[],
	geojson: AnyGeojsonFeature,
	jsonSchemas: { [key: string]: IJsonSchema },
) => {
	let turfCouche = _.cloneDeep(geojson)

	if (Array.isArray(turfCouche))
		turfCouche = {
			type: "FeatureCollection",
			features: turfCouche,
		}

	if (!turfCouche) return
	const pointsInGeojson = await Promise.all(
		pointsToFilter.map(async (pointObj) => {
			const geolocKey = JsonSchemaService.getUndeterminedGeolocPropertyName(
				pointObj,
				jsonSchemas,
			)
			if (!geolocKey) return
			const coos = pointObj.geojson.properties[geolocKey]?.coo
			if (!coos || !coos.lng || !coos.lat) return
			const turfPoint = point([coos.lng, coos.lat])
			let contained = !!customContains(turfCouche, turfPoint)

			if (contained) return pointObj
		}),
	)

	return pointsInGeojson.filter((point) => point)
}

export default {
	bufferMultiPolygonGeojson,
	uniformGeojson,
	buffSelectedGeojson,
	customContains,
	nbDatasWithoutGeoloc,
	filterPointsInGeojson,
}
