import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import { setPanel } from "@/redux-toolkit/common/reducer"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import SearchIcon from "@mui/icons-material/Search"
import { List, ListItem, ListItemButton, TextField } from "@mui/material"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import usePointPropertyToText from "@/utils/components/jsonSchema/properties/usePointPropertyToText"
import { Flex } from "@/utils/components/style/flex"
import { Neutral500Text, Small } from "@/utils/components/style/text"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import { DAYBOOK_LEGEND, DAYBOOK_SUPPORT_POINT } from "./PanelCarto"
import Button from "@/styles/atoms/Button"
import useMapState from "@/hooks/useMapState"
import Div from "../dnd/Div"
import CollapsibleList from "../components/list/CollapsibleList"
import FeedIcon from '@mui/icons-material/Feed'
import Defer from "../Defer"
import getImage from "../getImage"

// app:javascript:utils:panels:PanelDaybookList.tsx
const debug = Debug("app:javascript:utils:panels:PanelDaybookList")
debug.log = console.log.bind(console)

const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
`

const StyledFlex = styled(Flex)`
    justify-content: space-between !important;
    width: 100% !important;
    flex-grow: 0 !important;
`
const StyledListItem = styled(ListItem)`
    padding: 0px !important;
    width: 100% !important;
`

const StyledListItemButton = styled(ListItemButton)`
    display: flex;
    justify-content: space-between !important;
    border-bottom: 1px solid var(--neutral100) !important;
`
const SFlex = styled(Flex)`
    padding: 1rem;
    width: 100% !important;
`
const StyledList = styled(List)`
    padding: 0px !important;
    width: 100% !important;
    max-height: calc(100vh - 11rem);
    overflow: auto;
    & ${StyledListItem}:first-child {
        ${StyledListItemButton} {
            border-top: 1px solid var(--neutral100) !important;
        }
    }
`
const SSearchIcon = styled(SearchIcon)`
    margin-right: 0.5rem;
`
const PanelSupportsList = () => {
    const dispatch = useDispatch()
    const points = usePoints()
    const multiPolygons = useMultiPolygons()
    const jsonSchemas = useJsonSchemasWithStatics()
    const pointPropertyToText = usePointPropertyToText()
    const [search, setSearch] = useState("")
    const [collapsedList, setCollapsedList] = useState([])
    const { showedFacility } = useMapState()
    const group = points[showedFacility]

    const supportsByCategory = useMemo(() => {
        const res = {} as { [key: string]: any[] }
        Object.values(points).filter((point) =>
            point.geojson.properties.suivi_group_id === showedFacility
        ).forEach((support) => {
            const supportJSchemaId = support.geojson.properties.relatedObject.categoryId
            if (!jsonSchemas[supportJSchemaId]) return
            if (!res[supportJSchemaId]) res[supportJSchemaId] = []
            res[supportJSchemaId].push(support)
        })

        return res
    }, [points, showedFacility])

    const searchedPoints = useMemo(() => {
        return _.union(...Object.values(supportsByCategory)).filter((support: any) => {
            const idPropertiesInternal = []
            const point = points[support.geojson.properties.relatedObject.objectId]
            const jsonSchema = jsonSchemas[support.geojson.properties.relatedObject.categoryId]
            jsonSchema.template.properties.forEach((property: any) => {
                if (property.isIdentifiant) {
                    idPropertiesInternal.push(point.geojson.properties[property.name])
                }
            })
            return formatNfdLowerCase(idPropertiesInternal.join("")).includes(
                formatNfdLowerCase(search),
            )
        })
    }, [search, supportsByCategory])

    const idPropertiesByCategory = {}
    Object.keys(supportsByCategory).forEach((jsonschema_id) => {
        idPropertiesByCategory[jsonschema_id] = []
        let firstColumn
        const jsonSchema = jsonSchemas[jsonschema_id]
        jsonSchema.template.properties.forEach((property: any) => {
            if (property.isIdentifiant) {
                idPropertiesByCategory[jsonschema_id].push(property)
            }
            if (property.isColumn && !firstColumn) {
                firstColumn = property
            }
        })
        if (idPropertiesByCategory[jsonschema_id].length === 0) {
            idPropertiesByCategory[jsonschema_id].push(firstColumn)
        }
    })

    return (
        <Container>
            <SFlex directionColumn gap="1rem">
                <StyledFlex>
                    <Button
                        color="primary"
                        variant="light"
                        onClick={() => dispatch(setPanel({ type: DAYBOOK_LEGEND }))}
                        startContent={<ArrowBackIcon />}
                    >
                        Retour à la légende
                    </Button>
                </StyledFlex>
                <Flex gap="1rem" fullWidth>
                    <FeedIcon />
                    <div>
                        {group?.geojson?.properties?.name}
                        <Neutral500Text>
                            <Small>{_.sum(Object.values(supportsByCategory).map((values) => values.length))} accompagnements</Small>
                        </Neutral500Text>
                    </div>
                </Flex>
                <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Rechercher un accompagnement"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    InputProps={{
                        startAdornment: <SSearchIcon />,
                    }}
                    helperText={
                        _.isEmpty(search)
                            ? null
                            : searchedPoints.length === 0
                                ? "Aucun résultat"
                                : searchedPoints.length + " résultats"
                    }
                />
            </SFlex>
            <StyledList>
                {!search.length && (Object.entries(supportsByCategory).map(([key, supports]) => {
                    return (
                        <CollapsibleList
                            key={key}
                            Wrapper={Div}
                            avatar={<img
                                src={getImage(jsonSchemas[key]?.imgId)}
                                width={25}
                                height={25}
                            />}
                            buttonText={jsonSchemas[key].title}
                            initialOpen={!collapsedList.includes(key)}
                            onCollapse={() => {
                                if (!collapsedList.includes(key)) {
                                    setCollapsedList([...collapsedList, key])
                                } else {
                                    setCollapsedList(
                                        collapsedList.filter((cat) => cat !== key),
                                    )
                                }
                            }}
                            numberElements={supports.length}
                        >
                            {supports.map((support) => {
                                return (
                                    <StyledListItem key={support.id}>
                                        <StyledListItemButton
                                            onClick={() => {
                                                dispatch(
                                                    setPanel({
                                                        type: DAYBOOK_SUPPORT_POINT,
                                                        id: support.id,
                                                    }),
                                                )
                                                const point = points[support?.geojson?.properties?.relatedObject?.objectId]
                                                if (!point) return
                                                const geolocProperty =
                                                    JsonSchemaService.getGeolocProperty(jsonSchemas[point?.jsonschema_id])
                                                const coordinates = point?.geojson?.properties?.[geolocProperty.name]?.coo
                                                if (_.isEmpty(coordinates)) return
                                                MapService.getMap().flyTo(coordinates, 18)
                                            }}
                                        >
                                            <Flex gap="0.5rem">
                                                {idPropertiesByCategory[key].map((idProperty: any) => {
                                                    const point = points[support?.geojson?.properties?.relatedObject?.objectId]
                                                    let pointProperties =
                                                        point?.geojson?.properties?.[idProperty.name]
                                                    if (!pointProperties) {
                                                        return "(sans identifiant)"
                                                    }
                                                    if (!_.isString(pointProperties)) {
                                                        if (pointProperties.secteur) {
                                                            pointProperties =
                                                                multiPolygons[pointProperties.secteur]?.name ?? ""
                                                        }
                                                        if (idProperty.name === "Responsables") {
                                                            //* Even if's identifiant, we won't display managers here for sector points
                                                            pointProperties = ""
                                                        }
                                                    }
                                                    return (
                                                        <div key={idProperty.name}>
                                                            {pointPropertyToText(
                                                                point,
                                                                jsonSchemas[key].template.properties.find(
                                                                    (property) => property.name === idProperty.name,
                                                                ),
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </Flex>
                                            <ArrowForwardIosIcon fontSize="small" />
                                        </StyledListItemButton>
                                    </StyledListItem>
                                )
                            })}
                        </CollapsibleList>
                    )
                }))}
                <Defer>
                    {!!search.length && (searchedPoints.map((support: any) => {
                        const jsonschema_id = jsonSchemas[support.geojson.properties.relatedObject.categoryId].id
                        return (
                            <StyledListItem key={support.id}>
                                <StyledListItemButton
                                    onClick={() => {
                                        dispatch(
                                            setPanel({
                                                type: DAYBOOK_SUPPORT_POINT,
                                                id: support.id,
                                            }),
                                        )
                                        const point = points[support?.geojson?.properties?.relatedObject?.objectId]
                                        if (!point) return
                                        const geolocProperty =
                                            JsonSchemaService.getGeolocProperty(jsonSchemas[point?.jsonschema_id])
                                        const coordinates = point?.geojson?.properties?.[geolocProperty.name]?.coo
                                        if (_.isEmpty(coordinates)) return
                                        MapService.getMap().flyTo(coordinates, 18)
                                    }}
                                >
                                    <Flex gap="0.5rem">
                                        {idPropertiesByCategory[jsonschema_id].map((idProperty: any) => {
                                            const point = points[support?.geojson?.properties?.relatedObject?.objectId]
                                            let pointProperties =
                                                point?.geojson?.properties?.[idProperty.name]
                                            if (!pointProperties) {
                                                return "(sans identifiant)"
                                            }
                                            if (!_.isString(pointProperties)) {
                                                if (pointProperties.secteur) {
                                                    pointProperties =
                                                        multiPolygons[pointProperties.secteur]?.name ?? ""
                                                }
                                                if (idProperty.name === "Responsables") {
                                                    //* Even if's identifiant, we won't display managers here for sector points
                                                    pointProperties = ""
                                                }
                                            }
                                            return (
                                                <div key={idProperty.name}>
                                                    {pointPropertyToText(
                                                        point,
                                                        jsonSchemas[jsonschema_id].template.properties.find(
                                                            (property) => property.name === idProperty.name,
                                                        ),
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </Flex>
                                    <ArrowForwardIosIcon fontSize="small" />
                                </StyledListItemButton>
                            </StyledListItem>
                        )
                    }))}
                </Defer>
            </StyledList>
        </Container>
    )
}

export default PanelSupportsList
