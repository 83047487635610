import EditIcon from "@mui/icons-material/Edit"
import {
	CardHeader,
	Chip,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { useDispatch } from "react-redux"
import { Flex } from "../style/flex"
import LightTooltip from "../../../styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { StyledChip } from "../style/chip"

function VehiculeChip({ vehicule, editable = true, highlight = false }) {
	const dispatch = useDispatch()
	return (
		<LightTooltip
			arrow
			title={
				<>
					<CardHeader
						title={vehicule.geojson.properties["Modèle"]}
						subheader={vehicule.geojson.properties["Type"]}
						action={
							editable && (
								<IconButton onClick={() => dispatch(setCurrentPoint(vehicule))}>
									<EditIcon />
								</IconButton>
							)
						}
					/>

					<Table>
						<TableBody>
							{["Immatriculation", "Places", "Carburant", "Observation"].map(
								(field) => (
									<TableRow key={field}>
										<TableCell>{field}</TableCell>
										<TableCell align="right">
											{vehicule.geojson.properties[field]}
										</TableCell>
									</TableRow>
								),
							)}
						</TableBody>
					</Table>
				</>
			}
		>
			<StyledChip
				style={{ maxWidth: "100%" }}
				variant="outlined"
				label={vehicule.geojson.properties["Modèle"]}
				$colorProperty={highlight ? "var(--blue800)" : undefined}
			/>
		</LightTooltip>
	)
}

export default VehiculeChip
