import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

const useDraggableInPortal = () => {
	const [draggingElement, setDraggingElement] = useState<HTMLDivElement | null>(
		null,
	)

	useEffect(() => {
		const div = document.createElement("div")
		div.style.position = "absolute"
		div.style.pointerEvents = "none"
		div.style.top = "0"
		div.style.width = "100%"
		div.style.height = "100%"
		setDraggingElement(div)
		document.body.appendChild(div)
		return () => {
			document.body.removeChild(div)
		}
	}, [])

	return (render) => (provided, ...args) => {
		const element = render(provided, ...args)
		if (provided.draggableProps.style.position === "fixed") {
			console.log(self)
			if (_.isEmpty(draggingElement)) return element
			return createPortal(element, draggingElement)
		}
		return element
	}
}

export default useDraggableInPortal
