import React from "react"
import { ComponentProps } from "react"
import FText from "./FText"

const FTextArea = (props: ComponentProps<typeof FText>) => {
	// FText display number of character
	return <FText multiline displayNumberCharacter {...props} />
}

export default FTextArea
