import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import { Icon } from "@iconify/react"
import ClearIcon from "@mui/icons-material/Clear"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import SearchIcon from "@mui/icons-material/Search"
import WarningIcon from "@mui/icons-material/Warning"
import AlarmIcon from "@mui/icons-material/Alarm"
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	TextField,
} from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import { JSON_SCHEMAS, MULTI_POLYGONS } from "@/redux-toolkit/time/constants"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { PrimaryButton } from "utils/components/style/button"
import {
	ANNUAIRES,
	DISPOSITIF_GESTION_CRISE,
	ENJEUX,
	RESSOURCES,
	RISQUES,
} from "utils/menuTab"
import CategoryView from "./subComponents/CategoryView"
import JsonSchemaCard from "./subComponents/JsonSchemaCard"
import MultiPolygonCard from "./subComponents/MultiPolygonCard"
import useNavigationTree from "./useNavigationTree"
import CampaignIcon from "@mui/icons-material/Campaign"

const SCard = styled(Card)`
    margin: 1em;
`
const SAvatar = styled(Avatar)`
    ${(props) => css`
        background-color: ${props.color} !important;
    `}
`
const StyledList = styled.div``

interface ITreeDisplayConfig {
	[key: string]: {
		icon: JSX.Element
		color: string
	}
}

export const treeDisplayConfig: ITreeDisplayConfig = {
	[DISPOSITIF_GESTION_CRISE]: {
		icon: <CampaignIcon />,
		color: "var(--brown)",
	},
	[ANNUAIRES]: {
		icon: <ContactPhoneIcon />,
		color: "var(--green500)",
	},
	[RESSOURCES]: {
		icon: <HomeWorkIcon />,
		color: "var(--primary500)",
	},
	[ENJEUX]: {
		icon: <AdminPanelSettingsIcon />,
		color: "var(--orange500)",
	},
	[RISQUES]: {
		icon: <WarningIcon />,
		color: "var(--red500)",
	},
}

const CategoryNavigation = ({ cartography = false }) => {
	const [searchValue, setSearchValue] = useState("")
	const [searchResults, setSearchResults] = useState([])
	const [selectedCategory, setSelectedCategory] = useState(undefined)

	const { tree, flat } = useNavigationTree({
		includeMultiPolygons: cartography,
	})

	const updateSearchResult = (event) => {
		const searchValue = event.target.value
		const capitalizedSearchValue = searchValue.toUpperCase()

		const results = flat.filter((item) =>
			item.title.toUpperCase().includes(capitalizedSearchValue),
		)

		setSearchValue(searchValue)
		setSearchResults(results)
	}

	return (
		<StyledList>
			{!selectedCategory && (
				<SCard>
					<CardContent>
						<TextField
							placeholder="Rechercher une donnée"
							value={searchValue}
							onChange={updateSearchResult}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<>
										{searchValue && (
											<InputAdornment position="start">
												<IconButton onClick={() => setSearchValue("")}>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										)}
									</>
								),
							}}
						/>
					</CardContent>
				</SCard>
			)}
			{!searchValue &&
				!selectedCategory &&
				tree.map((category) => {
					const number = category.subCategories.reduce((acc, subCategory) => {
						return acc + subCategory.items.length
					}, 0)
					return (
						<SCard key={category.title}>
							<CardHeader
								avatar={
									<SAvatar color={treeDisplayConfig[category.title]?.color}>
										{treeDisplayConfig[category.title]?.icon}
									</SAvatar>
								}
								title={<h5>{category.title}</h5>}
								subheader={`${number} jeux de données`}
								action={
									<PrimaryButton
										size="small"
										onClick={() => {
											setSelectedCategory(category)
										}}
									>
										Voir
									</PrimaryButton>
								}
							/>
						</SCard>
					)
				})}
			{searchValue && (
				<>
					{searchResults.map((item) => (
						<React.Fragment key={item.id}>
							{item.type === JSON_SCHEMAS && (
								<JsonSchemaCard cartography={cartography} jsonSchema={item} />
							)}
							{item.type === MULTI_POLYGONS && (
								<MultiPolygonCard multiPolygon={item} />
							)}
						</React.Fragment>
					))}
				</>
			)}
			{selectedCategory && (
				<CategoryView
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					cartography={cartography}
					color={treeDisplayConfig[selectedCategory.title]?.color}
				/>
			)}
		</StyledList>
	)
}

export default CategoryNavigation
