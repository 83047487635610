import React from "react"
import { Flex } from "utils/components/style/flex"
import { DragDropContext } from "react-beautiful-dnd"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDroppable from "utils/dnd/SuperDroppable"
import { StyledChip } from "utils/components/style/chip"
import { IFExtractSelectOption } from "../FExtractSelect"

interface IDraggablePointTags {
	getTagProps: any
	value: any
	onDragEnd: any
	readOnly: boolean
}

const DraggablePointTags = ({
	getTagProps,
	value,
	onDragEnd,
	readOnly,
}: IDraggablePointTags) => {
	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<SuperDroppable
					component={Flex}
					droppableProps={{
						direction: "horizontal",
					}}
					$wrap
					gap="5px"
				>
					{value.map((option: IFExtractSelectOption, index: number) => {
						return (
							<SuperDraggable
								key={option.label}
								id={option.label}
								index={index}
								disabled={readOnly}
							>
								<StyledChip
									variant="outlined"
									disabled={false}
									avatar={
										option.imageSrc && (
											<img
												src={option.imageSrc}
												alt={option.label}
												height="20px"
												width="20px"
											/>
										)
									}
									label={
										<Flex directionColumn>
											<div>{option.label ?? ""}</div>
										</Flex>
									}
									{...getTagProps({ index })}
									{...(readOnly
										? {
												onDelete: undefined,
										  }
										: {
												style: {
													cursor: "grab",
													backgroundColor: "white",
												},
										  })}
								/>
							</SuperDraggable>
						)
					})}
				</SuperDroppable>
			</DragDropContext>
		</>
	)
}

export default DraggablePointTags
