import React from "react"
import { Paper } from "@mui/material"
import styled from "styled-components"

const StyledPaper = styled(Paper)`
    height: 55px;
    background-color: var(--primary800) !important;
    color: white !important;
    padding: 0.5rem;
    border-radius: 0px !important;
`

export default StyledPaper
