import LocalPoliceIcon from "@mui/icons-material/LocalPolice"
import { Button } from "@mui/material"
import { setCurrentIncident } from "@/redux-toolkit/edit/reducer"
import Debug from "debug"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import EventNoteIcon from "@mui/icons-material/EventNote"
import StyledPaper from "./StyledButton"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { Kbd } from "@nextui-org/react"

// app:javascript:components:maincourante:subComponents:bottomCenterControl:EventControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:EventControl",
)
debug.log = console.log.bind(console)
const StyledButton = styled(Button)`
    padding: 0 !important;
`
const IncidentControl = () => {
	const dispatch = useDispatch()
	return (
		<LightTooltip
			title={
				<>
					Raccourci : <Kbd>Ctrl+Espace</Kbd>
				</>
			}
		>
			<StyledButton
				onClick={() => {
					dispatch(setCurrentIncident({}))
				}}
			>
				<StyledPaper>
					<Flex directionColumn>
						<EventNoteIcon />
						<div>Evènement</div>
					</Flex>
				</StyledPaper>
			</StyledButton>
		</LightTooltip>
	)
}

export default IncidentControl
