import Axios from "axios"

const getUserSettings = async () => {
	const res = await Axios.get("/api/users/self")
	return res.data
}

export default {
	getUserSettings,
}
