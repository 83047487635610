import { StaticFormType } from "utils/services/StaticFormsService"
import React from "react"
import LinkToOrga from "./enhancers/LinkToOrga"
import DisplayStocks from "./enhancers/DisplayStocks"
import DisplayVehicules from "./enhancers/DisplayVehicules"

function useEnhanceStatic(schema) {
	const enhancements = {
		addBefore: [],
		addAfter: [],
	}

	if (!schema) return enhancements

	if (
		schema.id === StaticFormType.STOCK_PLACE ||
		schema.id === StaticFormType.CARE
	) {
		enhancements.addAfter.push({
			title: "Stocks",
			renderer: (props) => <DisplayStocks {...props} />,
		})
		enhancements.addAfter.push({
			title: "Véhicules",
			renderer: (props) => <DisplayVehicules {...props} />,
		})
	}

	if (schema.id === StaticFormType.PCC) {
		enhancements.addBefore.push({
			title: "Organigramme",
			renderer: (props) => <LinkToOrga {...props} />,
		})
	}

	return enhancements
}

export default useEnhanceStatic
