import ArrowBack from "@mui/icons-material/ArrowBack"
import useStep from "@/pages/telechargement/Upload/hooks/useStep"
import React, { useState, useEffect } from "react"

import { Flex } from "@/utils/components/style/flex"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"

const ButtonsSection = ({
	steps,
	step,
	setStep,
	onSubmit,
	onClose,
	setModalPanel,
	isEditingCoucheStyle,
	setIsEditingCoucheStyle,
	tempValue,
	setTempValue,
}: {
	steps: string[]
	step: number
	setStep: (value: number) => void
	onSubmit: any
	onClose: () => void
	setModalPanel: React.Dispatch<
		React.SetStateAction<{ panel: number; id: string }>
	>
	isEditingCoucheStyle: boolean
	setIsEditingCoucheStyle: React.Dispatch<React.SetStateAction<boolean>>
	tempValue: any
	setTempValue: React.Dispatch<React.SetStateAction<any>>
}) => {
	const { value, setStepValue, activeStep } = useStep(steps[step] ?? "")
	const { onBack } = useStep(steps[step - 1] ?? "")
	const skippableStep = [0]

	return (
		<Flex flexEnd fullWidth gap=".5em">
			{isEditingCoucheStyle && (
				<Button
					variant="outlined"
					color="primary"
					startIcon={<ArrowBack />}
					onClick={() => {
						setIsEditingCoucheStyle(false)
						setModalPanel({ panel: 0, id: undefined })
					}}
				>
					Retour
				</Button>
			)}
			{!isEditingCoucheStyle && <CancelButton onClick={onClose} />}
			{step > 0 && (
				<Button variant="outlined" color="primary" onClick={onBack}>
					Précédent
				</Button>
			)}
			{step === steps.length && (
				<Button color="primary" onClick={onSubmit}>
					Terminer
				</Button>
			)}
			{step < steps.length && !isEditingCoucheStyle && (
				<Button
					color="primary"
					disabled={!tempValue && !skippableStep.includes(activeStep)}
					onClick={() => {
						setStepValue(tempValue)
						setTempValue(undefined)
					}}
				>
					Suivant
				</Button>
			)}
		</Flex>
	)
}

export default ButtonsSection
