import AddLocationIcon from "@mui/icons-material/AddLocation"
import CloseIcon from "@mui/icons-material/Close"
import LocationOffIcon from "@mui/icons-material/LocationOff"
import {
	Autocomplete,
	Divider,
	IconButton,
	MenuItem,
	Paper,
	TextField,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Axios from "axios"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import useCommuneGeojson from "@/hooks/useCommuneGeojson"
import Debug from "debug"
import L from "leaflet"
import { default as React, useEffect, useRef, useState, useMemo } from "react"
import { Marker, TileLayer } from "react-leaflet"
import { useGeolocation } from "react-use"
import styled from "styled-components"
import StyledControl from "@/utils/components/map/StyledControl"
import SuperMap from "@/utils/components/map/SuperMap"
import CustomTooltip from "@/utils/components/style/CustomTooltip"
import { Flex } from "@/utils/components/style/flex"
import { GeolocUser } from "@/utils/form/GeolocUser"
import getImage from "@/utils/getImage"
import IGeoloc from "@/utils/types/IGeoloc"
import _ from "lodash"
import useAddressSearch from "@/hooks/api/useAddressSearch"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import { ModalContent } from "@nextui-org/react"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import { PrimaryButton } from "@/utils/components/style/button"

// app:javascript:utils:form:specific:geoloc:FModalSelectGeoloc.tsx
const debug = Debug(
	"app:javascript:utils:form:specific:geoloc:FModalSelectGeoloc",
)
debug.log = console.log.bind(console)

const SPaper = styled(Paper)`
    min-width: 200px;
    max-width: 600px;
    flex: 1;
`

const SuperStyledControl = styled(StyledControl)`
    //margin-right: 40%;
    margin-top: 10px;
    left: 75px;
    right: 75px;
    display: flex;
    justify-content: center;
    gap: 1em;
    .MuiOutlinedInput-notchedOutline {
        top: -7px;
    }
`
const SModalBody = styled(ModalBody)`
padding: 0 !important;
margin: 0 !important;
`
const StyledContainer = styled.div`
	--leaflet-container-height: 60vh;
	--leaflet-container-width: 100%;
	height: var(--leaflet-cotainer-height);

	overflow: hidden;
`
const FModalSelectGeoloc = ({
	isOpen,
	onClose,
	onChangeFinalValue,
	finalValue,
}) => {
	const communeGeojson = useCommuneGeojson()
	const userPosition = useGeolocation({ enableHighAccuracy: true })
	const autoCompleteRef = useRef(null)
	const deleteRef = useRef(null)
	const [value, onChange] = useState({} as any)
	const markerRef = useRef(null)
	const onDoubleClick = (event, activeMap) => {
		onChange({
			...value,
			coo: { lat: event.latlng.lat, lng: event.latlng.lng },
		})
		activeMap.flyTo(event.latlng, activeMap.getZoom())
	}

	const { isLoading, apiAddressResults } = useAddressSearch({
		value,
		condition: true,
	})

	useEffect(() => {
		if (finalValue?.coo?.lat === "" && finalValue?.coo?.lng === "") {
			onChange({ ...value, coo: undefined })
		} else {
			onChange(finalValue ?? {})
		}
	}, [isOpen, finalValue])

	useEffect(() => {
		// Looking if finalValue has already coordinates when modal is opening
		const initialValue = finalValue
		if (initialValue?.coo?.lat && initialValue?.coo?.lng) {
			setTimeout(
				() =>
					MapService.setView({
						lat: initialValue.coo.lat,
						lng: initialValue.coo.lng,
					}),
				100,
			)
		}
	}, [isOpen])

	const onSelectAddress = (address: IGeoloc) => {
		const newAdress = {
			Adresse: address.Adresse,
			coo: {
				lat: address.coo.lat,
				lng: address.coo.lng,
			},
		} as IGeoloc
		onChange(newAdress)
		MapService.setView({
			lat: address.coo.lat,
			lng: address.coo.lng,
		})
		setTimeout(() => {
			deleteRef.current.focus()
			MapService.enableScrollAndDrag()
		}, 100)
	}

	const onSelectPoint = (point) => {
		const newAdress = point.geoloc
		onChange(newAdress)
		MapService.setView({
			lat: newAdress.coo.lat,
			lng: newAdress.coo.lng,
		})
		setTimeout(() => deleteRef.current.focus(), 100)
	}

	const selectUserLocation = async () => {
		const res = await Axios.get(
			`https://api-adresse.data.gouv.fr/reverse/?lon=${userPosition.longitude}&lat=${userPosition.latitude}`,
		)
		const resultAddress =
			res?.data?.features?.[0]?.properties?.label ??
			"Pas d'adresse correspondante"
		const newAdress = {
			Adresse: resultAddress,
			coo: {
				lat: userPosition.latitude,
				lng: userPosition.longitude,
			},
		} as IGeoloc
		onChange(newAdress)
		MapService.setView({
			lat: userPosition.latitude,
			lng: userPosition.longitude,
		})
		setTimeout(() => deleteRef.current.focus(), 100)
		MapService.enableScrollAndDrag()
	}

	const renderOption = (option, state) => {
		switch (state.type) {
			case "MA_POSITION":
				return (
					<GeolocUser
						userPosition={userPosition}
						onClickAction={selectUserLocation}
					/>
				)
			case "ADRESSE":
				return (
					<MenuItem onClick={() => onSelectAddress(state)}>
						{state.Adresse}
					</MenuItem>
				)
			case "DIVIDER":
				return <Divider />
			case "LOADING":
				return (
					<MenuItem>
						<Flex gap="1rem">
							<CircularProgress size={20} />
							<div>Recherche en cours</div>
						</Flex>
					</MenuItem>
				)
			case "LOADING_POINTS":
				return (
					<MenuItem>
						<Flex gap="1rem">
							<CircularProgress size={20} />
							<div>Recherche en cours dans la base de donnée</div>
						</Flex>
					</MenuItem>
				)
			case "POINTS":
				return (
					<MenuItem onClick={() => onSelectPoint(state)}>
						<Flex gap="1rem">
							<img src={getImage(state.jsonSchema.imgId)} width={40} />
							<div>{state.geoloc.Adresse}</div>
						</Flex>
					</MenuItem>
				)
			default:
				return <MenuItem>ok</MenuItem>
		}
	}

	const communeLocation = () => {
		const location = L.geoJSON(communeGeojson).getBounds().getCenter()
		return onChange({ ...value, coo: { lat: location.lat, lng: location.lng } })
	}

	const handleToggleCancel = () => {
		onClose()
		onChange({})
	}

	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current
				if (marker != null) {
					onChange({
						...value,
						coo: { lat: marker.getLatLng().lat, lng: marker.getLatLng().lng },
					})
				}
			},
		}),
		[markerRef, value],
	)

	return (
		<Modal isOpen={isOpen} onClose={handleToggleCancel} size="5xl">
			<ModalContent>
				<ModalHeader>Géolocalisation</ModalHeader>
				<SModalBody>
					<StyledContainer>
						<SuperMap
							resize={false}
							hasSearchControl={false}
							disableMargin
							position="relative"
							onDoubleClick={onDoubleClick}
							displayOptions={{
								displayBottomControl: false,
								displayRightControl: false,
								displayMinimap: false,
							}}
						>
							<TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
							<SuperStyledControl>
								<SPaper>
									<Autocomplete
										fullWidth
										disableClearable
										blurOnSelect
										freeSolo
										PaperComponent={(props) => (
											<Paper {...props} elevation={20} />
										)}
										loading={isLoading}
										id="combo-box-demo"
										getOptionLabel={(option) => ""}
										filterOptions={(options, state) => options}
										options={[
											{ type: "MA_POSITION" },
											{ type: "DIVIDER" },
											...(isLoading
												? [{ type: "LOADING" }]
												: apiAddressResults.map((address) => ({
														...address,
														type: "ADRESSE",
												  }))),
										]}
										onChange={(e: any, newValue: any) => {
											onChange(newValue)
										}}
										onInputChange={(e: any, newInputValue: any) => {
											onChange({
												...value,
												Adresse: newInputValue,
											})
										}}
										inputValue={value?.Adresse ?? ""}
										ref={autoCompleteRef}
										renderOption={renderOption}
										renderInput={(params) => (
											<>
												<TextField
													{...params}
													value={value?.Adresse}
													placeholder="Saisir une adresse"
													minRows={3}
													variant="outlined"
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{params.InputProps.endAdornment}
																{value?.Adresse && (
																	<IconButton
																		ref={deleteRef}
																		onClick={() => {
																			onChange({})
																		}}
																	>
																		<CloseIcon />
																	</IconButton>
																)}
																{!value.coo && (
																	<CustomTooltip title="Ajouter un point">
																		<IconButton
																			onClick={() => {
																				MapService.fitGeoJSONBounds(
																					communeGeojson,
																				)
																				communeLocation()
																			}}
																		>
																			<AddLocationIcon />
																		</IconButton>
																	</CustomTooltip>
																)}
																{value.coo && !value.Adresse && (
																	<CustomTooltip title="Supprimer le point">
																		<IconButton
																			onClick={() => {
																				onChange({})
																			}}
																		>
																			<LocationOffIcon />
																		</IconButton>
																	</CustomTooltip>
																)}
															</>
														),
													}}
												/>
											</>
										)}
									/>
								</SPaper>
							</SuperStyledControl>
							{value?.coo?.lat && value?.coo?.lng && (
								<Marker
									draggable
									ref={markerRef}
									position={[value.coo?.lat, value.coo?.lng]}
									eventHandlers={eventHandlers}
								/>
							)}
						</SuperMap>
					</StyledContainer>
				</SModalBody>
				<ModalFooter>
					<CancelButton onClick={handleToggleCancel} />
					<PrimaryButton
						onClick={() => {
							onChangeFinalValue(value)
							onClose()
						}}
					>
						Enregistrer
					</PrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default FModalSelectGeoloc
