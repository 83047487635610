import useAllVehicules from "@/hooks/useAllVehicules"
import React from "react"
import VehiculeChip from "utils/components/chip/VehiculeChip"
import DisplayStockplaceItems from "./subComponents/DisplayStockplaceItems"

function DisplayVehicules({ point, editable, align }) {
	const vehicules = useAllVehicules()

	return (
		<DisplayStockplaceItems
			allItems={Object.values(vehicules)}
			stockPlace={point}
			align={align}
		>
			{({ item, highlight }) => (
				<VehiculeChip
					editable={editable}
					key={item.id}
					vehicule={item}
					highlight={highlight}
				/>
			)}
		</DisplayStockplaceItems>
	)
}

export default DisplayVehicules
