import React from "react"
import { useLocalStorage } from "react-use"
import NestedListParentBase, {
	NestedListParentBaseProps,
} from "./NestedListParentBase"

const NestedListParentPersistent = (props: NestedListParentBaseProps) => {
	const [open, setOpen] = useLocalStorage(props.item?.title, false)

	return <NestedListParentBase open={open} setOpen={setOpen} {...props} />
}

export default NestedListParentPersistent
