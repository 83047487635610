import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import { setPanel } from "@/redux-toolkit/common/reducer"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import SearchIcon from "@mui/icons-material/Search"
import TableChartIcon from "@mui/icons-material/TableChart"
import { List, ListItem, ListItemButton, TextField } from "@mui/material"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import Defer from "@/utils/Defer"
import usePointPropertyToText from "@/utils/components/jsonSchema/properties/usePointPropertyToText"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { Neutral500Text, Small } from "@/utils/components/style/text"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import getImage from "@/utils/getImage"
import { DAYBOOK_LEGEND, DAYBOOK_POINT } from "./PanelCarto"
import Button from "@/styles/atoms/Button"

// app:javascript:utils:panels:PanelDaybookList.tsx
const debug = Debug("app:javascript:utils:panels:PanelDaybookList")
debug.log = console.log.bind(console)

const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
`

const StyledFlex = styled(Flex)`
    justify-content: space-between !important;
    width: 100% !important;
    flex-grow: 0 !important;
`
const StyledListItem = styled(ListItem)`
    padding: 0px !important;
    width: 100% !important;
`

const StyledListItemButton = styled(ListItemButton)`
    display: flex;
    justify-content: space-between !important;
    border-bottom: 1px solid var(--neutral100) !important;
`
const SFlex = styled(Flex)`
    padding: 1rem;
    width: 100% !important;
`
const StyledList = styled(List)`
    padding: 0px !important;
    width: 100% !important;
    max-height: calc(100vh - 11rem);
    overflow: auto;
    & ${StyledListItem}:first-child {
        ${StyledListItemButton} {
            border-top: 1px solid var(--neutral100) !important;
        }
    }
`
const SSearchIcon = styled(SearchIcon)`
    margin-right: 0.5rem;
`
const PanelDaybookList = () => {
	const dispatch = useDispatch()
	const currentPannel = useSelector((state: any) => state.common.panel)
	const jsonSchemas = useJsonSchemasWithStatics()
	const [search, setSearch] = useState("")
	const jsonSchema = jsonSchemas[currentPannel?.jsonschema_id]
	const history = useHistory()
	const points = usePoints()
	const multiPolygons = useMultiPolygons()
	const pointPropertyToText = usePointPropertyToText()

	const concernedPoints = useMemo(() => {
		return Object.values(points)
			.filter((point: any) => {
				return point.jsonschema_id === currentPannel?.jsonschema_id
			})
			.sort((a: any, b: any) => {
				const aIdProperties = []
				const bIdProperties = []
				jsonSchema.template.properties.forEach((property: any) => {
					if (property.isIdentifiant) {
						aIdProperties.push(a.geojson.properties[property.name])
						bIdProperties.push(b.geojson.properties[property.name])
					}
				})
				return aIdProperties.join("") > bIdProperties.join("") ? 1 : -1
			})
	}, [points, currentPannel?.jsonschema_id])

	const searchedPoints = useMemo(() => {
		return concernedPoints.filter((point: any) => {
			const idPropertiesInternal = []
			jsonSchema.template.properties.forEach((property: any) => {
				if (property.isIdentifiant) {
					idPropertiesInternal.push(point.geojson.properties[property.name])
				}
			})
			return formatNfdLowerCase(idPropertiesInternal.join("")).includes(
				formatNfdLowerCase(search),
			)
		})
	}, [search, concernedPoints])

	const idProperties = []
	let firstColumn
	jsonSchema.template.properties.forEach((property: any) => {
		if (property.isIdentifiant) {
			idProperties.push(property)
		}
		if (property.isColumn && !firstColumn) {
			firstColumn = property
		}
	})
	if (idProperties.length === 0) {
		idProperties.push(firstColumn)
	}
	return (
		<Container>
			<SFlex directionColumn gap="1rem">
				<StyledFlex>
					<Button
						color="primary"
						variant="light"
						onClick={() => dispatch(setPanel({ type: DAYBOOK_LEGEND }))}
						startContent={<ArrowBackIcon />}
					>
						Retour à la légende
					</Button>
					<Button
						color="primary"
						variant="bordered"
						startContent={<TableChartIcon />}
						onClick={() => {
							dispatch(setPanel(undefined))
							history.push(`/customdata/my_forms/${jsonSchema?.id}`)
						}}
					>
						Ouvrir le Tableau
					</Button>
				</StyledFlex>
				<Flex gap="1rem" fullWidth>
					<img src={getImage(jsonSchema?.imgId)} width={50} height={50} />
					<div>
						{jsonSchema?.title}
						<Neutral500Text>
							<Small>{concernedPoints.length} données</Small>
						</Neutral500Text>
					</div>
				</Flex>
				<TextField
					variant="outlined"
					fullWidth
					placeholder="Rechercher"
					onChange={(e) => setSearch(e.target.value)}
					value={search}
					InputProps={{
						startAdornment: <SSearchIcon />,
					}}
					helperText={
						_.isEmpty(search)
							? null
							: searchedPoints.length === 0
							? "Aucun résultat"
							: searchedPoints.length + " résultats"
					}
				/>
			</SFlex>
			<StyledList>
				<Defer>
					{searchedPoints.map((point: any) => {
						return (
							<StyledListItem key={point.id}>
								<StyledListItemButton
									onClick={() => {
										dispatch(
											setPanel({
												type: DAYBOOK_POINT,
												id: point.id,
											}),
										)
										const geolocProperty =
											JsonSchemaService.getGeolocProperty(jsonSchema)
										const coordinates =
											point.geojson?.properties?.[geolocProperty.name]?.coo
										if (_.isEmpty(coordinates)) {
											return
										}
										MapService.getMap().flyTo(coordinates, 18)
									}}
								>
									<Flex gap="0.5rem">
										{idProperties.map((idProperty: any) => {
											let pointProperties =
												point?.geojson?.properties?.[idProperty.name]
											if (!pointProperties) {
												return "(sans identifiant)"
											}
											if (!_.isString(pointProperties)) {
												if (pointProperties.secteur) {
													pointProperties =
														multiPolygons[pointProperties.secteur]?.name ?? ""
												}
												if (idProperty.name === "Responsables") {
													//* Even if's identifiant, we won't display managers here for sector points
													pointProperties = ""
												}
											}
											return (
												<div key={idProperty.name}>
													{pointPropertyToText(
														point,
														jsonSchema.template.properties.find(
															(property) => property.name === idProperty.name,
														),
													)}
												</div>
											)
										})}
									</Flex>
									<ArrowForwardIosIcon fontSize="small" />
								</StyledListItemButton>
							</StyledListItem>
						)
					})}
				</Defer>
			</StyledList>
		</Container>
	)
}

export default PanelDaybookList
