import { createAsyncThunk } from "@reduxjs/toolkit"
import ErrorService from "@/services/ErrorService"
import Axios from "axios"
import { updateEvent } from "../data/events/resources"
import {
	createMultiPolygon,
	deleteMultiPolygon,
} from "../data/multiPolygon/resources"
import DatabaseService from "@/services/DatabaseService"
import { addCoucheMultiPolygons, resetDaybookReducer } from "./reducer"
import { v4 as uuidv4 } from "uuid"
export const createDrawMultiPolygon = createAsyncThunk(
	"daybook/createDrawMultiPolygon",
	async (multiPolygon: any, { dispatch, getState }) => {
		const currentState = getState() as any
		try {
			await DatabaseService.set(
				multiPolygon.id,
				JSON.stringify(multiPolygon.geojson),
			)
			dispatch(
				createMultiPolygon({
					id: multiPolygon.id,
					name: multiPolygon.name,
					commune_ids: [currentState.commune.id],
					custom_props: multiPolygon?.custom_props,
					event_id: multiPolygon?.event_id,
				}),
			)

			dispatch(addCoucheMultiPolygons({ id: multiPolygon.id }))

			const currentEvent = currentState.data.events[multiPolygon.event_id]
			dispatch(
				updateEvent({
					...currentEvent,
					multiPolygonsIds: [...currentEvent.multiPolygonsIds, multiPolygon.id],
				}),
			)
		} catch (error) {
			const errorMessage = `Erreur lors de la création de la couche. Message d'erreur ${error.message}`
			ErrorService.error({
				component: "ressources:createDrawMultiPolygon",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateDrawMultiPolygon = createAsyncThunk(
	"daybook/updateDrawMultiPolygon",
	async (multiPolygon: any, { dispatch, getState }) => {
		try {
			const geojson = await DatabaseService.get(multiPolygon.id)
			await DatabaseService.set(multiPolygon.id, undefined)
			dispatch(deleteMultiPolygon(multiPolygon))

			dispatch(
				createDrawMultiPolygon({
					...multiPolygon,
					geojson: JSON.parse(geojson),
					id: uuidv4(),
				}),
			)
		} catch (error) {
			const errorMessage = `Erreur lors de la suppression de la couche. Message d'erreur ${error.message}`
			ErrorService.error({
				component: "ressources:updateDrawMultiPolygon",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

//* Kept remoteExtract even if isn't used to maybe extract care as pdf later
export const remoteExtract = createAsyncThunk(
	"daybook/remoteExtract",
	async (dataObject: any, { dispatch }) => {
		try {
			const res = await Axios.post("/api/care_table", dataObject)
			window.open(`/api/care_table/${res.data.uuid}`)
		} catch (err) {
			const errorMessage = `L'extraction a échoué, message d'erreur : ${err}`
			ErrorService.error({
				component: "resources:remoteExtract",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const resetDaybookReducerAction = createAsyncThunk(
	"carto/resetCartoReducer",
	async (_, { dispatch, getState }) => {
		return dispatch(resetDaybookReducer())
	},
)
