import { combineReducers } from "@reduxjs/toolkit"
import combinedAdminReducer from "./admin/combinedReducer"
import combinedDataReducer from "./data/combinedReducer"
import cartoReducer from "./carto/reducer"
import commonReducer from "./common/reducer"
import communeReducer from "./commune/reducer"
import editReducer from "./edit/reducer"
import searchHistoryReducer from "./searchHistory/reducer"
import timeReducer from "./time/reducer"
import userSettingsReducer from "./userSettings/reducer"
import cdcReducer from "./cdc/reducer"
import daybookReducer from "./daybook/reducer"
import picrimReducer from "./picrim/reducer"

export default combineReducers({
	edit: editReducer,
	searchHistory: searchHistoryReducer,
	commune: communeReducer,
	userSettings: userSettingsReducer,
	common: commonReducer,
	carto: cartoReducer,
	time: timeReducer,
	admin: combinedAdminReducer,
	data: combinedDataReducer,
	cdc: cdcReducer,
	daybook: daybookReducer,
})
