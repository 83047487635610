import styled from "styled-components"

export const H1 = styled.h1`
    color: var(--primary800) !important;
    padding: 2vh 0px 3vh 0px;
`
/**
 * h2 with dark blue
 */
export const H2 = styled.div`
    font-size: 2rem;
    font-weight: 500;
`
export const H3 = styled.h3`
    color: var(--primary800) !important;
`
export const H4 = styled.h4`
    color: var(--primary800) !important;
    font-family: 'Roboto' !important;
`
export const H5 = styled.h5`
    color: var(--primary800) !important;
`
export const H6 = styled.h6`
    margin: 0 !important;
    color: var(--primary800) !important;
`
export const H2bis = styled(H2)`
    color: var(--primary800) !important;
    font-size: var(--font-size-huge-plus) !important;
`
