import { TextField } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import ReactInputMask from "react-input-mask"
import styled from "styled-components"
import { Neutral350Text, Small } from "utils/components/style/text"

const STextField = styled(TextField)`
    &&.MuiInputLabel-root {
        ffont-size: var(--font-size-huge);
    }
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`
const FPhoneNumber = ({
	name,
	label = undefined,
	multiline = false,
	details = undefined,
	placeholder = "",
	disabled = false,
	InputProps = {},
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const {
		field: { ref, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	return (
		<Container>
			{details && (
				<Neutral350Text>
					<Small>{details}</Small>
				</Neutral350Text>
			)}
			<ReactInputMask mask="99 99 99 99 99" disabled={disabled} {...inputProps}>
				{/**  @ts-ignore */}
				{(inputPropsMasked) => (
					<STextField
						minRows={3}
						inputRef={ref}
						placeholder={placeholder}
						error={!!error?.message}
						multiline={multiline}
						label={label ?? name}
						helperText={error?.message}
						{...inputPropsMasked}
						InputProps={InputProps}
					/>
				)}
			</ReactInputMask>
		</Container>
	)
}

export default FPhoneNumber
