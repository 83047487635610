import useRiskFiles from "@/hooks/useRiskFiles"
import useStaticFiles from "@/hooks/useStaticFiles"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import _ from "lodash"
import { useContext, useEffect } from "react"
import { TreeContext } from "../../redux/page/context"
import { setTree } from "../../redux/tree/action"
import DownloadService from "../../services/DownloadService"

const useUploadLocation = () => {
	const uploadedFiles = useUploadedFiles()
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)

	useEffect(() => {
		const getTree = async () => {
			const result = await DownloadService.downloadTree()
			treeDispatch(setTree(result.data.tree))
		}
		if (treeDispatch) getTree()
	}, [uploadedFiles])

	const riskFiles = useRiskFiles(false)
	const staticFiles = useStaticFiles({ withUploaded: true })

	function getPathInTreeFromKey(pcsTree, key, path = []) {
		for (const pcsKey in pcsTree) {
			if (pcsKey === key || key === pcsTree[pcsKey].id) {
				path.push(pcsKey)
				return path
			} else {
				if (pcsTree[pcsKey].children) {
					const pathMaybe = [...path, pcsKey, "children"]
					const subPath = getPathInTreeFromKey(
						pcsTree[pcsKey].children,
						key,
						pathMaybe,
					)
					if (subPath.length > 0) return subPath
				}
			}
		}
		return [] // Return an empty array if the key is not found in the tree
	}

	const getUploadLocation = (config) => {
		if (!config) return
		if (config.pcs?.after) {
			const key = config.pcs?.after
			if (!Object.keys(treeState).length) return "..."
			const pathNames = []
			const path = getPathInTreeFromKey(treeState, key)
			const keys = path.slice(0, path.length)
			keys.forEach((key, index) => {
				if (key === "children") return
				pathNames.push(
					// @ts-ignore
					_.get(treeState, keys.slice(0, index + 1))?.title,
				)
			})
			if (pathNames.includes(undefined)) return "En annexe"
			return `Après : ${pathNames.join(" / ")}`
		}
		if (config.pcs?.replace) {
			if (config.category === "PROCEDURE") {
				if (!staticFiles[config.pcs.replace]) {
					debugger
				}
				return `Remplace : ${staticFiles[config.pcs.replace]?.title}`
			}
			if (config.category === "PEDAGOGIQUE")
				return `Remplace : ${riskFiles[config.pcs.replace].risk}`
			return
		}
		if (config.pcs?.appendix) return "En annexe"
	}

	return {
		getUploadLocation,
		refresh: treeState,
	}
}

export default useUploadLocation
