import AddBusinessIcon from "@mui/icons-material/AddBusiness"
import { IconButton, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import useStockPlacesAndCare from "@/hooks/useStockPlacesAndCare"
import React, { useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useBoolean } from "react-use"
import { GreenChip, OrangeChip } from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"
import { StaticFormType } from "utils/services/StaticFormsService"
import FModalStockPlaceAndCare from "./modal/FModalStockPlaceAndCare"

const FStockPlaceAndCare = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
}) => {
	const [isOpenModal, toggleModal] = useBoolean(false)

	const stockPlacesAndCare = useStockPlacesAndCare()

	const { control } = useFormContext()

	const {
		field: { ref, onChange, value },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	const options = Object.values(stockPlacesAndCare).map((sp) => sp.id)

	const correctedValue = value ? (options.includes(value) ? value : null) : null

	const [acMenuOpen, setAcMenuOpen] = useState(false)
	return (
		<>
			<Autocomplete
				open={acMenuOpen}
				onOpen={() => setAcMenuOpen(readOnly ? false : true)}
				onClose={() => setAcMenuOpen(false)}
				disableClearable
				fullWidth
				value={correctedValue}
				noOptionsText={"Aucune donnée correspondante"}
				options={options}
				onChange={(e, newValue: string) => {
					onChange(newValue)
				}}
				renderOption={(props, option) => {
					const stockPlaceOrCare = stockPlacesAndCare[option]
					const ColoredChip =
						stockPlaceOrCare.jsonschema_id === StaticFormType.STOCK_PLACE
							? GreenChip
							: OrangeChip
					return (
						<li {...props} key={option}>
							<Flex gap="1rem">
								<ColoredChip label={stockPlaceOrCare.jsonschema_id} />
								{stockPlaceOrCare.geojson.properties.Lieu}
							</Flex>
						</li>
					)
				}}
				getOptionLabel={(option) =>
					stockPlacesAndCare[option].geojson.properties.Lieu
				}
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						inputRef={ref}
						error={Boolean(error)}
						helperText={error?.message}
						label={label}
						InputProps={{
							...params.InputProps,
							readOnly,
							endAdornment: readOnly ? undefined : (
								<>
									{params.InputProps.endAdornment}
									<IconButton
										onClick={() => {
											toggleModal()
										}}
										disabled={disabled}
									>
										<AddBusinessIcon />
									</IconButton>
								</>
							),
						}}
					/>
				)}
			/>
			<FModalStockPlaceAndCare
				onChange={onChange}
				value={correctedValue}
				isOpen={isOpenModal}
				onClose={toggleModal}
			/>
		</>
	)
}

export default FStockPlaceAndCare
