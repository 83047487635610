import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import SynchroService from "@/services/SynchroService"
import { useSelector } from "react-redux"
import IMultiPolygon from "utils/types/IMultiPolygon"

const selectMultiPolygons = createDeepEqualSelector(
	(state: any) => state.data.multiPolygons,
	(multiPolygons) => SynchroService.notDeleted(multiPolygons),
)

const useMultiPolygons: () => { [key: string]: IMultiPolygon } = () => {
	return useSelector(selectMultiPolygons) ?? []
}

export default useMultiPolygons
