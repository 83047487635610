import { LEAVING_FAMILY } from "@/pages/maincourante/care/leaving/ModalDaybookLeavingGroup"
import { CARE_FAMILY } from "@/redux-toolkit/userSettings/constants"
import { useMemo } from "react"
import { specificityOptions } from "utils/form/specific/care/FamilyMemberCard"
import { IFamilyMemberType } from "utils/types/IFamilyMember"
import IPoint from "utils/types/IPoint"
import usePoints from "../usePoints"
import useSelectedEvent from "../useSelectedEvent"

const useCareMembers = ({ care }: { care: IPoint }) => {
	const points = usePoints()
	const selectedEvent = useSelectedEvent()

	const { adultMember, childMember, babyMember, animalMember, specificities } =
		useMemo(() => {
			let adultMember = 0
			let childMember = 0
			let babyMember = 0
			let animalMember = 0
			const concernedPoints = Object.values(points).filter(
				(point) =>
					point.event_id === selectedEvent?.id &&
					point.geojson.properties["careId"] === care.id &&
					point.jsonschema_id === CARE_FAMILY,
			)
			const leavingFamily = Object.values(points).filter(
				(point) =>
					point.event_id === selectedEvent?.id &&
					point.geojson.properties["careId"] === care.id &&
					point.jsonschema_id === LEAVING_FAMILY,
			)
			const leavingFamilyGroupObject = {}

			leavingFamily.forEach((point) => {
				const associatedPoints =
					point.geojson.properties?.["Points associés"] ?? []
				associatedPoints.forEach((pointAssoc) => {
					if (
						!leavingFamilyGroupObject[
							pointAssoc["Identifiant du point associé"]
						]
					) {
						leavingFamilyGroupObject[
							pointAssoc["Identifiant du point associé"]
						] = []
					}
					leavingFamilyGroupObject[pointAssoc["Identifiant du point associé"]] =
						[
							...leavingFamilyGroupObject[
								pointAssoc["Identifiant du point associé"]
							],
							pointAssoc["Index du membre associé"],
						]
				})
			})

			concernedPoints.forEach((point) => {
				if (!leavingFamilyGroupObject[point.id]?.includes(-1)) {
					adultMember++
				}
				const familyMembers = point?.geojson?.properties?.family ?? []
				familyMembers.forEach((familyMember, index) => {
					if (leavingFamilyGroupObject[point.id]?.includes(index)) {
						return
					}
					if (familyMember.type === IFamilyMemberType.ADULT) {
						familyMember.quantity
							? (adultMember += familyMember.quantity)
							: adultMember++
					} else if (familyMember.type === IFamilyMemberType.CHILD) {
						familyMember.quantity
							? (childMember += familyMember.quantity)
							: childMember++
					} else if (familyMember.type === IFamilyMemberType.BABY) {
						familyMember.quantity
							? (babyMember += familyMember.quantity)
							: babyMember++
					} else if (familyMember.type === IFamilyMemberType.ANIMAL) {
						familyMember.quantity
							? (animalMember += familyMember.quantity)
							: animalMember++
					}
				})
			})
			const specificitiesTmp = specificityOptions
				.map((option) => {
					let count = 0
					concernedPoints.forEach((point) => {
						if (!leavingFamilyGroupObject[point.id]?.includes(-1)) {
							if (
								(point.geojson.properties.Spécificités ?? []).includes(
									option.label,
								)
							) {
								count++
							}
						}
						const familyMember = point.geojson.properties.family ?? []
						familyMember.forEach((familyMember, index) => {
							if (leavingFamilyGroupObject[point.id]?.includes(index)) {
								return
							}
							if ((familyMember?.Spécificités ?? []).includes(option.label)) {
								count++
							}
						})
					})

					return {
						label: option.label,
						count,
					}
				})
				.filter((specificities) => specificities.count > 0)
			return {
				adultMember,
				childMember,
				babyMember,
				animalMember,
				specificities: specificitiesTmp,
			}
		}, [care, points])

	return { adultMember, childMember, babyMember, animalMember, specificities }
}

export default useCareMembers
