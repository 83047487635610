import React from "react"
import Button from "../Button"

const CreateButton = React.forwardRef<
	HTMLButtonElement,
	React.ComponentProps<typeof Button>
>((props, ref) => {
	return (
		<Button {...props} ref={ref} color="primary">
			{props.children ? props.children : "Créer"}
		</Button>
	)
})

export default CreateButton
