import { Chip, Tooltip } from "@mui/material"
import useIsInterco from "@/hooks/useIsInterco"
import Debug from "debug"
import _ from "lodash"
import React from "react"
import styled from "styled-components"
import colored from "utils/colored"
import { Flex } from "utils/components/style/flex"
import getImage from "@/utils/getImage"
import intercoNameAdapter from "utils/intercoNameAdapter"
import { DiffSpan, TextAlign } from "./PropertiesType.styled"

// app:javascript:utils:components:jsonSchema:properties:propertiesType:TypeSelectWithImage.tsx
const debug = Debug(
	"app:javascript:utils:components:jsonSchema:properties:propertiesType:TypeSelectWithImage",
)
debug.log = console.log.bind(console)
const SChip = styled(colored(Chip))``
const TypeSelectWithImage = ({
	value,
	align,
	diffWith,
	fontSize = undefined,
	property,
}) => {
	const isInterco = useIsInterco()
	if (_.isEmpty(value)) {
		return null
	}
	let usedValue = value
	if (!_.isArray(value)) {
		usedValue = [value]
	}

	return (
		<TextAlign align={align} $fontSize={fontSize}>
			<Flex gap="0.25rem">
				{diffWith !== undefined && diffWith !== usedValue ? (
					<>
						<DiffSpan>{diffWith ?? ""}</DiffSpan>{" "}
						<DiffSpan add>{JSON.stringify(usedValue) ?? ""}</DiffSpan>
					</>
				) : usedValue ? (
					<Flex gap="0.25rem" $wrap>
						{usedValue.map((v) => (
							<Tooltip
								key={v}
								title={intercoNameAdapter(v, isInterco)}
								placement="top"
							>
								<SChip
									$textColor="black"
									$backgroundColor="white"
									label={intercoNameAdapter(v, isInterco)}
									variant="outlined"
									style={{ maxWidth: "150px" }}
									avatar={
										<img
											src={getImage(
												property.itemsImage.find((item) => item.label === v)
													?.imgId,
											)}
											width={40}
										/>
									}
								/>
							</Tooltip>
						))}
					</Flex>
				) : (
					""
				)}
			</Flex>
		</TextAlign>
	)
}

export default TypeSelectWithImage
