import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import ICommune from "utils/types/ICommune"
import IUploadedFile from "utils/types/IUploadedFile"

const selectUploadedFiles = createDeepEqualSelector(
	(state: any) => state.data.uploadedFiles,
	(uploadedFiles: any) => uploadedFiles,
)

const useUploadedFiles: () => { [key: string]: IUploadedFile } = () => {
	return useSelector(selectUploadedFiles)
}

export default useUploadedFiles
