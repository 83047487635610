import { useSelector } from "react-redux"

const useHasVortexStation = () => {
	const commune = useSelector((state: any) => state.commune)

	if (commune.name === "Démo Nieul-sur-Mer" || commune.name === "Gardouch") {
		return true
	}
	return false
}

export default useHasVortexStation
