import useAllHumans from "@/hooks/useAllHumans"
import useAllStockPlaces from "@/hooks/useAllStockPlaces"
import { useCallback } from "react"
import HumanService from "utils/services/HumanService"
import {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import IPoint from "utils/types/IPoint"
import Moment from "moment"
function usePointPropertyToText() {
	const stockPlaces = useAllStockPlaces()
	const humans = useAllHumans()

	return useCallback(
		(point: IPoint, property: IJsonSchemaProperty): string => {
			const value = point?.geojson?.properties?.[property.name]
			switch (property.type) {
				case JsonSchemaPropertiesType.TIME:
				case JsonSchemaPropertiesType.TEXT:
				case JsonSchemaPropertiesType.CREATABLE_SELECT:
				case JsonSchemaPropertiesType.TEXTAREA:
				case JsonSchemaPropertiesType.SELECT:
				case JsonSchemaPropertiesType.RADIO:
				case JsonSchemaPropertiesType.NUMBER:
					return value ?? ""
				case JsonSchemaPropertiesType.GEOLOC:
					return value?.Adresse ?? ""
				case JsonSchemaPropertiesType.PHONE:
					if (!Array.isArray(value)) return ""
					return value
						.map((phone) => `${phone.Type} : ${phone.Numéro}`)
						.join(" / ")
				case JsonSchemaPropertiesType.CHECKBOX:
				case JsonSchemaPropertiesType.TAGS:
					if (!Array.isArray(value)) return ""
					return value.join(" / ")
				case JsonSchemaPropertiesType.STOCKPLACE:
					return stockPlaces[value]?.geojson?.properties?.Lieu ?? ""
				case JsonSchemaPropertiesType.RESPONSABLES:
					if (!Array.isArray(value)) return ""
					return value
						.map((value) => HumanService.displayFullName(humans[value]))
						.join(" / ")
				case JsonSchemaPropertiesType.DATE:
					const isValueValidDate = Moment(value).isValid()
					if (!isValueValidDate) return ""
					return Moment(value).format("DD/MM/YYYY HH:mm")
				case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
					if (property?.options?.isMulti) {
						if (!Array.isArray(value)) return ""
						return value.join(" / ")
					}
					return value ?? ""

				default:
					return typeof value === "string" ? value : JSON.stringify(value)
			}
		},
		[stockPlaces, humans],
	)
}

export default usePointPropertyToText
