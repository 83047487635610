/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close"
import HistoryIcon from "@mui/icons-material/History"
import RoomIcon from "@mui/icons-material/Room"
import {
	Chip,
	ClickAwayListener,
	Divider,
	IconButton,
	ListSubheader,
	MenuItem,
	Paper,
	TextField,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import WarningIcon from "@mui/icons-material/Warning"
import Axios from "axios"
import useAddressesSearchHistory from "@/hooks/useAddressesSearchHistory"
import useCommune from "@/hooks/useCommune"
import React, { useEffect, useRef, useState, useMemo } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useBoolean, useGeolocation } from "react-use"
import styled from "styled-components"
import ApiDataGouvAddress from "utils/api_externe/ApiDataGouvAddress"
import ApiGoogleAddress from "utils/api_externe/ApiGoogleAddress"
import { Flex } from "utils/components/style/flex"
import {
	BlueText,
	Neutral350Text,
	NormalBlue,
	Small,
} from "utils/components/style/text"
import getImage from "@/utils/getImage"
import IAddressGoogle from "utils/types/IAddressGoogle"
import IGeoloc from "utils/types/IGeoloc"
import { GeolocUser, StyledMenuItem } from "./GeolocUser"
import FModalSelectGeoloc from "./specific/geoloc/FModalSelectGeoloc"
import {
	polygon,
	multiPolygon,
	feature,
	featureCollection,
	point,
	booleanContains,
} from "@turf/turf"

import { useDispatch, useSelector } from "react-redux"
import DatabaseService from "@/services/DatabaseService"
import IMultiPolygon from "utils/types/IMultiPolygon"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { CustomChip, StyledChip } from "utils/components/style/chip"
import useAddressSearch from "@/hooks/api/useAddressSearch"
const HistoryMenuItem = styled(MenuItem)`
  display: flex;
  gap: 0.5rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

const CustomHelperText = styled.div`
  font-size: var(--font-size-small);
  color: gray;
  margin-left: 1rem;
`
const StyledCoo = styled.div`
  color: var(--neutral800);
  font-size: var(--font-size-small);
`
const StyledNoCoo = styled.div`
  & > * {
    color: var(--orange600);
    font-size: var(--font-size-small);
  }
  color: var(--orange600);
  font-size: var(--font-size-small);
`
const StyledWarningIcon = styled(WarningIcon)`
  font-size: var(--font-size-small) !important;
  margin-right: 0.5rem;
`
const SListSubheader = styled(ListSubheader)`
  line-height: 36px;
  top: -8px;
`
const StyledInfo = styled.div`
  font-size: var(--font-size-small);
  color: var(--primary600);
`
const ChipContainer = styled.div`
  width: 180px !important;
`
/* Defining the different types of options that can be displayed in the dropdown menu. */
enum OptionType {
	MA_POSITION = "MA_POSITION",
	GEOLOCALISER = "GEOLOCALISER",
	ADRESSE = "ADRESSE",
	DIVIDER = "DIVIDER",
	LOADING = "LOADING",
	LOADING_POINTS = "LOADING_POINTS",
	POINTS = "POINTS",
	HISTORY_ADDRESS = "HISTORY_ADDRESS",
}

const FGeoloc = ({
	name,
	label = undefined,
	multiline = false,
	details = undefined,
	placeholder = "",
	disabled = false,
	InputProps = {},
}) => {
	const userPosition = useGeolocation({ enableHighAccuracy: true })
	const autoCompleteRef = useRef(null)

	const refContainer = useRef(null)
	const deleteRef = useRef(null)
	const [isOpenModalGeoloc, toggleModalGeoloc] = useBoolean(false)
	const multiPolygons = useMultiPolygons()
	const { control } = useFormContext()
	const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)

	const {
		field: { onChange, value, ...inputProps },
		fieldState: { error, isDirty },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	// handle search of address with a debounce of 500ms
	const {
		isLoading,
		apiAddressResults,
		setApiAddressResults,
		lastSearch,
		setLastSearch,
	} = useAddressSearch({ value, condition: isDirty })

	const customContains = (feature, point) => {
		if (feature.type === "FeatureCollection") {
			return feature.features.some((f) => customContains(f, point))
		}
		if (feature.type === "Feature") {
			return customContains(feature.geometry, point)
		}
		if (feature.type === "MultiPolygon") {
			return feature.coordinates.some((polygonCoordinates) =>
				booleanContains(
					{
						type: "Polygon",
						coordinates: polygonCoordinates,
					},
					point,
				),
			)
		}
		return booleanContains(feature, point)
	}

	const onClose = async (optionalValue = undefined) => {
		const usedValue = optionalValue || value
		if (!usedValue?.coo?.lat && usedValue?.coo?.lng) {
			return null
		}
		let calculatedSecteur
		await Promise.all(
			Object.values(multiPolygons)
				.filter(
					(multiPolygon: IMultiPolygon) => multiPolygon.custom_props.secteur,
				)
				.map(async (poly: IMultiPolygon) => {
					if (!calculatedSecteur) {
						let couche = await DatabaseService.get(poly.id)
						couche = JSON.parse(couche)
						if (Array.isArray(couche)) {
							couche = {
								type: "FeatureCollection",
								features: couche,
							}
						}
						let turfCouche
						if (!usedValue?.coo?.lat || !usedValue?.coo?.lng) {
							return
						}
						const turfPoint = point([usedValue?.coo?.lng, usedValue?.coo?.lat])
						let contained = false
						if (couche["type"] === "Polygon") {
							turfCouche = polygon(couche["coordinates"])
							contained = booleanContains(turfCouche, turfPoint)
						}
						if (couche["type"] === "MultiPolygon") {
							turfCouche = multiPolygon(couche["coordinates"])
							contained = booleanContains(turfCouche, turfPoint)
						}
						if (couche["type"] === "Feature") {
							turfCouche = feature(couche)
							contained = booleanContains(turfCouche, turfPoint)
						}
						if (couche["type"] === "FeatureCollection") {
							turfCouche = featureCollection(couche)
							contained = customContains(turfCouche.features, turfPoint)
						}
						if (!turfCouche) {
							return
						}
						if (contained) {
							calculatedSecteur = poly.id
						}
					}
				}),
		)

		if (calculatedSecteur) {
			onChange({ ...usedValue, secteur: calculatedSecteur })
		} else {
			onChange({ ...usedValue, secteur: "Hors secteur" })
		}
	}

	const { addressesSearchHistory, pushAddressToSearchHistory } =
		useAddressesSearchHistory()

	const onChangeWithHistory = (value) => {
		onChange(value)
		pushAddressToSearchHistory(value)
		setTimeout(() => deleteRef.current?.focus?.(), 100)
	}

	const onSelectGeoloc = (geoloc: IGeoloc) => {
		setLastSearch(geoloc?.Adresse)
		onChangeWithHistory(geoloc)
	}

	const onSelectPoint = (point) => {
		const newAdress = point.geoloc
		onChangeWithHistory(newAdress)
	}

	const onSelectHistoryAddress = (value) => {
		setLastSearch(value?.Adresse)
		onChangeWithHistory(value)
		onClose()
	}

	const selectUserLocation = async () => {
		let res
		try {
			const headers = {
				"Content-Type": "application/json",
			}
			res = await Axios.get(
				`https://api-adresse.data.gouv.fr/reverse/?lon=${userPosition.longitude}&lat=${userPosition.latitude}`,
				{ headers },
			)
		} catch (error) {
			console.log(error)
			console.log(error)
		}
		const resultAddress =
			res?.data?.features?.[0]?.properties?.label ??
			"Pas d'adresse correspondante"
		const newAdress = {
			Adresse: resultAddress,
			coo: {
				lat: userPosition.latitude,
				lng: userPosition.longitude,
			},
		} as IGeoloc
		setLastSearch(newAdress.Adresse)
		onChangeWithHistory(newAdress)
		onClose(newAdress)
	}

	const displayCoo = () => {
		if (value?.coo?.lat && value?.coo?.lng) {
			return `lat : ${value?.coo?.lat} / lng : ${value?.coo?.lng}`
		}
	}

	const renderAdressLatLng = (geoloc) => {
		return (
			<div>
				{geoloc?.coo?.lat && geoloc?.coo?.lng ? (
					<>
						<StyledCoo>
							lat : {geoloc?.coo?.lat} / lng : {geoloc?.coo?.lng}
						</StyledCoo>
					</>
				) : (
					<>
						<StyledNoCoo>
							<Flex>
								<StyledWarningIcon />
								<StyledNoCoo>Pas de lat / lng</StyledNoCoo>
							</Flex>
						</StyledNoCoo>
					</>
				)}
			</div>
		)
	}

	const renderOption = (option, state) => {
		const noAdress =
			state.value?.Adresse === undefined ||
			state.value?.Adresse !== "Pas d'adresse correspondante"
		switch (state.type) {
			case OptionType.MA_POSITION:
				return (
					<GeolocUser
						userPosition={userPosition}
						onClickAction={() => {
							setAutoCompleteOpen(false)
							selectUserLocation()
						}}
					/>
				)
			case OptionType.GEOLOCALISER:
				return (
					<StyledMenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							toggleModalGeoloc()
						}}
					>
						<RoomIcon />
						Géolocaliser sur la carte
					</StyledMenuItem>
				)
			case OptionType.ADRESSE:
				const isGeolocalised = state?.group === "Adresse géolocalisée"
				return (
					<MenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							onSelectGeoloc(state)
							onClose(state)
						}}
					>
						<Flex gap="0.5rem">
							<ChipContainer>
								{isGeolocalised && (
									<CustomChip
										label={state?.group}
										$backgroundColor="var(--primary500)"
										size="small"
									/>
								)}
								{!isGeolocalised && (
									<StyledChip
										$colorProperty="var(--primary500)"
										label={state?.group}
										variant="outlined"
										size="small"
									/>
								)}
							</ChipContainer>
							<Flex directionColumn alignItemsStart>
								<div>{state?.Adresse}</div>

								{renderAdressLatLng(state)}
							</Flex>
						</Flex>
					</MenuItem>
				)
			case OptionType.DIVIDER:
				return <Divider />
			case OptionType.LOADING:
				return (
					<MenuItem>
						<Flex gap="1rem">
							<CircularProgress size={20} />
							<div>Recherche en cours</div>
						</Flex>
					</MenuItem>
				)
			case OptionType.LOADING_POINTS:
				return (
					<MenuItem>
						<Flex gap="1rem">
							<CircularProgress size={20} />
							<div>Recherche en cours dans la base de donnée</div>
						</Flex>
					</MenuItem>
				)
			case OptionType.POINTS:
				return (
					<MenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							onSelectPoint(state)
							onClose(state)
						}}
					>
						<Flex gap="1rem">
							<img src={getImage(state.jsonSchema.imgId)} width={40} />
							<div>{state.geoloc.Adresse}</div>
						</Flex>
					</MenuItem>
				)
			case OptionType.HISTORY_ADDRESS:
				return (
					<HistoryMenuItem
						onClick={() => {
							setAutoCompleteOpen(false)
							onSelectHistoryAddress(state.value)
							onClose(state.value)
						}}
					>
						<HistoryIcon />
						{noAdress
							? state.value.Adresse
							: `latitude : ${state.value?.coo?.lat} / longitude : ${state.value?.coo?.lng}`}
					</HistoryMenuItem>
				)
			default:
				return <MenuItem>ok</MenuItem>
				break
		}
	}

	const buildedOptions = useMemo(() => {
		if (disabled) {
			return { options: [] }
		}
		if (isLoading) {
			return { options: [{ type: OptionType.LOADING }] }
		}
		if (apiAddressResults.length > 0) {
			return {
				options: [
					...apiAddressResults.map((address) => ({
						...address,
						type: OptionType.ADRESSE,
						group: "Adresse géolocalisée",
					})),
					{
						type: OptionType.ADRESSE,
						group: "Adresse non géolocalisée",
						Adresse: value?.Adresse,
					},
				],
				groupBy: (option) => option.group,
			}
		}
		return {
			options: [
				{ type: OptionType.MA_POSITION },
				{ type: OptionType.GEOLOCALISER },
				{ type: OptionType.DIVIDER },
				...addressesSearchHistory.map((value) => ({
					value,
					type: OptionType.HISTORY_ADDRESS,
				})),
			],
		}
	}, [isLoading, apiAddressResults, addressesSearchHistory, value])
	const isGeolocalisedWithoutAdress =
		value?.coo?.lat && value?.coo?.lng && !value?.Adresse
	return (
		<>
			<Container ref={refContainer}>
				{details && (
					<Neutral350Text>
						<Small>{details}</Small>
					</Neutral350Text>
				)}
				<Autocomplete
					open={autoCompleteOpen}
					onClick={() => setAutoCompleteOpen(true)}
					onClose={() => onClose()}
					disableClearable
					blurOnSelect={true}
					freeSolo
					PaperComponent={(props) => <Paper {...props} elevation={20} />}
					loading={isLoading}
					id="combo-box-demo"
					// @ts-ignore
					groupBy={buildedOptions?.groupBy}
					getOptionLabel={(option) => ""}
					filterOptions={(options, state) => options}
					options={buildedOptions.options}
					onChange={(e: any, newValue: any) => {
						onChange(newValue)
					}}
					onInputChange={(e: any, newInputValue: any) => {
						if (e) {
							if (newInputValue === "") {
								onChange(null)
							} else {
								if (!autoCompleteOpen) {
									setAutoCompleteOpen(true)
								}
								onChange({
									...value,
									Adresse: newInputValue,
								})
							}
						}
					}}
					inputValue={value?.Adresse ?? ""}
					value={value}
					{...inputProps}
					ref={autoCompleteRef}
					renderOption={renderOption}
					disabled={disabled}
					renderGroup={(params) => <>{params.children}</>}
					renderInput={(params) => (
						<>
							<TextField
								{...params}
								value={value?.Adresse}
								minRows={3}
								onClick={() => setAutoCompleteOpen(true)}
								variant="outlined"
								onBlur={() => {
									setAutoCompleteOpen(false)
								}}
								error={!!error}
								multiline={multiline}
								label={
									isGeolocalisedWithoutAdress
										? "Point géolocalisé"
										: label ?? name
								}
								InputProps={{
									...params.InputProps,
									...InputProps,
									endAdornment: (
										<>
											{params.InputProps.endAdornment}
											{value?.Adresse && !disabled && (
												<IconButton
													ref={deleteRef}
													onClick={() => {
														onChange({})
													}}
												>
													<CloseIcon />
												</IconButton>
											)}
											{disabled ? null : isLoading ? (
												<CircularProgress color="secondary" size={20} />
											) : (
												<IconButton
													onClick={() => {
														setAutoCompleteOpen(false)
														toggleModalGeoloc()
													}}
												>
													<RoomIcon />
												</IconButton>
											)}
										</>
									),
								}}
							/>
						</>
					)}
				/>
				<Flex gap="0.25rem">
					{value?.secteur && (
						<CustomChip
							size="small"
							$backgroundColor="var(--primary500)"
							label={multiPolygons[value?.secteur]?.name ?? "Hors secteur"}
						/>
					)}
					{renderAdressLatLng(value)}
				</Flex>
				<CustomHelperText>{error?.message}</CustomHelperText>
			</Container>
			{isOpenModalGeoloc && (
				<FModalSelectGeoloc
					isOpen={isOpenModalGeoloc}
					onClose={toggleModalGeoloc}
					onChangeFinalValue={(value) => {
						onChangeWithHistory(value)
						onClose(value)
					}}
					finalValue={value}
				/>
			)}
		</>
	)
}

export default FGeoloc
