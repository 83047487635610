import { Avatar, Chip } from "@mui/material"
import React from "react"
import colored from "utils/colored"
import { IOrgaCellule, ORGA_COLOR } from "utils/types/IOrga"
import styled from "styled-components"

const CChip = styled(colored(Chip))`
    max-width: 100%;
`
const CAvatar = colored(Avatar)

function CelluleChip({
	cellule,
	small = true,
	...props
}: {
	cellule: IOrgaCellule
	small?: boolean
	props?: any[]
	disabled?: boolean
}) {
	if (!cellule) return null
	return (
		<CChip
			$textColor="black"
			$backgroundColor="white"
			label={cellule?.idName ?? "La cellule n'existe plus"}
			size={small ? "small" : "medium"}
			variant="outlined"
			avatar={
				<CAvatar
					$textColor="white"
					$backgroundColor={ORGA_COLOR[cellule?.category] ?? "grey"}
				/>
			}
			{...props}
		/>
	)
}

export default CelluleChip
