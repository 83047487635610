import ExpandLess from "@mui/icons-material/ExpandLess"
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material"
import React from "react"
import { useBoolean } from "react-use"
import styled, { css } from "styled-components"
import { Flex } from "../style/flex"
import getImage from "@/utils/getImage"

const SList = styled(List)`
    width: 100%;
    & ul {
        position: relative;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & .MuiListItemButton-root {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        &:hover {
            background-color: var(--neutral200);
        }
    }
`

const SListItemButton = styled(ListItemButton)`
    font-weight: 450;
    position: sticky !important;
    top: 0;
    z-index: 50;
    background-color: white !important;
    border-radius: 0.5rem;
    box-shadow: var(--cool-shadow) !important;
    &:hover {
        background-color: var(--neutral100) !important;
    }
`
const IconContainer = styled.div<{ $isOpen: boolean }>`
    transition: transform 0.3s ease-in-out;
    width: 24px;
    height: 24px;
    ${({ $isOpen }) =>
        $isOpen &&
        css`
            transform: rotate(180deg);
        `}
`
const ChildrenWrapper = styled.div`
    & ul {
        position: relative;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & .MuiListItemButton-root {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        &:hover {
            background-color: var(--neutral200);
        }
    }
`
const CircleGrey = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: var(--neutral700);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.825rem;
`
const SListItemText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-weight: 450;
    }
`
const CollapsibleList = ({
    Wrapper,
    buttonText,
    children,
    initialOpen = true,
    onCollapse = () => null,
    numberElements,
    avatar = undefined,
}: {
    Wrapper?: React.FC
    buttonText: string
    children: JSX.Element
    initialOpen?: boolean
    onCollapse?: () => void
    numberElements?: number
    avatar?: JSX.Element
}) => {
    const [isOpen, toggle] = useBoolean(initialOpen)

    const CustomWrapper = Wrapper || SList
    return (
        <CustomWrapper>
            <SListItemButton
                onClick={() => {
                    toggle()
                    onCollapse()
                }}
            >
                <Flex spaceBetween fullWidth>
                    <Flex gap="1rem">
                        {avatar}
                        <SListItemText primary={buttonText} />
                    </Flex>
                    <Flex gap="0.5rem">
                        {numberElements && <CircleGrey>{numberElements}</CircleGrey>}
                        <IconContainer $isOpen={isOpen}>
                            <ExpandLess />
                        </IconContainer>
                    </Flex>
                </Flex>
            </SListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <ChildrenWrapper>{children}</ChildrenWrapper>
            </Collapse>
        </CustomWrapper>
    )
}

export default CollapsibleList
