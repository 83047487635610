import React from "react"
import styled from "styled-components"
import { TypeComponentProps } from "../PointPropertyValue"
import { DiffSpan } from "./PropertiesType.styled"

const TextAlign = styled.div<{ align: "right" | "left" }>`
    text-align: ${({ align }) => align};
`

export const safeArray = <T,>(value): T[] => {
	if (!value) return []
	if (!Array.isArray(value)) return []
	return value
}

function TypeCheckbox({ value, align, diffWith }: TypeComponentProps) {
	const safeValue = safeArray<string>(value)

	return (
		<TextAlign align={align}>
			{diffWith &&
				diffWith
					.filter((item) => !safeValue.includes(item))
					.map((item) => <DiffSpan key={item}>{item}</DiffSpan>)}
			{safeValue.map((item) => (
				<div key={item}>
					{diffWith ? (
						diffWith.includes(item) ? (
							item
						) : (
							<DiffSpan add>{item}</DiffSpan>
						)
					) : (
						item
					)}
				</div>
			))}
		</TextAlign>
	)
}

export default TypeCheckbox
