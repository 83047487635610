import { useSelector } from "react-redux"
import SynchroService from "@/services/SynchroService"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import {
	ANNUAIRES_ELUS,
	ANNUAIRES_PERSONNEL_COMMUNAL,
	RESERVE_COMMUNAL_SECURITE_CIVILE,
} from "utils/services/StaticFormsService"
import IPoint from "utils/types/IPoint"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(state: any) => state.userSettings.commune_id,
	(points, commune_id) =>
		SynchroService.notDeletedWithCurrentCommune(points, commune_id),
)

const useHumans: () => { [key: string]: IPoint } = () => {
	const points = useSelector(selectPoints) as IPoint[]
	const humans = {}
	Object.values(points)
		.filter(
			(point) =>
				point["jsonschema_id"] === ANNUAIRES_ELUS.id ||
				point["jsonschema_id"] === ANNUAIRES_PERSONNEL_COMMUNAL.id ||
				point["jsonschema_id"] === RESERVE_COMMUNAL_SECURITE_CIVILE.id,
		)
		.forEach((point) => {
			humans[point.id] = point
		})
	return humans
}

export default useHumans
