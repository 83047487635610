import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, List, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import NestedList, { LIVRET_7 } from "../DownloadPCS/NestedList"
import { TreeContext } from "../redux/page/context"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import intercoNameAdapter from "utils/intercoNameAdapter"
import useIsInterco from "@/hooks/useIsInterco"
import _ from "lodash"
const SFlex = styled(Flex)`
  width: 100%;
`

const SList = styled(List)`
  width: 100%;
`

const STextField = styled(TextField)`
  width: 400px;
`

const TreeSelection = ({
	handleCheck,
	updateSelectedKit = undefined,
	stopEditing = undefined,
	disableRootSelection = false,
	disableChildrens = [],
	enableCoop = true,
	isChecked = undefined,
	onlyOne = false,
	setKeyOrder = undefined,
}) => {
	const { state: treeState } = useContext(TreeContext)
	const [validTreeStateKeys, setValidTreeStateKeys] = useState(undefined)
	const [searchValue, setSearchValue] = useState("")
	const isInterco = useIsInterco()

	useEffect(() => {
		if (_.isEmpty(treeState)) return
		const correctedTreeKeys = Object.values(treeState)
			.filter((treeElement: any) => {
				const { key, subtitle } = treeElement
				return enableCoop
					? true
					: !(LIVRET_7 === key && subtitle === "Coopération")
			})
			.map((treeElement: any) => treeElement.key)
		setValidTreeStateKeys(correctedTreeKeys)
	}, [treeState])

	const regroupAllCategory = []
	const getAllCategory = (element = {}, key) => {
		Object.values(element).forEach((subelement: any) => {
			regroupAllCategory.push({
				title: subelement.title,
				path: [...key, subelement.key],
				checked: subelement.checked,
				children: subelement.children,
				subtitle: subelement.subtitle,
				code: subelement.code,
			})
			getAllCategory(subelement.children ?? {}, [
				...key,
				subelement.key,
				"children",
			])
		})
	}

	getAllCategory(treeState, [])

	const tempDisplayKeyWords = regroupAllCategory.filter((category) => {
		const words = category.code
			? category.code + " " + category.title
			: category.title

		return formatNfdLowerCase(words).includes(formatNfdLowerCase(searchValue))
	})

	const onChangeSearchValue = (event) => {
		setSearchValue(event.target.value)
	}

	return (
		<SFlex directionColumn alignItemsInitial>
			<Flex gap="2rem">
				<STextField
					id="searchbar"
					placeholder={intercoNameAdapter(
						"Rechercher un élément du PCS",
						isInterco,
					)}
					value={searchValue}
					onChange={onChangeSearchValue}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<>
								{searchValue && (
									<InputAdornment position="start">
										<IconButton onClick={() => setSearchValue("")}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								)}
							</>
						),
					}}
				/>
			</Flex>
			<SFlex alignItemsInitial>
				{searchValue && (
					<>
						<SList>
							{tempDisplayKeyWords.map((category) => {
								const data = {
									title: category.title,
									children: category.children,
									subtitle: category.subtitle,
									code: category.code,
								}

								return (
									<NestedList
										disableRootSelection={disableRootSelection}
										disableIf={
											!!data?.subtitle ||
											disableChildrens.includes(
												category.path[category.path.length - 1],
											)
										}
										handleCheck={handleCheck}
										key={category.key}
										idKeys={category.path}
										data={{
											title: category.title,
											children: category.children,
											subtitle: category.subtitle,
											code: category.code,
										}}
										checked={
											category.checked ||
											(isChecked?.(category.path[category.path.length - 1]) ??
												false)
										}
										isChecked={isChecked}
										onlyOne={onlyOne}
										updateSelectedKit={updateSelectedKit}
										stopEditing={stopEditing}
									/>
								)
							})}
						</SList>
					</>
				)}

				{!searchValue && treeState && validTreeStateKeys && (
					<>
						<SList aria-label="main mailbox folders">
							{validTreeStateKeys.map((key) => {
								const data = {
									title: treeState[key].code
										? treeState[key].code + " " + treeState[key].title
										: treeState[key].title,
									subtitle: treeState[key].subtitle,
									children: treeState[key]?.children,
									code: treeState[key].code,
								}

								return (
									<NestedList
										disableRootSelection={disableRootSelection}
										disableChildrens={disableChildrens}
										disableIf={!!data?.subtitle}
										handleCheck={handleCheck}
										key={key}
										idKeys={[key]}
										data={data}
										checked={
											treeState[key].checked || (isChecked?.(key) ?? false)
										}
										isChecked={isChecked}
										onlyOne={onlyOne}
										updateSelectedKit={updateSelectedKit}
										stopEditing={stopEditing}
									/>
								)
							})}
						</SList>
					</>
				)}
			</SFlex>
		</SFlex>
	)
}

export default TreeSelection
