import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import IJsonSchema from "./types/IJsonSchema"

const getImage = (jsonSchemaImgId: IJsonSchema["imgId"]) => {
	if (jsonSchemaImgId === INCIDENT) {
		return "/img/maincourante/evenement_encours_normale.png"
	}
	if (jsonSchemaImgId === INTERVENTION) {
		return "/img/maincourante/intervention_afaire_normale.png"
	}
	if (jsonSchemaImgId === TEMPORARY_CARE) {
		return "/img/maincourante/care.png"
	}
	return `https://numeriskhot.s3.eu-west-3.amazonaws.com/image_marker/${jsonSchemaImgId}.png`
}

export const getDirectImage = (name) =>
	`https://numeriskhot.s3.eu-west-3.amazonaws.com/${name}.png`

export default getImage
