import { Autocomplete, createFilterOptions, TextField } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import React, { useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"
import { FStandardProps } from "utils/components/jsonSchema/form/FPointProperty"

const filter = createFilterOptions()

const FCustomCreatableSuitesType = ({
	name,
	label = undefined,
	readOnly = false,
	optionsList,
	disabled = false,
}: {
	name: string
	label?: string
	readOnly?: boolean
	optionsList: string[]
	disabled?: boolean
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
		defaultValue: "",
	})

	const [acMenuOpen, setAcMenuOpen] = useState(false)
	const options = optionsList.map((option) => ({
		title: option,
		value: option,
	}))
	return (
		<Autocomplete
			disabled={disabled}
			fullWidth
			open={acMenuOpen}
			noOptionsText="Aucune option, tapez un mot pour commencer"
			onOpen={() => setAcMenuOpen(readOnly ? false : true)}
			onClose={() => setAcMenuOpen(false)}
			onKeyDown={(event) => {
				if (event.key === "Backspace" && readOnly) {
					//@ts-ignore
					event.defaultMuiPrevented = true
				}
			}}
			value={{ title: value, value }}
			onChange={(event, newValue) => {
				// @ts-ignore
				if (newValue?.inputValue) {
					// @ts-ignore
					onChange(newValue.inputValue)
				} else {
					// @ts-ignore
					onChange(newValue?.value ?? "")
				}
			}}
			// @ts-ignore
			filterOptions={(options, params) => {
				let filtered = filter(options, params)

				const { inputValue } = params
				// Suggest the creation of a new value
				const isExisting = options.some((option) => inputValue === option.title)
				if (inputValue !== "" && !isExisting) {
					filtered = [
						{
							inputValue,
							title: `Ajouter "${inputValue}"`,
						},
						...filtered,
					]
				}

				return filtered
			}}
			options={options}
			// @ts-ignore
			getOptionLabel={(option) => option?.title ?? ""}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={ref}
					label={label}
					error={Boolean(error?.message)}
					helperText={error?.message}
					InputProps={{ ...params.InputProps, readOnly }}
					disabled={disabled}
				/>
			)}
		/>
	)
}

export default FCustomCreatableSuitesType
