import usePoints from "@/hooks/usePoints"
import React, { useMemo } from "react"
import { FStandardProps } from "utils/components/jsonSchema/form/FPointProperty"
import FCreatableSelect from "../FCreatableSelect"

const FCreatableSelectJsonSchema = ({
	jsonSchema,
	...otherProps
}: FStandardProps) => {
	const points = usePoints()
	const { name } = otherProps

	const memoizedSuggestions = useMemo(() => {
		const buildedOptions = [] as string[]
		Object.values(points)
			.filter((point) => {
				return point.jsonschema_id === jsonSchema?.id
			})
			.forEach((point) => {
				const pointValue = point.geojson.properties[name]
				if (pointValue && !buildedOptions.includes(pointValue)) {
					buildedOptions.push(pointValue)
				}
			})
		return buildedOptions.sort(function (a, b) {
			if (a < b) {
				return -1
			}
			if (a > b) {
				return 1
			}
			return 0
		})
	}, [points, jsonSchema?.id])

	return <FCreatableSelect suggestions={memoizedSuggestions} {...otherProps} />
}

export default FCreatableSelectJsonSchema
