import React from "react"
import { StepLabel } from "@mui/material"
import { Flex } from "utils/components/style/flex"

const HorizontalStepLabel = ({ label }) => {
	return (
		<StepLabel>
			<Flex directionColumn>{label}</Flex>
		</StepLabel>
	)
}

export default HorizontalStepLabel
