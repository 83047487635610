import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "../rootActions"
import { initialState } from "./constants"

const userSettingSlice = createSlice({
	name: "userSetting",
	initialState,
	reducers: {
		setUserSettings(state, { payload: userSettings }) {
			return userSettings
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default userSettingSlice.reducer
export const { setUserSettings } = userSettingSlice.actions
