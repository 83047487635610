import React, { useState, useEffect } from "react"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import CreateButton from "@/styles/atoms/Button/specialized/CreateButton"
import SaveButton from "@/styles/atoms/Button/specialized/SaveButton"
import { H5 } from "@/utils/components/style/header"
import { FormProvider, useForm } from "react-hook-form"
import Yup from "@/utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Flex } from "@/utils/components/style/flex"
import FormikPCS from "./FormikPCS"
import FSelectHumans from "@/utils/form/FSelectHumans"
import useAccessRights from "@/hooks/useAccessRights"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { FormDependencies } from "@/utils/form/FormDependencies"
import FieldSuivi from "./FieldSuivi"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import { SUIVI_PCS } from "@/redux-toolkit/userSettings/constants"
import IPoint from "@/utils/types/IPoint"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import usePoints from "@/hooks/usePoints"

const schema = Yup.object({
	geojson: Yup.object({
		properties: Yup.object({
			suivi_group_id: Yup.string(),
			relatedObject: Yup.object().required(),
			responsableId: Yup.array(Yup.string()).default([]),
			suivi: Yup.array().default([]),
		}),
	}),
})

const ModalSupport = ({
	isOpen,
	onClose,
	currentSupport,
	isVirtual,
}: {
	isOpen: boolean
	onClose: () => void
	currentSupport: IPoint
	isVirtual: boolean
}) => {
	const { facilityId: facilityIdParams } = useParams<{
		facilityId: string
	}>()
	const facilityId =
		facilityIdParams === "undefined" ? undefined : facilityIdParams
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.SUIVI_PCS)
	const isDisabled = !permissions.write || selectedEvent?.locked
	const dispatch = useDispatch()
	const points = usePoints()

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.getDefault(),
	})

	useEffect(() => {
		if (!isOpen) {
			setTimeout(() => methods.reset(schema.getDefault()), 200)
			return
		}
		let value = points[currentSupport?.id] as any
		if (isVirtual) {
			const { geojson, ...rest } = currentSupport
			value = { geojson: geojson }
		}
		methods.reset(value)
	}, [isOpen])

	const onSubmit = (values) => {
		methods.setValue("geojson.properties.suivi_group_id", facilityId)
		const suivi = {
			...values,
			event_id: selectedEvent.id,
			jsonschema_id: SUIVI_PCS,
		}
		if (currentSupport?.id && !isVirtual) {
			dispatch(
				updatePointWithUpdateEvent({
					point: suivi,
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: SUIVI_PCS,
								subTitle: "Édition d'un accompagnement",
								title: "Accompagnement édité pour le dispositif : " + points[facilityId].geojson.properties.name,
								date: moment().format(),
							},
						],
					},
				}),
			)
		} else {
			dispatch(
				createPointWithUpdateEvent({
					point: {
						id: uuidv4(),
						...suivi,
					},
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: SUIVI_PCS,
								subTitle: "Création d'un accompagnement",
								title: "Nouvel accompagnement pour le dispositif : " + points[facilityId].geojson.properties.name,
								date: moment().format(),
							},
						],
					},
				}),
			)
		}
		onClose()
	}

	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={"5xl"}
				className="max-w-7xl"
				classNames={{
					base: "max-w-7xl",
				}}
			>
				<ModalContent>
					<ModalHeader>
						<H5>
							{isDisabled
								? "Accompagnement"
								: !!currentSupport
									? "Edition d'un accompagnement"
									: "Ajouter un accompagnement"}
						</H5>
					</ModalHeader>
					<ModalBody>
						<Flex fullWidth directionColumn gap="1rem">
							<Flex fullWidth spaceBetween>
								<FormikPCS
									name="geojson.properties.relatedObject"
									label="Personne accompagnée"
									disabled={isDisabled}
								/>
							</Flex>
							<FSelectHumans
								name="geojson.properties.responsableId"
								label="Référents"
								disabled={isDisabled}
							/>
							<FormDependencies targets={["geojson.properties.responsableId"]}>
								{({ "geojson.properties.responsableId": responsableId }) => {
									return (
										<FieldSuivi
											isNew={!currentSupport}
											name="geojson.properties.suivi"
											defaultResponsableId={responsableId}
											disabled={isDisabled}
										/>
									)
								}}
							</FormDependencies>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={() => onClose()} />
						{!currentSupport && (
							<CreateButton
								onClick={methods.handleSubmit(onSubmit)}
								disabled={isDisabled}
							/>
						)}
						{!!currentSupport && (
							<SaveButton
								onClick={methods.handleSubmit(onSubmit)}
								disabled={isDisabled}
							/>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	)
}

export default ModalSupport
