import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { FormProvider, useForm } from "react-hook-form"
import FSelectWithImage from "utils/form/FSelectWithImage"
import useMapState from "@/hooks/useMapState"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { PanelContainer } from "./PanelFilterJsonSchema.styled"
import PointProperty from "utils/components/jsonSchema/properties/PointProperty"
import JsonSchemaHeader from "utils/components/jsonSchema/JsonSchemaHeader"
import { Flex } from "utils/components/style/flex"
import {
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_LEGEND } from "utils/panels/PanelCarto"
import { Subtitle } from "utils/components/style/text"
import ArrowBack from "@mui/icons-material/ArrowBack"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"

// app:javascript:utils:panels:panelCarto:PanelFilterJsonSchema:PanelFilterJsonSchema.tsx
const debug = Debug(
	"app:javascript:utils:panels:panelCarto:PanelFilterJsonSchema:PanelFilterJsonSchema.tsx",
)
debug.log = console.log.bind(console)

const PanelFilterJsonSchema = ({}) => {
	const { jsonSchemaId } = useSelector((state: any) => state.common.panel)
	const dispatch = useDispatch()
	const { addCoucheJsonSchemas, coucheJsonSchemas } = useMapState()
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const jsonSchema = jsonSchemaWithStatics[jsonSchemaId]
	const methods = useForm({
		defaultValues: {
			imgId: coucheJsonSchemas[jsonSchemaId]?.activeFilter,
		},
	})
	const values = methods.watch()

	useEffect(() => {
		methods.reset(
			{
				imgId: coucheJsonSchemas[jsonSchemaId]?.activeFilter,
			},
			{
				keepErrors: true,
				keepDirty: true,
				keepIsSubmitted: true,
				keepTouched: true,
				keepIsValid: true,
				keepSubmitCount: true,
			},
		)
	}, [jsonSchemaId, coucheJsonSchemas[jsonSchemaId]?.activeFilter])

	useEffect(() => {
		dispatch(
			addCoucheJsonSchemas({
				id: jsonSchemaId,
				activeFilter: values.imgId,
			}),
		)
	}, [values.imgId])

	return (
		<PanelContainer>
			<FormProvider {...methods}>
				<Flex gap="1rem" directionColumn fullWidth>
					<Flex gap="0.5rem" fullWidth>
						<PrimaryOutlinedButton
							onClick={() => dispatch(setPanel({ type: DAYBOOK_LEGEND }))}
							fullWidth
							startIcon={<ArrowBack />}
						>
							Retour
						</PrimaryOutlinedButton>
						<PrimaryTextButton
							onClick={() => {
								dispatch(
									addCoucheJsonSchemas({
										id: jsonSchemaId,
										activeFilter: undefined,
									}),
								)
							}}
							fullWidth
							disabled={_.isEmpty(values)}
						>
							Réinitialiser
						</PrimaryTextButton>
					</Flex>
					<JsonSchemaHeader jsonSchema={jsonSchema} />
					<Flex directionColumn gap="0.5rem" fullWidth alignItemsStart>
						<Subtitle>Filtrer par</Subtitle>
						<FSelectWithImage
							name="imgId"
							placeholder="Ajouter un filtre"
							isMulti={
								JsonSchemaService.getFilterProperty(jsonSchema)?.options
									?.isMulti
							}
							options={
								JsonSchemaService.getFilterProperty(jsonSchema)?.itemsImage
							}
						/>
					</Flex>
				</Flex>
			</FormProvider>
		</PanelContainer>
	)
}

export default PanelFilterJsonSchema
