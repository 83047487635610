import React from "react"
import SuperMap from "utils/components/map/SuperMap"

const CartographyPreview = ({
	children,
	layer = "Osm",
	viewPosition = undefined,
	mapDimensions = {},
	setMapEventEndValue = () => {},
	disableDragging = false,
	disableZoomControl = true,
	disableZoom = false,
}: {
	children: JSX.Element
	layer?: any
	viewPosition?: any
	mapDimensions?: {
		width?: string
		height?: string
		maxWidth?: string
		maxHeight?: string
	}
	setMapEventEndValue?: (value: any) => void
	disableDragging?: boolean
	disableZoomControl?: boolean
	disableZoom?: boolean
}) => {
	const position = [46.14868, -1.155302] as any

	return (
		<SuperMap
			mapDimensions={{
				width: "100%",
				height: "100%",
				...mapDimensions,
			}}
			mapContainerHeight="100%"
			center={position}
			resize={false}
			disableMargin
			disableZoomControl={disableZoomControl}
			disableZoom={disableZoom}
			displayOptions={{
				displayMinimap: false,
				displayDraw: false,
				displaySignal: false,
				displayIncidentControl: false,
				displayBottomControl: false,
				displayRightControl: false,
				defaultLayer: layer,
				defaultViewPosition: viewPosition,
			}}
			hasSearchControl={false}
			setMapEventEndValue={setMapEventEndValue}
			disableDragging={disableDragging}
			position="relative"
		>
			{children}
		</SuperMap>
	)
}
export default CartographyPreview
