import SearchIcon from "@mui/icons-material/Search"
import { TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import {
	CUSTOMDATA,
	PATH_CUSTOM_PCS,
	PATH_DONNEES_COMMUNALES,
} from "@/redux-toolkit/userSettings/constants"
import React, { useState, useMemo } from "react"
import styled from "styled-components"
import useNavigationTree from "utils/components/categoryNavigation/useNavigationTree"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import JsonSchemaTreeCard from "./JsonSchemaTreeCard"
import NestedListParent from "./NestedListParent"
import NestedListParentPersistent from "./NestedListParentPersistent"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const Container = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
`
const StyledTextField = styled(TextField)`
    padding: 1rem !important;
    width: 100% !important;
`
const StyledWhiteContainer = styled.div`
    background-color: white;
`
const PcsData = ({
	setPcsCategory = undefined,
	actionOnFinalSelect = undefined,
}) => {
	const readPermission = useAccessRights(EnumToolPath.DONNEES_COMMUNALES).read
	const [searchValue, setSearchValue] = useState("")
	const { tree } = useNavigationTree({ includeMultiPolygons: false })
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const filteredJsonSchemas = useMemo(() => {
		if (!searchValue) {
			return
		}
		return Object.values(jsonSchemasWithStatics).filter(({ title }) =>
			formatNfdLowerCase(title).includes(formatNfdLowerCase(searchValue)),
		)
	}, [searchValue, jsonSchemasWithStatics])

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<StyledWhiteContainer>
			<Container>
				<StyledTextField
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="Rechercher un formulaire de données"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				{!searchValue &&
					setPcsCategory &&
					tree.map((item: any) => (
						<NestedListParent
							key={item.id}
							item={item}
							setPcsCategory={setPcsCategory}
							actionOnFinalSelect={actionOnFinalSelect}
						/>
					))}
				{!searchValue &&
					!setPcsCategory &&
					tree.map((item: any) => (
						<NestedListParentPersistent key={item.id} item={item} />
					))}
				{searchValue &&
					filteredJsonSchemas.map((jsonSchema: any) => (
						<JsonSchemaTreeCard
							key={jsonSchema.id}
							color="neutral"
							itemJsonSchema={jsonSchema}
							setPcsCategory={setPcsCategory}
							actionOnFinalSelect={actionOnFinalSelect}
						/>
					))}
			</Container>
		</StyledWhiteContainer>
	)
}

export default PcsData
