import { useSelector } from "react-redux"
import SynchroService from "@/services/SynchroService"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IPoint from "utils/types/IPoint"
import { StaticFormType, STOCK_PLACE } from "utils/services/StaticFormsService"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(state: any) => state.userSettings.commune_id,
	(points, commune_id) =>
		SynchroService.notDeletedWithCurrentCommune(points, commune_id),
)

const useStockPlacesAndCare: () => { [key: string]: IPoint } = () => {
	const points = useSelector(selectPoints) as IPoint[]
	const stockPlaces = {}
	Object.values(points)
		.filter(
			(point) =>
				point["jsonschema_id"] === StaticFormType.STOCK_PLACE ||
				point["jsonschema_id"] === StaticFormType.CARE,
		)
		.forEach((point) => {
			stockPlaces[point.id] = point
		})
	return stockPlaces
}

export default useStockPlacesAndCare
