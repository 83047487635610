import { addLoadingKey, removeLoadingKey } from "@/redux-toolkit/common/reducer"
import SynchroService from "../SynchroService"
import { SYNCHRO_EVENT } from "@/redux-toolkit/common/loadingKeys"
import {
	synchronizeCreatedEvent,
	synchronizeUpdatedEvent,
	synchronizeDeletedEvent,
	getEvents,
	getEventsWithDeleted,
} from "@/redux-toolkit/data/events/resources"
import { toast } from "react-toastify"
import ErrorService from "@/services/ErrorService"
import { createAsyncThunk } from "@reduxjs/toolkit"
const synchronize = async (dispatch, getState, withoutDelete = false) => {
	dispatch(addLoadingKey(SYNCHRO_EVENT))

	try {
		if (withoutDelete) {
			await getEvents(dispatch, getState)
		} else {
			await getEventsWithDeleted(dispatch, getState)
		}
		const events = getState().data.events
		const {
			toDeleteItems: toDeleteEvents,
			toCreateItems: toCreateEvents,
			toUpdateItems: toUpdateEvents,
		} = SynchroService.getItemsByLocalStatus(events)
		await Promise.all([
			...toCreateEvents.map((event) =>
				synchronizeCreatedEvent(dispatch, event),
			),
			...toUpdateEvents.map((event) =>
				synchronizeUpdatedEvent(dispatch, event),
			),
			...toDeleteEvents.map((event) =>
				synchronizeDeletedEvent(dispatch, event),
			),
		])
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation des events, message: ${error.message}`
		ErrorService.error({
			component: "SynchroEventService:synchronize",
			message: errorMessage,
			dispatch,
		})
	} finally {
		dispatch(removeLoadingKey(SYNCHRO_EVENT))
	}
}

export const synchronizeAction = createAsyncThunk(
	"data/events/addEventWebSocket",
	async (_a, { dispatch, getState }) => {
		try {
			await synchronize(dispatch, getState, false)
		} catch (error) {
			const errorMessage = `Erreur lors de la synchronisation des events, message: ${error.message}`
			ErrorService.error({
				component: "SynchroEventService:addEventWebSocket",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export default { synchronize, synchronizeAction }
