import {
	Image as RImage,
	Document as RDocument,
	Page as RPage,
	View as RView,
} from "@react-pdf/renderer"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import React from "react"
import getImage from "@/utils/getImage"
import NormalText from "utils/pdf/NormalText"
import PTitle from "utils/pdf/PTitle"
import PdfJsonSchemaTable from "utils/pdf/table/PdfJsonSchemaTable/PdfJsonSchemaTable"
import {
	INCIDENT_SCHEMA,
	INTERVENTION_SCHEMA,
	TEMPORARY_CARE_SCHEMA,
} from "utils/services/StaticFormsService"
import IJsonSchema from "@/utils/types/IJsonSchema"
const Document = RDocument as unknown as any
const Page = RPage as unknown as any
const View = RView as any

const Image = RImage as any
interface RequestPdfProps {
	intersectedPointsByCouches: any
	jsonSchemaWithStatics: Record<string, IJsonSchema>
	getTextFromProperty: any
	concernedMultiPolygon: any
}

const RequestPdf = ({
	intersectedPointsByCouches,
	jsonSchemaWithStatics,
	getTextFromProperty,
	concernedMultiPolygon,
}: RequestPdfProps) => {
	// fill 50 array

	const array = Array.from(Array(50).keys())
	const styles = {
		page: {
			flexDirection: "column",
			backgroundColor: "#FFFFFF",
			width: "100%",
			paddingTop: 16, // set the top margin
			paddingLeft: 16,
			paddingRight: 16,
			paddingBottom: 16,
		},
		section: {
			margin: 10,
			padding: 10,
			flexGrow: 1,
		},
	}
	if (!jsonSchemaWithStatics) return null
	const jsonSchemas = Object.keys(intersectedPointsByCouches).map(
		(jsonSchemaId) => {
			if (jsonSchemaId === INCIDENT) {
				return INCIDENT_SCHEMA
			}
			if (jsonSchemaId === INTERVENTION) {
				return INTERVENTION_SCHEMA
			}
			if (jsonSchemaId === TEMPORARY_CARE) {
				return TEMPORARY_CARE_SCHEMA
			}
			return jsonSchemaWithStatics[jsonSchemaId]
		},
	)

	return (
		<Document>
			<Page size="A4" style={styles.page} renderTextLayer={false}>
				{jsonSchemas.map((jsonSchema, index) => (
					<View
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}
						break={index !== 0}
					>
						<View
							style={{
								padding: "24px",
								dipslay: "flex",
								flexDirection: "row",
								gap: "16px",
								alignItems: "center",
								maxWidth: "500px",
							}}
						>
							<Image
								src={getImage(jsonSchema.imgId)}
								style={{
									width: "38px",
									height: "34px",
								}}
							/>{" "}
							<PTitle
								style={{
									fontSize: "16px",
								}}
							>
								Extraction des {jsonSchema.title} avec{" "}
								{concernedMultiPolygon.label}
							</PTitle>
						</View>
						<PdfJsonSchemaTable
							jsonSchema={jsonSchema}
							points={intersectedPointsByCouches[jsonSchema.id]}
							getTextFromProperty={getTextFromProperty}
						/>
					</View>
				))}
			</Page>
		</Document>
	)
}

export default RequestPdf
