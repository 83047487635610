import React, { useState, useEffect, useMemo, useContext } from "react"
import Debug from "debug"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import LinearScaleIcon from "@mui/icons-material/LinearScale"
import { Menu, MenuItem, Tooltip } from "@mui/material"
import { Marker, Polyline, useMap } from "react-leaflet"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Flex } from "@/utils/components/style/flex"
import {
	setCurrentIncident,
	setCurrentIntervention,
} from "@/redux-toolkit/edit/reducer"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import { ScreenShotContext } from "./DaybookDashboard"
import useAccessRights from "@/hooks/useAccessRights"
import {
	PATH_MAIN_COURANTE_CARTOGRAPHY,
	PATH_MAIN_COURANTE_INCIDENT,
	PATH_MAIN_COURANTE_INTERVENTION,
} from "@/redux-toolkit/userSettings/constants"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:maincourante:DaybookMapContextMenu.tsx
const debug = Debug(
	"app:javascript:components:maincourante:DaybookMapContextMenu.tsx",
)
debug.log = console.log.bind(console)
const StyledMenuItem = styled(MenuItem)`
    background-color: inherit !important;
    &:hover {
        background-color: var(--primary100) !important;
    }
`
const StyledLinearScaleIcon = styled(LinearScaleIcon)`
    font-size: 30px !important;
`
const Subtitle = styled.div`
    font-size: 0.75rem !important;
    color: var(--neutral500) !important;
`
const StyledDiv = styled.div<{ $mouseLatLngXY: any }>`
    position: absolute;
    font-size: 0.9rem;
    background-color: white;
    z-index: 999999;
    padding: 0.5rem;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    top: ${(props) => props.$mouseLatLngXY.mouseY - 70}px;
    left: ${(props) => props.$mouseLatLngXY.mouseX - 280}px;
`
const DaybookMapContextMenu = ({ children }) => {
	const map = useMap()
	const [currentEvent, setCurrentEvent] = useState(undefined)
	const [mouseLatLngXY, setMouseLatLngXY] = useState(undefined)
	const dispatch = useDispatch()
	const [contextMenu, setContextMenu] = React.useState(null)
	const [activateMouseDistance, setActivateMouseDistance] = useState(false)
	const handleClose = () => {
		setContextMenu(null)
		setCurrentEvent(undefined)
	}
	const selectedEvent = useSelectedEvent()
	const { isTakingScreenshotMap, setIsTakingScreenshotMap } =
		useContext(ScreenShotContext)
	const permissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)
	const interventionPermissions = useAccessRights(EnumToolPath.INTERVENTION)
	const incidentPermissions = useAccessRights(EnumToolPath.INCIDENT)
	const isLocked = selectedEvent?.locked

	const [isPressed] = useKeyboardJs("escape")

	useEffect(() => {
		map.on("contextmenu", (event: any) => {
			if (isTakingScreenshotMap) {
				return
			}
			event.originalEvent.preventDefault()
			setCurrentEvent({
				latlng: event?.latlng,
				mouseX: event.originalEvent.clientX,
				mouseY: event.originalEvent.clientY,
			})
			setContextMenu(
				contextMenu === null
					? {
							mouseX: event.originalEvent.clientX - 2,
							mouseY: event.originalEvent.clientY - 4,
						}
					: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
						// Other native context menus might behave different.
						// With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
						null,
			)
		})

		return () => {
			map.off("contextmenu")
		}
	}, [map])
	const sleep = async (ms) => {
		return new Promise((resolve, reject) => {
			// @ts-ignore
			DaybookMapContextMenu.cancellationToken = {}
			// @ts-ignore
			DaybookMapContextMenu.cancellationToken.cancel = function () {}
			setTimeout(resolve, ms)
		})
	}
	useEffect(() => {
		if (activateMouseDistance) {
			map.on("mousemove", async (event: any) => {
				// @ts-ignore
				DaybookMapContextMenu.cancellationToken?.cancel()
				await sleep(50)
				setMouseLatLngXY({
					latlng: event?.latlng,
					mouseX: event.originalEvent.clientX + 20,
					mouseY: event.originalEvent.clientY + 50,
				})
			})
		} else {
			map.off("mousemove")
		}
		return () => {
			map.off("mousemove")
		}
	}, [activateMouseDistance, map])

	useEffect(() => {
		if (isPressed) {
			setActivateMouseDistance(false)
			setMouseLatLngXY(undefined)
			setCurrentEvent(undefined)
		}
	}, [isPressed])
	const distance = MapService.getDistanceBetweenTwoMarker(
		currentEvent?.latlng,
		mouseLatLngXY?.latlng,
	)
	return (
		<>
			{children}
			<Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null
						? { top: contextMenu.mouseY, left: contextMenu.mouseX }
						: undefined
				}
			>
				{permissions.write && !isLocked && (
					<div>
						{incidentPermissions.write && (
							<StyledMenuItem
								onClick={() => {
									handleClose()
									dispatch(
										setCurrentIncident({
											Géolocalisation: {
												coo: {
													lat: currentEvent?.latlng.lat,
													lng: currentEvent?.latlng.lng,
												},
												Adresse: "",
											},
										}),
									)
								}}
							>
								<Flex gap="1rem">
									<img
										width={30}
										src="/img/maincourante/evenement_encours_normale.png"
									/>
									<div>Créer un Évènement</div>
								</Flex>
							</StyledMenuItem>
						)}
						{interventionPermissions.write && (
							<StyledMenuItem
								onClick={() => {
									handleClose()
									dispatch(
										setCurrentIntervention({
											Adresse: {
												coo: {
													lat: currentEvent?.latlng.lat,
													lng: currentEvent?.latlng.lng,
												},
												Adresse: "",
											},
										}),
									)
								}}
							>
								<Flex gap="1rem">
									<img
										width={30}
										src="/img/maincourante/intervention_afaire_normale.png"
									/>
									<div>Créer une Intervention</div>
								</Flex>
							</StyledMenuItem>
						)}
					</div>
				)}
				<StyledMenuItem
					onClick={() => {
						setContextMenu(null)
						setActivateMouseDistance(true)
					}}
				>
					<Flex gap="1rem">
						<StyledLinearScaleIcon />
						<div>Calculer la distance entre deux points</div>
					</Flex>
				</StyledMenuItem>
				<StyledMenuItem
					onClick={() => {
						setContextMenu(null)
						navigator.clipboard.writeText(
							`${currentEvent?.latlng.lat},${currentEvent?.latlng.lng}`,
						)
					}}
				>
					<Flex gap="1rem">
						<ContentCopyIcon />
						<div>Copier la position</div>
					</Flex>
				</StyledMenuItem>
			</Menu>
			{currentEvent?.latlng && (
				<>
					<Marker position={currentEvent?.latlng} />
					{/** Marker at mouse position */}
					{mouseLatLngXY?.latlng && (
						<>
							<StyledDiv $mouseLatLngXY={mouseLatLngXY}>
								<Flex gap="1rem" directionColumn>
									<div>
										Distance entre les deux points : {}
										{distance < 1000 ? (
											<>
												<b>{distance}</b> mètres
											</>
										) : (
											<>
												<b>{Math.floor(distance / 10) / 100}</b> km
											</>
										)}
									</div>
									<Subtitle>Appuyez sur Echap pour fermer</Subtitle>
								</Flex>
							</StyledDiv>
							<Marker position={mouseLatLngXY?.latlng} />
							<Polyline
								positions={[[currentEvent?.latlng, mouseLatLngXY?.latlng]]}
							/>
						</>
					)}
				</>
			)}
		</>
	)
}

export default DaybookMapContextMenu
