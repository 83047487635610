import { DEFAULT_POLYGON_STYLE } from "utils/defaultPolygonStyle"
import _ from "lodash"
const getPolygonStyle = (multiPolygon) => {
	const style = _.cloneDeep(
		multiPolygon?.custom_props?.style ?? DEFAULT_POLYGON_STYLE,
	)
	if (!style["opacity"] && style["opacity"] !== 0) {
		style["opacity"] = 1.0
	}
	if (!style["fillOpacity"] && style["fillOpacity"] !== 0) {
		if (multiPolygon?.geojson?.includes?.("LineString")) {
			style["fillOpacity"] = 0.0
		} else {
			style["fillOpacity"] = 0.3
		}
	}
	if (style["weight"]) {
		const weight = parseInt(style["weight"])
		style["weight"] = weight < 0.5 ? 0.5 : weight
	}
	return style
}

export default getPolygonStyle
