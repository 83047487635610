import "@geoman-io/leaflet-geoman-free"
import useCdcGeojson from "@/hooks/useCdcGeojson"
import useCommuneGeojson from "@/hooks/useCommuneGeojson"
import useHasVortexStation from "@/hooks/useHasVortexStation"
import useMapState from "@/hooks/useMapState"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import BottomCenterControl from "@/pages/maincourante/BottomCenterControl"
import MinimapControl from "@/pages/maincourante/MinimapControl"
import RightCenterControl from "@/pages/maincourante/RightCenterControl"
import MarkerVortex from "@/pages/maincourante/subComponents/carto/MarkerVortex"
import { createDrawMultiPolygon } from "@/redux-toolkit/daybook/resources"
import { VORTEX_STATION } from "@/redux-toolkit/userSettings/constants"
import { IProvidedSelection } from "@/pages/telechargement/services/CartographyTemplate"
import { default as React, useState } from "react"
import { isDesktop } from "react-device-detect"
import { FormProvider, useForm } from "react-hook-form"
import { MapContainer, ScaleControl } from "react-leaflet"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import FColor from "utils/form/FColor"
import FText from "utils/form/FText"
import SuperModal from "utils/modals/SuperModal"
import TileLayerService from "utils/services/TileLayerService"
import { v4 as uuidv4 } from "uuid"
import { Flex } from "../style/flex"
import SearchControl from "./SearchControl"
import SuperMapContained from "./SuperMapContained"
import vortexStations from "./vortexStations"

const Container = styled.div<{
	disableMargin: boolean
	mapContainerMarginTop?: string
	$position: string
	$mapDimensions: {
		width?: string
		height?: string
		maxWidth?: string
		maxHeight?: string
	}
	invisible?: boolean
}>`
    width: ${({ $mapDimensions }) => $mapDimensions.width} !important;
    height: ${({ $mapDimensions }) => $mapDimensions.height} !important;
    max-width: ${({ $mapDimensions }) => $mapDimensions.maxWidth} !important;
    max-height: ${({ $mapDimensions }) => $mapDimensions.maxHeight} !important;
	z-index: 0;
    transition: all 0.3s ease-in-out;
    margin-top: ${({ disableMargin }) =>
			disableMargin ? 0 : "var(--height-top-bar)"};
    ${({ disableMargin }) =>
			disableMargin
				? css`
                  margin-left: 0px;
              `
				: css`
                  margin-left: var(--width-navbar);
              `};
    ${({ disableMargin }) =>
			disableMargin
				? css`
                  width: 100%;
              `
				: css`
                  width: calc(100% - var(--width-navbar) - var(--panel-width));
              `};
    ${({ mapContainerMarginTop }) => {
			if (mapContainerMarginTop) {
				return css`
                margin-top: ${mapContainerMarginTop};
            `
			}
			return ""
		}}
    ${({ invisible }) => {
			if (invisible) {
				return css`
                z-index: -1 !important;
            `
			}
			return ""
		}}
    ${({ $position }) => {
			if ($position === "absolute") {
				return css`
                position: absolute !important;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            `
			} else {
				return css`
                position: ${$position} !important;
            `
			}
		}}
`

const MyMapContainer = styled(MapContainer)<{
	$position: string
	$mapContainerHeight?: string
	$hasMargin?: boolean
}>`
	${({ $hasMargin }) => {
		if ($hasMargin) {
			return css`
				margin: 0.5rem !important;
			`
		}
		return ""
	}}
    ${({ $mapContainerHeight }) => {
			if ($mapContainerHeight) {
				return css`
                height: ${$mapContainerHeight};
            `
			}
			return ""
		}}
    ${({ $position }) => {
			if ($position === "absolute") {
				return css`
                position: absolute !important;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            `
			} else {
				return css`
                position: ${$position} !important;
            `
			}
		}}
` as any

/**
 * 描述
 * @date 2021-07-29
 * @param {any} {center=[1
 * @param {any} 1]asany
 * @param {any} zoom=10
 * @param {any} children
 * @param {any} communeGeojson
 * @param {any} resize=true
 * @param {any} }
 * @returns {any}
 */

const SuperMap = ({
	center = [1, 1] as any,
	zoom = 10,
	children,
	resize = true,
	disableMargin = false,
	cooperation = false,
	disableZoomControl = false,
	disableZoom = false,
	onDoubleClick = (event: any, activeMap: any) => {},
	setMapRef = () => {},
	setMapScreenBlob,
	mapDimensions = {},
	mapContainerHeight = undefined,
	mapContainerMarginTop = undefined,
	displayOptions = {
		displayMinimap: true,
		displayDraw: true,
		displaySignal: true,
		displayIncidentControl: true,
		displayBottomControl: true,
		displayRightControl: true,
		defaultLayer: undefined,
		defaultViewPosition: undefined,
		hasMargin: false,
	},
	setMapEventEndValue = undefined,
	hasSearchControl = true,
	invisible = false,
	providedSelection = undefined,
	disableDragging = false,
	position = "absolute",
}: {
	center?: number[]
	zoom?: number
	children: any
	resize?: boolean
	disableMargin?: boolean
	cooperation?: boolean
	disableZoomControl?: boolean
	disableZoom?: boolean
	onDoubleClick?: (event: any, activeMap: any) => void
	setMapRef?: React.Dispatch<(prevState: undefined) => undefined>
	setMapScreenBlob?: React.Dispatch<any>
	mapDimensions?: {
		width?: string
		height?: string
		maxWidth?: string
		maxHeight?: string
	}
	mapContainerHeight?: string
	mapContainerMarginTop?: string
	displayOptions?: {
		displayMinimap?: boolean
		displayDraw?: boolean
		displaySignal?: boolean
		displayIncidentControl?: boolean
		displayBottomControl?: boolean
		displayRightControl?: boolean
		defaultLayer?: string
		defaultViewPosition?: {
			center: [number, number]
			zoom: number
		}
		hasMargin?: boolean
	}
	setMapEventEndValue?: (value: any) => void
	hasSearchControl?: boolean
	invisible?: boolean
	providedSelection?: IProvidedSelection
	disableDragging?: boolean
	position?: "absolute" | "relative" | "fixed" | "sticky" | "static"
}) => {
	const dispatch = useDispatch()
	const communeGeojson = useCommuneGeojson()
	const cdcGeojson = useCdcGeojson()
	const methods = useForm()
	const [tempDrawCouche, setTempDrawCouche] = useState(undefined)
	const selectedEvent = useSelectedEvent()
	const { coucheEvents, selectedLayer } = useMapState()
	const vortexPresent = coucheEvents[VORTEX_STATION] !== false
	const hasVortexStation = useHasVortexStation()
	const [isCaptureSelect, setIsCaptureSelect] = useState(false)

	const onSubmit = (values) => {
		dispatch(
			createDrawMultiPolygon({
				geojson: tempDrawCouche.toGeoJSON(),
				id: uuidv4(),
				name: values["Nom de la couche"],
				custom_props: {
					style: {
						color: values["fillColor"],
						fillColor: values["fillColor"],
						fill: true,
						stroke: true,
						fillOpacity: 0.2,
						opacity: 1.0,
						weight: 3,
					},
					priority: "10",
					details: values["details"],
				},
				event_id: selectedEvent?.id,
			}),
		)
		methods.reset({})
		setTempDrawCouche(undefined)
	}

	const mergedDisplayOptions = {
		displayMinimap: displayOptions?.displayMinimap ?? true,
		displayDraw: displayOptions?.displayDraw ?? true,
		displaySignal: displayOptions?.displaySignal ?? true,
		displayIncidentControl: displayOptions?.displayIncidentControl ?? true,
		displayBottomControl: displayOptions?.displayBottomControl ?? true,
		displayRightControl: displayOptions?.displayRightControl ?? true,
	}

	let providedSelectionLayerType = providedSelection?.layerType
	if (providedSelectionLayerType === "Carroyages DFCI") {
		providedSelectionLayerType = "Osm"
	}

	return (
		<Container
			disableMargin={disableMargin}
			mapContainerMarginTop={mapContainerMarginTop}
			$position={position}
			$mapDimensions={mapDimensions}
			invisible={invisible}
		>
			<MyMapContainer
				id="numerisk-map-container"
				zoomSnap={0}
				zoomDelta={0.1}
				wheelPxPerZoomLevel={60}
				center={center}
				zoom={zoom}
				preferCanvas={true}
				zoomControl={!disableZoomControl}
				maxZoom={18}
				whenCreated={setMapRef}
				$mapContainerHeight={mapContainerHeight}
				$mapDimensions={mapDimensions}
				$position={position}
				$hasMargin={displayOptions?.hasMargin}
			>
				<SuperMapContained
					setTempDrawCouche={setTempDrawCouche}
					communeGeojson={cooperation ? cdcGeojson : communeGeojson}
					resize={resize}
					onDoubleClick={onDoubleClick}
					setMapScreenBlob={setMapScreenBlob}
					setMapEventEndValue={setMapEventEndValue}
					isCaptureSelect={isCaptureSelect}
					disableDragging={disableDragging}
					disableZoom={disableZoom}
					providedSelection={providedSelection}
					defaultViewPosition={displayOptions?.defaultViewPosition}
				>
					<>
						{isDesktop && (
							<ScaleControl imperial={false} position="bottomright" />
						)}

						{hasSearchControl && <SearchControl />}

						{isDesktop && mergedDisplayOptions?.displayMinimap && (
							<MinimapControl selectedLayer={selectedLayer} />
						)}
						{
							TileLayerService.getAllLayers()[
								providedSelectionLayerType ??
									displayOptions?.defaultLayer ??
									selectedLayer
							]
						}

						{mergedDisplayOptions?.displayRightControl && (
							<RightCenterControl />
						)}
						{vortexPresent && hasVortexStation && (
							<>
								{vortexStations.map((vortexStation) => (
									<MarkerVortex
										key={vortexStation.name}
										vortexStation={vortexStation}
									/>
								))}
							</>
						)}

						{mergedDisplayOptions?.displayBottomControl && (
							<BottomCenterControl
								displayDraw={mergedDisplayOptions?.displayDraw}
								displayIncidentControl={
									mergedDisplayOptions?.displayIncidentControl
								}
								displaySignal={mergedDisplayOptions?.displaySignal}
								tempDrawCouche={tempDrawCouche}
								setTempDrawCouche={setTempDrawCouche}
								isCaptureSelect={isCaptureSelect}
								setIsCaptureSelect={setIsCaptureSelect}
							/>
						)}
						{children}

						<FormProvider {...methods}>
							<SuperModal
								isOpen={!!tempDrawCouche && !isCaptureSelect}
								onClose={() => setTempDrawCouche(undefined)}
								onClick={methods.handleSubmit(onSubmit)}
								isNew
								title="Ajouter une couche"
							>
								<Flex alignItemsInitial directionColumn gap="1rem">
									<FText name="Nom de la couche" />
									<FColor label="Couleur" name="fillColor" />
									<FText name="details" label="Note (facultatif)" multiline />
								</Flex>
							</SuperModal>
						</FormProvider>
					</>
				</SuperMapContained>
			</MyMapContainer>
		</Container>
	)
}

export default SuperMap
