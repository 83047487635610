import { createContext, useEffect, useState } from "react"
import { ROW_HEIGHT } from "utils/components/tables/SuperReactTable/SuperReactTable.styled"

export const ReactTableContainerContext = createContext<HTMLElement>(
	document.body,
)

function useAutoPageSize({
	pageSize,
	setPageSize,
	infiniteSize,
	forcedFixedElementPerPage,
	additionalLineNumber = 0,
}: {
	pageSize: number
	setPageSize: (n: number) => void
	infiniteSize: boolean
	forcedFixedElementPerPage: number
	additionalLineNumber?: number
}) {
	const [defaultPageSize, setDefaultPageSize] = useState(pageSize)
	const onResize = () => {
		setDefaultPageSize(
			Math.floor((window.innerHeight - window.innerHeight / 2) / ROW_HEIGHT) +
				additionalLineNumber,
		)
		if (infiniteSize) {
			setPageSize(999)
			return
		}
		if (forcedFixedElementPerPage) {
			setPageSize(forcedFixedElementPerPage)
			return
		}
		setPageSize(
			Math.floor((window.innerHeight - window.innerHeight / 2) / ROW_HEIGHT) +
				additionalLineNumber,
		)
	}
	useEffect(() => {
		onResize()
		window.addEventListener("resize", onResize)
		return () => {
			window.removeEventListener("resize", onResize)
		}
	}, [infiniteSize, forcedFixedElementPerPage])

	return defaultPageSize
}

export default useAutoPageSize
