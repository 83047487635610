import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"

const initialState = {}

const uploadedFileSlice = createSlice({
	name: "uploadedFile",
	initialState,
	reducers: {
		setUploadedFiles(state, { payload: uploadedFiles }) {
			return uploadedFiles
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default uploadedFileSlice.reducer
export const { setUploadedFiles } = uploadedFileSlice.actions
