import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek"
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns"
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap"
import React from "react"
import ReactDOMServer from "react-dom/server"
import styled from "styled-components"
import L from "leaflet"
import SouthIcon from "@mui/icons-material/South"
import SouthEastIcon from "@mui/icons-material/SouthEast"
import SouthWestIcon from "@mui/icons-material/SouthWest"
import NorthIcon from "@mui/icons-material/North"
import NorthEastIcon from "@mui/icons-material/NorthEast"
import NorthWestIcon from "@mui/icons-material/NorthWest"
import EastIcon from "@mui/icons-material/East"
import WestIcon from "@mui/icons-material/West"
import GroupsIcon from "@mui/icons-material/Groups"
import PetsIcon from "@mui/icons-material/Pets"
import CampaignIcon from "@mui/icons-material/Campaign"
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety"
import BuildCircleIcon from "@mui/icons-material/BuildCircle"
import FlashOnIcon from "@mui/icons-material/FlashOn"
import FlashOffIcon from "@mui/icons-material/FlashOff"
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"
import WaterIcon from "@mui/icons-material/Water"
import AirIcon from "@mui/icons-material/Air"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import CropSquareIcon from "@mui/icons-material/CropSquare"
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory"
import { Icon } from "@iconify/react"
import baselineFireTruck from "@iconify/icons-ic/baseline-fire-truck"

const colored = (Component) => {
	return styled(Component)<{ color: string }>`
        color: ${({ color }) => color};
    `
}

const StyledReportProblemIcon = colored(ReportProblemIcon)
const StyledDoDisturbOnIcon = colored(DoDisturbOnIcon)
const StyledLocalParkingIcon = colored(LocalParkingIcon)
const StyledFollowTheSignsIcon = colored(FollowTheSignsIcon)
const StyledZoomInMapIcon = colored(ZoomInMapIcon)
const StyledCalendarViewWeekIcon = colored(CalendarViewWeekIcon)
const StyledSouthIcon = colored(SouthIcon)
const StyledSouthEastIcon = colored(SouthEastIcon)
const StyledSouthWestIcon = colored(SouthWestIcon)
const StyledNorthIcon = colored(NorthIcon)
const StyledNorthWestIcon = colored(NorthWestIcon)
const StyledNorthEastIcon = colored(NorthEastIcon)
const StyledEastIcon = colored(EastIcon)
const StyledWestIcon = colored(WestIcon)
const StyledGroupsIcon = colored(GroupsIcon)
const StyledPetsIcon = colored(PetsIcon)
const StyledCampaignIcon = colored(CampaignIcon)
const StyledHealthAndSafetyIcon = colored(HealthAndSafetyIcon)
const StyledBuildCircleIcon = colored(BuildCircleIcon)
const StyledFlashOnIcon = colored(FlashOnIcon)
const StyledFlashOffIcon = colored(FlashOffIcon)
const StyledLocalFireDepartmentIcon = colored(LocalFireDepartmentIcon)
const StyledWaterIcon = colored(WaterIcon)
const StyledAirIcon = colored(AirIcon)
const StyledAcUnitIcon = colored(AcUnitIcon)
const StyledCropSquareIcon = colored(CropSquareIcon)
const StyledTriangle = colored(ChangeHistoryIcon)
// ? Population
export const PEOPLE_GROUP = "Groupe de personnes"
export const ANIMALS = "Animaux"

// ? Signalétique
export const BARRIERE = "Barrière"
export const DANGER = "Danger"
export const INTERDICTION = "Circulation interdite"
export const PARKING = "Stationnement"
export const EVACUATION_ZONE = "Zone évacuée"
export const MEETING_POINT = "Point de rassemblement"

// ? Direction
export const ARROW_LEFT = "Nord-ouest"
export const ARROW_RIGHT = "Nord-est"
export const ARROW_UP = "Sud"
export const ARROW_DOWN = "Nord"
export const ARROW_DOWN_LEFT = "Sud-ouest"
export const ARROW_DOWN_RIGHT = "Sud-est"
export const ARROW_UP_LEFT = "Ouest"
export const ARROW_UP_RIGHT = "Est"

// ? Intervention
export const ALERT = "Alerte"
export const RESCUE = "Service de secours"
export const REPAIR = "Réparation"
export const AMBULANCE = "Ambulance"
export const FIRETRUCK = "Camion de pompier"
export const POLICE_CAR = "Véhicule de police"
export const POLICE = "Police"
// ? Danger
export const POWERED_NETWORKS = "Réseaux alimentés"
export const STOPPED_NETWORKS = "Réseaux coupés"
export const FIRE = "Feu"
export const FLOODS = "Inondations"
export const DEBRIS = "Chûte de débris"
export const BLACK_ICE = "Verglas"
export const GRUE = "Grue"
export const TRUCK = "Camion"

export const SQUARE = "Carré"
export const TRIANGLE = "Triangle"

const DashedDraggable = styled.div<{ $draggable: boolean }>`
    ${({ $draggable }) => $draggable && "border: 2px dashed #000;"}
`

const signalMapping = {
	[TRUCK]: (color) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path
				fill={color}
				d="M18 18.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5m1.5-9l1.96 2.5H17V9.5m-11 9A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5M20 8h-3V4H3c-1.11 0-2 .89-2 2v11h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4Z"
			/>
		</svg>
	),
	[GRUE]: (color) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g
				fill="none"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			>
				<path d="M2 17a2 2 0 1 0 4 0a2 2 0 1 0-4 0m9 0a2 2 0 1 0 4 0a2 2 0 1 0-4 0m2 2H4m0-4h9" />
				<path d="M8 12V7h2a3 3 0 0 1 3 3v5" />
				<path d="M5 15v-2a1 1 0 0 1 1-1h7m8.12-2.12L18 5l-5 5m8.12-.12A3 3 0 0 1 19 15a3 3 0 0 1-2.12-.88l4.24-4.24z" />
			</g>
		</svg>
	),
	[DANGER]: (color) => <StyledReportProblemIcon color={color} />,
	[INTERDICTION]: (color) => <StyledDoDisturbOnIcon color={color} />,
	[BARRIERE]: (color) => <StyledCalendarViewWeekIcon color={color} />,
	[PARKING]: (color) => <StyledLocalParkingIcon color={color} />,
	[EVACUATION_ZONE]: (color) => <StyledFollowTheSignsIcon color={color} />,
	[MEETING_POINT]: (color) => <StyledZoomInMapIcon color={color} />,
	[ARROW_LEFT]: (color) => <StyledSouthEastIcon color={color} />,
	[ARROW_RIGHT]: (color) => <StyledSouthWestIcon color={color} />,
	[ARROW_UP]: (color) => <StyledNorthIcon color={color} />,
	[ARROW_DOWN]: (color) => <StyledSouthIcon color={color} />,
	[ARROW_DOWN_LEFT]: (color) => <StyledNorthEastIcon color={color} />,
	[ARROW_DOWN_RIGHT]: (color) => <StyledNorthWestIcon color={color} />,
	[ARROW_UP_LEFT]: (color) => <StyledEastIcon color={color} />,
	[ARROW_UP_RIGHT]: (color) => <StyledWestIcon color={color} />,
	[PEOPLE_GROUP]: (color) => <StyledGroupsIcon color={color} />,
	[ANIMALS]: (color) => <StyledPetsIcon color={color} />,
	[ALERT]: (color) => <StyledCampaignIcon color={color} />,
	[RESCUE]: (color) => <StyledHealthAndSafetyIcon color={color} />,
	[REPAIR]: (color) => <StyledBuildCircleIcon color={color} />,
	[POWERED_NETWORKS]: (color) => <StyledFlashOnIcon color={color} />,
	[STOPPED_NETWORKS]: (color) => <StyledFlashOffIcon color={color} />,
	[FIRE]: (color) => <StyledLocalFireDepartmentIcon color={color} />,
	[FLOODS]: (color) => <StyledWaterIcon color={color} />,
	[DEBRIS]: (color) => <StyledAirIcon color={color} />,
	[BLACK_ICE]: (color) => <StyledAcUnitIcon color={color} />,
	[SQUARE]: (color) => <StyledCropSquareIcon color={color} />,
	[TRIANGLE]: (color) => <StyledTriangle color={color} />,
	[FIRETRUCK]: (color) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			width="24"
			height="24"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 24 24"
		>
			<path
				fill={color}
				d="m22.9 10.69l-1.44-4.32A2.01 2.01 0 0 0 19.56 5H19V4c0-.55-.45-1-1-1h-1c-.55 0-1 .45-1 1v1h-2c-1.1 0-2 .9-2 2v4H1v5c0 1.1.9 2 2 2h1c0 1.66 1.34 3 3 3s3-1.34 3-3h4c0 1.66 1.34 3 3 3s3-1.34 3-3h3v-6.68c0-.21-.03-.42-.1-.63zM7 19c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm10 0c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm-3-8V7h5.56l1.33 4H14z"
			/>
			<path
				fill={color}
				d="M11 8.5h-1v-2h1V5H1v1.5h1v2H1V10h10V8.5zm-5.75 0H3.5v-2h1.75v2zm3.25 0H6.75v-2H8.5v2z"
			/>
		</svg>
	),
	[AMBULANCE]: (color) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			width="24"
			height="24"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 24 24"
		>
			<path
				fill={color}
				d="M18 18.5a1.5 1.5 0 0 0 1.5-1.5a1.5 1.5 0 0 0-1.5-1.5a1.5 1.5 0 0 0-1.5 1.5a1.5 1.5 0 0 0 1.5 1.5m1.5-9H17V12h4.46L19.5 9.5M6 18.5A1.5 1.5 0 0 0 7.5 17A1.5 1.5 0 0 0 6 15.5A1.5 1.5 0 0 0 4.5 17A1.5 1.5 0 0 0 6 18.5M20 8l3 4v5h-2a3 3 0 0 1-3 3a3 3 0 0 1-3-3H9a3 3 0 0 1-3 3a3 3 0 0 1-3-3H1V6c0-1.11.89-2 2-2h14v4h3M8 6v3H5v2h3v3h2v-3h3V9h-3V6H8Z"
			/>
		</svg>
	),
	[POLICE_CAR]: (color) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			width="24"
			height="24"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 24 24"
		>
			<path
				fill={color}
				d="M11 0v3h2V0h-2M7.88 1.46L6.46 2.87L8.59 5L10 3.58L7.88 1.46m8.24 0L14 3.58L15.41 5l2.13-2.12l-1.42-1.42M12 5a2 2 0 0 0-2 2v1H6.5c-.66 0-1.22.42-1.42 1L3 15v8a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h12v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-8l-2.08-6c-.2-.58-.76-1-1.42-1H14V7a2 2 0 0 0-2-2M6.5 9.5h11L19 14H5l1.5-4.5m0 6.5A1.5 1.5 0 0 1 8 17.5A1.5 1.5 0 0 1 6.5 19A1.5 1.5 0 0 1 5 17.5A1.5 1.5 0 0 1 6.5 16m11 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5Z"
			/>
		</svg>
	),
	[POLICE]: (color) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			role="img"
			width="24"
			height="24"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 15 15"
		>
			<path
				fill={color}
				d="M5.5 1L6 2h5l.5-1h-6zM6 2.5v1.25S6 6.5 8.5 6.5S11 3.75 11 3.75V2.5H6zM1.984 3.986A1 1 0 0 0 1 5v4a1 1 0 0 0 1.217.977L5 9.357V14l5.879-6.93a1.58 1.58 0 0 0-.438-.07H6.5L3 7.754V5a1 1 0 0 0-1.016-1.014zm9.764 3.725L6.412 14H12V8.559c0-.314-.094-.604-.252-.848z"
			/>
		</svg>
	),
}

const signalCategory = {
	Direction: [
		ARROW_DOWN_RIGHT,
		ARROW_UP,
		ARROW_DOWN_LEFT,
		ARROW_UP_RIGHT,
		ARROW_RIGHT,
		ARROW_DOWN,
		ARROW_LEFT,
		ARROW_UP_LEFT,
	],
	Signalétique: [
		DANGER,
		INTERDICTION,
		BARRIERE,
		PARKING,
		EVACUATION_ZONE,
		MEETING_POINT,
		SQUARE,
		TRIANGLE,
	],
	Population: [PEOPLE_GROUP, ANIMALS],
	Intervention: [
		ALERT,
		RESCUE,
		REPAIR,
		AMBULANCE,
		FIRETRUCK,
		POLICE_CAR,
		POLICE,
		GRUE,
		TRUCK,
	],
	Danger: [POWERED_NETWORKS, STOPPED_NETWORKS, FIRE, FLOODS, DEBRIS, BLACK_ICE],
}

const buildIcon = ({ color, type, id = undefined, draggable = false }) =>
	L.divIcon({
		html: ReactDOMServer.renderToString(
			<DashedDraggable $draggable={draggable}>
				{signalMapping[type]?.(color)}
			</DashedDraggable>,
		),
		/* ? we are adding color and type to save it in database to rebuild the icon later*/
		// @ts-ignore
		color,
		type,
		id,
		iconSize: [24, 40],
		iconAnchor: [12, 20],
	})

export default {
	buildIcon,
	signalMapping,
	signalCategory,
}
