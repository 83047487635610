export enum LOCAL_STATUS {
	CREATED = "CREATED",
	UPDATED = "UPDATED",
	DELETED = "DELETED",
}

export interface ILocalDiffElement {
	path: string[]
	value: any
	oldValue: any
}

interface ILocal {
	localStatus?: LOCAL_STATUS
	localModifications?: ILocalDiffElement[]
	updated_at?: string
}

export default ILocal
