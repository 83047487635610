import _ from "lodash"
import React, { useMemo, useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import TypeResponsables from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import Moment from "moment"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import ModalSuiviElement from "./ModalSuiviElement"
import { Chip } from "@mui/material"
import { StyledChip } from "@/utils/components/style/chip"
import Button from "@/styles/atoms/Button"
import moment from "moment"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"

export const NextSuiviDateField = ({ getValue }) => {
	const value = getValue()
	if (_.isEmpty(value)) {
		return <Chip variant="outlined" label="Pas de date programmée" />
	} else {
		return Moment(value).format("DD/MM/YYYY HH:mm")
	}
}

const FieldSuivi = ({ isNew, name, defaultResponsableId, disabled = false }) => {
	const { control } = useFormContext()
	const { fields, append, remove, update, prepend } = useFieldArray({
		control,
		name,
	})
	const permissions = useAccessRights(EnumToolPath.SUIVI_PCS)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [initialValues, setInitialValues] = useState({})
	const [initialIndex, setInitialIndex] = useState(undefined)

	const columns = useMemo(
		() => [
			{
				header: "Statut",
				accessorKey: "Statut",
				cell: ({ row }) => {
					const statut = row.original["Statut"]
					const suiviDate = row.original["Date prise de contact"]
					const colorPpt = statut === "À faire"
						? moment().isAfter(suiviDate)
							? "var(--red)"
							: "var(--orange)"
						: statut === "En cours"
							? moment().isAfter(suiviDate)
								? "var(--red)"
								: "var(--yellow)"
							: "var(--green)"
					return (
						<StyledChip
							label={statut}
							$colorProperty={colorPpt}
							variant="outlined"
						/>
					)
				},
			},
			{
				header: "Date",
				accessorKey: "Date prise de contact",
				cell: NextSuiviDateField,
			},
			{
				header: "Action",
				accessorKey: "Suites à donner", // String-based value accessors!
			},
			{
				header: "Référent",
				accessorKey: "responsableId",
				cell: ({ row }) => {
					return (
						<TypeResponsables
							editable={true}
							align="left"
							value={row.original.responsableId}
						/>
					)
				},
			},
		],
		[],
	)

	return (
		<>
			<SuperReactTable
				data={fields}
				columns={columns}
				actionsButtons={
					<Button
						color="primary"
						onClick={() => {
							setInitialIndex(undefined)
							setInitialValues({
								responsableId: defaultResponsableId ?? null,
								"Date prise de contact": Moment().format(),
							})
							setIsModalOpen(true)
						}}
						disabled={disabled}
					>
						Ajouter un suivi
					</Button>
				}
				writePermission={!disabled}
				isEditable
				onEditClick={(original: any) => {
					const currentInitialIndex = fields.findIndex((field) => {
						return field.id === original.id
					})
					setInitialIndex(currentInitialIndex)
					setInitialValues(original)
					setIsModalOpen(true)
				}}
				isDeletable={!disabled && permissions.canDeleteDatas || isNew}
				onDeleteClick={(original) => {
					remove(original)
				}}
				initialSortBy={[
					{
						desc: true,
						id: "Date prise de contact",
					},
				]}
			/>
			<ModalSuiviElement
				isNew={initialIndex === undefined}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(!isModalOpen)}
				suiviElement={initialValues}
				fields={fields}
				onSubmit={(data) => {
					if (initialIndex !== undefined) {
						update(initialIndex, data)
					} else {
						prepend(data)
					}
					setIsModalOpen(false)
				}}
				disabled={disabled}
			/>
		</>
	)
}
export default FieldSuivi
