import Modal from "@/styles/organisms/Modal"
import React from "react"

import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import Button from "@/styles/atoms/Button"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const ConfirmationModal = ({
	modalShown,
	onClose,
	onConfirm,
	onConfirmName = "Confirmer",
	secondConfirmChoice = undefined,
	secondConfirmChoiceName = "Seconde Action",
	message,
}) => {
	return (
		<Modal isOpen={modalShown} onClose={onClose}>
			<ModalContent>
				<ModalHeader>Confirmation</ModalHeader>
				<ModalBody>{message}</ModalBody>
				<ModalFooter>
					<CancelButton onClick={onClose} />
					{secondConfirmChoice && (
						<Button
							color="primary"
							variant="bordered"
							onClick={secondConfirmChoice}
						>
							{secondConfirmChoiceName}
						</Button>
					)}
					<Button color="primary" onClick={onConfirm}>
						{onConfirmName}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ConfirmationModal
