import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Chip } from "@mui/material"
import ActionCellules from "@/pages/customdata/fiches/subAction/ActionCellules"
import useAccessRights from "@/hooks/useAccessRights"
import useOrga from "@/hooks/useOrga"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import { setCurrentIncident } from "@/redux-toolkit/edit/reducer"
import {
	INCIDENT,
	INTERVENTION,
	PATH_MAIN_COURANTE_INCIDENT,
} from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import HumanService from "@/utils/services/HumanService"
import { useDispatch, useSelector } from "react-redux"
import TypeResponsables from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import {
	PrimaryButton,
	PrimaryTextButton,
} from "@/utils/components/style/button"
import { RedChip, StyledChip } from "@/utils/components/style/chip"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import {
	width100,
	width80,
	widthCustom,
} from "@/utils/components/tables/widthProps"
import FCellules from "@/utils/form/FCellules"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import CsvService from "@/utils/services/CsvService"
import Filter from "./filter/Filter"
import useFilterForm, { NO_FILTER } from "./filter/useFilterForm"
import TableIncidentSubRow from "./TableIncidentSubRow"
import { sortByDate } from "@/utils/sort"
import moment from "moment"
import ReponsesTooltip from "@/utils/components/tooltip/ReponsesTooltip"
import { ColumnDef } from "@tanstack/react-table"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { Kbd } from "@nextui-org/react"
import { isMobile } from "react-device-detect"

export const renderIncidentStatus = (
	incident: "En cours" | "Terminé",
	small = false,
) => {
	if (incident === "En cours") {
		return (
			<StyledChip
				variant="outlined"
				label="En cours"
				$colorProperty="var(--orange500)"
				size={small ? "small" : "medium"}
			/>
		)
	} else {
		return (
			<StyledChip
				variant="outlined"
				label="Terminé"
				$colorProperty="var(--green500)"
				size={small ? "small" : "medium"}
			/>
		)
	}
}

export const convert = (html: string) => {
	return html.replace(/<[^>]*>?/gm, "").replace(/&#x27;/gm, "'")
}

const filterOptions = [
	{
		name: "Cellules",
		component: FCellules,
		default: [],
	},
	{
		name: "Priorité",
		items: ["Normale", "Urgente"],
		default: NO_FILTER
	},
	{
		name: "Statut",
		items: ["En cours", "Terminé"],
		default: NO_FILTER
	},
	{
		name: "Type",
		items: ["Décision", "Information", "Accueil mairie"],
		default: NO_FILTER
	},
]

const TableIncident = ({ tableProps = {}, customFilter = undefined }) => {
	const points = usePoints()
	const [expandedRowId, setExpandedRowId] = useState(undefined)
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const cellules = useOrga()
	const currentIncident = useSelector(
		(state: any) => state.edit.currentIncident,
	)
	const [isPressed] = useKeyboardJs("ctrl + space")
	const isLocked = selectedEvent?.locked

	useEffect(() => {
		if (isPressed && !currentIncident) {
			dispatch(setCurrentIncident({}))
		}
	}, [isPressed])

	const interventions = Object.values(points).filter(
		(point) =>
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === INTERVENTION,
	)

	const { values, ...filterConfig } = useFilterForm({
		options: filterOptions,
	})

	const permissions = useAccessRights(EnumToolPath.INCIDENT)

	let concernedPoints = Object.values(points)
		.filter((point) => point.event_id === selectedEvent?.id)
		.filter((point) => point.jsonschema_id === INCIDENT)

	if (customFilter) {
		concernedPoints = concernedPoints.filter(customFilter)
	}

	const interventionPoints = Object.values(points)
		.filter((point) => point.event_id === selectedEvent?.id)
		.filter((point) => point.jsonschema_id === INTERVENTION)
	const columns = useMemo(
		() =>
			[
				{
					header: "Date",
					accessorKey: "geojson.properties.Date de début",
					cell: ({ row }) => {
						const value = row.original.geojson.properties
						return <>{Moment(value["Date de début"]).format("DD/MM HH:mm")}</>
					},
					...widthCustom(90),
				},
				{
					header: "Priorité",
					accessorKey: "geojson.properties.Priorité",
					cell: ({ getValue }) => {
						if (getValue() === "Urgente") {
							return <RedChip label="Urgente" />
						}
						return <Chip label="Normale" />
					},
					...widthCustom(90),
				},
				{
					header: "Statut",
					accessorKey: "geojson.properties.Statut",
					cell: ({ getValue }) => {
						const value = getValue()
						return renderIncidentStatus(value)
					},
					...widthCustom(90),
				},
				{
					header: "Type",
					accessorKey: "geojson.properties.Type",
					...widthCustom(130),
					cell: ({ getValue }) => {
						return <Chip label={getValue()} />
					},
				},
				{
					header: "Objet",
					accessorKey: "geojson.properties.Objet",
				},
				{
					header: "Points de situation",
					accessorKey: "geojson.properties.Réponses",
					cell: ({ getValue, row }) => {
						const value = getValue()
						if (_.isEmpty(value)) {
							return null
						}

						const noOfReponses = value?.filter(
							(reponse) =>
								reponse.objet !== "" || reponse.date !== "Invalid date",
						)?.length

						return (
							<Flex fullWidth justifyCenter>
								{noOfReponses > 0 && (
									<ReponsesTooltip data={row.original.geojson?.properties}>
										<Chip label={String(noOfReponses)} />
									</ReponsesTooltip>
								)}
							</Flex>
						)
					},
					enableSorting: false,
					...width80,
				},
				{
					header: "Cellules et Responsables",
					accessorKey: "geojson.properties",
					cell: ({ getValue }) => {
						const value = getValue()
						return (
							<Flex directionColumn alignItemsStart gap="0.5rem">
								<ActionCellules
									withCrisisOrga
									celluleIds={value["Cellules"]}
									customWrapper={(props) => (
										<Flex directionColumn alignItemsStart {...props} />
									)}
								/>
								<TypeResponsables
									value={value["Responsable"]}
									editable={false}
									align="left"
									isSmall
									directionColumn
								/>
							</Flex>
						)
					},
				},
				{
					header: "Interventions",
					accessorKey: "Interventions",
					cell: ({ row, table: { toggleAllRowsExpanded } }) => {
						const isExpanded = row.getIsExpanded()
						if (
							!isExpanded &&
							expandedRowId === row.original.id &&
							expandedRowId
						) {
							row.toggleExpanded()
						}

						const numberOfInterventions = interventionPoints.filter(
							(intervention) =>
								intervention.geojson.properties["En lien avec"] ===
								row.original.id,
						).length
						if (numberOfInterventions === 0) {
							return <div />
						}
						return (
							<PrimaryTextButton
								endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
								onClick={() => {
									if (isExpanded) {
										setExpandedRowId(undefined)
										row.toggleExpanded()
										return
									}
									toggleAllRowsExpanded(false)
									setExpandedRowId(row.original.id)
									row.toggleExpanded()
								}}
							>
								{numberOfInterventions}
							</PrimaryTextButton>
						)
					},
					enableSorting: false,
					...width100,
					meta: {
						expandableCell: true,
					},
				},
			] as ColumnDef<any, any>[],
		[points, expandedRowId],
	)

	const tmpFilteredPoints = concernedPoints
		.filter((point) => {
			const priority = values["Priorité"]
			if (priority === NO_FILTER) return true

			return point.geojson.properties.Priorité === priority
		})
		.filter((point) => {
			const status = values["Statut"]
			if (status === NO_FILTER) return true

			return point.geojson.properties.Statut === status
		})
		.filter((point) => {
			const type = values["Type"]
			if (type === NO_FILTER) return true

			return point.geojson.properties.Type === type
		})
		.filter((point) => {
			const cellules = values["Cellules"]
			if (cellules.length === 0) return true
			return (point.geojson.properties?.Cellules ?? []).some((cellule) =>
				cellules.includes(cellule),
			)
		})

	const filteredPoints = sortByDate(
		tmpFilteredPoints,
		"geojson.properties.Date de début",
	)

	const convertToCsv = useMemo(() => {
		return (selected: string[]) => {
			let csvData = []
			filteredPoints
				.filter((incident) =>
					selected !== null ? selected.includes(incident["id"]) : true,
				)
				.forEach((incident) => {
					let csvIntervention = []
					const incidentInterventions = interventions.filter((intervention) => {
						return (
							intervention.geojson.properties?.["En lien avec"] === incident.id
						)
					})
					incidentInterventions.forEach(({ id: interventionId }) => {
						const intervention = points[interventionId]
						if (!intervention) {
							return
						}
						const interventionResponse =
							intervention.geojson.properties?.Réponses
						const csvInterventionResponses = []
						if (interventionResponse) {
							interventionResponse.forEach((response) =>
								csvInterventionResponses.push([
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									"",
									`=""${response.objet}""`,
									`=""${moment(response.date).format("DD/MM/YYYY HH:mm")}""`,
								]),
							)
						}
						csvIntervention = [
							...csvIntervention,
							[
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								"",
								`=""${intervention.geojson.properties?.Objet ?? ""}""`,
								`=""${intervention.geojson.properties?.Statut ?? ""}""`,
								CsvService.formatDate(
									intervention.geojson.properties?.["Date de début"],
								),
								CsvService.formatDate(
									intervention.geojson.properties?.["Date de fin"],
								),
							],
							...csvInterventionResponses,
						]
					})

					const csvRéponses = []
					incident.geojson.properties?.Réponses?.forEach((reponse) => {
						csvRéponses.push([
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							`=""${convert(reponse.objet)}""`,
							`=""${moment(reponse.date).format("DD/MM/YYYY HH:mm")}""`,
						])
					})
					csvData = [
						...csvData,
						[
							`=""${incident.geojson.properties?.Type ?? ""}""`,
							`=""${incident.geojson.properties?.Objet ?? ""}""`,
							`=""${incident.geojson.properties?.Priorité ?? ""}""`,
							`=""${incident.geojson.properties?.Statut ?? ""}""`,
							`=""${(incident.geojson.properties?.Cellules ?? [])
								.map((cellId) => {
									return cellules?.[cellId]?.idName ?? ""
								})
								.join(" / ")}""`,
							`=""${(incident.geojson.properties?.Responsable ?? [])
								.map((respId) => {
									return HumanService.displayFullName(points?.[respId])
								})
								.join(" / ")}""`,
							CsvService.formatDate(
								incident.geojson.properties?.["Date de début"],
							),
							CsvService.formatDate(
								incident.geojson.properties?.["Date de fin"],
							),
							`=""${incident.geojson.properties.Contact?.Identité ?? ""}""`,
							`=""${incident.geojson.properties.Contact?.Téléphone ?? ""}""`,
							`=""${incident.geojson.properties.Contact?.Courriel ?? ""}""`,
							`=""${incident.geojson.properties?.Géolocalisation?.Adresse ?? ""
							}""`,
							`=""${incident.geojson.properties.Géolocalisation?.coo?.lat ?? ""
							}""`,
							`=""${incident.geojson.properties.Géolocalisation?.coo?.lng ?? ""
							}""`,
							`=""${convert(incident.geojson.properties.Détails) ?? ""}""`,
						],
						...csvRéponses,
						...csvIntervention,
					]
				})
			return {
				data: csvData,
				headers: [
					"Type",
					"Objet",
					"Priorité",
					"Statut",
					"Cellules",
					"Responsable",
					"Date de début",
					"Date de fin",
					"Fiche contact : Identité",
					"Fiche contact : Téléphone",
					"Fiche contact : Courriel",
					"Adresse",
					"Latitude",
					"Longitude",
					"Détails",
					"Point de situation",
					"Date de point de situation",
					"Interventions : Objet",
					"Interventions : Statut",
					"Interventions : Date de début",
					"Interventions : Date de fin",
					"Interventions : Point de situation",
					"Interventions : Date de point de situation",
				],
				filename: selectedEvent?.name + "_évènements.csv",
			}
		}
	}, [filteredPoints, cellules, points])

	return (
		<>
			<SuperReactTable
				onEditClick={(incident) => dispatch(setCurrentIncident(incident))}
				actionsButtons={
					<>
						<Filter {...filterConfig} />
						{permissions.write && (
							<LightTooltip
								placement="top"
								title={
									<>
										Raccourci : <Kbd>Ctrl + Espace</Kbd>
									</>
								}
							>
								<Button
									className={isMobile ? "w-full" : ""}
									color="primary"
									onClick={() => dispatch(setCurrentIncident({}))}
									isDisabled={isLocked}
								>
									Ajouter un évènement
								</Button>
							</LightTooltip>
						)}
					</>
				}
				onDeleteClick={(incident) => {
					dispatch(
						deletePointWithUpdateEvent({
							point: incident,
							event: {
								...selectedEvent,
								history: [
									...selectedEvent?.history,
									{
										type: INCIDENT,
										title: "Suppression d'évènement",
										date: Moment().format(),
										noAction: true,
									},
								],
							},
						}),
					)
				}}
				additionalLineNumber={1}
				data={filteredPoints}
				columns={columns}
				writePermission={permissions.write}
				isEditable
				isDeletable={permissions.canDeleteDatas}
				isLocked={isLocked}
				convertToCsv={convertToCsv}
				RenderSubRow={({ row }) => (
					<TableIncidentSubRow incident={row.original} type="DIRECT" />
				)}
				autoResetPage={false}
				{...tableProps}
			/>
		</>
	)
}

export default TableIncident
