import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

const dicrimSlice = createSlice({
	name: "data/dicrims",
	initialState,
	reducers: {
		addDicrim(state, { payload: dicrim }) {
			state[dicrim.id] = dicrim
		},
		setDicrims(state, { payload: dicrims }) {
			Object.keys(state).forEach((id) => {
				delete state[id]
			})
			dicrims.forEach((dicrim) => (state[dicrim.id] = dicrim))
		},
	},
})

export default dicrimSlice.reducer
export const { setDicrims, addDicrim } = dicrimSlice.actions
