/* eslint-disable react/no-unescaped-entities */
import { CardContent } from "@mui/material"
import { useCurrentPng } from "@/hooks/useCurrentPng"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { INCIDENT } from "@/redux-toolkit/userSettings/constants"
import React, { useEffect, useMemo } from "react"
import { Pie, PieChart, Tooltip } from "recharts"
import styled from "styled-components"
import { Card } from "@/utils/components/style/card"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import { Legend } from "@/styles/atoms/Legend/Legend"

export const PieContainer = styled.div`
    flex: 0 0 25% !important;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: left;

    flex-direction: column;

    @media (max-width: 1600px) {
        flex: 0 0 50% !important;
    }

    @media (max-width: 600px) {
        flex: 0 0 100% !important;
    }
`
export const SubPieContainer = styled(Card)`
    margin: 0.5rem;
    padding: 0.5rem;
`
const PieChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.4em 0.2em;
	gap: 0.5rem;
`
const LowPaddingCardContent = styled(CardContent)`
    padding: 0 !important;
`

const DaybookPieStatus = ({ setEventStatusBlob, enableAnimation = true }) => {
	const selectedEvent = useSelectedEvent()
	const points = usePoints()
	const [getPng, { ref: pieRef, isLoading, ready, setRef }] = useCurrentPng(
		"pie",
		setEventStatusBlob,
	)

	const concernedPoints = Object.values(points).filter(
		(point) =>
			point.jsonschema_id === INCIDENT && point.event_id === selectedEvent?.id,
	)
	const pieData = useMemo(() => {
		const newData = [
			{
				name: "En cours",
				value: 0,
				fill: "orange",
			},
			{
				name: "Terminé",
				value: 0,
				fill: "green",
			},
		]
		concernedPoints.forEach((point) => {
			if (point.geojson.properties?.["Statut"] === "En cours") {
				newData[0].value += 1
			} else {
				newData[1].value += 1
			}
			return
		})

		return newData.filter((row) => row.value > 0)
	}, [concernedPoints])

	return (
		<PieContainer>
			<SubPieContainer>
				<H6>Evènement par statut</H6>
				<Flex gap="1rem" justifyCenter>
					<LowPaddingCardContent>
						<PieChartWrapper>
							<PieChart width={100} height={100} ref={pieRef}>
								<Pie
									isAnimationActive={enableAnimation}
									data={pieData}
									dataKey="value"
									innerRadius={20}
									outerRadius={30}
								/>
								<Tooltip />
								<text
									x={50}
									y={50}
									textAnchor="middle"
									dominantBaseline="middle"
								>
									{concernedPoints.length}
								</text>
							</PieChart>
						</PieChartWrapper>
					</LowPaddingCardContent>
					<LowPaddingCardContent>
						<Wrapper>
							<Legend color="orange" />
							En cours
						</Wrapper>
						<Wrapper>
							<Legend color="green" />
							Terminé
						</Wrapper>
					</LowPaddingCardContent>
				</Flex>
			</SubPieContainer>
		</PieContainer>
	)
}

export default DaybookPieStatus
