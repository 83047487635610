import React from "react"
import FCheckboxes from "@/utils/form/FCheckboxes"
import FCreatableSelectJsonSchema from "@/utils/form/FCreatableSelect/specific/FCreatableSelectJsonSchema"
import FDate from "@/utils/form/FDate"
import FGeoloc from "@/utils/form/FGeoloc"
import FPhone from "@/utils/form/FPhone"
import FRadios from "@/utils/form/FRadios"
import FSelect from "@/utils/form/FSelect"
import FSelectHumans from "@/utils/form/FSelectHumans"
import FSelectWithImage from "@/utils/form/FSelectWithImage"
import FStockPlaceAndCare from "@/utils/form/FStockPlaceAndCare"
import FTags from "@/utils/form/FTags"
import FText from "@/utils/form/FText"
import FTextArea from "@/utils/form/FTextArea"
import FTime from "@/utils/form/FTime"
import IJsonSchema, {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"

export type FStandardProps = {
	name: string
	label?: string
	disabled?: boolean
	readOnly?: boolean
	jsonSchema?: IJsonSchema
	required?: boolean
	isPublic?: boolean
	options?:
	| string[]
	| {
		label: string
		imgId: string
	}[]
	| any[]
	autoFocus?: boolean
}

const getPropertyComponent = (type: JsonSchemaPropertiesType) => {
	switch (type) {
		case JsonSchemaPropertiesType.TEXT:
		case JsonSchemaPropertiesType.NUMBER:
			return FText
		case JsonSchemaPropertiesType.TEXTAREA:
			return FTextArea
		case JsonSchemaPropertiesType.DATE:
			return FDate
		case JsonSchemaPropertiesType.TIME:
			return FTime
		case JsonSchemaPropertiesType.SELECT:
			return FSelect
		case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
			return FSelectWithImage
		case JsonSchemaPropertiesType.RESPONSABLES:
			return FSelectHumans
		case JsonSchemaPropertiesType.GEOLOC:
			return FGeoloc
		case JsonSchemaPropertiesType.PHONE:
			return FPhone
		case JsonSchemaPropertiesType.CHECKBOX:
			return FCheckboxes
		case JsonSchemaPropertiesType.RADIO:
			return FRadios
		case JsonSchemaPropertiesType.STOCKPLACE:
			return FStockPlaceAndCare
		case JsonSchemaPropertiesType.TAGS:
			return FTags
		case JsonSchemaPropertiesType.CREATABLE_SELECT:
			return FCreatableSelectJsonSchema
	}
}

const FPointProperty = ({
	name,
	property,
	disabled = false,
	readOnly = false,
	jsonSchema,
	...props
}: {
	property: IJsonSchemaProperty
} & FStandardProps) => {
	const Component = getPropertyComponent(property.type)
	const isPicrimPage = window.location.pathname.includes("/picrim/")

	if (isPicrimPage && props.isPublic && !property.isPublic) return null

	return (
		<Component
			name={name}
			label={`${property.label}${property.isRequired ? " (champ obligatoire)" : ""
				}`}
			disabled={disabled || property.isReadOnly}
			readOnly={readOnly}
			{...(property.items && { options: property.items })}
			{...(property.itemsImage && {
				options: property.itemsImage,
				placeholder: "Ajouter un(e) " + property.label.toLowerCase(),
				isMulti: property.options?.isMulti,
			})}
			{...(property.tags && { tags: property.tags })}
			jsonSchema={jsonSchema}
			{...props}
		/>
	)
}

export default FPointProperty
