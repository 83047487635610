import { useEventHandlers } from "@react-leaflet/core"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { MapContainer, Rectangle, useMap, useMapEvent } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import SuperControl from "utils/components/map/SuperControl"
import TileLayerService from "utils/services/TileLayerService"
import { ScreenShotContext } from "./DaybookDashboard"
import { toggleChangingCarto } from "@/redux-toolkit/common/reducer"
import useMapState from "@/hooks/useMapState"

const StyledMapContainer = styled(MapContainer)`
    transition: all 0.3s;
    height: 80px !important;
    width: 80px !important;
    border: 1px solid #8d8d8d;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    &:hover {
        cursor: pointer;
        opacity: 0.6;
        background-color: #fff;
    }
` as any
const StyledMapContainer2 = styled(MapContainer)`
    transition: all 0.3s;
    height: 140px !important;
    width: 140px !important;
    border: 1px solid #8d8d8d;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

    &:hover {
        cursor: pointer;
        opacity: 0.6;
        background-color: #fff;
    }
` as any
const BOUNDS_STYLE = { weight: 1 }

const Container = styled(SuperControl)`
    display: flex;
    flex-direction: column-reverse;
    margin: 1rem;
    gap: 1rem;
    width: fit-content;
    max-height: calc(100vh - 80px);
    overflow: auto;
`

const BottomName = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #000000b0;
    padding: 0.5rem;
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    z-index: 1000;
`

const MinimapBounds = ({ parentMap, zoom }) => {
	const minimap = useMap()

	// Clicking a point on the minimap sets the parent's map center
	const onClick = useCallback((e) => {}, [parentMap])
	useMapEvent("click", onClick)

	// Keep track of bounds in state to trigger renders
	const [bounds, setBounds] = useState(parentMap.getBounds())
	useEffect(() => {
		if (parentMap && parentMap._isLoaded) {
			setBounds(parentMap.getBounds())
			const center = parentMap.getCenter()
			const zoom = parentMap.getZoom()
			console.log("center", center)
			console.log("zoom", zoom)
			//minimap.setView(parentMap.getCenter(), parentMap.getZoom() - 6);
		}
	}, [parentMap])
	const onChange = useCallback(() => {
		setBounds(parentMap.getBounds())
		// Update the minimap's view to match the parent map's center and zoom
		minimap.setView(parentMap.getCenter(), parentMap.getZoom() - 6)
	}, [minimap, parentMap, zoom])

	// Listen to events on the parent map
	const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
	// @ts-ignore
	useEventHandlers({ instance: parentMap }, handlers)

	return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}

const MinimapControl = ({ zoom = 8, selectedLayer }) => {
	const parentMap = useMap()
	const isChangingCarto = useSelector(
		(state: any) => state.common.isChangingCarto,
	)
	const dispatch = useDispatch()
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const { toggleSelectedLayer } = useMapState()

	const onClick = (e) => {
		dispatch(toggleChangingCarto())
		setTimeout(() => {
			MapService.enableScrollAndDrag()
		}, 500)
	}

	if (isTakingScreenshotMap) {
		return null
	}
	// Memoize the minimap so it's not affected by position changes
	const minimap = (
		<Container onClick={onClick} horizontal="left" vertical="bottom">
			{!isChangingCarto && (
				<StyledMapContainer
					center={parentMap.getCenter()}
					isChangingCouche={isChangingCarto}
					zoom={parentMap.getZoom() - 6}
					dragging={false}
					doubleClickZoom={false}
					scrollWheelZoom={false}
					attributionControl={false}
					zoomControl={false}
				>
					{TileLayerService.getAllLayers()[selectedLayer]}
					<MinimapBounds parentMap={parentMap} zoom={parentMap.getZoom() - 6} />
				</StyledMapContainer>
			)}
			{isChangingCarto && (
				<>
					{Object.entries(TileLayerService.getAllLayers()).map(
						([key, value]) => (
							<div
								key={key}
								onClick={() => {
									dispatch(toggleSelectedLayer(key))
								}}
							>
								<StyledMapContainer2
									isChangingCouche={isChangingCarto}
									center={parentMap.getCenter()}
									zoom={parentMap.getZoom() - 6}
									dragging={false}
									doubleClickZoom={false}
									scrollWheelZoom={false}
									attributionControl={false}
									zoomControl={false}
								>
									{value}
									<MinimapBounds
										parentMap={parentMap}
										zoom={parentMap.getZoom() - 6}
									/>
									<BottomName>{key}</BottomName>
								</StyledMapContainer2>
							</div>
						),
					)}
				</>
			)}
		</Container>
	)

	return minimap
}
export default MinimapControl
