import Close from "@mui/icons-material/Close"
import Delete from "@mui/icons-material/Delete"
import Extension from "@mui/icons-material/Extension"
import ExtensionOff from "@mui/icons-material/ExtensionOff"
import FilterAlt from "@mui/icons-material/FilterAlt"
import List from "@mui/icons-material/List"
import Palette from "@mui/icons-material/Palette"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityIconOff from "@mui/icons-material/VisibilityOff"
import FilterAltOff from "@mui/icons-material/FilterAltOff"
import {
	CardActions,
	IconButton,
	ListItemAvatar,
	ListItemText,
	Tooltip,
} from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMapState from "@/hooks/useMapState"
import { setPanel } from "@/redux-toolkit/common/reducer"
import React from "react"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import {
	DAYBOOK_LIST,
	FILTER_JSONSCHEMA,
	STYLE_EDITOR_POINT,
	STYLE_EDITOR_POLYGON,
} from "./PanelCarto"
import _ from "lodash"
import EditIcon from "@mui/icons-material/Edit"
import { StyledChip } from "utils/components/style/chip"
import { Subtitle } from "utils/components/style/text"
import { useBoolean } from "react-use"
import ModalEditCoucheInfos from "utils/modals/ModalEditCoucheInfos"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import ManageHistoryIcon from "@mui/icons-material/ManageHistory"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"

const SManageHistoryIcon = styled(ManageHistoryIcon)<{
	$currentFilter: string
}>`
	${({ $currentFilter }) =>
		$currentFilter &&
		css` color: 
			${
				$currentFilter === "A faire"
					? "var(--primary500)"
					: $currentFilter === "En cours"
					? "var(--orange500)"
					: $currentFilter === "Terminé"
					? "var(--green500)"
					: "none"
			} !important;
		`}
`
const SVisibility = styled(Visibility)`
    color: var(--primary500);
`
const SCardActions = styled(CardActions)`
    transition: all 0.5s ease-in-out;
    max-height: 0px;
    //overflow: hidden;
    padding: 0px !important;
    padding-left: 0.5rem !important;
    opacity: 0;
    & > * {
        transition: opacity 0.5s ease-in-out;
        opacity: 0 !important;
    }
`
const StyledCard = styled.div`
    background-color: white;
    box-shadow: var(--cool-shadow) !important;
    padding: 0px !important;
    margin: 1rem 1.5rem;
    &:hover {
        ${SCardActions} {
            
            transition all 0.3s ease-in-out;
            transition-delay: 0.2s;
            overflow: hidden;
            max-height: 100vh;
            padding-left: 0.5rem !important;
            opacity: 1;
            border-top: 1px solid var(--neutral300);
            & > * {
                opacity: 1 !important;
            }
        }
    }
`
const SDelete = styled(Delete)`
    color: var(--red500);
`
const SCloseIcon = styled(Close)`
    color: var(--red500);
`

const SIconButton = styled(IconButton)`
    padding: 0.5rem;
`
const DescriptionContainer = styled(Flex)`
    padding: 0.5rem;
`
const SExtension = styled(Extension)`
    color: var(--primary500);
`
const SFilterAlt = styled(FilterAlt)`
    color: var(--primary500) !important;
`
const SListItemAvatar = styled(ListItemAvatar)`
    display: flex;
    align-items: center;
`

const StyledListItemText = styled(ListItemText)`
    & .MuiListItemText-primary {
        /* font-weight: 425; */
    }
    .MuiListItemText-secondary {
        font-size: var(--font-size-small);
    }
`
function PanelLegendItem({
	avatar,
	title,
	subtitle = undefined,
	visible,
	deleteAction,
	editAction = undefined,
	onClick = undefined,
	onChangeFilter = undefined,
	jsonSchemaId = undefined,
	count = undefined,
	canDegroup = false,
	canEditStyle = false,
	canEditInfos = false,
	canRemove = false,
	canGoToList = false,
	canFilterByStatus = false,
	entityId = undefined,
	visibilityAction = undefined,
	canDelete = false,
}: {
	avatar: React.ReactNode
	title: string
	subtitle?: string
	visible: boolean
	deleteAction: any
	editAction?: any
	onClick?: () => void
	onChangeFilter?: () => void
	jsonSchemaId?: string
	count?: number
	canDegroup?: boolean
	canEditStyle?: boolean
	canEditInfos?: boolean
	canRemove?: boolean
	canGoToList?: boolean
	canFilterByStatus?: boolean
	entityId?: string
	visibilityAction?: () => void
	canDelete?: boolean
}) {
	const dispatch = useDispatch()
	const { coucheJsonSchemas, toggleMarkerCluster, addStyleJsonSchemas } =
		useMapState()
	const [isOpenEditInfos, setIsOpenEditInfos] = useBoolean(false)
	const isCluster = coucheJsonSchemas[jsonSchemaId]?.cluster
	const filter = coucheJsonSchemas[jsonSchemaId]?.activeFilter
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const clusterText = isCluster
		? "Dégrouper les éléments"
		: "Regrouper les éléments"
	const visibleText = visible ? "Masquer l'élément" : "Afficher l'élément"

	const canFilter = !_.isEmpty(
		JsonSchemaService.getFilterProperty(jsonSchemasWithStatics[jsonSchemaId]),
	)

	return (
		<>
			<StyledCard>
				<DescriptionContainer fullWidth spaceBetween>
					<Flex fullWidth>
						<SListItemAvatar>{avatar}</SListItemAvatar>
						<StyledListItemText
							primary={title}
							disableTypography
							secondary={
								<Subtitle>
									<Flex fullWidth spaceBetween>
										<div>{count ? `${count} éléments` : subtitle}</div>
										{canFilter && !_.isEmpty(filter) && (
											<StyledChip
												$colorProperty="var(--primary500)"
												size="small"
												variant="outlined"
												label={`Filtres actifs : (${
													_.isArray(filter) ? filter.length : 1
												})`}
												icon={<SFilterAlt />}
												onClick={() =>
													dispatch(
														setPanel({
															type: FILTER_JSONSCHEMA,
															jsonSchemaId: jsonSchemaId,
														}),
													)
												}
											/>
										)}
									</Flex>
								</Subtitle>
							}
						/>
					</Flex>
				</DescriptionContainer>
				<SCardActions>
					<Flex directionColumn fullWidth>
						<Flex spaceBetween fullWidth>
							<Flex>
								<Tooltip title={visibleText} arrow>
									<SIconButton
										onClick={() =>
											onClick ? onClick() : dispatch(visibilityAction)
										}
									>
										{visible ? (
											<SVisibility fontSize="small" />
										) : (
											<VisibilityIconOff fontSize="small" />
										)}
									</SIconButton>
								</Tooltip>
								{canDegroup && (
									<Tooltip title={clusterText} arrow>
										<IconButton
											onClick={() => {
												const cluster = coucheJsonSchemas[jsonSchemaId]?.cluster
												dispatch(toggleMarkerCluster(jsonSchemaId, !cluster))
											}}
										>
											{isCluster ? (
												<SExtension fontSize="small" />
											) : (
												<ExtensionOff fontSize="small" />
											)}
										</IconButton>
									</Tooltip>
								)}

								{canFilter && (
									<Tooltip title="Filtrer" arrow>
										<IconButton
											onClick={() => {
												dispatch(
													setPanel({
														type: FILTER_JSONSCHEMA,
														jsonSchemaId: jsonSchemaId,
													}),
												)
											}}
										>
											{_.isEmpty(filter) ? (
												<FilterAltOff fontSize="small" />
											) : (
												<SFilterAlt fontSize="small" />
											)}
										</IconButton>
									</Tooltip>
								)}
								{canEditStyle && (
									<Tooltip title="Modifier le style" arrow>
										<IconButton
											onClick={() => {
												if (editAction) {
													editAction()
												} else {
													if (jsonSchemaId) {
														dispatch(
															setPanel({
																type: STYLE_EDITOR_POINT,
																jsonSchemaId,
															}),
														)
													}
													if (entityId) {
														dispatch(
															setPanel({
																type: STYLE_EDITOR_POLYGON,
																entityId,
															}),
														)
													}
												}
											}}
										>
											<Palette fontSize="small" />
										</IconButton>
									</Tooltip>
								)}
								{canGoToList && (
									<Tooltip title="Afficher la liste" arrow>
										<IconButton
											onClick={(e) => {
												e.stopPropagation()
												dispatch(
													setPanel({
														type: DAYBOOK_LIST,
														jsonschema_id: jsonSchemaId,
													}),
												)
											}}
										>
											<List />
										</IconButton>
									</Tooltip>
								)}
								{canEditInfos && (
									<Tooltip title="Renommer la couche" arrow>
										<IconButton onClick={() => setIsOpenEditInfos(true)}>
											<EditIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								)}
								{canFilterByStatus && (
									<Tooltip title="Filtrer par statut" arrow>
										<IconButton
											onClick={(e) => {
												e.stopPropagation()
												onChangeFilter ? onChangeFilter() : null
											}}
										>
											<SManageHistoryIcon
												fontSize="small"
												$currentFilter={
													coucheJsonSchemas[jsonSchemaId].filterBy
												}
											/>
										</IconButton>
									</Tooltip>
								)}
							</Flex>
							{canRemove && (
								<Tooltip title="Retirer la couche" arrow>
									<IconButton onClick={() => dispatch(deleteAction)}>
										<SCloseIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
							{canDelete && (
								<Tooltip title="Supprimer la couche" arrow>
									<IconButton onClick={() => dispatch(deleteAction)}>
										<SDelete fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</Flex>
					</Flex>
				</SCardActions>
			</StyledCard>
			<ModalEditCoucheInfos
				isOpenEditInfos={isOpenEditInfos}
				setIsOpenEditInfos={setIsOpenEditInfos}
				multiPolygon={multiPolygons[entityId]}
			/>
		</>
	)
}

export default PanelLegendItem
