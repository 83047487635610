import { useSelector } from "react-redux"
import { createSelector } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { setOpenMenu } from "@/redux-toolkit/common/reducer"

const getAppBarState = createSelector(
	(state: any) => state.common.openMenu,
	(openMenu) => openMenu,
)

const useOpenAppBar = () => {
	const dispatch = useDispatch()

	const isOpenAppBar = useSelector(getAppBarState)

	const setOpenAppBar = (value: boolean) => {
		return dispatch(setOpenMenu(value))
	}

	return [isOpenAppBar, setOpenAppBar]
}

export default useOpenAppBar
