import {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import IPoint from "@/utils/types/IPoint"
import HumanService from "./HumanService"
import Moment from "moment"
import IMultiPolygon from "@/utils/types/IMultiPolygon"
import _ from "lodash"

const formatDate = (dateString) => {
	if (!dateString) {
		return ""
	}
	const date = Moment(dateString)
	return `=""${date.format("DD/MM/YYYY HH:mm")}""`
}

const pointPropertyToText = ({
	point,
	property,
	humans,
	stockPlaces,
}: {
	point: IPoint
	property: IJsonSchemaProperty
	humans: { [key: string]: IPoint }
	stockPlaces: { [key: string]: IPoint }
}): string => {
	const value = point?.geojson?.properties?.[property.name]
	switch (property.type) {
		case JsonSchemaPropertiesType.DATE:
		case JsonSchemaPropertiesType.CREATABLE_SELECT:
		case JsonSchemaPropertiesType.TIME:
		case JsonSchemaPropertiesType.TEXT:
		case JsonSchemaPropertiesType.TEXTAREA:
		case JsonSchemaPropertiesType.SELECT:
		case JsonSchemaPropertiesType.RADIO:
			return value ?? ""
		case JsonSchemaPropertiesType.GEOLOC:
			return value?.Adresse ?? ""
		case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
			if (_.isString(value)) return value
			if (_.isArray(value)) return value.join(" / ")
			return ""
		case JsonSchemaPropertiesType.PHONE:
			if (!_.isArray(value)) return ""
			return value.map((phone) => `${phone.Type} : ${phone.Numéro}`).join(" / ")
		case JsonSchemaPropertiesType.CHECKBOX:
		case JsonSchemaPropertiesType.TAGS:
			if (!_.isArray(value)) return ""
			return value.join(" / ")
		case JsonSchemaPropertiesType.STOCKPLACE:
			return stockPlaces[value]?.geojson?.properties?.Lieu ?? ""
		case JsonSchemaPropertiesType.RESPONSABLES:
			if (!_.isArray(value)) return ""
			return value
				.map((value) => HumanService.displayFullName(humans[value]))
				.join(" / ")
		default:
			return typeof value === "string" ? value : JSON.stringify(value)
	}
}

const propertyToCSV = ({
	point,
	property,
	humans,
	stockPlaces,
	multiPolygons,
}: {
	point: IPoint
	property: IJsonSchemaProperty
	humans: { [key: string]: IPoint }
	stockPlaces: { [key: string]: IPoint }
	multiPolygons: { [key: string]: IMultiPolygon }
}) => {
	if (property.type === JsonSchemaPropertiesType.GEOLOC) {
		const value = point?.geojson?.properties?.[property.name]
		if (!value || (!value.Adresse && !value.coo?.lat)) return

		let secteur = multiPolygons[value.secteur]?.name
		if (!secteur) {
			secteur = value.secteur ?? "Aucun secteur"
		}
		return {
			[property.label]: `=""${value.Adresse}""`,
			[`${property.label}#lat`]: `${value.coo?.lat}`,
			[`${property.label}#lng`]: `=""${value.coo?.lng}""`,
			[`${property.label}#sector`]: `=""${secteur}""`,
		}
	}

	let value = pointPropertyToText({
		point,
		property,
		humans,
		stockPlaces,
	})
	// replace all double quotes by single quotes
	value = value?.replace(/"/g, "'")

	return {
		[property.label]: value,
	}
}

const getPropertyHeader = (property) => {
	switch (property.type) {
		case JsonSchemaPropertiesType.GEOLOC:
			return [
				{ key: property.label, label: property.label },
				{ key: `${property.label}#lat`, label: "(latitude)" },
				{ key: `${property.label}#lng`, label: "(longitude)" },
				{ key: `${property.label}#sector`, label: "(secteur)" },
			]
		default:
			return [{ key: property.label, label: property.label }]
	}
}

const convertToCsvFactory =
	({
		humans,
		stockPlaces,
		title,
		properties,
		data,
		multiPolygons,
	}: {
		humans: { [key: string]: IPoint }
		stockPlaces: { [key: string]: IPoint }
		title: string
		properties: IJsonSchemaProperty[]
		data: IPoint[]
		multiPolygons: { [key: string]: IMultiPolygon }
	}) =>
		(selected: string[]) => {
			const headers = properties.reduce(
				(acc, prop) => [...acc, ...getPropertyHeader(prop)],
				[],
			)

			const filename = (title ?? "extraction") + ".csv"

			const selectedPoints = selected
				? data.filter((item) => selected.includes(item.id))
				: data

			if (selectedPoints.length === 0)
				return {
					headers: [{ label: "Aucune données", key: "" }],
					data: [],
					filename,
				}

			const csvData = selectedPoints.map((point) => {
				let cleanedPoint = {}
				properties.forEach((property) => {
					cleanedPoint = {
						...cleanedPoint,
						...propertyToCSV({
							point,
							property,
							humans,
							stockPlaces,
							multiPolygons,
						}),
					}
				})
				return cleanedPoint
			})

			return {
				headers,
				data: csvData,
				filename,
			}
		}

export default {
	pointPropertyToText,
	propertyToCSV,
	getPropertyHeader,
	convertToCsvFactory,
	formatDate,
}
