import { Autocomplete, createFilterOptions, TextField } from "@mui/material"
import React, { useState } from "react"
import { Path, useController, useFormContext } from "react-hook-form"
import { FStandardProps } from "utils/components/jsonSchema/form/FPointProperty"

const filter = createFilterOptions()

interface ISuggestion {
	title: string
	value: string
}

const FCreatableSelect = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
	suggestions,
}: FStandardProps & {
	suggestions: string[]
}) => {
	const { control } = useFormContext()
	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController<{ [key: string]: string }, Path<{ [key: string]: string }>>(
		{
			name,
			control,
			defaultValue: "",
		},
	)

	const suggestionsWithCreatableItem = [
		...suggestions,
		...(suggestions.includes(value) ? [] : [value]),
	].map((option) => ({
		title: option,
		value: option,
	})) as ISuggestion[]

	const [acMenuOpen, setAcMenuOpen] = useState(false)

	return (
		<Autocomplete
			fullWidth
			open={acMenuOpen}
			onOpen={() => setAcMenuOpen(readOnly ? false : true)}
			onClose={() => setAcMenuOpen(false)}
			onKeyDown={(event) => {
				if (event.key === "Backspace" && readOnly) {
					//@ts-ignore
					event.defaultMuiPrevented = true
				}
			}}
			value={{ title: value, value }}
			onChange={(event, newValue) => {
				// @ts-ignore
				if (newValue?.inputValue) {
					// @ts-ignore
					onChange(newValue.inputValue)
				} else {
					// @ts-ignore
					onChange(newValue?.value ?? "")
				}
			}}
			// @ts-ignore
			filterOptions={(options, params) => {
				let filtered = filter(options, params)

				const { inputValue } = params
				// Suggest the creation of a new value
				const isExisting = options.some((option) => inputValue === option.title)
				if (inputValue !== "" && !isExisting) {
					filtered = [
						{
							inputValue,
							title: `Ajouter "${inputValue}"`,
						},
						...filtered,
					]
				}

				return filtered
			}}
			options={suggestionsWithCreatableItem}
			getOptionLabel={(option: ISuggestion) => option.title}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={ref}
					label={label}
					error={Boolean(error?.message)}
					helperText={error?.message}
					InputProps={{ ...params.InputProps, readOnly }}
				/>
			)}
		/>
	)
}

export default FCreatableSelect
