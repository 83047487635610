import { FormControl, InputLabel } from "@mui/material"
import Debug from "debug"
import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"

const SFormControl = styled(FormControl)`
    border: 1px solid var(--neutral300) !important;
    width: 100% !important;
    border-radius: 3px;
    min-width: -webkit-fill-available !important;
    &:hover {
        ${({ $hasError, $isActive }: any) => {
					if ($isActive || $hasError) {
						return
					}
					return css`
                border: 1px solid var(--neutral900) !important;
            `
				}}
    }

    ${({ $isActive, $hasError }: any) => {
			if ($isActive) {
				if ($hasError) {
					return css`
                    border: 2px solid var(--red500) !important;
                `
				}
				return css`
                border: 2px solid var(--primary500) !important;
            `
			}
			if ($hasError) {
				return css`
                border: 1px solid var(--red500) !important;
            `
			}
		}}
    ${({ $letBorderAndShadow }: any) =>
			!$letBorderAndShadow &&
			css`
            && *,
            *::after,
            *::before {
                border: none !important;
                box-shadow: none !important;
            }
        `}
` as any

const SInputLabel = styled(InputLabel)`
    background-color: white;
    padding-left: 6px !important;
    padding-right: 6px !important;
    color: ${(props) => (props.error ? "red" : "inherit")};
    font-size: var(--font-size-small);
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const StyledError = styled.div`
    color: var(--red500);
    font-size: var(--font-size-small);
    margin-left: 1rem;
    margin-top: 3px;
`

const debug = Debug("app:javascript:utils:form:formUtils:StyledInputLayout")
debug.log = console.log.bind(console)

const StyledInputLayout = ({
	errors,
	name,
	value,
	children,
	onClick = () => {},
	onBlur = () => {},
	isExternalOpen = false,
	style = {},
	isExternalShrinked = false,
	label = undefined,
	$letBorderAndShadow = false,
}) => {
	const [isActive, setIsActive] = useState(false)

	const _onClick = () => {
		setIsActive(true)
		onClick()
	}

	const _onBlur = () => {
		setIsActive(false)
		onBlur()
	}
	return (
		<Container>
			<SFormControl
				$hasError={_.get(errors, name)}
				variant="outlined"
				$isActive={isActive || isExternalOpen}
				onClick={_onClick}
				onBlur={_onBlur}
				style={style}
				$letBorderAndShadow={$letBorderAndShadow}
			>
				<SInputLabel
					htmlFor="component-outlined"
					variant="outlined"
					error={_.get(errors, name)}
					shrink={
						isActive ||
						!_.isEmpty(value) ||
						isExternalOpen ||
						isExternalShrinked
					}
					focused={isActive || isExternalOpen}
				>
					{label ?? name}
				</SInputLabel>
				{children}
			</SFormControl>
			<StyledError>{_.get(errors, name)?.message}</StyledError>
		</Container>
	)
}

export default StyledInputLayout
