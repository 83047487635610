import baselineAdminPanelSettings from "@iconify/icons-ic/baseline-admin-panel-settings"
import { Icon } from "@iconify/react"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import WarningIcon from "@mui/icons-material/Warning"
import { Avatar } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Debug from "debug"
import { default as React } from "react"
import { useLocalStorage } from "react-use"
import AlarmIcon from "@mui/icons-material/Alarm"
import styled from "styled-components"
import NestedListChildrenDaybook from "./NestedListChildrenDaybook"
import { treeDisplayConfig } from "utils/components/categoryNavigation/CategoryNavigation"
// app:javascript:utils:panels:panelCarto:NestedListParent.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:NestedListParent")
debug.log = console.log.bind(console)

const StyledList = styled(List)<{ color: string }>`
    padding: 0px !important;
    border: 1px solid var(--neutral100);
`
const SAvatar = styled(Avatar)<{ color: string }>`
    background-color: ${({ color }) => color} !important;
`
const NestedListParentDaybook = ({ item, noListIcon = false }) => {
	const [open, setOpen] = useLocalStorage(
		"NestedListParentDaybook." + item.title,
		false,
	)

	const handleClick = () => {
		setOpen(!open)
	}

	debug("NestedListParent", item)

	return (
		<>
			<StyledList color={treeDisplayConfig[item.title].color}>
				<ListItemButton onClick={handleClick}>
					<ListItemIcon>
						<SAvatar color={treeDisplayConfig[item.title].color}>
							{treeDisplayConfig[item.title].icon}
						</SAvatar>
					</ListItemIcon>
					<ListItemText primary={item.title} />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					{item.subCategories.map((subCategory) => (
						<NestedListChildrenDaybook
							color={treeDisplayConfig[item.title].color}
							key={subCategory.title}
							subCategory={subCategory}
							noListIcon={noListIcon}
						/>
					))}
				</Collapse>
			</StyledList>
		</>
	)
}

export default NestedListParentDaybook
