import { useSelector } from "react-redux"
import IJsonSchema from "@/utils/types/IJsonSchema"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectCdcSharedJsonSchemas = createDeepEqualSelector(
	(state: any) => state.cdc.cdcSharedJsonSchemas,
	(item: any) => item,
)

const useCdcSharedJsonSchemas: () => { [key: string]: IJsonSchema } = () => {
	return useSelector(selectCdcSharedJsonSchemas) ?? {}
}

export default useCdcSharedJsonSchemas
