import CheckIcon from "@mui/icons-material/Check"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	MAIN_COURANTE,
	PATH_FICHES_ACTIONS,
	PATH_FICHES_SCENARIOS,
	PATH_MAIN_COURANTE_DASHBOARD,
} from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import NewStyledInputLayout from "utils/form/formUtils/NewStyledInputLayout"
import { Vigilance, VigilanceColor, VigilanceLabel } from "utils/types/IFiche"

const SelectableColoredFlag = styled.div<{ color: string }>`
    height: 32px;
    border-radius: 3px;
    background-color: ${({ color }) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`

const BoxColor = styled.div<{ color: string; disabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 3px;
    transition: background-color 0.2s;
    &:hover {
        ${({ disabled, color }) =>
					css`
                background-color: ${disabled ? "none" : color + "33"};
            `}
    }
    cursor: pointer;
    flex: 1;
    max-width: 52px;
`

const SCheckIcon = styled(CheckIcon)<{ $isActive: boolean; disabled: boolean }>`
    color: white;
    display: ${({ $isActive }) => ($isActive ? "inherit" : "none")} !important;
    ${BoxColor}:hover & {
        display: inherit !important;
    }
    flex: 1;
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16.5px 14px;
`

const Selected = styled.div`
    font-size: var(--font-size-small);
    font-weight: bold;
    line-height: 0.9em;
    padding-left: 3px;

    :before {
        content: 'Vigilance séléctionnée : ';
        font-weight: normal;
    }
`

const FFlag = ({ name, isNewCrisis = false }) => {
	const { control } = useFormContext()
	const selectedEvent = useSelectedEvent()
	// based on current url
	const isLocked = selectedEvent?.locked
	const daybookCreationCriseWritePerm = useAccessRights(
		EnumToolPath.CREATION_CRISE,
	).write
	const daybookGestionCriseWritePerm = useAccessRights(
		EnumToolPath.GESTION_CRISE,
	).write
	const permissionFicheAction = useAccessRights(EnumToolPath.FICHES_ACTIONS)
	const permissionFicheScenario = useAccessRights(EnumToolPath.FICHES_SCENARIOS)

	const writePermission =
		window.document.location.pathname === "/maincourante/dashboard"
			? isNewCrisis
				? daybookCreationCriseWritePerm // Creating crisis
				: daybookGestionCriseWritePerm && !isLocked // Editing Crisis from Modal/Dashboard
			: permissionFicheScenario?.write || permissionFicheAction?.write // Fiches

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<NewStyledInputLayout
			label={"Niveau de vigilance"}
			error={Boolean(error)}
			helperText={error?.message}
			empty={false}
		>
			<InputWrapper>
				<Flex gap=".6rem">
					{[
						Vigilance.GREEN,
						Vigilance.YELLOW,
						Vigilance.ORANGE,
						Vigilance.RED,
						Vigilance.BLUE,
					].map((vigilance) => (
						<BoxColor
							key={vigilance}
							color={VigilanceColor[vigilance]}
							onClick={() => {
								if (!writePermission) return
								onChange(vigilance)
							}}
							tabIndex={0}
							onKeyPress={(event) => {
								if (!writePermission) return
								if (event.key === "Enter") onChange(vigilance)
							}}
							disabled={!writePermission}
						>
							<SelectableColoredFlag
								color={
									value === vigilance || writePermission
										? VigilanceColor[vigilance]
										: "#dcdcdc"
								}
							>
								<SCheckIcon
									$isActive={value === vigilance}
									disabled={!writePermission}
								/>
							</SelectableColoredFlag>
						</BoxColor>
					))}
				</Flex>
				{value && <Selected>{VigilanceLabel[value]}</Selected>}
			</InputWrapper>
		</NewStyledInputLayout>
	)
}

export default FFlag
