import useCooperationFilters from "@/hooks/useCooperationFilters"
import React, { useState } from "react"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import IPoint from "utils/types/IPoint"
import _ from "lodash"
const ExpandLink = styled.div`
    cursor: pointer;
    font-weight: bold;

    :hover {
        text-decoration: underline;
    }
`

function DisplayStockplaceItems({
	stockPlace,
	allItems,
	align,
	children,
}: {
	stockPlace: IPoint
	allItems: IPoint[]
	align: "right" | "left"
	children: ({ item, highlight }) => JSX.Element
}) {
	const items = Object.values(allItems).filter((item: IPoint) => {
		return item.geojson.properties["Lieu de stockage"] === stockPlace.id
	})

	const coopFilters = useCooperationFilters()

	const highlight = !_.isEmpty(coopFilters.tag)
		? items.filter((item) => {
				const itemTags = item.geojson.properties["Tags"] ?? []
				return itemTags.includes(coopFilters.tag.key)
		  })
		: []

	if (highlight.length)
		items.sort((a, b) => {
			const incA = highlight.includes(a)
			const incB = highlight.includes(b)
			if (incA === incB) return 0
			if (incA) return -1
			return 1
		})

	const [displayNonHighlighted, setDisplayNonHighlighted] = useState(false)
	if (items.length <= 2)
		return (
			<Flex wrap flexEnd={align === "right"} gap="5px">
				{items.map((item) =>
					children({
						item,
						highlight: highlight.includes(item),
					}),
				)}
			</Flex>
		)
	const numberOfHighlighted = highlight.length
	if (numberOfHighlighted === 0) {
		return (
			<Flex wrap flexEnd={align === "right"} gap="5px">
				{items.slice(0, 2).map((item) =>
					children({
						item,
						highlight: false,
					}),
				)}
				{displayNonHighlighted && (
					<>
						{items.slice(2).map((item) =>
							children({
								item,
								highlight: false,
							}),
						)}
					</>
				)}
				{displayNonHighlighted ? (
					<ExpandLink onClick={() => setDisplayNonHighlighted(false)}>
						voir moins
					</ExpandLink>
				) : (
					<ExpandLink onClick={() => setDisplayNonHighlighted(true)}>
						+ {items.length - numberOfHighlighted}
					</ExpandLink>
				)}
			</Flex>
		)
	}
	return (
		<Flex wrap flexEnd={align === "right"} gap="5px">
			{highlight.length > 0 && (
				<>
					{highlight.map((item) =>
						children({
							item,
							highlight: true,
						}),
					)}
					{displayNonHighlighted && (
						<>
							{items
								.filter((item) => !highlight.includes(item))
								.map((item) =>
									children({
										item,
										highlight: false,
									}),
								)}
						</>
					)}
				</>
			)}

			{displayNonHighlighted ? (
				<ExpandLink onClick={() => setDisplayNonHighlighted(false)}>
					voir moins
				</ExpandLink>
			) : (
				<ExpandLink onClick={() => setDisplayNonHighlighted(true)}>
					+ {items.length - numberOfHighlighted}
				</ExpandLink>
			)}
		</Flex>
	)
}

export default DisplayStockplaceItems
