import { combineReducers } from "redux"

import pointReducer from "./points/reducer"
import eventReducer from "./events/reducer"
import ficheReducer from "./fiche/reducer"
import jsonSchemaReducer from "./jsonSchema/reducer"
import multiPolygonReducer from "./multiPolygon/reducer"
import pdfReducer from "./pdf/reducer"
import pdfGeneratorsReducer from "./pdfGenerator/reducer"
import uploadedFilesReducer from "./uploadedFile/reducer"
import uploadedCartosReducer from "./uploadedCarto/reducer"
import mappingsReducer from "./mappings/reducer"
import dicrimReducer from "./dicrim/reducer"

const reducer = combineReducers({
	points: pointReducer,
	events: eventReducer,
	fiches: ficheReducer,
	jsonSchemas: jsonSchemaReducer,
	multiPolygons: multiPolygonReducer,
	pdfs: pdfReducer,
	pdfGenerators: pdfGeneratorsReducer,
	uploadedFiles: uploadedFilesReducer,
	uploadedCartos: uploadedCartosReducer,
	mappings: mappingsReducer,
	dicrims: dicrimReducer,
})

export default reducer
