import React from "react"
import { Droppable } from "react-beautiful-dnd"

function doMakeDroppable(Component) {
	return ({ droppableProps, ...props }) => {
		const correctedDroppableProps = {
			droppableId: "main",
			direction: "vertical",
			...droppableProps,
		}

		return (
			<Droppable {...correctedDroppableProps}>
				{(provided, snapshot) => (
					<Component
						ref={provided.innerRef}
						{...provided.droppableProps}
						{...props}
						style={{
							...props.style,
							...(props.dndStyle
								? props.dndStyle(snapshot.isDraggingOver)
								: undefined),
						}}
					>
						{props.children}
						{provided.placeholder}
					</Component>
				)}
			</Droppable>
		)
	}
}

function makeDroppable(Component, droppableProps = undefined) {
	return droppableProps
		? (props) => doMakeDroppable(Component)({ ...props, droppableProps })
		: doMakeDroppable(Component)
}

export default makeDroppable
