import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import GetApp from "@mui/icons-material/GetApp"
import { IconButton } from "@mui/material"
import {
	PDFDownloadLink,
	BlobProvider as RBlobProvider,
} from "@react-pdf/renderer"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import styled from "styled-components"
import usePointPropertyToText from "@/utils/components/jsonSchema/properties/usePointPropertyToText"
import { Flex } from "@/utils/components/style/flex"
import RequestPdf from "./pdf/RequestPdf"
import { ModalContent } from "@nextui-org/react"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import Button from "@/styles/atoms/Button"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
const BlobProvider = RBlobProvider as any
const SPDFDownloadLink = styled(PDFDownloadLink as any)`
    margin: 1em;
`

const BlobProviderContainer = styled.div`
	transform: scale(1.0);
`

const SPage = styled(Page)`
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
`
const RequestModalPDF = ({
	intersectedPointsByCouches,
	concernedMultiPolygon,
	setIsModalOpen,
	isModalOpen,
}) => {
	const [documentLoading, setDocumentLoading] = useState(true)
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [pdfIsLoading, setPdfIsLoading] = useState(true)
	const getTextFromProperty = usePointPropertyToText()
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
		setPageNumber(1)
	}

	const changePage = (offset) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset)
	}

	const previousPage = () => {
		changePage(-1)
	}

	const nextPage = () => {
		changePage(1)
	}

	const RequestPdfOne = (
		<RequestPdf
			intersectedPointsByCouches={intersectedPointsByCouches}
			jsonSchemaWithStatics={jsonSchemaWithStatics}
			getTextFromProperty={getTextFromProperty}
			concernedMultiPolygon={concernedMultiPolygon}
		/>
	)

	return (
		<>
			<ModalBody>
				<Flex fullWidth justifyCenter>
					<IconButton onClick={previousPage} disabled={pageNumber <= 1}>
						<ChevronLeft />
					</IconButton>
					<BlobProviderContainer>
						<BlobProvider document={RequestPdfOne}>
							{({ blob, url, loading, error }) => {
								// Do whatever you need with blob here
								if (loading) {
									if (!documentLoading) {
										setDocumentLoading(true)
									}
									return <div>Loading document...</div>
								}
								if (documentLoading) {
									setDocumentLoading(false)
								}
								return (
									<div>
										<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
											<Flex gap={"50px"} justifyCenter>
												<SPage
													pageNumber={pageNumber}
													renderAnnotationLayer={false}
												/>
											</Flex>
										</Document>
									</div>
								)
							}}
						</BlobProvider>
					</BlobProviderContainer>
					<IconButton onClick={nextPage} disabled={pageNumber >= numPages}>
						<ChevronRight />
					</IconButton>
				</Flex>
			</ModalBody>
			<ModalFooter>
				<Flex gap="1rem">
					<CancelButton onClick={() => setIsModalOpen(!isModalOpen)} />
					<SPDFDownloadLink
						document={RequestPdfOne}
						fileName={"Extraction.pdf"}
					>
						{({ blob, url, loading, error }) => {
							if (loading) {
								if (!pdfIsLoading) {
									setPdfIsLoading(true)
								}
								return <div>Loading document...</div>
							}
							if (pdfIsLoading) {
								setPdfIsLoading(false)
							}
							return (
								<Button
									color="primary"
									startContent={<GetApp />}
									onClick={() => {
										setIsModalOpen(!isModalOpen)
									}}
								>
									Télécharger
								</Button>
							)
						}}
					</SPDFDownloadLink>
				</Flex>
			</ModalFooter>
		</>
	)
}

export default RequestModalPDF
