import CustomTooltip from "./style/CustomTooltip"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import React, { useState } from "react"
import ConfirmationModal from "./ConfirmationModal"
import { toast } from "react-toastify"
import styled from "styled-components"
import { RedOutlinedButton } from "./style/button"
import { isMobile } from "react-device-detect"
import Button from "@/styles/atoms/Button"

const Div = styled.div``

const SuperDeleteButton = ({
	onClick,
	wrapper = undefined,
	small = false,
	disabled = false,
	CustomIconButton = undefined,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const Wrapper = wrapper ?? Div
	const IconButtonWrapper = CustomIconButton ?? IconButton
	return (
		<>
			<Wrapper
				onClick={(event) => {
					if (disabled) {
						return
					}
					setIsOpen(true)
					event.stopPropagation()
				}}
			>
				{!isMobile && (
					<CustomTooltip title={"Supprimer"}>
						<IconButtonWrapper
							size={small ? "small" : "medium"}
							style={{ fontSize: 24, padding: 0 }}
							aria-label="Delete"
							disabled={disabled}
						>
							<DeleteIcon style={{ fontSize: 24, padding: 0 }} />
						</IconButtonWrapper>
					</CustomTooltip>
				)}
				{isMobile && (
					<Button
						color="danger"
						variant="bordered"
						startContent={<DeleteIcon />}
						aria-label="Delete"
						disabled={disabled}
						$fullHeight
						$fullWidth
					>
						Supprimer
					</Button>
				)}
			</Wrapper>
			<ConfirmationModal
				modalShown={isOpen}
				onClose={() => setIsOpen(false)}
				onConfirm={() => {
					onClick()
					toast.info("Elément(s) supprimé(s)")
					setIsOpen(false)
				}}
				message={"Êtes-vous certain(e) de vouloir supprimer ce(s) élément(s)"}
			/>
		</>
	)
}

export default SuperDeleteButton
