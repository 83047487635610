import Moment from "moment"
import {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import Yup from "utils/yup"
import _ from "lodash"
import { getYupGeoloc } from "utils/types/IGeoloc"

const yupPointProperty = ({
	property,
	isNew = false,
}: {
	property: IJsonSchemaProperty
	isNew?: boolean
}) => {
	const shouldUsePropertyDefaultValue =
		isNew && property.defaultValue && !_.isEmpty(property.defaultValue)
	let yupToReturn = null
	switch (property.type) {
		// ! TEXT
		case JsonSchemaPropertiesType.TEXT:
		case JsonSchemaPropertiesType.CREATABLE_SELECT:
		case JsonSchemaPropertiesType.TEXTAREA:
			const string = Yup.string()
				.default(
					shouldUsePropertyDefaultValue
						? (property.defaultValue as string)
						: "",
				)
				.transform((v) => (!v ? "" : v))
			yupToReturn = string
			break

		// ! TIME
		case JsonSchemaPropertiesType.TIME:
			const nullableString = Yup.string()
				.nullable()
				.default(
					shouldUsePropertyDefaultValue
						? Moment(Moment.now()).format("HH:mm")
						: null,
				)
			yupToReturn = nullableString
			break

		// ! STOCKPLACE
		case JsonSchemaPropertiesType.STOCKPLACE:
			const nullableString3 = Yup.string().nullable().default(null)
			yupToReturn = nullableString3
			break

		// ! RADIO & SELECT
		case JsonSchemaPropertiesType.RADIO:
		case JsonSchemaPropertiesType.SELECT:
			const nullableString2 = Yup.string()
				.nullable()
				.default(
					shouldUsePropertyDefaultValue
						? (property.defaultValue as string)
						: null,
				)
			yupToReturn = nullableString2
			break
		case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
			let nullableElement
			if (property?.options?.isMulti) {
				nullableElement = Yup.array(Yup.string()).nullable().default([])
			} else {
				nullableElement = Yup.string().nullable().default(null)
			}

			yupToReturn = nullableElement
			break

		// ! DATE
		case JsonSchemaPropertiesType.DATE:
			const nullableStringDate = Yup.string()
				.nullable()
				.default(
					shouldUsePropertyDefaultValue
						? Moment(Moment.now()).format("DD/MM/YYYY")
						: null,
				)
			yupToReturn = nullableStringDate
			break

		// ! TAGS & RESPONSABLES & CHECKBOX
		case JsonSchemaPropertiesType.TAGS:
		case JsonSchemaPropertiesType.RESPONSABLES:
		case JsonSchemaPropertiesType.CHECKBOX:
			const stringArray = Yup.array(Yup.string()).ensure().default([])
			yupToReturn = property.isRequired
				? stringArray.required().min(1)
				: stringArray
			break

		// ! GEOLOC
		case JsonSchemaPropertiesType.GEOLOC:
			// ! TODO : handle property.isRequired
			yupToReturn = getYupGeoloc({
				isRequired: property.isRequired,
			})
			break
		// ! PHONE
		case JsonSchemaPropertiesType.PHONE:
			const phones = Yup.array(
				Yup.object({
					Type: Yup.string().required(),
					Numéro: Yup.string().required(),
				}),
			)
				.default([{ Type: "", Numéro: "" }])
				.transform((phones) =>
					(phones ?? []).filter((phone) => phone.Type || phone.Numéro),
				)
			yupToReturn = property.isRequired
				? phones.test(
						"min",
						"Veuillez ajouter au moins un numéro de téléphone",
						(phones) => phones.length > 0,
				  )
				: phones
			break

		// ! NUMBER
		case JsonSchemaPropertiesType.NUMBER:
			const number = Yup.number()
				.nullable()
				.default(0)
				.transform((v) => (!v ? 0 : v))
			yupToReturn = number
			break
	}
	if (property.validations) {
		property.validations.forEach((validation) => {
			yupToReturn = yupToReturn.test(
				validation.name,
				validation.label,
				validation.test,
			)
		})
	}
	if (property.isRequired) {
		yupToReturn = yupToReturn.required()
	}
	return yupToReturn
}

export default yupPointProperty
