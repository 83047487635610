import { searchHistoryPushAddress } from "@/redux-toolkit/searchHistory/reducer"
import { useDispatch, useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const addressesSearchHistorySelector = createDeepEqualSelector(
	(state: any) => state.searchHistory.addresses,
	(addresses) => addresses,
)

const useAddressesSearchHistory = () => {
	const dispatch = useDispatch()
	return {
		addressesSearchHistory: useSelector(addressesSearchHistorySelector),
		pushAddressToSearchHistory: (address) =>
			dispatch(searchHistoryPushAddress(address)),
	}
}

export default useAddressesSearchHistory
