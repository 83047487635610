import AddIcon from "@mui/icons-material/Add"
import GetApp from "@mui/icons-material/GetApp"
import Button from "@mui/material/Button"
import React from "react"
import styled, { css } from "styled-components"
import LoadingButton from "@mui/lab/LoadingButton"

const buildVar = (color: string, suffix = "") => `var(--${color}${suffix})`

interface IAdditionalButtonProps {
	$error?: boolean
	$fullWidth?: boolean
	$padding?: string
	$margin?: string
	$fullHeight?: boolean
}

export const coloredButtonBuilder = (color: string, loading = false) => styled(
	loading ? LoadingButton : Button,
)<IAdditionalButtonProps>`
  font-size: calc(var(--font-size-base) * 0.9) !important;
  text-transform: none !important;
  font-weight: 400 !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  ${({ $margin }) =>
		$margin &&
		css`
      margin: ${$margin} !important;
    `}

  ${({ $padding }) =>
		$padding &&
		css`
      padding: ${$padding} !important;
    `}

    ${({ $fullWidth }) =>
			$fullWidth &&
			css`
      width: 100%;
    `}
	${({ $fullHeight }) =>
		$fullHeight &&
		css`
      height: 100%;
    `}
		
    ${({ size }) =>
			size === "small" &&
			css`
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    `}

    ${({ variant, disabled, $error }) =>
			disabled
				? css``
				: variant === "outlined"
				  ? css`
          color: ${buildVar($error ? "red" : color, "500")} !important;
          border-color: ${buildVar($error ? "red" : color, "500t42")} !important;
          &:hover {
            border-color: ${buildVar($error ? "red" : color, "500")} !important;
          }
        `
				  : variant === "contained"
					  ? css`
          color: white !important;
          background-color: ${buildVar($error ? "red" : color, "500")} !important;
          &:hover {
            background-color: ${buildVar($error ? "red" : color, "900")} !important;
          }
        `
					  : css`
          color: ${buildVar($error ? "red" : color, "500")} !important;
        `}
`

type ButtonProps = React.ComponentProps<typeof Button> & IAdditionalButtonProps
export const PrimaryBaseButton = coloredButtonBuilder("primary")
export const OrangeBaseButton = coloredButtonBuilder("orange")
export const GreenBaseButton = coloredButtonBuilder("green")

export const PrimaryLoadingButton = coloredButtonBuilder(
	"primary",
	true,
) as typeof LoadingButton

const withVariant =
	(variant: ButtonProps["variant"]) =>
	(Component: typeof PrimaryBaseButton) =>
	(props: Omit<ButtonProps, "variant">) => {
		return <Component {...props} variant={variant}></Component>
	}

export const PrimaryButton = withVariant("contained")(PrimaryBaseButton)
export const PrimaryOutlinedButton = withVariant("outlined")(PrimaryBaseButton)
export const PrimaryTextButton = withVariant("text")(PrimaryBaseButton)

export const RedButton = withVariant("contained")(coloredButtonBuilder("red"))

export const GreenButton = withVariant("contained")(
	coloredButtonBuilder("green"),
)
export const GreenOutlinedButton = withVariant("outlined")(GreenBaseButton)
export const OrangeButton = withVariant("contained")(
	coloredButtonBuilder("orange"),
)
export const OrangeOutlinedButton = withVariant("outlined")(
	coloredButtonBuilder("orange"),
)
export const RedOutlinedButton = withVariant("outlined")(
	coloredButtonBuilder("red"),
)
export const YellowButton = withVariant("contained")(
	coloredButtonBuilder("yellow"),
)
export const PurpleButton = withVariant("contained")(
	coloredButtonBuilder("purple"),
)
export const BrownButton = withVariant("contained")(
	coloredButtonBuilder("brown"),
)
export const BrownOutlinedButton = withVariant("outlined")(
	coloredButtonBuilder("brown"),
)
export const PurpleOutlinedButton = withVariant("outlined")(
	coloredButtonBuilder("purple"),
)
export const WhiteOutlinedButton = withVariant("outlined")(
	coloredButtonBuilder("white"),
)
export const NeutralOutlinedButton = withVariant("outlined")(
	coloredButtonBuilder("neutral"),
)
