import _ from "lodash"
import { useEffect, useMemo, useState } from "react"

export const sortByExternalArray = (ext_order, accessor) => {
	return (a, b) => {
		const indexA = ext_order.indexOf(accessor(a))
		const indexB = ext_order.indexOf(accessor(b))

		if (indexA === indexB) return 0
		if (indexA === -1) return 1
		if (indexB === -1) return -1

		return indexA > indexB ? 1 : -1
	}
}

const combineSort = (sort1, sort2) => {
	return (a, b) => {
		const s1 = sort1(a, b)
		if (s1 !== 0) return s1
		return sort2(a, b)
	}
}

const defaultAccessor = (item) => item?.id

const useReorder = (data, orderConfig) => {
	const ferror = () => console.error("ferror")

	const [order, setOrder] = useState(orderConfig?.order)
	const mainAccessor = orderConfig?.accessor ?? defaultAccessor

	useEffect(() => {
		setOrder(orderConfig?.order)
	}, [orderConfig?.order])

	const orderedData = useMemo(
		() =>
			order && orderConfig
				? [...data].sort(
						orderConfig.preOrder
							? combineSort(
									sortByExternalArray(
										orderConfig.preOrder.order,
										orderConfig.preOrder.accessorFn,
									),
									sortByExternalArray(order, mainAccessor),
							  )
							: sortByExternalArray(order, mainAccessor),
				  )
				: data,
		[order, data],
	)

	const fullOrder = orderedData.map(mainAccessor)

	const handleMove = orderConfig
		? (move) => {
				const newOrder = [...fullOrder]
				const [removed] = newOrder.splice(move.source.index, 1)
				newOrder.splice(move.destination.index, 0, removed)

				setOrder(newOrder)
		  }
		: ferror

	const resetOrder = orderConfig
		? () => {
				setOrder(orderConfig.order)
		  }
		: ferror

	const confirmOrder = orderConfig
		? () => {
				if (orderConfig.onOrder) orderConfig.onOrder(fullOrder)
		  }
		: ferror

	useEffect(() => {
		if (orderConfig?.onOrder && !_.isEqual(order, fullOrder)) {
			setOrder(fullOrder)
			confirmOrder()
		}
	}, [fullOrder])

	return {
		orderedData,
		handleMove,
		resetOrder,
		confirmOrder,
	}
}

export default useReorder
