import React from "react"
import { Flex } from "utils/components/style/flex"
import FExtractSelect from "utils/form/FExtractSelect"
import FRadios from "utils/form/FRadios"

const RequestModalForm = ({
	optionPoints,
	optionMultiPolygon,
	orderOptions,
}) => {
	return (
		<Flex directionColumn gap="1rem">
			<FExtractSelect
				options={optionPoints}
				name="Couche(s) de points"
				label="Couche(s) de points"
				required
			/>

			<FExtractSelect
				options={optionMultiPolygon}
				name="Couche surfacique"
				label="Couche surfacique"
				multiple={false}
				required
			/>
			<FExtractSelect
				options={orderOptions}
				name="Ordonnancement"
				label="Trier par (facultatif)"
				multiple={false}
			/>
		</Flex>
	)
}

export default RequestModalForm
