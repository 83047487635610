import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"

const initialState = {}

const pdfGeneratorsSlice = createSlice({
	name: "data/pdfGenerators",
	initialState,
	reducers: {
		addPdfGenerator(state, { payload: pdfGenerator }) {
			state[pdfGenerator.id] = pdfGenerator
		},
		removePdfGenerator(state, { payload: id }) {
			delete state[id]
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default pdfGeneratorsSlice.reducer
export const { addPdfGenerator, removePdfGenerator } =
	pdfGeneratorsSlice.actions
