import moment from "moment"
import {
	addLoadingKey,
	removeLoadingKey,
	setOnline,
	setOffLine,
} from "@/redux-toolkit/common/reducer"
import { getJsonSchemas } from "@/redux-toolkit/data/jsonSchema/resources"
import { getMultiGeojson } from "@/redux-toolkit/data/multiPolygon/resources"
import { getPdfs } from "@/redux-toolkit/data/pdf/resources"
import { getPdfGenerators } from "@/redux-toolkit/data/pdfGenerator/resources"
import { getPublicPoints } from "@/redux-toolkit/data/points/resources"
import { getFiches } from "@/redux-toolkit/data/fiche/resources"
import Debug from "debug"
import { toast } from "react-toastify"
import SynchroPointService from "./synchro/SynchroPointService"
import SynchroEventService from "./synchro/SynchroEventService"
import {
	SYNCHRO,
	SYNCHRO_INITIAL,
} from "@/redux-toolkit/common/loadingKeys"
import { getUserCommune } from "@/redux-toolkit/commune/ressources"
import Axios from "axios"
import ErrorService from "@/services/ErrorService"
import _ from "lodash"
import SynchroFicheService from "./synchro/SynchroFicheService"
import { getUploadedFiles } from "@/redux-toolkit/data/uploadedFile/resources"
import { getUserSettings } from "@/redux-toolkit/userSettings/ressources"
import { getUserCommunesCdc } from "@/redux-toolkit/cdc/ressources"
import CacheService, { prettyPrintCacheResult } from "./CacheService"
import StaticFormsService from "@/utils/services/StaticFormsService"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { getMappings } from "@/redux-toolkit/data/mappings/ressources"
import { fetchTags } from "@/redux-toolkit/common/resources"
import SynchroMultiPolygonService from "./synchro/SynchroMultiPolygonService"
import { LOCAL_STATUS } from "@/utils/types/ILocal"
import { getCartos } from "@/redux-toolkit/data/uploadedCarto/resources"
import IPoint, { IPointLocal } from "@/utils/types/IPoint"

export const SUCCESS_PING = "SUCCESS_PING"
export const FAILURE_PING = "FAILURE_PING"
export const FIRST_SYNCHRO_DATE = "2000-04-12T12:47:50+02:00"

const debug = Debug("components:services:SynchroService")
const synchroOncePerSession = ({ withoutDelete = true }) => {
	return async (dispatch, getState) => {
		dispatch(addLoadingKey(SYNCHRO_INITIAL))
		try {
			const currentState = getState()
			await Promise.all([
				getJsonSchemas(dispatch, currentState),
				getUserSettings(dispatch, currentState),
				getPublicPoints(dispatch, currentState),
				getUserCommune(dispatch),
				getPdfGenerators(dispatch, currentState),
				getFiches(dispatch, currentState),
				getUploadedFiles(dispatch, currentState),
				getUserCommunesCdc(dispatch),
				getMappings(dispatch, currentState),
				getCartos(dispatch, currentState),
				SynchroFicheService.synchronize(dispatch, getState, withoutDelete),
				fetchTags(dispatch, currentState),
			])
		} catch (error) {
			const errorMessage = `Erreur lors de la synchronisation initial de session, message d'erreur : ${error.message}`
			ErrorService.error({
				component: "SynchroService:synchroOncePerSession",
				message: errorMessage,
				dispatch,
			})
		} finally {
			dispatch(removeLoadingKey(SYNCHRO_INITIAL))

			// === CACHE MAP TILES
			const currentState = getState()

			CacheService.cacheMapTiles(currentState.commune.geojson)?.then(
				prettyPrintCacheResult("Map caching", "green"),
			)
			// ===
		}
	}
}

const ping = async (dispatch, isOnline) => {
	try {
		await Axios.get("/api/server_datetime")
		dispatch(setOnline())
		if (!isOnline) {
			toast.success("Vous êtes en ligne, la synchronisation reprend")
		}
		return SUCCESS_PING
	} catch (error) {
		if (isOnline) {
			toast.error(
				"Vous êtes hors ligne, la synchronisation se fera ultérieurement",
			)
		}
		dispatch(setOffLine())
		return FAILURE_PING
	}
}

const checkOnlineStatus = createAsyncThunk(
	"checkOnlineStatus",
	async (param, { getState, dispatch }) => {
		const currentState = getState() as any
		const isOnline = currentState.common.isOnline
		return await ping(dispatch, isOnline)
	},
)

const synchro = ({ withoutDelete, initial }) => {
	debug("synchro", "Starting synchro")

	return async (dispatch, getState) => {
		if (initial) {
			dispatch(addLoadingKey(SYNCHRO_INITIAL))
		}
		dispatch(addLoadingKey(SYNCHRO))
		const currentState = getState()
		const res = await ping(dispatch, currentState.common.isOnline)

		try {
			await SynchroPointService.synchronize(dispatch, getState, withoutDelete)
			await Promise.all([
				SynchroEventService.synchronize(dispatch, getState, withoutDelete),
				SynchroMultiPolygonService.synchronize(
					dispatch,
					getState,
					withoutDelete,
				),
				getPdfs(dispatch),
			])
		} catch (err) {
			const errorMessage = `La Synchronisation a échoué, message d'erreur : ${err.message}`
			ErrorService.error({
				component: "SynchroService:synchro",
				message: errorMessage,
				dispatch,
			})
		} finally {
			if (initial) {
				dispatch(removeLoadingKey(SYNCHRO_INITIAL))
				toast.info("Données mises à jour")
			}
			dispatch(removeLoadingKey(SYNCHRO))

			// === CACHE JSONSCHEMA ICONS
			// TODO once per session ?
			const currentState = getState()

			const jsonSchemas = _.clone(currentState.data.jsonSchemas)
			StaticFormsService.getStaticForms().forEach((staticForm) => {
				jsonSchemas[staticForm.id] = staticForm
			})

			CacheService.cacheJsonSchemasIcons(jsonSchemas)?.then(
				prettyPrintCacheResult("Icons caching", "blue"),
			)
			// ===
		}
	}
}

const notDeleted = (objects = {}) => {
	const result = {}
	_.sortBy(Object.values(objects), (object: any) =>
		moment(object.updated_at).unix(),
	)
		.reverse()
		.forEach((object: any) => {
			if (object.localStatus === LOCAL_STATUS.DELETED) {
				return
			}
			if (object.deleted) {
				return
			}
			result[object.id] = object
		})
	return result
}

//filter points here
const notDeletedWithCurrentCommune = (
	points,
	communeId,
	options = {
		isValidated: true,
	},
) => {
	const mergedOptions = {
		isValidated: true,
		...options,
	}

	const result = {}
	_.sortBy(Object.values(points), (point: IPointLocal) =>
		moment(point.updated_at).unix(),
	)
		.reverse()
		.forEach((point: IPointLocal) => {
			if (
				point.hasOwnProperty("is_validated")
					? mergedOptions.isValidated
						? !point.is_validated
						: point.is_validated
					: false
			) {
				return
			}
			if (point.localStatus === LOCAL_STATUS.DELETED) {
				return
			}
			if (point.commune_id !== communeId) {
				return
			}
			if (point.deleted) {
				return
			}
			result[point.id] = point
		})
	return result
}

const getItemsByLocalStatus = (items = {}) => {
	const toDeleteItems = []
	const toUpdateItems = []
	const toCreateItems = []

	Object.values(items).forEach((item: any) => {
		if (item.localStatus === LOCAL_STATUS.DELETED) {
			toDeleteItems.push(item)
		}
		if (item.localStatus === LOCAL_STATUS.CREATED) {
			toCreateItems.push(item)
		}
		if (item.localStatus === LOCAL_STATUS.UPDATED) {
			toUpdateItems.push(item)
		}
	})

	return { toCreateItems, toUpdateItems, toDeleteItems }
}

export default {
	ping,
	synchro,
	notDeleted,
	getItemsByLocalStatus,
	synchroOncePerSession,
	notDeletedWithCurrentCommune,
	checkOnlineStatus,
}
