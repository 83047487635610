import React from "react"
import PH2 from "utils/pdf/header/PH2"
import NormalText from "utils/pdf/NormalText"
import PPage from "utils/pdf/PPage"
import JsonSchemaTableRGPD from "../components/JsonSchemaTableRGPD"
import StandardTableRGPD from "../components/StandardTableRGPD"

const DynamicDataPage = ({ commune, jsonSchemas, responsableRgpd }) => {
	return (
		<>
			<PPage>
				<PH2>
					AC02 - Fiche de registre de l&apos;activité : Gestion de Données
					&quot;Dynamiques&quot;
				</PH2>

				<StandardTableRGPD
					commune={commune}
					responsableRgpd={responsableRgpd}
				/>

				<PH2>Contexte et Objectifs</PH2>

				<NormalText>
					Numérisk PCS a pour objectifs de numériser les informations liées au
					Plan Communal de Sauvegarde (PCS) afin de faciliter leur mise à
					disposition lors d’une gestion de crise. Les outils développés sur la
					plateforme permettent dans le cadre de la prévention des risques
					majeurs, la préparation de la commune, la gestion et collaboration
					lors d’une crise et le retour d’expérience en post-événement. Afin de
					s’adapter au mieux aux modes d’organisation de la commune, Numérisk a
					développé un système de formulaire « dynamique » permettant une
					personnalisation de l’application au contenu papier du PCS. Ces
					formulaires sont configurés en amont par Numérisk et peuvent être
					adaptés à tout moment depuis l’interface d’administration de Numérisk
					PCS. Compte tenu du caractère évolutif de ces formulaires dynamiques,
					le tableau récapitulatif du détail des données est généré également
					dynamiquement et annoté de sa date de génération. Bien que ces
					formulaires soient peu de fois modifiés au cours de l’abonnement d’une
					commune, il est conseillé de générer ce tableau récapitulatif des
					données le plus régulièrement possible.
				</NormalText>
			</PPage>
			<PPage>
				<PH2>AC02 - Détail des données</PH2>
				<JsonSchemaTableRGPD jsonSchemas={Object.values(jsonSchemas)} />
			</PPage>
		</>
	)
}
export default DynamicDataPage
