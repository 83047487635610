import useIsInterco from "@/hooks/useIsInterco"
import React from "react"
import styled, { css } from "styled-components"
import intercoNameAdapter from "utils/intercoNameAdapter"
import { TypeComponentProps } from "../PointPropertyValue"
import { DiffSpan, TextAlign } from "./PropertiesType.styled"

function TypeText({
	value,
	align,
	diffWith,
	fontSize = undefined,
}: TypeComponentProps) {
	const isInterco = useIsInterco()
	return (
		<TextAlign align={align} $fontSize={fontSize}>
			{diffWith !== undefined && diffWith !== value ? (
				<>
					<DiffSpan>{diffWith ?? ""}</DiffSpan>{" "}
					<DiffSpan add>{value ?? ""}</DiffSpan>
				</>
			) : value ? (
				intercoNameAdapter(value, isInterco)
			) : (
				""
			)}
		</TextAlign>
	)
}

export default TypeText
