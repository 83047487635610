import Debug from "debug"
import _ from "lodash"
import React, { useMemo } from "react"
import IPoint from "@/utils/types/IPoint"
import TypeResponsables from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import SuperReactTable from "@/utils/components/tables/SuperReactTable"
import { NextSuiviDateField } from "../suivi/FieldSuivi"
import { Flex } from "@/utils/components/style/flex"
import { StyledChip } from "@/utils/components/style/chip"
import moment from "moment"
import ISuivi from "@/utils/types/ISuivi"

// app:javascript:components:maincourante:following:TableSuiviContactSubRow.tsx
const debug = Debug(
    "app:javascript:components:maincourante:following:TableSuiviContactSubRow",
)
debug.log = console.log.bind(console)

const TableSuiviContactSubRow = ({
    suivis,
    isArchive = false
}: {
    suivis: ISuivi[]
    isArchive?: boolean
}) => {
    const columns = useMemo(
        () => [
            {
                header: "Statut",
                accessorKey: "Statut",
                cell: ({ row }) => {
                    const statut = row.original["Statut"]
                    const suiviDate = row.original["Date prise de contact"]
                    const colorPpt = statut === "À faire"
                        ? moment().isAfter(suiviDate)
                            ? "var(--red)"
                            : "var(--orange)"
                        : statut === "En cours"
                            ? moment().isAfter(suiviDate)
                                ? "var(--red)"
                                : "var(--yellow)"
                            : "var(--green)"
                    return (
                        <StyledChip
                            label={statut}
                            $colorProperty={colorPpt}
                            variant="outlined"
                        />
                    )
                },
            },
            {
                header: "Date",
                accessorKey: "Date prise de contact",
                cell: NextSuiviDateField,
            },
            {
                header: "Action",
                accessorKey: "Suites à donner",
            },
            {
                header: "Observation",
                accessorKey: "Observation",
            },
            {
                header: "Référent",
                accessorKey: "responsableId",
                cell: ({ row }) => {
                    return (
                        !isArchive
                            ? <TypeResponsables
                                editable={true}
                                align="left"
                                value={row.original.responsableId}
                            />
                            : row.original.responsables.map((resp, index) => {
                                return <Flex>{resp.Nom + " " + resp.Prénom + (row.original.responsables.length != index + 1 ? "; " : "")}</Flex>
                            })
                    )
                },
            },
        ],
        [],
    )

    return (
        <>
            <SuperReactTable
                columns={columns}
                data={suivis}
                additionalLineNumber={1}
                isEditable={false}
                isDeletable={false}
                simpleTable={true}
                selectable={false}
                showBottom={false}
                infiniteSize={true}
                autoResetExpanded={false}
                $secondaryTableStyle={true}
            />
        </>
    )
}

export default TableSuiviContactSubRow