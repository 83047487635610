import GetAppIcon from "@mui/icons-material/GetApp"
import React, { useRef, useState } from "react"
import { CSVLink } from "react-csv"
import styled from "styled-components"
import Button from "../Button"

export const CSVLinkText = styled.div`
  text-decoration: none;
  font-size: calc(var(--font-size-base));
`

const ExtractionBouton = (props) => {
	const ref = useRef(null)
	const { title, data, buttonTitle = "Extraction", disabled = false } = props
	return (
		<Button
			color="primary"
			onClick={() => {
				ref.current.link.click()
				props?.callBack?.()
			}}
			startContent={<GetAppIcon />}
			disabled={disabled}
		>
			<CSVLink
				ref={ref}
				filename={title + ".csv"}
				data={data}
				separator={";"}
			/>
			<CSVLinkText>{buttonTitle}</CSVLinkText>
		</Button>
	)
}
export default ExtractionBouton
