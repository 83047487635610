import { CardHeader, Checkbox, ListItemButton } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import usePoints from "@/hooks/usePoints"
import { addCouchePoints, removeCouche } from "@/redux-toolkit/carto/reducer"
import { closeMaptools } from "@/redux-toolkit/common/reducer"
import React, { useMemo } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import getImage from "@/utils/getImage"

const SListItem = styled(ListItemButton)`
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 0px !important;
`

const JsonSchemaCard = ({
	jsonSchema,
	closeMaptools,
	cartography,
	addCouchePoints,
	removeCouche,
	couches,
}) => {
	const points = Object.values(usePoints())
	const history = useHistory()
	const numberOfPoints = useMemo(() => {
		return points.filter((point) => point.jsonschema_id === jsonSchema.id)
			.length
	}, [jsonSchema, points])

	const _onClick = () => {
		history.push(`/customdata/my_forms/${jsonSchema.id}`)
		closeMaptools()
	}

	let rightRender = <ArrowForwardIosIcon fontSize="small" />
	let onClick = jsonSchema?.onClick ?? _onClick

	const isGeolocalisable = JsonSchemaService.isGeolocalisable(jsonSchema)
	if (cartography) {
		onClick = () => {
			if (!isGeolocalisable) {
				return
			}
			if (!Object.keys(couches).includes(jsonSchema.id)) {
				addCouchePoints(jsonSchema.id)
			} else {
				removeCouche(jsonSchema.id)
			}
		}
		rightRender = isGeolocalisable ? (
			<Checkbox
				checked={couches[jsonSchema.id] !== undefined}
				onClick={onClick}
			/>
		) : (
			<div />
		)
	}

	return (
		<>
			<SListItem onClick={onClick}>
				<CardHeader
					avatar={
						jsonSchema?.img ?? (
							<img src={getImage(jsonSchema.imgId)} width="40" />
						)
					}
					title={jsonSchema.title}
					subheader={`${numberOfPoints} données`}
				/>
				{rightRender}
			</SListItem>
		</>
	)
}
const mapDispatchToProps = (dispatch) => ({
	closeMaptools: () => {
		dispatch(closeMaptools())
	},
	addCouchePoints: (id) => {
		return dispatch(addCouchePoints(id))
	},
	removeCouche: (id) => {
		return dispatch(removeCouche(id))
	},
})

const mapStateToProps = (state) => ({
	couches: state.carto.couches,
})
export default connect(mapStateToProps, mapDispatchToProps)(JsonSchemaCard)
