import React, { useState } from "react"
import NestedListParentBase, {
	NestedListParentBaseProps,
} from "./NestedListParentBase"

const NestedListParent = (props: NestedListParentBaseProps) => {
	const [open, setOpen] = useState(false)

	return <NestedListParentBase open={open} setOpen={setOpen} {...props} />
}

export default NestedListParent
