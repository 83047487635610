import { Link } from "@react-pdf/renderer"
import React from "react"
import PH1 from "utils/pdf/header/PH1"
import PH2 from "utils/pdf/header/PH2"
import PList from "utils/pdf/list/PList"
import NormalText from "utils/pdf/NormalText"
import PPage from "utils/pdf/PPage"

const ConfidentialityRgpd = () => {
	return (
		<PPage>
			<PH1>
				CD - Confidentialité des données et Mesures de sécurité technique
			</PH1>

			<PH2>Sécurité des données</PH2>

			<NormalText marginBottom={14}>
				Afin d&apos;assurer la protection de vos données Numérisk à mis en place
				les mesures suivantes :
			</NormalText>

			<PList
				items={[
					"Utilisation d'un certificat SSL (encryptage des données)",
					"Accés à vos données via un identifiant et un mot de passe",
					"Création d'une sauvegarde de la base de données Numérisk",
					"tous les 24h à 2h du matin. (Scalingo)",
					"Chiffrement automatique des données au repos (Scalingo)",
				]}
				marginBottom={14}
			/>

			<PH2>Conformité</PH2>

			<NormalText marginBottom={14}>
				Les données de Numérisk PCS sont sauvegardées chez Scalingo, les
				serveurs utilisés sont ceux de Outscale à Paris, Scalingo est une
				société française basée en Alsace. Elle possède les certifications{" "}
				<Link src="https://scalingo.com/iso-27001-certification">
					ISO 27001
				</Link>{" "}
				, <Link src="https://scalingo.com/hds-certification">HDS</Link> et
				SecNumCloud
			</NormalText>

			<PH2>Transfert hors Union Européenne</PH2>

			<NormalText marginBottom={14}>
				Les données de Numérisk PCS sont sauvegardées au sein de l&apos;Union
				Européenne. (non transféré aux États-Unis)
			</NormalText>

			<PH2>Cookies et stockage hors ligne</PH2>

			<NormalText marginBottom={14}>
				Un seul cookie est utilisé afin de sauvegarder votre session.
			</NormalText>

			<PH2>Accès hors ligne</PH2>
			<NormalText marginBottom={14}>
				Lors de votre première connexion sur la plateforme Numérisk PCS, les
				données de votre commune sont sauvegardées sur votre ordinateur ainsi
				que les cartographies (Base de données indexDB). De plus, certains fonds
				de carte sont également sauvegardés localement afin d&apos;être
				disponibles hors connexion. En cas de coupure de courant, vous pourrez
				toujours accéder à ces informations et à la plupart des fonctionnalités
				de Numérisk PCS. Les données créées hors ligne seront synchronisées
				lorsque vous retrouverez une connexion internet.
			</NormalText>

			<PH2>Téléversement de fichier</PH2>
			<NormalText marginBottom={14}>
				Les fichiers téléversés sur Numérisk PCS par la commune sont sauvegardés
				sur un serveur Amazon hébergé à Paris.
			</NormalText>
		</PPage>
	)
}

export default ConfidentialityRgpd
