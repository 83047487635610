import { yupResolver } from "@hookform/resolvers/yup"
import { IRowMappingResult } from "@/pages/admin/mapping/core/MappingSchemaRunner"
import React, { useContext, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"
import FPointProperty from "utils/components/jsonSchema/form/FPointProperty"
import yupPointProperty from "utils/components/jsonSchema/form/yupPointProperty"
import { getPointTitle } from "utils/components/jsonSchema/useGetPointSummary"
import { Flex } from "utils/components/style/flex"
import { ParentSubmitContext } from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitContext"
import useParentSubmit from "utils/form/specific/fiche/links/utils/parentSubmit/useParentSubmit"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import Yup from "utils/yup"

const SolveDetails = styled.div`
    color: var(--orange700);
    padding-bottom: 10px;
    font-size: 0.94em;
`

export const Title = styled.div`
    color: var(--primary800);
    font-size: 1.05em;
    font-weight: 500;
`

const ErrorsSolverItem = ({
	erroredItem,
	jsonSchemaProperties,
}: {
	erroredItem: IRowMappingResult
	jsonSchemaProperties: IJsonSchemaProperty[]
}) => {
	const schema = useMemo(
		() =>
			Yup.object(
				Object.fromEntries(
					Object.keys(erroredItem.errors).map((key) => {
						const property = jsonSchemaProperties.find(
							(prop) => prop.label === key,
						)
						return [
							key,
							yupPointProperty({
								property: {
									...property,
									isRequired:
										property.isRequired ||
										erroredItem.errors[key].forceRequired,
								},
							}),
						]
					}),
				),
			),
		[erroredItem, jsonSchemaProperties],
	)

	const defaultValues = useMemo(() => {
		const vals = Object.fromEntries(
			Object.keys(erroredItem.errors)
				.map((key) => {
					if (!erroredItem.errors[key].value) return
					return [key, erroredItem.errors[key].value]
				})
				.filter(Boolean),
		)

		return schema.cast(vals)
	}, [schema])

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues,
	})

	useParentSubmit(methods.handleSubmit)

	const title = getPointTitle(jsonSchemaProperties, erroredItem.data)

	return (
		<FormProvider {...methods}>
			<Flex alignItemsStretch directionColumn gap>
				<Title>
					Ligne {erroredItem.index} : {title}
				</Title>
				{Object.entries(erroredItem.errors).map(([key, error]) => {
					const FormComponent = error.component ?? FPointProperty

					const property = jsonSchemaProperties.find(
						(prop) => prop.label === key,
					)

					return (
						<div key={key}>
							<SolveDetails>{error.message}</SolveDetails>
							<FormComponent
								name={key}
								property={{
									...property,
									isReadOnly: false,
									isRequired: property.isRequired || error.forceRequired,
								}}
								{...error.props}
							/>
						</div>
					)
				})}
			</Flex>
		</FormProvider>
	)
}

export default ErrorsSolverItem
