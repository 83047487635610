import { useSelector } from "react-redux"
import _ from "lodash"
import { useMemo } from "react"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import {
	EnumToolPath,
	IAccessRights,
	ITools,
} from "./services/useAccessRightsServices"

export const selectAccessRights = createDeepEqualSelector(
	(state: any) => state.userSettings.settings.accessRights,
	(accessRights: any) => accessRights,
)

const useAccessRights = <T extends EnumToolPath>(toolPath: T) => {
	const accessRights = useSelector(selectAccessRights) as IAccessRights
	const toolsAccessRights = accessRights?.tools

	const calculatedAccess = useMemo(() => {
		const specificToolAccessRight = _.get(toolsAccessRights, toolPath, {
			read: false,
			write: false,
		})

		return specificToolAccessRight
	}, [toolsAccessRights])

	return calculatedAccess as ITools<T>
}

export default useAccessRights
