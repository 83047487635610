import React from "react"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import IPoint from "@/utils/types/IPoint"
import PointPropertyValue from "./PointPropertyValue"

function PointProperty({
	point,
	property,
	align = "left",
	editable = false,
}: {
	point: IPoint
	property: IJsonSchemaProperty
	align?: "left" | "right"
	editable?: boolean
}) {
	if (!property?.name) {
		debugger
	}
	return (
		<PointPropertyValue
			property={property}
			value={
				point.geojson.properties[property.name ? property.name : property.label]
			}
			editable={editable}
			align={align}
		/>
	)
}

export default PointProperty
