import useMultiPolygons from "@/hooks/useMultiPolygons"
import React from "react"
import { CustomChip } from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"
import { TypeComponentProps } from "../PointPropertyValue"
import TypeText from "./TypeText"
import _ from "lodash"
import { DiffSpan } from "./PropertiesType.styled"
function TypeGeoloc({
	value,
	isColumnSectorOnly = false,
	rowDisplay = false,
	fontSize = undefined,
	...props
}: TypeComponentProps) {
	const multiPolygons = useMultiPolygons()

	if (!props.diffWith) {
		const hasAdress = value?.Adresse
		const hasLatAndLng = value?.coo?.lat && value?.coo?.lng
		return (
			<Flex
				directionColumn={!rowDisplay}
				alignItemsEnd={props.align === "right"}
				alignItemsStart={props.align === "left"}
				gap={rowDisplay ? ".5em" : null}
			>
				{!isColumnSectorOnly && (
					<TypeText
						value={
							hasAdress
								? value?.Adresse
								: hasLatAndLng
								? `lat : ${value?.coo?.lat
										?.toString()
										?.slice(0, 9)}, lng : ${value?.coo?.lng
										?.toString()
										?.slice(0, 9)}`
								: "Aucune Adresse"
						}
						fontSize={fontSize}
						{...props}
					/>
				)}
				{value?.secteur && (
					<CustomChip
						size="small"
						$backgroundColor="var(--primary500)"
						label={
							multiPolygons[value?.secteur]?.name
								? multiPolygons[value?.secteur]?.name
								: _.isEmpty(value?.secteur)
								? "Aucun secteur"
								: "Secteurs non à jour"
						}
					/>
				)}
			</Flex>
		)
	}

	const buildCoo = (coo) => {
		if (!coo) return ""
		return `(lat: ${coo.lat} / lng: ${coo.lng})`
	}

	const oldCoo = buildCoo(props.diffWith.coo)
	const newCoo = buildCoo(value?.coo)

	return (
		<Flex
			$wrap
			directionColumn
			alignItemsEnd={props.align === "right"}
			alignItemsStart={props.align === "left"}
			gap="5px"
		>
			{(value?.Adresse || props.diffWith.Adresse) &&
				(value?.Adresse !== props.diffWith.Adresse ? (
					<span>
						{props.diffWith.Adresse && (
							<DiffSpan>{props.diffWith.Adresse}</DiffSpan>
						)}{" "}
						{value?.Adresse && <DiffSpan add>{value?.Adresse ?? ""}</DiffSpan>}
					</span>
				) : (
					<div>{value?.Adresse}</div>
				))}

			{(oldCoo || newCoo) &&
				(oldCoo !== newCoo ? (
					<>
						{oldCoo && <DiffSpan>{oldCoo}</DiffSpan>}{" "}
						{newCoo && <DiffSpan add>{newCoo}</DiffSpan>}
					</>
				) : (
					<div>{newCoo}</div>
				))}
		</Flex>
	)
}

export default TypeGeoloc
