import Axios from "axios"
import ErrorService from "@/services/ErrorService"
import IGeoloc from "utils/types/IGeoloc"

export type ISearchAddress = (
	address: string,
	dispatch,
	setLoading?: (boolean) => void,
) => Promise<IGeoloc[]>
const searchAddress: ISearchAddress = async (address, dispatch, setLoading) => {
	try {
		setLoading?.(true)
		const res = await Axios.post(
			"/googlecall",
			JSON.stringify({
				Adresse: addressAdapter(address),
			}),
		)
		if (res.data === "not ok") {
			// const errorMessage = 'Adresse introuvable, veuillez réessayer'
			// ErrorService.error({
			//     component: 'ApiGoogleAddress:searchAddress',
			//     message: errorMessage,
			//     dispatch,
			// })
		} else {
			return (res?.data ?? []).map((feature) => ({
				Adresse: feature.formatted_address,
				coo: {
					lat: feature.geometry.location.lat,
					lng: feature.geometry.location.lng,
				},
			}))
		}
	} catch (error) {
		const errorMessage = `Erreur, veuillez recommencer l'opération : ${error.message}`
		ErrorService.error({
			component: "ApiGoogleAddress:searchAddress",
			message: errorMessage,
			dispatch,
		})
	} finally {
		setLoading?.(false)
	}
	return []
}

const addressAdapter = (address: string) => {
	const replace = {
		"&": "et",
		",": " ",
	}

	return Object.keys(replace).reduce(
		(acc, key) => acc.replace(new RegExp(key, "g"), replace[key]),
		address,
	)
}

export default {
	searchAddress,
}
