import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import {
	addMultipolygon,
	createLocalMultipolygon,
	deleteLocalMultipolygon,
	removeMultiPolygon,
	setLoadedMultipolygon,
	updateLocalMultipolygon,
} from "./reducer"
import { MULTI_POLYGONS } from "@/redux-toolkit/time/constants"
import ApiMultiPolygon from "./MultiPolygonApi"
import DatabaseService from "@/services/DatabaseService"
import ErrorService from "@/services/ErrorService"
import { createAsyncThunk } from "@reduxjs/toolkit"
import SynchroMultiPolygonService from "@/services/synchro/SynchroMultiPolygonService"
import MultiPolygonApi from "./MultiPolygonApi"
import { toast } from "react-toastify"

export const getMultiGeojson = async (dispatch, state) => {
	try {
		const { multiPolygons, serverDatetime } =
			await ApiMultiPolygon.getMultiPolygons(state.time[MULTI_POLYGONS])
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: MULTI_POLYGONS,
			}),
		)
		multiPolygons.forEach(({ geojson, name, ...multiPolygon }) => {
			dispatch(addMultipolygon({ name, ...multiPolygon, loaded: false }))
		})
		await Promise.all(
			multiPolygons.map(async (multipolygon) => {
				const detailsMultiPolygon =
					await ApiMultiPolygon.getMultiPolygonDetails(multipolygon.id)
				await DatabaseService.set(
					multipolygon.id,
					JSON.stringify(detailsMultiPolygon.multiPolygon),
				)

				return dispatch(setLoadedMultipolygon(multipolygon.id))
			}),
		)
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des couches polygon, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:getMultiGeojson",
			message: errorMessage,
			dispatch,
		})
	}
}
export const createMultiPolygon = createAsyncThunk(
	"daybook/createMultiPolygon",
	async (multiPolygon: any, { dispatch, getState }) => {
		dispatch(createLocalMultipolygon(multiPolygon))
		await SynchroMultiPolygonService.synchronize(dispatch, getState)
	},
)

export const updateMultiPolygon = createAsyncThunk(
	"daybook/updateMultiPolygon",
	async (multiPolygon: any, { dispatch, getState }) => {
		dispatch(updateLocalMultipolygon(multiPolygon))
		await SynchroMultiPolygonService.synchronize(dispatch, getState)
	},
)

export const deleteMultiPolygon = createAsyncThunk(
	"daybook/deleteMultiPolygon",
	async (multiPolygon: any, { dispatch, getState }) => {
		dispatch(deleteLocalMultipolygon(multiPolygon))
		await SynchroMultiPolygonService.synchronize(dispatch, getState)
	},
)

export const synchronizeCreatedMultiPolygon = async (
	dispatch,
	multiPolygon: any,
) => {
	const { localStatus, ...toCreateMultiPolygon } = multiPolygon
	try {
		const createdMultiPolygon = await MultiPolygonApi.createMultiPolygon(
			toCreateMultiPolygon,
		)
		dispatch(addMultipolygon(createdMultiPolygon))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la création de multiPolygon, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeCreatedMultiPolygon",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeUpdatedMultiPolygon = async (
	dispatch,
	multiPolygon,
) => {
	const { localStatus, ...toUpdateMultiPolygon } = multiPolygon
	try {
		const updatedMultiPolygon = await MultiPolygonApi.updateMultiPolygon(
			toUpdateMultiPolygon,
		)
		dispatch(addMultipolygon(updatedMultiPolygon))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la modification de multiPolygon, message d'erreur : ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeUpdatedMultiPolygon",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeDeletedMultiPolygon = async (
	dispatch,
	multiPolygon,
) => {
	try {
		await MultiPolygonApi.deleteMultiPolygon(multiPolygon.id)
		dispatch(removeMultiPolygon(multiPolygon.id))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la suppression de multiPolygon, message d'erreur : ${error.message}'`
		ErrorService.error({
			component: "resources:synchronizeDeletedMultiPolygon",
			message: errorMessage,
			dispatch,
		})
	}
	return
}

export const getMultiPolygonPublic = async (dispatch, communeId) => {
	try {
		const { multi_polygons } = await MultiPolygonApi.getPublicMultiPolygons(
			communeId,
		)

		multi_polygons.forEach((point) => dispatch(addMultipolygon(point)))

		await Promise.all(
			multi_polygons.map(async (multipolygon) => {
				const detailsMultiPolygon =
					await ApiMultiPolygon.getPublicMultiPolygonDetails(multipolygon.id)
				await DatabaseService.set(
					multipolygon.id,
					JSON.stringify(detailsMultiPolygon.multiPolygon),
				)

				return dispatch(setLoadedMultipolygon(multipolygon.id))
			}),
		)
	} catch (error) {
		console.error(error)
		throw new Error(
			`Erreur lors de la récupération des polygons, message d'erreur : ${error.message}`,
		)
	}
}
const MAX_RETRY_COUNT = 3;
const RETRY_DELAY_MS = 20000;

async function makeRequestWithRetry(multipolygon) {
	try {
		const detailsMultiPolygon = await ApiMultiPolygon.getMultiPolygonDetails(
			multipolygon.id
		);
		await DatabaseService.set(
			multipolygon.id,
			JSON.stringify(detailsMultiPolygon.multiPolygon)
		);
		return true;
	} catch (error) {
		return false;
	}
}

async function processMultiPolygons(multiPolygons, retryCount = 0, dispatch) {
	for (const multipolygon of multiPolygons) {
		const success = await makeRequestWithRetry(multipolygon);
		if (!success) {
			if (retryCount < MAX_RETRY_COUNT) {
				console.log(
					`Failed to process multipolygon with id ${multipolygon.id}. Retrying in 20 seconds..., attempt ${retryCount + 1} of ${MAX_RETRY_COUNT}`
				);
				await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY_MS));
				await processMultiPolygons([multipolygon], retryCount + 1, dispatch);
			} else {
				console.log(
					`Failed to process multipolygon with id ${multipolygon.id} after ${MAX_RETRY_COUNT} attempts. Aborting.`
				);
				// Handle the case where the request has failed after all retries.
				// For example, you might want to log an error or take further action.
			}
		} else {
			// Dispatch the success action only when the request is successful.
			dispatch(setLoadedMultipolygon(multipolygon.id));
		}
	}
}
export const getMultiPolygon = async (dispatch, getState) => {
	try {
		const { multiPolygons, serverDatetime } =
			await MultiPolygonApi.getMultiPolygons(getState().time[MULTI_POLYGONS])
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: MULTI_POLYGONS,
			}),
		)
		multiPolygons.forEach((point) => dispatch(addMultipolygon(point)))

		await processMultiPolygons(multiPolygons, 0, dispatch);

	} catch (error) {
		console.error(error)
		throw new Error(
			`Erreur lors de la récupération des polygons, message d'erreur : ${error.message}`,
		)
	}
}
export const getMultiPolygonsWithDeleted = async (dispatch, getState) => {
	try {
		const { multiPolygons, serverDatetime } =
			await MultiPolygonApi.getMultiPolygonsWithDeleted(
				getState().time[MULTI_POLYGONS],
			)
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: MULTI_POLYGONS,
			}),
		)
		multiPolygons.forEach((multiPolygon) => {
			if (multiPolygon.deleted) {
				dispatch(removeMultiPolygon(multiPolygon))
				return
			}
			dispatch(addMultipolygon(multiPolygon))
		})
		await Promise.all(
			multiPolygons.map(async (multipolygon) => {
				if (multipolygon.deleted) {
					return
				}
				const detailsMultiPolygon =
					await ApiMultiPolygon.getMultiPolygonDetails(multipolygon.id)
				await DatabaseService.set(
					multipolygon.id,
					JSON.stringify(detailsMultiPolygon.multiPolygon),
				)

				return dispatch(setLoadedMultipolygon(multipolygon.id))
			}),
		)
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des couches polygon, message : ${error.message}`
		ErrorService.error({
			component: "resources:getMultiPolygonsWithDeleted",
			message: errorMessage,
			dispatch,
		})
	}
	return
}
