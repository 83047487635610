import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "../rootActions"
import { initialState } from "./constants"

const timeSlice = createSlice({
	name: "time",
	initialState,
	reducers: {
		setServerDatetime(state, { payload: { entity, datetime } }) {
			state[entity] = datetime
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default timeSlice.reducer
export const { setServerDatetime } = timeSlice.actions
