import { FormControl, FormHelperText, InputLabel } from "@mui/material"
import React, { useState } from "react"
import styled, { css } from "styled-components"

const SFormControl = styled(FormControl)<{ disabled: boolean }>`
    ${({ disabled }) =>
			disabled &&
			css`
            pointer-events: none;
        `}
    width: 100%;
`

const Outline = styled.div<{
	focused: boolean
	error: boolean
	hovered: boolean
	disabled: boolean
	noBorder: boolean
}>`
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    overflow: hidden;
    min-width: 0%;

    border-radius: 4px;
    border-style: solid;
	${({ noBorder }) => noBorder && "border: none !important;"};

    border-color: ${({ focused, error, hovered, disabled, noBorder }) => {
			if (noBorder) {
				return "transparent"
			}
			return disabled
				? "rgba(0, 0, 0, 0.26)"
				: error
				? "#d32f2f"
				: focused
				? "#1976d2"
				: hovered
				? "rgba(0, 0, 0, 0.87)"
				: "rgba(0, 0, 0, 0.23)"
		}};

    border-width: ${({ focused }) => (focused ? "2px" : "1px")};
`

const SInputLabel = styled(InputLabel)`
    position: relative;
    overflow: visible !important;

    :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        background-color: white;
        pointer-events: none;
        bottom: 0;
        left: -5px;
        right: -8px;
        z-index: -1;
    }
`

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`

const NewStyledInputLayout = ({
	label = undefined,
	error,
	helperText,
	empty,
	children,
	disabled = false,
	onClick = undefined,
	onBlur = undefined,
	noBorder = false,
}) => {
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)

	return (
		<SFormControl
			disabled={disabled}
			error={error}
			onFocus={() => {
				setFocused(true)
				onClick?.()
			}}
			onBlur={() => {
				setFocused(false)
				onBlur?.()
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{label && (
				<SInputLabel shrink={!empty || focused} focused={focused}>
					{label}
				</SInputLabel>
			)}
			<InputWrapper>
				{children}
				<Outline
					noBorder={noBorder}
					focused={focused}
					hovered={hovered}
					error={error}
					disabled={disabled}
				/>
			</InputWrapper>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</SFormControl>
	)
}

export default NewStyledInputLayout
