import React, { createContext, useContext, useState } from "react"

const EasyPrintContext = React.createContext({
	setEasyPrint: () => {},
	easyPrint: undefined,
} as {
	setEasyPrint: React.Dispatch<any>
	easyPrint: any
})

export const useEasyPrint = () => {
	const { easyPrint, setEasyPrint } = useContext(EasyPrintContext)
	return { easyPrint, setEasyPrint }
}

export default EasyPrintContext
