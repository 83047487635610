import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"

const initialState = {}

const jsonSchemaSlice = createSlice({
	name: "data/jsonSchema",
	initialState,
	reducers: {
		addJsonSchema(state, { payload: jsonSchema }) {
			state[jsonSchema.id] = jsonSchema
		},
		removeJsonSchema(state, { payload: id }) {
			delete state[id]
		},
		setJsonSchema(state, { payload: jsonSchemas }) {
			Object.keys(state).forEach((id) => {
				delete state[id]
			})
			jsonSchemas.forEach((jsonSchema) => (state[jsonSchema.id] = jsonSchema))
		},
		togglePublicJsonSchema(state, { payload: id }) {
			state[id].public = !state[id].public
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default jsonSchemaSlice.reducer
export const {
	addJsonSchema,
	removeJsonSchema,
	setJsonSchema,
	togglePublicJsonSchema,
} = jsonSchemaSlice.actions
