// ! Category

export const RISQUES_SOCIETAUX = "Risques sociétaux"
export const RISQUES_SANITAIRES = "Risques sanitaires"
export const RISQUES_TECHNOLOGIQUES = "Risques technologiques"
export const RISQUES_NATURELS = "Risques naturels"
export const RISQUES_CYBER = "Risques cyber"

// ! Risks

export const MENACE_TERRORISTE = "Menace terroriste"
export const MANIFESTATION = "Manifestation"

export const PANDEMY = "Pandémie"
export const EPIZOOTIE = "Épizootie"
export const CANICULE = "Canicule"
export const POLLUTION_AIR = "Pollution de l’air"

export const INDUSTRIEL = "Industriel"
export const TMD = "Transport de Matières Dangereuses"
export const NUCLEAIRE = "Nucléaire"
export const POLLUTION_MARINE = "Pollution marine"
export const POLLUTION_EAU_POTABLE = "Pollution réseau d’eau potable"
export const RESEAUX_CANALISATION = "Réseaux et canalisation"
export const RISQUE_MINIER = "Risque minier"
export const BARRAGE = "Rupture de barrage"

export const TEMPETE_ET_VENTS_VIOLENTS = "Tempête et vents violents"
export const SUBMERSION = "Submersion"
export const EROSION = "Érosion"
export const SEISME = "Séisme"
export const INONDATION = "Inondation"
export const MOUVEMENT_DE_TERRAIN = "Mouvement de terrain"
export const CAVITE_SOUTERRAINES = "Cavités souterraines"
export const RETRAIT_GONFLEMENT_ARGILES = "Retrait et gonflement des argiles"
export const AVALANCHE = "Avalanche"
export const VOLCANISME = "Volcanisme"
export const CONFLITS_ET_AIDE_HUMANITAIRE = "Conflits et aide humanitaire"
export const CYCLONE = "Cyclone"
export const FEUX_DE_FORET = "Feux de forêt"
export const GRAND_FROID = "Grand froid"
export const TSUNAMI = "Tsunami"
export const SECHERESSE = "Sécheresse"
export const RADON = "Radon"
export const ENGINS_DE_GUERRE = "Engins de guerre"
export const SOLS_POLLUES = "Sols pollués"
export const CYBERCRIMINALITE = "Cybercriminalité"
export const ATTEINTE_IMAGE = "Atteinte à l’image"
export const ESPIONNAGE = "Espionnage"
export const SABOTAGE = "Sabotage"

const getRisques = () => ({
	[RISQUES_SOCIETAUX]: [
		MENACE_TERRORISTE,
		MANIFESTATION,
		CONFLITS_ET_AIDE_HUMANITAIRE,
	],
	[RISQUES_SANITAIRES]: [
		PANDEMY,
		EPIZOOTIE,
		CANICULE,
		POLLUTION_AIR,
		SOLS_POLLUES,
	],
	[RISQUES_TECHNOLOGIQUES]: [
		INDUSTRIEL,
		TMD,
		NUCLEAIRE,
		POLLUTION_MARINE,
		POLLUTION_EAU_POTABLE,
		RESEAUX_CANALISATION,
		RISQUE_MINIER,
		BARRAGE,
		ENGINS_DE_GUERRE,
	],
	[RISQUES_NATURELS]: [
		TEMPETE_ET_VENTS_VIOLENTS,
		SUBMERSION,
		EROSION,
		SEISME,
		INONDATION,
		MOUVEMENT_DE_TERRAIN,
		CAVITE_SOUTERRAINES,
		RETRAIT_GONFLEMENT_ARGILES,
		AVALANCHE,
		VOLCANISME,
		CYCLONE,
		FEUX_DE_FORET,
		GRAND_FROID,
		TSUNAMI,
		SECHERESSE,
		RADON,
	],
	[RISQUES_CYBER]: [CYBERCRIMINALITE, ATTEINTE_IMAGE, ESPIONNAGE, SABOTAGE],
})

const getRiskParent = (risk) => {
	const allRisks = getRisques()
	let parent
	Object.entries(allRisks).forEach(([key, value]) => {
		if (value.includes(risk)) {
			parent = key
		}
	})
	return parent
}

const getRisquesList = () => {
	const allRisks = getRisques()
	const arrayRisks = [
		...allRisks[RISQUES_SOCIETAUX],
		...allRisks[RISQUES_SANITAIRES],
		...allRisks[RISQUES_TECHNOLOGIQUES],
		...allRisks[RISQUES_NATURELS],
		...allRisks[RISQUES_CYBER],
	]
	return arrayRisks.sort()
}

export default {
	getRisques,
	getRisquesList,
	getRiskParent,
}
