import EditIcon from "@mui/icons-material/Edit"
import MailIcon from "@mui/icons-material/Mail"
import PersonIcon from "@mui/icons-material/Person"
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"
import RoomIcon from "@mui/icons-material/Room"
import {
	Avatar,
	CardHeader,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import customCopy from "utils/customCopy"
import HumanService from "utils/services/HumanService"
import { useSelector } from "react-redux"
const STableRow = styled(TableRow)`
    cursor: copy !important;
    & .MuiTableCell-body {
        padding: 8px !important;
    }
`
const HumanTypeRow = styled(TableRow)`
    & .MuiTableCell-body {
        padding: 0 8px 8px 8px !important;
    }
`

const NoWrap = styled.span`
    white-space: nowrap;
`

export const HumanTooltipInfos = ({
	human,
	writePermission,
	renderDetails = true,
}) => {
	const communeId = useSelector((state: any) => state.commune.id)
	if (!human) return null
	const dispatch = useDispatch()
	const humanProperties = human?.geojson?.properties
	const displayJobAndService = () => {
		const jobTitle = humanProperties?.Poste ?? ""
		const service = humanProperties?.Service ? (
			<i>{humanProperties?.Service}</i>
		) : (
			""
		)
		const separator =
			humanProperties?.Poste && humanProperties?.Service ? ", " : ""
		return (
			<>
				{jobTitle}
				{separator}
				{service}
			</>
		)
	}

	return (
		<>
			<CardHeader
				avatar={<Avatar>{HumanService.displayInitials(human)}</Avatar>}
				title={HumanService.displayFullName(human)}
				subheader={displayJobAndService()}
				action={
					writePermission ? (
						<IconButton onClick={() => dispatch(setCurrentPoint(human))}>
							<EditIcon />
						</IconButton>
					) : null
				}
			/>
			{renderDetails && (
				<Table>
					<TableBody>
						<HumanTypeRow>
							<TableCell colSpan={3}>
								{HumanService.displayTypeAsChip(human, true)}
							</TableCell>
						</HumanTypeRow>
						{(humanProperties?.Téléphones ?? []).map((phone) => {
							return (
								<STableRow
									key={phone.Type}
									hover
									onClick={() => customCopy(phone.Numéro)}
								>
									<TableCell>
										<PhoneAndroidIcon />
									</TableCell>
									<TableCell>{phone.Type}</TableCell>
									<TableCell>
										<NoWrap>{phone.Numéro}</NoWrap>
									</TableCell>
								</STableRow>
							)
						})}

						{humanProperties?.Email && (
							<STableRow
								hover
								onClick={() => customCopy(humanProperties.Email)}
							>
								<TableCell>
									<MailIcon />
								</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>{humanProperties.Email}</TableCell>
							</STableRow>
						)}

						{humanProperties?.Poste && (
							<STableRow
								hover
								onClick={() => customCopy(humanProperties.Poste)}
							>
								<TableCell>
									<PersonIcon />
								</TableCell>
								<TableCell>Poste</TableCell>
								<TableCell>{humanProperties.Poste}</TableCell>
							</STableRow>
						)}

						{humanProperties?.Adresse?.Adresse &&
							human.commune_id === communeId && (
								<STableRow
									hover
									onClick={() => customCopy(humanProperties.Adresse.Adresse)}
								>
									<TableCell>
										<RoomIcon />
									</TableCell>
									<TableCell>Adresse</TableCell>
									<TableCell>{humanProperties.Adresse.Adresse}</TableCell>
								</STableRow>
							)}
					</TableBody>
				</Table>
			)}
		</>
	)
}

export default HumanTooltipInfos
