import React from "react"
import { FormProvider } from "react-hook-form"
import SuperModal from "@/utils/modals/SuperModal"
import { Flex } from "@/utils/components/style/flex"
import FExtractSelect from "@/utils/form/FExtractSelect"
import { specificityOptions } from "@/utils/form/specific/care/FamilyMemberCard"
import FDate from "@/utils/form/FDate"
import { IFamilyMemberType } from "@/utils/types/IFamilyMember"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const ModalCAREExtraction = ({ isOpen, handleExtract, methods, toggler }) => {
	const specOptions = [
		...specificityOptions.map((option) => ({
			label: option.label,
			value: option.label,
		})),
	]
	const typoOptions = [
		{
			value: IFamilyMemberType.ADULT,
			label: "Adultes",
		},
		{
			value: IFamilyMemberType.CHILD,
			label: "Enfants",
		},
		{
			value: IFamilyMemberType.BABY,
			label: "Bébé",
		},
		{
			value: IFamilyMemberType.ANIMAL,
			label: "Animaux",
		},
	]

	const onToggle = () => {
		toggler()
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				title="Préparation de l'extraction"
				isNextable
				isNew
				onClick={methods.handleSubmit(handleExtract)}
				onClose={onToggle}
				toggleOnClickOutside={false}
				writePermissions={false}
			>
				<>
					<Flex directionColumn gap="1rem">
						<FDate
							name="Trier par date de arrivée ( facultatif )"
							label="Trier par date de arrivée ( facultatif )"
							required={false}
						/>
						<FDate
							name="Trier par date de sortie ( facultatif )"
							label="Trier par date de sortie ( facultatif )"
							required={false}
						/>
						<FExtractSelect
							options={specOptions}
							name="Trier par spécificités ( facultatif )"
							label="Trier par spécificités ( facultatif )"
							multiple={false}
						/>
						<FExtractSelect
							options={typoOptions}
							name="Trier par typologies ( facultatif )"
							label="Trier par typologies ( facultatif )"
							multiple={false}
						/>
					</Flex>
				</>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalCAREExtraction
