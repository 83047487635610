import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useAccessRights from "@/hooks/useAccessRights"
import useHumans from "@/hooks/useHumans"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	deletePointWithUpdateEvent,
	updatePoint,
} from "@/redux-toolkit/data/points/resources"
import { SUIVI_PCS } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import Moment from "moment"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import TypeResponsables from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import FSelectHumans from "@/utils/form/FSelectHumans"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import getImage from "@/utils/getImage"
import { sortTypeDateFactory } from "@/utils/sort"
import IJsonSchema from "@/utils/types/IJsonSchema"
import { v4 as uuidv4 } from "uuid"
import Filter from "../following/filter/Filter"
import useFilterForm, { NO_FILTER } from "../following/filter/useFilterForm"
import { NextSuiviDateField } from "./FieldSuivi"
import ModalListSuiviImport from "./ModalListSuiviImport"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { H2 } from "@/utils/components/style/header"
import { useBoolean } from "react-use"
import moment from "moment"
import { width100 } from "@/utils/components/tables/widthProps"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import TableSuiviContactSubRow from "../subComponents/TableSuiviContactSubRow"
import FSelect from "@/utils/form/FSelect"
import usePointPropertyToText from "@/utils/components/jsonSchema/properties/usePointPropertyToText"
import HumanService from "@/utils/services/HumanService"
import Button from "@/styles/atoms/Button"
import { isMobile } from "react-device-detect"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import ISuivi from "@/utils/types/ISuivi"
import ISupport from "@/utils/types/ISupport"
import ModalSupport from "./ModalSupport"

const WITHOUT_SUIVI = "Sans suivi"

export const getNextSuivi = (suivis) => {
	let nextSuivi = {}

	const now = moment()
	const sorted = _.sortBy(suivis, ["Date prise de contact"])
	sorted.forEach((suivi) => {
		if (!_.isEmpty(nextSuivi)) return
		if (now.isBefore(suivi["Date prise de contact"])) {
			nextSuivi = suivi
		}
	})

	return nextSuivi
}

const TableSupport = () => {
	const points = usePoints()
	const { facilityId: facilityIdParams } = useParams<{
		facilityId: string
	}>()
	const facilityId =
		facilityIdParams === "undefined" ? undefined : facilityIdParams
	const facility = Object.values(points).find(
		(point) => point.id === facilityId,
	)

	const [currentSupport, setCurrentSupport] = useState(undefined)
	const [isVirtual, setIsVirtual] = useBoolean(false)
	const [isOpenModalSupport, setIsOpenModalSupport] = useBoolean(false)
	const [openModalVirtualizedDestruction, setOpenModalVirtualizedDestruction] =
		useState(undefined)
	const [expandedRowId, setExpandedRowId] = useState(undefined)
	const humans = useHumans()
	const multiPolygons = useMultiPolygons()
	const jsonSchemas = useJsonSchemasWithStatics()
	const dispatch = useDispatch()
	const history = useHistory()
	const [isOpenModalListImport, setIsOpenModalListImport] = useState(false)
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.SUIVI_PCS)
	const isLocked = selectedEvent?.locked
	const pointToText = usePointPropertyToText()

	const sortedData = useMemo(() => {
		const filteredSupportPoints = Object.values(points)
			.filter((point) => point.jsonschema_id === SUIVI_PCS)
			.filter((point) => point.geojson.properties.suivi_group_id === facilityId)
			.filter(
				(point) =>
					!!jsonSchemas[point.geojson.properties.relatedObject.categoryId],
			)
		return filteredSupportPoints
	}, [points, facilityIdParams])

	let restrainedHumanIds = []
	sortedData.forEach((point) => {
		const respId = point.geojson.properties?.responsableId
		if (!_.isEmpty(respId)) {
			restrainedHumanIds = [...restrainedHumanIds, ...respId]
		}
	})
	restrainedHumanIds = _.uniq(restrainedHumanIds)

	const filterSelectOptions = useMemo(() => {
		const actionOptions = []
		const categoryPcsOptions = []
		Object.values(points)
			.filter(
				(point) =>
					point.jsonschema_id === SUIVI_PCS &&
					point.geojson.properties.suivi_group_id === facilityId,
			)
			.forEach((point) => {
				const support = point.geojson.properties as ISupport
				const categoryId = support.relatedObject["categoryId"]
				support.suivi.forEach((suivi) => {
					const currentOption = suivi?.["Suites à donner"]
					if (currentOption && !actionOptions.includes(currentOption)) {
						actionOptions.push(currentOption)
					}
					if (categoryId && !categoryPcsOptions.includes(categoryId)) {
						categoryPcsOptions.push(categoryId)
					}
				})
			})
		return {
			actionOptions: actionOptions,
			categoryPcsOptions: categoryPcsOptions,
		}
	}, [points])

	const filterOptions = [
		{
			name: "Référents",
			component: FSelectHumans,
			componentOptionalProps: {
				restrainedHumanIds,
			},
			default: [],
		},
		{
			name: "Actions",
			component: FSelect,
			componentOptionalProps: {
				label: "Actions",
				options: [
					...(true && [WITHOUT_SUIVI]),
					...filterSelectOptions.actionOptions,
				],
			},
			default: null,
		},
		{
			name: "Liste du PCS",
			component: FSelect,
			componentOptionalProps: {
				label: "Liste",
				options: filterSelectOptions.categoryPcsOptions,
				renderOption: (props, jsonschema_id) => {
					return (
						<li {...props} key={jsonschema_id}>
							<Flex gap={5}>
								<img
									src={getImage(jsonSchemas[jsonschema_id]?.imgId)}
									width={25}
									height={25}
								/>
								{jsonSchemas[jsonschema_id]?.title}
							</Flex>
						</li>
					)
				},
				getOptionLabel: (jsonschema_id) =>
					jsonSchemas[jsonschema_id]?.title ?? "",
			},
			default: null,
		},
	]

	const { values, ...filterConfig } = useFilterForm({
		// @ts-ignore
		options: filterOptions,
	})

	const getIdentityValue = (row) => {
		const support = row?.geojson?.properties as ISupport
		const relatedObject = support.relatedObject
		const properties =
			jsonSchemas?.[relatedObject?.categoryId]?.template?.properties ?? []
		const identifiant = properties.filter(
			(property) => property.isIdentifiant,
		)?.[0]
		const identifiant2 = properties.filter(
			(property) => property.isIdentifiant,
		)?.[1]
		if (identifiant === undefined) {
			return
		}
		const identifiantValue =
			points[relatedObject?.objectId]?.geojson?.properties?.[
				identifiant?.name
			] ?? ""
		const identifiantValue2 =
			points[relatedObject?.objectId]?.geojson?.properties?.[
				identifiant2?.name
			] ?? ""
		if (identifiantValue.secteur) {
			return multiPolygons[identifiantValue.secteur]?.name
		}
		return `${identifiantValue} ${identifiantValue2}`
	}

	const columns = useMemo(
		() =>
			[
				{
					header: "Liste du PCS",
					id: "geojson.properties.categorie_pcs",
					accessorFn: (props) =>
						props.geojson.properties?.relatedObject?.categoryId,
					cell: ({ getValue }) => {
						const value = getValue()
						const jsonSchema = (jsonSchemas[value] ?? {}) as IJsonSchema
						if (!jsonSchema.imgId) {
							return <>Formulaire supprimé</>
						}
						return (
							<Flex gap="0.25rem">
								<img
									src={getImage(jsonSchemas[value]?.imgId)}
									height={30}
									width={30}
								/>
								{jsonSchema?.title}
							</Flex>
						)
					},
				},
				{
					header: "Identité",
					id: "geojson.properties.identifiant",
					accessorFn: getIdentityValue,
					filter: (rows, id, filterValue) => {
						return rows.filter((row) => {
							const rowValue = getIdentityValue(row)
							return !formatNfdLowerCase(rowValue).includes(
								formatNfdLowerCase(filterValue),
							)
						})
					},
				},
				{
					header: "Prochaine action",
					id: "geojson.properties.suivi",
					accessorFn: (props) => {
						const nextSupportSuivi = getNextSuivi(
							props.geojson.properties?.suivi,
						)
						return nextSupportSuivi["Suites à donner"] ?? "Pas d'action prévue"
					},
				},
				{
					header: "Date",
					id: "Date prise de contact",
					accessorFn: (props) => {
						const nextSupportSuivi = getNextSuivi(
							props.geojson.properties?.suivi,
						)
						return nextSupportSuivi["Date prise de contact"] ?? ""
					},
					sortType: sortTypeDateFactory(
						"geojson.properties.suivi[0].Date prise de contact",
						true,
					),
					cell: NextSuiviDateField,
				},
				{
					header: "Observation",
					id: "Observation",
					accessorFn: (props) => {
						const nextSupportSuivi = getNextSuivi(
							props.geojson.properties?.suivi,
						)
						return nextSupportSuivi["Observation"] ?? "-"
					},
				},
				{
					header: "Référent",
					id: "geojson.properties.responsableId",
					accessorKey: "geojson.properties.responsableId",
					cell: (props) => (
						<TypeResponsables
							editable={false}
							value={props.row.original.geojson?.properties?.responsableId}
							align="left"
						/>
					),
				},
				{
					header: "Suivis",
					accessorFn: (props) => {
						return (
							props.geojson.properties?.suivi?.length ?? "Aucun suivi recensé"
						)
					},
					cell: ({ row, table: { toggleAllRowsExpanded } }) => {
						const isExpanded = row.getIsExpanded()
						if (
							!isExpanded &&
							expandedRowId === row.original.id &&
							expandedRowId
						) {
							row.toggleExpanded()
						}

						const numberOfSuivis =
							row.original.geojson.properties?.suivi?.length
						if (!numberOfSuivis) {
							return <div />
						}
						return (
							<PrimaryTextButton
								endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
								onClick={() => {
									if (isExpanded) {
										setExpandedRowId(undefined)
										row.toggleExpanded()
										return
									}
									toggleAllRowsExpanded(false)
									setExpandedRowId(row.original.id)
									row.toggleExpanded()
								}}
							>
								{numberOfSuivis}
							</PrimaryTextButton>
						)
					},
					enableSorting: false,
					...width100,
					meta: {
						expandableCell: true,
					},
				},
			] as ColumnDef<any, any>[],
		[humans, expandedRowId],
	)

	const tmpFilteredSupports = sortedData.filter((point) => {
		const referentIds = values["Référents"]
		const suiviActionName = values["Actions"]
		const pcsCategory = values["Liste du PCS"]
		if (_.isEmpty(referentIds) && !suiviActionName && !pcsCategory) return true

		return (
			(!_.isEmpty(referentIds)
				? point.geojson.properties.responsableId?.some((id) =>
						referentIds.includes(id),
					)
				: true) &&
			(suiviActionName
				? suiviActionName === WITHOUT_SUIVI
					? !point.geojson.properties.suivi.length
					: point.geojson.properties.suivi?.some((field) =>
							_.isEqual(field["Suites à donner"], suiviActionName),
						)
				: true) &&
			(pcsCategory
				? _.isEqual(
						point.geojson.properties.relatedObject["categoryId"],
						pcsCategory,
					)
				: true)
		)
	})

	let virtualizedSuivi = []
	const facilityJsonSchemaActivated =
		facility.geojson.properties?.suiviJsonSchemaActivated ?? []

	const objectIdsConcerned = sortedData.map((point) => {
		return point.geojson.properties.relatedObject.objectId
	})
	facilityJsonSchemaActivated.forEach((jsonSchemaId) => {
		if (!jsonSchemas[jsonSchemaId]) return
		Object.values(points)
			.filter((point) => point.jsonschema_id === jsonSchemaId)
			.forEach((point) => {
				if (objectIdsConcerned.includes(point.id)) {
					return
				}
				virtualizedSuivi.push({
					geojson: {
						properties: {
							relatedObject: {
								categoryId: jsonSchemaId,
								objectId: point.id,
							},
							responsableId: [],
							suivi: [],
						},
					},
					id: uuidv4(),
					jsonschema_id: SUIVI_PCS,
					event_id: selectedEvent?.id,
					virtualized: true,
				})
			})
	})
	if (
		!_.isEmpty(values["Référents"]) ||
		(!_.isEmpty(values["Actions"]) &&
			!_.isEqual(values["Actions"], WITHOUT_SUIVI)) ||
		!_.isEmpty(values["Liste du PCS"])
	) {
		virtualizedSuivi = []
	}

	//* CSV EXTRACTION part
	const enhancedData = useMemo(() => {
		const data = tmpFilteredSupports.map((point) => {
			const headers = []
			jsonSchemas?.[
				point?.geojson?.properties?.relatedObject?.categoryId
			]?.template?.properties?.forEach((property) => {
				if (property.type === "responsables") {
					return
				}
				headers.push({
					title: property.label,
					accessorKey: property.label,
				})
			})
			const values = {}
			headers.forEach((header) => {
				if (header.title === "Coordonnées") {
					const coords = _.get(
						points[point?.geojson?.properties?.relatedObject?.objectId],
						`geojson.properties.${header.accessorKey}`,
						"",
					)
					if (coords) {
						const coordsArray = []
						coords.forEach((coord) =>
							coordsArray.push(`${coord.Type} : ${coord.Numéro}`),
						)
						values[header.title] = coordsArray.join(" / ")
					}

					return
				}
				const currentPoint =
					points[point?.geojson?.properties?.relatedObject?.objectId]
				const currentJsonSchema = jsonSchemas?.[currentPoint?.jsonschema_id]
				try {
					const text = pointToText(
						currentPoint,
						currentJsonSchema.template.properties.find(
							(property) => property.name === header.title,
						),
					)
					values[header.title] = text
				} catch (e) {
					debugger
				}
			})

			return {
				"Détails suivi": point?.geojson?.properties?.suivi,
				"Suites à donner": point?.geojson?.properties?.suivi?.map(
					(suivi) => suivi["Suites à donner"],
				),
				Responsable: point?.geojson?.properties?.responsableId?.map((id) =>
					HumanService.displayFullName(points[id]),
				),
				headers: headers,
				"Catégorie PCS":
					jsonSchemas?.[point?.geojson?.properties?.relatedObject?.categoryId]
						?.title,
				...values,
			}
		})
		return data
	}, [tmpFilteredSupports])

	const csvData = useMemo(() => {
		const csvRows = []
		if (enhancedData === undefined || enhancedData.length === 0) {
			return csvRows
		}

		enhancedData.forEach((element) => {
			csvRows.push([
				...enhancedData[0].headers.map((h) => `=""${element[h.title] ?? ""}""`),
				`=""${element["Responsable"]}""`,
				`=""${element["Catégorie PCS"]}""`,
				"",
				"",
			])
			const details = element["Détails suivi"] ?? []
			details.forEach((detail) => {
				const newElement = ["", "", ...enhancedData[0].headers.map(() => "")]
				csvRows.push([
					...newElement,
					`=""${
						moment(detail["Date prise de contact"]).format("L") +
						" - " +
						moment(detail["Date prise de contact"]).format("LT")
					}""`,
					detail["Suites à donner"],
					detail["Observation"],
				])
			})
		})
		return csvRows
	}, [enhancedData])

	const convertToCsv = useMemo(
		() => () => {
			const enhancedHeader = enhancedData[0]?.headers?.map((h) => h.title) ?? []
			return {
				headers: [
					...enhancedHeader,
					"Référent",
					"Catégorie PCS",
					"Suivi : Date",
					"Suivi : Action",
					"Suivi : Observations",
				],
				data: csvData,
				filename:
					selectedEvent?.name +
					"_Aide aux population - Dispositif " +
					facility.geojson.properties?.name +
					".csv",
			}
		},
		[tmpFilteredSupports],
	)

	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<>
			<PrimaryTextButton
				startIcon={<ArrowBackIcon />}
				onClick={() => {
					history.push("/maincourante/donnees")
				}}
			>
				Retour
			</PrimaryTextButton>
			<H2>{facility.geojson.properties?.name}</H2>
			<SuperReactTable
				data={[...tmpFilteredSupports, ...virtualizedSuivi].map((data) => ({
					...data,
					searchIdentity: getIdentityValue(data),
				}))}
				columns={columns}
				initialSortBy={[
					{
						desc: true,
						id: "Date prise de contact",
					},
				]}
				isLocked={isLocked}
				writePermission={permissions.write}
				isEditable
				onEditClick={(original) => {
					setCurrentSupport(original)
					if (original.virtualized) {
						setIsVirtual(true)
					}
					setIsOpenModalSupport(true)
				}}
				isDeletable={permissions.write && permissions.canDeleteDatas} //* permissions.write > canDeleteDatas
				onDeleteClick={(original) => {
					if (original.virtualized) {
						setOpenModalVirtualizedDestruction(original)
						return
					}

					dispatch(
						deletePointWithUpdateEvent({
							point: original,
							event: {
								...selectedEvent,
								updated_at: Moment().format(),
							},
						}),
					)
				}}
				actionsButtons={
					<>
						<Filter {...filterConfig} size="big" />
						{permissions.write && (
							<>
								<Button
									className={isMobile ? "w-full" : ""}
									variant="bordered"
									color="primary"
									onClick={() => {
										setIsOpenModalListImport(true)
									}}
									disabled={isLocked}
								>
									Importer une liste
								</Button>
								<Button
									className={isMobile ? "w-full" : ""}
									color="primary"
									onClick={() => setIsOpenModalSupport(true)}
									disabled={isLocked}
								>
									Ajouter un accompagnement
								</Button>
							</>
						)}
					</>
				}
				RenderSubRow={({ row }) => (
					<TableSuiviContactSubRow
						suivis={row.original.geojson.properties.suivi}
					/>
				)}
				convertToCsv={convertToCsv}
			/>
			<ModalListSuiviImport
				isOpen={isOpenModalListImport}
				onClose={() => setIsOpenModalListImport(!isOpenModalListImport)}
			/>
			<Dialog
				open={openModalVirtualizedDestruction !== undefined}
				onClose={() => setOpenModalVirtualizedDestruction(undefined)}
			>
				<DialogTitle>
					L'accompagnement que vous souhaitez supprimer est présent car vous
					avez choisi d&apos;importer le formulaire{" "}
					{
						jsonSchemas[
							openModalVirtualizedDestruction?.geojson?.properties
								?.relatedObject?.categoryId
						]?.title
					}
				</DialogTitle>
				<DialogContent>
					Vous pouvez choisir de supprimer l&apos;import du formulaire{" "}
					{
						jsonSchemas[
							openModalVirtualizedDestruction?.geojson?.properties
								?.relatedObject?.categoryId
						]?.title
					}{" "}
					cela retirera tout les accompagnements associés à ce formulaire qui
					n&apos;ont aucun suivi.
				</DialogContent>
				<DialogActions>
					<PrimaryOutlinedButton
						onClick={() => {
							dispatch(
								updatePoint({
									...points[facilityId],
									geojson: {
										properties: {
											...points[facilityId].geojson.properties,
											suiviJsonSchemaActivated: points[
												facilityId
											].geojson.properties?.suiviJsonSchemaActivated.filter(
												(id) =>
													id !==
													openModalVirtualizedDestruction?.geojson?.properties
														?.relatedObject?.categoryId,
											),
										},
									},
								}),
							)

							setOpenModalVirtualizedDestruction(undefined)
						}}
						color="primary"
					>
						Supprimer l&apos;import de{" "}
						{
							jsonSchemas[
								openModalVirtualizedDestruction?.geojson?.properties
									?.relatedObject?.categoryId
							]?.title
						}
					</PrimaryOutlinedButton>
					<PrimaryButton
						onClick={() => {
							setOpenModalVirtualizedDestruction(undefined)
						}}
						color="primary"
					>
						Annuler
					</PrimaryButton>
				</DialogActions>
			</Dialog>
			<ModalSupport
				isOpen={isOpenModalSupport}
				onClose={() => {
					setIsOpenModalSupport(false)
					setCurrentSupport(undefined)
					setIsVirtual(false)
				}}
				currentSupport={currentSupport}
				isVirtual={isVirtual}
			/>
		</>
	)
}

export default TableSupport
