import { Text } from "@react-pdf/renderer"
import React from "react"

const PTitle = ({
	fontSize = 18,
	color = "black",
	marginBottom = 0,
	children,
	style = {},
}) => {
	return (
		<Text
			style={{
				fontSize: fontSize,
				color: color,
				marginBottom,
				textAlign: "justify",
				fontFamily: "Roboto Mono",
				...style,
			}}
		>
			{children}
		</Text>
	)
}

export default PTitle
