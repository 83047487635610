import React from "react"
import { useContext } from "react"
import { ParentSubmitContext } from "./ParentSubmitContext"

const ParentSubmitButtonWrapper = ({
	children,
}: {
	children: ({ onClick }) => void
}) => {
	const context = useContext(ParentSubmitContext)
	const onClick = () => {
		return context.setShouldSubmit(true)
	}

	return <>{children({ onClick })}</>
}

export default ParentSubmitButtonWrapper
