import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Checkbox } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import useMapState from "@/hooks/useMapState"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import {} from "@/redux-toolkit/daybook/reducer"
import { JSON_SCHEMAS, MULTI_POLYGONS } from "@/redux-toolkit/time/constants"
import Debug from "debug"
import React from "react"
import { useDispatch } from "react-redux"
import { useLocalStorage } from "react-use"
import styled from "styled-components"
import NestedJsonSchemaDaybookCard from "./NestedJsonSchemaDaybookCard"
// app:javascript:utils:panels:panelCarto:NestedListChildren.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:NestedListChildren")
debug.log = console.log.bind(console)
const StyledList = styled(List)<{ $color: string }>`
    padding: 0px !important;
    border-left: 2px solid ${({ $color }) => $color} !important;
    margin-left: 2em !important;
`
const StyledListItemButton = styled(ListItemButton)<{ $color: string }>`
    padding-left: 2rem !important;
    margin-left: -1.5rem !important;
    display: flex;
`

const StyledListItemText = styled(ListItemText)`
    margin-left: 0rem !important;
`
const LengthIndicator = styled.div<{ $color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 5px;
    min-width: 20px;
    font-size: var(--font-size-small);
    color: white;
    font-weight: 500;
    border-radius: 10px;
    margin: 0 2px;
    background-color: ${({ $color }) => ($color ? $color : "#757575")};
`
const NestedListChildrenDaybook = ({
	subCategory,
	color = "neutral",
	noListIcon = false,
}) => {
	debug("NestedListChildren", subCategory)
	const {
		coucheJsonSchemas,
		coucheMultiPolygons,
		removeCoucheJsonSchemas,
		addCoucheJsonSchemas,
		addCoucheMultiPolygons,
		removeCoucheMultiPolygons,
	} = useMapState()
	const [open, setOpen] = useLocalStorage(
		"NestedListChildrenDaybook." + subCategory.title,
		false,
	)
	const multiPolygons = useMultiPolygons()
	const dispatch = useDispatch()
	const handleClick = () => {
		setOpen(!open)
	}
	const checked = subCategory.items.every((item) => {
		switch (item.type) {
			case JSON_SCHEMAS:
				return Boolean(coucheJsonSchemas?.[item.id])
			case MULTI_POLYGONS:
				return Boolean(coucheMultiPolygons?.[item.id])
			default:
				return false
		}
	})

	const onCheckboxClick = (e) => {
		e.stopPropagation()
		subCategory.items.forEach((item) => {
			switch (item.type) {
				case JSON_SCHEMAS:
					if (checked) {
						dispatch(removeCoucheJsonSchemas(item.id))
					} else {
						dispatch(addCoucheJsonSchemas({ id: item.id }))
					}
					break
				case MULTI_POLYGONS:
					if (checked) {
						dispatch(removeCoucheMultiPolygons(item.id))
					} else {
						dispatch(
							addCoucheMultiPolygons({
								id: item.id,
								priority: multiPolygons[item.id]?.custom_props?.priority,
							}),
						)
					}

					break
				default:
					break
			}
		})
	}

	return (
		<>
			<StyledList $color={color}>
				<StyledListItemButton onClick={handleClick} $color={color}>
					<Checkbox onClick={onCheckboxClick} checked={checked} />
					<StyledListItemText primary={subCategory.title} />
					<LengthIndicator $color={color}>
						{subCategory?.items?.length ?? 0}
					</LengthIndicator>
					{open ? <ExpandLess /> : <ExpandMore />}
				</StyledListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					{subCategory.items.map((itemJsonSchema) => (
						<NestedJsonSchemaDaybookCard
							key={itemJsonSchema.id}
							itemJsonSchema={itemJsonSchema}
							color={color}
							noListIcon={noListIcon}
						/>
					))}
				</Collapse>
			</StyledList>
		</>
	)
}

export default NestedListChildrenDaybook
