import { Chip } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { StyledChip } from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"

const OpaChip = styled(Chip)`
    opacity: 0.7;
`

const FChipSelect = ({ name, options, renderOption = undefined }) => {
	const { control } = useFormContext()
	const {
		field: { onChange, value },
	} = useController({
		name,
		control,
	})

	return (
		<Flex gap="1rem" spaceBetween fullWidth>
			<div>{name}</div>
			<Flex gap="0.5rem" $wrap fullWidth>
				{options.map((option: any) => {
					if (value === option) {
						return (
							<StyledChip
								$colorProperty="var(--primary500)"
								variant="outlined"
								key={option}
								label={renderOption?.(option) ?? option}
							/>
						)
					}
					return (
						<OpaChip
							onClick={() => {
								onChange(option)
							}}
							key={option}
							label={renderOption?.(option) ?? option}
						/>
					)
				})}
			</Flex>
		</Flex>
	)
}

export default FChipSelect
