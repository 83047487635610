export const JSON_SCHEMA = "JSON_SCHEMA"

export enum JsonSchemaPropertiesType {
	TEXT = "text",
	TEXTAREA = "textarea",
	RADIO = "radio",
	SELECT = "select",
	CREATABLE_SELECT = "creatable-select",
	PHONE = "phone",
	DATE = "date",
	TIME = "time",
	CHECKBOX = "checkbox",
	TAGS = "tags",
	GEOLOC = "geoloc",
	STOCKPLACE = "stockplace",
	RESPONSABLES = "responsables",
	NUMBER = "number",
	SELECTION_WITH_IMAGE = "selectionWithImage",
}

export interface IJsonSchemaProperty {
	type: JsonSchemaPropertiesType
	label: string
	isColumn: boolean
	isColumnSectorOnly?: boolean
	isRequired: boolean
	isPcs: boolean
	isIdentifiant: boolean
	isReadOnly?: boolean
	isPublic: boolean
	name: string
	items?: string[]
	itemsImage?: {
		label: string
		imgId: string
	}[]
	options?: {
		isMulti?: boolean
	}
	size?: number
	tags?: "stocks" | "vehicules"
	defaultValue?: string | boolean | number
	validations?: {
		name: string
		label: string
		test: (value: string) => boolean
	}[]
}

export interface ISortBy {
	property: string
	desc: boolean
}

interface IJsonSchema {
	id: string
	title: string
	pcsTitle?: string
	alias?: string[]
	title_interco?: string
	template: {
		properties: IJsonSchemaProperty[]
	}
	main_category?: string
	sub_category?: string
	is_standard?: boolean
	is_telealert?: boolean
	write?: boolean
	hide?: boolean
	sort_by: ISortBy[]
	imgId: string
}

export default IJsonSchema
