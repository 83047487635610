import { Document } from "@react-pdf/renderer"
import React from "react"
import ConfidentialityRgpd from "./pages/ConfidentialityRgpd"
import DynamicDataPage from "./pages/DynamicDataPage"
import FirstPage from "./pages/FirstPage"
import StaticDataPage from "./pages/StaticDataPage"

export const GENERIC_HEIGHT = 50
export const GENERIC_BLUE = "#0070c0"
export const GENERIC_MARGIN = 5
const RgpdPDF = ({ commune, jsonSchemas, responsableRgpd }) => (
	<Document>
		<ConfidentialityRgpd />
		<FirstPage commune={commune} responsableRgpd={responsableRgpd} />
		<StaticDataPage commune={commune} responsableRgpd={responsableRgpd} />
		<DynamicDataPage
			commune={commune}
			jsonSchemas={jsonSchemas}
			responsableRgpd={responsableRgpd}
		/>
	</Document>
)

export default RgpdPDF
