import React from "react"
import getPolygonStyle from "./getPolygonStyle"

const hexMap = "0123456789abcdef".split("")

const hexOpacity = (opacity) => {
	const base = Math.trunc(opacity * 255)
	const h1 = Math.trunc(base / 16)
	const h0 = Math.round(base - 16 * h1)
	return hexMap[h1] + hexMap[h0]
}

const MultiPolygonColorSquare = ({
	multiPolygon,
	marginRight,
	size = "medium",
}: {
	multiPolygon: any
	marginRight?: string
	size?: "small" | "medium" | "large"
}) => {
	const customStyle = getPolygonStyle(multiPolygon)
	const customSizes = {
		large: { width: "400px", height: "250px" },
		medium: { width: "50px", height: "30px" },
		small: { width: "30px", height: "18px" },
	}
	return (
		<div
			style={{
				width: customSizes[size].width,
				height: customSizes[size].height,
				display: "inline-table",
				marginRight: marginRight || "0px",
				...(customStyle.stroke
					? {
							border: `${customStyle.weight}px solid ${
								customStyle.color + hexOpacity(customStyle.opacity)
							}`,
					  }
					: {}),
				...(customStyle.fill
					? {
							backgroundColor:
								customStyle.fillColor + hexOpacity(customStyle.fillOpacity),
					  }
					: {}),
				...(customStyle.stroke && customStyle.color === "#ffffff"
					? {
							boxShadow: "0 0 4px #ddd",
					  }
					: {}),
			}}
		/>
	)
}

export default MultiPolygonColorSquare
