import { Text } from "@react-pdf/renderer"
import React from "react"

const PH2 = ({ children, marginBottom = "14px", ...props }) => {
	return (
		<Text
			style={{
				...{ fontSize: 20, color: "#2e74b5", marginBottom },
				...props.style,
			}}
		>
			{children}
		</Text>
	)
}

export default PH2
