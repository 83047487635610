import { createAsyncThunk } from "@reduxjs/toolkit"
import Axios from "axios"
import ErrorService from "@/services/ErrorService"
import { setTags } from "./reducer"
export const fetchTags = async (dispatch: any, _state: any) => {
	try {
		const response = await Axios.get("/api/tag")
		dispatch(setTags(response.data))
	} catch (error) {
		ErrorService.error({
			component: "commone:resources:fetchTags",
			message: "Erreur lors de la récupération des tags",
			dispatch,
		})
	}
}
