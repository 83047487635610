import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useOrga from "@/hooks/useOrga"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import ActionCellules from "@/pages/customdata/fiches/subAction/ActionCellules"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import {
	setCurrentIncident,
	setCurrentIntervention,
} from "@/redux-toolkit/edit/reducer"
import { INTERVENTION } from "@/redux-toolkit/userSettings/constants"
import Button from "@/styles/atoms/Button"
import TypeResponsables from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import { PrimaryTextButton } from "@/utils/components/style/button"
import { StyledChip } from "@/utils/components/style/chip"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import {
	width50,
	width80,
	widthCustom,
} from "@/utils/components/tables/widthProps"
import ReponsesTooltip from "@/utils/components/tooltip/ReponsesTooltip"
import FCellules from "@/utils/form/FCellules"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import CsvService from "@/utils/services/CsvService"
import HumanService from "@/utils/services/HumanService"
import { sortByDate } from "@/utils/sort"
import PrintIcon from "@mui/icons-material/Print"
import { Chip, IconButton, Tooltip } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import Debug from "debug"
import _ from "lodash"
import { default as Moment, default as moment } from "moment"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { convert } from "./TableIncident"
import Filter from "./filter/Filter"
import useFilterForm, { NO_FILTER } from "./filter/useFilterForm"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { Kbd } from "@nextui-org/react"
import { isMobile } from "react-device-detect"

// app:javascript:components:maincourante:following:TableIncident.tsx
const debug = Debug(
	"app:javascript:components:maincourante:following:TableIncident",
)
debug.log = console.log.bind(console)

export const renderInterventionStatus = (
	value: "A faire" | "En cours" | "Terminé",
	small = false,
) => {
	if (value === "En cours") {
		return (
			<StyledChip
				variant="outlined"
				label="En cours"
				$colorProperty="var(--orange500)"
				size={small ? "small" : "medium"}
			/>
		)
	}
	if (value === "A faire") {
		return (
			<StyledChip
				variant="outlined"
				label="A faire"
				$colorProperty="var(--primary500)"
				size={small ? "small" : "medium"}
			/>
		)
	}
	return (
		<StyledChip
			variant="outlined"
			label="Terminé"
			$colorProperty="var(--green500)"
			size={small ? "small" : "medium"}
		/>
	)
}

const filterOptions = [
	{
		name: "Cellules",
		component: FCellules,
		default: [],
	},
	{
		name: "Statut",
		items: ["A faire", "En cours", "Terminé"],
		default: NO_FILTER
	},
]

const TableIntervention = ({
	interventions = undefined,
	tableProps = {},
	additionalColumns = [],
}) => {
	const history = useHistory()
	const points = usePoints()
	const cellules = useOrga()
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const currentIntervention = useSelector(
		(state: any) => state.edit.currentIntervention,
	)

	const { values, ...filterConfig } = useFilterForm({
		options: filterOptions,
	})

	const permissions = useAccessRights(EnumToolPath.INTERVENTION)
	const isLocked = selectedEvent?.locked

	const concernedPoints = interventions
		? interventions
		: Object.values(points)
			.filter((point) => point.event_id === selectedEvent?.id)
			.filter((point) => point.jsonschema_id === INTERVENTION)
	const tmpFilteredPoints = concernedPoints
		.filter((point) => {
			const statut = values["Statut"]
			if (statut === NO_FILTER) return true

			return point.geojson.properties.Statut === statut
		})
		.filter((point) => {
			const cellules = values["Cellules"]
			if (cellules.length === 0) return true
			return (point.geojson.properties?.Cellules ?? []).some((cellule) =>
				cellules.includes(cellule),
			)
		})

	const filteredPoints = sortByDate(
		tmpFilteredPoints,
		"geojson.properties.Date de début",
	)
	const convertToCsv = useMemo(() => {
		return (selected: string[]) => {
			let csvData = []
			concernedPoints
				.filter((intervention) =>
					selected !== null ? selected.includes(intervention["id"]) : true,
				)
				.forEach((intervention) => {
					const csvRéponses = []
					intervention.geojson.properties?.Réponses?.forEach((reponse) => {
						csvRéponses.push([
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							"",
							`=""${convert(reponse.objet)}""`,
							`=""${moment(reponse.date).format("DD/MM/YYYY HH:mm")}""`,
						])
					})
					csvData = [
						...csvData,
						[
							`=""${intervention.geojson.properties?.Objet ?? ""}""`,
							`=""${intervention.geojson.properties?.Statut ?? ""}""`,
							`=""${points[intervention.geojson.properties?.["En lien avec"]]
								?.geojson?.properties?.Objet ?? ""
							}""`,
							CsvService.formatDate(
								intervention.geojson.properties?.["Date de début"],
							),
							CsvService.formatDate(
								intervention.geojson.properties?.["Date de fin"],
							),
							`=""${convert(intervention.geojson.properties?.Détails) ?? ""}""`,
							`=""${(intervention.geojson.properties?.Cellules ?? [])
								.map((cellId) => {
									return cellules?.[cellId]?.idName ?? ""
								})
								.join(" / ")}""`,
							`=""${(intervention.geojson.properties?.Membres ?? [])
								.map((member) => HumanService.displayFullName(points[member]))
								.join(" / ")}""`,
							`=""${intervention.geojson.properties?.Adresse?.Adresse ?? ""}""`,
							`=""${intervention.geojson.properties?.Adresse?.coo?.lat ?? ""
							}""`,
							`=""${intervention.geojson.properties?.Adresse?.coo?.lng ?? ""
							}""`,
						],
						...csvRéponses,
					]
				})
			return {
				data: csvData,
				headers: [
					"Objet",
					"Statut",
					"Événement lié",
					"Date de début",
					"Date de fin",
					"Détails",
					"Cellules",
					"Membres",
					"Adresse",
					"Latitude",
					"Longitude",
					"Points de situation",
					"Date de point de situation",
				],
				filename: selectedEvent?.name + "_interventions.csv",
			}
		}
	}, [concernedPoints, points])
	const columns = useMemo(
		() =>
			[
				{
					header: "Date",
					accessorKey: "geojson.properties.Date de début",
					cell: ({ row }) => {
						const value = row.original.geojson.properties
						return <>{Moment(value["Date de début"]).format("DD/MM HH:mm")}</>
					},
					...widthCustom(90),
				},
				{
					header: "Statut",
					accessorKey: "geojson.properties.Statut",
					enableSorting: false,
					cell: ({ getValue }) => {
						const value = getValue() as "En cours" | "Terminé" | "A faire"
						return renderInterventionStatus(value)
					},
					...widthCustom(90),
				},
				{
					header: "Intervention",
					enableSorting: false,
					accessorKey: "geojson.properties.Objet",
				},
				{
					header: "Points de situation",
					accessorKey: "geojson.properties.Réponses",
					cell: ({ getValue, row }) => {
						const value = (getValue() ?? []) as any
						if (_.isEmpty(value)) {
							return null
						}

						const noOfReponses = value?.filter(
							(reponse) =>
								reponse.objet !== "" || reponse.date !== "Invalid date",
						)?.length

						return (
							<Flex fullWidth justifyCenter>
								{noOfReponses > 0 && (
									<ReponsesTooltip data={row.original.geojson?.properties}>
										<Chip label={String(noOfReponses)} />
									</ReponsesTooltip>
								)}
							</Flex>
						)
					},
					enableSorting: false,
					...width80,
				},
				{
					header: "Cellules et Membres",
					accessorKey: "geojson.properties",
					cell: ({ getValue }) => {
						const value = getValue()
						return (
							<Flex directionColumn alignItemsStart gap="0.5rem">
								<ActionCellules
									withCrisisOrga
									celluleIds={value["Cellules"]}
									customWrapper={(props) => (
										<Flex directionColumn alignItemsStart {...props} />
									)}
								/>
								<TypeResponsables
									isSmall
									value={value["Membres"]}
									editable={false}
									align="left"
									directionColumn
								/>
							</Flex>
						)
					},
				},
				{
					header: "Évènement lié",
					accessorKey: "geojson.properties.En lien avec",
					cell: ({ getValue }) => {
						const value = getValue() as string
						const incident = points[value]
						if (!incident) {
							return null
						}
						return (
							<PrimaryTextButton
								onClick={() => {
									dispatch(setCurrentIncident(points[value]))
								}}
							>
								<b>{points[value].geojson.properties.Objet}</b>
							</PrimaryTextButton>
						)
					},
				},
				{
					header: "",
					accessorKey: "printRow",
					enableSorting: false,
					cell: ({ row }) => (
						<Tooltip title="Imprimer">
							<IconButton
								onClick={() =>
									history.push({
										pathname: "/maincourante/donnees/impression",
										state: { actions: [row.original] },
									})
								}
							>
								<PrintIcon />
							</IconButton>
						</Tooltip>
					),
					...width50,
				},
				...additionalColumns,
			] as ColumnDef<any>[],
		[additionalColumns],
	)

	return (
		<>
			<SuperReactTable
				onEditClick={(incident) => dispatch(setCurrentIntervention(incident))}
				convertToCsv={convertToCsv}
				additionalLineNumber={1}
				additionalMultiOptions={[
					{
						key: "printSelected",
						label: "Imprimer la sélection",
						icon: <PrintIcon />,
						onClick: (rows) => {
							const actionsKeys = Object.keys(rows)
							const actionsToPrint = concernedPoints.filter((point) =>
								actionsKeys.includes(point.id),
							)
							history.push({
								pathname: "/maincourante/donnees/impression",
								state: { actions: actionsToPrint },
							})
						},
					},
				]}
				actionsButtons={
					<>
						<Filter {...filterConfig} />
						{permissions.write && (
							<LightTooltip
								placement="top"
								title={
									<>
										Raccourci : <Kbd>Ctrl + Espace</Kbd>
									</>
								}
							>
								<Button
									className={isMobile ? "w-full" : ""}
									color="primary"
									onClick={() => dispatch(setCurrentIntervention({}))}
									isDisabled={isLocked}
								>
									Ajouter une intervention
								</Button>
							</LightTooltip>
						)}
					</>
				}
				onDeleteClick={(intervention) => {
					dispatch(
						deletePointWithUpdateEvent({
							point: intervention,
							event: {
								...selectedEvent,
								history: [
									...selectedEvent?.history,
									{
										type: INTERVENTION,
										title: "Suppression d'Intervention",
										date: Moment().format(),
										noAction: true,
									},
								],
							},
						}),
					)
				}}
				writePermission={permissions.write}
				isEditable
				isDeletable={permissions.canDeleteDatas}
				isLocked={isLocked}
				data={filteredPoints}
				columns={columns}
				{...tableProps}
			/>
		</>
	)
}

export default TableIntervention
