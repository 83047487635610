import React from "react"
import HumanService from "@/utils/services/HumanService"
import styled, { css } from "styled-components"
import IPoint from "@/utils/types/IPoint"

import { Flex } from "@/utils/components/style/flex"
import HumanTooltip from "@/styles/atoms/Tooltip/specialized/HumanTooltip"
import { SChip } from "./HumanChip.styled"

const StyledStrong = styled.strong<{ $size: "small" | "medium" }>`
    font-weight: 500;
    font-size: 0.9rem;
    ${({ $size }) =>
			$size === "small" &&
			css`
            font-size: var(--font-size-small) !important;
        `}
`
function HumanChip({
	human,
	writePermission = false,
	added = false,
	deleted = false,
	size = "medium",
	...props
}: {
	human: IPoint
	writePermission?: boolean
	added?: boolean
	size?: "small" | "medium"
	deleted?: boolean
} & Omit<any, "human" | "writePermission" | "deleted" | "added">) {
	if (!human) {
		return null
	}
	return (
		<HumanTooltip human={human} writePermission={writePermission}>
			<SChip
				isDisabled={deleted}
				color={added ? "success" : deleted ? "danger" : undefined}
				variant="bordered"
				avatar={HumanService.displayInitialsAsAvatar(human)}
				size={size === "small" ? "sm" : "md"}
				{...props}
			>
				<Flex directionColumn>
					<StyledStrong $size={size}>
						{HumanService.displayFullName(human)}
					</StyledStrong>
				</Flex>
			</SChip>
		</HumanTooltip>
	)
}

export default HumanChip
