import styled from "styled-components"
import { CardMedia } from "@mui/material"
import { Card as MuiCard } from "@mui/material"
export const Card = styled(MuiCard)`
    box-shadow: var(--cool-shadow) !important;
`
export const CardOverlayBlack = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.2) 35%,
        rgba(0, 0, 0, 0) 100%
    );
    pointer-events: none;
    z-index: 10;
`

export const StyledCardMedia = styled(CardMedia)`
    &:hover {
        transform: scale(1.1);
        transition: 0.4s;
        opacity: 0.75;
    }
`

export const RedCard = styled.div`
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: var(--red50);
    border-radius: 4px;
`
export const GreenCard = styled.div`
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: var(--green50);
    border-radius: 4px;
`
export const OrangeCard = styled.div`
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: var(--orange50);
    border-radius: 4px;
`
