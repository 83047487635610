import { POINTS } from "@/redux-toolkit/time/constants"
import Axios from "axios"
import { addPoints } from "./reducer"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import { setOffLine } from "@/redux-toolkit/common/reducer"

const getCouche = async (title, decoupage) => {
	const result = await Axios.get(
		`/askcouche/${encodeURI(title)}/${encodeURI("cdc")}`,
	)
	if (result.status !== 200) {
		throw Error(
			`failed to fetch couche with title : ${title} and decoupage : ${decoupage}`,
		)
	}
	return result.data
}

// Updated getPoints function to dispatch points page by page
const getPoints = async (dispatch, getState) => {
    let page = 1;
    let allPoints = [];

    const fetchPointsPage = async (page) => {
        const result = await Axios.get(`/api/points?lastSynchro=${getState().time[POINTS]}&page=${page}&per_page=100`);
        const fetchedPoints = result.data.points;
        const totalPages = result.data.total_pages;

        allPoints = allPoints.concat(fetchedPoints);

        dispatch(addPoints(fetchedPoints));

        if (page < totalPages) {
            await fetchPointsPage(page + 1);
        } else {
            const serverDatetime = result.data.serverDatetime;
            dispatch(setServerDatetime({ datetime: serverDatetime, entity: POINTS }));
        }
    };

    try {
        await fetchPointsPage(page);
    } catch (error) {
        console.error(error);
        if (error.message.includes("Network Error")) {
            dispatch(setOffLine());
            return;
        }
        throw new Error(`Erreur lors de la récupération des points, message d'erreur : ${error.message}`);
    }
}

const getCdcSharedPoints = async (sharedJsonSchemasIds) => {
	const result = await Axios.get(`/api/points/cdc`, { params: { sharedJsonSchemasIds } })
	return result.data
}

const getPointsWithDeleted = async (
	lastSynchro = "2000-04-12T12:47:50+02:00",
	page = 1,
	perPage = 25,
	allPoints = [],
) => {
	const result = await Axios.get(
		`/api/points?lastSynchro=${lastSynchro}&page=${page}&per_page=${perPage}&deleted=true`,
	)
	const fetchedPoints = result.data.points
	const totalPages = result.data.total_pages

	// Combine the points from the current page with those previously fetched
	const combinedPoints = allPoints.concat(fetchedPoints)

	if (page < totalPages) {
		// If there are more pages, recursively call getPoints for the next page
		return getPointsWithDeleted(
			lastSynchro,
			page + 1,
			perPage,
			combinedPoints,
		)
	} else {
		// If all pages have been fetched, return the combined list of points
		return { points: combinedPoints, serverDatetime: result.data.serverDatetime }
	}
}

const createPoint = async (point) => {
	const result = await Axios.post("/api/points", { data: point })
	return result.data
}

const createPublicPoint = async (point) => {
	const result = await Axios.post("/api/picrim/points/create", {
		data: point,
	})

	return result.data
}

const deletePoint = async (id) => {
	const result = await Axios.delete(`/api/points/${id}`)
	return result.data
}

const updatePoint = async (point) => {
	const result = await Axios.put(`/api/points/${point.id}`, {
		data: point,
	})
	return result.data
}

const getPublicPoints = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(
		`/api/public_points?lastSynchro=${lastSynchro}`,
	)
	return result.data
}

const getPointsPicrim = async (communeId) => {
	const result = await Axios.get(`/api/picrim/points/communeId={${communeId}}`)
	return result.data
}

export default {
	getCouche,
	getPoints,
	getCdcSharedPoints,
	getPointsWithDeleted,
	createPoint,
	updatePoint,
	deletePoint,
	getPublicPoints,
	getPointsPicrim,
	createPublicPoint,
}
