import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { Radio } from "@mui/material"
import useJsonSchemaColumns from "@/hooks/useJsonSchemaColumns"
import usePoints from "@/hooks/usePoints"
import Debug from "debug"
import React, { useMemo } from "react"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { width50 } from "@/utils/components/tables/widthProps"
import { ModalContent } from "@nextui-org/react"
import Button from "@/styles/atoms/Button"

const debug = Debug("app:javascript:utils:formik:PCS:ModalPCSElement")

const ModalPCSElement = ({
	isOpenModal,
	onClose,
	title,
	setPointId,
	pcsCategory,
}) => {
	const points = Object.values(usePoints()).filter(
		(point: any) => point.jsonschema_id === pcsCategory?.id,
	)

	const columnsProperty = useJsonSchemaColumns(pcsCategory?.id)
	const columns = useMemo(
		() => [
			{
				header: "Choisir",
				accessorKey: "choose_id",
				cell: ({ row }) => (
					<Radio
						checked={false}
						onChange={() => {
							setPointId(row.original.id)
							onClose()
						}}
					/>
				),
				...width50,
			},
			...columnsProperty,
		],
		[columnsProperty],
	)
	return (
		<Modal isOpen={isOpenModal} onClose={onClose} size={'4xl'}>
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalBody>
					<SuperReactTable
						data={points}
						columns={columns}
						selectable={false}
						isDeletable={false}
						onRowClick={(row) => {
							setPointId(row.original.id)
							onClose()
						}}
						isEditable={false}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" variant="light" onClick={onClose}>
						Annuler
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalPCSElement
