import useMapState from "@/hooks/useMapState"
import React, { useState, useMemo, useEffect } from "react"
import SuperMap from "utils/components/map/SuperMap"
import usePoints from "@/hooks/usePoints"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import MarkerClusterGroup from "@changey/react-leaflet-markercluster"
import DatabaseService from "@/services/DatabaseService"
import { Pane, GeoJSON } from "react-leaflet"
import getPolygonStyle from "utils/components/map/polygon/getPolygonStyle"
import { useSelector, useDispatch } from "react-redux"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_COUCHE, DAYBOOK_POINT } from "utils/panels/PanelCarto"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook"
import CartographyMultiPolygon from "./subComponents/CartographyMultiPolygon"
import Defer from "utils/Defer"

const CartographyMap = () => {
	const position = [45.8870177, -1.1964972] as any

	const [multiPolygonsToDisplay, setMultiPolygonsToDisplay] = useState([])
	const points = usePoints()
	const multiPolygons = useMultiPolygons()
	const panel = useSelector((state: any) => state.common.panel)
	const { coucheJsonSchemas, coucheMultiPolygons, surfaceOrder } = useMapState()
	const dispatch = useDispatch()

	const { clusterPoints, noClusterPoints } = useMemo(() => {
		const visibleCouches = Object.values(coucheJsonSchemas ?? {})
			.filter((couche: any) => couche.visibility)
			.map((couche: any) => couche.id)

		const filteredPoints = Object.values(points).filter((point) =>
			visibleCouches.includes(point.jsonschema_id),
		)

		const clusterPoints = []

		const noClusterPoints = []

		filteredPoints.filter((point) => {
			const coucheJsonSchema = Object.values(coucheJsonSchemas ?? {}).find(
				(couche: any) => couche.id === point.jsonschema_id,
			) as any
			if (coucheJsonSchema.cluster) {
				clusterPoints.push(point)
			} else {
				noClusterPoints.push(point)
			}
		})

		return {
			clusterPoints,
			noClusterPoints,
		}
	}, [points, coucheJsonSchemas])

	useEffect(() => {
		const processMultiPolygon = async () => {
			const visibleCouches = Object.values(coucheMultiPolygons ?? {})
				.filter((couche: any) => couche.visibility)
				.map((couche: any) => couche.id)
			const multiPolygonToRender = Object.values(multiPolygons).filter(
				(polygon: any) => visibleCouches.includes(polygon.id),
			)
			const geo = []
			await Promise.all(
				multiPolygonToRender.map(async (vcouche: any) => {
					const data = await DatabaseService.get(vcouche.id)
					if (data) {
						geo.push({
							...vcouche,
							geojson: data,
						})
					}
					return
				}),
			)
			setMultiPolygonsToDisplay(geo)
		}
		processMultiPolygon()
	}, [multiPolygons, coucheMultiPolygons])

	return (
		<>
			<SuperMap
				center={position}
				zoom={10}
				displayOptions={{
					displayDraw: false,
					displaySignal: false,
					displayIncidentControl: false,
				}}
			>
				<>
					<MarkerClusterGroup key={Date.now()} maxClusterRadius={40}>
						{clusterPoints.map((point) => {
							if (point.id === panel?.id) {
								return null
							}
							return (
								<MarkerDaybook
									key={point.id}
									point={point}
									handleClick={() => {
										dispatch(
											setPanel({
												type: DAYBOOK_POINT,
												id: point.id,
											}),
										)
									}}
								/>
							)
						})}
					</MarkerClusterGroup>
					{noClusterPoints.map((point) => {
						if (point.id === panel?.id) {
							return null
						}
						return (
							<MarkerDaybook
								key={point.id}
								point={point}
								handleClick={() => {
									dispatch(
										setPanel({
											type: DAYBOOK_POINT,
											id: point.id,
										}),
									)
								}}
							/>
						)
					})}

					{panel?.id && points[panel?.id] && (
						<MarkerDaybook
							point={points[panel?.id]}
							handleClick={() => {
								dispatch(
									setPanel({
										type: DAYBOOK_POINT,
										id: points[panel?.id],
									}),
								)
							}}
							isSelected
						/>
					)}
					<Defer>
						{multiPolygonsToDisplay.map((elem) => {
							const zIndex = 500 - surfaceOrder.indexOf(elem.id)
							return (
								<CartographyMultiPolygon
									elem={elem}
									zIndex={zIndex}
									key={`${elem.id}:${zIndex.toString()}`}
								/>
							)
						})}
					</Defer>
				</>
			</SuperMap>
		</>
	)
}

export default CartographyMap
