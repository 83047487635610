import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"
import syncDataAdapter from "../syncDataAdapter"

const initialState = {}

const ficheSlice = createSlice({
	name: "data/fiche",
	initialState,
	reducers: {
		addFiche: syncDataAdapter.add,
		removeFiche: syncDataAdapter.remove,
		createLocalFiche: syncDataAdapter.createLocal,
		updateLocalFiche: syncDataAdapter.updateLocal,
		deleteLocalFiche: syncDataAdapter.deleteLocal,
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default ficheSlice.reducer
export const {
	addFiche,
	removeFiche,
	createLocalFiche,
	updateLocalFiche,
	deleteLocalFiche,
} = ficheSlice.actions
