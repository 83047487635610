import {
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Tooltip,
} from "@mui/material"
import React from "react"
import { isMobile } from "react-device-detect"
import { useController, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import HelpIcon from "@mui/icons-material/Help"
import { Flex } from "utils/components/style/flex"
import { SFormControlLabel } from "./FCheckboxes"
import NewStyledInputLayout from "./formUtils/NewStyledInputLayout"
const StyledHelpIcon = styled(HelpIcon)`
    margin-right: 1rem;
    color: #767676;
`
const SFlex = styled(Flex)`
    margin-left: 1rem;
    margin-top: 0.75rem;
    ${
			isMobile &&
			css`
        flex-direction: column;
        align-items: flex-start;
    `
		}
`

const FRadios = ({
	name,
	label = undefined,
	options,
	directionColumn = false,
	disabled = false,
	readOnly = false,
	helpText = null,
	noBorder = false,
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<NewStyledInputLayout
			error={Boolean(error)}
			helperText={error?.message}
			label={label}
			empty={false}
			disabled={disabled}
			noBorder={noBorder}
		>
			<RadioGroup
				ref={ref}
				value={value}
				onChange={(e) => {
					onChange(e.target.value)
				}}
				tabIndex={0}
			>
				<Flex fullWidth spaceBetween>
					<SFlex directionColumn={directionColumn}>
						{options.map((option) => (
							<SFormControlLabel
								$readOnly={readOnly}
								key={option}
								value={option}
								control={<Radio />}
								label={option}
							/>
						))}
					</SFlex>
					{helpText && (
						<Tooltip title={helpText}>
							<StyledHelpIcon />
						</Tooltip>
					)}
				</Flex>
			</RadioGroup>
		</NewStyledInputLayout>
	)
}

export default FRadios
