import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import ICommune from "utils/types/ICommune"

const selectCommune = createDeepEqualSelector(
	(state: any) => state.commune,
	(commune: any) => commune,
)

const useCommune: () => ICommune = () => {
	return useSelector(selectCommune)
}

export default useCommune
