import MapService from "@/pages/carto2/cartographie/service/MapService"
import React from "react"
import styled, { css } from "styled-components"

const SuperControl = styled((props) => (
	<div
		onMouseEnter={() => {
			if (props.noDisable) {
				return
			}
			return MapService.disableScrollAndDrag()
		}}
		onMouseLeave={() => {
			if (props.noDisable) {
				return
			}
			MapService.enableScrollAndDrag()
		}}
		{...props}
	/>
))`
    z-index: 1000;
    position: absolute;
    ${({ horizontal = "right" }: any) => {
			if (horizontal === "right") {
				return css`
                right: 0;
            `
			}
			if (horizontal === "left") {
				return css`
                left: 0;
            `
			}
		}}
    ${({ vertical = "top" }: any) => {
			if (vertical === "top") {
				return css`
                top: 0;
            `
			}
			if (vertical === "bottom") {
				return css`
                bottom: 0;
            `
			}
		}}
`
export default SuperControl
