import { useSelector } from "react-redux"
import SynchroService from "@/services/SynchroService"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { VEHICULES } from "utils/services/StaticFormsService"
import IPoint from "utils/types/IPoint"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(points) => SynchroService.notDeleted(points),
)

const useAllVehicules: () => { [key: string]: IPoint } = () => {
	const points = useSelector(selectPoints) as IPoint[]
	const vehicules = {}
	Object.values(points)
		.filter((point) => point["jsonschema_id"] === VEHICULES.id)
		.forEach((point) => {
			vehicules[point.id] = point
		})
	return vehicules
}

export default useAllVehicules
