import React from "react"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import TypePhone from "@/utils/components/jsonSchema/properties/propertiesType/TypePhone"
import PointPropertyValue from "@/utils/components/jsonSchema/properties/PointPropertyValue"
import { Flex } from "@/utils/components/style/flex"
import getImage from "@/utils/getImage"
import _ from "lodash"
import TypeGeoloc from "@/utils/components/jsonSchema/properties/propertiesType/TypeGeoloc"

const DisplayObject = (props) => {
	const { objectId, categoryId, object } = props
	const jsonSchemas = useJsonSchemasWithStatics()
	const point = object
	const category = Object.values(jsonSchemas).filter(
		(jsonSchema: any) => jsonSchema.id === categoryId,
	)?.[0]

	if (category === undefined || _.isEmpty(point)) {
		return null
	}

	const identifiantsProperty =
		category?.template?.properties.filter(
			(property) => property.isIdentifiant,
		) ?? []

	const phoneProperty =
		category?.template?.properties.filter(
			(property) => property.type === JsonSchemaPropertiesType.PHONE,
		) ?? []

	const geolocProperty =
		category?.template?.properties.filter(
			(property) => property.type === JsonSchemaPropertiesType.GEOLOC,
		) ?? []

	return (
		<Flex gap="0.5rem">
			<img
				src={getImage(jsonSchemas[category.id]?.imgId)}
				alt={category.title}
				width={40}
			/>
			<Flex gap="1rem">
				{identifiantsProperty.map((property) => {
					return (
						<PointPropertyValue
							key={property.name}
							property={property}
							value={point["geojson"]["properties"][property.name]}
						/>
					)
				})}

				{phoneProperty.map((property) => (
					<TypePhone
						editable={false}
						value={point["geojson"]["properties"]?.[property.name]}
						//diffWith={undefined}
						align={"left"}
					/>
				))}

				{geolocProperty.map((property) => (
					<TypeGeoloc
						value={point["geojson"]["properties"]?.[property.name]}
						editable={false}
						align={"left"}
					/>
				))}
			</Flex>
		</Flex>
	)
}

export default DisplayObject
