import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "../rootActions"

const MAX_ADDRESSES = 3

const initialState = {
	addresses: [],
}

const searchHistorySlice = createSlice({
	name: "searchHistory",
	initialState,
	reducers: {
		searchHistoryPushAddress(state, { payload: geoloc }) {
			state.addresses.unshift(geoloc)
			if (state.addresses.length > MAX_ADDRESSES) state.addresses.pop()
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default searchHistorySlice.reducer
export const { searchHistoryPushAddress } = searchHistorySlice.actions
