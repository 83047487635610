import React, { createContext } from "react"
import { Draggable } from "react-beautiful-dnd"
import useDraggableInPortal from "./useDraggableInPortal"

export const DraggableItemContext = createContext(undefined)

interface IDraggableProps {
	id: string
	index: number
	fullHandle: boolean
}

const doMakeDraggable = (Component) => {
	return ({
		draggableProps,
		...props
	}: {
		draggableProps: IDraggableProps
		[key: string]: any
	}) => {
		const renderDraggable = useDraggableInPortal()

		return (
			<Draggable draggableId={draggableProps.id} index={draggableProps.index}>
				{renderDraggable((provided, snapshot) => (
					<DraggableItemContext.Provider value={provided.dragHandleProps}>
						<Component
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...(draggableProps.fullHandle
								? provided.dragHandleProps
								: undefined)}
							{...props}
							style={{
								...provided.draggableProps.style,
								...props.style,
								...(props.dndStyle
									? props.dndStyle(snapshot.isDragging)
									: undefined),
							}}
							$isDragging={snapshot.isDragging}
						>
							{props.children}
						</Component>
					</DraggableItemContext.Provider>
				))}
			</Draggable>
		)
	}
}

const makeDraggable = (Component, draggableProps = undefined) => {
	return draggableProps
		? (props) => doMakeDraggable(Component)({ ...props, draggableProps })
		: doMakeDraggable(Component)
}

export default makeDraggable
