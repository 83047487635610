import React from "react"
import { ModalHeader as NModalHeader } from "@nextui-org/react"
import { isMobile } from "react-device-detect"

// Update your ModalHeader component to use React.forwardRef
const ModalHeader = React.forwardRef<
    HTMLDivElement,
    React.ComponentPropsWithRef<typeof NModalHeader>
>((props, ref) => {
    // Forward the ref along with other props to NModalHeader
    return (
        <NModalHeader
            {...props}
            disableAnimation={isMobile}
            isDismissable={false}
            ref={ref}
        />
    )
})

ModalHeader.displayName = "ModalHeader" // Setting a displayName is a good practice for components using forwardRef

export default ModalHeader
