import Debug from "debug"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { useLocation } from "react-router"
import { useEffectOnce } from "react-use"
import styled, { css } from "styled-components"
import { ReactTableContainerContext } from "utils/hooks/useAutoPageSize"
import useReactTableContainer from "utils/hooks/useReactTableContainer"

const Container = styled.div<{
	$noPadding?: boolean
	$reducePadding?: boolean
	relative?: boolean
	flex: boolean
}>`
    transition: padding 0.3s;
    padding: ${({ $noPadding, $reducePadding }) =>
			$noPadding ? "0" : isMobile || $reducePadding ? "0.5rem" : "2rem"};
    max-height: calc(100vh - var(--height-top-bar));
    overflow: auto;
    max-width: var(--modal-width) !important;
    height: 100%;

    ${({ relative }) =>
			relative &&
			css`
            position: relative;
        `}
    ${({ flex }) =>
			flex &&
			css`
            display: flex;
            flex-direction: column;
        `}
`

// app:javascript:utils:components:layout:PageContainer.tsx
const debug = Debug("app:javascript:utils:components:layout:PageContainer")
debug.log = console.log.bind(console)

const PageContainer = ({
	children,
	noPadding = false,
	reducePadding = false,
	relative = false,
	flex = false,
}) => {
	const { value, callbackRef } = useReactTableContainer()
	const [isScrolledTop, setIsScrolledTop] = useState(false)
	const location = useLocation()
	useEffect(() => {
		value?.scrollTo(0, 0)
	}, [location])

	const onScroll = () => {
		if (value.scrollTop === 0) {
			document.documentElement.style.setProperty(
				"--shadow-scroll",
				"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
			)
		}
		if (value.scrollTop !== 0 && !isScrolledTop) {
			document.documentElement.style.setProperty(
				"--shadow-scroll",
				"0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
			)
		}
	}

	return (
		<Container
			ref={callbackRef}
			onScroll={onScroll}
			$reducePadding={reducePadding}
			$noPadding={noPadding}
			relative={relative}
			flex={flex}
		>
			<ReactTableContainerContext.Provider value={value}>
				{children}
			</ReactTableContainerContext.Provider>
		</Container>
	)
}

export default PageContainer
