import { yupResolver } from "@hookform/resolvers/yup"
import { createPoint, updatePoint } from "@/redux-toolkit/data/points/resources"
import React, { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import FPointProperty from "@/utils/components/jsonSchema/form/FPointProperty"
import yupPointProperty from "@/utils/components/jsonSchema/form/yupPointProperty"
import { FlexWrap } from "@/utils/components/style/flex"
import SuperModal from "@/utils/modals/SuperModal"
import IJsonSchema from "@/utils/types/IJsonSchema"
import IPoint from "@/utils/types/IPoint"
import Yup from "@/utils/yup"
import {
	removeQuotes,
	restoreQuotes,
	withoutQuote,
} from "./quoteFixForJsonSchema"
import { isMobile } from "react-device-detect"

const JsonSchemaForm = ({
	isOpen,
	onClose,
	isNew,
	selectedValues = undefined,
	jsonSchema,
	writePermissions = true,
	autoFocus = false,
}: {
	isOpen: boolean
	onClose: () => void
	isNew: boolean
	selectedValues?: IPoint | null
	jsonSchema: IJsonSchema
	writePermissions?: boolean
	autoFocus?: boolean
}) => {
	const dispatch = useDispatch()
	const properties = jsonSchema?.template?.properties ?? []

	const toQuoteFix = properties
		.filter((field) => field.name.includes("'"))
		.map((field) => field.name)

	const schema = useMemo(
		() =>
			Yup.object(
				Object.fromEntries(
					properties.map((property) => [
						property.name,
						yupPointProperty({ property, isNew }),
					]),
				),
			),
		[properties],
	)

	const defaultValues = useMemo(() => schema.getDefault(), [schema])

	const methods = useForm({
		resolver: yupResolver(restoreQuotes(schema, toQuoteFix)),
		defaultValues: defaultValues,
	})

	useEffect(() => {
		if (isOpen) {
			if (isNew) {
				methods.reset(defaultValues)
			} else {
				methods.reset(
					removeQuotes(schema, toQuoteFix).cast(
						selectedValues?.geojson?.properties,
					),
				)
			}
		}
	}, [isOpen])

	const onSubmit = (values: IPoint["geojson"]["properties"]) => {
		debugger
		if (isNew) {
			dispatch(
				createPoint({
					geojson: { properties: values },
					jsonschema_id: jsonSchema.id,
				}),
			)
		} else {
			dispatch(
				updatePoint({
					id: selectedValues.id,
					jsonschema_id: selectedValues.jsonschema_id,
					geojson: { properties: values },
				}),
			)
			onClose()
		}
		methods.reset(defaultValues)
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				disableSave={!methods.formState.isDirty}
				toggleOnClickOutside={!methods.formState.isDirty}
				allowKeepOpen
				isOpen={isOpen}
				onClose={onClose}
				onClick={methods.handleSubmit(onSubmit, console.info)}
				isNew={isNew}
				title={jsonSchema?.title}
				writePermissions={writePermissions}
			>
				<FlexWrap>
					{properties.map((property, index) => (
						<FPointProperty
							jsonSchema={jsonSchema}
							key={property.name}
							property={property}
							name={withoutQuote(property.name)}
							readOnly={!writePermissions}
							disabled={!writePermissions}
							autoFocus={!isMobile && index === 0}
						/>
					))}
				</FlexWrap>
			</SuperModal>
		</FormProvider>
	)
}

export default JsonSchemaForm
