import React, { useMemo } from "react"
import { Grid } from "@mui/material"
import styled from "styled-components"
import { H6 } from "utils/components/style/header"
import PanelDaybookData from "utils/panels/panelCarto/PanelDaybookData"
import CollapsibleList from "utils/components/list/CollapsibleList"
import Div from "utils/dnd/Div"
import useMapState from "@/hooks/useMapState"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import PanelLegendItem from "utils/panels/PanelLegendItem"
import getImage from "@/utils/getImage"
import {
	removeCoucheJsonSchemas,
	removeCoucheMultiPolygons,
} from "@/redux-toolkit/carto/reducer"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"

const SMultiPolygonColorSquare = styled(MultiPolygonColorSquare)`
    & > div {
        margin-right: 1.5rem !important;
    }
`

const DatasSelection = ({ setModalPanel, setIsEditingCoucheStyle }) => {
	const points = usePoints()
	const jsonSchemas = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const selectedEvent = useSelectedEvent()
	const { coucheJsonSchemas, coucheMultiPolygons } = useMapState()

	const countPoints = useMemo(() => {
		const count = {}

		Object.values(points).forEach((point) => {
			if (point.event_id === selectedEvent?.id) {
				if (count[point.jsonschema_id]) {
					count[point.jsonschema_id] += 1
				} else {
					count[point.jsonschema_id] = 1
				}
			}
		})

		return count
	}, [points, selectedEvent])

	return (
		<>
			<Grid container spacing={2}>
				<Grid item md={6} sm={12}>
					<H6>Légende actuelle :</H6>
					<CollapsibleList
						Wrapper={Div}
						buttonText="Données surfaciques"
						numberElements={Object.values(coucheMultiPolygons ?? {}).length}
					>
						<>
							{Object.values(coucheMultiPolygons).map((obj) => {
								const { id } = obj
								return (
									<PanelLegendItem
										key={id}
										entityId={id}
										canEditStyle
										canRemove
										title={multiPolygons[id]?.name}
										avatar={
											<SMultiPolygonColorSquare
												multiPolygon={multiPolygons[id]}
												marginRight="1rem"
											/>
										}
										visible
										editAction={() => {
											setIsEditingCoucheStyle(true)
											setModalPanel({
												panel: 2,
												id: id,
											})
										}}
										deleteAction={removeCoucheMultiPolygons(id)}
									/>
								)
							})}
						</>
					</CollapsibleList>
					<CollapsibleList
						Wrapper={Div}
						buttonText="Données PCS"
						numberElements={Object.values(coucheJsonSchemas).length}
					>
						<>
							{Object.values(coucheJsonSchemas ?? {}).map(
								(coucheJsonSchema: any) => {
									const { id } = coucheJsonSchema
									return (
										<PanelLegendItem
											key={id}
											jsonSchemaId={id}
											avatar={
												<img
													src={getImage(jsonSchemas[id]?.imgId)}
													width={35}
													height={35}
												/>
											}
											title={jsonSchemas[id]?.title}
											count={countPoints[id]}
											visible
											canEditStyle
											canRemove
											editAction={() => {
												setIsEditingCoucheStyle(true)
												setModalPanel({
													panel: 1,
													id: id,
												})
											}}
											deleteAction={removeCoucheJsonSchemas(
												coucheJsonSchema.id,
											)}
										/>
									)
								},
							)}
						</>
					</CollapsibleList>
				</Grid>
				<Grid item md={6} sm={12}>
					<H6>Ajouter :</H6>
					<PanelDaybookData noBackButton noListIcon />
				</Grid>
			</Grid>
		</>
	)
}

export default DatasSelection
