import React, { createContext, useState } from "react"

export const ParentSubmitContext = createContext<{
	onSubmit: (values) => void
	shouldSubmit: boolean
	setShouldSubmit: (val: boolean) => void
}>({
	onSubmit: () => {},
	shouldSubmit: false,
	setShouldSubmit: () => {},
})

export const ParentSubmitContextProvider = ({ children, onSubmit }) => {
	const [shouldSubmit, setShouldSubmit] = useState(false)

	return (
		<ParentSubmitContext.Provider
			value={{
				onSubmit,
				shouldSubmit,
				setShouldSubmit,
			}}
		>
			{children}
		</ParentSubmitContext.Provider>
	)
}
