import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"

const initialState = {
	users: {},
	communes: {},
	cdcs: {},
}

const accountsSlice = createSlice({
	name: "admin/accounts",
	initialState,
	reducers: {
		addUsers(state, { payload: users }) {
			users.forEach((user) => (state.users[user.id] = user))
		},
		addCommunes(state, { payload: communes }) {
			communes.forEach((commune) => (state.communes[commune.id] = commune))
		},
		addCdcs(state, { payload: cdcs }) {
			cdcs.forEach((cdc) => (state.cdcs[cdc.id] = cdc))
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default accountsSlice.reducer
export const { addUsers, addCommunes, addCdcs } = accountsSlice.actions
