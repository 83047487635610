/* eslint-disable react/no-unescaped-entities */
import React from "react"
import PH2 from "utils/pdf/header/PH2"
import PH4 from "utils/pdf/header/PH4"
import PList from "utils/pdf/list/PList"
import NormalText from "utils/pdf/NormalText"
import PPage from "utils/pdf/PPage"
import PCell from "utils/pdf/table/PCell"
import PRow from "utils/pdf/table/PRow"
import PTable from "utils/pdf/table/PTable"
import HumanService from "utils/services/HumanService"
import IPoint from "utils/types/IPoint"

const FirstPage = ({
	commune,
	responsableRgpd,
}: {
	commune: any
	responsableRgpd: IPoint
}) => {
	return (
		<PPage>
			<PH2>AC - RGPD Registre des activités de traitement {commune.name}</PH2>

			<PTable>
				<PRow>
					<PCell head>
						<PH4>Coordonnées du responsable de traitement Numérisk</PH4>
					</PCell>
					<PCell borderBottom>
						<NormalText>Amaury Fischer</NormalText>
						<NormalText>47 Avenue Danton, 17000 La Rochelle</NormalText>
						<NormalText>
							Co-gérant Numérisk, Responsable Traitement des données,
							Responsable R&amp;D
						</NormalText>
						<NormalText>07.69.99.77.85</NormalText>
					</PCell>
				</PRow>
				<PRow>
					<PCell head>
						<PH4>Coordonnées du Délégué à la protection des données</PH4>
					</PCell>
					<PCell>
						<NormalText>
							{HumanService.displayFullName(responsableRgpd)}
						</NormalText>
						<NormalText>
							{responsableRgpd?.geojson?.properties?.Adresse?.Adresse}
						</NormalText>
						<NormalText>
							{responsableRgpd?.geojson?.properties?.Poste}
						</NormalText>
						{(responsableRgpd?.geojson?.properties?.Téléphones ?? []).map(
							(tel) => (
								<NormalText key={tel.Numéro}>
									{tel.Type} : {tel.Numéro}
								</NormalText>
							),
						)}
					</PCell>
				</PRow>
			</PTable>

			<PH2>Liste des activités de traitement</PH2>

			<PList
				items={[
					'Gestion de Données "Statiques"',
					'Gestion de Données "Dynamiques"',
				]}
			></PList>
		</PPage>
	)
}

export default FirstPage
