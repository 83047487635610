import { Badge, Box, Tab, Tabs } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { ReactTableContainerContext } from "utils/hooks/useAutoPageSize"
import useReactTableContainer from "utils/hooks/useReactTableContainer"

const SBadge = styled(Badge)`
    & > .MuiBadge-badge {
        top: -4px !important;
    }
`

const StyledTabs = styled(Tabs)`
    border-bottom: 1px solid var(--neutral600);

    && .Mui-selected {
        font-weight: 700;
        color: var(--primary800);
        border-bottom: 5px solid var(--primary500);
        border-radius: 5px 5px 0px 0px;
    }
    && .MuiTabs-indicator {
        visibility: hidden !important;
    }
    && .MuiTabs-scroller {
        overflow-x: auto !important;
    }
    // margin: 2em;
`
const StyledBox = styled(Box)`
    padding: 16px 0 0 0 !important;
`
const StyledTab = styled(Tab)`
    && {
        color: var(--neutral600);
        text-transform: none;
    }
    padding: 12px !important;
`

const Scrollable = styled.div<{ $noScroll: boolean }>`
    ${({ $noScroll }) =>
			!$noScroll &&
			css`
            overflow: auto;
        `}
`

const ScrollableComponent = ({ children, scrollable, ...props }) => {
	const { value: contextValue, callbackRef } = useReactTableContainer()

	return (
		<Scrollable $noScroll={!scrollable} ref={callbackRef} {...props}>
			{scrollable ? (
				<ReactTableContainerContext.Provider value={contextValue}>
					{children}
				</ReactTableContainerContext.Provider>
			) : (
				children
			)}
		</Scrollable>
	)
}

const TabPanel = ({ children, value, index, scrollable, ...other }) => {
	const mounted = useRef(false)
	if (value !== index && !mounted.current) return null
	mounted.current = true

	return (
		<ScrollableComponent
			scrollable={scrollable}
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<StyledBox p={3}>{children}</StyledBox>
		</ScrollableComponent>
	)
}

const a11yProps = (index) => {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	}
}

export interface CustomTabsBaseProps {
	tabChildrens: { key: string; render: JSX.Element | null; badge?: number }[]
	endRender?: JSX.Element
	Wrapper?: string | ((props: { children: React.ReactNode }) => JSX.Element)
	fullWidthWrapper?: boolean
	scrollable?: boolean
}

interface CustomTabsBaseExtendsProps extends CustomTabsBaseProps {
	currentTab: number
	setCurrentTab: (n: number) => void
}

const CustomTabsBase = ({
	tabChildrens = [],
	endRender = null,
	Wrapper = "div",
	fullWidthWrapper = false,
	scrollable = false,
	currentTab,
	setCurrentTab,
}: CustomTabsBaseExtendsProps) => {
	const theme = useTheme()

	return (
		<Wrapper style={{ width: fullWidthWrapper ? "100%" : "auto" }}>
			<StyledTabs
				value={currentTab}
				onChange={(_event, index) => {
					setCurrentTab(index)
				}}
				aria-label="tabs"
			>
				{tabChildrens.map((child, index) => (
					<StyledTab
						label={
							child.badge ? (
								<SBadge badgeContent={child.badge} color="primary">
									{child.key}
								</SBadge>
							) : (
								child.key
							)
						}
						{...a11yProps(index)}
						key={child.key}
					/>
				))}
			</StyledTabs>
			{endRender}
			{tabChildrens.map((child, index) => (
				<TabPanel
					value={currentTab}
					index={index}
					dir={theme.direction}
					key={child.key}
					scrollable={scrollable}
				>
					{React.cloneElement(child.render, { currentTab })}
				</TabPanel>
			))}
		</Wrapper>
	)
}

export default CustomTabsBase
