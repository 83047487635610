import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"
import moment from "moment"
import { RUBY_DATE_FORMAT } from "@/utils/dateFormat"

const initialState = {}

const mappingsSlice = createSlice({
	name: "data/mappings",
	initialState,
	reducers: {
		addMapping(state, { payload: mapping }) {
			state[mapping.id] = mapping
		},
		removeMapping(state, { payload: id }) {
			delete state[id]
		},
		updateLocalMappingLastImport(state, { payload: id }) {
			state[id].last_import = moment().format(RUBY_DATE_FORMAT)
			state[id].must_update_last_import = true
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default mappingsSlice.reducer
export const { addMapping, removeMapping, updateLocalMappingLastImport } =
	mappingsSlice.actions
