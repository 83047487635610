import Axios from "axios"
import IEvent from "utils/types/IEvent"

const getEvents = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(`/api/events?lastSynchro=${lastSynchro}`)
	return result.data as { events: IEvent[]; serverDatetime: string }
}

const getEventsWithDeleted = async (
	lastSynchro = "2000-04-12T12:47:50+02:00",
) => {
	const result = await Axios.get(
		`/api/events?lastSynchro=${lastSynchro}&deleted=true`,
	)
	return result.data as { events: IEvent[]; serverDatetime: string }
}

const createEvent = async (event: IEvent) => {
	const result = await Axios.post("/api/events", {
		data: event,
	})

	return result.data as IEvent
}

const updateEvent = async (event: IEvent) => {
	const result = await Axios.put(`/api/events/${event.id}`, {
		data: event,
	})
	return result.data as IEvent
}

const deleteEvent = async (eventId: string) => {
	const result = await Axios.delete(`/api/events/${eventId}`)
	return result.data as IEvent
}

export default {
	getEvents,
	getEventsWithDeleted,
	createEvent,
	updateEvent,
	deleteEvent,
}
