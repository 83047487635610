import RiskService from "./services/RiskService"

// Tree
export const ANNUAIRES = "Annuaires"
export const ANNUAIRES_COMMUNE = "Annuaires de la commune"
export const DISPOSITIF_GESTION_CRISE = "Dispositif de gestion de crise"

export const RESSOURCES = "Ressources"
export const RESOURCES_LIEUX_BATIMENTS_COMMUNAUX =
	"Lieux et bâtiments communaux"
export const RESOURCES_MATERIELS_STOCKS_COMMUNAUX =
	"Matériels et stocks communaux"
export const RESOURCES_MOYENS_PRIVES_MOBILISABLES = "Moyens privés mobilisables"

export const ENJEUX = "Enjeux"
export const ENJEUX_POPULATIONS_VULNERABLES = "Populations vulnérables"
export const ENJEUX_LIEUX_PUBLICS_ERP = "Lieux publics et ERP"
export const ENJEUX_RESEAUX = "Réseaux"
export const ENJEUX_ACTIVITES_ECONOMIQUES = "Activités économiques"
export const ENJEUX_URBANISMES = "Urbanismes"

export const SECTEURS = "Alerte et secteurs"

export const RISQUES = "Risques"
export const EVACUATION_CONFINEMENT = "Évacuation et balisage"
export const menuTreeItems = {
	[DISPOSITIF_GESTION_CRISE]: [SECTEURS, EVACUATION_CONFINEMENT],
	[ANNUAIRES]: [ANNUAIRES_COMMUNE],
	[RESSOURCES]: [
		RESOURCES_LIEUX_BATIMENTS_COMMUNAUX,
		RESOURCES_MATERIELS_STOCKS_COMMUNAUX,
		RESOURCES_MOYENS_PRIVES_MOBILISABLES,
	],
	[ENJEUX]: [
		ENJEUX_POPULATIONS_VULNERABLES,
		ENJEUX_LIEUX_PUBLICS_ERP,
		ENJEUX_RESEAUX,
		ENJEUX_ACTIVITES_ECONOMIQUES,
		ENJEUX_URBANISMES,
	],
	[RISQUES]: RiskService.getRisquesList(),
}
