import React from "react"
import { isMobile, isSafari } from "react-device-detect"

const Defer = ({ chunkSize = 1, children, noDefer = false }) => {
	const [renderedItemsCount, setRenderedItemsCount] = React.useState(chunkSize)

	const childrenArray = React.useMemo(
		() => React.Children.toArray(children),
		[children],
	)

	React.useEffect(() => {
		if (noDefer) {
			return
		}
		if (renderedItemsCount < childrenArray.length) {
			if (!window.requestIdleCallback || isSafari) {
				setTimeout(() => {
					setRenderedItemsCount(
						Math.min(renderedItemsCount + chunkSize, childrenArray.length),
					)
				}, 200)
			} else {
				window.requestIdleCallback(
					() => {
						setRenderedItemsCount(
							Math.min(renderedItemsCount + chunkSize, childrenArray.length),
						)
					},
					{ timeout: 200 },
				)
			}
		}
	}, [renderedItemsCount, childrenArray.length, chunkSize, noDefer])
	if (noDefer) {
		return children
	}
	return <>{childrenArray.slice(0, renderedItemsCount)}</>
}

export default Defer
