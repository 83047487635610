export const FICHE = "FICHE"
export const FICHE_SCENARIO = "FICHE_SCENARIO"
export const FICHE_ACTION = "FICHE_ACTION"

export enum LinkType {
	JSON_SCHEMA = "JSON_SCHEMA",
	INTERNAL = "INTERNAL",
	EXTERNAL = "EXTERNAL",
	NO_LINK = "NO_LINK",

	RISK_FILE = "RISK_FILE",
	STATIC_FILE = "STATIC_FILE",
	DYNAMIC_FILE = "DYNAMIC_FILE",

	UPLOADED_CONVENTION = "UPLOADED_CONVENTION",
	UPLOADED_IMAGE = "UPLOADED_IMAGE",
	UPLOADED_CARTO = "UPLOADED_CARTO",
	UPLOADED_EDITABLE = "UPLOADED_EDITABLE",
}

export enum Vigilance {
	GREEN = "GREEN",
	YELLOW = "YELLOW",
	ORANGE = "ORANGE",
	RED = "RED",
	BLUE = "BLUE",
}

export const VigilanceColor = {
	[Vigilance.GREEN]: `#008000`,
	[Vigilance.YELLOW]: `#f2df00`,
	[Vigilance.ORANGE]: `#ff8520`,
	[Vigilance.RED]: `#ff0000`,
	[Vigilance.BLUE]: `#1F97F6`,
}
export const VigilanceLabel = {
	[Vigilance.GREEN]: "Vert",
	[Vigilance.YELLOW]: "Jaune",
	[Vigilance.RED]: "Rouge",
	[Vigilance.BLUE]: "Bleu (après la crise)",
	[Vigilance.ORANGE]: "Orange",
}

export interface IActionLink {
	type: LinkType
	[key: string]: string
}
export interface IAction {
	id: string
	Vigilance: Vigilance
	Contenu: string
	Cellules?: string[]
	Liens?: IActionLink[]
}

export interface IActionCategory {
	categoryName: string
	id: string
	actionIds: string[]
}

export enum TypeFiche {
	FICHE_SCENARIO = "FICHE_SCENARIO",
	FICHE_ACTION = "FICHE_ACTION",
}

interface IFiche {
	id: string
	risk: string
	classification: TypeFiche
	actions: { [id: string]: IAction }
	actionsByCategory: IActionCategory[]
	nom: string
}

export default IFiche
