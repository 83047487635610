import Chip from "@mui/material/Chip"
import styled, { css } from "styled-components"
export const StyledChip = styled(Chip) <{
    $colorProperty: string
    size: string
    $backgroundColorProperty?: string
    $fullWidth?: boolean
    $fitContent?: boolean
}>`
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${({ $fullWidth }) =>
        $fullWidth &&
        css`
                    width: 100%;
                `};
    
    ${({ $fitContent }) =>
        $fitContent &&
        css`
                width: fit-content;
            `};

    ${({ $colorProperty }) =>
        $colorProperty &&
        css`
            color: ${$colorProperty} !important;
            border-color: ${$colorProperty} !important;
        `};
    ${({ $backgroundColorProperty }) =>
        $backgroundColorProperty &&
        css`
            background-color: ${$backgroundColorProperty} !important;
        `};
    & > .MuiChip-label {
        ${({ size }) =>
        size === "small" &&
        css`
                font-size: 0.8rem !important;
            `};
    }
` as any
export const BlueChip = styled(Chip)`
    background-color: var(--primary300) !important;
    color: white !important;
`
export const StyledChipWithPadding = styled(Chip)`
    margin-right: 0.5em;
    margin-bottom: 0.5em;
`
export const AmberChip = styled(Chip)`
    background-color: var(--orange400) !important;
    color: black !important;
`
export const GreenChip = styled(Chip)`
    background-color: var(--green700) !important;
    color: white !important;
`
export const GreenOutlinedChip = styled(Chip)`
    color: var(--green700) !important;
    border-color: var(--green700) !important;
`
export const LightRedChip = styled(Chip)`
    background-color: var(--red500) !important;
    color: white !important;
`
export const RedChip = styled(Chip)`
    background-color: var(--red700) !important;
    color: white !important;
`
export const RedOutlinedChip = styled(Chip)`
    color: var(--red700) !important;
    border-color: var(--red700) !important;
`
export const OrangeChip = styled(Chip)`
    background-color: var(--orange400) !important;
    color: black !important;
`
export const OrangeOutlinedChip = styled(Chip)`
    color: var(--orange400) !important;
    border-color: var(--orange400) !important;
`
export const YellowChip = styled(Chip)`
    background-color: var(--yellow500) !important;
    color: black !important;
`

export const CustomChip = styled(Chip) <{
    /**
    / @deprecated
    **/
    backgroundColor?: string
    $backgroundColor: string
}>`
    background-color: ${({ backgroundColor }: any) => backgroundColor} !important;
    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
                    background-color: ${$backgroundColor} !important;
                `};
    color: white !important;
    & > span {
        font-size: 0.9rem;
    }
`

// new one

export const PrimaryChip = styled(Chip)`
    background-color: var(--primary200) !important;
    color: var(--primary800) !important;
`
export const NeutralChip = styled(Chip)`
    background-color: var(--neutral150) !important;
    color: var(--neutral999) !important;
`
