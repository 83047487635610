import React from "react"
import { TileLayer, WMSTileLayer } from "react-leaflet"
import * as L from "leaflet"

const getAllLayers = () => {
	return {
		"Ortho-imagerie HR": (
			<WMSTileLayer
				key="oertho"
				layers="OI.OrthoimageCoverage.HR"
				url="https://wxs.ign.fr/inspire/inspire/r/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&crs=EPSG:3857"
				crs={L.CRS.EPSG3857}
			/>
		),
		"Carroyages DFCI": (
			<>
				<TileLayer key="Osm" url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
				
			</>
		),

		Osm: (
			<TileLayer
				key="Osm"
				url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
				maxZoom={40}
				maxNativeZoom={19}
			/>
		),
		OsmCyclo: (
			<TileLayer
				key="OsmCyclo"
				url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
			/>
		),
		"Parcelles cadastrales": (
			<WMSTileLayer
				key="Parcelles cadastrales"
				layers="CP.CadastralParcel"
				url="https://wxs.ign.fr/inspire/inspire/r/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&crs=EPSG:3857"
				crs={L.CRS.EPSG3857}
			/>
		),
	}
}
export default {
	getAllLayers,
}
