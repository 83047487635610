export const VORTEX_STATION = "VORTEX_STATION"
export const TEMPORARY_CARE = "TEMPORARY_CARE"
export const CARE_FAMILY = "CARE_FAMILY"
export const CARTOGRAPHY = "CARTOGRAPHY"
export const MAIN_COURANTE = "GESTION_CRISE"
export const CUSTOMDATA = "CUSTOMDATA"
export const DOWNLOAD_PCS = "DOWNLOAD_PCS"
export const DOCUMENTS = "DOCUMENTS"
export const JSONSCHEMAS = "JSONSCHEMAS"
export const COOPERATION = "COOPERATION"
export const SUIVI_CRISE = "SUIVI_CRISE"
export const DASHBOARD = "DASHBOARD"
export const STOCKS = "STOCKS"
export const STOCK_PLACES = "STOCK_PLACES"
export const HUMANS = "HUMANS"
export const ORGA = "ORGA"
export const FICHES_ACTIONS = "FICHES_ACTIONS"
export const FICHES_SCENARIOS = "FICHES_SCENARIOS"
export const FICHES = "FICHES"
export const SUIVI_GROUP = "SUIVI_GROUP"
export const SUIVI_PCS = "SUIVI_PCS"
export const DONNEES_COMMUNALES = "DONNEES_COMMUNALES"
export const CUSTOM_PCS = "CUSTOM_PCS"
export const UPLOAD = "UPLOAD"
export const WHITELIST = "WHITELIST"
export const ARRETES = "ARRETES"
export const ARRETES_TEMPLATES = "ARRETES_TEMPLATES"
export const INCIDENT = "INCIDENT"
export const INTERVENTION = "INTERVENTION"
export const CARE = "CARE"
export const FICHE = "FICHE"
export const ARRETE = "ARRETE"
export const MESSAGE_ALERTE_TEMPLATE = "MESSAGE_ALERTE_TEMPLATE"
export const CREATION_CRISE = "CREATION_CRISE"
export const REPORT = "REPORT"
export const CONFIGURATION = "CONFIGURATION"
export const VIGILANCE_CHANGE = "VIGILANCE_CHANGE"
export const ALERTE = "ALERTE"
export const DISPOSITIF = "DISPOSITIF"
//* **********************************************
//* Path export for "Gestion de crise" menu
//* **********************************************
export const PATH_MAIN_COURANTE_DASHBOARD = `${MAIN_COURANTE}.${DASHBOARD}`
export const PATH_MAIN_COURANTE_SUIVI = `${MAIN_COURANTE}.${SUIVI_CRISE}`
export const PATH_MAIN_COURANTE_CARTOGRAPHY = `${MAIN_COURANTE}.${CARTOGRAPHY}`
export const PATH_MAIN_COURANTE_CREATION_CRISE = `${MAIN_COURANTE}.${CREATION_CRISE}`
export const PATH_MAIN_COURANTE_ORGA = `${MAIN_COURANTE}.${ORGA}`

export const PATH_MAIN_COURANTE_FICHE = `${PATH_MAIN_COURANTE_SUIVI}.${FICHE}`
export const PATH_MAIN_COURANTE_INCIDENT = `${PATH_MAIN_COURANTE_SUIVI}.${INCIDENT}`
export const PATH_MAIN_COURANTE_INTERVENTION = `${PATH_MAIN_COURANTE_SUIVI}.${INTERVENTION}`
export const PATH_MAIN_COURANTE_ARRETE = `${PATH_MAIN_COURANTE_SUIVI}.${ARRETE}`
export const PATH_MAIN_COURANTE_CARE = `${PATH_MAIN_COURANTE_SUIVI}.${CARE}`
export const PATH_MAIN_COURANTE_SUIVI_PCS = `${PATH_MAIN_COURANTE_SUIVI}.${SUIVI_PCS}`
export const PATH_MAIN_COURANTE_ALERTE = `${PATH_MAIN_COURANTE_SUIVI}.${ALERTE}`

//* **********************************************
//* Path export for "Données PCS" menu
//* **********************************************
export const PATH_DASHBOARD = `${CUSTOMDATA}.${DASHBOARD}`
export const PATH_DONNEES_COMMUNALES = `${CUSTOMDATA}.${DONNEES_COMMUNALES}`
export const PATH_CARTOGRAPHY = `${CUSTOMDATA}.${CARTOGRAPHY}`
export const PATH_ORGA = `${CUSTOMDATA}.${ORGA}`
export const PATH_FICHES = `${CUSTOMDATA}.${FICHES}`
export const PATH_FICHES_ACTIONS = `${CUSTOMDATA}.${FICHES}.${FICHES_ACTIONS}`
export const PATH_FICHES_SCENARIOS = `${CUSTOMDATA}.${FICHES}.${FICHES_SCENARIOS}`
export const PATH_CUSTOM_PCS = `${CUSTOMDATA}.${CUSTOM_PCS}`
export const PATH_ARRETES_TEMPLATES = `${CUSTOMDATA}.${ARRETES_TEMPLATES}`
export const PATH_MESSAGE_ALERTE_TEMPLATE = `${CUSTOMDATA}.${MESSAGE_ALERTE_TEMPLATE}`
//* **********************************************
//* Path export for "Documents" menu
//* **********************************************
export const PATH_DOWNLOAD_PCS = `${DOCUMENTS}.${DOWNLOAD_PCS}`
export const PATH_UPLOAD = `${DOCUMENTS}.${UPLOAD}`
export const PATH_REPORT = `${DOCUMENTS}.${REPORT}`

//* **********************************************
//* Path export for "JSONSCHEMAS" accessRights
//* **********************************************

export const initialState = {
	settings: {
		firstName: "",
		lastName: "",
		accessRights: {
			tools: {
				CONFIGURATION: {
					read: true,
					write: true,
				},
				CARTOGRAPHY: {
					read: true,
					write: true,
				},
				GESTION_CRISE: {
					read: true,
					write: true,
					canDeleteCrisis: true,
					CREATION_CRISE: {
						write: true,
						read: true,
					},
					DASHBOARD: {
						write: true,
						read: true,
					},
					CRISIS_CARTO: {
						read: true,
						write: true,
					},
					ORGA: {
						read: true,
						write: true,
					},
					SUIVI_CRISE: {
						read: true,
						INCIDENT: {
							read: true,
							write: true,
							canDeleteDatas: true,
						},
						INTERVENTION: {
							read: true,
							write: true,
							canDeleteDatas: true,
						},
						CARE: {
							read: true,
							write: true,
							canDeleteDatas: true,
						},
						ARRETE: {
							read: true,
							write: true,
							canDeleteDatas: true,
						},
						FICHE: {
							read: true,
							write: true,
						},
						SUIVI_PCS: {
							read: true,
							write: true,
							canDeleteDatas: true,
							canLockCrisis: true,
						},
					},
				},
				CUSTOMDATA: {
					read: true,
					DASHBOARD: {
						read: true,
						write: true,
					},
					DONNEES_COMMUNALES: {
						read: true,
					},
					ORGA: {
						read: true,
						write: true,
					},
					FICHES: {
						read: true,
						FICHES_ACTIONS: {
							read: true,
							write: true,
						},
						FICHES_SCENARIOS: {
							read: true,
							write: true,
						},
					},
					CUSTOM_PCS: {
						read: true,
						write: true,
					},
					ARRETES_TEMPLATES: {
						read: true,
						write: true,
					},
				},
				COOPERATION: {
					read: true,
					write: true,
				},
				DOCUMENTS: {
					read: true,
					DOWNLOAD_PCS: {
						read: true,
						write: true,
					},
					UPLOAD: {
						read: true,
						write: true,
					},
				},
				JSONSCHEMAS: {
					accessAll: true,
					readAll: true,
					WHITELIST: {},
					BLACKLIST: [],
				},
			},
		},
	},
}
