import styled, { css } from "styled-components"

export interface ColoredProps {
	$backgroundColor?: string
	$textColor?: string
	$borderColor?: string
	$color
}

function colored<P, V extends React.ComponentType<P>>(Component: V) {
	return styled(Component)<ColoredProps>`
        ${({ $color }) =>
					$color &&
					css`
                --nextui-primary: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}-lightness);
                --nextui-primary-foreground: var(--${$color}-text-color);
                --nextui-primary50: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}50-lightness);
                --nextui-primary100: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}100-lightness);
                --nextui-primary200: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}200-lightness);
                --nextui-primary300: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}300-lightness);
                --nextui-primary400: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}400-lightness);
                --nextui-primary500: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}500-lightness);
                --nextui-primary600: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}600-lightness);
                --nextui-primary700: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}500-lightness);
                --nextui-primary800: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}800-lightness);
                --nextui-primary900: var(--${$color}-hue) var(--${$color}-saturation) var(--${$color}900-lightness);
        `}
        ${({ $backgroundColor }) =>
					$backgroundColor &&
					css`
                background-color: ${$backgroundColor} !important;
            `}
        ${({ $textColor }) =>
					$textColor &&
					css`
                    --nextui-foreground: var(--${$textColor}-hue) var(--${$textColor}-saturation) var(--${$textColor}-lightness); 
                color: ${$textColor} !important;
            `}
        ${({ $borderColor }) =>
					$borderColor &&
					css`
                border-color: ${$borderColor} !important;
            `}
    `
}

export default colored
