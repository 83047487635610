import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import {
	IHystoryElementAugmented,
	IHystoryElementDescription,
} from "utils/types/IEvent"
import { CardContent } from "@mui/material"
import sanitize from "sanitize-html"
import { Flex } from "utils/components/style/flex"
import ReactHtmlParser from "react-html-parser"

// app:javascript:components:maincourante:subComponents:historicCard:HistoryCardDescription.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:historicCard:HistoryCardDescription.tsx",
)
debug.log = console.log.bind(console)
const StyledContainer = styled.div`
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem;
`

const StyledTable = styled.table`
    width: 100%;
    tr {
        border-bottom: 1px solid var(--neutral200);
        th,
        td {
            padding: 0.5rem;
        }
        td:first-child {
            font-weight: 500;
        }
    }
    tr:last-child {
        border-bottom: none;
    }
    th {
        font-size: 0.8rem;
        color: var(--neutral500);
        font-weight: 400;
    }
`

const RedCard = styled.div`
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: var(--red100);
    border-radius: 4px;
`
const GreenCard = styled.div`
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: var(--green100);
    border-radius: 4px;
`

const HistoryCardDescription = ({
	historyEvent,
}: {
	historyEvent: IHystoryElementAugmented
}) => {
	if (typeof historyEvent.description === "string") {
		return (
			<CardContent>
				<div
					dangerouslySetInnerHTML={{
						__html: sanitize(historyEvent.description, {
							allowedTags: ["b", "ul", "li", "br", "del"],
						}),
					}}
				/>
			</CardContent>
		)
	}

	const description = historyEvent.description as IHystoryElementDescription
	const determinatedDesc =
		description.Objet === "Réponses"
			? "Points de situation" //* "Réponses" label has only been visually renamed to this label
			: description.Objet

	return (
		<>
			<CardContent>
				<b>{determinatedDesc}</b>
				<StyledTable>
					<tr>
						<th>Type</th>
						<th>Ancienne valeur</th>
						<th>Nouvelle valeur</th>
					</tr>

					{Object.keys(description?.details ?? {}).map((key) => {
						// // @ts-ignore
						const descriptionDetail = description.details[key]
						if (typeof descriptionDetail === "string") {
							return (
								<tr key={key}>
									<td>{key}</td>
									<td>
										<div
											dangerouslySetInnerHTML={{
												__html: sanitize(descriptionDetail, {
													allowedTags: ["b", "ul", "li", "br", "del"],
												}),
											}}
										/>
									</td>
								</tr>
							)
						}
						if (
							typeof descriptionDetail.oldValue === "object" ||
							typeof descriptionDetail.newValue === "object"
						) {
							return null
						}
						return (
							<tr key={key}>
								<td>{key}</td>
								<td>
									<RedCard>
										<del>{ReactHtmlParser(descriptionDetail.oldValue)}</del>
									</RedCard>
								</td>
								<td>
									<GreenCard>
										{ReactHtmlParser(descriptionDetail.newValue)}
									</GreenCard>
								</td>
							</tr>
						)
					})}
				</StyledTable>
			</CardContent>
		</>
	)
}

export default HistoryCardDescription
