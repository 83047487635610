import { createSlice } from "@reduxjs/toolkit"
import { isDesktop } from "react-device-detect"
import { resetRedux } from "../rootActions"
import { toast } from "react-toastify"
import {
	ARRETE,
	CARE_FAMILY,
	INCIDENT,
	INTERVENTION,
} from "../userSettings/constants"
const initialState = {
	loading: 0,
	initialized: false,
	selectedEvent: undefined,
	isOnline: true,
	activeTool: 0,
	activeMapTool: undefined,
	notifications: [],
	tags: [],
	panel: undefined,
	oldPanel: undefined,
	isChangingCarto: false,
	picrimMapInitialized: false,
	processingSomething: null,
	openMenu: false,
}

const commonSlice = createSlice({
	name: "common",
	initialState,
	reducers: {
		setOpenMenu(state, { payload: open }) {
			state.openMenu = open
		},
		setMaptoolsActivetool(state, { payload: mapTool }) {
			state.activeMapTool = mapTool
		},
		closeMaptools(state) {
			state.activeMapTool = undefined
		},
		setOnline(state) {
			state.isOnline = true
		},
		setOffLine(state) {
			state.isOnline = false
		},
		setActiveTool(state, { payload: tool }) {
			state.activeTool = tool
		},
		setSelectedEvent(state: any, { payload: eventId }: { payload: string }) {
			state.selectedEvent = eventId
		},
		addLoading(state) {
			state.loading++
		},
		removeLoading(state) {
			state.loading--
		},
		addLoadingKey(state, { payload: key }) {
			state[key] = (state[key] ?? 0) + 1
		},
		removeLoadingKey(state, { payload: key }) {
			state[key]--
		},
		setTags(state, { payload: tags }) {
			state.tags = tags
		},
		initialization(state) {
			state.initialized = true
		},
		pushNotifications(state, { payload: notifications }) {
			if (notifications.length > 0) {
				switch (notifications[0].type) {
					case ARRETE:
						break
					case INCIDENT:
						break
					case INTERVENTION:
						break
					case CARE_FAMILY:
						break
					default:
						break
				}
			}
			state.notifications.push(...notifications)
		},
		clearNotifications(state) {
			state.notifications = []
		},
		setPanel(state, { payload: panel }) {
			state.oldPanel = state.panel
			state.panel = panel
		},
		restorePanel(state) {
			state.panel = state.oldPanel
		},
		toggleChangingCarto(state) {
			state.isChangingCarto = !state.isChangingCarto
		},

		picrimInitMultipolygons(state) {
			state.picrimMapInitialized = !state.picrimMapInitialized
		},
		setProcessingSomething(state, { payload: message }) {
			state.processingSomething = message
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux, (state) => {
			state.initialized = false
		})
	},
})

export default commonSlice.reducer
export const {
	setMaptoolsActivetool,
	closeMaptools,
	setOnline,
	setOffLine,
	setActiveTool,
	setSelectedEvent,
	addLoading,
	removeLoading,
	addLoadingKey,
	removeLoadingKey,
	initialization,
	pushNotifications,
	clearNotifications,
	setTags,
	setPanel,
	toggleChangingCarto,
	restorePanel,
	picrimInitMultipolygons,
	setProcessingSomething,
	setOpenMenu,
} = commonSlice.actions
