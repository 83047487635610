import Axios from "axios"
import ErrorService from "@/services/ErrorService"
import { ISearchAddress } from "./ApiGoogleAddress"

const searchAddressAdmin = async (address) => {
	let toSendAddress = address.split(" ").join("+")
	while (toSendAddress.includes("++")) {
		toSendAddress = toSendAddress.split("++").join("+")
	}
	while (toSendAddress.includes("+ ")) {
		toSendAddress = toSendAddress.split("+ ").join("+")
	}
	while (toSendAddress.includes(" +")) {
		toSendAddress = toSendAddress.split(" +").join("+")
	}
	while (toSendAddress.endsWith("+")) {
		toSendAddress = toSendAddress.slice(0, -1)
	}
	const res = await Axios.get(
		`https://api-adresse.data.gouv.fr/search/?q=${toSendAddress}`,
		{
			// remove `common` attribute to avoid request CORS error
			transformRequest: [
				(data, headers) => {
					delete headers.common
					return data
				},
			],
		},
	)
	return res
}

const searchAddress: ISearchAddress = async (address, setLoading) => {
	try {
		setLoading?.(true)
		const res = await Axios.get(
			`https://api-adresse.data.gouv.fr/search/?q=${address}`,
			{
				// remove `common` attribute to avoid request CORS error
				transformRequest: [
					(data, headers) => {
						console.log(headers)
						delete headers.common
						return data
					},
				],
			},
		)
		return (res?.data?.features ?? []).map((feature) => {
			return {
				Adresse: feature.properties.label,
				coo: {
					lat: feature.geometry.coordinates[1],
					lng: feature.geometry.coordinates[0],
				},
			}
		})
	} catch (error) {
		// do nothing
	} finally {
		setLoading?.(false)
	}
}

const reverseSearchLatLng = async ({ lat, lng }) => {
	try {
		const res = await Axios.get(
			`https://api-adresse.data.gouv.fr/reverse/?lon=${lng}&lat=${lat}`,
			{
				// remove `common` attribute to avoid request CORS error
				transformRequest: [
					(data, headers) => {
						console.log(headers)
						delete headers.common
						return data
					},
				],
			},
		)
		return res?.data?.features[0]?.properties?.label
	} catch (error) {
		// do nothing
		console.log(error)
	}
}

export default {
	reverseSearchLatLng,
	searchAddressAdmin,
	searchAddress,
}
