import { View } from "@react-pdf/renderer"
import { GENERIC_BLUE } from "@/pages/rgpd/pdf/RgpdPDF"
import React from "react"

const PTable = ({ children, marginBottom = "16px", ...props }) => {
	return (
		<View
			{...props}
			style={{
				...{
					flexDirection: "column",
					border: 1,
					borderColor: GENERIC_BLUE,
					marginBottom,
				},
				...props.style,
			}}
		>
			{children}
		</View>
	)
}

export default PTable
