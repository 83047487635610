import GetAppIcon from "@mui/icons-material/GetApp"
import React, { useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { isMobile } from "react-device-detect"
import styled from "styled-components"
import { CSVLinkText } from "../../../styles/atoms/Button/specialized/ExtractionBouton"
import Button from "@/styles/atoms/Button"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import LightTooltip from "../../../styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { Kbd } from "@nextui-org/react"

const HiddenCSVLink = styled(CSVLink)`
  display: none;
`

const ExtractionButtonTable = ({
	convertToCsv,
	selected,
	noContainer = false,
}) => {
	const ref = useRef(null)
	const [isMouseHover, setIsMouseHover] = useState(false)
	const [isPressed] = useKeyboardJs("ctrl + d")

	useEffect(() => {
		if (isPressed) {
			ref.current.link.click()
		}
	}, [isPressed])

	const csvData = convertToCsv(selected.length ? selected : null)
	const Container = noContainer ? (props) => <div {...props} /> : Button
	return (
		<>
			<LightTooltip
				placement="top"
				title={
					<>
						Raccourci : <Kbd>Ctrl + d</Kbd>
					</>
				}
			>
				<Container
					color="primary"
					variant="bordered"
					id="extraction-button"
					className={isMobile ? "w-full" : ""}
					onClick={() => ref.current.link.click()}
					startContent={<GetAppIcon />}
					onMouseEnter={() => setIsMouseHover(true)}
					onMouseLeave={() => setIsMouseHover(false)}
					$fullWidth
				>
					{/** @ts-ignore */}
					<CSVLinkText isMouseHover={isMouseHover}>
						{isMobile
							? "Extraire"
							: selected.length
								? "Extraire les données séléctionnées"
								: "Extraire toutes les données"}
					</CSVLinkText>
				</Container>
			</LightTooltip>
			<HiddenCSVLink {...csvData} ref={ref} separator={";"} />
		</>
	)
}
export default ExtractionButtonTable
