import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useBoolean, useDebounce } from "react-use"
import ApiDataGouvAddress from "utils/api_externe/ApiDataGouvAddress"
import ApiGoogleAddress from "utils/api_externe/ApiGoogleAddress"
import useCommune from "../useCommune"

const useAddressSearch = ({
	value,
	condition,
}: {
	value: any
	condition: boolean
}) => {
	const [apiAddressResults, setApiAddressResults] = useState([])
	const [lastSearch, setLastSearch] = useState("")
	const commune = useCommune()
	const dispatch = useDispatch()

	const apiCall = useCallback(async () => {
		try {
			let res = []
			setLastSearch(value?.Adresse)
			res = await ApiGoogleAddress.searchAddress(
				value?.Adresse + " " + commune.name,
				dispatch,
			)
			if (res.length === 0) {
				res = await ApiDataGouvAddress.searchAddress(
					value?.Adresse + " " + commune.name,
					dispatch,
				)
			}
			setApiAddressResults(res)
		} catch (error) {
			// coucou
		}
	}, [value?.Adresse, commune.name])

	// debounce with react-use
	const [isReady, _cancel] = useDebounce(
		() => {
			if (value?.Adresse !== lastSearch && condition && value?.Adresse !== "") {
				apiCall()
			}
		},
		1000,
		[value?.Adresse, lastSearch, condition],
	)

	return {
		isLoading:
			isReady() === false &&
			condition &&
			value?.Adresse !== "" &&
			value?.Adresse !== lastSearch,
		apiAddressResults,
		setApiAddressResults,
		lastSearch,
		setLastSearch,
	}
}

export default useAddressSearch
