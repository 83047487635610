import { createAsyncThunk } from "@reduxjs/toolkit"
import { EVENTS } from "@/redux-toolkit/time/constants"
import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import SynchroEventService from "@/services/synchro/SynchroEventService"
import SynchroPointService from "@/services/synchro/SynchroPointService"
import { getFiches } from "@/redux-toolkit/data/fiche/resources"
import _ from "lodash"
import ErrorService from "@/services/ErrorService"
import EventApi from "./EventApi"
import { getPdfs } from "@/redux-toolkit/data/pdf/resources"

import {
	addEvent,
	createLocalEvent,
	deleteLocalEvent,
	removeEvent,
	replaceEvent,
	updateLocalEvent,
} from "./reducer"
import { pushNotifications } from "@/redux-toolkit/common/reducer"
import { toast } from "react-toastify"
import {
	ARRETE,
	CARE_FAMILY,
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import { addCoucheMultiPolygons } from "@/redux-toolkit/daybook/reducer"
import SynchroMultiPolygonService from "@/services/synchro/SynchroMultiPolygonService"
import IEvent from "@/utils/types/IEvent"
import { VigilanceLabel } from "@/utils/types/IFiche"

// ! START LOCAL

export const createEvent = createAsyncThunk(
	"data/events/createEvent",
	async (event: IEvent, { dispatch, getState }) => {
		dispatch(createLocalEvent(event))
		SynchroEventService.synchronize(dispatch, getState, true)
	},
)

export const updateEvent = createAsyncThunk(
	"data/events/updateEvent",
	async (event: IEvent, { dispatch, getState }) => {
		dispatch(updateLocalEvent(event))
		await SynchroEventService.synchronize(dispatch, getState, true)
	},
)

export const deleteEvent = createAsyncThunk(
	"data/events/deleteEvent",
	async (event: IEvent, { dispatch, getState }) => {
		dispatch(deleteLocalEvent(event))
		SynchroEventService.synchronize(dispatch, getState, true)
	},
)

export const addEventWebSocket = createAsyncThunk(
	"data/events/addEventWebSocket",
	async (event: IEvent, { dispatch, getState }) => {
		const currentStoreState = getState() as any

		// store old event for deepcompare
		const oldEvent =
			currentStoreState.data.events[currentStoreState.common.selectedEvent]

		const difference = _.differenceWith(event as any, oldEvent, _.isEqual)
		// vigilance update
		if (!_.isEqual(event.vigilance, oldEvent.vigilance)) {
			toast.success(
				"Niveau de vigilance mis à jour : " + VigilanceLabel[event.vigilance],
			)
		}

		// synchronize new multiPolygons
		const differenceMultiPolygons = _.differenceWith(
			event.multiPolygonsIds,
			oldEvent.multiPolygonsIds,
			_.isEqual,
		)
		if (differenceMultiPolygons.length > 0) {
			await SynchroMultiPolygonService.synchronize(dispatch, getState)
			differenceMultiPolygons.forEach((id) => {
				dispatch(addCoucheMultiPolygons({ id }))
			})
		}
		const differenceMultiPolygonsOld = _.differenceWith(
			oldEvent.multiPolygonsIds,
			event.multiPolygonsIds,
			_.isEqual,
		)
		if (differenceMultiPolygonsOld.length > 0) {
			await SynchroMultiPolygonService.synchronize(dispatch, getState)
		}

		// difference history to generate notifications
		const differenceHistory = _.differenceWith(
			event.history,
			oldEvent.history,
			_.isEqual,
		)
		let shouldSynchronizePoints = false
		const shouldSynchronizeFiches = false
		let shouldSynchronizeArretes = false
		differenceHistory.forEach((historyEvent) => {
			if (
				historyEvent.type === INTERVENTION ||
				historyEvent.type === INCIDENT ||
				historyEvent.type === CARE_FAMILY ||
				historyEvent.type === TEMPORARY_CARE
			) {
				shouldSynchronizePoints = true
			}
			if (historyEvent.type === ARRETE) {
				shouldSynchronizeArretes = true
			}
		})
		if (difference.length === 0) {
			shouldSynchronizePoints = true
		}
		dispatch(pushNotifications(differenceHistory))
		if (shouldSynchronizePoints) {
			SynchroPointService.synchronize(dispatch, getState, false)
		}
		if (shouldSynchronizeFiches) {
			getFiches(dispatch, getState())
		}
		if (shouldSynchronizeArretes) {
			getPdfs(dispatch)
		}
		dispatch(addEvent(event))
		SynchroEventService.synchronize(dispatch, getState, false)
	},
)

// ! END LOCAL

// ! START SYNCHRONIZATION

export const getEvents = async (dispatch, getState) => {
	try {
		const { events, serverDatetime } = await EventApi.getEvents(
			getState().time[EVENTS],
		)
		dispatch(setServerDatetime({ datetime: serverDatetime, entity: EVENTS }))
		events.forEach((event) => dispatch(addEvent(event)))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des Evènements, message: ${error.message}`
		ErrorService.error({
			component: "resources:getEvents",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeCreatedEvent = async (
	dispatch: any,
	event: IEventLocal,
) => {
	const { localStatus, localModifications, ...toCreateEvent } = event
	try {
		const createdEvent = await EventApi.createEvent(toCreateEvent)
		// @ts-ignore
		if (createdEvent?.status === 404) {
			return
		}
		dispatch(replaceEvent(createdEvent))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de création d'un évènement', message: ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeCreatedEvent",
			message: errorMessage,
			dispatch,
			silence: true,
		})
	}
}

export const synchronizeUpdatedEvent = async (dispatch, event) => {
	const { localStatus, localModifications, ...toUpdateEvent } = event
	try {
		const updatedEvent = await EventApi.updateEvent(toUpdateEvent)
		dispatch(replaceEvent(updatedEvent))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la modification d'un évènement, message: ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeUpdatedEvent",
			message: errorMessage,
			dispatch,
		})
	}
	return
}

export const synchronizeDeletedEvent = async (dispatch, event) => {
	try {
		const deletedEvent = await EventApi.deleteEvent(event.id)
		dispatch(removeEvent(deletedEvent.id))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la suppression d'un évènement, message: ${error.message}`
		ErrorService.error({
			component: "resources:synchronizeDeletedEvent",
			message: errorMessage,
			dispatch,
		})
	}
}

export const getEventsWithDeleted = async (dispatch, getState) => {
	try {
		const { events, serverDatetime } = await EventApi.getEventsWithDeleted(
			getState().time[EVENTS],
		)
		dispatch(setServerDatetime({ datetime: serverDatetime, entity: EVENTS }))
		events.forEach((event) => {
			if (event.deleted) {
				dispatch(removeEvent(event.id))
				return
			}
			dispatch(addEvent(event))
		})
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des évènements, message d'erreur: ${error.message}`
		ErrorService.error({
			component: "resources:getEventsWithDeleted",
			message: errorMessage,
			dispatch,
		})
	}
}

// ! END SYNCHRONIZATION
