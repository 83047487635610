import keyboard from "./internal/keyboard"
import React, { useState, useEffect } from "react"
import useMount from "react-use/lib/useMount"
import useUnmount from "react-use/lib/useUnmount"
import KeyboardService from "@/pages/carto2/cartographie/service/KeyboardService"
import { isMobile } from "react-device-detect"
const useKeyboardJs = function (combination, stopPropagation = true) {
	//return [false]
	if (isMobile) {
		return [false]
	}
	const [uuid, setUUID] = useState(undefined)

	const _a = useState([false, null]),
		state = _a[0],
		set = _a[1]

	const _b = useState(null),
		keyboardJs = _b[0],
		setKeyboardJs = _b[1]

	useMount(() => {
		const uuidToRegister = KeyboardService.registerKeyboardShortcut(combination)
		setUUID(uuidToRegister)
		Promise.resolve()
			.then(function () {
				return keyboard
			})
			.then(function (k) {
				return setKeyboardJs(k)
			})
	})

	useUnmount(() => {
		KeyboardService.removeKeyboardShortcut(uuid)
	})

	useEffect(
		function () {
			if (!keyboardJs) {
				return
			}
			const down = function (event) {
				// if not first then do not update isPressed value
				if (stopPropagation) {
					event.stopPropagation()
					event.preventDefault()
				}
				if (!KeyboardService.isCombinationFirst({ combination, uuid })) {
					return
				}
				return set([true, event])
			}
			const up = function (event) {
				return set([false, event])
			}
			keyboardJs.bind(combination, down, up, true)
			return function () {
				keyboardJs.unbind(combination, down, up)
			}
		},
		[combination, keyboardJs, uuid],
	)
	return [...state, uuid]
}
export default useKeyboardJs
