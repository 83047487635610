const isPositionValid = (position) => {
	return !(
		position.includes(undefined) ||
		position.includes("") ||
		position.includes(null) ||
		position.includes(0)
	)
}

export default isPositionValid
