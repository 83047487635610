import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import { StyledChip } from "utils/components/style/chip"
import { Avatar } from "@mui/material"

// app:javascript:components:maincourante:care:chips:AdultChip.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:chips:AdultChip",
)
debug.log = console.log.bind(console)

export const StyledAvatarCareChip = styled(Avatar)<{
	$color: string
	$backgroundColor: string
	$small?: boolean
}>`
    width: auto !important;
    min-width: ${({ $small }) => ($small ? "18px" : "24px")} !important;
    max-width: 36px !important;
    font-size: ${({ $small }) => ($small ? "0.8rem" : "0.9rem")} !important;
    color: ${({ $color }) => $color} !important;
    background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
`

const AdultChip = ({ number = undefined, ...props }) => {
	return (
		<StyledChip
			$colorProperty="var(--green500)"
			variant="outlined"
			label={number > 1 ? "Adultes" : "Adulte"}
			avatar={
				<StyledAvatarCareChip
					$color="var(--green500)"
					$backgroundColor="var(--green50)"
					$small={props.size === "small"}
				>
					{number}
				</StyledAvatarCareChip>
			}
			{...props}
		/>
	)
}

export default AdultChip
