import Delete from "@mui/icons-material/Delete"
import Edit from "@mui/icons-material/Edit"
import OutboxIcon from "@mui/icons-material/Outbox"
import { Divider, MenuItem } from "@mui/material"
import useAccessRights from "@/hooks/useAccessRights"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	deletePointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import { setCurrentIntervention } from "@/redux-toolkit/edit/reducer"
import {
	INTERVENTION,
	PATH_MAIN_COURANTE_INCIDENT,
	PATH_MAIN_COURANTE_INTERVENTION,
} from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import ConfirmationModal from "utils/components/ConfirmationModal"
import { Flex } from "utils/components/style/flex"
import { NormalRed } from "utils/components/style/text"
import { widthCustom } from "utils/components/tables/widthProps"
import MoreMenu from "utils/menu/MoreMenu"
import TableIntervention from "./TableIntervention"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:maincourante:following:TableIncidentSubRow.tsx
const debug = Debug(
	"app:javascript:components:maincourante:following:TableIncidentSubRow",
)
debug.log = console.log.bind(console)

const TableIncidentSubRow = ({
	incident,
	type,
	addTask = undefined,
}: {
	incident: any
	type: "DIRECT" | "NEW" | "EDIT"
	addTask?: (task: any) => void
}) => {
	const points = usePoints()

	const [shouldClose, setShouldClose] = useState(false)
	const [tempIntervention, setTempIntervention] = useState(undefined)
	const [tempInterventionUnlink, setTempInterventionUnlink] =
		useState(undefined)
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const incidentPermissions = useAccessRights(EnumToolPath.INCIDENT)
	const interventionPermissions = useAccessRights(EnumToolPath.INTERVENTION)

	const linkedInterventions = Object.values(points).filter(
		(point) =>
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === INTERVENTION &&
			point?.geojson?.properties?.["En lien avec"] ===
				(type === "NEW" ? incident?.geojson?.properties?.tmpId : incident.id),
	)
	const isLocked = selectedEvent?.locked

	const removeLinkFromIntervention = (intervention) => {
		const clonedIntervention = _.cloneDeep(intervention)
		delete clonedIntervention.isExpanded
		delete clonedIntervention.geojson.properties["En lien avec"]
		dispatch(
			updatePointWithUpdateEvent({
				point: clonedIntervention,
				event: {
					...selectedEvent,
					updated_at: Moment().format(),
				},
			}),
		)
	}

	const addBackLinkToIntervention = (intervention) => {
		const clonedIntervention = _.cloneDeep(intervention)
		clonedIntervention.geojson.properties["En lien avec"] = incident.id
		delete clonedIntervention.isExpanded
		dispatch(
			updatePointWithUpdateEvent({
				point: clonedIntervention,
				event: {
					...selectedEvent,
					updated_at: Moment().format(),
				},
			}),
		)
	}

	const handleDetach = (row) => {
		if (type === "DIRECT") {
			removeLinkFromIntervention(row.original)
			return
		}
		if (type === "EDIT") {
			removeLinkFromIntervention(row.original)
			addTask?.(() => addBackLinkToIntervention(row.original))
			return
		}
		// case new
		if (type === "NEW") {
			removeLinkFromIntervention(row.original)
			return
		}
	}

	const additionalColumns = useMemo(
		() => [
			{
				header: "Actions",
				accessorKey: "id",
				enableSorting: false,
				cell: ({ row }) => (
					<>
						<MoreMenu
							setShouldClose={setShouldClose}
							shouldClose={shouldClose}
							options={[
								<MenuItem
									key="edit"
									onClick={() => {
										setShouldClose(true)
										dispatch(setCurrentIntervention(row.original))
									}}
								>
									<Flex gap="1rem">
										<Edit />
										{incidentPermissions.write ? "Éditer" : "Voir"}
									</Flex>
								</MenuItem>,
								...(type === "NEW" ||
								(!incidentPermissions.write && !interventionPermissions.write)
									? []
									: [
											<Divider key="firstDivider" light />,
											<MenuItem
												key="detach"
												onClick={() => {
													setShouldClose(true)
													setTempInterventionUnlink(row)
												}}
											>
												<Flex gap="1rem">
													<OutboxIcon />
													Retirer le lien avec l&#8217;évènement
												</Flex>
											</MenuItem>,
									  ]),
								...(!interventionPermissions.write
									? []
									: [
											<Divider key="secondDivider" light />,
											<MenuItem
												key="delete"
												onClick={() => {
													setShouldClose(true)
													setTempIntervention(row.original)
												}}
											>
												<NormalRed>
													<Flex gap="1rem">
														<Delete />
														Supprimer Définitivement
													</Flex>
												</NormalRed>
											</MenuItem>,
									  ]),
							]}
						/>
					</>
				),
				...widthCustom(80),
			},
		],
		[points, shouldClose],
	)

	const onDeleteIntervention = () => {
		dispatch(
			deletePointWithUpdateEvent({
				point: tempIntervention,
				event: {
					...selectedEvent,
					history: [
						...selectedEvent?.history,
						{
							type: INTERVENTION,
							title: "Suppression d'Intervention",
							date: Moment().format(),
							noAction: true,
						},
					],
				},
			}),
		)
	}

	return (
		<>
			<Flex directionColumn gap="0.5rem" fullWidth>
				<TableIntervention
					interventions={linkedInterventions.map((intervention, index) => {
						if (index === 0) {
							return {
								...intervention,
								isExpanded: true,
							}
						}
						return intervention
					})}
					tableProps={{
						showBottom: false,
						infiniteSize: true,
						simpleTable: true,
						selectable: false,
						isDeletable: false,
						autoResetExpanded: false,
						$secondaryTableStyle: true,
					}}
				/>
			</Flex>
			<ConfirmationModal
				modalShown={tempIntervention}
				onClose={() => setTempIntervention(undefined)}
				onConfirm={onDeleteIntervention}
				message={"Voulez vous vraiment supprimer l'intervention ?"}
			/>
			<ConfirmationModal
				modalShown={tempInterventionUnlink}
				onClose={() => setTempInterventionUnlink(undefined)}
				onConfirm={() => {
					handleDetach(tempInterventionUnlink)
					setTempInterventionUnlink(undefined)
				}}
				message={"Voulez vous vraiment supprimer le lien avec l'évènement ?"}
			/>
		</>
	)
}

export default TableIncidentSubRow
