import domtoimage from "dom-to-image"
import fileSaver from "file-saver"
import L from "leaflet"

// @ts-ignore
L.Control.EasyPrint = L.Control.extend({
	options: {
		title: "Print map",
		position: "topleft",
		sizeModes: ["Current"],
		filename: "map",
		exportOnly: false,
		skipSaving: false,
		hidden: false,
		tileWait: 500_000,
		hideControlContainer: true,
		hideClasses: [],
		customWindowTitle: window.document.title,
		spinnerBgCOlor: "#0DC5C1",
		customSpinnerClass: "epLoader",
		defaultSizeTitles: {
			Current: "Current Size",
			A4Landscape: "A4 Landscape",
			A4Portrait: "A4 Portrait",
		},
	},

	onAdd: function () {
		this.mapContainer = this._map.getContainer()
		this.options.sizeModes = this.options.sizeModes.map(function (sizeMode) {
			if (sizeMode === "Current") {
				return {
					name: this.options.defaultSizeTitles.Current,
					className: "CurrentSize",
				}
			}
			if (sizeMode === "A4Landscape") {
				return {
					height: this._a4PageSize.height,
					width: this._a4PageSize.width,
					name: this.options.defaultSizeTitles.A4Landscape,
					className: "A4Landscape page",
				}
			}
			if (sizeMode === "A4Portrait") {
				return {
					height: this._a4PageSize.width,
					width: this._a4PageSize.height,
					name: this.options.defaultSizeTitles.A4Portrait,
					className: "A4Portrait page",
				}
			}
			return sizeMode
		}, this)

		const container = L.DomUtil.create(
			"div",
			"leaflet-control-easyPrint leaflet-bar leaflet-control",
		)
		if (!this.options.hidden) {
			this._addCss()

			L.DomEvent.addListener(
				container,
				"mouseover",
				this._togglePageSizeButtons,
				this,
			)
			L.DomEvent.addListener(
				container,
				"mouseout",
				this._togglePageSizeButtons,
				this,
			)

			let btnClass = "leaflet-control-easyPrint-button"
			if (this.options.exportOnly) btnClass = btnClass + "-export"

			this.link = L.DomUtil.create("a", btnClass, container)
			this.link.id = "leafletEasyPrint"
			this.link.title = this.options.title
			this.holder = L.DomUtil.create("ul", "easyPrintHolder", container)

			this.options.sizeModes.forEach(function (sizeMode) {
				const btn = L.DomUtil.create("li", "easyPrintSizeMode", this.holder)
				btn.title = sizeMode.name
				const link = L.DomUtil.create("a", sizeMode.className, btn)
				L.DomEvent.addListener(btn, "click", this.printMap, this)
			}, this)

			L.DomEvent.disableClickPropagation(container)
		}
		return container
	},

	printMap: async function ({ size, filename, onFinished, scaleMultiplicator = 1, onError = (error) => {
		console.error("oops, something went wrong!", error);
		throw error;
	} }) {
		this._map.invalidateSize();

		// wait 300ms for the map to be redrawn
		const that = this;
		if (filename) {
			that.options.filename = filename;
		}
		if (!that.options.exportOnly) {
			that._page = window.open(
				"",
				"_blank",
				"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10, top=10, width=200, height=250, visible=none"
			);
			that._page.document.write(
				that._createSpinner(
					that.options.customWindowTitle,
					that.options.customSpinnerClass,
					that.options.spinnerBgCOlor
				)
			);
		}
		that.originalState = {
			mapWidth: this.mapContainer.style.width,
			widthWasAuto: false,
			widthWasPercentage: false,
			mapHeight: this.mapContainer.style.height,
			zoom: that._map.getBoundsZoom(size.bounds),
			center: that._map.getCenter(),
		};
		that._map.invalidateSize();
		if (that.originalState.mapWidth === "auto") {
			that.originalState.mapWidth = that._map.getSize().x + "px";
			that.originalState.widthWasAuto = true;
		} else if (that.originalState.mapWidth.includes("%")) {
			that.originalState.percentageWidth = that.originalState.mapWidth;
			that.originalState.widthWasPercentage = true;
			that.originalState.mapWidth = that._map.getSize().x + "px";
		}

		that._map.fire("easyPrint-start", { event: event });
		if (!that.options.hidden) {
			that._togglePageSizeButtons({ type: null });
		}
		if (that.options.hideControlContainer) {
			that._toggleControls();
		}
		if (that.options.hideClasses) {
			that._toggleClasses(that.options.hideClasses);
		}

		that.outerContainer = that._createOuterContainer(that.mapContainer);
		if (that.originalState.widthWasAuto) {
			that.outerContainer.style.width = that.originalState.mapWidth;
		}
		try {
			await that._createImagePlaceholder({ size, onFinished, scaleMultiplicator });
		} catch (error) {
			onError(error); // Call the onError handler if an error occurs
		}
	},


	_createImagePlaceholder: async function ({
		size,
		onFinished,
		scaleMultiplicator = 1,
	}) {
		const plugin = this;
		console.log(domtoimage)
		const dataUrl = await domtoimage.toPng(this.mapContainer, {
			width: parseInt(this.originalState.mapWidth.replace("px")),
			height: parseInt(this.originalState.mapHeight.replace("px")),
		});
		plugin.blankDiv = document.createElement("div");
		const blankDiv = plugin.blankDiv;
		plugin.outerContainer.parentNode.insertBefore(
			blankDiv,
			plugin.outerContainer,
		);
		blankDiv.className = "epHolder";
		blankDiv.style.backgroundImage = 'url("' + dataUrl + '")';
		blankDiv.style.position = "absolute";
		blankDiv.style.zIndex = 1011;
		blankDiv.style.display = "initial";
		blankDiv.style.width = plugin.originalState.mapWidth;
		blankDiv.style.height = plugin.originalState.mapHeight;
		plugin._resizeAndPrintMap({ size, onFinished, scaleMultiplicator });
	},

	_resizeAndPrintMap: function ({ size, onFinished, scaleMultiplicator = 1 }) {
		// Resize map
		this.outerContainer.style.opacity = 0
		this.mapContainer.style.width = size.width + "px"
		this.mapContainer.style.height = size.height + "px"
		if (this.mapContainer.style.width > this.mapContainer.style.height) {
			this.orientation = "portrait"
		} else {
			this.orientation = "landscape"
		}
		this._map.invalidateSize(false)

		// Adjust zoom
		this._map.fitBounds(size.bounds, { animate: false })

		// Adjust scale line
		const element = document.querySelector(
			".leaflet-control-scale-line",
		) as HTMLElement
		const unit = element.innerHTML.includes("km") ? "km" : "m"
		const parsedValue = parseInt(element.innerHTML.replace(unit, "").trim())

		// Save default scale line properties for reset them after printing
		const defaultScaleLineProperties = {
			width: element.style.width,
			value: element.innerHTML,
		}

		if (parsedValue && unit) {
			// Editing scale html content and style using scaleMultiplicator before printing
			element.innerHTML = parsedValue * scaleMultiplicator + unit
			if (scaleMultiplicator > 1) {
				element.style.transform = "scale(" + scaleMultiplicator + ")"
				element.style.marginRight = scaleMultiplicator * 2.2 + "rem"
				element.style.marginBottom = scaleMultiplicator * 1 + "rem"
			}
			element.style.fontWeight = "600"
			element.style.borderColor = "black"
		}

		// Print
		this._pausePrint({
			size,
			onFinished,
			defaultScaleLineProperties: defaultScaleLineProperties,
		})
	},

	_pausePrint: function ({ size, onFinished, defaultScaleLineProperties }) {
		const plugin = this
		const loadingTest = setInterval(function () {
			if (!plugin.options.tileLayer?.isLoading()) {
				clearInterval(loadingTest)
				plugin._printOpertion({ size, onFinished, defaultScaleLineProperties })
			}
		}, plugin.options.tileWait)
	},

	_printOpertion: function ({ size, onFinished, defaultScaleLineProperties }) {
		const plugin = this
		let widthForExport = this.mapContainer.style.width
		if (
			(this.originalState?.widthWasAuto && size === "CurrentSize") ||
			(this.originalState?.widthWasPercentage && size === "CurrentSize")
		) {
			widthForExport = this.originalState.mapWidth
		}
		domtoimage
			.toPng(plugin.mapContainer, {
				width: parseInt(widthForExport),
				height: parseInt(plugin.mapContainer.style.height.replace("px")),
			})
			.then((dataUrl) => {
				const blob = plugin._dataURItoBlob(dataUrl)
				if (plugin.options.exportOnly) {
					if (!plugin.options.skipSaving)
						fileSaver.saveAs(blob, plugin.options.filename + ".png")
				} else {
					plugin._sendToBrowserPrint(dataUrl, plugin.orientation)
				}
				plugin._toggleControls(true)
				plugin._toggleClasses(plugin.options.hideClasses, true)

				if (plugin.outerContainer) {
					if (plugin.originalState.widthWasAuto) {
						plugin.mapContainer.style.width = "auto"
					} else if (plugin.originalState.widthWasPercentage) {
						plugin.mapContainer.style.width =
							plugin.originalState.percentageWidth
					} else {
						plugin.mapContainer.style.width = plugin.originalState.mapWidth
					}
					plugin.mapContainer.style.height = plugin.originalState.mapHeight
					plugin._removeOuterContainer(
						plugin.mapContainer,
						plugin.outerContainer,
						plugin.blankDiv,
					)
					plugin._map.invalidateSize()
					plugin._map.fitBounds(size.bounds, { animate: false })
				}
				plugin._map.fire("easyPrint-finished")
				if (onFinished) {
					onFinished({ blob: dataUrl })
				}
			})
			.catch((error) => {
				plugin._toggleControls(true)
				plugin._toggleClasses(plugin.options.hideClasses, true)

				if (plugin.outerContainer) {
					if (plugin.originalState.widthWasAuto) {
						plugin.mapContainer.style.width = "auto"
					} else if (plugin.originalState.widthWasPercentage) {
						plugin.mapContainer.style.width =
							plugin.originalState.percentageWidth
					} else {
						plugin.mapContainer.style.width = plugin.originalState.mapWidth
					}
					plugin.mapContainer.style.height = plugin.originalState.mapHeight
					plugin._removeOuterContainer(
						plugin.mapContainer,
						plugin.outerContainer,
						plugin.blankDiv,
					)
					plugin._map.invalidateSize()
					plugin._map.fitBounds(size.bounds)
				}
				plugin._map.fire("easyPrint-finished")
				if (onFinished) {
					onFinished({ blob: undefined, error: error })
				}
			})
			.finally(() => {
				// Reset scale line properties
				const element = document.querySelector(
					".leaflet-control-scale-line",
				) as HTMLElement
				element.removeAttribute("style")
				element.style.width = defaultScaleLineProperties.width
				element.innerHTML = defaultScaleLineProperties.value
			})
	},

	_sendToBrowserPrint: function (img, orientation) {
		this._page.resizeTo(600, 800)
		const pageContent = this._createNewWindow(img, orientation, this)
		this._page.document.body.innerHTML = ""
		this._page.document.write(pageContent)
		this._page.document.close()
	},

	_createSpinner: function (title, spinnerClass, spinnerColor) {
		return (
			`<html><head><title>` +
			title +
			`</title></head><body><style>
      body{
        background: ` +
			spinnerColor +
			`;
      }
      .epLoader,
      .epLoader:before,
      .epLoader:after {
        border-radius: 50%;
      }
      .epLoader {
        color: #ffffff;
        font-size: 11px;
        text-indent: -99999em;
        margin: 55px auto;
        position: relative;
        width: 10em;
        height: 10em;
        box-shadow: inset 0 0 0 1em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
      .epLoader:before,
      .epLoader:after {
        position: absolute;
        content: '';
      }
      .epLoader:before {
        width: 5.2em;
        height: 10.2em;
        background: #0dc5c1;
        border-radius: 10.2em 0 0 10.2em;
        top: -0.1em;
        left: -0.1em;
        -webkit-transform-origin: 5.2em 5.1em;
        transform-origin: 5.2em 5.1em;
        -webkit-animation: load2 2s infinite ease 1.5s;
        animation: load2 2s infinite ease 1.5s;
      }
      .epLoader:after {
        width: 5.2em;
        height: 10.2em;
        background: #0dc5c1;
        border-radius: 0 10.2em 10.2em 0;
        top: -0.1em;
        left: 5.1em;
        -webkit-transform-origin: 0px 5.1em;
        transform-origin: 0px 5.1em;
        -webkit-animation: load2 2s infinite ease;
        animation: load2 2s infinite ease;
      }
      @-webkit-keyframes load2 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes load2 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      </style>
    <div class="` +
			spinnerClass +
			`">Loading...</div></body></html>`
		)
	},

	_createNewWindow: function (img, orientation, plugin) {
		return (
			`<html><head>
        <style>@media print {
          img { max-width: 98%!important; max-height: 98%!important; }
          @page { size: ` +
			orientation +
			`;}}
        </style>
        <script>function step1(){
        setTimeout('step2()', 10);}
        function step2(){window.print();window.close()}
        </script></head><body onload='step1()'>
        <img src="` +
			img +
			`" style="display:block; margin:0!important;"></body></html>`
		)
	},

	_createOuterContainer: function (mapDiv) {
		const outerContainer = document.createElement("div")
		mapDiv.parentNode.insertBefore(outerContainer, mapDiv)
		mapDiv.parentNode.removeChild(mapDiv)
		outerContainer.appendChild(mapDiv)
		outerContainer.style.width = mapDiv.style.width
		outerContainer.style.height = mapDiv.style.height
		outerContainer.style.display = "inline-block"
		outerContainer.style.overflow = "hidden"
		return outerContainer
	},

	_removeOuterContainer: function (mapDiv, outerContainer, blankDiv) {
		if (outerContainer.parentNode) {
			outerContainer.parentNode.insertBefore(mapDiv, outerContainer)
			outerContainer.parentNode.removeChild(blankDiv)
			outerContainer.parentNode.removeChild(outerContainer)
		}
	},

	_addCss: function () {
		const css = document.createElement("style")
		css.type = "text/css"
		css.innerHTML = `.leaflet-control-easyPrint-button { 
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMTI4LDMyaDI1NnY2NEgxMjhWMzJ6IE00ODAsMTI4SDMyYy0xNy42LDAtMzIsMTQuNC0zMiwzMnYxNjBjMCwxNy42LDE0LjM5OCwzMiwzMiwzMmg5NnYxMjhoMjU2VjM1Mmg5NiAgIGMxNy42LDAsMzItMTQuNCwzMi0zMlYxNjBDNTEyLDE0Mi40LDQ5Ny42LDEyOCw0ODAsMTI4eiBNMzUyLDQ0OEgxNjBWMjg4aDE5MlY0NDh6IE00ODcuMTk5LDE3NmMwLDEyLjgxMy0xMC4zODcsMjMuMi0yMy4xOTcsMjMuMiAgIGMtMTIuODEyLDAtMjMuMjAxLTEwLjM4Ny0yMy4yMDEtMjMuMnMxMC4zODktMjMuMiwyMy4xOTktMjMuMkM0NzYuODE0LDE1Mi44LDQ4Ny4xOTksMTYzLjE4Nyw0ODcuMTk5LDE3NnoiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
      background-size: 16px 16px; 
      cursor: pointer; 
    }
    .leaflet-control-easyPrint-button-export { 
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQzMy41IDQzMy41IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MzMuNSA0MzMuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJmaWxlLWRvd25sb2FkIj4KCQk8cGF0aCBkPSJNMzk1LjI1LDE1M2gtMTAyVjBoLTE1M3YxNTNoLTEwMmwxNzguNSwxNzguNUwzOTUuMjUsMTUzeiBNMzguMjUsMzgyLjV2NTFoMzU3di01MUgzOC4yNXoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
      background-size: 16px 16px; 
      cursor: pointer; 
    }
    .easyPrintHolder a {
      background-size: 16px 16px;
      cursor: pointer;
    }
    .easyPrintHolder .CurrentSize{
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTZweCIgdmVyc2lvbj0iMS4xIiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiPgogIDxnPgogICAgPGcgZmlsbD0iIzFEMUQxQiI+CiAgICAgIDxwYXRoIGQ9Ik0yNS4yNTUsMzUuOTA1TDQuMDE2LDU3LjE0NVY0Ni41OWMwLTEuMTA4LTAuODk3LTIuMDA4LTIuMDA4LTIuMDA4QzAuODk4LDQ0LjU4MiwwLDQ1LjQ4MSwwLDQ2LjU5djE1LjQwMiAgICBjMCwwLjI2MSwwLjA1MywwLjUyMSwwLjE1NSwwLjc2N2MwLjIwMywwLjQ5MiwwLjU5NCwwLjg4MiwxLjA4NiwxLjA4N0MxLjQ4Niw2My45NDcsMS43NDcsNjQsMi4wMDgsNjRoMTUuNDAzICAgIGMxLjEwOSwwLDIuMDA4LTAuODk4LDIuMDA4LTIuMDA4cy0wLjg5OC0yLjAwOC0yLjAwOC0yLjAwOEg2Ljg1NWwyMS4yMzgtMjEuMjRjMC43ODQtMC43ODQsMC43ODQtMi4wNTUsMC0yLjgzOSAgICBTMjYuMDM5LDM1LjEyMSwyNS4yNTUsMzUuOTA1eiIgZmlsbD0iIzAwMDAwMCIvPgogICAgICA8cGF0aCBkPSJtNjMuODQ1LDEuMjQxYy0wLjIwMy0wLjQ5MS0wLjU5NC0wLjg4Mi0xLjA4Ni0xLjA4Ny0wLjI0NS0wLjEwMS0wLjUwNi0wLjE1NC0wLjc2Ny0wLjE1NGgtMTUuNDAzYy0xLjEwOSwwLTIuMDA4LDAuODk4LTIuMDA4LDIuMDA4czAuODk4LDIuMDA4IDIuMDA4LDIuMDA4aDEwLjU1NmwtMjEuMjM4LDIxLjI0Yy0wLjc4NCwwLjc4NC0wLjc4NCwyLjA1NSAwLDIuODM5IDAuMzkyLDAuMzkyIDAuOTA2LDAuNTg5IDEuNDIsMC41ODlzMS4wMjctMC4xOTcgMS40MTktMC41ODlsMjEuMjM4LTIxLjI0djEwLjU1NWMwLDEuMTA4IDAuODk3LDIuMDA4IDIuMDA4LDIuMDA4IDEuMTA5LDAgMi4wMDgtMC44OTkgMi4wMDgtMi4wMDh2LTE1LjQwMmMwLTAuMjYxLTAuMDUzLTAuNTIyLTAuMTU1LTAuNzY3eiIgZmlsbD0iIzAwMDAwMCIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==)
    }
    .easyPrintHolder .page {
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ0NC44MzMgNDQ0LjgzMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgzMyA0NDQuODMzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNTUuMjUsNDQ0LjgzM2gzMzQuMzMzYzkuMzUsMCwxNy03LjY1LDE3LTE3VjEzOS4xMTdjMC00LjgxNy0xLjk4My05LjM1LTUuMzgzLTEyLjQ2N0wyNjkuNzMzLDQuNTMzICAgIEMyNjYuNjE3LDEuNywyNjIuMzY3LDAsMjU4LjExNywwSDU1LjI1Yy05LjM1LDAtMTcsNy42NS0xNywxN3Y0MTAuODMzQzM4LjI1LDQzNy4xODMsNDUuOSw0NDQuODMzLDU1LjI1LDQ0NC44MzN6ICAgICBNMzcyLjU4MywxNDYuNDgzdjAuODVIMjU2LjQxN3YtMTA4LjhMMzcyLjU4MywxNDYuNDgzeiBNNzIuMjUsMzRoMTUwLjE2N3YxMzAuMzMzYzAsOS4zNSw3LjY1LDE3LDE3LDE3aDEzMy4xNjd2MjI5LjVINzIuMjVWMzR6ICAgICIgZmlsbD0iIzAwMDAwMCIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
    }
    .easyPrintHolder .A4Landscape { 
      transform: rotate(-90deg);
    }
    .leaflet-control-easyPrint-button{
      display: inline-block;
    }
    .easyPrintHolder{
      margin-top:-31px;
      margin-bottom: -5px;
      margin-left: 30px;
      padding-left: 0px;
      display: none;
    }
    .easyPrintSizeMode {
      display: inline-block;
    }
    .easyPrintHolder .easyPrintSizeMode a {
      border-radius: 0px;
    }
    .easyPrintHolder .easyPrintSizeMode:last-child a{
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      margin-left: -1px;
    }
    .easyPrintPortrait:hover, .easyPrintLandscape:hover{
      background-color: #757570;
      cursor: pointer;
    }`
		document.body.appendChild(css)
	},

	_dataURItoBlob: function (dataURI) {
		const byteString = atob(dataURI.split(",")[1])
		const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
		const ab = new ArrayBuffer(byteString.length)
		const dw = new DataView(ab)
		for (let i = 0; i < byteString.length; i++) {
			dw.setUint8(i, byteString.charCodeAt(i))
		}
		return new Blob([ab], { type: mimeString })
	},

	_togglePageSizeButtons: function (e) {
		const holderStyle = this.holder.style
		const linkStyle = this.link.style
		if (e.type === "mouseover") {
			holderStyle.display = "block"
			linkStyle.borderTopRightRadius = "0"
			linkStyle.borderBottomRightRadius = "0"
		} else {
			holderStyle.display = "none"
			linkStyle.borderTopRightRadius = "2px"
			linkStyle.borderBottomRightRadius = "2px"
		}
	},

	_toggleControls: function (show) {
		const controlContainer = document.getElementsByClassName(
			"leaflet-control-container",
		)[0]
		// @ts-ignore
		if (show) return (controlContainer.style.display = "block")
		// @ts-ignore
		controlContainer.style.display = "none"
	},
	_toggleClasses: function (classes, show) {
		classes.forEach(function (className) {
			const div = document.getElementsByClassName(className)[0]
			// @ts-ignore
			if (show) {
				if (div === undefined) return
				if (className.includes("SuperControl-sc-scj8iu-0")) {
					// @ts-ignore
					div.style.display = "block"
				} else {
					// @ts-ignore
					div.style.display = "flex"
				}
				return
			}
			if (div === undefined) return
			// @ts-ignore
			div.style.display = "none"
		})
	},

	_a4PageSize: {
		height: 715,
		width: 1045,
	},
})
// @ts-ignore
L.easyPrint = function (options) {
	// @ts-ignore
	return new L.Control.EasyPrint(options)
}
const easyPrint = function (options) {
	// @ts-ignore
	return new L.Control.EasyPrint(options)
}
export default easyPrint
