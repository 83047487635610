import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import IMappingTool from "../IMappingTool"

export const MAPPING_TOOL_KEY = "key"

const key: IMappingTool<never, "field", never> = {
	id: MAPPING_TOOL_KEY,
	name: "Texte (comme identifiant externe)",
	config: {},
	singleInputs: { field: "Champs*" },
	arrayInputs: {},
	outputs: [JsonSchemaPropertiesType.TEXT],
	builder: () => ({ singleInputs: { field } }) => {
		if (!field)
			return Promise.reject({
				cause: "key",
				message:
					"Ce champs est utilisé pour la mise en relation des données, il ne peut pas être vide.",
				forceRequired: true,
			})
		return Promise.resolve(field)
	},
}

export default key
