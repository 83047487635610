/* Problème : https://github.com/react-hook-form/react-hook-form/discussions/3830 */
/* Le problème vient de la fonction stringToPath (react-hook-form/src/utils/stringToPath.ts)
qui est utilisée dans la fonction set (react-hook-form/src/utils/set.ts)
qui par ailleur est réutilisée par yupResolver */

export const withoutQuote = (s: string) => s.replace(/'/g, "")

export const restoreQuotes = (schema, toQuoteFix) => {
	toQuoteFix.forEach((name) => {
		schema = schema.from(withoutQuote(name), name)
	})
	return schema
}

export const removeQuotes = (schema, toQuoteFix = undefined) => {
	let toQuoteFixUsed = toQuoteFix
	if (!toQuoteFix) {
		toQuoteFixUsed = schema.properties
			.filter((field) => field.name.includes("'"))
			.map((field) => field.name)
	}
	toQuoteFixUsed.forEach((name) => {
		schema = schema.from(name, withoutQuote(name))
	})
	return schema
}
