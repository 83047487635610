import { openDB } from "idb"

const dbPromise = openDB("keyval-store", 4, {
	upgrade(db, oldVersion, newVersion, transaction) {
		console.log("db=", db, "oldVersion=", oldVersion, "newVersion=", newVersion)
		try {
			db.createObjectStore("multiPolygon", {})
		} catch (error) {
			console.error(error.message)
		}
	},
})

const get = async (key) => {
	return (await dbPromise).get("multiPolygon", key)
}
const set = async (key, value) => {
	return (await dbPromise).put("multiPolygon", value, key)
}
const _delete = async (key) => {
	return (await dbPromise).delete("multiPolygon", key)
}

const clear = async () => {
	return (await dbPromise).clear("multiPolygon")
}

const keys = async () => {
	return (await dbPromise).getAllKeys("multiPolygon")
}

// @ts-ignore
window.DatabaseService = {
	get,
	set,
	delete: _delete,
	clear,
	keys,
	deleteDB: () => clear(), //() => deleteDB('keyval-store'),
}

export default {
	get,
	set,
	delete: _delete,
	clear,
	keys,
}
