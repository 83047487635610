import { Chip } from "@mui/material"
import StoreIcon from "@mui/icons-material/Store"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { useDispatch } from "react-redux"

function StockplaceChip({
	stockplace,
	editable,
	added = false,
	deleted = false,
}) {
	const dispatch = useDispatch()
	if (!stockplace) {
		return null
	}
	return (
		<Chip
			color={added ? "success" : deleted ? "error" : undefined}
			disabled={deleted}
			onClick={
				editable ? () => dispatch(setCurrentPoint(stockplace)) : undefined
			}
			variant="outlined"
			icon={<StoreIcon />}
			label={stockplace.geojson.properties["Lieu"]}
		/>
	)
}

export default StockplaceChip
