import styled from "styled-components"

export const Content = styled.div`
	font-size: 1.2rem;
	font-weight: 425;
	line-height: 1.43;
    letter-spacing: 0.01071em;
    display: block;
`
export const SubContent = styled.div`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.6);
    display: block;
`
