import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { Slider, TextField } from "@mui/material"
import useMapState from "@/hooks/useMapState"
import { FormProvider, useForm } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { addMultipolygon } from "@/redux-toolkit/data/multiPolygon/reducer"
import useWhyDidYouUpdate from "@/hooks/useWhyDidYouUpdate"
import { toggleVisibilityMultiPolygons } from "@/redux-toolkit/carto/reducer"
import FColor2 from "utils/form/color/FColor2"
import FormatColorFill from "@mui/icons-material/FormatColorFill"
import ButtonPopover from "@/pages/maincourante/subComponents/ButtonPopover"
import { ChromePicker } from "react-color"
import { SectionTitle } from "@/pages/maincourante/care/CareResumeCard"
import BorderColor from "@mui/icons-material/BorderColor"
import LineWeight from "@mui/icons-material/LineWeight"
import { useDebounce } from "react-use"
import FText from "utils/form/FText"
import FPolygonColor from "utils/form/carto/FPolygonColor"
// app:javascript:utils:panels:panelCarto:EditStyle.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:EditStyle.tsx")
debug.log = console.log.bind(console)

const Container = styled(Flex)`
    // left border
    width: 100%;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: var(--neutral200);
        z-index: 1;
    }
`

const SquareColor = styled.div<{ color: string }>`
    width: 50px;
    height: 20px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    padding: 0.25rem;
    cursor: pointer !important;
    margin: 0.25rem;
    border: 1px solid ${(props) => props.color};
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    &:hover {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }
`
const SBorderColor = styled(BorderColor)`
    background-color: white;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
`
const SFormatColorFill = styled(FormatColorFill)`
    background-color: white;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
`
const SLineWeight = styled(LineWeight)`
    background-color: white;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
`

const EditStylePolygon = ({ entityId }: { entityId?: string }) => {
	const { toggleVisibilityMultiPolygons } = useMapState()
	const dispatch = useDispatch()
	const multiPolygons = useMultiPolygons()
	const initialValues = {
		color: multiPolygons[entityId].custom_props?.style?.color,
		stroke: true,
		fill: true,
		fillColor: multiPolygons[entityId].custom_props?.style?.fillColor,
		fillOpacity: multiPolygons[entityId].custom_props?.style?.fillOpacity,
		opacity: multiPolygons[entityId].custom_props?.style?.opacity,
		weight: multiPolygons[entityId].custom_props?.style?.weight,
	}
	const methods = useForm({
		defaultValues: initialValues,
		mode: "onBlur",
	})
	const values = methods.watch()
	useEffect(() => {
		if (_.isEqual(values, initialValues)) return
		methods.reset(initialValues)
	}, [entityId, ...Object.values(initialValues)])

	useDebounce(
		() => {
			if (!entityId) return
			if (!multiPolygons[entityId]) return
			if (_.isEqual(values, initialValues)) return
			dispatch(toggleVisibilityMultiPolygons(entityId))
			dispatch(
				addMultipolygon({
					...multiPolygons[entityId],
					custom_props: {
						...multiPolygons[entityId].custom_props,
						style: {
							...values,
							stroke: true,
							fill: true,
						},
					},
				}),
			)
			setTimeout(() => {
				dispatch(toggleVisibilityMultiPolygons(entityId))
			}, 100)
		},
		300,
		[entityId, ...Object.values(values)],
	)

	return (
		<FormProvider {...methods}>
			<FPolygonColor />
		</FormProvider>
	)
}

export default EditStylePolygon
