import React from "react"
import { Page as PagePDF } from "@react-pdf/renderer"
const Page = PagePDF as any

const PPage = ({ children, noPadding = false, ...props }) => {
	return (
		<Page
			size="A4"
			renderTextLayer={false}
			renderAnnotationLayer={false}
			style={{
				...props.style,
				padding: noPadding ? 0 : 30,
				height: "100vh",
			}}
			{...props}
		>
			{children}
		</Page>
	)
}

export default PPage
