import React, { useState } from "react"
import { Button } from "@mui/material"
import Debug from "debug"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import StyledPaper from "./StyledButton"
import BuildIcon from "@mui/icons-material/Build"
import RequestModal from "@/pages/carto2/cartographie/RequestModal/RequestModal"

export const DAYBOOK_CARTOGRAPHIE = "daybook_cartographie"

const StyledButton = styled(Button)`
    padding: 0 !important;
`
// app:javascript:components:maincourante:subComponents:bottomCenterControl:RequestControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:RequestControl",
)
debug.log = console.log.bind(console)

const RequestControl = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const toggleRequestModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<StyledButton onClick={() => toggleRequestModal()}>
				<StyledPaper>
					<Flex directionColumn>
						<BuildIcon />
						<div>Requêtes</div>
					</Flex>
				</StyledPaper>
			</StyledButton>
			<RequestModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
		</>
	)
}

export default RequestControl
