import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { ClickAwayListener, IconButton } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import styled from "styled-components"

// <MenuItem onClick={handleClose}>Logout</MenuItem>
const SIconButton = styled(IconButton)`
    width: fit-content;
    padding: 0.5rem;
`
const MoreMenu = ({
	options,
	shouldClose = false,
	setShouldClose = (arg) => {},
	callback = (arg) => {},
}: any) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
		callback()
	}
	useEffect(() => {
		if (shouldClose) {
			setAnchorEl(null)
			setShouldClose(false)
		}
	}, [shouldClose])

	const handleClose = () => {
		setAnchorEl(null)
	}
	return (
		<ClickAwayListener onClickAway={handleClose}>
			<>
				<SIconButton onClick={handleClick}>
					<MoreVertIcon fontSize="small" />
				</SIconButton>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{options}
				</Menu>
			</>
		</ClickAwayListener>
	)
}
export default MoreMenu
