import { setServerDatetime } from "@/redux-toolkit/time/reducer"
import { POINTS, PUBLIC_POINTS } from "@/redux-toolkit/time/constants"
import SynchroPointService from "@/services/synchro/SynchroPointService"
import ErrorService from "@/services/ErrorService"
import {
	addPoint,
	addPoints,
	createLocalPoint,
	deleteLocalPoint,
	deleteLocalPoints,
	removePoint,
	removePoints,
	replacePoint,
	updateLocalPoint,
} from "./reducer"
import PointApi from "./PointsApi"
import { setOffLine } from "@/redux-toolkit/common/reducer"
import { createAsyncThunk } from "@reduxjs/toolkit"
import _ from "lodash"
import IEvent from "utils/types/IEvent"
import IPoint from "utils/types/IPoint"
import { updateLocalEvent } from "../events/reducer"
import SynchroEventService from "@/services/synchro/SynchroEventService"
import { toast } from "react-toastify"

// ! START LOCAL

export const createPoint = createAsyncThunk(
	"data/points/createPoint",
	async (point: any, { dispatch, getState }) => {
		const commune_id = (getState() as any).commune.id
		dispatch(createLocalPoint({ ...point, commune_id }))
		await SynchroPointService.synchronize(dispatch, getState)
	},
)

export const createPoints = createAsyncThunk(
	"data/points/createPoints",
	async (points: Partial<IPoint>[], { dispatch, getState }) => {
		points.forEach((point) => {
			dispatch(createLocalPoint(point))
		})
		await SynchroPointService.synchronize(dispatch, getState)
	},
)

export const createPointWithUpdateEvent = createAsyncThunk(
	"data/points/createPointWithUpdateEvent",
	async (
		{ point, event }: { point: Partial<IPoint>; event: IEvent },
		{ dispatch, getState },
	) => {
		const commune_id = (getState() as any).commune.id
		dispatch(createLocalPoint({ ...point, commune_id }))
		await SynchroPointService.synchronize(dispatch, getState, true)
		dispatch(updateLocalEvent(event))
		await SynchroEventService.synchronize(dispatch, getState, true)
	},
)

export const updatePoint = createAsyncThunk(
	"data/points/updatePoint",
	async (point: Partial<IPoint>, { dispatch, getState }) => {
		const commune_id = (getState() as any).commune.id
		dispatch(updateLocalPoint({ ...point, commune_id }))
		await SynchroPointService.synchronize(dispatch, getState)
	},
)
export const updatePointWithUpdateEvent = createAsyncThunk(
	"data/points/updatePointWithUpdateEvent",
	async (
		{ point, event }: { point: Partial<IPoint>; event: IEvent },
		{ dispatch, getState },
	) => {
		const commune_id = (getState() as any).commune.id
		dispatch(updateLocalPoint({ ...point, commune_id }))
		await SynchroPointService.synchronize(dispatch, getState)
		dispatch(updateLocalEvent(event))
		await SynchroEventService.synchronize(dispatch, getState)
	},
)
// send point, no point.id
export const deletePoint = createAsyncThunk(
	"data/points/deletePoint",
	async (point: any, { dispatch, getState }) => {
		dispatch(deleteLocalPoint(point))
		await SynchroPointService.synchronize(dispatch, getState)
	},
)

export const deletePointWithUpdateEvent = createAsyncThunk(
	"data/points/deletePointWithUpdateEvent",
	async (
		{ point, event }: { point: Partial<IPoint>; event: IEvent },
		{ dispatch, getState },
	) => {
		dispatch(deleteLocalPoint(point))
		await SynchroPointService.synchronize(dispatch, getState)
		dispatch(updateLocalEvent(event))
		await SynchroEventService.synchronize(dispatch, getState)
	},
)

export const deleteAllPoints = createAsyncThunk(
	"data/points/deleteAllPoints",
	async (points: any, { dispatch, getState }) => {
		dispatch(deleteLocalPoints(points))
		await SynchroPointService.synchronize(dispatch, getState)
	},
)

// ! END LOCAL

// ! START SYNCHRONIZATION

export const getPoints = async (dispatch, getState) => {
		return await PointApi.getPoints(
			dispatch,
			getState,
		)
}

export const synchronizeCreatedPoint = async (dispatch, point) => {
	const { localStatus, localModifications, ...toCreatePoint } = point
	try {
		const createdPoint = await PointApi.createPoint(toCreatePoint)
		dispatch(replacePoint(createdPoint))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la création de points, message d'erreur : ${error.message}`
		if (errorMessage.includes("Network Error")) {
			dispatch(setOffLine())
			return
		}
		ErrorService.error({
			component: "resources:synchronizeCreatedPoint",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeUpdatedPoint = async (dispatch, point) => {
	const { localStatus, localModifications, ...toUpdatePoint } = point
	try {
		const updatedPoint = await PointApi.updatePoint(toUpdatePoint)
		dispatch(replacePoint(updatedPoint))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la modification de points, message d'erreur : ${error.message}`
		if (errorMessage.includes("Network Error")) {
			dispatch(setOffLine())
			return
		}
		ErrorService.error({
			component: "resources:synchronizeUpdatedPoint",
			message: errorMessage,
			dispatch,
		})
	}
}

export const synchronizeDeletedPoint = async (dispatch, point) => {
	try {
		const deletedPoint = await PointApi.deletePoint(point.id)
		dispatch(removePoint(deletedPoint))
	} catch (error) {
		const errorMessage = `Erreur lors de la synchronisation de la suppression de points, message d'erreur : ${error.message}'`
		if (errorMessage.includes("Network Error")) {
			dispatch(setOffLine())
			return
		}
		ErrorService.error({
			component: "resources:synchronizeDeletedPoint",
			message: errorMessage,
			dispatch,
		})
	}
	return
}

export const getPointsWithDeleted = async (dispatch, getState) => {
	try {
		const { points, serverDatetime } = await PointApi.getPointsWithDeleted(
			getState().time[POINTS],
		)
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: POINTS,
			}),
		)

		const grouped = _.groupBy(points, (point) =>
			point.deleted ? "toDelete" : "toAdd",
		)

		if (grouped.toAdd) dispatch(addPoints(grouped.toAdd))
		if (grouped.toDelete)
			dispatch(removePoints(grouped.toDelete.map((item) => item.id)))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des points, message : ${error.message}`
		if (errorMessage.includes("Network Error")) {
			dispatch(setOffLine())
			return
		}
		ErrorService.error({
			component: "resources:getPointsWithDeleted",
			message: errorMessage,
			dispatch,
		})
	}
	return
}

// ! END SYNCHRONIZATION

export const getPublicPoints = async (dispatch, state) => {
	try {
		const { points, serverDatetime } = await PointApi.getPublicPoints(
			state.time[PUBLIC_POINTS],
		)
		dispatch(
			setServerDatetime({
				datetime: serverDatetime,
				entity: PUBLIC_POINTS,
			}),
		)
		points.forEach((point) => {
			if (point.deleted) {
				dispatch(removePoint(point.id))
				return
			}
			dispatch(addPoint(point))
		})
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des points public, message : ${error.message}`
		if (errorMessage.includes("Network Error")) {
			dispatch(setOffLine())
			return
		}
		ErrorService.error({
			component: "resources:getPublicPoints",
			message: errorMessage,
			dispatch,
		})
	}
}

export const getPointsPicrim = createAsyncThunk(
	"picrim/getPoints",
	async (communeId: string, { dispatch }) => {
		try {
			const { points } = await PointApi.getPointsPicrim(communeId)
			dispatch(addPoints(points))
		} catch (error) {
			console.error(error)
			throw new Error(
				`Erreur lors de la récupération des points, message d'erreur : ${error.message}`,
			)
		}
	},
)
