import ArrowBack from "@mui/icons-material/ArrowBack"
import SearchIcon from "@mui/icons-material/Search"
import { InputAdornment, TextField } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { JSON_SCHEMAS, MULTI_POLYGONS } from "@/redux-toolkit/time/constants"
import Debug from "debug"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import useNavigationTree from "@/utils/components/categoryNavigation/useNavigationTree"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import { DAYBOOK_LEGEND } from "../PanelCarto"
import NestedJsonSchemaDaybookCard from "./NestedJsonSchemaDaybookCard"
import NestedListParentDaybook from "./NestedListParentDaybook"
import Button from "@/styles/atoms/Button"
// app:javascript:utils:panels:panelCarto:PanelDaybookData.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:PanelDaybookData")
debug.log = console.log.bind(console)

const Container = styled.div`
    max-height: calc(100vh - 48px);
    overflow-x: hidden;
    overflow-y: auto;
`

const StyledTextField = styled(TextField)`
    width: 100% !important;
`
const TopContainer = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const PanelDaybookData = ({ noBackButton = false, noListIcon = false }) => {
	const { tree, flat } = useNavigationTree({ includeGeolocalisable: false })
	const [searchValue, setSearchValue] = useState("")
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const dispatch = useDispatch()

	const filteredJsonSchemaAndMultiPolygons = useMemo(() => {
		if (!searchValue) return
		const filteredJsonSchemas = (
			Object.values(jsonSchemasWithStatics).filter(({ title }) =>
				formatNfdLowerCase(title).includes(formatNfdLowerCase(searchValue)),
			) ?? []
		).map(({ id, title }) => ({ id, title, type: JSON_SCHEMAS }))
		const filteredMultiPolygons = (
			Object.values(multiPolygons).filter(({ name }) =>
				formatNfdLowerCase(name).includes(formatNfdLowerCase(searchValue)),
			) ?? []
		).map((polygon: any) => ({
			id: polygon.id,
			type: MULTI_POLYGONS,
			title: polygon.name,
		}))
		return [...filteredJsonSchemas, ...filteredMultiPolygons]
	}, [searchValue, jsonSchemasWithStatics, multiPolygons])

	return (
		<Container>
			<TopContainer>
				{!noBackButton && (
					<Button
						color="primary"
						variant="bordered"
						onClick={() => dispatch(setPanel({ type: DAYBOOK_LEGEND }))}
						startContent={<ArrowBack />}
					>
						Retour à la légende
					</Button>
				)}
				<StyledTextField
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="Rechercher une donnée"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</TopContainer>
			{!searchValue &&
				tree.map((item) => (
					<NestedListParentDaybook
						key={item.title}
						item={item}
						noListIcon={noListIcon}
					/>
				))}
			{searchValue && (
				<>
					{filteredJsonSchemaAndMultiPolygons.map((item) => (
						<NestedJsonSchemaDaybookCard
							key={item.id}
							itemJsonSchema={item}
							color="neutral"
							noListIcon={noListIcon}
						/>
					))}
				</>
			)}
		</Container>
	)
}

export default PanelDaybookData
