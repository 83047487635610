import { Alert, DialogActions, DialogContent } from "@mui/material"
import useUploadedCartos from "@/hooks/useUploadedCartos"
import FormStep from "@/pages/telechargement/Upload/component/FormStep"
import SelectAfterPCS from "@/pages/telechargement/Upload/component/SelectAfterPCS"
import useStep from "@/pages/telechargement/Upload/hooks/useStep"
import useUploadLocation from "@/pages/telechargement/Upload/hooks/useUploadLocation"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import styled from "styled-components"
import SDialog from "utils/components/SDialog"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import ParentSubmitButtonWrapper from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitButtonWrapper"
import { ParentSubmitContextProvider } from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitContext"

const SFlex = styled(Flex)`
    width: fit-content;
`

const StepLocation = ({ methods, ...props }) => {
	const { value, setStepValue, onBack, onSkip, shouldLeave, disabled } =
		useStep("location", { dependencies: ["category"] })
	const [modalOpen, setModalOpen] = useState(false)
	const [fileIdsToDisabled, setFileIdsToDisabled] = useState([])
	const { getUploadLocation } = useUploadLocation()
	const uploadedCartos = useUploadedCartos()

	methods.watch("idToReplace")
	const selectionIdToReplace = methods.getValues("idToReplace")
	const uploadedCarto = uploadedCartos[selectionIdToReplace]
	const currentConfigLocation = uploadedCartos[selectionIdToReplace]?.config

	useEffect(() => {
		if (shouldLeave) setModalOpen(false)
	}, [shouldLeave])

	useEffect(() => {
		if (!uploadedCarto) return
		const tempFileIdsToDisabled = [uploadedCarto.id]
		const findUploadedFileFollowingId = (fileId) => {
			Object.values(uploadedCartos).forEach((uploadedCarto) => {
				const isAfterId = _.cloneDeep(uploadedCarto.config?.pcs?.after) //bypass read-only
				if (isAfterId === fileId) {
					tempFileIdsToDisabled.push(uploadedCarto.id)
					return findUploadedFileFollowingId(uploadedCarto.id)
				}
			})
		}
		findUploadedFileFollowingId(uploadedCarto.id)
		setFileIdsToDisabled(tempFileIdsToDisabled)
	}, [uploadedCarto])

	return (
		<FormStep
			question=""
			formatedValue=""
			disabled={disabled}
			clearStyles
			{...props}
		>
			<Flex directionColumn alignItemsInitial gap=".5em">
				{currentConfigLocation && _.isEmpty(value) && (
					<Alert severity="info">
						Emplacement actuel :{" "}
						{!currentConfigLocation.pcs
							? "Non intégrée au PCS"
							: getUploadLocation(currentConfigLocation)}
					</Alert>
				)}
				<SFlex
					directionColumn={isMobile}
					alignItemsInitial={isMobile}
					gap
					$wrap
				>
					{!_.isEmpty(selectionIdToReplace) && (
						<PrimaryButton
							fullWidth={isMobile}
							onClick={() => setStepValue(currentConfigLocation.pcs)}
						>
							Garder l&apos;emplacement actuel
						</PrimaryButton>
					)}
					<PrimaryButton
						fullWidth={isMobile}
						onClick={() => setModalOpen(true)}
					>
						Après un élément du PCS
					</PrimaryButton>
					<PrimaryButton
						fullWidth={isMobile}
						onClick={() => setStepValue({ appendix: true })}
					>
						En annexe
					</PrimaryButton>
					<PrimaryOutlinedButton
						fullWidth={isMobile}
						onClick={() => setStepValue(null)}
					>
						Ne pas intégrer au PCS
					</PrimaryOutlinedButton>
				</SFlex>
			</Flex>
			<ParentSubmitContextProvider onSubmit={setStepValue}>
				<SDialog
					onClose={() => setModalOpen(false)}
					open={modalOpen}
					maxWidth="md"
					fullWidth
				>
					<DialogContent dividers>
						<SelectAfterPCS
							title="Sélectionnez un livret du PCS puis la catégorie ou la fiche qui
                                précédera votre cartographie"
							fileIdsToDisabled={fileIdsToDisabled}
						/>
					</DialogContent>
					<DialogActions>
						<PrimaryTextButton onClick={() => setModalOpen(false)}>
							Annuler
						</PrimaryTextButton>
						<ParentSubmitButtonWrapper>
							{({ onClick }) => (
								<PrimaryButton onClick={onClick}>Valider</PrimaryButton>
							)}
						</ParentSubmitButtonWrapper>
					</DialogActions>
				</SDialog>
			</ParentSubmitContextProvider>
		</FormStep>
	)
}

export default StepLocation
