import { Card } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React from "react"
import PointCardContent from "./PointCardContent"
import PointCardHeader from "./PointCardHeader"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"

const PointCard = ({
	point,
	onClose = undefined,
	showCommune = false,
	Container = Card,
}) => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const jsonSchemasWithShared = { ...jsonSchemas, ...cdcSharedJsonSchemas }
	const jsonSchema = jsonSchemasWithShared[point?.jsonschema_id]

	if (!jsonSchema) return null

	return (
		<Container>
			<PointCardHeader point={point} onClose={onClose} />
			<PointCardContent point={point} showCommune={showCommune} />
		</Container>
	)
}

export default PointCard
