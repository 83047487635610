import useAllStockPlaces from "@/hooks/useAllStockPlaces"
import React from "react"
import StockplaceChip from "utils/components/chip/StockplaceChip"
import { Flex } from "utils/components/style/flex"
import { TypeComponentProps } from "../PointPropertyValue"

function TypeStockplace({
	value,
	align,
	editable,
	diffWith,
}: TypeComponentProps) {
	const stockPlaces = useAllStockPlaces()
	const stockPlace = stockPlaces[value]

	const isDifferent = diffWith !== undefined && diffWith !== value

	return (
		<Flex wrap flexEnd={align === "right"} gap="5px">
			<StockplaceChip
				stockplace={stockPlace}
				editable={editable}
				added={isDifferent}
			/>
			{isDifferent && (
				<StockplaceChip
					stockplace={stockPlaces[diffWith]}
					editable={editable}
					deleted
				/>
			)}
		</Flex>
	)
}

export default TypeStockplace
