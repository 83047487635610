import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import { SketchPicker } from "react-color"
import _ from "lodash"
import ButtonPopover from "../ButtonPopover"
import { IconButton } from "@mui/material"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_CARTOGRAPHY } from "@/redux-toolkit/userSettings/constants"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:maincourante:subComponents:bottomCenterControl:ColorManager.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:ColorManager",
)
debug.log = console.log.bind(console)
const CircleColor = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 1px solid black;
    background-color: ${(props) => props.color};
`
const ColorManager = ({ color, setColor, disabled = false }) => {
	const permissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)
	return (
		<>
			<ButtonPopover
				popoverContent={({ handleClose }) => (
					<SketchPicker
						color={color}
						onChangeComplete={(color) => {
							handleClose()
							return setColor(color["hex"])
						}}
					/>
				)}
			>
				{({ handleClick }) => (
					<IconButton
						onClick={handleClick}
						disabled={disabled || !permissions.write}
					>
						<CircleColor color={color} />
					</IconButton>
				)}
			</ButtonPopover>
		</>
	)
}

export default ColorManager
