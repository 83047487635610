import React from "react"
import FSelect, { FSelectProps } from "./FSelect"
import useTags from "@/hooks/useTags"

const FTags = ({
	tags,
	...props
}: { tags: "stocks" | "vehicules" } & Omit<
	FSelectProps,
	"options" | "isMulti" | "getOptionLabel"
>) => {
	const gettedTags = useTags(tags)
	return <FSelect isMulti options={gettedTags} {...props} />
}

export default FTags
