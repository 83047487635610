import React from "react"
import { Grid } from "@mui/material"
import PanelStyleEditorPoint from "utils/panels/panelCarto/PanelStyleEditorPoint"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook"
import usePoints from "@/hooks/usePoints"
import CartographyPreview from "@/pages/carto2/cartographie/CartographyPreview"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_POINT } from "utils/panels/PanelCarto"
import { useDispatch } from "react-redux"

const JsonSchemaEdition = ({ jsonSchemaId }) => {
	const points = usePoints()
	const dispatch = useDispatch()

	const point = Object.values(points).filter(
		(obj) => obj.jsonschema_id === jsonSchemaId,
	)[0]

	return (
		<Grid container spacing={2}>
			<Grid item md={6} sm={12}>
				<PanelStyleEditorPoint providedJsonSchemaId={jsonSchemaId} />
			</Grid>
			<Grid item md={6} sm={12}>
				<CartographyPreview
					disableDragging
					disableZoom
					mapDimensions={{ height: "400px" }}
				>
					<MarkerDaybook
						key={jsonSchemaId}
						point={point}
						handleClick={() =>
							dispatch(
								setPanel({
									type: DAYBOOK_POINT,
									id: point.id,
								}),
							)
						}
						noInteraction
					/>
				</CartographyPreview>
			</Grid>
		</Grid>
	)
}

export default JsonSchemaEdition
