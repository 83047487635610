import { Text } from "@react-pdf/renderer"
import React from "react"

const PH1 = ({ children, marginBottom = "20px", ...props }) => {
	return (
		<Text
			style={{
				...{ fontSize: 24, color: "#2e74b5", marginBottom },
				...props.style,
			}}
		>
			{children}
		</Text>
	)
}

export default PH1
