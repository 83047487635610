import LocalPoliceIcon from "@mui/icons-material/LocalPolice"
import { Button } from "@mui/material"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import Debug from "debug"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import React from "react"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import StyledPaper from "./StyledButton"
import LayersIcon from "@mui/icons-material/Layers"
import { useDispatch } from "react-redux"
import { toggleChangingCarto } from "@/redux-toolkit/common/reducer"
// app:javascript:components:maincourante:subComponents:bottomCenterControl:EventControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:EventControl",
)
debug.log = console.log.bind(console)
const StyledButton = styled(Button)`
    padding: 0 !important;
`
const LayersControl = () => {
	const dispatch = useDispatch()
	return (
		<>
			<StyledButton
				onClick={() => {
					dispatch(toggleChangingCarto())
				}}
			>
				<StyledPaper>
					<Flex directionColumn>
						<LayersIcon />
						<div>Fond carto</div>
					</Flex>
				</StyledPaper>
			</StyledButton>
		</>
	)
}

export default LayersControl
