import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import PanelStyleEditorPolygon from "utils/panels/panelCarto/PanelStyleEditorPolygon"
import { Pane, GeoJSON } from "react-leaflet"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import DatabaseService from "@/services/DatabaseService"
import getPolygonStyle from "utils/components/map/polygon/getPolygonStyle"
import useMapState from "@/hooks/useMapState"
import CartographyPreview from "@/pages/carto2/cartographie/CartographyPreview"

const MultiPolygonEdition = ({ multiPolygonId }) => {
	const multiPolygons = useMultiPolygons()
	const [multiPolygonsBounds, setMultiPolygonsBounds] = useState(undefined)
	const { coucheMultiPolygons } = useMapState()
	const visible = coucheMultiPolygons[multiPolygonId].visibility

	useEffect(() => {
		const processMultiPolygon = async () => {
			const data = await DatabaseService.get(multiPolygonId)
			setMultiPolygonsBounds(data)
		}
		processMultiPolygon()
	}, [multiPolygonId])

	return (
		<Grid container spacing={2}>
			<Grid item md={6} sm={12}>
				<PanelStyleEditorPolygon providedMultiPolygonId={multiPolygonId} />
			</Grid>
			<Grid item md={6} sm={12}>
				<CartographyPreview
					disableDragging
					disableZoom
					mapDimensions={{ height: "400px" }}
				>
					<Pane
						name={multiPolygonId}
						key={`${multiPolygonId}:500`}
						style={{
							zIndex: 500,
						}}
					>
						{multiPolygonsBounds && visible && (
							<GeoJSON
								key={multiPolygonId}
								data={JSON.parse(multiPolygonsBounds ?? [])}
								style={() => getPolygonStyle(multiPolygons[multiPolygonId])}
							/>
						)}
					</Pane>
				</CartographyPreview>
			</Grid>
		</Grid>
	)
}

export default MultiPolygonEdition
