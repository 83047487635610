import { useSelector } from "react-redux"
import IJsonSchema from "@/utils/types/IJsonSchema"
import { IPermission } from "@/utils/types/IPermission"
import { selectAccessRights } from "./useAccessRights"
import { selectJsonSchemas } from "./useJsonSchemasWithStatics"

const getJsonSchemaPermission = (jsonSchemaId, jsonSchemasAccessRights) => {
	return jsonSchemasAccessRights[IPermission.WHITELIST][jsonSchemaId]?.write
}
const getJsonSchemaPermissionHide = (jsonSchemaId, jsonSchemasAccessRights) => {
	return jsonSchemasAccessRights[IPermission.WHITELIST][jsonSchemaId]?.hide
}

export const buildJsonSchemasWithAccessRights = (
	jsonSchemas,
	jsonSchemasAccessRights,
) => {
	const whitelistedIds = Object.keys(
		jsonSchemasAccessRights?.[IPermission.WHITELIST] ?? [],
	)
	const blacklistedIds = jsonSchemasAccessRights?.[IPermission.BLACKLIST] ?? []

	const allSchemasWithAccessRights = {}
	Object.values(jsonSchemas).forEach((element: any) => {
		if (blacklistedIds.includes(element.id)) {
			return
		}
		if (whitelistedIds.includes(element.id)) {
			allSchemasWithAccessRights[element.id] = {
				...element,
				write: getJsonSchemaPermission(element.id, jsonSchemasAccessRights),
				hide: getJsonSchemaPermissionHide(element.id, jsonSchemasAccessRights),
			}
			return
		}
		if (
			!jsonSchemasAccessRights?.accessAll &&
			!jsonSchemasAccessRights?.readAll
		) {
			return
		}
		allSchemasWithAccessRights[element.id] = {
			...element,
			write: jsonSchemasAccessRights?.accessAll ? true : false,
		}
	})
	return allSchemasWithAccessRights
}

const useJsonSchemas: () => { [key: string]: IJsonSchema } = () => {
	const accessRights = useSelector(selectAccessRights)
	const jsonSchemasAccessRights = accessRights.tools.JSONSCHEMAS
	const { jsonSchemas } = useSelector(selectJsonSchemas)
	return buildJsonSchemasWithAccessRights(jsonSchemas, jsonSchemasAccessRights)
}

export default useJsonSchemas
