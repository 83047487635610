import React from "react"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import { connect } from "react-redux"
import { addCouchePolygon, removeCouche } from "@/redux-toolkit/carto/reducer"
import styled from "styled-components"
import { ListItemButton, CardHeader, Checkbox } from "@mui/material"
const SListItem = styled(ListItemButton)`
    display: flex !important;
    justify-content: space-between !important;
`

const MultiPolygonCard = ({
	multiPolygon,
	removeCouche,
	addCouchePolygon,
	couches,
	multiPolygons,
}) => {
	return (
		<SListItem
			key={multiPolygon.id}
			onClick={() => {
				if (Object.keys(couches).includes(multiPolygon.id)) {
					removeCouche(multiPolygon.id)
					return
				}
				addCouchePolygon(multiPolygon.id)
			}}
		>
			<CardHeader
				avatar={
					<MultiPolygonColorSquare
						multiPolygon={multiPolygons[multiPolygon.id]}
					/>
				}
				title={multiPolygon.title}
			/>
			<Checkbox checked={Object.keys(couches).includes(multiPolygon.id)} />
		</SListItem>
	)
}
const mapStateToProps = (state) => ({
	multiPolygons: state.data.multiPolygons,
	couches: state.carto.couches,
})

const mapDispatchToProps = (dispatch) => ({
	addCouchePolygon: (multiPolygon) => dispatch(addCouchePolygon(multiPolygon)),
	removeCouche: (multiPolygon) => dispatch(removeCouche(multiPolygon)),
})
export default connect(mapStateToProps, mapDispatchToProps)(MultiPolygonCard)
