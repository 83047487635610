import React from "react"
import { connect } from "react-redux"
import CartographyMap from "./cartographyMap/CartographyMap"
import { PATH_CARTOGRAPHY } from "@/redux-toolkit/userSettings/constants"
import useAccessRights from "@/hooks/useAccessRights"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { setMaptoolsActivetool } from "@/redux-toolkit/common/reducer"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

export const CARTOGRAPHIE = "cartographie"

const Cartography = () => {
	const permissions = useAccessRights(EnumToolPath.CD_CARTOGRAPHY)

	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<div style={{ display: "flex" }}>
			<CartographyMap />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		drawGeojson: state.carto.drawGeojson,
		activeMapTool: state.common.activeMapTool,
	}
}

const mapDispatchToProps = (dispatch) => ({
	setMaptoolsActivetool: (activeMapTool) =>
		dispatch(setMaptoolsActivetool(activeMapTool)),
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Cartography) as any
