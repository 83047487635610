import { Checkbox, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import React, { useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import styled, { css } from "styled-components"
import Defer from "utils/Defer"
import DraggablePointTags from "./FExtractSelect/DraggablePointTags"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import useMultiPolygons from "@/hooks/useMultiPolygons"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const StyledLi = styled.li<{
	lowPadding: boolean
}>`
	${({ lowPadding }) =>
		lowPadding &&
		css`
			padding: ${lowPadding ? ".2em" : ".5em"} !important;
		`}
	margin-left: 3px;
`

const ListboxComponent = ({ children, ...props }) => {
	return (
		<div {...props}>
			<Defer chunkSize={10}>{children}</Defer>
		</div>
	)
}
export interface IFExtractSelectOption {
	value: string
	label: string
	imageSrc?: string
	polygonId?: string
}
interface IFExtractSelect {
	options: IFExtractSelectOption[] | string[]
	name: string
	label?: string
	groupBy?: (option: any) => string
	renderGroup?: (option: any) => JSX.Element
	disabled?: boolean
	readOnly?: boolean
	multiple?: boolean
	required?: boolean
}
const FExtractSelect = ({
	options,
	name,
	label = undefined,
	groupBy = undefined,
	renderGroup = undefined,
	disabled = false,
	readOnly = false,
	multiple = true,
	required = false,
}: IFExtractSelect) => {
	const [acMenuOpen, setAcMenuOpen] = useState(false)
	const multiPolygons = useMultiPolygons()
	const { control } = useFormContext()

	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: required },
		defaultValue: multiple ? [] : null,
	})

	let correctedValue = (
		!multiple && value ? [value] : value
	) as Array<IFExtractSelectOption>
	if (!correctedValue) correctedValue = []
	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...correctedValue]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onChange(reordered)
	}

	return (
		<Autocomplete
			ListboxComponent={ListboxComponent}
			disableCloseOnSelect={multiple}
			open={acMenuOpen}
			onOpen={() => setAcMenuOpen(readOnly ? false : true)}
			onClose={() => setAcMenuOpen(false)}
			onKeyDown={(event) => {
				if (event.key === "Backspace" && readOnly) {
					//@ts-ignore
					event.defaultMuiPrevented = true
				}
			}}
			fullWidth
			disableClearable={multiple}
			multiple={multiple}
			noOptionsText={"Aucune donnée correspondante"}
			value={value}
			options={options}
			onChange={(e, newValue: any) => {
				onChange(newValue)
			}}
			disabled={disabled}
			renderOption={(props, option: IFExtractSelectOption, { selected }) => {
				let checked = false
				if (Array.isArray(correctedValue)) {
					checked = correctedValue
						.map((cvalue) => cvalue.value)
						?.includes(option.value)
				}
				return (
					<StyledLi
						lowPadding={multiple}
						{...props}
						onClick={(e) => {
							if (multiple) {
								if (checked) {
									onChange(
										correctedValue.filter(
											(cvalue) => cvalue.value !== option.value,
										),
									)
								} else {
									onChange([...correctedValue, option])
								}
							} else {
								onChange(option)
								setAcMenuOpen(false)
							}
						}}
						key={option.label}
					>
						{multiple && (
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								checked={checked}
							/>
						)}
						<Flex gap="1rem">
							{option.imageSrc && (
								<img src={option.imageSrc} alt="[Icône]" width="36px" />
							)}
							{option.polygonId && (
								<MultiPolygonColorSquare
									multiPolygon={multiPolygons[option.polygonId]}
								/>
							)}
							{/* option can be a string */}
							{option.label ?? option}
						</Flex>
					</StyledLi>
				)
			}}
			getOptionLabel={(option) => option.label ?? option}
			renderTags={(value: readonly string[], getTagProps) => {
				return (
					<DraggablePointTags
						getTagProps={getTagProps}
						value={value}
						onDragEnd={onDragEnd}
						readOnly={readOnly}
					/>
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					value={value}
					placeholder={null}
					inputRef={ref}
					error={Boolean(error)}
					helperText={error?.message}
					label={label}
					InputProps={{ ...params.InputProps }}
				/>
			)}
			groupBy={groupBy}
			renderGroup={renderGroup}
		/>
	)
}

export default FExtractSelect
