import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMapState from "@/hooks/useMapState"
import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"
import Debug from "debug"
import L from "leaflet"
import _ from "lodash"
import React, { useContext } from "react"
import { Marker, Popup } from "react-leaflet"
import PointCardHeader from "utils/components/jsonSchema/PointCardHeader"
import getImage from "@/utils/getImage"
import IPoint from "utils/types/IPoint"
import ReactDOMServer from "react-dom/server"
import styled, { css } from "styled-components"
import isPositionValid from "utils/isPositionValid"

const debug = Debug("components:carto2:cartographie:MarkerDaybook")

const SelectableImage = styled.img<{ selected: boolean }>`
    transform-origin: bottom center;
    transform: scale(1);
    filter: contrast(1);

    ${({ selected }) =>
			selected &&
			css`
            transform: scale(1.2);
            filter: contrast(1.8);
        `};
`

const MyIcon = ({ title, width, height, isSelected, imgId }) => {
	const image = imgId?.includes("/img/") ? imgId : getImage(imgId)

	return (
		<div>
			<SelectableImage
				selected={isSelected}
				src={image}
				style={{ width: width + "px", height: height + "px" }}
			/>
		</div>
	)
}

export const iconCreateFunction = (props) => {
	const { width, height } = props

	return L.divIcon({
		html: ReactDOMServer.renderToString(<MyIcon {...props} />),
		iconSize: [width, height],
		iconAnchor: [width / 2, height],
		popupAnchor: [0, -height],
		className: null,
	})
}

interface IStyle {
	iconSize: number
}

const MarkerDaybook = ({
	point,
	handleClick,
	forcedStyle = undefined,
	noInteraction = false,
	isSelected = false,
}: {
	point: IPoint
	handleClick: () => void
	forcedStyle?: IStyle
	noInteraction?: boolean
	isSelected?: boolean
}) => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const jsonSchema = jsonSchemas[point.jsonschema_id]
	const schemaProperties = jsonSchema?.template?.properties ?? []
	const geolocProperty = schemaProperties.filter(
		(property) => property.type === "geoloc",
	)[0]
	const positions = point?.geojson?.properties[geolocProperty?.name] as any
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const { coucheJsonSchemas } = useMapState()

	debug(
		"point",
		point,
		"\n",
		"jsonSchema",
		jsonSchema,
		"\n",
		"geolocProperty",
		geolocProperty,
		"\n",
		"positions",
		positions,
	)

	if (!positions) {
		return null
	}

	const position = [positions?.coo?.lat, positions?.coo?.lng]
	const customStyle = coucheJsonSchemas[point.jsonschema_id]?.style
	const activeFilter = coucheJsonSchemas[point.jsonschema_id]?.activeFilter

	// icon by default
	let icon = iconCreateFunction({
		imgId: jsonSchemas[point.jsonschema_id]?.imgId ?? jsonSchema.imgId,
		title: jsonSchemas[point.jsonschema_id]?.title,
		width: forcedStyle?.iconSize ?? customStyle?.iconSize ?? 30,
		height: forcedStyle?.iconSize ?? customStyle?.iconSize ?? 30,
		isSelected,
	})
	const filterProperty = JsonSchemaService.getFilterProperty(jsonSchema)
	if (filterProperty && !filterProperty?.options?.isMulti) {
		icon = iconCreateFunction({
			imgId:
				filterProperty.itemsImage.filter(
					(itemImage) =>
						point.geojson.properties?.[filterProperty?.name] ===
						itemImage.label,
				)[0]?.imgId ?? jsonSchema.imgId,
			title: jsonSchemas[point.jsonschema_id]?.title,
			width: customStyle?.iconSize ?? 30,
			height: customStyle?.iconSize ?? 30,
			isSelected,
		})
	}

	if (!_.isEmpty(activeFilter)) {
		let pointImageIds = point.geojson.properties?.[filterProperty?.name]
		if (!_.isArray(pointImageIds)) {
			pointImageIds = [pointImageIds]
		}
		if (
			!pointImageIds?.some((id) => activeFilter.includes(id)) &&
			activeFilter.length > 0
		) {
			return null
		}
		icon = iconCreateFunction({
			imgId: filterProperty.itemsImage.filter((itemImage) =>
				activeFilter.includes(itemImage.label),
			)[0]?.imgId,
			title: jsonSchemas[point.jsonschema_id]?.title,
			width: customStyle?.iconSize ?? 30,
			height: customStyle?.iconSize ?? 30,
			isSelected,
		})
	}

	// prevent crashing for undefined lat/lng
	if (!isPositionValid(position)) {
		return null
	}

	return (
		<Marker
			position={position as any}
			icon={icon}
			eventHandlers={{
				click: () => {
					if (isTakingScreenshotMap || noInteraction) {
						return
					}
					handleClick()
				},
				mouseover: (e) => {
					if (isTakingScreenshotMap || noInteraction) {
						return
					}
					const { target } = e
					target.openPopup()
				},
				mouseout: (e) => {
					if (isTakingScreenshotMap || noInteraction) {
						return
					}
					const { target } = e
					target.closePopup()
				},
			}}
		>
			{!noInteraction && (
				<Popup>
					<PointCardHeader point={point} />
				</Popup>
			)}
		</Marker>
	)
}

export default MarkerDaybook
