import CloseIcon from "@mui/icons-material/Close"
import { CardHeader, IconButton } from "@mui/material"
import React from "react"
import styled, { css } from "styled-components"
import useGetPointSummary from "./useGetPointSummary"
import { Image } from "@nextui-org/react"
import _ from "lodash"

const SCardHeader = styled(CardHeader) <{ $removePadding: boolean }>`
    ${({ $removePadding }) =>
		$removePadding &&
		css`
            padding: 0 !important;
        `}
    & .MuiCardHeader-title {
        font-size: 1.2rem;
        font-weight: 425;
    }
`

const PointCardHeader = ({
	point,
	onClose = undefined,
	removePadding = false,
}) => {
	const getPointSummary = useGetPointSummary()
	const pointSummary = getPointSummary(point)

	return (
		<SCardHeader
			$removePadding={removePadding}
			avatar={<Image height={42} width={42} src={pointSummary?.image} />}
			title={_.isEmpty(pointSummary?.title) ? "Sans identifiant" : pointSummary?.title}
			subheader={pointSummary?.jsonSchemaTitle ?? "Donnée inexistante"}
			action={
				onClose && (
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				)
			}
		/>
	)
}

export default PointCardHeader
