import React, { useCallback } from "react"
import IPoint from "utils/types/IPoint"
import _ from "lodash"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePointPropertyToText from "../jsonSchema/properties/usePointPropertyToText"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import useMultiPolygons from "@/hooks/useMultiPolygons"
const useSuperReactTableFilter = (disabledSearchProperties = []) => {
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const pointPropertyToText = usePointPropertyToText()
	const multiPolygons = useMultiPolygons()
	return useCallback(
		(row, columnId, value, addMeta) => {
			const filterValue = value
			if (
				formatNfdLowerCase(row.getValue(columnId)).includes(
					formatNfdLowerCase(value),
				)
			) {
				return true
			}
			const jsonSchema = jsonSchemasWithStatics[row.original?.jsonschema_id]

			if (jsonSchema) {
				const point = row.original as IPoint
				const jsonSchema = jsonSchemasWithStatics[point.jsonschema_id]
				let needToFilter = false
				jsonSchema.template.properties.forEach((property) => {
					if (disabledSearchProperties?.includes(property.name)) {
						return
					}
					const text = pointPropertyToText(point, property)
					if (
						formatNfdLowerCase(text)?.includes(formatNfdLowerCase(filterValue))
					) {
						needToFilter = true
					}
					if (property.type === JsonSchemaPropertiesType.GEOLOC) {
						const secteur =
							multiPolygons[point.geojson?.properties?.[property.name]?.secteur]
						if (secteur) {
							if (
								formatNfdLowerCase(secteur.name).includes(
									formatNfdLowerCase(filterValue),
								)
							) {
								needToFilter = true
							}
						}
						if (
							!secteur &&
							point.geojson?.properties?.[property.name]?.secteur ===
								"Hors secteur"
						) {
							if (
								formatNfdLowerCase("Hors secteur").includes(
									formatNfdLowerCase(filterValue),
								)
							) {
								needToFilter = true
							}
						}
					}
				})
				return needToFilter
			}
			let needToFilter = false
			Object.entries(row.original).forEach(([key, value]) => {
				if (disabledSearchProperties?.includes(key)) {
					return
				}
				if (
					formatNfdLowerCase(JSON.stringify(value))?.includes(
						formatNfdLowerCase(filterValue),
					)
				) {
					needToFilter = true
				}
			})
			return needToFilter
		},
		[jsonSchemasWithStatics],
	)
}

export default useSuperReactTableFilter
