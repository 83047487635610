import version from "@/redux-toolkit/version"
import ErrorService from "@/services/ErrorService"
import SynchroService from "@/services/SynchroService"
import { createAsyncThunk } from "@reduxjs/toolkit"
import Axios from "axios"
import { addCommunes } from "../admin/accounts/reducer"
import { initialization } from "../common/reducer"
import { resetRedux } from "../rootActions"
import CommuneApi from "./CommuneApi"
import { setCommune } from "./reducer"

export const getUserCommune = async (dispatch) => {
	try {
		const { commune } = await CommuneApi.getCommune()
		dispatch(setCommune(commune))
	} catch (error) {
		const errorMessage = `Erreur lors de la récupération des paramétres d'utilisateur, message : ${error.message}`
		ErrorService.error({
			component: "ressources:getUserCommune",
			message: errorMessage,
			dispatch,
		})
	}
}

export const getPublicCommune = createAsyncThunk(
	"picrim/getCommune",
	async (communeName: string, { dispatch }) => {
		try {
			const result = await CommuneApi.getPublicCommune(communeName)
			dispatch(setCommune(result.data.commune))
			return result.data.commune
		} catch (error) {
			const errorMessage = `Erreur, la commune n'a pas pu être récupéré, message d'erreur: ${error.message}`
			console.error(errorMessage)
		}
	},
)

export const updateCommuneOrga = createAsyncThunk(
	"commune/updateCommuneOrga",
	async (orga: any, { dispatch, getState }) => {
		try {
			await CommuneApi.updateCommune({ orga })
			getUserCommune(dispatch)
		} catch (error) {
			const errorMessage = `Erreur lors de la mise à jour de l'organigramme. Message d'erreur ${error.message}`
			ErrorService.error({
				component: "ressources:updateCommuneOrga",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateCommune = createAsyncThunk(
	"commune/updateCommune",
	async (commune: any, { dispatch, getState }) => {
		try {
			await CommuneApi.updateCommune(commune)
			getUserCommune(dispatch)
		} catch (error) {
			const errorMessage = `Erreur lors de la mise à jour de commune. Message d'erreur ${error.message}`
			ErrorService.error({
				component: "ressources:updateCommune",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const setRisks = createAsyncThunk(
	"commune/setRisks",
	async (risks: any, { dispatch, getState }) => {
		try {
			const commune2 = (getState() as any).commune
			const newRisks = risks.filter((x) => !commune2.risks.includes(x))
			const oldRisks = commune2.risks.filter((x) => !risks.includes(x))
			await CommuneApi.setRisks({ risks, newRisks, oldRisks })
			localStorage.clear()
			dispatch(resetRedux())
			localStorage["version"] = version
			dispatch(
				SynchroService.synchro({
					withoutDelete: true,
					initial: true,
				}),
			)
			dispatch(SynchroService.synchroOncePerSession({ withoutDelete: true }))
			dispatch(initialization())
		} catch (error) {
			const errorMessage = `Erreur lors de la configuration des risques, message : ${error.message}`
			ErrorService.error({
				component: "ressources:setRisks",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const setAdminRisks = createAsyncThunk(
	"commune/setAdminRisks",
	async ({ risks, commune_id }: any, { dispatch, getState }) => {
		try {
			await CommuneApi.setAdminRisks({ risks, commune_id })
			const res = await Axios.get("/api/communes")
			if (res.data.status !== 200) {
				throw new Error(`Request failed with status : ${res.data.status}`)
			}
			const communes = JSON.parse(res.data.communes)
			dispatch(addCommunes(communes))
		} catch (error) {
			const errorMessage = `Erreur lors de la configuration des risques, message : ${error.message}`
			ErrorService.error({
				component: "ressources:setAdminRisks",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
