import _ from "lodash"
import Moment from "moment"

export const sortByUpdatedAt = (object) => {
	if (Array.isArray(object)) {
		return object.sort((a, b) => {
			// @ts-ignore
			return new Date(b.updated_at) - new Date(a.updated_at)
		})
	}
	return Object.values(this ?? {}).sort((a, b) => {
		// @ts-ignore
		return new Date(b.updated_at) - new Date(a.updated_at)
	})
}

export const sortByCreatedAt = (object) => {
	if (Array.isArray(object)) {
		return object.sort((a, b) => {
			// @ts-ignore
			return new Date(b.created_at) - new Date(a.created_at)
		})
	}
	return Object.values(this ?? {}).sort((a, b) => {
		// @ts-ignore
		return new Date(b.created_at) - new Date(a.created_at)
	})
}

export const sortByDate = (object, accessor) => {
	if (Array.isArray(object)) {
		return object.sort((a, b) => {
			// @ts-ignore
			return new Date(_.get(b, accessor)) - new Date(_.get(a, accessor))
		})
	}
	return Object.values(this ?? {}).sort((a, b) => {
		// @ts-ignore
		return new Date(_.get(b, accessor)) - new Date(_.get(a, accessor))
	})
}

// SuperReactTable sortType date
export const sortTypeDateFactory =
	(accessor, invertedEmpty = false) => (rowA, rowB, id, desc) => {
		const a = _.get(rowA.original, accessor, null)
			? Moment(_.get(rowA.original, accessor), "DD/MM/YYYY HH:mm")
			: null
		const b = _.get(rowB.original, accessor, null)
			? Moment(_.get(rowB.original, accessor), "DD/MM/YYYY HH:mm")
			: null

		if (!a && b) {
			return invertedEmpty ? 1 : -1
		}
		if (!b && a) {
			return invertedEmpty ? -1 : 1
		}
		if (!a && !b) {
			return 0
		}
		return a.diff(b) > 0 ? 1 : -1
	}
