import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"
import syncDataAdapter from "../syncDataAdapter"

const initialState = {}

const pointsSlice = createSlice({
	name: "points",
	initialState,
	reducers: {
		addPoint: syncDataAdapter.addWithMerge,
		replacePoint: syncDataAdapter.replace,
		addPoints: syncDataAdapter.many(syncDataAdapter.addWithMerge),
		removePoint: syncDataAdapter.remove,
		removePoints: syncDataAdapter.many(syncDataAdapter.remove),
		createLocalPoint: syncDataAdapter.createLocal,
		updateLocalPoint: syncDataAdapter.updateLocal,
		deleteLocalPoint: syncDataAdapter.deleteLocal,
		deleteLocalPoints: syncDataAdapter.many(syncDataAdapter.deleteLocal),
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => {
			return initialState
		})
	},
})

export default pointsSlice.reducer
export const {
	replacePoint,
	addPoint,
	addPoints,
	removePoint,
	removePoints,
	createLocalPoint,
	updateLocalPoint,
	deleteLocalPoint,
	deleteLocalPoints,
} = pointsSlice.actions
