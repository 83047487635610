import _ from "lodash"
import moment from "moment"
import getImage from "@/utils/getImage"
import { lat2tile, lon2tile } from "@/utils/map"
import Axios from "axios"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"

const S3_CACHE_NAME = ":s3-cache"
const MAP_CACHE_NAME = ":map-cache"

const cacheAllURLs = (urls, cacheName, { timeout = 15, threads = 4 } = {}) => {
	const urlsPerThread = Array(threads)
		.fill(0)
		.map(() => [])
	urls.forEach((url, index) => {
		urlsPerThread[index % threads].push(url)
	})
	return window.caches
		.open(cacheName)
		.then((/* It's a cache object. */ cache) =>
			Promise.all(
				// Asynchrone thread
				urlsPerThread.map(async (urls) => {
					const allStatus = []
					// Synchrone tasks
					for (const url of urls) {
						const status = await cache.match(url).then(async (cacheRes) => {
							if (cacheRes) {
								// Cache exists
								const fetchedOn = moment(
									cacheRes.headers.get("cache-fetch-date"),
								)
								if (moment().isBefore(fetchedOn.add(timeout, "days"))) {
									// Cache id valid
									return { url, status: "valid" }
								}
							}

							const handleSuccess = () => ({
								url,
								status: cacheRes ? "renewed" : "added",
							})
							const handleError = (error) => {
								return {
									url,
									status: cacheRes ? "outdated" : "failed",
									error,
								}
							}

							try {
								const freshRes = await Axios.get(url, {
									headers: {
										"Cache-Control": "no-cache",
										"Access-Control-Allow-Origin": "*",
										["no-sw-cache"]: "yes",
										["cache-fetch-date"]: moment().toISOString(),
									},
									responseType: "blob",
								})

								if (freshRes.status !== 200) {
									return handleError(freshRes.statusText)
								}

								const headersWithDate = new Headers(freshRes.headers)
								headersWithDate.set("cache-fetch-date", moment().format())

								return cache.put(
									url,
									new Response(freshRes.data, {
										status: freshRes.status,
										statusText: freshRes.statusText,
										headers: headersWithDate,
									}),
								)
							} catch (error) {
								return handleError(error)
							}
						})
						allStatus.push(status)
					}
					return allStatus
				}),
			).then((values) => _.flatten(values)),
		)
}

const cacheJsonSchemasIcons = (jsonSchemas) => {
	const urls = []
	Object.values(jsonSchemas).forEach((jsonSchema: any) => {
		const filterProperty = JsonSchemaService.getFilterProperty(jsonSchema)
		if (filterProperty) {
			;(filterProperty?.itemsImage ?? []).forEach((img) => {
				urls.push(getImage(img.imgId))
			})
		}

		urls.push(getImage(jsonSchema.imgId))
	})
	return cacheAllURLs(urls, S3_CACHE_NAME)
}

const MAX_ZOOM = 15

const cacheMapTiles = (mapBounds) => {
	if (!mapBounds) return

	const lons = []
	const lats = []
	switch (mapBounds.type) {
		case "Polygon":
			mapBounds.coordinates.forEach((path) => {
				path.forEach((coo) => {
					lons.push(coo[0])
					lats.push(coo[1])
				})
			})
			break
		case "MultiPolygon":
			mapBounds.coordinates.forEach((group) => {
				group.forEach((path) => {
					path.forEach((coo) => {
						lons.push(coo[0])
						lats.push(coo[1])
					})
				})
			})
			break
		default:
			console.log("[cachedev:map] not a polygon nor a multipolygon")
			return
	}

	const topLeft = {
		lon: Math.min(...lons),
		lat: Math.max(...lats),
	}
	const bottomRight = {
		lon: Math.max(...lons),
		lat: Math.min(...lats),
	}

	const tiles = []

	for (let zoom = 1; zoom <= MAX_ZOOM; zoom++) {
		const limitLeft = lon2tile(topLeft.lon, zoom)
		const limitRight = lon2tile(bottomRight.lon, zoom)
		const limitTop = lat2tile(topLeft.lat, zoom)
		const limitBottom = lat2tile(bottomRight.lat, zoom)

		const extra = zoom > 1 && zoom <= 13 ? 1 : 0

		for (let x = limitLeft - extra; x <= limitRight + extra; x++) {
			for (let y = limitTop - extra; y <= limitBottom + extra; y++) {
				tiles.push({ x, y, zoom })
			}
		}
	}

	const buildURL = ({ x, y, zoom }) =>
		`https://a.tile.osm.org/${zoom}/${x}/${y}.png`

	const tilesURL = tiles.map(buildURL)

	return cacheAllURLs(tilesURL, MAP_CACHE_NAME, { threads: 2 })
}

export default {
	cacheJsonSchemasIcons,
	cacheMapTiles,
}

export const prettyPrintCacheResult = (title, color) => {
	const start = performance.now()
	return (result) => {
		const end = performance.now()
		const status = ["valid", "added", "renewed", "failed", "outdated"]
		const messages = status.map((stat) => {
			return stat + " : " + result.filter((e: any) => e?.status === stat).length
		})
		console.log(
			`%c[${title}][${end - start}]\n`,
			"color: white;background-color: " + color,
			messages.join("\n"),
		)
	}
}
