export enum IOrgaCategory {
	DECISIONNEL = "Décisionnelles",
	OPERATIONNEL = "Opérationnelles",
	SUPPORT = "Support",
}

export const ORGA_CATEGORIES = [
	IOrgaCategory.DECISIONNEL,
	IOrgaCategory.OPERATIONNEL,
	IOrgaCategory.SUPPORT,
]

export const DECISIONNEL_COLOR = "#c34720"
export const OPERATIONNEL_COLOR = "#1879CE"
export const SUPPORT_COLOR = "#008000dd"

export const ORGA_COLOR = {
	[IOrgaCategory.DECISIONNEL]: DECISIONNEL_COLOR,
	[IOrgaCategory.OPERATIONNEL]: OPERATIONNEL_COLOR,
	[IOrgaCategory.SUPPORT]: SUPPORT_COLOR,
}

export interface IOrgaCellule {
	idName: string
	id: string
	category: IOrgaCategory
	responsableIds: string[]
	suppleantIds: string[]
	membresIds: string[]
	description?: string
}

interface IOrga {
	[celluleIdName: string]: IOrgaCellule
}

export default IOrga
