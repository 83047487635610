import useUploadedCartos from "@/hooks/useUploadedCartos"
import FormStep from "@/pages/telechargement/Upload/component/FormStep"
import useStep from "@/pages/telechargement/Upload/hooks/useStep"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Flex } from "utils/components/style/flex"
import FCreatableSelect from "utils/form/FCreatableSelect"
import FText from "utils/form/FText"
import RiskService from "utils/services/RiskService"

const StepMoreInfos = ({ setTempValue, ...props }) => {
	const { value, onBack } = useStep("name")
	const uploadedCartos = useUploadedCartos()

	useEffect(() => {
		setTempValue(value)
	}, [value])

	const memoizedSuggestions = useMemo(() => {
		const buildedOptions = [] as string[]
		const risksSuggestions = RiskService.getRisquesList().sort(function (a, b) {
			if (a < b) {
				return -1
			}
			if (a > b) {
				return 1
			}
			return 0
		})
		Object.values(uploadedCartos).forEach((carto: any) => {
			const categoryKey = carto.category
			if (_.isEmpty(categoryKey)) return
			if (
				!buildedOptions.includes(carto.category) &&
				!risksSuggestions.includes(carto.category)
			) {
				buildedOptions.push(categoryKey)
			}
		})

		return [...buildedOptions, ...risksSuggestions]
	}, [uploadedCartos])

	return (
		<FormStep
			question=""
			formatedValue=""
			onBack={onBack}
			clearStyles
			{...props}
		>
			<Flex gap=".5em" directionColumn alignItemsStretch>
				<FText name="name" label="Titre" />
				<FCreatableSelect
					name="category"
					label="Catégorie (optionnel)"
					suggestions={memoizedSuggestions}
				/>
			</Flex>
		</FormStep>
	)
}

export default StepMoreInfos
