import { StaticFormType } from "utils/services/StaticFormsService"

const layersPersonnes = [
	{
		key: StaticFormType.ANNUAIRES_ELUS,
		label: StaticFormType.ANNUAIRES_ELUS,
		jsonschema_id: StaticFormType.ANNUAIRES_ELUS
	},
	{
		key: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
		label: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
		jsonschema_id: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL
	},
	{
		key: StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
		label: StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
		jsonschema_id: StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE
	}
]

const layersRessources = [
	{
		key: StaticFormType.PCC,
		label: StaticFormType.PCC,
		jsonschema_id: StaticFormType.PCC
	},
	{
		key: StaticFormType.CARE,
		label: StaticFormType.CARE,
		jsonschema_id: StaticFormType.CARE
	},
	{
		key: StaticFormType.STOCK_PLACE,
		label: StaticFormType.STOCK_PLACE,
		jsonschema_id: StaticFormType.STOCK_PLACE
	}
]

export { layersPersonnes, layersRessources }
