import pcsFiles from "@/isoMorphicData/pcsStaticFiles/pcsStaticFiles.json"
import picsFiles from "@/isoMorphicData/pcsStaticFiles/picsStaticFiles.json"
import pcsDynamicFiles from "@/isoMorphicData/pcsDynamicFiles/pcsDynamicFiles.json"
import picsDynamicFiles from "@/isoMorphicData/pcsDynamicFiles/picsDynamicFiles.json"
import _ from "lodash"
import { useMemo } from "react"
import useCalculateCode from "./useCalculateCode"
import useUploadedFiles from "./useUploadedFiles"
import fileinfo from "@/utils/fileinfo"
import buildUploadedFileLink from "@/utils/buildUploadedFileLink"
import useIsInterco from "./useIsInterco"

interface IFile {
	id: string
	pdf: string
	title: string
	path: string[]
	code: string
}
interface IDynamicFile {
	key: string
	title: string
	path: string[]
	type?: string
}
interface IFiles {
	[key: string]: IFile | IDynamicFile
}
interface IFilesWithLivret {
	[key: string]: (IFile | IDynamicFile) & { livret: string }
}

const useStaticFiles: ({
	withUploaded,
	withDynamics,
}: {
	withUploaded: boolean
	withDynamics?: boolean
}) => IFilesWithLivret = ({ withUploaded = true, withDynamics = false }) => {
	const isInterco = useIsInterco()
	const staticFiles = isInterco ? picsFiles : pcsFiles
	const dynamicFiles = isInterco ? picsDynamicFiles : pcsDynamicFiles

	const staticFilesWithKey = Object.fromEntries(
		Object.entries(staticFiles).map(([id, detail]) => [id, { ...detail, id }]),
	) as IFiles

	const uploadedFiles = useUploadedFiles()

	const calculateNewCode = useCalculateCode()

	return useMemo(
		() => {
			const staticFilesWithKeyClone = _.cloneDeep(staticFilesWithKey)
			const modifiedStaticFiles = {} as IFilesWithLivret
			Object.entries(staticFilesWithKeyClone).forEach(([id, staticFile]) => {
				let extractLivret = staticFilesWithKeyClone[id].path[0]
				extractLivret = extractLivret.replace("livret", "LIVRET_")
				const calculatedCode = calculateNewCode(staticFile.id)
				modifiedStaticFiles[id] = {
					...staticFile,
					livret: extractLivret,
					title: calculatedCode + " " + staticFile.title,
					code: calculatedCode,
				}
			})

			if (!withUploaded) {
				Object.entries(modifiedStaticFiles).forEach(([id, file]) => {
					if (file.livret === "uploaded_files") {
						allFiles[id] = {
							...file,
							livret: allFiles[id].path?.[0],
						}
					}
				})

				// remove replaced files
				Object.values(uploadedFiles)
					.filter(
						(file) =>
							file.config.category === "PROCEDURE" &&
							"replace" in file.config.pcs,
					)
					.forEach((file) => {
						// @ts-ignore
						const replace = file.config.pcs.replace
						delete modifiedStaticFiles[replace]
					})

				return Object.fromEntries(
					_.sortBy(
						Object.entries(modifiedStaticFiles),
						([, item]) => item.title,
					),
				)
			}

			if (withDynamics) {
				const dynamicFilesWithKey = Object.fromEntries(
					Object.entries(dynamicFiles).map(([id, detail]) => [
						id,
						{ ...detail, id },
					]),
				) as IFiles
				const dynamicFilesWithKeyClone = _.cloneDeep(dynamicFilesWithKey)
				Object.entries(dynamicFilesWithKeyClone).forEach(
					([id, dynamicFile]) => {
						let extractLivret = dynamicFilesWithKeyClone[id].path[0]
						extractLivret = extractLivret.replace("livret", "LIVRET_")
						const calculatedCode = calculateNewCode(dynamicFile.key)
						modifiedStaticFiles[id] = {
							...dynamicFile,
							livret: extractLivret,
							title: calculatedCode + " " + dynamicFile.title,
							code: calculatedCode,
						}
					},
				)
			}

			// Replace existing
			Object.values(uploadedFiles)
				.filter(
					(file) =>
						file.config.category === "PROCEDURE" &&
						"replace" in file.config.pcs,
				)
				.forEach((file) => {
					// @ts-ignore
					const replace = file.config.pcs.replace
					modifiedStaticFiles[replace] = {
						...modifiedStaticFiles[replace],
						title: fileinfo(file.filename).base,
						pdf: buildUploadedFileLink(file.id, false),
					} as IFile & { livret: string }
				})

			// Add new
			const uploadedStaticFiles = Object.fromEntries(
				Object.entries(uploadedFiles)
					.filter(
						([, file]) =>
							file.config.category === "PROCEDURE" &&
							("after" in file.config.pcs || "appendix" in file.config.pcs),
					)
					.map(([id, file]) => [
						id,
						{
							id,
							title: fileinfo(file.filename).base,
							pdf: buildUploadedFileLink(file.id, false),
							// @ts-ignore
							path: file.path,
							// @ts-ignore
							livret: file.path?.[0],
						},
					]),
			) as IFilesWithLivret

			const allFiles = {
				...modifiedStaticFiles,
				...uploadedStaticFiles,
			} as IFilesWithLivret

			Object.entries(allFiles).forEach(([id, file]) => {
				if (file.livret === "uploaded_files") {
					allFiles[id] = {
						...file,
						livret: allFiles[id].path?.[0],
					}
				}
			})

			return Object.fromEntries(
				_.sortBy(Object.entries(allFiles), ([, item]) => item.title),
			)
		},
		withUploaded ? [uploadedFiles] : [],
	)
}

export default useStaticFiles
