import React from "react"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import { TypeComponentProps } from "../PointPropertyValue"
import { safeArray } from "./TypeCheckbox"
import { DiffSpan } from "./PropertiesType.styled"

const NoWrap = styled.span`
    white-space: nowrap;
`

const ListItem = styled.div<{ align: "right" | "left"; $fontSize: string }>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ align }) =>
			align === "left" ? "flex-start" : "flex-end"};
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 7px;
    & > * {
        ${({ $fontSize }) =>
					$fontSize &&
					css`
                font-size: ${$fontSize};
            `}
    }
`

const ListItemName = styled.div`
    position: relative;
    :before {
        content: '•';
        position: absolute;
        left: -7px;
    }
`

function TypePhone({
	value: phonesList,
	align,
	diffWith,
	fontSize = undefined,
}: TypeComponentProps) {
	const safePhoneList = safeArray<{
		Numéro: string
		Type: string
	}>(phonesList)

	return (
		<Flex alignItemsEnd directionColumn flexEnd={align === "right"} gap="5px">
			{safePhoneList.length === 0 && <></>}
			{diffWith === undefined ? (
				safePhoneList.map(
					(phone) =>
						phone.Numéro &&
						phone.Type && (
							<ListItem key={phone?.Numéro} align={align} $fontSize={fontSize}>
								<ListItemName>{phone?.Type}&nbsp;:&nbsp;</ListItemName>
								<NoWrap>{phone?.Numéro}</NoWrap>
							</ListItem>
						),
				)
			) : (
				<>
					{diffWith
						.filter(
							(phone) => !safePhoneList.find((ph) => ph.Type === phone.Type),
						)
						.map((phone) => (
							<ListItem key={phone?.Numéro} align={align} $fontSize={fontSize}>
								<ListItemName>
									<DiffSpan>{phone?.Type}</DiffSpan>
									&nbsp;:&nbsp;
								</ListItemName>
								<NoWrap>
									<DiffSpan>{phone?.Numéro}</DiffSpan>
								</NoWrap>
							</ListItem>
						))}

					{safePhoneList.map((phone) => {
						if (!phone || (!phone.Numéro && !phone.Type)) return null

						const eq = diffWith.find((ph) => ph.Type === phone.Type)

						return (
							<ListItem key={phone.Numéro} align={align} $fontSize={fontSize}>
								<ListItemName>
									{eq ? phone?.Type : <DiffSpan add>{phone?.Type}</DiffSpan>}
									&nbsp;:&nbsp;
								</ListItemName>
								{eq ? (
									eq.Numéro !== phone.Numéro ? (
										<>
											<NoWrap>
												<DiffSpan>{eq.Numéro}</DiffSpan>
											</NoWrap>{" "}
											<NoWrap>
												<DiffSpan add>{phone.Numéro}</DiffSpan>
											</NoWrap>
										</>
									) : (
										phone.Numéro
									)
								) : (
									<NoWrap>
										<DiffSpan add>{phone.Numéro}</DiffSpan>
									</NoWrap>
								)}
							</ListItem>
						)
					})}
				</>
			)}
		</Flex>
	)
}

export default TypePhone
