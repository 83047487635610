import Debug from "debug"
import Axios from "axios"

const debug = Debug("components:redux:data:jsonSchema:JsonSchemaApi")

const getJsonSchemas = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(`/api/json_schemas?lastSynchro=${lastSynchro}`)
	return result.data
}

const getCdcJsonSchemas = async (cdc_id = null) => {
	const result = await Axios.get(`/api/json_schemas/cdc?cdc_id=${cdc_id}`)
	return result.data
}

const getPicrimJsonSchemas = async (communeId) => {
	const result = await Axios.get(`/api/picrim/json_schemas/${communeId}`)
	return result.data
}

const getJsonSchemasWithDeleted = async (
	lastSynchro = "2000-04-12T12:47:50+02:00",
) => {
	const result = await Axios.get(
		`/api/json_schemas?lastSynchro=${lastSynchro}&deleted=true`,
	)
	return result.data
}

const getJsonSchemasAdmin = async () => {
	const result = await Axios.get("/api/json_schemas/admin")
	return result.data
}

const createJsonSchema = async (jsonSchema) => {
	const result = await Axios.post(`/api/json_schemas`, { data: jsonSchema })
	return result.data
}

const updateJsonSchema = async (jsonSchema) => {
	const result = await Axios.put(`/api/json_schemas/${jsonSchema.id}`, {
		data: jsonSchema,
	})
	return result.data
}

const toggleJsonSchemaPublic = async (jsonSchema) => {
	const result = await Axios.put(`/api/json_schemas/${jsonSchema.id}`, {
		data: {
			...jsonSchema,
			public: !jsonSchema.public,
		},
	})
	return result.data
}

const deleteJsonSchema = async (id) => {
	const result = await Axios.delete(`/api/json_schemas/${id}`)
	return result.data
}

export default {
	getJsonSchemasWithDeleted,
	getJsonSchemas,
	getCdcJsonSchemas,
	getPicrimJsonSchemas,
	createJsonSchema,
	updateJsonSchema,
	deleteJsonSchema,
	getJsonSchemasAdmin,
	toggleJsonSchemaPublic,
}
