import Debug from "debug"
import React from "react"
import { Spacer } from "./NSpacer.styled"

// app:javascript:utils:components:style:NSpacer:NSpacer.tsx
const debug = Debug("app:javascript:utils:components:style:NSpacer:NSpacer")
debug.log = console.log.bind(console)

interface Props {
	size?: number
	direction?: "horizontal" | "vertical"
}
const NSpacer = ({ size = 1, direction = "vertical" }: Props) => {
	return <Spacer size={size} direction={direction} />
}

export default NSpacer
