import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Edit from "@mui/icons-material/Edit"
import LocalPoliceIcon from "@mui/icons-material/LocalPolice"
import { IconButton } from "@mui/material"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import Debug from "debug"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import styled from "styled-components"
import PointCard from "@/utils/components/jsonSchema/PointCard"
import { Flex } from "@/utils/components/style/flex"
import { DAYBOOK_LIST } from "./PanelCarto"
import Button from "@/styles/atoms/Button"
const debug = Debug("app:javascript:utils:panels:PanelDaybookPoint")
debug.log = console.log.bind(console)

const PanelDaybookPoint = () => {
	const daybookPannel = useSelector((state: any) => state.common.panel)
	const points = usePoints()
	const history = useHistory()
	const currentPoint = points[daybookPannel.id]
	const dispatch = useDispatch()
	const jsonSchemas = useJsonSchemasWithStatics()
	const jsonSchema = jsonSchemas[currentPoint?.jsonschema_id]
	const writePermission = jsonSchema?.write
	useEffect(() => {
		const geolocProperty = jsonSchema?.template?.properties?.find(
			(property) => property.type === "geoloc",
		)
		if (!geolocProperty) {
			return
		}
		const geoloc = currentPoint.geojson.properties[geolocProperty.name]
		if (!geoloc || !geoloc?.coo?.lat || !geoloc?.coo?.lon) {
			return
		}
		MapService.getMap()?.setView([geoloc.coo.lat, geoloc.coo.lng])
	}, [currentPoint])

	if (!currentPoint) {
		return null
	}
	return (
		<>
			<Flex fullWidth spaceBetween padding="1rem">
				<Button
					color="primary"
					variant="bordered"
					$margin="1rem"
					onClick={() => {
						dispatch(
							setPanel({
								type: DAYBOOK_LIST,
								jsonschema_id: currentPoint.jsonschema_id,
							}),
						)
					}}
					startIcon={<ArrowBackIcon />}
				>
					Retour liste
				</Button>
				{writePermission && (
					<Button
						color="primary"
						$margin="1rem"
						onClick={() => {
							dispatch(setCurrentPoint(currentPoint))
						}}
						startIcon={<Edit />}
					>
						Éditer
					</Button>
				)}
			</Flex>
			<PointCard point={currentPoint} Container={React.Fragment} />
		</>
	)
}

export default PanelDaybookPoint
