import styled from "styled-components"

export const Legend = styled.div`
    width: 20px;
    height: 10px;
    border-radius: 15px;
    align-self: center;
    background-color: ${({ color }) => color};
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 14%),
        0px 2px 2px 0px rgb(0 0 0 / 12%), 0px 1px 5px 0px rgb(0 0 0 / 8%);
`
