import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import SuperModal from "utils/modals/SuperModal"
import { Flex } from "utils/components/style/flex"
import { H6 } from "utils/components/style/header"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import usePoints from "@/hooks/usePoints"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import { CARE_FAMILY } from "@/redux-toolkit/userSettings/constants"
import FLeavingGroupListElement from "./FLeavingGroupListElement"
import { PrimaryTextButton } from "utils/components/style/button"

const STextField = styled(TextField)`
    width: -webkit-fill-available !important;
`
// app:javascript:components:maincourante:care:leaving:ModalLeavingPerson.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:leaving:ModalLeavingPerson",
)
debug.log = console.log.bind(console)

const ModalLeavingPerson = ({ isOpen, onClose, careId, initialData }) => {
	const [searchPersonValue, setSearchPersonValue] = useState("")
	const selectedEvent = useSelectedEvent()
	const points = usePoints()
	const dispatch = useDispatch()
	const careFamilyPoints = Object.values(points).filter(
		(point) =>
			point.jsonschema_id === CARE_FAMILY &&
			point.event_id === selectedEvent.id &&
			point.geojson.properties.careId === careId,
	)

	const matchingSearchPersons = useMemo(() => {
		if (searchPersonValue === "") {
			return {}
		}

		const resSearch: {
			[key: string]: { id: string; matchingKeys: string[] }
		} = {}
		careFamilyPoints.forEach((careFamilyPoint) => {
			const searchPersonValueLowerCase = formatNfdLowerCase(searchPersonValue)
			const matchingKeys = []
			Object.entries(careFamilyPoint.geojson.properties).forEach(
				([key, value]) => {
					if (formatNfdLowerCase(value).includes(searchPersonValueLowerCase)) {
						matchingKeys.push(key)
					}
				},
			)
			if (matchingKeys.length > 0) {
				resSearch[careFamilyPoint.id] = {
					id: careFamilyPoint.id,
					matchingKeys,
				}
			}
		})
		return resSearch
	}, [searchPersonValue, points, selectedEvent])

	return (
		<SuperModal
			isOpen={isOpen}
			onClose={onClose}
			title="Choisir une personne à ajouter au groupe de sortie"
			onClick={onClose}
			createButtonProps={{ children: "Valider" }}
		>
			<Flex directionColumn gap="1rem" fullWidth alignItemsStart>
				<>
					<H6>Ajouter une ou des personnes au groupe de sortie</H6>
					<STextField
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: (
								<>
									{searchPersonValue && (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													setSearchPersonValue("")
												}}
											>
												<ClearIcon />
											</IconButton>
										</InputAdornment>
									)}
								</>
							),
						}}
						placeholder="Rechercher une personne en centre d'accueil"
						value={searchPersonValue}
						onChange={(e) => {
							setSearchPersonValue(e.target.value)
						}}
					/>
					<FLeavingGroupListElement
						initialData={initialData}
						name="geojson.properties.Points associés"
						label="Points associés"
						matchingSearchPersons={matchingSearchPersons}
						careFamilyPoints={careFamilyPoints}
					/>
				</>
			</Flex>
		</SuperModal>
	)
}

export default ModalLeavingPerson
