import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IMultiPolygon from "utils/types/IMultiPolygon"

const selectDrawGeoJSON = createDeepEqualSelector(
	(state: any) => state.daybook,
	(daybook: any) => daybook.drawGeojson,
)

const useDrawGeoJSON: () => Record<string, IMultiPolygon> = () => {
	return useSelector(selectDrawGeoJSON)
}

export default useDrawGeoJSON
