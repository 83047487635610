export const POINTS = "POINTS"
export const PUBLIC_POINTS = "PUBLIC_POINTS"
export const JSON_SCHEMAS = "JSON_SCHEMAS"
export const STOCK_PLACES = "STOCK_PLACES"
export const STOCKS = "STOCKS"
export const FICHES = "FICHES"
export const PDFS = "PDFS"
export const PDF_GENERATORS = "PDF_GENERATORS"
export const HUMANS = "HUMANS"
export const FICHE_ACTIONS = "FICHE_ACTIONS"
export const MULTI_POLYGONS = "MULTI_POLYGONS"
export const EVENTS = "EVENTS"
export const MAPPINGS = "MAPPINGS"
export const UPLOADED_CARTOS = "UPLOADED_CARTOS"

export const initialState = {
	[POINTS]: "2000-04-12T12:47:50+02:00",
	[JSON_SCHEMAS]: "2000-04-12T12:47:50+02:00",
	[STOCK_PLACES]: "2000-04-12T12:47:50+02:00",
	[STOCKS]: "2000-04-12T12:47:50+02:00",
	[FICHES]: "2000-04-12T12:47:50+02:00",
	[PDFS]: "2000-04-12T12:47:50+02:00",
	[PDF_GENERATORS]: "2000-04-12T12:47:50+02:00",
	[HUMANS]: "2000-04-12T12:47:50+02:00",
	[MULTI_POLYGONS]: "2000-04-12T12:47:50+02:00",
	[FICHE_ACTIONS]: "2000-04-12T12:47:50+02:00",
	[PUBLIC_POINTS]: "2000-04-12T12:47:50+02:00",
	[EVENTS]: "2000-04-12T12:47:50+02:00",
	[MAPPINGS]: "2000-04-12T12:47:50+02:00",
}
