import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import PeopleIcon from "@mui/icons-material/People"
import {
	Avatar,
	CardHeader,
	Chip,
	Divider,
	List,
	ListItem,
} from "@mui/material"
import useCareMembers from "@/hooks/care/useCareMembers"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import AdultChip from "@/pages/maincourante/care/chips/AdultChip"
import AnimalChip from "@/pages/maincourante/care/chips/AnimalChip"
import BabyChip from "@/pages/maincourante/care/chips/BabyChip"
import ChildChip from "@/pages/maincourante/care/chips/ChildChip"
import { setPanel } from "@/redux-toolkit/common/reducer"
import Debug from "debug"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { PrimaryTextButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { DAYBOOK_DATA } from "./PanelCarto"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"
import { Image } from "@nextui-org/react"
// app:javascript:utils:panels:PanelDaybookSignal.tsx
const debug = Debug("app:javascript:utils:panels:PanelDaybookSignal")
debug.log = console.log.bind(console)
const Container = styled.div`
    padding: 1rem;
`
const PanelDaybookCare = () => {
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const points = usePoints()
	const history = useHistory()
	const currentPannel = useSelector((state: any) => state.common.panel)

	const care = points[currentPannel.id]

	const { adultMember, childMember, babyMember, animalMember, specificities } =
		useCareMembers({ care })

	const capacite = care.geojson.properties["Capacité"] ?? 0

	return (
		<>
			<PrimaryTextButton
				onClick={() => dispatch(setPanel({ type: DAYBOOK_DATA }))}
			>
				<ArrowBackIcon />
				Retour aux données
			</PrimaryTextButton>
			<CardHeader
				avatar={<Image width={42} src="/img/maincourante/care.png" />}
				title={care.geojson.properties.Lieu}
				subheader="Centre d'accueil"
				action={
					<PrimaryTextButton
						onClick={() => {
							dispatch(setPanel(undefined))
							history.push(`/maincourante/care/${care.id}`)
						}}
					>
						Gérer
					</PrimaryTextButton>
				}
			/>
			<Container>
				<List>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Téléphones</div>
							{(care.geojson.properties?.Téléphones ?? [])
								.map((tel) => `${tel.Type} : ${tel.Numéro}`)
								.join(", ")}
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Capacité</div>
							<Flex gap="1rem">
								<PeopleIcon />
								<div>
									{adultMember + childMember + babyMember + animalMember} /{" "}
									{capacite}
								</div>
							</Flex>
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Responsables</div>
							<Flex gap="0.5rem" directionColumn>
								{(care.geojson.properties?.Responsable ?? []).map((human) => (
									<HumanChip key={human} human={points[human]} />
								))}
							</Flex>
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Type</div>
							<Flex gap="0.5rem" directionColumn>
								{adultMember > 0 && <AdultChip number={adultMember} />}
								{childMember > 0 && <ChildChip number={childMember} />}
								{babyMember > 0 && <BabyChip number={babyMember} />}
								{animalMember > 0 && <AnimalChip number={animalMember} />}
							</Flex>
						</Flex>
					</ListItem>
					<Divider />
					<ListItem>
						<Flex spaceBetween fullWidth>
							<div>Spécificités</div>
							<Flex gap="0.5rem" alignItemsEnd directionColumn>
								{specificities.map((specificity) => (
									<Chip
										variant="outlined"
										key={specificity.label}
										label={specificity.label}
										avatar={<Avatar>{specificity.count}</Avatar>}
									/>
								))}
							</Flex>
						</Flex>
					</ListItem>
				</List>
			</Container>
		</>
	)
}

export default PanelDaybookCare
