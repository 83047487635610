import MapService from "@/pages/carto2/cartographie/service/MapService"
import { useCurrentPngMap } from "@/hooks/useCurrentPngMap"
import useIsOnline from "@/hooks/useIsOnline"
import useMapState from "@/hooks/useMapState"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"
import easyPrint from "@/pages/maincourante/subComponents/easyPrint/easyPrint"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import SynchroService from "@/services/SynchroService"
import { IProvidedSelection } from "@/pages/telechargement/services/CartographyTemplate"
import $ from "jquery"
import L from "leaflet"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useMap, useMapEvents, useMapEvent } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { useNetworkState } from "react-use"
import useKeyboardJs from "utils/keyboard/useKeyboardJs"
import { v4 as uuidv4 } from "uuid"
import customTranslationLeafletPM from "./customTranslationLeafletPM"
import EasyPrintContext from "./print/EasyPrintContext"
import useOpenAppBar from "@/hooks/useOpenAppBar"

export const mapZoomDiff = 1.139741808166793
export const maxZoom = 18

const SuperMapContained = ({
	children,
	communeGeojson,
	resize,
	setTempDrawCouche,
	onDoubleClick = (event: any, activeMap: any) => {},
	setMapScreenBlob,
	setMapEventEndValue = undefined,
	isCaptureSelect,
	disableDragging = false,
	disableZoom = false,
	providedSelection = undefined,
	defaultViewPosition = undefined,
}: {
	children: JSX.Element
	communeGeojson: any
	resize: boolean
	setTempDrawCouche: React.Dispatch<any>
	onDoubleClick?: (event: any, activeMap: any) => void
	setMapScreenBlob?: React.Dispatch<any>
	setMapEventEndValue?: (value: any) => void
	isCaptureSelect: boolean
	disableDragging?: boolean
	disableZoom?: boolean
	providedSelection?: IProvidedSelection
	defaultViewPosition?: { center: [number, number]; zoom: number }
}) => {
	const map = useMap()
	const [currentEasyPrint, setCurrentEasyPrint] = useState(undefined)
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const { getPng } = useCurrentPngMap(currentEasyPrint)
	const [isCtrlPressed] = useKeyboardJs("ctrl")
	const { viewportParams } = useMapState()
	const [mapInit, setMapInit] = useState(false)
	const [isOpenAppBar, setIsOpenAppBar] = useOpenAppBar()

	//* Used to setView when restoring carto for download PCS
	if (providedSelection?.viewParams) {
		const correctedZoom = providedSelection?.viewParams?.zoom + mapZoomDiff
		const zoomParam = correctedZoom > maxZoom ? maxZoom : correctedZoom

		if (
			!_.isEqual(
				Object.values(map.getCenter()),
				providedSelection?.viewParams?.center,
			) ||
			!_.isEqual(map.getZoom(), providedSelection?.viewParams?.zoom)
		) {
			//* Applying zoom correction due to modal usage, it's equal to 2x zoom scoll in.
			map.setView(providedSelection?.viewParams?.center, zoomParam)
		}
	}

	//* Same goal of setView but using flyTo on classic usage
	useEffect(() => {
		if (defaultViewPosition) {
			map.flyTo(defaultViewPosition.center, defaultViewPosition.zoom)
			return
		}
		if (!_.isEmpty(viewportParams)) {
			map.flyTo(viewportParams?.center, viewportParams?.zoom)
			return
		}
		const centerCoos = L.geoJson(communeGeojson).getBounds().getCenter()
		map.flyTo([centerCoos.lat, centerCoos.lng], 13.8)
	}, [viewportParams, defaultViewPosition])

	useMapEvents({
		dblclick: (e) => onDoubleClick(e, map),
		dragend: () => {
			if (!setMapEventEndValue) return
			setMapEventEndValue({
				center: [map.getCenter().lat, map.getCenter().lng],
			})
		},
		zoomend: () => {
			if (!setMapEventEndValue) return
			setMapEventEndValue({
				center: [map.getCenter().lat, map.getCenter().lng], //can move on map using zoom-in/zoom-out
				zoom: map.getZoom(),
			})
		},
	})

	useEffect(() => {
		if (!map) return
		// Skip saving for PCS and Daybook Report screenshots download
		const skipSaving = !window.location.pathname.includes("carto")
		// @ts-ignore
		const newEasyPrint = easyPrint({
			title: "My awesome print button",
			exportOnly: true,
			skipSaving: skipSaving,
			position: "bottomright",
			tileWait: 5000,
			hidden: true,
			sizeModes: ["A4Portrait", "A4Landscape"],
			hideControlContainer: false,
			filename: "mapForPCS",
			hideClasses: [
				"BottomCenterControl",
				"RightCenterControl",
				"SuperControl-sc-scj8iu-0",
				"BottomCenterControl__Control-sc-7kwrdm-0 fZyyLC",
				"MinimapControl__StyledMapContainer-sc-ufy13m-0",
				"RightCenterControl__Control-sc-ydisjj-2 cDBDeK",
				"leaflet-top",
				"leaflet-left",
				"leaflet-control-attribution",
			],
		}).addTo(map)

		setCurrentEasyPrint(newEasyPrint)
	}, [map])

	const panel = useSelector((state: any) => state.common.panel)
	const openMenu = useSelector((state: any) => state.common.openMenu)
	useMapEvent("dblclick", (e) => onDoubleClick(e, map))
	const { isTakingScreenshotMap, setIsTakingScreenshotMap } =
		useContext(ScreenShotContext)

	useEffect(() => {
		if (disableDragging) {
			map.dragging.disable()
		}
		if (disableZoom) {
			map.scrollWheelZoom.disable()
			map.boxZoom.disable()
		}
	}, [])

	useEffect(() => {
		setTimeout(() => {
			// @ts-ignore
			if (map && map._loaded && !mapInit) {
				setMapInit(true)
				map.invalidateSize()
				map.fitBounds(L.geoJson(communeGeojson).getBounds())
			}
		}, 300)
	}, [panel, openMenu, map])

	useEffect(() => {
		setTimeout(() => {
			// @ts-ignore
			if (map && map._loaded) {
				map.invalidateSize()
			}
		}, 300)
	}, [isOpenAppBar])

	useEffect(() => {
		MapService.registerMap(map)
		MapService.fitGeoJSONBounds(communeGeojson)
		if (resize) {
			$(".leaflet-container").css(
				"height",
				`calc(100vh - var(--height-top-bar))`,
			)
		}
	}, [resize])

	useEffect(() => {
		if (!isTakingScreenshotMap) return

		const takeScreen = async () => {
			const onFinishedCallback = (blobScreen) => {
				setMapScreenBlob(blobScreen)
				setIsTakingScreenshotMap?.(false)
			}
			getPng(onFinishedCallback)
		}

		takeScreen()
	}, [isTakingScreenshotMap])

	useEffect(() => {
		MapService.registerMap(map)

		// @ts-ignore
		map.pm.setLang("customFr", customTranslationLeafletPM, "fr")
		const onCreate = ({ shape, layer }: any) => {
			if (!isCaptureSelect) {
				if (!layer._drawnByGeoman) {
					return
				}
				if (shape !== "Marker") {
					return
				}
				map.removeLayer(layer)
				if (!isCtrlPressed) {
					map.pm.disableDraw()
				}
				const id = uuidv4()
				dispatch(
					updateEvent({
						...selectedEvent,
						mapSignalMarkers: {
							...selectedEvent.mapSignalMarkers,
							[id]: {
								id,
								lat: layer._latlng.lat,
								lng: layer._latlng.lng,
								color: layer.options.icon.options.color,
								type: layer.options.icon.options.type,
								description: "",
							},
						},
					}),
				)
			}
		}
		map.on("pm:create", onCreate)
		const drawEnd = ({ shape }) => {
			const layers = map.pm.getGeomanDrawLayers(false) as any
			layers.forEach((layer) => {
				map.removeLayer(layer)
			})
			if (shape === "Marker") {
				return
			}
			const group = L.featureGroup()
			if (shape === "Circle") {
				group.addLayer(
					// @ts-ignore
					L.PM.Utils.circleToPolygon(layers[layers.length - 1]),
				)
			} else {
				group.addLayer(layers[0])
			}
			setTempDrawCouche(group)
		}
		map.on("pm:drawend", drawEnd)
		return () => {
			map.off("pm:create", onCreate)
			map.off("pm:drawend", drawEnd)
		}
	}, [selectedEvent, isCtrlPressed])

	useEffect(() => {
		const onResize = () => {
			map.invalidateSize()
		}
		window.addEventListener("resize", onResize)
		return () => window.removeEventListener("resize", onResize)
	}, [])

	const debounced = useCallback(
		_.debounce(() => {
			map.setZoom(15)
			dispatch(SynchroService.checkOnlineStatus())
		}, 250),
		[],
	)

	useEffect(() => {
		map.eachLayer((layer) => {
			layer.on("tileerror", (error) => {
				const z = error?.coords?.z
				if (z > 15) {
					debounced()
				}
			})
		})
	})

	const { online } = useNetworkState() // Browser
	const isOnline = useIsOnline() // Ping

	useEffect(() => {
		map.setMaxZoom(online && isOnline ? 18 : 15)
	}, [online, isOnline])

	return (
		<EasyPrintContext.Provider
			value={{
				easyPrint: currentEasyPrint,
				setEasyPrint: setCurrentEasyPrint,
			}}
		>
			{children}
		</EasyPrintContext.Provider>
	)
}

export default SuperMapContained
