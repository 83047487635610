import GetApp from "@mui/icons-material/GetApp"
import { CircularProgress, IconButton } from "@mui/material"
import PdfApi from "@/redux-toolkit/data/pdf/PdfApi"
import CustomTooltip from "utils/components/style/CustomTooltip"
import ErrorService from "@/services/ErrorService"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

const DownloadCell = ({ arreteId }) => {
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const downloadAndOpenPdf = async () => {
		setLoading(true)
		try {
			const uuid = await PdfApi.generate(arreteId)
			const uri = await PdfApi.waitArreteGeneration(uuid)
			window.open(uri)
		} catch (e) {
			ErrorService.error({
				component: "TableArrete:download",
				message: "L'arrêté n'a pas pu être téléchargé",
				dispatch,
			})
		}
		setLoading(false)
	}

	return (
		<span
			onClick={() => {
				if (!loading) downloadAndOpenPdf()
			}}
			className="center"
		>
			<CustomTooltip title="Télécharger">
				<IconButton aria-label="Delete">
					{loading ? (
						<CircularProgress size={24} />
					) : (
						<GetApp style={{ fontSize: 24 }} />
					)}
				</IconButton>
			</CustomTooltip>
		</span>
	)
}
export default DownloadCell
