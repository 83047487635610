import AnnouncementIcon from "@mui/icons-material/Announcement"
import CloseIcon from "@mui/icons-material/Close"
import { Button, IconButton } from "@mui/material"
import Divider from "@mui/material/Divider"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import SignalService from "@/pages/carto2/cartographie/service/SignalService"
import Debug from "debug"
import React from "react"
import { useMap } from "react-leaflet"
import { useLocalStorage } from "react-use"
import styled from "styled-components"
import PersistentCustomTabs from "utils/components/customTabs/PersistentCustomTabs"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
import ButtonPopover from "../ButtonPopover"
import ColorManager from "./ColorManager"
import StyledPaper from "./StyledButton"
// app:javascript:components:maincourante:subComponents:bottomCenterControl:SignalControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:SignalControl",
)

const GreyButton = styled(Button)`
    color: initial !important;
    width: 100px;
`

debug.log = console.log.bind(console)
const StyledButton = styled(Button)`
    padding: 0 !important;
`

const Container = styled.div`
    padding: 0.5rem;
    max-width: 800px;
    min-height: 400px;
`

const StyledFlex = styled(Flex)`
    align-items: flex-start;
    width: 100% !important;
    justify-content: space-between;
`
const SignalContainer = styled(Flex)`
    max-height: 300px !important;
    width: 500px !important;
    overflow: auto;
`
const StyledDivider = styled(Divider)`
    width: 100% !important;
`
const SignalControl = () => {
	const [color, setColor] = useLocalStorage("SignalControl:color", "#d10000")
	const map = useMap()
	return (
		<>
			<ButtonPopover
				popoverContent={({ handleClose }) => (
					<Container>
						<Flex gap="1rem" directionColumn fullWidth>
							<StyledFlex fullWidth>
								<div>
									<h5>Signalétique</h5>
								</div>

								<Flex directionColumn alignItemsEnd>
									<IconButton onClick={handleClose}>
										<CloseIcon />
									</IconButton>
									<Flex gap="1rem">
										<ColorManager color={color} setColor={setColor} />
									</Flex>
								</Flex>
							</StyledFlex>

							<SignalContainer gap="0.5rem" directionColumn>
								<PersistentCustomTabs
									localStorageKey="signalControl"
									tabChildrens={Object.keys(SignalService.signalCategory).map(
										(signalCategory) => ({
											key: signalCategory,
											render: (
												<>
													<Flex
														key={signalCategory}
														directionColumn
														gap="0.5rem"
														alignItemsStart
														fullWidth
													>
														<StyledDivider />
														<Flex gap="1rem" $wrap fullWidth>
															{SignalService.signalCategory[signalCategory].map(
																(signalElementTitle) => (
																	<GreyButton
																		key={signalElementTitle}
																		onClick={() => {
																			handleClose()
																			MapService.drawMarker({
																				color,
																				type: signalElementTitle,
																				currentMap: map,
																			})
																		}}
																	>
																		<Flex directionColumn>
																			{SignalService.signalMapping[
																				signalElementTitle
																			](color)}
																			{signalElementTitle}
																		</Flex>
																	</GreyButton>
																),
															)}
														</Flex>
													</Flex>
												</>
											),
										}),
									)}
								/>
							</SignalContainer>
						</Flex>
					</Container>
				)}
			>
				{({ handleClick }) => (
					<StyledButton onClick={handleClick}>
						<StyledPaper>
							<Flex directionColumn>
								<AnnouncementIcon />
								<div>Signalétique</div>
							</Flex>
						</StyledPaper>
					</StyledButton>
				)}
			</ButtonPopover>
		</>
	)
}

export default SignalControl
