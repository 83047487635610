import React, { useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import Debug from "debug"
import SuperModal from "@/utils/modals/SuperModal"
import usePoints from "@/hooks/usePoints"
import PcsData from "@/pages/customdata/pcsData/PcsData"
import getImage from "@/utils/getImage"
import { Flex } from "@/utils/components/style/flex"
import styled from "styled-components"
import { Alert } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { updatePoint } from "@/redux-toolkit/data/points/resources"
import { useParams } from "react-router-dom"

const Title = styled.div`
    font-size: 20px;
`

// app:javascript:components:maincourante:suivi:ModalListSuiviImport.tsx
const debug = Debug(
	"app:javascript:components:maincourante:suivi:ModalListSuiviImport",
)
debug.log = console.log.bind(console)

const ModalListSuiviImport = ({ isOpen, onClose }) => {
	const { facilityId: facilityIdParams } = useParams<{
		facilityId: string
	}>()
	const facilityId = facilityIdParams === "undefined" ? undefined : facilityIdParams
	const [pcsCategory, setPcsCategory] = useState(undefined)
	const points = usePoints()
	const dispatch = useDispatch()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const concernedPoints = useMemo(() => {
		return Object.values(points).filter((point) => {
			return point.jsonschema_id === pcsCategory?.id
		})
	}, [pcsCategory])

	const onValidateSuiviCreation = () => {
		dispatch(
			updatePoint({
				...points[facilityId],
				geojson: {
					properties: {
						...points[facilityId].geojson.properties,
						suiviJsonSchemaActivated: [
							...(points[facilityId].geojson.properties?.suiviJsonSchemaActivated ?? []),
							pcsCategory.id,
						]
					}
				}
			})
		)
		onClose()
		setPcsCategory(undefined)
	}

	return (
		<>
			<SuperModal
				title={
					!pcsCategory ? (
						"Selectionner une catégorie"
					) : (
						<Flex gap=".5em">
							<img
								src={getImage(jsonSchemasWithStatics[pcsCategory?.id]?.imgId)}
								alt="[Icône]"
								width={35}
							/>
							<Title>{pcsCategory.title}</Title>
						</Flex>
					)
				}
				isOpen={isOpen}
				onClose={() => {
					onClose()
					setPcsCategory(undefined)
				}}
				onClick={onValidateSuiviCreation}
				disableSave={!pcsCategory}
			>
				{!pcsCategory && <PcsData setPcsCategory={setPcsCategory} />}
				{pcsCategory && (
					<Alert severity="info">
						<div>
							La catégorie <b>{pcsCategory.title}</b> est sur le point
							d&apos;être importée.
						</div>
						<div>Cela va créer {concernedPoints.length} nouveaux suivis.</div>
					</Alert>
				)}
			</SuperModal>
		</>
	)
}

export default ModalListSuiviImport
