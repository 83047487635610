import React, { useEffect, useState } from "react"
import FormStep from "@/pages/telechargement/Upload/component/FormStep"
import useStep from "@/pages/telechargement/Upload/hooks/useStep"
import { Flex } from "utils/components/style/flex"
import usePoints from "@/hooks/usePoints"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook/MarkerDaybook"
import useMapState from "@/hooks/useMapState"
import { Pane, GeoJSON } from "react-leaflet"
import getPolygonStyle from "utils/components/map/polygon/getPolygonStyle"
import DatabaseService from "@/services/DatabaseService"
import CartographyPreview from "@/pages/carto2/cartographie/CartographyPreview"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_POINT } from "utils/panels/PanelCarto"
import { useDispatch } from "react-redux"

const StepViewPosition = ({ methods, setTempValue, tempValue, ...props }) => {
	const points = usePoints()
	const dispatch = useDispatch()
	const multiPolygons = useMultiPolygons()
	const { surfaceOrder } = useMapState()
	const [multiPolygonsToDisplay, setMultiPolygonsToDisplay] = useState([])
	const { value, onBack } = useStep("viewParams")
	methods.watch("layer")
	const selectedLayer = methods.getValues("layer")
	methods.watch("datas")
	const datas = methods.getValues("datas")
	const pointsKeys = datas.pointsKeys?.map((obj) => obj.id)
	const couchesKeys = datas.couchesKeys?.map((obj) => obj.id)

	const updateMapViewLocation = (newProps) => {
		const newObj = {
			...tempValue,
			...newProps,
		}
		setTempValue(newObj)
	}

	useEffect(() => {
		const processMultiPolygon = async () => {
			const multiPolygonToRender = Object.values(multiPolygons).filter(
				(polygon: any) => couchesKeys?.includes(polygon.id),
			)
			const geo = []
			await Promise.all(
				multiPolygonToRender.map(async (vcouche: any) => {
					const data = await DatabaseService.get(vcouche.id)
					if (data) {
						geo.push({
							...vcouche,
							geojson: data,
						})
					}
					return
				}),
			)
			setMultiPolygonsToDisplay(geo)
		}
		processMultiPolygon()
	}, [multiPolygons, datas.couchesKeys])

	return (
		<FormStep
			question=""
			formatedValue=""
			onBack={onBack}
			clearStyles
			{...props}
		>
			<Flex fullWidth fullHeight gap=".5em" directionColumn alignItemsCenter>
				<CartographyPreview
					layer={selectedLayer}
					viewPosition={value}
					setMapEventEndValue={updateMapViewLocation}
					mapDimensions={{ width: "75%", height: "400px" }}
					disableZoomControl={false}
				>
					<>
						{Object.values(points).map((point) => {
							if (pointsKeys?.includes(point.jsonschema_id)) {
								return (
									<MarkerDaybook
										key={point.id}
										point={point}
										handleClick={() =>
											dispatch(
												setPanel({
													type: DAYBOOK_POINT,
													id: point.id,
												}),
											)
										}
										noInteraction
									/>
								)
							}
						})}

						{multiPolygonsToDisplay.map((elem) => {
							const zIndex = 500 - surfaceOrder.indexOf(elem.id)
							return (
								<Pane
									name={elem.id}
									key={`${elem.id}:${zIndex.toString()}`}
									style={{
										zIndex: zIndex,
									}}
								>
									<GeoJSON
										key={elem.id}
										data={JSON.parse(elem.geojson)}
										style={() => getPolygonStyle(elem)}
									/>
								</Pane>
							)
						})}
					</>
				</CartographyPreview>
			</Flex>
		</FormStep>
	)
}

export default StepViewPosition
