import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectCdcGeojson = createDeepEqualSelector(
	(state: any) => state.cdc?.infos?.geojson,
	(item: any) => item,
)

const useCdcGeojson = () => {
	return useSelector(selectCdcGeojson)
}

export default useCdcGeojson
