import styled from "styled-components"

export const Spacer = styled.div<{ size: number; direction: string }>`
    ${({ size, direction }) => {
			if (direction === "horizontal") {
				return `width: ${size}rem;`
			}
			return `height: ${size}rem;`
		}}
`
