import EditIcon from "@mui/icons-material/Edit"
import {
	CardHeader,
	Chip,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { useDispatch } from "react-redux"
import { StyledChip, StyledChipWithPadding } from "../style/chip"
import { Flex } from "../style/flex"
import LightTooltip from "../../../styles/atoms/Tooltip/LightTooltip/LightTooltip"
import Avatar from "@/styles/atoms/Avatar"
import textToNumber from "@/utils/conversions/textToNumber"

function StockChip({ stock, editable = true, highlight = false }) {
	const dispatch = useDispatch()

	return (
		<LightTooltip
			arrow
			title={
				<>
					<CardHeader
						title={stock.geojson.properties["Nature"]}
						action={
							editable && (
								<IconButton onClick={() => dispatch(setCurrentPoint(stock))}>
									<EditIcon />
								</IconButton>
							)
						}
					/>

					<Table>
						<TableBody>
							<TableRow>
								<TableCell>Quantité</TableCell>
								<TableCell align="right">
									{stock.geojson.properties["Quantité"]}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Tags</TableCell>
								<TableCell align="right">
									<Flex wrap gap="5px" flexEnd>
										{stock.geojson.properties["Tags"].map((tag) => (
											<StyledChipWithPadding key={tag} label={tag} />
										))}
									</Flex>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</>
			}
		>
			<StyledChip
				style={{ maxWidth: "100%" }}
				avatar={
					<Avatar
						$textColor={highlight ? "var(--blue800)" : undefined}
						$backgroundColor={highlight ? "var(--blue100)" : undefined}
					>
						{textToNumber(stock.geojson.properties["Quantité"])}
					</Avatar>
				}
				variant="outlined"
				label={stock.geojson.properties["Nature"]}
				$colorProperty={highlight ? "var(--blue800)" : undefined}
			/>
		</LightTooltip>
	)
}

export default StockChip
