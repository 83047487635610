import React from "react"
import LightTooltip from "../../LightTooltip"
import HumanTooltipInfos from "@/utils/components/tooltip/HumanToooltipInfos"

const HumanTooltip = ({
	human,
	children,
	writePermission = false,
	enterDelay = 300,
	enterNextDelay = 300,
}) => {
	return (
		<LightTooltip
			placement="left"
			enterDelay={enterDelay}
			enterNextDelay={enterNextDelay}
			arrow
			title={
				<div onClick={(event) => event.stopPropagation()}>
					<HumanTooltipInfos human={human} writePermission={writePermission} />
				</div>
			}
		>
			{children}
		</LightTooltip>
	)
}

export default HumanTooltip
