import {
	Alert,
	CardActions,
	IconButton,
	ListItemAvatar,
	ListItemText,
	Tooltip,
} from "@mui/material"
import React from "react"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import DeleteIcon from "@mui/icons-material/Delete"
import {
	deleteCarto,
	updateCarto,
} from "@/redux-toolkit/data/uploadedCarto/resources"
import { useDispatch } from "react-redux"
import ConfirmationModal from "utils/components/ConfirmationModal"
import { useBoolean } from "react-use"
import { toast } from "react-toastify"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import WarningIcon from "@mui/icons-material/Warning"
import TileLayerService from "utils/services/TileLayerService"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import EditIcon from "@mui/icons-material/Edit"
import IUploadedCarto from "utils/types/IUploadedCarto"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { CARTO_CONFIG_INFO } from "./PanelCarto"
import UpdateIcon from "@mui/icons-material/Update"
import _ from "lodash"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_CARTOGRAPHY } from "@/redux-toolkit/userSettings/constants"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const SCardActions = styled(CardActions)`
    transition: all 0.5s ease-in-out;
    max-height: 0px;
    padding: 0px !important;
    padding-left: 0.5rem !important;
    opacity: 0;
    & > * {
        transition: opacity 0.5s ease-in-out;
        opacity: 0 !important;
    }
`
const StyledCard = styled.div<{ $noMargin: boolean }>`
    background-color: white;
    box-shadow: var(--cool-shadow) !important;
    padding: 0px !important;
    ${({ $noMargin }) =>
			!$noMargin &&
			css`
            margin: 1rem 1.5rem;
        `}
    &:hover {
        ${SCardActions} {
            
            transition all 0.3s ease-in-out;
            transition-delay: 0.2s;
            overflow: hidden;
            max-height: 100vh;
            padding: 0 0.5rem !important;
            opacity: 1;
            border-top: 1px solid var(--neutral300);
            & > * {
                opacity: 1 !important;
            }
        }
    }
`
const SIconButton = styled(IconButton)`
    padding: 0.5rem;
`
const DescriptionContainer = styled(Flex)`
    padding: 0.5rem;
`
const SListItemAvatar = styled(ListItemAvatar)`
    display: flex;
    align-items: center;
`

const StyledListItemText = styled(ListItemText)<{ $errorColor: boolean }>`
    & .MuiListItemText-primary {
        color: ${({ $errorColor }) =>
					$errorColor ? "#d32f2f" : "var(--primary800)"};
    }
    .MuiListItemText-secondary {
        font-size: var(--font-size-small);
    }
`
const SWarningIcon = styled(WarningIcon)`
    width: 32px !important;
    height: 32px !important;
`
const SAlert = styled(Alert)`
    margin: 4px 0 !important;
    padding: 0.5rem !important;
    & > .MuiAlert-message {
        padding: 0 !important;
        font-size: 14px !important;
    }
`

const PanelPresetsItem = ({
	carto,
	subtitle = undefined,
	avatar,
	onEdit,
	onClick,
	noMargin = false,
	isEditable = true,
	isDeletable = true,
}: {
	carto: IUploadedCarto
	subtitle?: string
	avatar: React.ReactNode
	onEdit: () => void
	onClick: () => void
	noMargin?: boolean
	isEditable?: boolean
	isDeletable?: boolean
}) => {
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useBoolean(false)
	const jsonSchemas = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const permissions = useAccessRights(EnumToolPath.CD_CARTOGRAPHY)

	const hasElementsMissing = () => {
		const pointsKeys = carto.pointsKeys
		const couchesKeys = carto.couchesKeys
		let error = false

		if (!TileLayerService.getAllLayers()[carto.layerType]) error = true
		pointsKeys.forEach((obj) => {
			if (error) return
			if (!jsonSchemas[obj.id]) {
				error = true
			}
		})
		couchesKeys.forEach((obj) => {
			if (error) return
			if (!multiPolygons[obj.id]) {
				error = true
			}
		})

		return error
	}

	const autoConfigUpdate = async (carto) => {
		const newValues = {
			idToReplace: carto.id,
			name: carto.configName,
			datas: {},
			layer: undefined,
			location: carto.config.pcs,
		}

		if (TileLayerService.getAllLayers()[carto.layerType]) {
			newValues["layer"] = carto.layerType
		} else {
			newValues["layer"] = "Osm"
		}
		newValues["datas"]["pointsKeys"] = carto.pointsKeys.filter(
			(obj) => jsonSchemas[obj.id],
		)
		newValues["datas"]["couchesKeys"] = carto.couchesKeys.filter(
			(obj) => multiPolygons[obj.id],
		)

		//Removing some keys to get same edition object format to provide
		const {
			configName,
			config,
			pointsKeys,
			couchesKeys,
			layerType,
			...updatedCarto
		} = {
			...carto,
			...newValues,
		}

		await dispatch(updateCarto(updatedCarto))
		toast.success(
			"Les données innexistantes ont été retirées de l'enregistrement",
		)
	}

	return (
		<>
			<StyledCard onClick={() => onClick()} $noMargin={noMargin}>
				<DescriptionContainer fullWidth spaceBetween>
					<Flex fullWidth spaceBetween>
						<Flex>
							<SListItemAvatar>
								{hasElementsMissing() ? <SWarningIcon color="error" /> : avatar}
							</SListItemAvatar>
							<StyledListItemText
								primary={carto.configName}
								secondary={subtitle}
								$errorColor={hasElementsMissing()}
							/>
						</Flex>
					</Flex>
				</DescriptionContainer>
				<SCardActions>
					{hasElementsMissing() && (
						<Flex fullWidth gap=".5em">
							<SAlert severity="error">
								Cet enregistrement possède des données innexistantes
							</SAlert>
							{permissions.write && (
								<Tooltip title="Mettre à jour" arrow>
									<IconButton onClick={() => autoConfigUpdate(carto)}>
										<UpdateIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</Flex>
					)}
					{!hasElementsMissing() && (
						<Flex fullWidth>
							<Tooltip title="Détails" arrow>
								<SIconButton
									onClick={(e) => {
										dispatch(
											setPanel({
												type: CARTO_CONFIG_INFO,
												configId: carto.id,
											}),
										)
										e.stopPropagation()
									}}
								>
									<HelpOutlineIcon fontSize="small" />
								</SIconButton>
							</Tooltip>
							{isEditable && (
								<Tooltip title="Modifier" arrow>
									<IconButton
										onClick={() => {
											onEdit()
										}}
									>
										<EditIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
							{isDeletable && (
								<Tooltip title="Supprimer" arrow>
									<IconButton
										onClick={(e) => {
											setIsOpen(true)
											e.stopPropagation()
										}}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</Flex>
					)}
				</SCardActions>
			</StyledCard>
			<ConfirmationModal
				modalShown={isOpen}
				toggler={() => setIsOpen(false)}
				message={`Voulez-vous vraiment supprimer le préréglage "${carto.configName}" ?`}
				onConfirm={() => {
					dispatch(deleteCarto(carto.id))
					toast.info("La cartographie a bien été supprimée.")
				}}
			/>
		</>
	)
}

export default PanelPresetsItem
