import moment from "moment"
import React from "react"
import PH4 from "utils/pdf/header/PH4"
import NormalText from "utils/pdf/NormalText"
import PCell from "utils/pdf/table/PCell"
import PRow from "utils/pdf/table/PRow"
import PTable from "utils/pdf/table/PTable"
import HumanService from "utils/services/HumanService"

const StandardTableRGPD = ({ commune, responsableRgpd }) => {
	return (
		<PTable>
			{[
				{
					label: "Date de dernière mise à jour de la fiche",
					value: moment().format("DD/MM/YYYY"),
				},
				{
					label: "Nom du responsable conjoint du traitement",
					value: `Amaury Fischer (Numérisk), ${HumanService.displayFullName(
						responsableRgpd,
					)} (${commune.name})`,
				},
				{ label: "Nom de l'application web", value: "Numérisk PCS" },
			].map((item, index) => (
				<PRow key={item.label}>
					<PCell head minHeight={40}>
						<PH4>{item.label}</PH4>
					</PCell>
					<PCell minHeight={40} borderBottom={index < 2}>
						<NormalText>{item.value}</NormalText>
					</PCell>
				</PRow>
			))}
		</PTable>
	)
}
export default StandardTableRGPD
