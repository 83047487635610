import ArrowBack from "@mui/icons-material/ArrowBack"
import { Alert } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import useUploadedCartos from "@/hooks/useUploadedCartos"
import { setPanel } from "@/redux-toolkit/common/reducer"
import useUploadLocation from "@/pages/telechargement/Upload/hooks/useUploadLocation"
import _ from "lodash"
import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { H6 } from "utils/components/style/header"
import getImage from "@/utils/getImage"
import TileLayerService from "utils/services/TileLayerService"
import { CARTO_CONFIGS } from "../PanelCarto"

const SPanelHead = styled(Flex)`
    width: 100%;
    padding: 1rem !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`
const Divider = styled.div`
    width: 100%;
    margin: 0 0.5em;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`
const SFlex = styled(Flex)`
    padding: 1rem;
`
const SAlert = styled(Alert)`
    margin: 1rem;
`

const PanelCartoConfigInfos = ({ configId }) => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const { getUploadLocation } = useUploadLocation()
	const dispatch = useDispatch()
	const carto = useUploadedCartos()[configId]

	return (
		<Flex fullWidth fullHeight directionColumn>
			<SPanelHead>
				<PrimaryOutlinedButton
					startIcon={<ArrowBack />}
					onClick={() => dispatch(setPanel({ type: CARTO_CONFIGS }))}
				>
					Retour aux cartes
				</PrimaryOutlinedButton>
				<H6>{carto.configName}</H6>
			</SPanelHead>
			<Divider />
			<SFlex fullWidth directionColumn alignItemsInitial gap=".5em">
				<H6>Catégorie :</H6>
				<div>{carto.category ? carto.category : "Non catégorisée"}</div>
			</SFlex>
			{!_.isEmpty(carto.pointsKeys) && (
				<>
					<Divider />
					<SFlex fullWidth directionColumn alignItemsInitial gap=".5em">
						<H6>Point(s) :</H6>
						<Flex fullWidth directionColumn alignItemsInitial gap=".5em">
							{carto.pointsKeys.map((obj) => (
								<Flex key={obj.id} gap=".5em">
									{jsonSchemas[obj.id] && (
										<>
											<img
												src={getImage(jsonSchemas[obj.id]?.imgId)}
												width={35}
												height={35}
											/>
											<div>{jsonSchemas[obj.id].title}</div>
										</>
									)}
								</Flex>
							))}
						</Flex>
					</SFlex>
				</>
			)}
			{!_.isEmpty(carto.couchesKeys) && (
				<>
					<Divider />
					<SFlex fullWidth directionColumn alignItemsInitial gap=".5em">
						<H6>Couche(s) :</H6>
						<Flex fullWidth directionColumn alignItemsInitial gap=".5em">
							{carto.couchesKeys.map((obj) => (
								<Flex key={obj.id} gap=".5em">
									{multiPolygons[obj.id] && (
										<>
											<MultiPolygonColorSquare
												multiPolygon={multiPolygons[obj.id]}
											/>
											<div>
												{multiPolygons[obj.id].name ??
													"[ERREUR, COUCHE SUPPRIMÉE !]"}
											</div>
										</>
									)}
								</Flex>
							))}
						</Flex>
					</SFlex>
				</>
			)}
			{_.isEmpty(carto.pointsKeys) && _.isEmpty(carto.couchesKeys) && (
				<>
					<Divider />
					<SAlert severity="info">
						La cartographie ne contient aucune donnée affichable.
					</SAlert>
				</>
			)}
			<Divider />
			<SFlex fullWidth directionColumn alignItemsInitial gap=".5em">
				<H6>Fond cartographique :</H6>
				{TileLayerService.getAllLayers()[carto.layerType] && (
					<div>{carto.layerType}</div>
				)}
			</SFlex>
			<Divider />
			<SFlex fullWidth directionColumn alignItemsInitial gap=".5em">
				<H6>Emplacement dans le PCS :</H6>
				{carto.config.pcs
					? getUploadLocation(carto.config)
					: "Non intégrée au PCS"}
			</SFlex>
		</Flex>
	)
}

export default PanelCartoConfigInfos
