import { diff } from "deep-object-diff"
import _ from "lodash"
import moment from "moment"
import ErrorService from "@/services/ErrorService"
import ILocal, { LOCAL_STATUS } from "@/utils/types/ILocal"
import { v4 as uuidv4 } from "uuid"

const getPathAndValueFromDiff = ({
	diffAB,
	key,
	path,
	lodashSetDiff,
	initialObject,
}) => {
	const newPath = [...path, key]
	const newValue = _.get(diffAB, newPath)
	if (_.isObject(newValue)) {
		Object.keys(newValue).forEach((key) => {
			getPathAndValueFromDiff({
				diffAB,
				key,
				path: newPath,
				lodashSetDiff,
				initialObject,
			})
		})
		return
	}
	if (_.isArray(newValue)) {
		newValue.forEach((item, index) => {
			getPathAndValueFromDiff({
				diffAB,
				key: index,
				path: newPath,
				lodashSetDiff,
				initialObject,
			})
			return
		})
	}

	lodashSetDiff.push({
		path: newPath,
		value: newValue,
		oldValue: _.get(initialObject, newPath),
	})
	return
}

const diffMaker: (
	objA: object,
	objB: object,
) => { path: string[]; value: any }[] = (objA, objB) => {
	const diffAB = diff(objA, objB)

	let lodashSetDiff = []
	Object.keys(diffAB).forEach((key) => {
		getPathAndValueFromDiff({
			diffAB,
			key,
			path: [],
			lodashSetDiff,
			initialObject: objA,
		})
	})
	return lodashSetDiff
}

const replace = (state, { payload: data }) => {
	state[data.id] = data
}
export interface IElementWithId extends ILocal {
	id: string
	deleted?: boolean
	commune_id?: string
}

const addWithMerge = <T extends IElementWithId>(
	state: { [id: string]: T },
	{ payload: data }: { payload: T },
) => {
	if (state[data.id] && state[data.id].updated_at === data.updated_at) {
		return
	}
	// ? if state[data.id].localStatus === UPDATED we want to merge new data with old data
	if (state[data.id] && state[data.id].localStatus === LOCAL_STATUS.UPDATED) {
		// ? pulled data from server differs from local data
		const clonedData = { ...data }
		state[data.id].localModifications.forEach((modification) => {
			// ? we apply modifications made locally
			if (
				_.isEqual(_.get(clonedData, modification.path), modification.oldValue)
			) {
				_.set(clonedData, modification.path, modification.value)
			} else {
				if (
					!_.isEqual(_.get(clonedData, modification.path), modification.value)
				) {
					if (_.isEqual(modification.path, ['updated_at'])) {
						return
					}
					console.log(
						"Modification because",
						state[data.id].updated_at,
						"and",
						data.updated_at,
					)
					console.log(
						"Local modification",
						JSON.parse(JSON.stringify(modification)),
					)
					console.log(
						"local modifications",
						JSON.parse(JSON.stringify(state[data.id].localModifications)),
					)
					// ! 🐛 if modification is not applied, log error and ignore local modification (server as priority)
					ErrorService.error({
						component: "addWithMerge",
						message: `Conflict on data ${JSON.stringify(
							data,
						)} and modification ${JSON.stringify(modification)}`,
						silence: true,
						dispatch: undefined,
					})
				}
			}
		})

		// ? save merged data in state, and keep localModifications in case we pull again new data before pushing
		state[data.id] = {
			...clonedData,
			localStatus: LOCAL_STATUS.UPDATED,
			localModifications: state[data.id].localModifications,
		}
		return
	} else if (
		state[data.id] &&
		state[data.id].localStatus === LOCAL_STATUS.DELETED
	) {
		// ? pulled data from server differs from local data
		if (data.deleted) {
			// ? event was deleted on server and on local
			delete state[data.id]
			return
		} else {
			// ? event was deleted on local but not on server
			state[data.id] = data
			state[data.id].localStatus = LOCAL_STATUS.UPDATED
		}
		return
	} else if (
		state[data.id] &&
		state[data.id].localStatus === LOCAL_STATUS.CREATED
	) {
		console.error("not supposed to happen")
		return
	}
	state[data.id] = data
}

const add = <T extends IElementWithId>(
	state: any,
	{ payload: data }: { payload: T },
) => {
	state[data.id] = data
}

const remove = (state: any, { payload: id }: { payload: string }) => {
	delete state[id]
}
const createLocal = <T extends IElementWithId>(
	state: any,
	{ payload: data }: { payload: T },
) => {
	const dataId = data?.id ?? uuidv4()
	const created_at = moment().format()
	state[dataId] = {
		...data,
		id: dataId,
		updated_at: created_at,
		created_at,
		localStatus: LOCAL_STATUS.CREATED,
	}
}

const updateLocal = <T extends IElementWithId>(
	state: any,
	{ payload: data }: { payload: T },
) => {
	// ? current is for debugger only as inspecting state[data.id] is not possible => show a proxy
	// const current = JSON.parse(JSON.stringify(state[data.id]))

	const {
		localStatus: _localStatus,
		localModifications: _localModifications,
		...dataOnly
	} = data

	const {
		localStatus: _localStatusCurrent,
		localModifications: localModificationsCurrent,
		...currentDataOnly
	} = state[data.id]

	// ! we store modifications made
	const diffMakerResult = diffMaker(currentDataOnly, dataOnly)
	state[data.id] = {
		...data,
		updated_at: moment().format(),
		localStatus:
			data?.localStatus === LOCAL_STATUS.CREATED
				? LOCAL_STATUS.CREATED
				: LOCAL_STATUS.UPDATED,
		localModifications: [
			// ! if modified data locally and offline are again modified, we store all changes and concat them
			...(data?.localModifications ?? []),
			...diffMakerResult,
		],
	}
}

const deleteLocal = <T extends IElementWithId>(
	state: any,
	{ payload: data }: { payload: T },
) => {
	if (data.localStatus === LOCAL_STATUS.CREATED) delete state[data.id]
	else state[data.id].localStatus = LOCAL_STATUS.DELETED
}

const many = (func) => (state, { payload: data }) => {
	data.forEach((item) => {
		func(state, { payload: item })
	})
}

export default {
	addWithMerge,
	add,
	remove,
	createLocal,
	updateLocal,
	deleteLocal,
	many,
	replace,
}
