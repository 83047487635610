import useAllStocks from "@/hooks/useAllStocks"
import React from "react"
import StockChip from "utils/components/chip/StockChip"
import DisplayStockplaceItems from "./subComponents/DisplayStockplaceItems"
import IPoint from "utils/types/IPoint"

function DisplayStocks({
	point,
	editable,
	align,
}: {
	point: IPoint
	editable: boolean
	align: "right" | "left"
}) {
	const stocks = useAllStocks()

	return (
		<DisplayStockplaceItems
			allItems={Object.values(stocks)}
			stockPlace={point}
			align={align}
		>
			{({ item, highlight }) => (
				<StockChip
					editable={editable}
					key={item.id}
					stock={item}
					highlight={highlight}
				/>
			)}
		</DisplayStockplaceItems>
	)
}

export default DisplayStocks
