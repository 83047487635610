import {
	Autocomplete,
	Checkbox,
	Chip,
	createFilterOptions,
	ListSubheader,
	TextField,
} from "@mui/material"
import { useOrgaWithOrder } from "@/hooks/useOrga"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import colored from "utils/colored"
import CelluleChip from "utils/components/chip/CelluleChip"
import { Flex } from "utils/components/style/flex"
import VerticalAutocomplete from "utils/components/VerticalAutocomplete"
import { ORGA_COLOR } from "utils/types/IOrga"
import SelectAllIcon from "@mui/icons-material/SelectAll"
import DeselectIcon from "@mui/icons-material/Deselect"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import styled from "styled-components"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const ColoredChip = colored(Chip)

const SListSubheader = styled(ListSubheader)`
    line-height: initial;
    padding: 0 !important;
    padding-left: 8px !important;
    margin: 0px !important;
`
const StyledLi = styled.li`
    padding: 0 !important;
    margin-left: 3px;
`
const StyledAddCell = styled.div`
    color: var(--neutral400);
    margin: 8px;
`
const filter = createFilterOptions()

const FCellules = ({ name, label, small = false, disabled = false }) => {
	const { cellules, order } = useOrgaWithOrder()
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: [],
	})

	const allSelected = value.length === order.length

	const getOptionLabel = (option) =>
		cellules[option]?.idName ?? "Cellule inexistante"

	return (
		<Autocomplete
			disabled={disabled}
			disableCloseOnSelect
			multiple
			fullWidth
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder="Sélectionnez une ou plusieurs cellules"
					error={Boolean(error)}
					helperText={error?.message}
					size={small ? "small" : undefined}
					disabled={disabled}
				/>
			)}
			options={order}
			filterOptions={(options, params) => {
				const filtered = filter(options, params)

				if (params.inputValue === "") {
					filtered.unshift(null)
				}

				return filtered
			}}
			value={order.filter((item) => value.includes(item))}
			onChange={(event, newValue: string[]) => {
				if (newValue.includes(null)) {
					allSelected ? onChange([]) : onChange(order)
				} else {
					onChange(order.filter((item) => newValue.includes(item)))
				}
			}}
			renderTags={(value, getTagProps) => (
				<>
					<Flex wrap fullWidth>
						{value.map((option: string, index) => (
							<CelluleChip
								key={option}
								cellule={cellules[option]}
								small
								{...getTagProps({ index })}
								disabled={disabled}
							/>
						))}
					</Flex>
				</>
			)}
			renderOption={(props, option, { selected }) => {
				return option === null ? (
					<li {...props} key={option}>
						{value.length === order.length ? (
							<Flex gap="5px">
								<DeselectIcon />
								<div>Tout désélectionner</div>
							</Flex>
						) : (
							<Flex gap="5px">
								<SelectAllIcon />
								<div>Tout sélectionner</div>
							</Flex>
						)}
					</li>
				) : (
					<StyledLi {...props}>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							checked={selected}
						/>
						{getOptionLabel(option)}
					</StyledLi>
				)
			}}
			getOptionLabel={getOptionLabel}
			groupBy={(option: string) => cellules[option]?.category}
			renderGroup={(params) => {
				return (
					<>
						{params.group && (
							<SListSubheader>
								<ColoredChip
									size="small"
									$textColor="white"
									$backgroundColor={ORGA_COLOR[params.group]}
									label={params.group}
									disabled={disabled}
								/>
							</SListSubheader>
						)}
						{params.children}
					</>
				)
			}}
		/>
	)
}

export default FCellules
