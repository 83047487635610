import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory"
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp"
import CircleIcon from "@mui/icons-material/Circle"
import CloseIcon from "@mui/icons-material/Close"
import GestureIcon from "@mui/icons-material/Gesture"
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined"
import { Button, IconButton, TextField } from "@mui/material"
import { ModalContent } from "@nextui-org/react"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import Debug from "debug"
import React, { useEffect } from "react"
import { useMap } from "react-leaflet"
import styled from "styled-components"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
import useKeyboardJs from "utils/keyboard/useKeyboardJs"
import ButtonPopover from "../ButtonPopover"
import StyledPaper from "./StyledButton"
// app:javascript:components:maincourante:subComponents:bottomCenterControl:DrawControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:DrawControl",
)

const GreyButton = styled(Button)`
    color: initial !important;
`

debug.log = console.log.bind(console)
const StyledButton = styled(Button)`
    padding: 0 !important;
`

const Container = styled.div`
    padding: 0.5rem;
`

const StyledFlex = styled(Flex)`
    align-items: flex-start;
`

const DrawControl = () => {
	const map = useMap()
	const [isOpenModalRadius, setIsOpenModalRadius] = React.useState(false)
	const [radius, setRadius] = React.useState(0)
	return (
		<>
			<ButtonPopover
				popoverContent={({ handleClose }) => (
					<Container>
						<Flex gap="1rem" directionColumn>
							<StyledFlex>
								<div>
									<h5>Dessiner</h5>
									<Subtitle>
										Cliquez sur un élément pour commencer à dessiner
									</Subtitle>
								</div>
								<IconButton onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</StyledFlex>
							<Flex gap="0.5rem">
								<GreyButton
									onClick={() => {
										handleClose()
										MapService.drawCircle(map)
									}}
								>
									<Flex directionColumn>
										<CircleIcon />
										Cercle
									</Flex>
								</GreyButton>
								<GreyButton
									onClick={() => {
										handleClose()
										MapService.drawPolygon(map)
									}}
								>
									<Flex directionColumn>
										<ChangeHistoryIcon />
										Polygon
									</Flex>
								</GreyButton>
								<GreyButton
									onClick={() => {
										handleClose()
										MapService.drawRectangle(map)
									}}
								>
									<Flex directionColumn>
										<CheckBoxOutlineBlankSharpIcon />
										Rectangle
									</Flex>
								</GreyButton>
								<GreyButton
									onClick={() => {
										handleClose()
										MapService.drawLine(map)
									}}
								>
									<Flex directionColumn>
										<HorizontalRuleOutlinedIcon />
										Ligne
									</Flex>
								</GreyButton>
							</Flex>
							<Flex gap="0.5rem">
								<GreyButton
									onClick={() => {
										handleClose()
										setIsOpenModalRadius(true)
									}}
								>
									<Flex directionColumn>
										<CircleIcon />
										Cercle avec rayon fixe
									</Flex>
								</GreyButton>
							</Flex>
						</Flex>
					</Container>
				)}
			>
				{({ handleClick }) => (
					<StyledButton onClick={handleClick}>
						<StyledPaper>
							<Flex directionColumn>
								<GestureIcon />
								<div>Dessiner</div>
							</Flex>
						</StyledPaper>
					</StyledButton>
				)}
			</ButtonPopover>
			<Modal isOpen={isOpenModalRadius}>
				<ModalContent>
					<ModalHeader>Rayon du cercle</ModalHeader>
					<ModalBody>
						<TextField
							autoFocus
							label="Rayon en mètres"
							type="number"
							onChange={(e) => setRadius(parseInt(e.target.value))}
							fullWidth
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								setIsOpenModalRadius(false)
							}}
						>
							Annuler
						</Button>
						<PrimaryButton
							onClick={() => {
								map.pm.enableDraw("Circle", {
									// @ts-ignore
									swappable: true,
									snapDistance: 20,
									minRadiusCircle: radius,
									maxRadiusCircle: radius,
								})
								setIsOpenModalRadius(false)
							}}
						>
							Valider
						</PrimaryButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default DrawControl
