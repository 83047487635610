import BorderColor from "@mui/icons-material/BorderColor"
import FormatColorFill from "@mui/icons-material/FormatColorFill"
import LineWeight from "@mui/icons-material/LineWeight"
import { Slider, TextField } from "@mui/material"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"

export const Container = styled(Flex)`
    // left border
    width: 100%;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: var(--neutral200);
        z-index: 1;
    }
`
export const SFlex = styled(Flex)`
    max-width: 800px;
    width: 100%;
`
export const SFormatColorFill = styled(FormatColorFill)`
    background-color: white;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
`
export const SquareColor = styled.div<{ color: string }>`
    width: 50px;
    height: 20px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    cursor: pointer;
    padding: 0.25rem;
    margin: 0.25rem;
    border: 1px solid ${(props) => props.color};
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    &:hover {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }
`
export const SBorderColor = styled(BorderColor)`
    background-color: white;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
`
export const SLineWeight = styled(LineWeight)`
    background-color: white;
    padding: 5px;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
`
export const STextField = styled(TextField)`
    min-width: 90px !important;
`
export const SSlider = styled(Slider)`
    width: 100%;
    min-width: 200px;
    flex-grow: 1;
    margin-right: 10px;
    margin-left: 10px;
`
