import { useSelector } from "react-redux"
import React from "react"
import SynchroService from "@/services/SynchroService"
import IEvent from "utils/types/IEvent"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectEvents = createDeepEqualSelector(
	(state: any) => state.data.events,
	(events) => SynchroService.notDeleted(events),
)

const useEvents: () => { [key: string]: IEvent } = () => {
	return useSelector(selectEvents)
}

export default useEvents
