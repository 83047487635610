import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "../rootActions"

const initialState = {}

const communeSlice = createSlice({
	name: "commune",
	initialState,
	reducers: {
		setCommune(state, { payload: commune }) {
			return commune
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default communeSlice.reducer
export const { setCommune } = communeSlice.actions
