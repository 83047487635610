import Axios from "axios"
import ErrorService from "@/services/ErrorService"
import { IFileConfig } from "utils/types/IUploadedFile"

const uploadFile = async ({
	file,
	config,
}: {
	file: any
	config: IFileConfig
}) => {
	const formData = new FormData()
	formData.append("file", file)
	formData.append("config", JSON.stringify(config))

	const res = await Axios.post("/api/uploaded_files", formData, {
		headers: {
			"content-type": "multipart/form-data",
		},
	})

	return res.data
}

const updateUploadedFile = async (
	id: string,
	{
		file,
		config,
	}: {
		file?: any
		config?: IFileConfig
	},
) => {
	const formData = new FormData()

	if (file) formData.append("file", file)
	if (config) formData.append("config", JSON.stringify(config))

	const res = await Axios.put(`/api/uploaded_files/${id}`, formData, {
		headers: {
			"content-type": "multipart/form-data",
		},
	})

	return res.data
}

const getUploadedFiles = async () => {
	const res = await Axios.get("/api/uploaded_files")
	return res.data
}

const deleteUploadedFile = async (id) => {
	await Axios.delete(`/api/uploaded_files/${id}`)
}

export default {
	uploadFile,
	getUploadedFiles,
	deleteUploadedFile,
	updateUploadedFile,
}
