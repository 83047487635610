import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import HighlightAltIcon from "@mui/icons-material/HighlightAlt"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor"
import { Button, CircularProgress } from "@mui/material"
import { ModalContent } from "@nextui-org/react"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import Debug from "debug"
import L from "leaflet"
import Moment from "moment"
import React, { useEffect, useState } from "react"
import { useMap } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { useEasyPrint } from "@/utils/components/map/print/EasyPrintContext"
import { PrimaryOutlinedButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import ErrorService from "@/services/ErrorService"
import ButtonPopover from "../ButtonPopover"
import StyledPaper from "./StyledButton"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
// app:javascript:components:maincourante:subComponents:bottomCenterControl:EventControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:bottomCenterControl:EventControl",
)
debug.log = console.log.bind(console)

// https://guide-format.com/a0/#:~:text=La%20r%C3%A9solution%20en%20pixels%20d,14043%20pixels%20%C3%A0%20300%20dpi.
// A0 150 DPI
const A0_SIZE = {
	height: 4967,
	width: 7022,
}

const StyledButton = styled(Button)`
    padding: 0 !important;
`

const Container = styled.div`
    padding: 0.5rem;
`
const GreyButton = styled(Button)`
    color: initial !important;
`

const SDiv = styled.div`
    text-align: center;
    font-size: 1rem !important;
`

const PhotoControl = ({
	tempDrawCouche,
	setTempDrawCouche,
	isCaptureSelect,
	setIsCaptureSelect,
}) => {
	// @ts-ignore
	const communeGeojson = useSelector((state) => state.commune.geojson)
	const map = useMap()
	const { easyPrint } = useEasyPrint()
	// use context easy print
	const [generationLoading, setGenerationLoading] = useState(false)
	const [optionsModalOpen, setOptionsModalOpen] = useState(false)
	const [isA0Capture, setIsA0Capture] = useState(undefined)
	const dispatch = useDispatch()

	const toggleOptionsModal = () => setOptionsModalOpen(!optionsModalOpen)

	const onClickZoneSelect = () => {
		setIsCaptureSelect(true) //Boolean pour ne pas sauvegarder la zone sélectionnée comme une couche
		MapService.drawRectangle(map) //Utiliser l'outil de dessin pour sélectionner une zone
	}

	// Une fois la zone est sélectionnée, récupérer les bounds de la zone (tempDrawCouche)
	useEffect(() => {
		if (isCaptureSelect) {
			onClickPhoto(tempDrawCouche.getBounds())
		}
	}, [tempDrawCouche])

	const onClickPhoto = (bounds) => {
		map.setMaxZoom(25)
		setGenerationLoading(true)
		setTimeout(() => {
			easyPrint?.printMap({
				size: {
					width: isA0Capture ? A0_SIZE.width : map.getSize().x,
					height: isA0Capture ? A0_SIZE.height : map.getSize().y,
					bounds,
				},
				filename: `Cartographie - ${Moment().format("DD/MM/YYYY HH:mm:ss")}`,
				onFinished: ({ blob, error = undefined }) => {
					map.invalidateSize()
					setGenerationLoading(false)
					map.setMaxZoom(18)

					if (isCaptureSelect) {
						setIsCaptureSelect(false)
						setTempDrawCouche(undefined)
					}
					if (error) {
						const errorMessage = `Echec de la tentative de génération de capture d'écran : ${error}`
						ErrorService.error({
							component: "PhotoControl:printMap",
							message: errorMessage,
							dispatch,
						})
					}
				},
			})
		}, 1000)
	}

	return (
		<>
			<ButtonPopover
				popoverContent={({ handleClose }) => (
					<Container>
						<Flex gap="1rem" directionColumn>
							<Flex gap="0.5rem">
								<GreyButton
									onClick={() => {
										handleClose()
										setIsA0Capture(false)
										toggleOptionsModal()
									}}
								>
									<Flex directionColumn>
										<PhotoCameraIcon />
										<div>Capture d&apos;écran simple</div>
										<div>(format A4)</div>
									</Flex>
								</GreyButton>
								<GreyButton
									onClick={() => {
										handleClose()
										setIsA0Capture(true)
										toggleOptionsModal()
									}}
								>
									<Flex directionColumn>
										<PhotoCameraIcon />
										<div>Capture d&apos;écran lourde</div>
										<div>(format A0, 8-12 Mo)</div>
									</Flex>
								</GreyButton>
							</Flex>
						</Flex>
					</Container>
				)}
			>
				{({ handleClick }) => (
					<StyledButton onClick={handleClick}>
						<StyledPaper>
							<Flex directionColumn>
								<PhotoCameraIcon />
								<div>Capture d&apos;écran</div>
							</Flex>
						</StyledPaper>
					</StyledButton>
				)}
			</ButtonPopover>
			<Modal isOpen={generationLoading} small onClose={() => {}}>
				<ModalContent>
					<ModalHeader>Génération de la capture d&apos;écran...</ModalHeader>
					<ModalBody>
						<Flex justifyCenter directionColumn gap>
							<CircularProgress />
							<SDiv>
								Cette opération prend du temps à s&apos;exécuter. Patientez une
								bonne minute le temps que la capture s&apos;effectue.
							</SDiv>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Modal
				isOpen={optionsModalOpen}
				small
				onClose={() => setOptionsModalOpen(false)}
			>
				<ModalContent>
					<ModalHeader>
						{isA0Capture ? " Options de capture A0" : "Options de capture"}
					</ModalHeader>
					<ModalBody>
						<Flex directionColumn alignItemsStretch gap={"1rem"}>
							<PrimaryOutlinedButton
								startIcon={<OpenInFullIcon />}
								size="large"
								onClick={() => {
									toggleOptionsModal()
									onClickPhoto(L.geoJSON(communeGeojson).getBounds())
								}}
							>
								Toute la commune
							</PrimaryOutlinedButton>
							<PrimaryOutlinedButton
								startIcon={<ScreenshotMonitorIcon />}
								size="large"
								onClick={() => {
									toggleOptionsModal()
									onClickPhoto(map.getBounds())
								}}
							>
								Ecran actuel
							</PrimaryOutlinedButton>
							<PrimaryOutlinedButton
								startIcon={<HighlightAltIcon />}
								size="large"
								onClick={() => {
									toggleOptionsModal()
									onClickZoneSelect()
								}}
							>
								Sélectionner une zone
							</PrimaryOutlinedButton>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={toggleOptionsModal} />
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default PhotoControl
