import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"
import IOrga from "utils/types/IOrga"

const selectOrga = createDeepEqualSelector(
	(state: any) => state.commune.orga,
	(orga) => orga?.cellules ?? orga ?? {},
)

const selectOrgaWithOrder = createDeepEqualSelector(
	(state: any) => state.commune.orga,
	(orga) => ({
		order: orga?.order ?? [],
		cellules: orga?.cellules ?? orga ?? {},
	}),
)

const useOrga: () => IOrga = () => {
	return useSelector(selectOrga) ?? {}
}

export const useOrgaWithOrder = (): { cellules: IOrga; order: string[] } => {
	return useSelector(selectOrgaWithOrder)
}

export const useOrgaPoints = (): string[] => {
	const orga = useOrga()
	const pointIds = []
	Object.values(orga).forEach((cellule) => {
		[...(cellule?.membresIds ?? []), ...(cellule?.responsableIds ?? []), ...(cellule?.suppleantIds ?? [])].forEach((id) => {
			pointIds.push(id)
		})
	})
	return pointIds
}

export default useOrga
