/* eslint-disable react/no-unescaped-entities */
import { Button, Paper } from "@mui/material"
import Debug from "debug"
import React, { useContext } from "react"
import styled, { css } from "styled-components"
import MapIcon from "@mui/icons-material/Map"
import { Flex } from "@/utils/components/style/flex"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import RouterIcon from "@mui/icons-material/Router"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { useDispatch, useSelector } from "react-redux"
import {
	CARTO_CONFIGS,
	DAYBOOK_DATA,
	DAYBOOK_LEGEND,
	DAYBOOK_VORTEX,
} from "@/utils/panels/PanelCarto"
import { ScreenShotContext } from "./DaybookDashboard"
import { setPanel } from "@/redux-toolkit/common/reducer"
import useHasVortexStation from "@/hooks/useHasVortexStation"
import FeedIcon from '@mui/icons-material/Feed';

// app:javascript:components:maincourante:BottomCenterControl.tsx
const debug = Debug(
	"app:javascript:components:maincourante:BottomCenterControl",
)
debug.log = console.log.bind(console)
const StyledPaper = styled(Paper) <{ $isActive: boolean }>`
    width: 70px;
    background-color: var(--primary800) !important;
    color: white !important;
    padding: 0.5rem !important;
    border-radius: 0px;
    ${({ $isActive }) => {
		if ($isActive) {
			return css`
                background-image: linear-gradient(
                    to right,
                    #00a4f3 0%,
                    #80d2f9 100%
                ) !important;
                color: white !important;
            `
		}
	}}
`

const StyledButton = styled(Button)`
    padding: 0 !important;
    &:first-child {
        ${StyledPaper} {
            border-radius: 5px 0px 0px 0px !important;
        }
    }
    &:last-child {
        ${StyledPaper} {
            border-radius: 0px 0px 0px 5px !important;
        }
    }
`

const Control = styled.div`
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 0;
    z-index: 999;
`

const Control2 = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    right: 0;
    z-index: 1000;
`
const RightCenterControl = () => {
	const dispatch = useDispatch()
	const panel = useSelector((state: any) => state.common.panel)
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const hasVortexStation = useHasVortexStation()
	const isDaybookMap = window.location.pathname.includes("maincourante/carto")

	if (isTakingScreenshotMap) return null

	return (
		<div className="RightCenterControl">
			{panel && (
				<Control2>
					<StyledButton
						onClick={() => {
							dispatch(setPanel(null))
						}}
					>
						<StyledPaper $isActive>
							<Flex directionColumn>
								<ArrowForwardIosIcon fontSize="small" />
								<div>Réduire</div>
							</Flex>
						</StyledPaper>
					</StyledButton>
				</Control2>
			)}
			<Control>
				<StyledButton
					onClick={() => {
						if (panel?.type !== DAYBOOK_LEGEND) {
							dispatch(setPanel({ type: DAYBOOK_LEGEND }))
						} else {
							dispatch(setPanel(null))
						}
					}}
				>
					<StyledPaper $isActive={panel?.type === DAYBOOK_LEGEND}>
						<Flex directionColumn>
							<RemoveRedEyeIcon />
							<div>Légende</div>
						</Flex>
					</StyledPaper>
				</StyledButton>
				{/* <StyledButton
                    onClick={() => {
                        if (panel?.type !== DAYBOOK_DATA) {
                            dispatch(setPanel({ type: DAYBOOK_DATA }))
                        } else {
                            dispatch(setPanel(null))
                        }
                    }}
                >
                    <StyledPaper $isActive={panel?.type === DAYBOOK_DATA}>
                        <Flex directionColumn>
                            <LayersIcon />
                            <div>Données</div>
                        </Flex>
                    </StyledPaper>
                </StyledButton> */}
				{hasVortexStation && (
					<StyledButton
						onClick={() => {
							if (panel?.type !== DAYBOOK_VORTEX) {
								dispatch(setPanel({ type: DAYBOOK_VORTEX }))
							} else {
								dispatch(setPanel(null))
							}
						}}
					>
						<StyledPaper $isActive={panel?.type === DAYBOOK_VORTEX}>
							<Flex directionColumn>
								<RouterIcon />
								<div>Vortex</div>
							</Flex>
						</StyledPaper>
					</StyledButton>
				)}
				<StyledButton
					onClick={() => {
						if (panel?.type !== CARTO_CONFIGS) {
							dispatch(setPanel({ type: CARTO_CONFIGS }))
						} else {
							dispatch(setPanel(null))
						}
					}}
				>
					<StyledPaper $isActive={panel?.type === CARTO_CONFIGS}>
						<Flex directionColumn>
							<MapIcon />
							<div>Cartes</div>
						</Flex>
					</StyledPaper>
				</StyledButton>
			</Control>
		</div>
	)
}

export default RightCenterControl
