import { FormHelperText, Switch } from "@mui/material"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { Flex } from "@/utils/components/style/flex"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import { useBoolean } from "react-use"
import KeyboardService from "@/pages/carto2/cartographie/service/KeyboardService"
import { isMobile } from "react-device-detect"
import Modal from "@/styles/organisms/Modal"
import {
	ModalHeader,
	ModalBody,
	ModalContent,
	ModalFooter,
	Kbd,
} from "@nextui-org/react"
import CreateButton from "@/styles/atoms/Button/specialized/CreateButton"
import SaveButton from "@/styles/atoms/Button/specialized/SaveButton"
import Button from "@/styles/atoms/Button"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import DownloadButton from "@/styles/atoms/Button/specialized/DownloadButton"
import LightTooltip from "../../styles/atoms/Tooltip/LightTooltip/LightTooltip"

interface ISuperModalProps {
	writePermissions?: boolean
	isOpen: boolean
	isNew?: boolean
	title?: string | JSX.Element
	children?: any
	onClick?: () => void
	small?: boolean
	toggleOnClickOutside?: boolean
	fade?: boolean
	activateShortcut?: boolean
	modalProps?: any
	isDownloadable?: boolean
	isNextable?: boolean
	disableSave?: boolean
	allowKeepOpen?: boolean
	rightContent?: any
	createButtonProps?: any
	onClose: () => void
	size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "full"
}
const SFlex = styled(Flex)`
    display: flex;
    flex-direction: row-reverse;
    gap: 32px;
`

const ChildrenContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    max-height: calc(var(--modal-max-height) - 200px);
    overflow: auto;
    padding-top: 0.3rem;
`
const RightContentContainer = styled.div`
    max-height: calc(var(--modal-max-height) - 200px);
`

const SuperModal = ({
	writePermissions = true,
	isOpen,
	onClose,
	isNew = true,
	title = "",
	size = undefined,
	children,
	onClick = () => {},
	small = false,
	toggleOnClickOutside = true,
	fade = true,
	activateShortcut = true,
	modalProps = {},
	isDownloadable = false,
	isNextable = false,
	disableSave = false,
	allowKeepOpen = false,
	rightContent = undefined,
	createButtonProps = {},
}: ISuperModalProps) => {
	const _onClick = () => {
		onClick()
	}
	const [isPressed, _unused, uuid] = useKeyboardJs("ctrl + enter")
	const [isPressedEsc, _unused2, uuid2] = useKeyboardJs("esc")
	const [keepOpened, toggleKeepOpened] = useBoolean(false)

	useEffect(() => {
		if (isPressedEsc && isOpen) {
			onClose()
		}
	}, [isPressedEsc, isOpen])
	useEffect(() => {
		if (isPressed && activateShortcut && isOpen && !disableSave) {
			onClick()
		}
	}, [isPressed])
	useEffect(() => {
		if (isOpen) {
			KeyboardService.pushOnTop(uuid)
			KeyboardService.pushOnTop(uuid2)
		}
	}, [isOpen])
	useEffect(() => {
		if (keepOpened && !isOpen) {
			onClose()
		}
	}, [isOpen])
	if (!isOpen && !keepOpened) {
		return null
	}
	return (
		<Modal
			size={size ?? (small ? "md" : "3xl")}
			isOpen={isOpen || keepOpened}
			onClose={onClose}
			autoFocus={false}
			{...modalProps}
			fade={fade && !isMobile}
		>
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalBody>
					{rightContent && !isMobile && (
						<Flex gap="1rem" alignItemsStart>
							<ChildrenContainer>{children}</ChildrenContainer>
							<RightContentContainer>{rightContent}</RightContentContainer>
						</Flex>
					)}
					{rightContent && isMobile && (
						<Flex gap="1rem" alignItemsStart directionColumn fullWidth>
							{rightContent}
							{children}
						</Flex>
					)}
					{!rightContent && children}
				</ModalBody>
				<ModalFooter>
					<Flex fullWidth spaceBetween>
						{allowKeepOpen && !isMobile ? (
							<Flex>
								<Switch checked={keepOpened} onChange={toggleKeepOpened} />

								<FormHelperText>Garder ouvert</FormHelperText>
							</Flex>
						) : (
							<div />
						)}
						<SFlex>
							{!isNew && writePermissions && !isDownloadable && !isNextable && (
								<LightTooltip
									title={
										<>
											Raccourci : <Kbd>Ctrl + Entrée</Kbd>
										</>
									}
								>
									<SaveButton onClick={_onClick} disabled={disableSave} />
								</LightTooltip>
							)}
							{isNew && !isDownloadable && !isNextable && (
								<LightTooltip
									title={
										<>
											Raccourci : <Kbd>Ctrl + Entrée</Kbd>
										</>
									}
								>
									<CreateButton
										onClick={_onClick}
										isDisabled={disableSave}
										{...createButtonProps}
									/>
								</LightTooltip>
							)}
							{isDownloadable && <DownloadButton onClick={_onClick} />}
							{isNextable && (
								<Button color="primary" onClick={_onClick}>
									Suivant
								</Button>
							)}
							<CancelButton
								onClick={() => {
									if (keepOpened) {
										toggleKeepOpened()
									}
									if (isOpen) {
										onClose()
									}
								}}
							/>
						</SFlex>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default SuperModal
