import { createAsyncThunk } from "@reduxjs/toolkit"
import ErrorService from "@/services/ErrorService"
import CartoApi from "./CartoApi"
import { resetCartoReducer } from "./reducer"

export const remoteExtract = createAsyncThunk(
	"carto/remoteExtract",
	async (coucheArray: any, { dispatch, getState }) => {
		try {
			const res = await CartoApi.remoteExtract(coucheArray)
		} catch (err) {
			const errorMessage = `L'extraction a échoué, message d'erreur : ${err}`
			ErrorService.error({
				component: "resources:remoteExtract",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const resetCartoReducerAction = createAsyncThunk(
	"carto/resetCartoReducer",
	async (_, { dispatch, getState }) => {
		return dispatch(resetCartoReducer())
	},
)
