import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"
import { setPanel } from "@/redux-toolkit/common/reducer"
import {
	TEMPORARY_CARE,
	VORTEX_STATION,
} from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import L from "leaflet"
import React, { useContext } from "react"
import { Marker } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import vortexStations from "utils/components/map/vortexStations"
import { DAYBOOK_CARE, DAYBOOK_VORTEX_STATION } from "utils/panels/PanelCarto"

// app:javascript:components:maincourante:subComponents:carto:MarkerTemporaryCare.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:carto:MarkerTemporaryCare.tsx",
)
debug.log = console.log.bind(console)

const MarkerVortex = ({ vortexStation }) => {
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const dispatch = useDispatch()
	return (
		<Marker
			key={vortexStation.name}
			position={[vortexStation.lat, vortexStation.lng]}
			eventHandlers={{
				click: () => {
					isTakingScreenshotMap
						? null
						: dispatch(
								setPanel({
									type: DAYBOOK_VORTEX_STATION,
									name: vortexStation.name,
								}),
						  )
				},
			}}
			icon={L.icon({
				iconUrl: "/img/maincourante/vortex.png",
				iconSize: [30, 30],
				iconAnchor: [15, 30],
				popupAnchor: [0, -30],
			})}
		/>
	)
}

export default MarkerVortex
