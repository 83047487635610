import { createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "@/redux-toolkit/rootActions"
import syncDataAdapter from "../syncDataAdapter"

const initialState = {}

const uploadedCartoSlice = createSlice({
	name: "uploadedCarto",
	initialState,
	reducers: {
		addCarto: syncDataAdapter.add,
		addCartos: syncDataAdapter.many(syncDataAdapter.add),
		replaceCarto: syncDataAdapter.replace,
		removeCarto: syncDataAdapter.remove,
		setUploadedCartos(state, { payload: uploadedCartos }) {
			Object.keys(state).forEach((id) => {
				delete state[id]
			})
			uploadedCartos.forEach((config) => (state[config.id] = config))
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default uploadedCartoSlice.reducer

export const {
	addCarto,
	addCartos,
	replaceCarto,
	removeCarto,
	setUploadedCartos,
} = uploadedCartoSlice.actions
