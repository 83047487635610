import { createAction, createSlice } from "@reduxjs/toolkit"
import { resetRedux } from "../rootActions"
import { SearchView } from "./cooperationFiltersRessources"

export const resetEdit = createAction("edit/reset")

const initialState = {
	currentPoint: undefined,
	currentPointIsReadOnly: false,
	cooperationFilters: {
		search: "",
		view: SearchView.CARTE,
		layer: [],
		availableLayers: [],
		communes: [],
		tags: [],
		excludeCommunes: false,
		changeMapView: true,
	},
	currentIncident: undefined,
	currentIntervention: undefined,
	currentAction: {
		current: undefined,
		request: undefined,
		requestIsActive: false,
		requestPage: undefined,
	},
}

const editSlice = createSlice({
	name: "edit",
	initialState,
	reducers: {
		/* === Action === */
		requestCurrentAction(state, { payload: request }) {
			state.currentAction.request = request
			state.currentAction.requestIsActive = true
		},
		requestPage(state, { payload: page }) {
			state.currentAction.requestPage = page
		},
		forceCurrentAction(state, { payload: request }) {
			state.currentAction.current = request
			state.currentAction.request = undefined
			state.currentAction.requestIsActive = false
			state.currentAction.requestPage = undefined
		},
		acceptCurrentActionRequest(state) {
			state.currentAction.current = state.currentAction.request
			state.currentAction.request = undefined
			state.currentAction.requestIsActive = false
			state.currentAction.requestPage = undefined
		},
		rejectCurrentActionRequest(state) {
			state.currentAction.request = undefined
			state.currentAction.requestIsActive = false
			state.currentAction.requestPage = undefined
		},
		/* === */
		setCurrentPoint(state, { payload: point }) {
			state.currentPoint = point
			state.currentPointIsReadOnly = false
		},
		setCurrentIncident(state, { payload: incident }) {
			state.currentIncident = incident
		},
		setCurrentIntervention(state, { payload: intervention }) {
			state.currentIntervention = intervention
		},
		setCurrentReadOnlyPoint(state, { payload: point }) {
			state.currentPoint = point
			state.currentPointIsReadOnly = true
		},
		setCooperationFilters(state, { payload: changes }) {
			state.cooperationFilters = {
				...state.cooperationFilters,
				changeMapView: true,
				...changes,
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetEdit.type, () => initialState)
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default editSlice.reducer
export const {
	forceCurrentAction,
	requestCurrentAction,
	requestPage,
	acceptCurrentActionRequest,
	rejectCurrentActionRequest,
	setCurrentReadOnlyPoint,
	setCurrentPoint,
	setCooperationFilters,
	setCurrentIncident,
	setCurrentIntervention,
} = editSlice.actions
