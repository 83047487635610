import React, { createContext, useState } from "react"
import { FormProvider } from "react-hook-form"

export const StepFormContext = createContext<{
	steps: string[]
	activeStep: number
	setActiveStep: (n: number) => void
	disabledSteps: string[]
	setDisabledSteps: (n: string[]) => void
	editableStep: string
}>(undefined)

const StepFormProvider = ({
	methods,
	steps,
	activeStep,
	setActiveStep,
	children,
}) => {
	const [disabledSteps, setDisabledSteps] = useState([])
	const editableStep = steps
		.slice(0, activeStep)
		.reverse()
		.find((item) => !disabledSteps.includes(item))
	return (
		<StepFormContext.Provider
			value={{
				steps,
				activeStep,
				setActiveStep,
				disabledSteps,
				setDisabledSteps,
				editableStep,
			}}
		>
			<FormProvider {...methods}>{children}</FormProvider>
		</StepFormContext.Provider>
	)
}

export default StepFormProvider
