import React from "react"
import PH2 from "utils/pdf/header/PH2"
import PList from "utils/pdf/list/PList"
import NormalText from "utils/pdf/NormalText"
import PPage from "utils/pdf/PPage"
import StaticFormsService from "@/utils/services/StaticFormsService"
import JsonSchemaTableRGPD from "../components/JsonSchemaTableRGPD"
import StandardTableRGPD from "../components/StandardTableRGPD"

const StaticDataPage = ({ commune, responsableRgpd }) => {
	const jsonSchemas = StaticFormsService.getStaticForms()

	return (
		<>
			<PPage>
				<PH2>
					AC01 - Fiche de registre de l&apos;activité : Gestion de Données
					&quot;Statiques&quot;
				</PH2>

				<StandardTableRGPD
					commune={commune}
					responsableRgpd={responsableRgpd}
				/>

				<PH2>Contexte et Objectifs</PH2>

				<NormalText marginBottom={10}>
					Les données statiques liées au PCS sont des données collectées dans le
					but de concevoir le Plan Communal de Sauvegarde et de préparer la
					commune à une gestion de crise efficace. Les données dites
					&quot;Statiques&quot; correspondent à des données que possèdent toutes
					les communes et sont enregistrées comme tel en base de données de
					Numérisk. Ces données sont susceptibles d&apos;être partagées avec
					d&apos;autres collectivités d&apos;un même territoire à des fins
					uniques de coopération intercommunale sous couvert d&apos;une
					convention. Les données collectées à ces fins sont les suivantes :
				</NormalText>

				<PList
					marginBottom={10}
					items={[
						"Annuaire des élus",
						"Annuaire du personnel communal",
						"Réserve communale de sécurité civile",
						"Lieux de stockage",
						"Centres d'accueils et de regroupement (CARE)",
						"Postes de commandemants communaux (PCC)",
						"Stocks",
						"Véhicules",
					]}
				/>

				<NormalText>
					Toutes les données peuvent être ajoutées, éditées, consultées et
					supprimées depuis l&apos;interface de Numérisk PCS. L&apos;interface
					mise à disposition de la commune est protégée par un identifiant et un
					mot de passe.
				</NormalText>
			</PPage>
			<PPage>
				<PH2>AC01 - Détail des données</PH2>
				<JsonSchemaTableRGPD jsonSchemas={jsonSchemas} />
			</PPage>
		</>
	)
}
export default StaticDataPage
