import React, { useMemo } from "react"
import PointProperty from "@/utils/components/jsonSchema/properties/PointProperty"
import usePointPropertyToText from "@/utils/components/jsonSchema/properties/usePointPropertyToText"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import useJsonSchemasWithStatics from "./useJsonSchemasWithStatics"
import useCdcSharedJsonSchemas from "./useCdcSharedJsonSchemas"
import { ColumnDef } from "@tanstack/react-table"

export const usePropertiesColumns = (properties: IJsonSchemaProperty[]) => {
	const pointPropertyToText = usePointPropertyToText()

	const columns = useMemo(() => {
		const selectedFields = properties.filter((el) => el.isColumn)
		const buildingColumns = selectedFields.map((field) => {
			return {
				header: field.label,
				accessorFn: (data) => pointPropertyToText(data, field),
				cell: ({ row }) => (
					<>
						{row && (
							<PointProperty point={row.original} property={field} editable />
						)}
					</>
				),
				...(field.size
					? {
							size: field.size,
							minSize: field.size,
							maxSize: field.size,
					  }
					: {}),
			}
		})
		return [...buildingColumns]
	}, [properties])
	return columns as ColumnDef<any>[]
}

const useJsonSchemaColumns = (jsonSchemaId: string) => {
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const allJsonSchema = { ...jsonSchemasWithStatics, ...cdcSharedJsonSchemas }
	return usePropertiesColumns(
		allJsonSchema[jsonSchemaId]?.template?.properties ?? [],
	)
}

export default useJsonSchemaColumns
