import { useMemo } from "react"
import { useFormContext } from "react-hook-form"

export const FormDependencies = <T extends string>({
	targets,
	children,
	memo = [],
}: {
	targets: T[]
	children: (p: { [key in T]: any }) => JSX.Element
	memo?: unknown[]
}) => {
	const methods = useFormContext()
	const values = methods.watch(targets)
	const objValues = Object.fromEntries(
		values.map((val, index) => [targets[index], val]),
	)

	// @ts-ignore
	return children(objValues)
}
