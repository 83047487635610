import React from "react"
import { Button as NButton } from "@nextui-org/react"
import { StyledButton } from "./Button.styled"

const Button = React.forwardRef<
	HTMLButtonElement,
	React.ComponentProps<typeof NButton> & {
		/**
		 * @deprecated use startContent instead
		 * */
		startIcon?: React.ReactNode
	}
>((props, ref) => {
	const { disabled, isDisabled, startIcon, startContent, ...rest } = props

	// Display a deprecation warning if the disabled prop is used
	if (disabled !== undefined) {
		console.warn(
			"The 'disabled' prop is deprecated. Please use 'isDisabled' instead.",
		)
	}

	// Display a deprecation warning if the startIcon prop is used
	if (startIcon !== undefined) {
		console.warn(
			"The 'startIcon' prop is deprecated. Please use 'startContent' instead.",
		)
	}

	// Forward the ref along with other props to NButton
	return (
		<StyledButton
			{...rest}
			isDisabled={disabled || isDisabled}
			startContent={startIcon || startContent}
			ref={ref}
		/>
	)
})

Button.displayName = "Button" // Setting a displayName is a good practice for components using forwardRef

export default Button
