import { Step, StepContent, StepLabel } from "@mui/material"
import React from "react"
import styled, { css } from "styled-components"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"

const SStep = styled(Step)<{ $disabled: boolean }>`
    & .MuiStepIcon-root.Mui-active {
        color: var(--primary800) !important;
    }
    & .MuiStepIcon-root.Mui-completed {
        color: var(--primary) !important;
    }

    ${({ $disabled }) =>
			$disabled &&
			css`
            filter: grayscale(1) opacity(30%);
        `}
`
const SStepContent = styled(StepContent)<{ $clearStyles: any }>`
    ${({ $clearStyles }) =>
			$clearStyles &&
			css`
            margin-left: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            border-left: none !important;
        `}
`

export const ValidateText = styled.span`
    color: var(--primary);
    font-weight: 500;
`

const FormStep = ({
	children,
	question,
	formatedValue,
	clearStyles = false,
	onBack = undefined,
	disabled = false,
	...props
}) => {
	return (
		<SStep {...props} $disabled={disabled}>
			<Flex spaceBetween>
				<StepLabel
					optional={
						formatedValue && <ValidateText>{formatedValue}</ValidateText>
					}
				>
					{question}
				</StepLabel>
				{onBack && (
					<PrimaryOutlinedButton onClick={onBack}>
						Changer
					</PrimaryOutlinedButton>
				)}
			</Flex>
			<SStepContent $clearStyles={clearStyles}>{children}</SStepContent>
		</SStep>
	)
}

export default FormStep
