export const TOGGLE_CHECK = "TOGGLE_CHECK"
export const SET_TREE = "SET_TREE"
export const CHECK_ALL = "CHECK_ALL"
export const RESET_TREE = "RESET_TREE"

export const checkAll = () => ({
	type: CHECK_ALL,
})
export const toggleCheck = (arrayOfKeys) => {
	return {
		type: TOGGLE_CHECK,
		arrayOfKeys,
	}
}

export const setTree = (tree) => ({
	tree,
	type: SET_TREE,
})

export const resetTree = () => ({
	type: RESET_TREE,
})
