import { useContext, useEffect } from "react"
import { useController, useFormContext } from "react-hook-form"
import { StepFormContext } from "../component/StepFormProvider"

const useStep = (name: string, { dependencies = [] } = {}) => {
	const {
		steps,
		activeStep,
		setActiveStep,
		disabledSteps,
		setDisabledSteps,
		editableStep,
	} = useContext(StepFormContext)

	const { control, getValues, watch, setValue } = useFormContext()

	/* step state */
	const isActive = activeStep === steps.indexOf(name)
	/* --- */

	/* dependencies */
	watch(dependencies)
	const values = getValues(dependencies)
	const dependenciesValue = {}
	dependencies.forEach((val, index) => {
		dependenciesValue[val] = values[index]
	})
	/* --- */

	/* disable */
	const disabled = disabledSteps.includes(name)

	useEffect(() => {
		if (isActive && disabled) {
			setActiveStep(activeStep + 1)
		}
	}, [isActive, disabled])
	/* --- */

	/* form control */
	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
	})
	/* --- */

	const canGoBack = !disabled && name === editableStep

	return {
		value,
		activeStep,
		setStepValue: (value) => {
			onChange(value)
			setActiveStep(activeStep + 1)
		},
		dependencies: dependenciesValue as {
			[key: string]: any
		},
		onBackWithoutChangingValue: canGoBack
			? () => {
					if (steps[activeStep]) setValue(steps[activeStep], undefined)
					setActiveStep(steps.indexOf(name))
			  }
			: undefined,

		onBack: canGoBack
			? () => {
					setValue(name, undefined)
					if (steps[activeStep]) setValue(steps[activeStep], undefined)
					setActiveStep(steps.indexOf(name))
			  }
			: undefined,
		onSkip: (fileConfig) => {
			const currentName = name === "location" ? "pcs" : name
			setValue(name, fileConfig[currentName])
			setActiveStep(activeStep + 1)
		},
		shouldReset: activeStep < steps.indexOf(name),
		shouldInit: isActive,
		shouldLeave: activeStep > steps.indexOf(name),
		disabled,
		setDisabled: (newDisabled) => {
			newDisabled
				? !disabled && setDisabledSteps([...disabledSteps, name])
				: disabled &&
				  setDisabledSteps(disabledSteps.filter((item) => item !== name))
		},
	}
}

export default useStep
