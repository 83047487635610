import { FormGroup } from "@mui/material"
import styled from "styled-components"

export const StyledFormGroup = styled(FormGroup)`
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    width: 100%;
`
