import { useSelector } from "react-redux"
import SynchroService from "@/services/SynchroService"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IPoint from "utils/types/IPoint"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(points) => SynchroService.notDeleted(points),
)
const selectCurrentEvent = createDeepEqualSelector(
	(state: any) => state.common.selectedEvent,
	(selectedEvent) => selectedEvent,
)

export const useStaticPoints = () => {
	const points = useSelector(selectPoints) as IPoint[]
	const selectedEventId = useSelector(selectCurrentEvent)
	const concernedIncident = []
	const concernedInterventions = []
	const concernedCare = []

	Object.values(points)
		.filter((point) => point.event_id === selectedEventId)
		.filter(
			(point) =>
				point.jsonschema_id === INCIDENT ||
				point.jsonschema_id === INTERVENTION ||
				point.jsonschema_id === TEMPORARY_CARE,
		)
		.forEach((point) =>
			point.jsonschema_id === INCIDENT
				? concernedIncident.push(point)
				: point.jsonschema_id === INTERVENTION
					? concernedInterventions.push(point)
					: point.jsonschema_id === TEMPORARY_CARE
						? concernedCare.push(point)
						: null,
		)

	return {
		incident: concernedIncident,
		intervention: concernedInterventions,
		care: concernedCare,
	}
}
