import IUploadedCarto from "utils/types/IUploadedCarto"
import useUploadedCartos from "./useUploadedCartos"
import useUploadedFiles from "./useUploadedFiles"
import _ from "lodash"
import { useMemo } from "react"
import IUploadedFile from "utils/types/IUploadedFile"

const uuidRegex =
	/^(?:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}|[0-9a-fA-F]{32})$/

function extractNumberAndLetters(input) {
	// Use regular expression to match letters and numbers
	const matches = input.match(/([A-Z]+)(\d+)/)

	if (matches && matches.length === 3) {
		// matches[1] contains the letters, and matches[2] contains the numbers
		const letters = matches[1]
		const numbers = parseInt(matches[2])
		return { letters, numbers }
	} else {
		// Return null if the pattern doesn't match
		return {}
	}
}

const useCalculateCode = () => {
	const uploadedCarto = useUploadedCartos()
	const uploadedFiles = useUploadedFiles()

	const analyseFilesAndCarto = (element: IUploadedCarto | IUploadedFile) => {
		if (element?.config?.pcs?.after !== undefined) {
			const isUuid = uuidRegex.test(element.config.pcs.after)
			if (!isUuid) {
				const { letters, numbers } = extractNumberAndLetters(
					element.config.pcs.after,
				)
				return { letters, numbers }
			} else {
				return analyseFilesAndCarto(
					uploadedFiles[element.config.pcs.after] ??
						uploadedCarto[element.config.pcs.after],
				)
			}
		}
		return {}
	}

	const structure = useMemo(() => {
		const finalStructure = {}
		Object.values(uploadedCarto).forEach((carto) => {
			// if after an element
			const { letters, numbers } = analyseFilesAndCarto(carto)
			if (letters && numbers) {
				_.set(
					finalStructure,
					[letters, numbers],
					_.get(finalStructure, [letters, numbers], 0) + 1,
				)
			}
		})
		Object.values(uploadedFiles).forEach((file) => {
			// if after an element
			const { letters, numbers } = analyseFilesAndCarto(file)
			if (letters && numbers) {
				_.set(
					finalStructure,
					[letters, numbers],
					_.get(finalStructure, [letters, numbers], 0) + 1,
				)
			}
		})
		return finalStructure
	}, [uploadedCarto, uploadedFiles])

	return (code: string) => {
		const { letters, numbers } = extractNumberAndLetters(code)
		if (letters && numbers) {
			const structureForCurrentLetters = _.get(structure, [letters], {})
			let numberToAdd = 0
			Object.keys(structureForCurrentLetters).forEach((key) => {
				if (parseInt(key) < numbers) {
					numberToAdd += structureForCurrentLetters[key]
				}
			})
			let newNumber = numbers + numberToAdd
			const stringNewNumber = newNumber.toString()
			let numberToRender = ""
			if (newNumber < 10) {
				numberToRender = `0${stringNewNumber}`
			} else {
				numberToRender = stringNewNumber
			}
			return `${letters}${numberToRender}`
		}
		return code
	}
}

export default useCalculateCode
