import Axios from "axios"
const getPdfs = async (lastSynchro = "2000-04-12T12:47:50+02:00") => {
	const result = await Axios.get(`/api/pdfs?lastSynchro=${lastSynchro}`)
	return result.data
}

const deletePdf = async (id) => {
	const res = await Axios.delete(`/api/arrete/${id}`)
	return res
}

const getArreteHtml = async (id) => {
	const res = await Axios.get(`/api/arrete/arreteStructure/${id}`)
	return res.data
}

const deleteArrete = async (id) => {
	const res = await Axios.delete(`/api/arrete/${id}`)
	return res
}

const create = async (data, title, event_id) => {
	const res = await Axios.post("/api/arrete/create", {
		data: {
			data: data,
			title: title,
			event_id,
		},
	})
	if (res.data.status !== 200) {
		throw new Error("Le rapport/arrêté n'a pas pu être créé")
	} else {
		return res.data.id
	}
}

const generate = async (id) => {
	const res = await Axios.get(`/api/arrete/generate/${id}`)
	return res.data.uuid
}

const waitArreteGeneration = (uuid) => {
	return new Promise<string>((resolve, reject) => {
		setTimeout(async () => {
			const status = await Axios.get(`/api/arrete/generationStatus/${uuid}`)
			if (!status.data.finished) {
				if (status.data.error) {
					return reject(status.data.error)
				}
				return resolve(waitArreteGeneration(uuid))
			}
			return resolve(`/api/arrete/download/${uuid}`)
		}, 1000)
	})
}

export default {
	getPdfs,
	deletePdf,
	getArreteHtml,
	deleteArrete,
	create,
	generate,
	waitArreteGeneration,
}
