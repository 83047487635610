export const width50 = {
	size: 50,
	minSize: 50,
	maxSize: 50,
}
export const width10 = {
	size: 10,
	minSize: 10,
	maxSize: 10,
}
export const width30 = {
	size: 30,
	minSize: 30,
	maxSize: 30,
}

export const width60 = {
	size: 60,
	minSize: 60,
	maxSize: 60,
}

export const width80 = {
	size: 80,
	minSize: 80,
	maxSize: 80,
}

export const width130 = {
	size: 130,
	minSize: 130,
	maxSize: 130,
}

export const width180 = {
	size: 180,
	minSize: 180,
	maxSize: 180,
}

export const width400 = {
	size: 200,
	minSize: 200,
	maxSize: 200,
}

export const width100 = {
	size: 100,
	minSize: 100,
	maxSize: 100,
}
export const widthCustom = (size) => ({
	size,
	minSize: size,
	maxSize: size,
})
