import Axios from "axios"



const generatePcs = async (selection, kit_name, config) => {
	return Axios.post(`/generate_pcs`, { data: selection, kit_name: kit_name, config })
}

const getStatus = async ({ uuid }) => {
	return Axios.get(`/pcs/status/${uuid}`)
}

const getDownloadUrl = async (id) => {
	const res = await Axios.get(`/api/uploaded_files/url/${id}`)
	return res.data
}

const downloadTree = async () => {
	return Axios.get("/download_tree")
}

export default {
	getStatus,
	generatePcs,
	downloadTree,
	getDownloadUrl,
}
