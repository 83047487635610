import { useMap } from "react-leaflet"
import L from "leaflet"
import useCommune from "./useCommune"
import Moment from "moment"
import { useContext, useState } from "react"
import ErrorService from "@/services/ErrorService"
import { useDispatch } from "react-redux"
import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"
// credits to https://github.com/brammitch/recharts-to-png

export const A4_SIZE = {
	width: Math.round(4213 * 0.7),
	height: Math.round(2979 * 0.55),
}

export type UseCurrentPng = {
	getPng: (
		onFinishCallback: (blob: string) => void,
	) => Promise<string | undefined>
	isLoading: boolean
}

/**
 * @param options - optional html2canvas Options object
 */
export const useCurrentPngMap = (easyPrint: any): UseCurrentPng => {
	const map = useMap()
	const commune = useCommune()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const { scaleMultiplicator = 1 } = useContext(ScreenShotContext)

	const getPng = (onFinishedCallback: (blob: string) => void) => {
		setIsLoading(true)
		easyPrint?.printMap({
			size: {
				width: A4_SIZE.width,
				height: A4_SIZE.height,
				bounds: map.getBounds(),
			},
			filename: `Cartographie - ${Moment().format("DD/MM/YYYY HH:mm:ss")}`,
			onFinished: ({ blob, error = undefined }) => {
				map.invalidateSize()
				setIsLoading(false)
				map.setMaxZoom(18)
				if (error) {
					const errorMessage = `Echec de la tentative de génération de capture d'écran : ${error}`
					debugger
					ErrorService.error({
						component: "useCurrentPngMap:printMap",
						message: errorMessage,
						dispatch,
					})
				}
				onFinishedCallback(blob)
			},
			onError: (error) => {
				ErrorService.error({
					component: "useCurrentPngMap:printMap",
					message: error,
					dispatch,
				})
			},
			scaleMultiplicator,
		})

		return
	}

	//@ts-ignore
	return { getPng, isLoading }
}
