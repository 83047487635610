import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Card, Checkbox, Collapse, List, ListItemButton } from "@mui/material"
import useCartoCouches from "@/hooks/useCartoCouches"
import {
	addCouchePoints,
	addCouchePolygon,
	removeCouche,
} from "@/redux-toolkit/carto/reducer"
import { JSON_SCHEMAS, MULTI_POLYGONS } from "@/redux-toolkit/time/constants"
import React, { useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import JsonSchemaCard from "./JsonSchemaCard"
import MultiPolygonCard from "./MultiPolygonCard"

const SListItem = styled(ListItemButton)`
    display: flex !important;
    justify-content: space-between !important;
`

const SCard = styled(Card)`
    margin: 1em;
`

const CategorydataListitem = ({
	category,
	color,
	cartography,
	forceOpen = false,
}) => {
	const [isOpen, setIsOpen] = useState(forceOpen || false)

	const dispatch = useDispatch()
	const coucheIds = Object.keys(useCartoCouches())

	const { checked, indeterminate, disable } = useMemo(() => {
		const total = category.items.filter(
			(item) => item.type === MULTI_POLYGONS || item.isGeolocalisable,
		).length

		const checkedIds = category.items.filter((item) =>
			coucheIds.includes(item.id),
		)

		const checked = checkedIds.length === total
		const indeterminate = checkedIds.length && checkedIds.length < total

		return { checked, indeterminate, disable: total === 0 }
	}, [coucheIds, category.items])

	const handleChange = useCallback(() => {
		if (checked) {
			category.items.forEach((item) => dispatch(removeCouche(item.id)))
		} else {
			category.items.forEach((item) => {
				if (item.type === JSON_SCHEMAS && item.isGeolocalisable)
					dispatch(addCouchePoints(item.id))
				if (item.type === MULTI_POLYGONS) dispatch(addCouchePolygon(item.id))
			})
		}
	}, [checked, category.items])

	return (
		<SCard>
			<List>
				<SListItem onClick={() => setIsOpen(!isOpen)}>
					<div
						style={{
							color: color,
							fontSize: "18px",
							flex: 1,
						}}
					>
						{category.title} ({category.items.length})
					</div>
					{isOpen ? <ExpandLess /> : <ExpandMore />}
					{!disable && (
						<Checkbox
							checked={checked}
							indeterminate={!!indeterminate}
							onChange={handleChange}
							onClick={(event) => event.stopPropagation()}
						/>
					)}
				</SListItem>
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					{category.items.map((item) => (
						<React.Fragment key={item.id}>
							{item.type === JSON_SCHEMAS && (
								<JsonSchemaCard cartography={cartography} jsonSchema={item} />
							)}
							{item.type === MULTI_POLYGONS && (
								<MultiPolygonCard multiPolygon={item} />
							)}
						</React.Fragment>
					))}
				</Collapse>
			</List>
		</SCard>
	)
}

export default CategorydataListitem
