import { toast } from "react-toastify"
import Axios from "axios"
import { setOffLine } from "@/redux-toolkit/common/reducer"
const error = ({
	component,
	message,
	silence = false,
	dispatch = undefined,
}) => {
	// @ts-ignore
	if (message?.includes?.("Network Error")) {
		dispatch?.(setOffLine())
		return
	}
	if (!silence && navigator.onLine) {
		// check if message is a string
		if (typeof message === "string") {
			toast.error(message)
		} else {
			toast.error("Une erreur est survenue")
		}
	}
	console.log(message)
	Axios.post("/api/clientErrors", { component, message })
}

export default { error }
