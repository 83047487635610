import AddIcon from "@mui/icons-material/Add"
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly"
import DeleteIcon from "@mui/icons-material/Delete"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import PersonIcon from "@mui/icons-material/Person"
import PetsIcon from "@mui/icons-material/Pets"
import { IconButton, Paper } from "@mui/material"
import Debug from "debug"
import React, { useState } from "react"
import styled from "styled-components"
import { PrimaryTextButton } from "utils/components/style/button"
import { StyledChip } from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"
import FSelect from "utils/form/FSelect"
import FText from "utils/form/FText"
import { IFamilyMemberType } from "utils/types/IFamilyMember"
import {
	REDUCED_MOBILITY,
	SPECIFIC_REQUIREMENTS,
	NOT_FRENCH_SPEAKER,
	DANGEREOUS_ANIMAL,
	FAT_ANIMAL,
	EXOTIC_ANIMAL,
} from "./SpecificityConstant"
// app:javascript:components:maincourante:care:modals:family:FamilyMemberCard.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:modals:family:FamilyMemberCard",
)
debug.log = console.log.bind(console)

const memberName = {
	BABY: "Bébé",
	CHILD: "Enfant",
	ADULT: "Adulte",
	ANIMAL: "Animal",
}
const memberColor = {
	BABY: "purple500",
	CHILD: "orange500",
	ADULT: "green500",
	ANIMAL: "brown500",
}
const memberIcon = {
	BABY: <ChildFriendlyIcon />,
	CHILD: <EmojiPeopleIcon />,
	ADULT: <PersonIcon />,
	ANIMAL: <PetsIcon />,
}

export const specificityOptions = [
	{
		label: REDUCED_MOBILITY,
		types: [
			IFamilyMemberType.ADULT,
			IFamilyMemberType.CHILD,
			IFamilyMemberType.BABY,
		],
	},
	{
		label: SPECIFIC_REQUIREMENTS,
		types: [
			IFamilyMemberType.ADULT,
			IFamilyMemberType.CHILD,
			IFamilyMemberType.BABY,
			IFamilyMemberType.ANIMAL,
		],
	},
	{
		label: NOT_FRENCH_SPEAKER,
		types: [IFamilyMemberType.ADULT, IFamilyMemberType.CHILD],
	},
	{
		label: DANGEREOUS_ANIMAL,
		types: [IFamilyMemberType.ANIMAL],
	},
	{
		label: FAT_ANIMAL,
		types: [IFamilyMemberType.ANIMAL],
	},
	{
		label: EXOTIC_ANIMAL,
		types: [IFamilyMemberType.ANIMAL],
	},
]
const StyledPaper = styled(Paper)`
    width: 100%;
`
const StyledPresentation = styled.div<{ $color: string }>`
    border: 1px solid ${({ $color }) => `var(--${$color})`};
    color: ${({ $color }) => `var(--${$color})`} !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    color: white;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding: 0.5rem;
    border-radius: 4px;
`

const StyledDeleteIcon = styled(DeleteIcon)`
    //color: white;
`
const StyledFlexInternal = styled.div`
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`
const FamilyMemberCard = ({
	member,
	index,
	remove,
	name,
	leftPerson = false,
	anyLeftSubPerson = false,
}) => {
	const [showBesoin, setShowBesoin] = useState(false)
	return (
		<StyledPaper key={index}>
			<StyledPresentation $color={memberColor[member.type]}>
				<Flex gap="1rem">
					{memberIcon[member.type]}
					<div>{memberName[member.type]}</div>
				</Flex>
				<Flex>
					{leftPerson ? (
						<StyledChip
							$colorProperty="var(--red500)"
							variant="outlined"
							size="small"
							label="Sorti(e)"
						/>
					) : (
						<StyledChip
							$colorProperty="var(--green500)"
							variant="outlined"
							size="small"
							label="Présent(e)"
						/>
					)}
					<IconButton
						disabled={anyLeftSubPerson}
						onClick={() => {
							if (!anyLeftSubPerson) {
								remove(index)
							}
						}}
					>
						<StyledDeleteIcon />
					</IconButton>
				</Flex>
			</StyledPresentation>
			<StyledFlexInternal>
				<Flex gap="1rem">
					<FText
						size="small"
						name={`${name}.${index}.Identité`}
						label="Identité"
					/>
					<FSelect
						size="small"
						isMulti
						label="Spécificités"
						options={specificityOptions
							.filter((option) => option.types.includes(member.type))
							.map((option) => option.label)}
						name={`${name}.${index}.Spécificités`}
					/>
				</Flex>
				{(member.besoin || showBesoin) && (
					<FText
						multiline={1}
						name={`${name}.${index}.besoin`}
						label="Commentaire"
					/>
				)}
			</StyledFlexInternal>

			{!member.besoin && !showBesoin && (
				<PrimaryTextButton
					startIcon={<AddIcon />}
					onClick={() => setShowBesoin(true)}
				>
					Ajouter un commentaire
				</PrimaryTextButton>
			)}
		</StyledPaper>
	)
}

export default FamilyMemberCard
