import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import { StyledChip } from "utils/components/style/chip"
import { Avatar } from "@mui/material"
import { StyledAvatarCareChip } from "./AdultChip"

// app:javascript:components:maincourante:care:chips:AdultChip.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:chips:AdultChip",
)
debug.log = console.log.bind(console)

const AnimalChip = ({ number = undefined, ...props }) => {
	return (
		<StyledChip
			$colorProperty="var(--brown)"
			variant="outlined"
			label={number > 1 ? "Animaux" : "Animal"}
			avatar={
				<StyledAvatarCareChip
					$color="var(--brown)"
					$backgroundColor="var(--brown50)"
					$small={props.size === "small"}
				>
					{number}
				</StyledAvatarCareChip>
			}
			{...props}
		/>
	)
}

export default AnimalChip
