import { Chip } from "@mui/material"
import React from "react"
import colored from "utils/colored"
import { Flex } from "utils/components/style/flex"
import { TypeComponentProps } from "../PointPropertyValue"
import { safeArray } from "./TypeCheckbox"
import { DiffSpan } from "./PropertiesType.styled"

const CChip = colored(Chip)

function TypeTags({ value: tags, align, diffWith }: TypeComponentProps) {
	const safeTags = safeArray<string>(tags)

	return (
		<Flex wrap flexEnd={align === "right"} gap="5px">
			{diffWith &&
				diffWith
					.filter((item) => !safeTags.includes(item))
					.map((tag) => (
						<CChip
							key={tag}
							label={<DiffSpan>{tag}</DiffSpan>}
							$backgroundColor={"var(--red200)"}
						/>
					))}
			{safeTags.map((tag) => {
				return (
					<CChip
						key={tag}
						label={tag}
						$backgroundColor={
							diffWith && !diffWith.includes(tag)
								? "var(--green150)"
								: undefined
						}
						{...(diffWith && !diffWith.includes(tag)
							? {
									$backgroundColor: "var(--green150)",
									$textColor: " var(--green700);",
							  }
							: {})}
					/>
				)
			})}
		</Flex>
	)
}

export default TypeTags
