import React from "react"
import styled from "styled-components"
import CategorydataListitem from "./CategorydataListitem"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { isMobile } from "react-device-detect"

const BackButtonContainer = styled.div`
    margin: 1em;
    display: flex;
    flex-direction: ${isMobile ? "row" : "row-reverse"};
`
const SButton = styled(PrimaryOutlinedButton)`
    width: fit-content;
    margin-bottom: 0.5rem !important;
`

const CategoryView = ({
	selectedCategory = undefined,
	setSelectedCategory,
	cartography,
	color,
}) => {
	return (
		<>
			<BackButtonContainer>
				<SButton
					startIcon={<ArrowBackIcon />}
					size="small"
					onClick={() => setSelectedCategory(undefined)}
				>
					Retour
				</SButton>
			</BackButtonContainer>

			{selectedCategory &&
				selectedCategory.subCategories.map((subCategory) => (
					<CategorydataListitem
						key={subCategory.title}
						category={subCategory}
						color={color}
						cartography={cartography}
						forceOpen={selectedCategory.subCategories.length === 1}
					/>
				))}
		</>
	)
}

export default CategoryView
