import { Dialog } from "@mui/material"
import { HEIGHT_TOP_BAR } from "@/pages/app/GlobalStyle"
import styled from "styled-components"

const SDialog = styled(Dialog)`
    padding-top: ${HEIGHT_TOP_BAR}px;
    padding-left: var(--width-navbar);
`

export default SDialog
