const intercoNames = ["intercommunaux", "intercommunal"]

const isAlreadyInterco = (name: string): boolean => {
	let res = false
	intercoNames.forEach((intercoName) => {
		if (name.toLowerCase().includes(intercoName)) {
			return res = true
		}
	})

	return res
}

const intercoNameAdapter = <T,>(name: T, isInterco: boolean): T => {
	if (typeof name === "string") {
		if (isInterco && !isAlreadyInterco(name)) {
			// @ts-ignore
			return name
				?.replaceAll("communaux", "intercommunaux")
				?.replaceAll("Communaux", "Intercommunaux")
				?.replaceAll("communal", "intercommunal")
				?.replaceAll("Communal", "Intercommunal")
				?.replaceAll("Annuaires de la commune", "Annuaires de l'intercommunalité")
				?.replaceAll("PCS", "PICS")
				?.replaceAll("maire", "président")
		}
		return name
	}
	return name
}

export default intercoNameAdapter
