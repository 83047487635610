import { useOrgaWithOrder } from "@/hooks/useOrga"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import _ from "lodash"
import { useMemo } from "react"

const useCrisisOrgaWithOrder = () => {
	const { cellules, order } = useOrgaWithOrder()
	const selectedEvent = useSelectedEvent()
	const substitutions = selectedEvent?.orga?.substitutions
	const absents = selectedEvent?.orga?.absents

	const crisisCellules = useMemo(
		() =>
			_.mapValues(cellules, (cellule) => {
				return {
					...cellule,
					crisisResponsableIds: substitutions
						? cellule.responsableIds
							.map((id) => {
								return substitutions[id]
									? substitutions[id]
									: absents.includes(id)
										? undefined
										: id
							})
							.filter(Boolean)
						: cellule.responsableIds,
				}
			}),
		[cellules, substitutions, absents],
	)

	return {
		cellules: crisisCellules,
		order: order,
		substitutions,
		absents,
	}
}

export default useCrisisOrgaWithOrder
