import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IEvent from "@/utils/types/IEvent"
import useEvents from "./useEvents"

const selectSelectedEvent = createDeepEqualSelector(
	(state: any) => state.common.selectedEvent,
	(selectedEvent) => selectedEvent,
)

const useSelectedEvent: () => IEvent = () => {
	const events = useEvents()
	return events[useSelector(selectSelectedEvent)]
}

export default useSelectedEvent
