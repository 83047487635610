import Axios from "axios"

class CartoApi {
	async getJsonSchemas() {
		const result = await Axios.get("/api/json_schemas")

		return result
	}

	async getCouche(title, decoupage) {
		const result = await Axios.get(
			`/askcouche/${encodeURI(title)}/${encodeURI(decoupage.toLowerCase())}`,
		)

		return result
	}

	async remoteExtract(coucheArray) {
		throw new Error("No longer implemented")
	}
}

export default new CartoApi()
