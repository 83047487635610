import { Checkbox, Radio } from "@mui/material"
import useHumans from "@/hooks/useHumans"
import useIsInterco from "@/hooks/useIsInterco"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { deletePoint } from "@/redux-toolkit/data/points/resources"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React, { useMemo } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import ButtonMulti from "@/utils/components/button/ButtonMulti/ButtonMulti"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import {
	width100,
	width80,
	widthCustom,
} from "@/utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import ModalBodyForTable from "@/utils/modals/ModalBodyForTable"
import HumanService from "@/utils/services/HumanService"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import _ from "lodash"
import { ColumnDef } from "@tanstack/react-table"
import Modal from "@/styles/organisms/Modal"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { ModalContent } from "@nextui-org/react"
import Button from "@/styles/atoms/Button"

const FModalHumans = ({
	isOpen,
	onClose,
	onChange,
	value,
	multiple = true,
	customTitle = undefined,
	exclude = [],
}) => {
	const humans = useHumans()
	const dispatch = useDispatch()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const isInterco = useIsInterco()
	const permissionsAnnuaireselus =
		jsonSchemasWithStatics[StaticFormType.ANNUAIRES_ELUS]
	const permissionsAnnuairesCommunal =
		jsonSchemasWithStatics[StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL]
	const permissionsRCSC =
		jsonSchemasWithStatics[StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE]

	const onSingleSelectChange = (row) => {
		onChange(row.original.id)
		onClose()
	}

	const singleSelectColumn = (props) => {
		return (
			<Radio
				checked={props.row.original.id === value}
				onChange={(event) => {
					if (event.target.checked) {
						onSingleSelectChange(props.row)
					}
				}}
			/>
		)
	}

	const onMultipeSelectChange = (row) => {
		const valueChecked = value.includes(row.original.id)

		if (valueChecked) {
			onChange(value.filter((item: string) => item !== row.original.id))
			return
		}
		onChange([...value, row.original.id])
	}

	const multipleSelectColumn = (props) => {
		const valueChecked = value.includes(props.row.original.id)

		return (
			<Checkbox
				checked={valueChecked}
				onChange={() => onMultipeSelectChange(props.row)}
			/>
		)
	}

	const columns = useMemo(() => {
		return [
			{
				header: "Choisir",
				accessorKey: "choose",
				cell: multiple ? multipleSelectColumn : singleSelectColumn,
				...width80,
				enableSorting: false,
			},
			{
				header: "Type",
				accessorKey: "jsonschema_id",
				cell: ({ row: { original } }) => {
					return (
						<Flex gap="1rem">
							{HumanService.displayTypeAsChip(original, false, isInterco)}
						</Flex>
					)
				},
				...widthCustom(230),
			},
			{
				header: "Nom",
				accessorKey: "geojson.properties.Nom",
			},
			{
				header: "Prénom",
				accessorKey: "geojson.properties.Prénom",
			},
			{
				header: "Poste",
				accessorKey: "geojson.properties.Poste",
			},
			{
				header: "Service | Missions",
				accessorKey: "geojson.properties.Service",
				cell: ({ row: { original } }) => {
					if (
						original.jsonschema_id ===
						StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL
					) {
						return <>{original.geojson.properties.Service}</>
					}
					if (
						original.jsonschema_id ===
						StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE
					) {
						return <>{original.geojson.properties.Missions}</>
					}
					return <>{original.geojson.properties.Service}</>
				},
			},
		] as ColumnDef<any>[]
	}, [multiple, value, onChange, humans])

	const data = useMemo(() => {
		return Object.values(humans).filter(
			(human) => !_.includes(exclude, human.id),
		)
	}, [humans, exclude])

	const actionButtonsOptions = [
		...(permissionsAnnuaireselus?.write
			? [
					{
						key: "addAnnuairesElus",
						id: "addAnnuairesElus",
						label: isMobile
							? "Dans Annuaire des élus"
							: "Ajouter un responsable dans Annuaire des élus",

						icon: (
							<img width="30px" src={getImage(StaticFormType.ANNUAIRES_ELUS)} />
						),
						onClick: () => {
							dispatch(
								setCurrentPoint({
									jsonschema_id: StaticFormType.ANNUAIRES_ELUS,
								}),
							)
						},
					},
				]
			: []),
		...(permissionsAnnuairesCommunal?.write
			? [
					{
						id: "addAnnuairesCommunal",
						key: "addAnnuairesCommunal",
						label: isMobile
							? intercoNameAdapter(
									"Dans Annuaire du personnel communal",
									isInterco,
								)
							: intercoNameAdapter(
									"Ajouter un responsable dans Annuaire du personnel communal",
									isInterco,
								),
						icon: (
							<img
								width="30px"
								src={getImage(StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL)}
							/>
						),
						onClick: () => {
							dispatch(
								setCurrentPoint({
									jsonschema_id: StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL,
								}),
							)
						},
					},
				]
			: []),
		...(permissionsRCSC?.write
			? [
					{
						id: "addRCSC",
						key: "addRCSC",
						label: isMobile
							? intercoNameAdapter(
									"Dans Réserve Communale de Sécurité Civile",
									isInterco,
								)
							: intercoNameAdapter(
									"Ajouter un responsable dans Réserve Communale de Sécurité Civile",
									isInterco,
								),
						icon: (
							<img
								width="30px"
								src={getImage(StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE)}
							/>
						),
						onClick: () => {
							dispatch(
								setCurrentPoint({
									jsonschema_id:
										StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE,
								}),
							)
						},
					},
				]
			: []),
	]
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="5xl">
			<ModalContent>
				<ModalHeader>
					{customTitle ?? "Sélectionner un responsable"}
				</ModalHeader>
				<ModalBodyForTable>
					isOpen : {isOpen.toString()}
					<SuperReactTable
						onRowClick={multiple ? onMultipeSelectChange : onSingleSelectChange}
						initialSortBy={[
							{
								id: "jsonschema_id",
								desc: false,
							},
						]}
						autoResetPage={false}
						actionsButtons={
							<ButtonMulti
								buttonLabel="Ajouter un responsable"
								options={actionButtonsOptions}
							/>
						}
						selectable={false}
						data={data}
						columns={columns}
						onEditClick={(human) => dispatch(setCurrentPoint(human))}
						onDeleteClick={(human) => dispatch(deletePoint(human))}
					/>
				</ModalBodyForTable>
				{multiple && (
					<ModalFooter>
						<Button color="primary" onClick={onClose}>
							Terminer
						</Button>
					</ModalFooter>
				)}
			</ModalContent>
		</Modal>
	)
}

export default FModalHumans
