import { createSlice } from "@reduxjs/toolkit"
import syncDataAdapter from "@/redux-toolkit/data/syncDataAdapter";
import { resetRedux } from "@/redux-toolkit/rootActions"

const initialState = {}

const multiPolygonsSlice = createSlice({
	name: "admin/multiPolygons",
	initialState,
	reducers: {
		setMultipolygons(state, { payload: multiPolygons }) {
			return multiPolygons
		},
		removeMultiPolygon(state, { payload: id }) {
			delete state[id]
		},
		addMultiPolygon(state, { payload: multiPolygon }) {
			state[multiPolygon.id] = multiPolygon
		},
		addMultiPolygons(state, { payload: multiPolygons }) {

			multiPolygons.forEach((multiPolygon) => {
				state[multiPolygon.id] = multiPolygon
			})
		},
		updateMultiPolygon(state, { payload: multiPolygon }) {
			state[multiPolygon.id] = multiPolygon
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetRedux.type, () => initialState)
	},
})

export default multiPolygonsSlice.reducer
export const {
	setMultipolygons,
	addMultiPolygons,
	updateMultiPolygon,
	addMultiPolygon,
	removeMultiPolygon,
} = multiPolygonsSlice.actions
