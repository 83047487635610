import styled from "styled-components"

export const DIV = styled.div`
    font-size: var(--font-size-base);
`

export const EditableText = styled.div`
    cursor: text;
`

export const Text20 = styled.div`
    font-size: var(--font-size-big);
    color: var(--neutral100);
`
export const WhiteText = styled.div`
    color: white !important;
`
export const NormalBlue = styled.div`
    color: var(--primary800) !important;
`
export const BlueText = styled.div`
    color: var(--primary500) !important;
`
export const NormalRed = styled.div`
    color: var(--red700);
`
export const Neutral350Text = styled.div`
    color: var(--neutral300);
`
export const Neutral500Text = styled.div`
    color: var(--neutral500);
`
export const Small = styled.div`
    font-size: var(--font-size-small) !important;
`
export const Subtitle = styled.div`
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: var(--font-size-small) !important;
`
