import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Avatar } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Debug from "debug"
import { default as React } from "react"
import styled from "styled-components"
import NestedListChildren from "./NestedListChildren"
import { treeDisplayConfig } from "@/utils/components/categoryNavigation/CategoryNavigation"
import NestedListChildrenPersistent from "./NestedListChildrenPersistent"

// app:javascript:utils:panels:panelCarto:NestedListParent.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:NestedListParent")
debug.log = console.log.bind(console)

const StyledList = styled(List)<{ color: string }>`
    padding: 0px !important;
    border: 1px solid var(--neutral50);
`
const SAvatar = styled(Avatar)<{ color: string }>`
    background-color: ${({ color }) => color} !important;
`

export interface NestedListParentBaseProps {
	item: { title: string; subCategories: Array<any> }
	setPcsCategory?: React.Dispatch<any>
	actionOnFinalSelect?: () => void
}

interface NestedListParentBaseExtendedProps extends NestedListParentBaseProps {
	open: boolean
	setOpen: React.Dispatch<any>
}

const NestedListParentBase = ({
	open,
	setOpen,
	item,
	setPcsCategory = undefined,
	actionOnFinalSelect = undefined,
}: NestedListParentBaseExtendedProps) => {
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<>
			<StyledList color={treeDisplayConfig[item.title].color}>
				<ListItemButton onClick={handleClick}>
					<ListItemIcon>
						<SAvatar color={treeDisplayConfig[item.title].color}>
							{treeDisplayConfig[item.title].icon}
						</SAvatar>
					</ListItemIcon>
					<ListItemText primary={item.title} />
					{!setPcsCategory && open ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					{setPcsCategory && (
						<>
							{item.subCategories.map((subCategory) => (
								<NestedListChildren
									color={treeDisplayConfig[item.title].color}
									key={subCategory.title}
									subCategory={subCategory}
									setPcsCategory={setPcsCategory}
									actionOnFinalSelect={actionOnFinalSelect}
								/>
							))}
						</>
					)}
					{!setPcsCategory && (
						<>
							{item.subCategories.map((subCategory) => (
								<NestedListChildrenPersistent
									color={treeDisplayConfig[item.title].color}
									key={subCategory.title}
									subCategory={subCategory}
								/>
							))}
						</>
					)}
				</Collapse>
			</StyledList>
		</>
	)
}

export default NestedListParentBase
