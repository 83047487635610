import CloseIcon from "@mui/icons-material/Close"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import { Button, IconButton } from "@mui/material"
import Debug from "debug"
import React, { useEffect } from "react"
import {
	useController,
	useFieldArray,
	useFormContext,
	useWatch,
} from "react-hook-form"
import styled from "styled-components"
import { FStandardProps } from "utils/components/jsonSchema/form/FPointProperty"
import { Flex } from "utils/components/style/flex"
import FPhoneNumber from "./formUtils/FPhoneNumber"
import FText from "./FText"
import FSelect from "./FSelect"

// app:javascript:utils:form:FPhone.tsx
const debug = Debug("app:javascript:utils:form:FPhone")
debug.log = console.log.bind(console)

const EMPTY_PHONE = { Type: "", Numéro: "" }

const SButton = styled(Button)`
    width: fit-content;
`
const RedMessageText = styled.div`
	color: var(--red700);
	font-size: 0.8rem;
`
const FPhone = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
	forceRemove = false,
	isPublic = false,
}: FStandardProps & {
	forceRemove?: boolean
}) => {
	const { control, trigger } = useFormContext()

	const {
		fieldState: { error, isDirty, isTouched },
	} = useController({
		name,
		control,
	})

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	})
	const newvalue = useWatch({
		name,
		control,
	})
	useEffect(() => {
		if (isDirty && isTouched) {
			trigger(name)
		}
	}, [newvalue, isDirty, isTouched, trigger, name])

	return (
		<Flex directionColumn alignItemsInitial gap fullWidth>
			{label}
			{(fields ?? []).map((field, index) => (
				<Flex key={field.id} gap>
					{!isPublic && (
						<FText
							name={`${name}[${index}].Type`}
							label="Type"
							disabled={disabled}
							readOnly={readOnly}
						/>
					)}
					{isPublic && (
						<FSelect
							name={`${name}[${index}].Type`}
							label="Type"
							options={["Mobile", "Fixe"]}
							disabled={disabled}
							readOnly={readOnly}
						/>
					)}
					<FPhoneNumber
						name={`${name}[${index}].Numéro`}
						label="Numéro"
						InputProps={disabled ? { readOnly: true } : undefined}
						disabled={disabled}
					/>
					{((fields.length > 1 && !disabled) || forceRemove) && (
						<IconButton onClick={() => remove(index)}>
							<CloseIcon />
						</IconButton>
					)}
				</Flex>
			))}
			{error?.message && <RedMessageText>{error.message}</RedMessageText>}
			{!disabled && (
				<SButton
					startIcon={<LocalPhoneIcon />}
					onClick={() => {
						append(EMPTY_PHONE)
					}}
					disabled={disabled}
				>
					Ajouter un téléphone
				</SButton>
			)}
		</Flex>
	)
}

export default FPhone
