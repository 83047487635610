import ModalBody from "@/styles/organisms/Modal/ModalBody"
import React from "react"
import styled from "styled-components"
import { ReactTableContainerContext } from "utils/hooks/useAutoPageSize"
import useReactTableContainer from "utils/hooks/useReactTableContainer"

const Wrapper = styled.div`
    overflow: auto;
    padding: 1rem;
`

function ModalBodyForTable({ children, ...props }) {
	const { value, callbackRef } = useReactTableContainer()

	return (
		<ModalBody {...props}>
			<Wrapper ref={callbackRef}>
				<ReactTableContainerContext.Provider value={value}>
					{children}
				</ReactTableContainerContext.Provider>
			</Wrapper>
		</ModalBody>
	)
}

export default ModalBodyForTable
