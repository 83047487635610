import pressureIcon from "@iconify/icons-carbon/pressure"
import roundWindPower from "@iconify/icons-ic/round-wind-power"
import humidityHigh from "@iconify/icons-material-symbols/humidity-high"
import { Icon } from "@iconify/react"
import { Button, Checkbox } from "@mui/material"
import dataThresholdingRounded from "@iconify/icons-material-symbols/data-thresholding-rounded"
import { Image } from "@nextui-org/react"
import Axios from "axios"
import twotoneSpeed from "@iconify/icons-ic/twotone-speed"
import { Carousel } from "react-responsive-carousel"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import baselineHeight from "@iconify/icons-ic/baseline-height"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useFullscreen, useMap, useToggle } from "react-use"
import {
	Area,
	AreaChart,
	CartesianGrid,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { H6 } from "utils/components/style/header"
import SmallText from "utils/pdf/SmallText"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { WhiteOutlinedButton } from "utils/components/style/button"
import { DAYBOOK_VORTEX } from "utils/panels/PanelCarto"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { useDispatch } from "react-redux"
// app:javascript:utils:panels:panelCarto:PanelDaybookVortex.tsx
const debug = Debug("app:javascript:utils:panels:panelCarto:PanelDaybookVortex")
debug.log = console.log.bind(console)

const CustomCard2 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14);
    width: 100%;
    height: 300px;
`

const CustomCard = styled.div`
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14);
    width: 100%;
    background: linear-gradient(45deg, var(--primary400), var(--primary800));
    & > * {
        color: #fff !important;
    }
    display: flex;
    gap: 2rem;
    flex-direction: column;
`
const CustomCardImage = styled.div`
    border-radius: 0.5rem;
    box-shadow: var(--cool-shadow);
    height: 100%;
    width: 100%;
`
const descToImgWeather = {
	Clouds: "/img/weather/cloud.png",
	Clear: "/img/weather/sun.png",
}

const Simg = styled.img``
const Temperature = styled.div`
    font-size: 2rem;
    color: white;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
`
const DateText = styled.div`
    white-space: pre-line;
    & > * {
        font-size: 0.8rem;
    }
`
const StationName = styled.div`
    color: white;
    margin-top: 1rem;
    font-size: 1.2rem;
`
const Container = styled.div`
    padding: 0.5rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`
const DataText = styled.div`
    font-size: 1.2rem;
`
const DataTitle = styled.div`
    font-size: 0.8rem;
`

const winDegToDirection = (deg) => {
	if (deg > 11.25 && deg < 33.75) {
		return "NNE"
	} else if (deg > 33.75 && deg < 56.25) {
		return "NE"
	} else if (deg > 56.25 && deg < 78.75) {
		return "ENE"
	} else if (deg > 78.75 && deg < 101.25) {
		return "E"
	} else if (deg > 101.25 && deg < 123.75) {
		return "ESE"
	} else if (deg > 123.75 && deg < 146.25) {
		return "SE"
	} else if (deg > 146.25 && deg < 168.75) {
		return "SSE"
	} else if (deg > 168.75 && deg < 191.25) {
		return "S"
	} else if (deg > 191.25 && deg < 213.75) {
		return "SSW"
	} else if (deg > 213.75 && deg < 236.25) {
		return "SW"
	} else if (deg > 236.25 && deg < 258.75) {
		return "WSW"
	} else if (deg > 258.75 && deg < 281.25) {
		return "W"
	} else if (deg > 281.25 && deg < 303.75) {
		return "WNW"
	} else if (deg > 303.75 && deg < 326.25) {
		return "NW"
	} else if (deg > 326.25 && deg < 348.75) {
		return "NNW"
	} else {
		return "N"
	}
}
const PanelDaybookVortexStation = () => {
	const [stationData, setStationData] = useState(undefined)
	const [remainingMinutes, setRemainingMinutes] = useState(0)
	const ref = useRef(null)
	const [show, toggle] = useToggle(false)
	const dispatch = useDispatch()
	const isFullscreen = useFullscreen(ref, show, {
		onClose: () => toggle(false),
	})
	const [showThreshold, setShowThreshold] = useState(false)

	const fetchStation = async () => {
		const response = await Axios.get("/api/vortex")
		const data = await response.data
		console.log(data)
		const stationCoo = data?.[0]?.weather?.coord
		MapService.setCenter({ lat: stationCoo.lat, lng: stationCoo.lon })
		data[0].time_stamp
		setStationData(data)
	}
	useEffect(() => {
		fetchStation()
	}, [])

	useEffect(() => {
		const setRemaining = () => {
			if (!stationData) {
				return
			}
			const stationDataLastHour = Moment(
				stationData[stationData.length - 1].time_stamp,
			)
				.add(1, "hour")
				.add(5, "minute")
			const remaining = stationDataLastHour.diff(Moment(), "minutes")
			setRemainingMinutes(remaining)
			if (Moment().isAfter(stationDataLastHour)) {
				fetchStation()
			}
		}
		setRemaining()
		const interval = setInterval(setRemaining, 5000)
		return () => clearInterval(interval)
	}, [stationData])

	const latestStationData = stationData?.[stationData?.length - 1]

	// @ts-ignore
	const max = Number(_.maxBy(stationData, "height")?.height || 0)
	// @ts-ignore
	const min = Number(_.minBy(stationData, "height")?.height || 0)
	const data = (stationData ?? []).map((data) => {
		return {
			name: Moment(data.time_stamp).format("dddd Do MMM hh:mm"),
			"Hauteur d'eau": data.height,
		}
	})

	const temperatureData = (stationData ?? []).map((data) => {
		return {
			name: Moment(data.time_stamp).format("dddd Do MMM hh:mm"),
			Température: data?.weather?.main?.temp,
		}
	})

	const stationDataCopy = _.isArray(stationData)
		? [...stationData?.slice(-10)].reverse()
		: []
	const yDomain = showThreshold
		? [0, 4]
		: ([
				(dataMin) => {
					return Number(Number(min - (max - min) * 0.1).toFixed(3))
				},
				(dataMax) => Number(Number(max + (max - min) * 0.1).toFixed(3)),
		  ] as any)
	return (
		<Container>
			<CustomCard>
				<Flex fullWidth spaceBetween>
					<Flex directionColumn alignItemsStart>
						<WhiteOutlinedButton
							size="small"
							onClick={() => {
								dispatch(setPanel({ type: DAYBOOK_VORTEX }))
							}}
						>
							<Flex gap="0.25rem">
								<ArrowBack /> <div>Retour</div>
							</Flex>
						</WhiteOutlinedButton>
						<StationName>Gardouch</StationName>
						<Temperature>
							{_.get(latestStationData, "weather.main.temp")} °C
						</Temperature>
						<DateText>
							<div>Date de la dernière mesure : </div>
							<div>
								{Moment(latestStationData?.time_stamp).format(
									"dddd Do MMM HH:mm",
								)}
							</div>
						</DateText>
					</Flex>
					<Simg
						src={
							descToImgWeather[_.get(latestStationData, "weather.weather.main")]
						}
						height="50"
						width="auto"
					/>
				</Flex>
				<Flex fullWidth spaceBetween alignItemsInitial>
					<Flex directionColumn gap="0.25rem">
						<DataTitle>Hauteur d&apos;eau</DataTitle>
						<Icon icon={baselineHeight} color="#ffffff" width="30" />

						<DataText>{_.get(latestStationData, "height")} m</DataText>
					</Flex>
					<Flex directionColumn gap="0.25rem">
						<DataTitle>Vitesse d'écoulement</DataTitle>
						<Icon icon={twotoneSpeed} width="30" />

						<div>
							<DataText>{_.get(latestStationData, "avg_speed")} m/s</DataText>
						</div>
					</Flex>
					<Flex directionColumn gap="0.25rem">
						<DataTitle>Seuil de déclenchement d&apos;alerte</DataTitle>
						<Icon icon={dataThresholdingRounded} width="30" />

						<DataText>2.6 et 3.6 m</DataText>
					</Flex>
				</Flex>
				<Flex fullWidth flexEnd>
					<SmallText>
						Mise à jour auto dans {remainingMinutes} minutes
					</SmallText>
				</Flex>
			</CustomCard>
			<CustomCard>
				<Flex fullWidth spaceBetween alignItemsInitial>
					<Flex directionColumn gap="0.25rem">
						<DataTitle>Humidité</DataTitle>
						<Icon icon={humidityHigh} color="#ffffff" width="30" />

						<DataText>
							{_.get(latestStationData, "weather.main.humidity")}%
						</DataText>
					</Flex>
					<Flex directionColumn gap="0.25rem">
						<DataTitle>Vent</DataTitle>
						<Icon icon={roundWindPower} width="30" />

						<div>
							<DataText>
								{_.get(latestStationData, "weather.wind.speed")}
								km/h
							</DataText>
							<DataText>
								{winDegToDirection(
									_.get(latestStationData, "weather.wind.deg"),
								)}
							</DataText>
						</div>
					</Flex>
					<Flex directionColumn gap="0.25rem">
						<DataTitle>Pression</DataTitle>
						<Icon icon={pressureIcon} width="30" />

						<DataText>
							{_.get(latestStationData, "weather.main.pressure")}
							hPa
						</DataText>
					</Flex>
				</Flex>
			</CustomCard>
			<CustomCardImage ref={ref}>
				<Carousel showArrows={true} showThumbs={false}>
					{stationDataCopy.map((data) => {
						return (
							<>
								<Image
									showSkeleton
									src={data["picture_url"]}
									alt="station"
									height="100%"
								/>
								<p className="legend">
									{Moment(data["time_stamp"]).format("dddd Do MMM HH:mm")}
								</p>
							</>
						)
					})}
				</Carousel>
			</CustomCardImage>
			<CustomCard2>
				<Flex fullWidth spaceBetween>
					<H6>Hauteur d&apos;eau (m) - 7 derniers jours</H6>
					<Flex>
						<Checkbox
							checked={showThreshold}
							onChange={() => {
								setShowThreshold(!showThreshold)
							}}
						/>
						Afficher les seuils
					</Flex>
				</Flex>
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart width={500} height={400} data={data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis domain={yDomain} unit="m" />
						<ReferenceLine
							y={2.6}
							stroke="orange"
							label="Seuil d'alerte 2.6m"
							strokeDasharray="3 3"
						/>
						<ReferenceLine
							y={3.6}
							stroke="red"
							label="Seuil d'alerte 3.6m"
							strokeDasharray="3 3"
						/>
						<Tooltip />
						<Area
							type="monotone"
							dataKey="Hauteur d'eau"
							stroke="#8884d8"
							fill="#8884d8"
						/>
					</AreaChart>
				</ResponsiveContainer>
			</CustomCard2>
			<CustomCard2>
				<H6>Températures - 7 derniers jours</H6>
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart width={500} height={400} data={temperatureData}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip />
						<Area
							type="monotone"
							dataKey="Température"
							stroke="#ffc107"
							fill="#ffc107"
						/>
					</AreaChart>
				</ResponsiveContainer>
			</CustomCard2>
		</Container>
	)
}

export default PanelDaybookVortexStation
