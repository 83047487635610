import React from "react"
import NormalText from "utils/pdf/NormalText"
import PCell from "utils/pdf/table/PCell"
import PRow from "utils/pdf/table/PRow"
import PTable from "utils/pdf/table/PTable"

const JsonSchemaTableRGPD = ({ jsonSchemas }) => {
	return (
		<PTable>
			<PRow>
				<PCell minHeight={0} head>
					<NormalText>Nom du jeu de données</NormalText>
				</PCell>
				<PCell minHeight={0} head>
					<NormalText>Types de données récupérées</NormalText>
				</PCell>
				<PCell minHeight={0} head>
					<NormalText>Délais effacement</NormalText>
				</PCell>
				<PCell minHeight={0} head>
					<NormalText>Transferts</NormalText>
				</PCell>
			</PRow>
			{jsonSchemas.map((jsonSchema: any) => (
				<PRow key={jsonSchema.id}>
					<PCell>
						<NormalText>{jsonSchema.title}</NormalText>
					</PCell>
					<PCell>
						<NormalText>
							{jsonSchema.template.properties
								.map((property) => property.label)
								.join(" / ")}
						</NormalText>
					</PCell>
					<PCell>
						<NormalText>
							Au rupture de contrat de la commune, ou bien sur demande de la
							commune ou de la personne
						</NormalText>
					</PCell>
					<PCell>
						<NormalText>
							Téléchargement sous format PDF lors du téléchargement du PCS /
							Téléchargement sous format Excel lors de l&apos;extraction de la
							donnée dans Numérisk
						</NormalText>
					</PCell>
				</PRow>
			))}
		</PTable>
	)
}
export default JsonSchemaTableRGPD
