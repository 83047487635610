/* eslint-disable react/jsx-key */
import Button from "@/styles/atoms/Button"
import Defer from "@/utils/Defer"
import { RUBY_DATE_FORMAT } from "@/utils/dateFormat"
import makeDraggable from "@/utils/dnd/makeDraggable"
import makeDroppable from "@/utils/dnd/makeDroppable"
import useAutoPageSize from "@/utils/hooks/useAutoPageSize"
import CancelIcon from "@mui/icons-material/Cancel"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import EditIcon from "@mui/icons-material/Edit"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import ReorderIcon from "@mui/icons-material/Reorder"
import RestoreIcon from "@mui/icons-material/Restore"
import { Collapse, TableBody, TableRow } from "@mui/material"
import {
	ColumnDef,
	RowData,
	RowSelectionState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { isDesktop, isMobile } from "react-device-detect"
import { useLocalStorage } from "react-use"
import SuperDeleteButton from "../../SuperDeleteButton"
import ButtonMulti from "../../button/ButtonMulti/ButtonMulti"
import IButtonMultiOption from "../../button/ButtonMulti/IButtonMultiOption"
import CustomTooltip from "../../style/CustomTooltip"
import { Flex, FlexColumn } from "../../style/flex"
import BottomSuperTable from "../BottomSuperTable"
import ExtractionButtonTable from "../ExtractionButtonTable"
import SearchTable from "../SearchTable"
import useReorder from "../hooks/useReorder"
import useSuperReactTableFilter from "../useSuperReactTableFilter"
import {
	B,
	BottomContainer,
	IndeterminateCheckbox,
	MainTableRow,
	NoDataCell,
	NoDataRow,
	ResponsiveFlex,
	RowContainer,
	RowIconButton,
	SExpandLessIcon,
	SExpandMoreIcon,
	SPaper,
	SSuperReactTableCell,
	STable,
	STableBody,
	STableHead,
	StyledBottomFlex,
	StyledCell,
	StyledDoneIcon,
	StyledErrorIcon,
	StyledWarningIcon,
	SubTableCell,
	SubTableRow,
	TableRelativeContainer,
	WhiteTableRow,
} from "./SuperReactTable.styled"
import ButtonRowSelection from "./button/ButtonRowSelection"

import { width50, widthCustom } from "../widthProps"

declare module "@tanstack/table-core" {
	interface ColumnMeta<TData extends RowData, TValue> {
		expandableCell?: boolean
		isAction?: boolean
		isSticky?: boolean
		canBeFiltered?: boolean
		Filter?: (a) => JSX.Element
	}
}

const SELECTION_ID = "selection_id"

// replace gotopage

const DroppableTableBody = makeDroppable(TableBody)

interface ISuperReactTable<T> {
	data: T[]
	columns: ColumnDef<T>[]
	haveStatus?: boolean
	isEditable?: boolean
	isDeletable?: boolean
	additionalMultiOptions?: IButtonMultiOption[]
	isLocked?: boolean
	onEditClick?: (object: T) => void
	onDeleteClick?: (object: T) => void
	onDeleteAllClick?: (objects: T[]) => void
	selectable?: boolean
	simpleTable?: boolean
	showHeader?: boolean
	infiniteSize?: boolean
	showBottom?: boolean
	displaySearch?: boolean
	actionsButtons?: JSX.Element
	actionsIcons?: JSX.Element
	orderConfig?: {
		order: string[]
		onOrder?: (any) => void
		accessor?: (any) => string
		preOrder?: {
			order: string[]
			accessorFn: (any) => string
		}
	}
	convertToCsv?: (selected: string[] | null) => { headers: any; data: any }
	RenderSubRow?: any
	customEmptyMessage?: string | JSX.Element
	writePermission?: boolean
	isSubrows?: boolean
	onRowClick?: (row: any) => void
	resetId?: string
	autoResetPage?: boolean
	autoResetSearch?: boolean
	initialSortBy?: {
		desc: boolean
		id: string
	}[]
	$secondaryTableStyle?: boolean
	noDefer?: boolean
	onRowSelectionChangeCallback?: (rowsId: string[]) => void
	additionalLineNumber?: number
	autoResetExpanded?: boolean
	rowSelection?: RowSelectionState
	onSelectedRowsChange?: (rows: any) => void
	displayNumberOfSelectedRows?: boolean
	disabledSearchProperties?: string[]
	disableMenuActions?: boolean
}

const SuperReactTable = <T extends { id: string }>({
	actionsButtons = null,
	actionsIcons = null,
	autoResetExpanded = true,
	autoResetPage = false,
	autoResetSearch = false,
	columns,
	convertToCsv = undefined,
	customEmptyMessage = undefined,
	data,
	displayNumberOfSelectedRows = false,
	displaySearch = true,
	haveStatus = false,
	infiniteSize = isMobile,
	isEditable = true,
	isDeletable = true,
	// writePermission disable their features !
	writePermission = true,
	//* =========================
	isSubrows = false,
	isLocked = false,
	onDeleteClick = (object: (typeof data)[0]) => {},
	onEditClick = (object: (typeof data)[0]) => {},
	onDeleteAllClick = undefined,
	onRowClick = undefined,
	orderConfig = undefined,
	RenderSubRow = undefined,
	resetId = undefined,
	selectable = true,
	showBottom = true,
	showHeader = true,
	simpleTable = false,
	initialSortBy = undefined,
	$secondaryTableStyle = false,
	noDefer = false,
	additionalLineNumber = 0,
	additionalMultiOptions = [],
	rowSelection = {},
	onRowSelectionChangeCallback = (rowsId) => {},
	disabledSearchProperties = [],
	disableMenuActions = false,
}: ISuperReactTable<T>) => {
	const [memoizedSortedBy, setMemoizedSortedBy] = useState([])
	const [reordering, setReordering] = useState(false)
	const { orderedData, handleMove, resetOrder, confirmOrder } = useReorder(
		data,
		orderConfig,
	)
	const [
		forcedFixedElementPerPage,
		setForcedFixedElementPerPage,
		removeFixedElementPerPage,
	] = useLocalStorage("SuperReactTable:pageSize", 10)
	const disrespectHeight =
		forcedFixedElementPerPage !== undefined || infiniteSize
	const editColumn = [
		{
			header: "",
			accessorKey: "edit",
			...widthCustom(50),
			enableSorting: false,
			cell: (props: any) => {
				return (
					<StyledCell
						style={{}}
						hide={reordering}
						noOverflow
						onClick={(event) => {
							onEditClick(props.row.original)
							event.stopPropagation()
						}}
					>
						{!isMobile && (
							<CustomTooltip
								title={writePermission && !isLocked ? "Éditer" : "Voir détail"}
							>
								<RowIconButton>
									<EditIcon style={{ fontSize: 24, padding: 0 }} />
								</RowIconButton>
							</CustomTooltip>
						)}
						{isMobile && (
							<Button
								color="secondary"
								variant="bordered"
								startContent={<EditIcon />}
							>
								{writePermission && !isLocked ? "Éditer" : "Voir détail"}
							</Button>
						)}
					</StyledCell>
				)
			},
			meta: {
				isAction: true,
				isSticky: true,
			},
		},
	] as ColumnDef<T>[]

	const deleteColumn = [
		{
			header: "",
			accessorKey: "delete",
			...widthCustom(50),
			enableSorting: false,
			cell: (props) => (
				<SuperDeleteButton
					onClick={() => {
						onDeleteClick(props.row.original)
					}}
					wrapper={(p) => <StyledCell hide={reordering} noOverflow {...p} />}
					disabled={isLocked}
					CustomIconButton={RowIconButton}
				/>
			),
			meta: {
				isAction: true,
				isSticky: true,
			},
		},
	] as ColumnDef<T>[]

	const augmentedColumn = useMemo(() => {
		const statusColumn = [
			{
				header: "État",
				accessorKey: "État",
				...widthCustom(40),
				cell: (data) => {
					const now = Moment()
					const stockDate = Moment(
						data.row.original.updated_at,
						RUBY_DATE_FORMAT,
					)
					now.subtract(12, "months")
					if (stockDate.isAfter(now)) {
						return (
							<StyledCell>
								<CustomTooltip
									title={`À jour : mis à jour ${stockDate.fromNow()} (${stockDate.format(
										"DD/MM/YYYY",
									)})`}
								>
									<StyledDoneIcon />
								</CustomTooltip>
							</StyledCell>
						)
					}
					now.subtract(24, "months")
					if (stockDate.isAfter(now)) {
						return (
							<StyledCell>
								<CustomTooltip
									title={`À vérifier : mis à jour ${stockDate.fromNow()} (${stockDate.format(
										"DD/MM/YYYY",
									)})`}
								>
									<StyledWarningIcon />
								</CustomTooltip>
							</StyledCell>
						)
					}
					return (
						<StyledCell>
							<CustomTooltip
								title={`Mettre à jour ! : mis à jour ${stockDate.fromNow()} (${stockDate.format(
									"DD/MM/YYYY",
								)})`}
							>
								<StyledErrorIcon />
							</CustomTooltip>
						</StyledCell>
					)
				},
			},
		]

		const reorderingColumn = [
			{
				id: "dnd",
				...width50,
				enableSorting: false,
				header: <div style={{ minHeight: "42px" }}></div>,
				cell: (
					<Flex fullHeight style={{ minHeight: "42px" }}>
						<DragIndicatorIcon fontSize="large" htmlColor="#666666" />
					</Flex>
				),
			},
		]
		const selectionColumn = !selectable
			? []
			: ([
					{
						id: SELECTION_ID,

						...width50,
						enableSorting: false,
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						header: ({ table }) => {
							return (
								<Flex alignItemsStart fullWidth>
									<IndeterminateCheckbox
										{...{
											indeterminate: table.getIsSomeRowsSelected(),
											checked: table.getIsAllRowsSelected(),
											onChange: () => table.toggleAllRowsSelected(),
										}}
									/>
								</Flex>
							)
						},
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						cell: ({ row }) => (
							<StyledCell>
								<IndeterminateCheckbox
									{...{
										checked: row.getIsSelected(),
										onChange: () => row.toggleSelected(),
									}}
								/>
							</StyledCell>
						),
					},
				] as ColumnDef<T>[])

		const mobileDeleteEditColumn = [
			{
				id: "mobileDeleteEdit",
				...width50,
				enableSorting: false,
				header: <div style={{ minHeight: "42px" }}></div>,
				cell: (props) => (
					<Flex fullHeight fullWidth style={{ minHeight: "42px" }} gap="1rem">
						{isEditable && (
							<Button
								variant="bordered"
								color="primary"
								startContent={<EditIcon />}
								$fullWidth
								$fullHeight
								onClick={(event) => {
									onEditClick(props.row.original)
									event.stopPropagation()
								}}
							>
								{writePermission && !isLocked ? "Éditer" : "Voir détail"}
							</Button>
						)}
						{isDeletable && (
							<SuperDeleteButton
								onClick={(event) => {
									onDeleteClick(props.row.original)
									event.stopPropagation()
								}}
							/>
						)}
					</Flex>
				),
				meta: {
					isAction: true,
					isSticky: true,
				},
			},
		]

		const modifiedColumns = improvedCells(columns)
		return [
			...(reordering ? reorderingColumn : selectionColumn),
			...(haveStatus ? statusColumn : []),
			...modifiedColumns,
			...(isEditable && !isMobile ? editColumn : []),
			...(isDeletable && !isMobile ? deleteColumn : []),
			...(isMobile && (isEditable || isDeletable)
				? mobileDeleteEditColumn
				: []),
		]
	}, [
		columns,
		isEditable,
		isDeletable,
		data,
		reordering,
		simpleTable,
		haveStatus,
		selectable,
	])

	const tableData = orderConfig ? orderedData : data

	const customFilter = useSuperReactTableFilter(disabledSearchProperties)

	const table = useReactTable<(typeof data)[0]>({
		columns: augmentedColumn as ColumnDef<
			(typeof data)[0],
			{ $expandable: boolean }
		>[],
		data: tableData,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getRowId: (row) => row.id,
		enableSorting: !reordering,
		autoResetPageIndex: autoResetPage,
		autoResetExpanded: autoResetExpanded,
		globalFilterFn: customFilter,
		initialState: {
			...(initialSortBy && !orderConfig ? { sorting: initialSortBy } : {}),
			rowSelection,
		},
	})
	const tableState = table.getState()

	const selectedFlatRows = tableState.rowSelection
	const selectedFlatRowsIds = Object.entries(selectedFlatRows)
		.map(([id, isSelected]) => (isSelected ? id : null))
		.filter((id) => id !== null)

	const autoNumberOfLines = useAutoPageSize({
		infiniteSize,
		pageSize: tableState.pagination.pageSize,
		setPageSize: table.setPageSize,
		forcedFixedElementPerPage,
		additionalLineNumber,
	})

	const defaultNumberOfLines = forcedFixedElementPerPage ?? autoNumberOfLines

	useEffect(() => {
		table.resetRowSelection()
	}, [data])
	useEffect(() => {
		if (selectedFlatRowsIds.length > 0 && onRowSelectionChangeCallback) {
			onRowSelectionChangeCallback(selectedFlatRowsIds)
		}
	}, [selectedFlatRowsIds, onRowSelectionChangeCallback])

	useEffect(() => {
		table.setGlobalFilter("")
		table.setPageIndex(0)
	}, [resetId])

	useEffect(() => {
		if (
			!_.isEmpty(rowSelection) &&
			!_.isEqual(rowSelection, tableState.rowSelection)
		) {
			table.setRowSelection(rowSelection)
		}
	}, [rowSelection, table])

	const optionsNumberOfElementsPerPage = [
		{
			value: autoNumberOfLines,
			label: String(autoNumberOfLines) + " (auto)",
		},
		{
			value: 3,
			label: "3",
		},
		{
			value: 5,
			label: "5",
		},
		{
			value: 10,
			label: "10",
		},
		{ value: 25, label: "25" },
		{ value: 50, label: "50" },
	]

	const handleStartReorder = () => {
		table.setSorting([])
		table.toggleAllPageRowsSelected(false)
		table.setPageSize(data.length)
		setReordering(true)
	}

	const handleConfirmReorder = () => {
		confirmOrder()
		table.setPageSize(defaultNumberOfLines)
		setReordering(false)
	}

	const handleCancelReorder = () => {
		resetOrder()
		table.setPageSize(defaultNumberOfLines)
		setReordering(false)
	}

	//* Apply previous memoized sortby if exist to prevent sort reset at each re-render
	useEffect(() => {
		if (
			!_.isEmpty(memoizedSortedBy) &&
			tableState.sorting !== memoizedSortedBy &&
			!reordering
		) {
			table.setSorting(memoizedSortedBy)
		}
	}, [data, tableState.sorting, memoizedSortedBy, reordering])

	const selectedFlatRowsLength = Object.values(selectedFlatRows).length

	return (
		<Flex gap="0.75rem" directionColumn fullWidth>
			{!simpleTable && (
				<SPaper>
					<BottomContainer>
						{selectable && selectedFlatRowsLength > 0 && (
							<Flex gap="1rem">
								<div>
									{isMobile && tableState.pagination.pageSize > 0 && (
										<IndeterminateCheckbox
											{...{
												checked: table.getIsAllPageRowsSelected(),
												indeterminate: table.getIsSomePageRowsSelected(),
												onChange: table.getToggleAllPageRowsSelectedHandler(),
											}}
										/>
									)}
									<B>{selectedFlatRowsLength}</B> élément(s) sélectionné(s) sur{" "}
									{tableData.length}
								</div>
							</Flex>
						)}
						<StyledBottomFlex>
							{displaySearch && (
								<ResponsiveFlex $flexGrow={6}>
									<SearchTable
										gotoPage={table.setPageIndex}
										globalFilter={tableState.globalFilter}
										setGlobalFilter={table.setGlobalFilter}
									/>
								</ResponsiveFlex>
							)}
							<ResponsiveFlex $flexGrow={1}>
								{orderConfig?.onOrder &&
									(reordering ? (
										<ButtonMulti
											secondary
											buttonLabel="Confirmer"
											onClick={handleConfirmReorder}
											icon={<PlaylistAddCheckIcon />}
											options={[
												{
													label: "Annuler",
													icon: <CancelIcon />,
													onClick: handleCancelReorder,
													key: "cancel",
												},
												{
													label: "Rétablir l'ordre initial",
													icon: <RestoreIcon />,
													onClick: resetOrder,
													key: "reset",
												},
											]}
										/>
									) : (
										<>
											{writePermission && (
												<div>
													<Button
														color="primary"
														variant="bordered"
														fullWidth
														onClick={handleStartReorder}
														startContent={<ReorderIcon />}
													>
														Ordonner
													</Button>
												</div>
											)}
										</>
									))}

								{selectedFlatRowsLength > 0 && !disableMenuActions && (
									<ButtonRowSelection
										disableMenuActions={disableMenuActions}
										selectedFlatRowsLength={selectedFlatRowsLength}
										selectedFlatRowsIds={selectedFlatRowsIds}
										selectedFlatRows={selectedFlatRows}
										data={data}
										convertToCsv={convertToCsv}
										onDeleteClick={onDeleteClick}
										onDeleteAllClick={onDeleteAllClick}
										additionalMultiOptions={additionalMultiOptions}
										isDeletable={isDeletable}
										writePermission={writePermission}
										isLocked={isLocked}
									/>
								)}
								{selectedFlatRowsLength === 0 && convertToCsv && (
									<ExtractionButtonTable
										selected={selectedFlatRowsIds}
										convertToCsv={convertToCsv}
									/>
								)}
								{(selectedFlatRowsLength === 0 || selectable === false) &&
									actionsButtons}
							</ResponsiveFlex>
						</StyledBottomFlex>
					</BottomContainer>
				</SPaper>
			)}
			{simpleTable && displayNumberOfSelectedRows && selectable && (
				<Flex gap="0.25rem" alignItemsStart fullWidth>
					{isMobile && tableState.pagination.pageSize > 0 && (
						<IndeterminateCheckbox
							{...{
								checked: table.getIsAllPageRowsSelected(),
								indeterminate: table.getIsSomePageRowsSelected(),
								onChange: table.getToggleAllPageRowsSelectedHandler(),
							}}
						/>
					)}
					<B>{selectedFlatRowsIds.length}</B> élément(s) sélectionné(s) sur{" "}
					{tableData.length}
				</Flex>
			)}
			<TableRelativeContainer>
				<STable
					$allowOverflow={!reordering}
					$secondaryTableStyle={$secondaryTableStyle}
				>
					{showHeader && (
						<>
							{isDesktop && (
								<STableHead>
									{table.getHeaderGroups().map((headerGroup) => (
										<TableRow id={headerGroup.id} key={headerGroup.id}>
											{headerGroup.headers.map((header) => {
												const size =
													header.column.columnDef.maxSize > 1000
														? null
														: header.column.columnDef.maxSize
												const isSticky = header.column.columnDef.meta?.isSticky
												return (
													<SSuperReactTableCell
														key={header.id}
														id={header.id}
														style={{
															width: size,
															...(isSticky
																? {
																		position: "sticky",
																		backgroundColor: "white",
																		boxSizing: "border-box",
																	}
																: {}),
														}}
														colSpan={header.colSpan}
													>
														<StyledCell
															colSpan={header.colSpan}
															onClick={(e) => {
																if (header.column.getCanSort()) {
																	if (!header.column.getIsSorted()) {
																		setMemoizedSortedBy([
																			{
																				id: header.column.id,
																				desc: false,
																			},
																		])
																	}
																	if (header.column.getIsSorted()) {
																		if (
																			header.column.getIsSorted() === "desc"
																		) {
																			setMemoizedSortedBy([
																				{
																					id: header.column.id,
																					desc: false,
																				},
																			])
																		} else {
																			setMemoizedSortedBy([
																				{
																					id: header.column.id,
																					desc: true,
																				},
																			])
																		}
																	}
																}
																e.stopPropagation()
															}}
														>
															<Flex directionColumn fullWidth>
																{flexRender(
																	header.column.columnDef.header,
																	header.getContext(),
																)}
																{header.column.columnDef.meta?.canBeFiltered ? (
																	<>
																		{header.column.columnDef.meta.Filter({
																			setGlobalFilter: (value) => {
																				table.setGlobalFilter(
																					value || undefined,
																				)
																				header.column.setFilterValue(
																					value || undefined,
																				)
																			},
																			state: {
																				globalFilter: tableState.globalFilter,
																				filterValue:
																					header.column.getFilterValue(),
																			},
																		})}
																	</>
																) : null}
															</Flex>
															{header.column.getCanSort() && (
																<span>
																	{header.column.getIsSorted() ? (
																		header.column.getIsSorted() === "desc" ? (
																			<div>
																				{"  "}
																				<FlexColumn>
																					<SExpandLessIcon disabled />
																					<SExpandMoreIcon />
																				</FlexColumn>
																			</div>
																		) : (
																			<div>
																				{" "}
																				<FlexColumn>
																					<SExpandLessIcon />
																					<SExpandMoreIcon disabled />
																				</FlexColumn>
																			</div>
																		)
																	) : (
																		<FlexColumn>
																			<SExpandLessIcon disabled />
																			<SExpandMoreIcon disabled />
																		</FlexColumn>
																	)}
																</span>
															)}
														</StyledCell>
													</SSuperReactTableCell>
												)
											})}
										</TableRow>
									))}
								</STableHead>
							)}
							<DragDropContext onDragEnd={handleMove}>
								<STableBody
									component={reordering ? DroppableTableBody : TableBody}
								>
									<Defer noDefer={noDefer}>
										{table.getRowModel().rows.map((row, index) => {
											return (
												<React.Fragment key={row.id}>
													<MainTableRow
														$expandedRow={row.getIsExpanded()}
														$disrespectHeight={disrespectHeight}
														$clickable={onRowClick !== undefined}
														onClick={() => onRowClick?.(row)}
														$removeBorder={
															!reordering && RenderSubRow && isDesktop
														}
														component={
															reordering
																? makeDraggable(WhiteTableRow, {
																		id: row.original.id,
																		index,
																		fullHandle: true,
																	})
																: undefined
														}
													>
														{row.getVisibleCells().map((cell) => {
															const cellColumn = cell.column as any
															const isAction =
																cell.column.columnDef?.meta?.isAction
															const isSelectionID =
																cell.column.columnDef.id === SELECTION_ID

															const size =
																cell.column.columnDef.maxSize > 1000
																	? null
																	: cell.column.columnDef.maxSize
															const isSticky = isAction && !isMobile

															const columnIndex = augmentedColumn.findIndex(
																(column) =>
																	(column?.accessorKey ?? column?.id) ===
																	(cell.column.columnDef?.accessorKey ??
																		cell.column.columnDef?.id),
															)
															const numberOfSticky = augmentedColumn
																.slice(columnIndex, augmentedColumn.length)
																.filter(
																	(column) => column.meta?.isSticky,
																).length
															const rightDistanceSticky =
																(numberOfSticky - 1) * 50
															const isMinimumIndexSticky =
																Math.min(
																	...augmentedColumn
																		.map((column, index) => ({
																			isSticky: column.meta?.isSticky,
																			index,
																		}))
																		.filter(({ isSticky }) => isSticky)
																		.map(({ index }) => index),
																) === columnIndex

															return (
																<SSuperReactTableCell
																	{...{
																		key: cell.id,
																		style: {
																			width: size,
																			minWidth: size ?? 120,
																			...(isSticky
																				? {
																						position: "sticky",
																						right: rightDistanceSticky,
																						backgroundColor: "white",
																						boxShadow:
																							"rgba(0, 0, 0, 0.21) -2px 0px 4px -2px",
																						boxSizing: "border-box",
																					}
																				: {}),
																		},
																	}}
																	$isSticky={isSticky}
																	$isMinimumIndexSticky={isMinimumIndexSticky}
																	$expandable={
																		cell.column.columnDef.meta?.expandableCell
																	}
																	$expanded={row.getIsExpanded()}
																>
																	{isMobile && (
																		<Flex fullWidth spaceBetween>
																			<div
																				{...(!isAction
																					? {
																							style: {
																								maxWidth: "50%",
																							},
																						}
																					: {})}
																			>
																				{!isSelectionID &&
																					flexRender(
																						cell.column.columnDef.header,
																						cell.getContext(),
																					)}
																			</div>
																			<div
																				{...(!isAction
																					? {
																							style: {
																								maxWidth: "50%",
																							},
																						}
																					: {})}
																			>
																				{flexRender(
																					cell.column.columnDef.cell,
																					cell.getContext(),
																				)}
																			</div>
																		</Flex>
																	)}
																	{!isMobile &&
																		flexRender(
																			cell.column.columnDef.cell,
																			cell.getContext(),
																		)}
																</SSuperReactTableCell>
															)
														})}
													</MainTableRow>
													{isMobile && !reordering && RenderSubRow && (
														<SubTableRow $allowOverflow={row.getIsExpanded()}>
															<SubTableCell colSpan={augmentedColumn.length}>
																<Collapse in={row.getIsExpanded()}>
																	{row.getIsExpanded() && (
																		<RenderSubRow row={row} />
																	)}
																</Collapse>
															</SubTableCell>
														</SubTableRow>
													)}
													{isDesktop && !reordering && RenderSubRow && (
														<SubTableRow $allowOverflow={row.getIsExpanded()}>
															<SubTableCell colSpan={augmentedColumn.length}>
																<Collapse in={row.getIsExpanded()}>
																	{row.getIsExpanded() && (
																		<RenderSubRow row={row} />
																	)}
																</Collapse>
															</SubTableCell>
														</SubTableRow>
													)}
												</React.Fragment>
											)
										})}
									</Defer>
									{_.isEmpty(data) && (
										<RowContainer>
											<NoDataRow>
												<NoDataCell>
													{customEmptyMessage ?? "Aucune donnée à afficher"}
												</NoDataCell>
											</NoDataRow>
										</RowContainer>
									)}
								</STableBody>
							</DragDropContext>
						</>
					)}
				</STable>
			</TableRelativeContainer>

			{showBottom && (isDesktop || tableState.pagination.pageSize > 0) && (
				<SPaper>
					<BottomSuperTable
						setForcedFixedElementPerPage={setForcedFixedElementPerPage}
						optionsNumberOfElementsPerPage={optionsNumberOfElementsPerPage}
						setPageSize={table.setPageSize}
						pageSize={tableState.pagination.pageSize}
						defaultNumberOfLines={defaultNumberOfLines}
						previousPage={() =>
							table.setPageIndex(tableState.pagination.pageIndex - 1)
						}
						nextPage={() =>
							table.setPageIndex(tableState.pagination.pageIndex + 1)
						}
						pageIndex={tableState.pagination.pageIndex}
						gotoPage={table.setPageIndex}
						pageCount={table.getPageCount()}
						dataLenght={data.length}
						isInfinite={infiniteSize}
						removeFixedElementPerPage={removeFixedElementPerPage}
					/>
				</SPaper>
			)}
		</Flex>
	)
}

export const improvedCells = (columns) =>
	columns.map((column) => ({
		...column,
		...(_.isEmpty(column.cell)
			? {}
			: {
					cell: ({ getValue, cell }) => {
						const size =
							cell.column.columnDef.maxSize > 1000
								? "unset"
								: cell.column.columnDef.maxSize
						return (
							<StyledCell
								style={{
									width: size,
								}}
							>
								{getValue()}
							</StyledCell>
						)
					},
				}),
	}))
export default SuperReactTable
