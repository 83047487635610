import Axios from "axios"

const getCommune = async () => {
	const result = await Axios.get("/api/communes/self")
	return result.data
}

const getPublicCommune = async (communeName) => {
	const result = await Axios.get(`/api/picrim/commune/${communeName}`)
	return result
}

const getCommunesCdc = async () => {
	const result = await Axios.get("/api/communes/cdc")
	return result.data
}

const updateCommune = async (data) => {
	const result = await Axios.put("/api/communes/self", { data })
	return result.data
}

const setRisks = async (data) => {
	const result = await Axios.post("/api/communes/risks", { data })
	return result.data
}

const setAdminRisks = async ({ risks, commune_id }) => {
	const result = await Axios.put(`/api/communes/risks/${commune_id}`, {
		data: risks,
	})
	return result.data
}

export default {
	getCommune,
	getPublicCommune,
	getCommunesCdc,
	updateCommune,
	setRisks,
	setAdminRisks,
}
