import React, { useMemo } from "react"
import ExtractionBouton from "@/styles/atoms/Button/specialized/ExtractionBouton"
import styled from "styled-components"
import { Alert } from "@mui/material"
import { IFamilyMemberType } from "@/utils/types/IFamilyMember"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { ModalContent } from "@nextui-org/react"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
const SAlert = styled(Alert)`
    width: 100%;
`

const ModalExtractDownload = ({ isOpenNext, setIsOpenNext, data }) => {
	const csvData = useMemo(() => {
		if (data === undefined || data.length === 0) {
			return
		}
		const header = [
			"Heure d'arrivée",
			"Référent",
			"Membre",
			"Spécificité(s)",
			"Statut",
			"Téléphone",
			"Commentaires",
			"Date de sortie",
			"Lieu de sortie",
		]
		const csvRows = [header]

		data.values.forEach((element) => {
			const properties = element.geojson.properties
			if (
				!data.filters ||
				((!data.filters.enteringDateFilter ||
					properties["Heure arrivée"]?.slice(0, 10) ===
						data.filters.enteringDateFilter) &&
					(!data.filters.leavingDateFilter ||
						properties["Date de sortie"]?.slice(0, 10) ===
							data.filters.leavingDateFilter) &&
					(!data.filters.specFilter ||
						properties["Spécificités"]?.includes(data.filters.specFilter)) &&
					(!data.filters.typoFilter ||
						data.filters.typoFilter === IFamilyMemberType.ADULT))
			) {
				csvRows.push([
					`=""${properties["Heure arrivée"]}""`,
					`=""${properties["Identité"]}""`,
					`=""${properties["Identité"]}""`,
					`=""${properties["Spécificités"]}""`,
					`=""${properties["Statut"]}""`,
					`=""${properties["Téléphone"]}""`,
					`=""${properties["besoin"]}""`,
					`=""${properties["Date de sortie"] ?? ""}""`,
					`=""${properties["Lieu de sortie"] ?? ""}""`,
				])
			}
			properties["family"].forEach((member) => {
				if (
					!data.filters ||
					((!data.filters.enteringDateFilter ||
						properties["Heure arrivée"]?.slice(0, 10) ===
							data.filters.enteringDateFilter) &&
						(!data.filters.leavingDateFilter ||
							member["Date de sortie"]?.slice(0, 10) ===
								data.filters.leavingDateFilter) &&
						(!data.filters.specFilter ||
							member["Spécificités"]?.includes(data.filters.specFilter)) &&
						(!data.filters.typoFilter ||
							member.type === data.filters.typoFilter))
				) {
					csvRows.push([
						`=""${properties["Heure arrivée"]}""`,
						`=""${properties["Identité"]}""`,
						`=""${member["Identité"]}""`,
						`=""${member["Spécificités"] ?? ""}""`,
						`=""${member["Statut"]}""`,
						`=""${properties["Téléphone"]}""`,
						`=""${member["Commentaire"] ?? ""}""`,
						`=""${member["Date de sortie"] ?? ""}""`,
						`=""${member["Lieu de sortie"] ?? ""}""`,
					])
				}
			})
		})

		return csvRows
	}, [data])

	return (
		<Modal isOpen={isOpenNext} onClose={() => setIsOpenNext(false)}>
			<ModalContent>
				<ModalHeader>Télécharger</ModalHeader>
				<ModalBody>
					<SAlert severity="info">
						Préparation terminée, les données sont désormais prêtes à
						l&apos;extraction et au téléchargement (.csv)
					</SAlert>
				</ModalBody>
				<ModalFooter>
					<CancelButton onClick={() => setIsOpenNext(false)} />
					<ExtractionBouton
						callBack={() => {
							setIsOpenNext(false)
						}}
						buttonTitle="Extraire"
						title={data?.title}
						data={csvData ?? [[]]}
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default ModalExtractDownload
