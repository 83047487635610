import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { fr } from "date-fns/locale"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import { FStandardProps } from "utils/components/jsonSchema/form/FPointProperty"
import { TimePicker } from "@mui/x-date-pickers"

const FTime = ({
	name,
	label = undefined,
	readOnly = false,
	disabled = false,
}: FStandardProps) => {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
			<TimePicker
				readOnly={disabled}
				format="HH:mm"
				label={label ?? name}
				value={value ? new Date(value) : null}
				onChange={(date) => {
					// check if date is valid
					if (date?.toString() === "Invalid Date") {
						return
					}
					onChange(date?.toISOString())
				}}
				disabled={disabled || readOnly}
			/>
		</LocalizationProvider>
	)
}

export default FTime
