import React, { useEffect } from "react"
import useStep from "@/pages/telechargement/Upload/hooks/useStep"
import FormStep from "@/pages/telechargement/Upload/component/FormStep"
import { Flex } from "utils/components/style/flex"
import TileLayerService from "utils/services/TileLayerService"
import FSelect from "utils/form/FSelect"

const choices = []
Object.keys(TileLayerService.getAllLayers()).forEach((key) => choices.push(key))

const StepLayer = ({ setTempValue, ...props }) => {
	const { value, onBack } = useStep("layer")
	useEffect(() => {
		setTempValue(value)
	}, [value])

	return (
		<FormStep
			question=""
			formatedValue=""
			onBack={onBack}
			clearStyles
			{...props}
		>
			<Flex gap wrap>
				<FSelect name="layer" label="Fond de carte" options={choices} />
			</Flex>
		</FormStep>
	)
}

export default StepLayer
