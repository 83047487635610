import { Radio } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useStockPlacesAndCare from "@/hooks/useStockPlacesAndCare"
import { deletePoint } from "@/redux-toolkit/data/points/resources"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React, { useMemo, useEffect } from "react"
import { useDispatch } from "react-redux"
import ButtonMulti from "utils/components/button/ButtonMulti/ButtonMulti"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width80 } from "utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import ModalBodyForTable from "utils/modals/ModalBodyForTable"
import { StaticFormType } from "utils/services/StaticFormsService"
import useKeyboardJs from "utils/keyboard/useKeyboardJs"
import { ColumnDef } from "@tanstack/react-table"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import Modal from "@/styles/organisms/Modal"
const FModalStockPlaceAndCare = ({ isOpen, onClose, onChange, value }) => {
	const stockPlaceAndCare = useStockPlacesAndCare()
	const dispatch = useDispatch()
	const jsonSchemas = useJsonSchemasWithStatics()
	const [isPressed] = useKeyboardJs("esc")
	const writePermission = jsonSchemas[StaticFormType.CARE]?.write

	useEffect(() => {
		if (isPressed && isOpen) {
			onClose()
		}
	}, [isPressed, isOpen])

	const onSingleSelectChange = (row) => {
		onChange(row.original.id)
		onClose()
	}

	const singleSelectColumn = (props) => {
		return (
			<Radio
				checked={props.row.original.id === value?.value}
				onChange={(event) => {
					if (event.target.checked) {
						onSingleSelectChange(props.row)
					}
				}}
			/>
		)
	}

	const columns = useMemo(
		() =>
			[
				{
					header: "Choisir",
					accessorKey: "choose",
					cell: singleSelectColumn,
					...width80,
				},
				{
					header: "Type",
					accessorKey: "jsonschema_id",
				},
				{
					header: "Lieu",
					accessorKey: "geojson.properties.Lieu",
				},
			] as ColumnDef<any>[],
		[onChange, value, onClose],
	)

	const data = useMemo(
		() => Object.values(stockPlaceAndCare),
		[stockPlaceAndCare],
	)

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalHeader>Sélectionner un lieu de stockage ou CARE</ModalHeader>
			<ModalBodyForTable>
				<SuperReactTable
					onRowClick={onSingleSelectChange}
					actionsButtons={
						writePermission && (
							<ButtonMulti
								buttonLabel="Ajouter un Lieu de stockage / CARE"
								options={[
									{
										key: "stockplace",
										label: "Ajouter un lieu de stockage",
										icon: (
											<img
												width="30px"
												src={getImage(StaticFormType.STOCK_PLACE)}
											/>
										),
										onClick: () => {
											dispatch(
												setCurrentPoint({
													jsonschema_id: StaticFormType.STOCK_PLACE,
												}),
											)
										},
									},
									{
										key: "care",
										label: "Ajouter un CARE",
										icon: (
											<img width="30px" src={getImage(StaticFormType.CARE)} />
										),
										onClick: () => {
											dispatch(
												setCurrentPoint({
													jsonschema_id: StaticFormType.CARE,
												}),
											)
										},
									},
								]}
							/>
						)
					}
					selectable={false}
					data={data}
					columns={columns}
					isEditable={writePermission}
					isDeletable={writePermission}
					onEditClick={(stockplace) => dispatch(setCurrentPoint(stockplace))}
					onDeleteClick={(stockplace) => dispatch(deletePoint(stockplace))}
				/>
			</ModalBodyForTable>
		</Modal>
	)
}

export default FModalStockPlaceAndCare
