import colored from "@/utils/colored"
import { Button } from "@nextui-org/react"
import styled, { css } from "styled-components"

export const StyledButton = colored(styled(Button)`
    ${({ variant }) => {
			switch (variant) {
				case "bordered":
					return css`
                    border-width: var(--nextui-border-width-small)
                `
			}
		}}
`)
