import React, { useMemo } from "react"
import Div from "./Div"
import makeDroppable from "./makeDroppable"

function SuperDroppable<P>({
	component = Div,
	droppableProps = undefined,
	children,
	...props
}: {
	component?: React.ComponentType<P>
	droppableProps?: any
	children: React.ReactNode
} & P) {
	const SuperComponent = useMemo(() => makeDroppable(component), [component])
	return (
		<SuperComponent {...props} droppableProps={droppableProps}>
			{children}
		</SuperComponent>
	)
}

export default SuperDroppable
