import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import usePoints from "@/hooks/usePoints"
import { Neutral500Text, Small } from "../style/text"
import getImage from "@/utils/getImage"
import { Flex } from "../style/flex"
import IJsonSchema from "@/utils/types/IJsonSchema"

// app:javascript:utils:components:jsonSchema:JsonSchemaHeader.tsx
const debug = Debug(
	"app:javascript:utils:components:jsonSchema:JsonSchemaHeader",
)
debug.log = console.log.bind(console)

const JsonSchemaHeader = ({
	jsonSchema,
	rightContent,
}: {
	jsonSchema: IJsonSchema
	rightContent?: JSX.Element
}) => {
	const points = usePoints()
	const concernedPoints = useMemo(() => {
		return Object.values(points).filter(
			(point) => point.jsonschema_id === jsonSchema?.id,
		)
	}, [points, jsonSchema])

	return (
		<Flex gap="1rem" fullWidth>
			<img src={getImage(jsonSchema?.imgId)} width={45} height={45} />
			<div>
				{jsonSchema?.title}
				<Flex fullWidth spaceBetween>
					<Neutral500Text>
						<Small>{concernedPoints.length} données</Small>
					</Neutral500Text>
					{rightContent}
				</Flex>
			</div>
		</Flex>
	)
}

export default JsonSchemaHeader
