import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import MapService from "@/pages/carto2/cartographie/service/MapService"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { setPanel } from "@/redux-toolkit/common/reducer"
import Debug from "debug"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import VisibilityIconOff from "@mui/icons-material/VisibilityOff"
import MultiPolygonCard from "utils/components/multiPolygon/MultiPolygonCard"
import {
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import {
	DAYBOOK_COUCHE,
	DAYBOOK_DATA,
	STYLE_EDITOR_POLYGON,
} from "./PanelCarto"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import { Divider, IconButton, Tooltip } from "@mui/material"
import Palette from "@mui/icons-material/Palette"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import { SectionTitle } from "@/pages/maincourante/care/CareResumeCard"
import Visibility from "@mui/icons-material/Visibility"
import useMapState from "@/hooks/useMapState"
import ModalEditCoucheInfos from "utils/modals/ModalEditCoucheInfos"
import EditIcon from "@mui/icons-material/Edit"
import { useBoolean } from "react-use"

const debug = Debug("app:javascript:utils:panels:PanelDaybookMultipolygon")
debug.log = console.log.bind(console)

const Container = styled.div`
    margin: 1rem;
`
const SIconButton = styled(IconButton)`
    padding: 0.5rem;
`
const SVisibility = styled(Visibility)`
    color: var(--primary500);
`
const SDivider = styled(Divider)`
    width: 100%;
`
const PanelDaybookMultipolygon = () => {
	const daybookPannel = useSelector((state: any) => state.common.panel)
	const multipolygons = useMultiPolygons()
	const { coucheMultiPolygons, toggleVisibilityMultiPolygons } = useMapState()
	const currentMultipolygon = multipolygons[daybookPannel.id]
	const dispatch = useDispatch()
	const jsonSchemas = useJsonSchemasWithStatics()
	const [isOpenEditInfos, setIsOpenEditInfos] = useBoolean(false)
	const jsonSchema = jsonSchemas[currentMultipolygon?.id]
	const multiPolygonCartoStatus = coucheMultiPolygons[currentMultipolygon?.id]
	const visible = multiPolygonCartoStatus?.visibility
	const visibleText = visible ? "Masquer l'élément" : "Afficher l'élément"

	useEffect(() => {
		const geolocProperty = jsonSchema?.template?.properties?.find(
			(property) => property.type === "geoloc",
		)
		if (!geolocProperty) {
			return
		}
		const geoloc = currentMultipolygon //add keys
		if (!geoloc) {
			return
		}
		MapService.getMap()?.setView([null, null])
	}, [currentMultipolygon])

	return (
		<>
			<Flex directionColumn margin="1rem" gap="1.5rem" alignItemsStart>
				<PrimaryOutlinedButton
					onClick={() => {
						dispatch(setPanel({ type: DAYBOOK_DATA }))
					}}
				>
					<ArrowBackIcon />
					Retour aux données
				</PrimaryOutlinedButton>
				<Flex gap="1rem">
					<MultiPolygonColorSquare multiPolygon={currentMultipolygon} />
					<SectionTitle>{currentMultipolygon?.name ?? "Couche"}</SectionTitle>
				</Flex>
				<Flex directionColumn fullWidth gap="0.25rem" alignItemsStart>
					<SDivider />
					<Flex gap="1rem" alignItemsStart>
						<Tooltip title={visibleText} arrow>
							<SIconButton
								onClick={() =>
									dispatch(
										toggleVisibilityMultiPolygons(currentMultipolygon?.id),
									)
								}
							>
								{visible ? (
									<SVisibility fontSize="small" />
								) : (
									<VisibilityIconOff fontSize="small" />
								)}
							</SIconButton>
						</Tooltip>
						<Tooltip title="Modifier le style" arrow>
							<IconButton
								onClick={() => {
									dispatch(
										setPanel({
											type: STYLE_EDITOR_POLYGON,
											entityId: daybookPannel.id,
										}),
									)
								}}
							>
								<Palette fontSize="small" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Renommer la couche" arrow>
							<IconButton onClick={() => setIsOpenEditInfos(true)}>
								<EditIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Flex>
					<SDivider />
				</Flex>
				<Flex spaceBetween fullWidth gap="1em">
					<div>Note</div>
					{currentMultipolygon?.custom_props?.details?.length > 0
						? currentMultipolygon?.custom_props?.details
						: "Aucune note pour cette couche."}
				</Flex>
			</Flex>
			<ModalEditCoucheInfos
				isOpenEditInfos={isOpenEditInfos}
				setIsOpenEditInfos={setIsOpenEditInfos}
				multiPolygon={multipolygons[daybookPannel.id]}
				extraActions={() => dispatch(setPanel(null))}
			/>
		</>
	)
}

export default PanelDaybookMultipolygon
