export const SYNCHRO_HUMAN = "SYNCHRO_HUMAN"
export const CREATE_PDF = "CREATE_PDF"
export const CREATE_PDF_GENERATOR = "CREATE_PDF_GENERATOR"
export const SYNCHRO_STOCK_PLACE = "SYNCHRO_STOCK_PLACE"
export const SYNCHRO_STOCK = "SYNCHRO_STOCK"
export const SYNCHRO_POINT = "SYNCHRO_POINT"
export const SYNCHRO_EVENT = "SYNCHRO_EVENT"
export const SYNCHRO_INITIAL = "SYNCHRO_INITIAL"
export const SYNCHRO_FICHE = "SYNCHRO_FICHE"
export const SYNCHRO_MULTIPOLYGON = "SYNCHRO_MULTIPOLYGON"
export const SYNCHRO = "SYNCHRO"
