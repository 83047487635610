export enum IFamilyMemberType {
	CHILD = "CHILD",
	ADULT = "ADULT",
	BABY = "BABY",
	ANIMAL = "ANIMAL",
}

interface IFamilyMember {
	type: IFamilyMemberType
	Identité: string
	Spécificités: string[]
	besoin: string
	quantity?: number
}

export default IFamilyMember
