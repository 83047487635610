import PcsData from "@/pages/customdata/pcsData/PcsData"
import React from "react"
import { ModalContent } from "@nextui-org/react"
import Modal from "@/styles/organisms/Modal"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import Button from "@/styles/atoms/Button"

const ModalPCSCategory = (props) => {
	const { isOpenModal, onClose, setPcsCategory, title } = props

	return (
		<Modal isOpen={isOpenModal} onClose={onClose} size={'4xl'}>
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalBody>
					<PcsData
						setPcsCategory={setPcsCategory}
						actionOnFinalSelect={onClose}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" variant="light" onClick={onClose}>Annuler</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalPCSCategory
