import { Alert, Grid } from "@mui/material"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ErrorService from "@/services/ErrorService"
import { Flex } from "utils/components/style/flex"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch } from "react-redux"
import Check from "@mui/icons-material/Check"
const B = styled.b`
    color: var(--primary800);
`

const SCloudUploadIcon = styled(CloudUploadIcon)`
    color: var(--neutral400);
`

const F12 = styled.div`
    font-size: var(--font-size-small);
    color: var(--primary800);
`

const SFlex = styled(Flex)`
    border-style: dashed;
    border-color: var(--primary800);
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
`
const Container = styled.div`
    padding: 0.5rem;
`
const DecoratedContainer = styled.div`
    border: 1px solid var(--green500);
    border-radius: 5px;
    border-style: dashed;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`
const SCheck = styled(Check)`
    color: var(--green500);
`
const FUploadGeojson = ({ name, label }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const dispatch = useDispatch()

	const {
		field: { ref, value = [], onChange, ...inputProps },
	} = useController({
		name,
		control,
		defaultValue: "",
	})
	return (
		<Container>
			{!value.length && (
				<Dropzone
					onDrop={async (files: any) => {
						if (files.length > 1) {
							ErrorService.error({
								component: "FormikUpload:onDrop",
								message: "un seul fichier autorisé",
								dispatch,
							})
							return
						}
						const reader = new FileReader()
						reader.addEventListener("load", (event) => {
							onChange(event.target.result)
						})
						reader.readAsText(files[0])
					}}
				>
					{({ getRootProps, getInputProps }) => (
						<SFlex {...getRootProps()}>
							<SCloudUploadIcon />
							<input {...getInputProps()} />
							<div>
								<F12>
									Faire glisser les fichiers à téléverser ou{" "}
									<B>Parcourir vos fichiers</B> (geojson uniquement)
								</F12>
							</div>
						</SFlex>
					)}
				</Dropzone>
			)}
			{value.length > 0 && (
				<Grid container spacing={2}>
					<Grid item sm={9}>
						<DecoratedContainer>
							<SCheck /> <div>Fichier uploadé</div>
						</DecoratedContainer>
					</Grid>
					<Grid item sm={3}>
						<PrimaryOutlinedButton
							startIcon={<EditIcon />}
							onClick={() => onChange([])}
						>
							Remplacer le fichier
						</PrimaryOutlinedButton>
					</Grid>
				</Grid>
			)}
		</Container>
	)
}

export default FUploadGeojson
