import { Text } from "@react-pdf/renderer"
import React from "react"

const NormalText = ({ children, marginBottom = 0, style = {} }) => {
	return (
		<Text
			style={{
				fontSize: 11,
				fontFamily: "Roboto Mono",
				marginBottom,
				...style,
			}}
		>
			{children}
		</Text>
	)
}

export default NormalText
