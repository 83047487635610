import SignalService from "@/pages/carto2/cartographie/service/SignalService"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import useMapState from "@/hooks/useMapState"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { setCurrentIncident } from "@/redux-toolkit/edit/reducer"
import {
	INCIDENT,
	INTERVENTION,
	PATH_MAIN_COURANTE_CARTOGRAPHY,
	SUIVI_PCS,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import DatabaseService from "@/services/DatabaseService"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { GeoJSON, Marker, Pane, useMapEvents } from "react-leaflet"
import MarkerClusterGroup from "@changey/react-leaflet-markercluster"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import getPolygonStyle from "@/utils/components/map/polygon/getPolygonStyle"
import SuperMap from "@/utils/components/map/SuperMap"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import {
	DAYBOOK_COUCHE,
	DAYBOOK_POINT,
	DAYBOOK_SIGNAL,
	DAYBOOK_SUPPORTS_LIST,
	DAYBOOK_SUPPORT_POINT,
} from "@/utils/panels/PanelCarto"
import { ScreenShotContext } from "./DaybookDashboard"
import DaybookMapContextMenu from "./DaybookMapContextMenu"
import MarkerDaybook from "./subComponents/carto/MarkerDaybook/MarkerDaybook"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import {
	STATUS_ALL,
	toggleDraggableSignalMarkers,
	toggleShowedFacility,
} from "@/redux-toolkit/daybook/reducer"
import MarkerDaybookStatic from "./subComponents/carto/MarkerDaybook/MarkerDaybookStatic"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const FastCreateEvent = ({ isTakingScreenshotMap, noInteraction }) => {
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked
	const permissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)

	useMapEvents({
		dblclick: (e) => {
			if (
				isTakingScreenshotMap ||
				!permissions.write ||
				noInteraction ||
				isLocked
			) {
				return
			}

			dispatch(
				setCurrentIncident({
					Géolocalisation: {
						coo: { lat: e.latlng.lat, lng: e.latlng.lng },
						Adresse: "",
					},
				}),
			)
		},
	})
	return null
}

interface ISuperMapProps {
	displayOptions?: {
		displayMinimap?: boolean
		displayBottomControl?: boolean
		displayRightControl?: boolean
		forceUnclustering?: boolean
	}
	noInteraction?: boolean
	hasSearchControl?: boolean
	mapDimensions?: {
		width?: string
		height?: string
		maxWidth?: string
		maxHeight?: string
	}
}

const DaybookMap = ({
	disableMargin = false,
	setMapScreenBlob = undefined,
	resize = true,
	superMapProps = {},
}: {
	disableMargin?: boolean
	setMapScreenBlob?: React.Dispatch<any>
	resize?: boolean
	superMapProps?: ISuperMapProps
}) => {
	const permissions = useAccessRights(EnumToolPath.GC_CARTOGRAPHY)
	const history = useHistory()

	const [multiPolygonsToDisplay, setMultiPolygonsToDisplay] = useState([])
	const { coucheJsonSchemas, coucheMultiPolygons, drawGeojson, surfaceOrder, showedFacility } =
		useMapState()
	const coucheEvents = useSelector(
		(state: any) => state.daybook?.coucheEvents ?? {},
	)
	const { isTakingScreenshotMap } = useContext(ScreenShotContext)
	const dispatch = useDispatch()
	const draggableSignalMarkers = useSelector(
		(state: any) => state.daybook.draggableSignalMarkers,
	)
	const selectedEvent = useSelectedEvent()
	const currentIncident = useSelector(
		(state: any) => state.edit.currentIncident,
	)
	const points = usePoints()
	const isLocked = selectedEvent?.locked

	const concernedIncident = Object.values(points).filter(
		(point) =>
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === INCIDENT &&
			(point.geojson.properties.Statut ===
				coucheJsonSchemas[INCIDENT].filterBy ||
				coucheJsonSchemas[INCIDENT].filterBy === STATUS_ALL),
	)

	const concernedInterventions = Object.values(points).filter(
		(point) =>
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === INTERVENTION &&
			(point.geojson.properties.Statut ===
				coucheJsonSchemas[INTERVENTION].filterBy ||
				coucheJsonSchemas[INTERVENTION].filterBy === STATUS_ALL),
	)

	const concernedCare = Object.values(points).filter(
		(point) =>
			point.jsonschema_id === TEMPORARY_CARE &&
			point.event_id === selectedEvent?.id,
	)

	const [isPressed] = useKeyboardJs("ctrl + space")

	useEffect(() => {
		if (isPressed && currentIncident === undefined && permissions.write) {
			dispatch(
				setCurrentIncident({
					geojson: { properties: { Objet: "" } },
				}),
			)
		}
	}, [isPressed])

	const [isPressedTab] = useKeyboardJs("space + tab", true)

	useEffect(() => {
		if (isPressedTab) {
			history.push("/maincourante/donnees")
		}
	}, [isPressedTab])

	const panel = useSelector((state: any) => state.common.panel)

	const multiPolygons = useMultiPolygons()

	const { clusterPoints, noClusterPoints } = useMemo(() => {
		const visibleCouches = Object.values(coucheJsonSchemas ?? {})
			.filter((couche: any) => couche.visibility)
			.map((couche: any) => couche.id)
		const supports = showedFacility
			? Object.values(points).filter((point) =>
				point.geojson.properties.suivi_group_id === showedFacility
			)
			: []

		const concernedSupportIds = supports.map((support) => support.geojson.properties.relatedObject.objectId)

		const filteredPoints = Object.values(points).filter(
			(point) =>
				visibleCouches.includes(point.jsonschema_id) &&
				![INCIDENT, INTERVENTION].includes(point.jsonschema_id),
		).filter((point) => {
			if (!showedFacility) return true
			return concernedSupportIds.includes(point.id) || point.jsonschema_id === TEMPORARY_CARE
		})

		const concernedPoints = [
			...concernedIncident,
			...concernedInterventions,
			...filteredPoints,
		]

		const clusterPoints = []
		const noClusterPoints = []

		concernedPoints.filter((point) => {
			const coucheJsonSchema = Object.values(coucheJsonSchemas ?? {}).find(
				(couche: any) => couche.id === point.jsonschema_id,
			) as any
			if (
				coucheJsonSchema.cluster &&
				!superMapProps?.displayOptions?.forceUnclustering
			) {
				clusterPoints.push(point)
			} else {
				noClusterPoints.push(point)
			}
		})

		return {
			clusterPoints,
			noClusterPoints,
		}
	}, [points, coucheJsonSchemas, showedFacility])

	useEffect(() => {
		const processMultiPolygon = async () => {
			const visibleCouches = Object.values(coucheMultiPolygons ?? {})
				.filter((couche: any) => couche.visibility)
				.map((couche: any) => couche.id)
			const multiPolygonToRender = Object.values(multiPolygons).filter(
				(polygon: any) => visibleCouches.includes(polygon.id),
			)
			const geo = []
			await Promise.all(
				multiPolygonToRender.map(async (vcouche: any) => {
					const data = await DatabaseService.get(vcouche.id)
					if (data) {
						geo.push({
							...vcouche,
							geojson: data,
						})
					}
					return
				}),
			)
			setMultiPolygonsToDisplay(geo)
		}
		processMultiPolygon()
	}, [multiPolygons, coucheMultiPolygons])

	const onDoubleClick = (event) => {
		if (
			isTakingScreenshotMap ||
			!permissions.write ||
			superMapProps.noInteraction ||
			isLocked
		) {
			return
		}
		dispatch(
			setCurrentIncident({
				Géolocalisation: {
					coo: { lat: event.latlng.lat, lng: event.latlng.lng },
					Adresse: "",
				},
			}),
		)
	}

	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	const checkFiltered = () => {
		const concernedPoints = []

		if (!superMapProps?.displayOptions?.forceUnclustering) {
			if (!coucheJsonSchemas[INCIDENT].cluster)
				concernedPoints.push(...concernedIncident)
			if (!coucheJsonSchemas[INTERVENTION].cluster)
				concernedPoints.push(...concernedInterventions)
			if (!coucheJsonSchemas[TEMPORARY_CARE].cluster)
				concernedPoints.push(...concernedCare)
		} else {
			concernedPoints.push(
				...[...concernedIncident, ...concernedInterventions, ...concernedCare],
			)
		}

		return concernedPoints.map((point) => (
			<MarkerDaybookStatic
				key={point.id}
				point={point}
				noInteraction={superMapProps.noInteraction}
			/>
		))
	}

	const getSupportIdFromPointId = (pointId) => {
		let supportId

		Object.values(points).filter((point) =>
			point.geojson.properties["suivi_group_id"] === showedFacility
		).forEach((support) => {
			if (support.geojson.properties.relatedObject.objectId === pointId) {
				return supportId = support.id
			}
		})

		return supportId
	}

	useEffect(() => {
		if (!showedFacility || !panel) return
		if (![DAYBOOK_SUPPORTS_LIST, DAYBOOK_SUPPORT_POINT].includes(panel.type)) {
			dispatch(toggleShowedFacility({ id: null }))
		}
	}, [panel])

	return (
		<SuperMap
			onDoubleClick={onDoubleClick}
			disableMargin={disableMargin}
			setMapScreenBlob={setMapScreenBlob}
			resize={resize}
			{...superMapProps}
		>
			<DaybookMapContextMenu>
				<FastCreateEvent
					isTakingScreenshotMap={isTakingScreenshotMap}
					noInteraction={superMapProps.noInteraction}
				/>
				<MarkerClusterGroup key={Date.now()} maxClusterRadius={40}>
					{clusterPoints.map((point) => {
						switch (point.jsonschema_id) {
							case INCIDENT:
							case INTERVENTION:
							case TEMPORARY_CARE:
								if (point.event_id !== selectedEvent?.id) {
									return null
								}
								return (
									<MarkerDaybookStatic
										key={point.id}
										point={point}
										noInteraction={superMapProps.noInteraction}
									/>
								)
							default:
								if (point.id === panel?.id) {
									return null
								}
								return (
									<MarkerDaybook
										key={point.id}
										point={point}
										handleClick={() =>
											dispatch(
												setPanel({
													type: showedFacility ? DAYBOOK_SUPPORT_POINT : DAYBOOK_POINT,
													id: showedFacility ? getSupportIdFromPointId(point.id) : point.id,
												}),
											)
										}
										noInteraction={superMapProps.noInteraction}
									/>
								)
						}
					})}
				</MarkerClusterGroup>
				{noClusterPoints.map((point) => {
					if (point.id === panel?.id) {
						return null
					}
					return (
						<MarkerDaybook
							key={point.id}
							point={point}
							handleClick={() =>
								dispatch(
									setPanel({
										type: showedFacility ? DAYBOOK_SUPPORT_POINT : DAYBOOK_POINT,
										id: showedFacility ? getSupportIdFromPointId(point.id) : point.id,
									}),
								)
							}
							noInteraction={superMapProps.noInteraction}
						/>
					)
				})}

				{panel?.id && points[panel?.id] && (
					<MarkerDaybook
						point={points[panel?.id]}
						handleClick={() =>
							dispatch(
								setPanel({
									type: DAYBOOK_POINT,
									id: points[panel?.id],
								}),
							)
						}
						noInteraction={superMapProps.noInteraction}
						isSelected
					/>
				)}

				{coucheJsonSchemas && checkFiltered()}

				{multiPolygonsToDisplay.map((elem) => {
					const zIndex = 500 - surfaceOrder.indexOf(elem.id)
					return (
						<Pane
							name={elem.id}
							key={`${elem.id}:${zIndex.toString()}`}
							style={{
								zIndex: zIndex,
							}}
						>
							<GeoJSON
								key={elem.id}
								data={JSON.parse(elem.geojson)}
								style={() => getPolygonStyle(elem)}
								eventHandlers={{
									click: () => {
										if (isTakingScreenshotMap || superMapProps.noInteraction) {
											return
										}
										dispatch(
											setPanel({
												type: DAYBOOK_COUCHE,
												id: elem.id,
											}),
										)
									},
								}}
							/>
						</Pane>
					)
				})}

				{Object.values(selectedEvent?.mapSignalMarkers ?? {}).map(
					(signalMarker: any) => {
						if (!signalMarker) {
							return null
						}
						if (coucheEvents[signalMarker.type] === false) {
							return null
						}
						return (
							<Marker
								key={signalMarker.id}
								position={[signalMarker.lat, signalMarker.lng]}
								draggable={draggableSignalMarkers?.[signalMarker.id]}
								eventHandlers={{
									click: () => {
										if (isTakingScreenshotMap || superMapProps.noInteraction) {
											return
										}
										dispatch(
											setPanel({
												type: DAYBOOK_SIGNAL,
												id: signalMarker.id,
											}),
										)
									},
									dragend: (e) => {
										if (isTakingScreenshotMap || superMapProps.noInteraction) {
											return
										}
										dispatch(
											updateEvent({
												...selectedEvent,
												mapSignalMarkers: {
													...selectedEvent?.mapSignalMarkers,
													[signalMarker.id]: {
														...signalMarker,
														lat: e.target._latlng.lat,
														lng: e.target._latlng.lng,
													},
												},
											}),
										)
										dispatch(toggleDraggableSignalMarkers(signalMarker.id))
									},
								}}
								icon={SignalService.buildIcon({
									color: signalMarker.color,
									type: signalMarker.type,
									id: signalMarker.id,
									draggable: draggableSignalMarkers?.[signalMarker.id],
								})}
							/>
						)
					},
				)}

				{Object.values(drawGeojson ?? {})
					.filter((drawGeojsonElement: any) => drawGeojsonElement.visibility)
					.map((geojson: any) => {
						const zIndex = 500 - surfaceOrder.indexOf(geojson.id)
						return (
							<Pane
								name={geojson.id}
								key={`${geojson.id}:${zIndex.toString()}`}
								style={{
									zIndex: zIndex,
								}}
							>
								<GeoJSON
									data={geojson.geojson}
									style={geojson?.custom_props?.style}
								/>
							</Pane>
						)
					})}
			</DaybookMapContextMenu>
		</SuperMap>
	)
}
export default DaybookMap
