import { yupResolver } from "@hookform/resolvers/yup"
import Alert from "@mui/material/Alert"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import { TreeContext } from "@/pages/telechargement/redux/page/context"
import TreeSelection from "@/pages/telechargement/TreeSelection/TreeSelection"
import _ from "lodash"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import useParentSubmit from "utils/form/specific/fiche/links/utils/parentSubmit/useParentSubmit"
import Yup from "utils/yup"
import IsolatedTreeProvider from "./IsolatedTreeProvider"

const Container = styled.div`
    display: flex;
    align-items: top;
    margin-top: 1rem;
`

const schema = Yup.object().shape({
	after: Yup.string().required("Veuillez sélectionner un élément"),
	dependence: Yup.string(),
})

const SelectAfterPCS = ({ title, fileIdsToDisabled = [] }) => {
	const { state: treeState } = useContext(TreeContext)

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)
	const uploadedFilesKeys = Object.keys(useUploadedFiles())

	const handleCheck = (keys) => {
		const precedentItem = _.get(treeState, keys) as any
		const dependence = uploadedFilesKeys.includes(precedentItem.key)
			? precedentItem.key
			: undefined

		// @ts-ignore
		methods.setValue("after", keys[keys.length - 1])
		// @ts-ignore
		methods.setValue("dependence", dependence)
	}

	// @ts-ignore
	methods.watch("after")
	// @ts-ignore
	const after = methods.getValues("after") as string

	return (
		<>
			<Alert severity="info">{title}</Alert>
			<Container>
				<TreeSelection
					onlyOne
					enableCoop={false}
					handleCheck={handleCheck}
					disableRootSelection
					disableChildrens={fileIdsToDisabled}
					isChecked={(key) => {
						return key === after
					}}
				/>
			</Container>
		</>
	)
}

export default SelectAfterPCS
