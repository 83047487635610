import { Checkbox, ListItem, Radio } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItemText from "@mui/material/ListItemText"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Avatar } from "@mui/material"
import React from "react"
import { Flex } from "utils/components/style/flex"
import FolderIcon from "@mui/icons-material/Folder"
import styled, { css } from "styled-components"
import DescriptionIcon from "@mui/icons-material/Description"
import { isMobile, isDesktop } from "react-device-detect"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import PeopleIcon from "@mui/icons-material/People"
import WarningIcon from "@mui/icons-material/Warning"
import GavelIcon from "@mui/icons-material/Gavel"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import ArticleIcon from "@mui/icons-material/Article"
import EventNoteIcon from "@mui/icons-material/EventNote"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import Campaign from "@mui/icons-material/Campaign"
import MapIcon from "@mui/icons-material/Map"
import useUploadedCartos from "@/hooks/useUploadedCartos"
import GridOnIcon from "@mui/icons-material/GridOn"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useCommune from "@/hooks/useCommune"
import _ from "lodash"

const SList = styled(List)`
    width: 100%;
    padding: 0 !important;
`
const SFlex = styled(Flex)`
    width: 100%;
`

const Title = styled.div`
    font-weight: 700;
`
export const SCollapse = styled(Collapse)`
    ${
			isMobile &&
			css`
        border-left: 2px solid rgba(66, 66, 66, 0.6);
    `
		};
`
export const StyledListItem = styled(ListItem)`
    width: 100% !important;
    padding: 8px 0 !important;
` as any
const SAvatar = styled(Avatar)<{ color: string }>`
    background-color: ${({ color }) => color} !important;
`

export const PREAMBULE = "preambule"
export const LIVRET_1 = "LIVRET_1"
export const LIVRET_2 = "LIVRET_2"
export const LIVRET_3 = "LIVRET_3"
export const LIVRET_4 = "LIVRET_4"
export const LIVRET_5 = "LIVRET_5"
export const LIVRET_6 = "LIVRET_6"
export const LIVRET_7 = "LIVRET_7"
export const LIVRET_8 = "LIVRET_8"
export const LIVRET_9 = "LIVRET_9"

export const NestedList = ({
	padding = 0,
	data,
	checked,
	idKeys,
	handleCheck,
	updateSelectedKit = undefined,
	stopEditing = undefined,
	disableRootSelection = false, //true if you want disabled a root of selections
	disableChildrens = [],
	disableIf = false, //condition to disable some selection on a disableable root of selections
	isChecked = undefined,
	onlyOne = false,
}) => {
	const [open, setOpen] = React.useState(false)
	const uploadedCartos = useUploadedCartos()
	const uploadedCartosIds = Object.keys(uploadedCartos)
	const jsonSchemas = useJsonSchemasWithStatics()
	const commune = useCommune()

	const handleClick = () => {
		setOpen(!open)
		if (!data.children) {
			if (disableRootSelection && disableIf) return //selection is disabled
			stopEditing ? stopEditing() : null
			handleCheck(idKeys)
			updateSelectedKit ? updateSelectedKit(undefined) : null
		}
	}

	if (data.children && Object.keys(data.children).length === 0) {
		return null
	}

	const CheckComponent = onlyOne ? Radio : Checkbox

	const getColor = {
		[PREAMBULE]: "var(--neutral500)",
		[LIVRET_1]: "var(--brown)",
		[LIVRET_2]: !commune.is_interco ? "var(--purple300)" : "var(--cyan300)",
		[LIVRET_3]: !commune.is_interco ? "var(--cyan300)" : "var(--green500)",
		[LIVRET_4]: !commune.is_interco ? "var(--green500)" : "var(--primary500)",
		[LIVRET_5]: !commune.is_interco ? "var(--primary500)" : "var(--orange500)",
		[LIVRET_6]: !commune.is_interco ? "var(--orange500)" : "var(--red500)",
		[LIVRET_7]: !commune.is_interco ? "var(--red500)" : "var(--purple300)",
		[LIVRET_8]: "var(--purple700)",
		[LIVRET_9]: "var(--neutral900)",
	}

	const renderIcons = {
		[PREAMBULE]: (
			<SAvatar color={getColor[PREAMBULE]}>
				<ReceiptLongIcon fontSize={isMobile ? "small" : "medium"} />
			</SAvatar>
		),
		[LIVRET_1]: (
			<SAvatar color={getColor[LIVRET_1]}>
				<Campaign fontSize={isMobile ? "small" : "medium"} />
			</SAvatar>
		),
		[LIVRET_2]: (
			<SAvatar color={getColor[LIVRET_2]}>
				{!commune.is_interco ? (
					<GavelIcon fontSize={isMobile ? "small" : "medium"} />
				) : (
					<ArticleIcon fontSize={isMobile ? "small" : "medium"} />
				)}
			</SAvatar>
		),
		[LIVRET_3]: (
			<SAvatar color={getColor[LIVRET_3]}>
				{!commune.is_interco ? (
					<ArticleIcon fontSize={isMobile ? "small" : "medium"} />
				) : (
					<ContactPhoneIcon fontSize={isMobile ? "small" : "medium"} />
				)}
			</SAvatar>
		),
		[LIVRET_4]: (
			<SAvatar color={getColor[LIVRET_4]}>
				{!commune.is_interco ? (
					<ContactPhoneIcon fontSize={isMobile ? "small" : "medium"} />
				) : (
					<HomeWorkIcon fontSize={isMobile ? "small" : "medium"} />
				)}
			</SAvatar>
		),
		[LIVRET_5]: (
			<SAvatar color={getColor[LIVRET_5]}>
				{!commune.is_interco ? (
					<HomeWorkIcon fontSize={isMobile ? "small" : "medium"} />
				) : (
					<AdminPanelSettingsIcon fontSize={isMobile ? "small" : "medium"} />
				)}
			</SAvatar>
		),
		[LIVRET_6]: (
			<SAvatar color={getColor[LIVRET_6]}>
				{!commune.is_interco ? (
					<AdminPanelSettingsIcon fontSize={isMobile ? "small" : "medium"} />
				) : (
					<WarningIcon fontSize={isMobile ? "small" : "medium"} />
				)}
			</SAvatar>
		),
		[LIVRET_7]: (
			<SAvatar color={getColor[LIVRET_7]}>
				{!commune.is_interco ? (
					<WarningIcon fontSize={isMobile ? "small" : "medium"} />
				) : (
					<PeopleIcon fontSize={isMobile ? "small" : "medium"} />
				)}
			</SAvatar>
		),
		[LIVRET_8]: (
			<SAvatar color={getColor[LIVRET_8]}>
				<EventNoteIcon fontSize={isMobile ? "small" : "medium"} />
			</SAvatar>
		),
		[LIVRET_9]: (
			<SAvatar color={getColor[LIVRET_9]}>
				<InsertDriveFileIcon fontSize={isMobile ? "small" : "medium"} />
			</SAvatar>
		),
	}

	const renderChildrenIcons = (data) => {
		if (uploadedCartosIds.includes(data.id)) {
			return <MapIcon fontSize={isMobile ? "small" : "medium"} />
		}
		if (
			jsonSchemas[data.jsonschema_id] ||
			!_.isEqual(commune.id, data.commune_id)
		) {
			return <GridOnIcon fontSize={isMobile ? "small" : "medium"} />
		}

		return <DescriptionIcon fontSize={isMobile ? "small" : "medium"} />
	}

	return (
		<div style={{ marginLeft: padding + "px" }}>
			<SList>
				<Flex>
					<CheckComponent
						disabled={disableRootSelection && disableIf}
						edge="start"
						tabIndex={-1}
						disableRipple
						onClick={() => {
							stopEditing ? stopEditing() : null
							handleCheck(idKeys)
							updateSelectedKit ? updateSelectedKit(undefined) : null
						}}
						checked={checked}
					/>
					<StyledListItem button onClick={handleClick}>
						<SFlex gap="1rem" spaceBetween>
							<Flex gap="1rem">
								{data.children === undefined && (
									<SAvatar color={getColor[idKeys[0]]}>
										{renderChildrenIcons(data)}
									</SAvatar>
								)}
								{data.children !== undefined && data.subtitle === undefined && (
									<SAvatar color={getColor[idKeys[0]]}>
										<FolderIcon fontSize={isMobile ? "small" : "medium"} />
									</SAvatar>
								)}
								{data.subtitle && (
									<>
										{renderIcons[idKeys[0]]}
										<div>
											<ListItemText
												primary={
													<Title>
														{data?.code
															? data.code + " " + data.title
															: data.title}
													</Title>
												}
											/>
											<ListItemText primary={data?.subtitle} />
										</div>
									</>
								)}
								{!data.subtitle && (
									<div>
										<ListItemText
											primary={
												data?.code ? data.code + " " + data.title : data.title
											}
										/>
									</div>
								)}
							</Flex>
							{data.children !== undefined && (
								<Flex gap={isDesktop ? "1rem" : null}>
									({Object.keys(data.children).length})
									{open ? <ExpandLess /> : <ExpandMore />}
								</Flex>
							)}
						</SFlex>
					</StyledListItem>
				</Flex>
				{data.children && (
					<SCollapse in={open} timeout="auto" unmountOnExit>
						{Object.keys(data.children).map((childKey) => (
							<NestedList
								key={childKey}
								idKeys={[...idKeys, "children", childKey]}
								data={data.children[childKey]}
								padding={isMobile ? 16 : padding + 32}
								checked={
									data.children[childKey].checked ||
									(isChecked?.(childKey) ?? false)
								}
								isChecked={isChecked}
								handleCheck={handleCheck}
								onlyOne={onlyOne}
								updateSelectedKit={updateSelectedKit}
								stopEditing={stopEditing}
								disableRootSelection={disableRootSelection}
								disableChildrens={disableChildrens}
								disableIf={disableChildrens.includes(
									data.children[childKey]?.id,
								)}
							/>
						))}
					</SCollapse>
				)}
			</SList>
		</div>
	)
}
export default NestedList
