import Popover from "@mui/material/Popover"
import Debug from "debug"
import React from "react"
// app:javascript:components:maincourante:subComponents:ButtonPopover.tsx
const debug = Debug(
	"app:javascript:components:maincourante:subComponents:ButtonPopover",
)
debug.log = console.log.bind(console)

const ButtonPopover = ({ children, popoverContent = null }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined
	return (
		<>
			<div>
				{children({ handleClick })}
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					{popoverContent({ handleClose })}
				</Popover>
			</div>
		</>
	)
}

export default ButtonPopover
