import { ClickAwayListener, Tooltip } from "@mui/material"
import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import { StyledTooltip } from "./LightTooltip.styled"

const LightTooltip = (props) => {
	if (!isMobile) {
		return (
			<StyledTooltip
				{...props}
				title={
					<div className="z-10  items-center justify-center subpixel-antialiased outline-none box-border text-small bg-content1 rounded-medium shadow-small bg-white">
						<div className="border border-white p-2">{props.title}</div>
					</div>
				}
			/>
		)
	}
	const [open, setOpen] = useState(false)

	const handleTooltipClose = () => {
		setOpen(false)
	}

	const handleTooltipOpen = (event) => {
		event.stopPropagation()
		setOpen(true)
	}
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div onClick={handleTooltipOpen}>
				<StyledTooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					{...props}
					title={
						<div className="z-10  items-center justify-center subpixel-antialiased outline-none box-border text-small bg-content1 rounded-medium shadow-small bg-white">
							<div className="border border-white p-2">{props.title}</div>
						</div>
					}
					placement={"bottom"}
				/>
			</div>
		</ClickAwayListener>
	)
}

export default LightTooltip
