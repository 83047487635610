import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectPdfs = createDeepEqualSelector(
	(state: any) => state.data.pdfs,
	(pdfs: any) => pdfs,
)

const usePdfs = () => {
    return useSelector(selectPdfs)
}

export default usePdfs
