import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import EditIcon from "@mui/icons-material/Edit"
import { Button, IconButton } from "@mui/material"
import Debug from "debug"
import React from "react"
import { isMobile } from "react-device-detect"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { PrimaryTextButton } from "./style/button"
import { Flex } from "./style/flex"
import { H3 } from "./style/header"

const Container = styled.div<{ $wrapper: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ $wrapper }) => $wrapper && "flex-wrap: wrap;"}
    gap: 20px;
    margin-bottom: 10px;
    ${isMobile && "flex-wrap: wrap;padding: 0 1rem;"}
`
const SButton = styled(PrimaryTextButton)`
    width: fit-content;
    margin-bottom: 0.5rem !important;
`

const StyledTitle = styled.div`
    margin-bottom: 0;
    min-width: max-content;
	max-width: 80vw;
`

// app:javascript:utils:components:style:TitleHeader.tsx
const debug = Debug("app:javascript:utils:components:style:TitleHeader")
debug.log = console.log.bind(console)

/**
 *
 *
 * @param {*} {
 *     title,
 *     rightElements = null,
 * }
 * @return {*}
 */
const TitleHeader = ({
	title,
	rightElements = null,
	backArrowTitle = null,
	backArrowUrl = null,
	backArrowAction = undefined,
	handleRenameClick = undefined,
	wrapper = true,
	subtitle = null,
}) => {
	const history = useHistory()

	return (
		<>
			{backArrowTitle && (backArrowUrl || backArrowAction) && (
				<SButton
					startIcon={<ArrowBackIcon />}
					onClick={() => {
						if (backArrowUrl) history.push(backArrowUrl)
						else backArrowAction()
					}}
				>
					{backArrowTitle}
				</SButton>
			)}
			<Container $wrapper={wrapper}>
				<Flex gap flex="1">
					<Flex directionColumn gap="0.25rem" alignItemsInitial>
						<StyledTitle className="text-4xl">{title}</StyledTitle>
						{subtitle}
					</Flex>
					{handleRenameClick && (
						<IconButton size="small" onClick={handleRenameClick}>
							<EditIcon />
						</IconButton>
					)}
				</Flex>
				{rightElements}
			</Container>
		</>
	)
}

export default TitleHeader
