import React, { useMemo } from "react"
import usePointPropertyToText from "utils/components/jsonSchema/properties/usePointPropertyToText"
import IJsonSchema, {
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import IPoint from "utils/types/IPoint"
import PTable from "../PTable"
import PRow from "../PRow"
import PCell from "../PCell"
import NormalText from "utils/pdf/NormalText"
import { Font, View } from "@react-pdf/renderer"

interface PdfJsonSchemaTableProps {
	jsonSchema: IJsonSchema
	points: IPoint[]
	getTextFromProperty: any
}

Font.register({
	family: "Roboto Mono",
	src: "/stylesheets/fonts/roboto_mono/RobotoMono-Regular.ttf",
})

const PdfJsonSchemaTable = ({
	jsonSchema,
	points,
	getTextFromProperty,
}: PdfJsonSchemaTableProps) => {
	const rowProperties = useMemo(() => {
		const properties = jsonSchema.template.properties.filter(
			(property) => property.isColumn,
		)
		return properties
	}, [jsonSchema])

	const calculatedProperties = useMemo(() => {
		const totalLength = points.reduce(
			(sum, point) =>
				sum +
				rowProperties.reduce((innerSum, property) => {
					return (
						innerSum +
							getTextFromProperty(point, property)?.toString()?.length || 0
					)
				}, 0),
			0,
		)

		const averageLength = totalLength / (points.length * rowProperties.length)

		return rowProperties
			.map((property) => {
				const maxPropertyLength = points
					.map((point) => getTextFromProperty(point, property).length)
					.reduce((a, b) => Math.max(a, b), 0)

				const scalingFactor = averageLength
				if (maxPropertyLength === 0) return { property, flexGrow: 0 }
				let flexGrow =
					maxPropertyLength > 0 ? maxPropertyLength / scalingFactor : 0

				const minFlexGrow = averageLength / 10
				flexGrow = flexGrow < minFlexGrow ? minFlexGrow : flexGrow

				return {
					property,
					flexGrow,
				}
			})
			.filter((item) => item.flexGrow > 0)
	}, [rowProperties, points, getTextFromProperty])

	function splitText(text, flexGrow) {
		const words = text?.toString()?.split(" ") || []
		const lines = []
		let currentLine = ""

		let splitThreshold = flexGrow * 0.75
		splitThreshold = Math.floor(splitThreshold)

		for (let index = 0; index < words.length; index++) {
			const word = words[index]
			const isEmail = /[^\s@]+@[^\s@]+\.[^\s@]+/.test(word) // Updated email regex
			const isPossiblePhonePart = /^\d{2}$/.test(word)

			if (currentLine.length + word.length <= splitThreshold) {
				if (
					isPossiblePhonePart &&
					index < words.length - 1 &&
					/^\d{2}$/.test(words[index + 1])
				) {
					let phone = word
					let j = index + 1
					while (j < words.length && /^\d{2}$/.test(words[j])) {
						phone += " " + words[j]
						j++
					}
					if (currentLine.length + phone.length <= splitThreshold) {
						currentLine += phone + " "
					} else {
						lines.push(currentLine.trim())
						currentLine = phone + " "
					}
					index = j - 1
				} else {
					currentLine += word + " "
				}
			} else {
				if (isEmail) {
					let [localPart, domainPart] = word.split("@")
					domainPart = "@" + domainPart

					const splitEmail = (emailPart) => {
						const parts = []
						let startIndex = 0

						for (
							let i = splitThreshold;
							i < emailPart.length;
							i += splitThreshold
						) {
							parts.push(emailPart.slice(startIndex, i))
							startIndex = i
						}

						if (startIndex < emailPart.length) {
							parts.push(emailPart.slice(startIndex))
						}

						return parts
					}

					const localParts = splitEmail(localPart)
					const domainParts = splitEmail(domainPart)

					// Combine local parts and add to lines
					localParts.forEach((part, index) => {
						const isLastPart = index === localParts.length - 1
						const lineToAdd =
							(isLastPart ? currentLine : currentLine.trim()) + part + " "

						if (lineToAdd.length <= splitThreshold) {
							currentLine = lineToAdd
						} else {
							lines.push(currentLine.trim())
							currentLine = part + " "
						}
					})

					// Add domain parts to lines
					domainParts.forEach((part, index) => {
						const isLastPart = index === domainParts.length - 1
						const lineToAdd =
							(isLastPart ? currentLine.trim() + "" : currentLine.trim()) +
							part +
							" "

						if (lineToAdd.length <= splitThreshold) {
							currentLine = lineToAdd
						} else {
							lines.push(currentLine.trim())
							currentLine = part + " "
						}
					})
				} else {
					lines.push(currentLine.trim())
					currentLine = word + " "
				}
			}
		}

		if (currentLine) {
			lines.push(currentLine.trim())
		}

		return lines
	}

	return (
		<PTable style={{ width: "100%" }}>
			<PRow>
				{calculatedProperties.map(({ property, flexGrow }) => (
					<PCell key={property.name} minHeight={0} head style={{ flexGrow }}>
						<NormalText>{property.label}</NormalText>
					</PCell>
				))}
			</PRow>
			{points.map((point, index) => (
				<PRow key={point.id}>
					{calculatedProperties.map(({ property, flexGrow }) => (
						<PCell
							key={property.name}
							minHeight={0}
							style={{ flexGrow }}
							borderBottom={index !== calculatedProperties.length - 1}
						>
							{splitText(
								getTextFromProperty(point, property),
								flexGrow * 7,
							).map((line) => (
								<NormalText
									key={line}
									style={{
										fontSize: 10,
										lineHeight: 1.2,
										textJustify: "none",
										textAlign: "left",
									}}
								>
									{line}
								</NormalText>
							))}
						</PCell>
					))}
				</PRow>
			))}
		</PTable>
	)
}

export default PdfJsonSchemaTable
