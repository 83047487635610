import { ListItemButton, ListItemText } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import Debug from "debug"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { IItemJSonSchema } from "utils/components/categoryNavigation/useNavigationTree"
import getImage from "@/utils/getImage"

// app:javascript:components:customdata:pcsData:JsonSchemaCard.ysx
const debug = Debug(
	"app:javascript:components:customdata:pcsData:JsonSchemaCard.ysx",
)
debug.log = console.log.bind(console)
const StyledListItemButton2 = styled(ListItemButton)<{ $color: string }>`
    display: flex !important;
    gap: 0.5rem !important;
    padding: 0.5rem !important;
    & .MuiListItemText-primary {
        font-size: 0.875rem !important;
    }
    //border-left: 1px solid ${({ $color }) => `var(--${$color}400)`} !important;
    margin-left: 1em !important;
`
const JsonSchemaTreeCard = ({
	itemJsonSchema,
	color,
	setPcsCategory = undefined,
	actionOnFinalSelect = undefined,
}: {
	itemJsonSchema: IItemJSonSchema
	color: string
	setPcsCategory?: React.Dispatch<any>
	actionOnFinalSelect?: () => void
}) => {
	const points = usePoints()
	const history = useHistory()
	const onClickPoints = () => {
		history.push(`/customdata/my_forms/${itemJsonSchema.id}`)
	}
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	return (
		<StyledListItemButton2
			$color={color}
			key={itemJsonSchema.id}
			onClick={() => {
				setPcsCategory ? setPcsCategory(itemJsonSchema) : onClickPoints()
				actionOnFinalSelect ? actionOnFinalSelect() : null
			}}
		>
			<img
				src={getImage(jsonSchemaWithStatics[itemJsonSchema.id]?.imgId)}
				width={35}
				height={35}
			/>
			<ListItemText
				primary={itemJsonSchema.title}
				secondary={
					Object.values(points).filter(
						(point) => point.jsonschema_id === itemJsonSchema.id,
					).length + " données"
				}
			/>
		</StyledListItemButton2>
	)
}

export default JsonSchemaTreeCard
