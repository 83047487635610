import React from "react"
import {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import TypeCheckbox from "./propertiesType/TypeCheckbox"
import TypeGeoloc from "./propertiesType/TypeGeoloc"
import TypePhone from "./propertiesType/TypePhone"
import TypeResponsables from "./propertiesType/TypeResponsables"
import TypeSelectWithImage from "./propertiesType/TypeSelectWithImage"
import TypeStockplace from "./propertiesType/TypeStockplace"
import TypeTags from "./propertiesType/TypeTags"
import TypeText from "./propertiesType/TypeText"

export interface TypeComponentProps {
	value: any
	editable: boolean
	align: "left" | "right"
	diffWith?: any
	isColumnSectorOnly?: boolean
	fontSize?: string
	rowDisplay?: boolean
}

const getPropertyComponent = (property: IJsonSchemaProperty) => {
	switch (property.type) {
		case JsonSchemaPropertiesType.DATE:
		case JsonSchemaPropertiesType.TIME:
		case JsonSchemaPropertiesType.TEXT:
		case JsonSchemaPropertiesType.CREATABLE_SELECT:
		case JsonSchemaPropertiesType.TEXTAREA:
		case JsonSchemaPropertiesType.SELECT:
		case JsonSchemaPropertiesType.RADIO:
		case JsonSchemaPropertiesType.NUMBER:
			return TypeText
		case JsonSchemaPropertiesType.RESPONSABLES:
			return TypeResponsables
		case JsonSchemaPropertiesType.GEOLOC:
			return (props) => (
				<TypeGeoloc
					isColumnSectorOnly={property?.isColumnSectorOnly}
					{...props}
				/>
			)
		case JsonSchemaPropertiesType.PHONE:
			return TypePhone
		case JsonSchemaPropertiesType.CHECKBOX:
			return TypeCheckbox
		case JsonSchemaPropertiesType.STOCKPLACE:
			return TypeStockplace
		case JsonSchemaPropertiesType.TAGS:
			return TypeTags
		case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
			return TypeSelectWithImage
		default:
			return ({ value }) => (
				<>{typeof value === "string" ? value : JSON.stringify(value)}</>
			)
	}
}

function PointPropertyValue({
	value,
	property,
	align = "left",
	editable = false,
	diffWith = undefined,
}: {
	value: any
	property: IJsonSchemaProperty
	align?: "left" | "right"
	editable?: boolean
	diffWith?: any
}) {
	const Component = getPropertyComponent(property)
	return (
		<Component
			value={value}
			editable={editable}
			align={align}
			diffWith={diffWith}
			property={property}
		/>
	)
}

export default PointPropertyValue
