import { Autocomplete, TextField } from "@mui/material"
import _ from "lodash"
import React, { ComponentProps, useState, FC } from "react"
import { useController, useFormContext } from "react-hook-form"
import { Flex } from "utils/components/style/flex"

function FSelect({
	name,
	label = undefined,
	options,
	disabled = false,
	readOnly = false,
	getOptionLabel = _.identity,
	isMulti = false,
	size = "medium",
	renderOption = null,
}: {
	size?: "medium" | "small"
	name: string
	label?: string
	options: string[] | number[]
	disabled?: boolean
	readOnly?: boolean
	getOptionLabel?: (option: string) => string
	renderOption?: FC
	isMulti?: boolean
}) {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	const correctedValue = value ? value : isMulti ? [] : null

	const [acMenuOpen, setAcMenuOpen] = useState(false)

	if (readOnly && _.isEmpty(correctedValue)) {
		return null
	}
	return (
		<Autocomplete
			size={size}
			open={acMenuOpen}
			onOpen={() => setAcMenuOpen(readOnly ? false : true)}
			onClose={() => setAcMenuOpen(false)}
			onKeyDown={(event) => {
				if (event.key === "Backspace" && readOnly) {
					//@ts-ignore
					event.defaultMuiPrevented = true
				}
			}}
			noOptionsText={"Aucune option"}
			disableClearable={readOnly}
			multiple={isMulti}
			fullWidth
			disabled={disabled}
			renderInput={(params) => (
				<Flex fullWidth>
					<TextField
						{...params}
						inputRef={ref}
						label={label}
						size={size}
						disabled={readOnly}
						error={Boolean(error?.message)}
						helperText={error?.message}
						InputProps={{ ...params.InputProps, readOnly }}
					/>
				</Flex>
			)}
			ChipProps={readOnly ? { onDelete: undefined } : {}}
			options={options}
			value={correctedValue}
			getOptionLabel={getOptionLabel}
			onChange={(event, newValue) => {
				onChange(newValue)
			}}
			renderOption={renderOption}
		/>
	)
}

export default FSelect
export type FSelectProps = ComponentProps<typeof FSelect>
