import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { Pane, GeoJSON } from "react-leaflet"
import getPolygonStyle from "utils/components/map/polygon/getPolygonStyle"
import { DAYBOOK_COUCHE } from "utils/panels/PanelCarto"
import { setPanel } from "@/redux-toolkit/common/reducer"

// app:javascript:components:carto2:cartographie:cartographyMap:subComponents:CartographyMultiPolygon.tsx
const debug = Debug(
	"app:javascript:components:carto2:cartographie:cartographyMap:subComponents:CartographyMultiPolygon.tsx",
)
debug.log = console.log.bind(console)

const CartographyMultiPolygon = ({ elem, zIndex }) => {
	const dispatch = useDispatch()

	return (
		<Pane
			name={elem.id}
			key={`${elem.id}:${zIndex.toString()}`}
			style={{
				zIndex: zIndex,
			}}
		>
			<GeoJSON
				key={elem.id}
				data={JSON.parse(elem.geojson)}
				style={() => getPolygonStyle(elem)}
				eventHandlers={{
					click: () => {
						dispatch(
							setPanel({
								type: DAYBOOK_COUCHE,
								id: elem.id,
							}),
						)
					},
				}}
			/>
		</Pane>
	)
}

export default React.memo(CartographyMultiPolygon)
