import ErrorService from "@/services/ErrorService"
import { setUserSettings } from "./reducer"
import UserSettingsApi from "./UserSettingsApi"

export const getUserSettings = async (dispatch, state) => {
	try {
		const userSettings = await UserSettingsApi.getUserSettings()
		dispatch(setUserSettings(userSettings))
	} catch (error) {
		console.error(error)
		const errorMessage = `Erreur lors de la récupération des paramétres d'utilisateur, message : ${error.message}`
		ErrorService.error({
			component: "ressources:getUserSettings",
			message: errorMessage,
			dispatch,
		})
	}
}
