import StaticFormsService from "@/utils/services/StaticFormsService"
import IJsonSchema from "@/utils/types/IJsonSchema"
import IPoint from "@/utils/types/IPoint"
import _ from "lodash"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { INCIDENT, INTERVENTION, TEMPORARY_CARE } from "@/redux-toolkit/userSettings/constants"
const getNameFromId = ({ id, jsonSchemas }) => {
	const staticForm = StaticFormsService.isStaticForm(id)
	// STATIC FORM
	if (staticForm) {
		return staticForm.id
	}

	// DYNAMIC FORM
	return jsonSchemas[id]?.title
}

const isGeolocalisable = (schema) => {
	if (!schema) {
		return false
	}
	if (schema.isGeolocalisable) {
		return schema.isGeolocalisable
	}
	let isGeolocalisable = false
	const schemaProperties = schema?.template?.properties ?? []
	schemaProperties.forEach((property) => {
		if (property.type === "geoloc") {
			isGeolocalisable = true
		}
	})
	return isGeolocalisable
}

const getIdProperties = (jsonSchema: IJsonSchema) => {
	return jsonSchema.template.properties.filter(
		(property) => property.isIdentifiant,
	)
}

const getFilterProperty = (jsonSchema: IJsonSchema) => {
	if (_.isEmpty(jsonSchema?.template?.properties)) {
		return undefined
	}
	return (
		jsonSchema.template.properties.filter(
			(property) => !_.isEmpty(property.itemsImage) && property.type === JsonSchemaPropertiesType.SELECTION_WITH_IMAGE
		)?.[0] ?? undefined
	)
}

const getPhoneProperty = (jsonSchema: IJsonSchema) => {
	return jsonSchema.template.properties.filter(
		(property) => property.type === JsonSchemaPropertiesType.PHONE,
	)[0]
}

const getIdentifiants = (point: IPoint, jsonSchema: IJsonSchema) => {
	const identifiants = {}
	if (!jsonSchema) {
		return {}
	}
	getIdProperties(jsonSchema).forEach((property) => {
		identifiants[property.name] = point?.geojson?.properties[property.name]
	})
	return identifiants
}

const getUndeterminedIdentifiants = (point: IPoint, jsonSchemas: { [key: string]: IJsonSchema }) => {
	const identifiants = []
	if (!jsonSchemas[point.jsonschema_id]) {
		return null
	}
	if (point.jsonschema_id === INTERVENTION) {
		return [point.geojson.properties["Numéro d'intervention"]]
	} else if (point.jsonschema_id === TEMPORARY_CARE) {
		return [point.geojson.properties["Numéro de prise en charge"]]
	} else if (point.jsonschema_id === INCIDENT) {
		return [point.geojson.properties["Numéro d'incident"]]
	} else {
		getIdProperties(jsonSchemas[point.jsonschema_id]).forEach((property) => {
			identifiants.push(point?.geojson?.properties[property.name])
		})
	}
	return identifiants
}


const getGeolocValue = (
	point: IPoint,
	jsonSchemaWithStatics: { [key: string]: IJsonSchema },
) => {
	let geolocProperty = undefined
	jsonSchemaWithStatics[point.jsonschema_id]?.template?.properties?.forEach(
		(property) => {
			if (property.type === "geoloc") {
				geolocProperty = property
			}
		},
	)
	return geolocProperty
}

const getStockPlaceProperty = (jsonSchema: IJsonSchema) => {
	return jsonSchema.template.properties.filter(
		(property) => property.type === JsonSchemaPropertiesType.STOCKPLACE,
	)[0]
}

const getGeolocProperty = (jsonSchema: IJsonSchema) => {
	let geolocProperty = undefined
	jsonSchema.template.properties.forEach((property) => {
		if (property.type === JsonSchemaPropertiesType.GEOLOC) {
			geolocProperty = property
		}
	})
	return geolocProperty
}
const getTagProperty = (jsonSchema: IJsonSchema) => {
	return jsonSchema.template.properties.filter(
		(property) => property.type === JsonSchemaPropertiesType.TAGS,
	)[0]
}
const getResponsableProperty = (jsonSchema: IJsonSchema) => {
	return jsonSchema.template.properties.filter(
		(property) => property.type === JsonSchemaPropertiesType.RESPONSABLES,
	)[0]
}
const getUndeterminedGeolocPropertyName = (point: IPoint, jsonSchemas: { [key: string]: IJsonSchema }) => {
	let geolocKey = null
	if (
		point.jsonschema_id === INTERVENTION ||
		point.jsonschema_id === TEMPORARY_CARE
	) {
		geolocKey = "Adresse"
	} else if (point.jsonschema_id === INCIDENT) {
		geolocKey = "Géolocalisation"
	} else {
		geolocKey = getGeolocProperty(
			jsonSchemas[point.jsonschema_id],
		).name
	}
	return geolocKey
}

export default {
	getUndeterminedGeolocPropertyName,
	getUndeterminedIdentifiants,
	getGeolocProperty,
	getIdentifiants,
	getIdProperties,
	getNameFromId,
	isGeolocalisable,
	getGeolocValue,
	getPhoneProperty,
	getFilterProperty,
	getStockPlaceProperty,
	getTagProperty,
	getResponsableProperty,
}
